<template>
  <v-row align="center" class="px-3">
    <v-col>
      <multi-text-answer-display
        v-if="result && result.data && result.data.groups"
        :response="response"
        :result="result"
        :override="override"
        :editing="editing"
        @input="handleOverrides"
      />
      <text-answer-display
        v-else
        :response="response"
        :override="override"
        :editing="editing"
        @input="handleOverrides"
      />
    </v-col>
    <v-col class="shrink">
      <platform-button
        force-color="secondary"
        text
        v-tooltip="editing ? 'Confirm changes' : 'Edit this response'"
        :icon="editing ? 'save' : 'edit'"
        @click="editing = !editing"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { QuestionAnswer } from '@/types/Question'
import TextAnswerDisplay from '@/components/analysis/results/answers/TextAnswerDisplay.vue'
import MultiTextAnswerDisplay from '@/components/analysis/results/answers/MultiTextAnswerDisplay.vue'
import { ResultType } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'DisplayAnswer',
  components: {
    TextAnswerDisplay,
    MultiTextAnswerDisplay
  },
  props: {
    response: Object as PropType<QuestionAnswer>,
    override: { type: Object as PropType<QuestionAnswer>, required: false },
    result: { type: Object as PropType<ResultType>, required: false }
  },
  data: () => ({
    editing: false
  }),
  methods: {
    handleOverrides (value) {
      let changed = false

      // Don't emit update event if value hasn't actually changed
      if (Array.isArray(value)) {
        // MultiTextQuestion answer, array of objects eg. [{ Strengths: '..', Situations: '..' }]
        value.forEach((v, index) => {
          const originalValues = Object.values(this.override?.value?.[index] ?? this.response.value?.[index])
          Object.values(v).forEach((r, i) => {
            if (r !== originalValues[i]) {
              changed = true
            }
          })
        })
      } else {
        changed = value !== (this.override?.value ?? this.response.value)
      }
      if (!changed) {
        return
      }
      this.$emit('update', {
        ...this.response,
        value
      })
    }
  }
})
</script>
