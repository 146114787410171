var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"fill-height pt-8 align-start justify-start"},[_c('v-row',{class:{
      'px-8': _vm.$vuetify.breakpoint.smAndUp,
      'px-0': _vm.$vuetify.breakpoint.xsOnly
    }},[_c('v-col',[_c('v-stepper',{class:{
          transparent: _vm.hideBackground
        },attrs:{"flat":_vm.hideBackground},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-stepper-header',{staticClass:"flex-nowrap white"},[_vm._l((_vm.steps),function(step,index){return [_c('v-stepper-step',{key:`qws-${step.step}`,staticClass:"overflow-ellipses",class:{
                'cursor-pointer jump-step': _vm.canJumpToStep(step.step)
              },attrs:{"step":step.step,"complete":step.completed},on:{"click":function($event){return _vm.jumpTo(step.step)}}},[_vm._v(" "+_vm._s(step.label)+" ")]),(index !== _vm.steps.length - 1)?_c('v-divider',{key:`qwd-${step.step}`}):_vm._e()]})],2),_c('v-stepper-items',_vm._l((_vm.steps),function(step){return _c('v-stepper-content',{key:`qwi-${step.step}`,staticClass:"px-0 pt-1",class:{
              transparent: _vm.hideBackground
            },attrs:{"step":step.step,"complete":step.completed}},[(step.component && _vm.currentStep === step.step)?_c(step.component,_vm._b({ref:"stepComponent",refInFor:true,tag:"component",on:{"next":_vm.incrementStep,"prev":_vm.decrementStep,"valid":(val) => _vm.isValid = val,"input":_vm.handleInput,"selectPackage":_vm.handlePackageSelection},model:{value:(_vm.formData[step.field]),callback:function ($$v) {_vm.$set(_vm.formData, step.field, $$v)},expression:"formData[step.field]"}},'component',{
                completed: step.completed,
                availablePackages: _vm.availablePackages,
                loading: _vm.pendingFetchTemplates,
                linkedQuestionnaire: _vm.selectedPackageQuestionnaire,
                selectedPackage: _vm.selectedPackage,
                companyQuestionnaires: _vm.companyQuestionnaires,
                hideCommittees: _vm.hideCommittees
              },false)):_vm._e(),(_vm.showStepButtons(step))?_c('v-row',{staticClass:"pt-6 px-6 pb-2",attrs:{"justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"grow"},[(!step.hidePreviousButton)?_c('platform-button',{staticClass:"mr-2",attrs:{"secondary":"","disabled":!_vm.canStepBackward},on:{"click":_vm.decrementStep}},[_vm._v(" "+_vm._s(_vm.$t('form.wizard.previous'))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"shrink"},[(step.nextButton)?_c('platform-button',{attrs:{"primary":"","disabled":!_vm.canStepForward,"loading":_vm.pendingCreate},on:{"click":_vm.incrementStep,"disabledClick":_vm.attemptValidate}},[_vm._v(" "+_vm._s(step.nextButton)+" ")]):_vm._e()],1)],1):_vm._e()],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }