<template>
  <div>
    <v-row no-gutters class="flex-column">
      <v-col class="pb-3">
        <strong class="table-header">{{ $t('analysis.universeSelectorModal.filterSubtitle') }}</strong>
      </v-col>
      <v-col :cols="12">
        <v-card
          outlined
          class="queries-selector overflow-y-auto overflow-x-hidden overflow-scroll-stable pa-3"
        >
          <v-slide-y-transition group>
            <universe-query-row
              v-for="(row, index) in queries"
              :key="`${index}`"
              v-model="queries[index]"
              :v2="v2"
              ref="queryInput"
              @delete="removeQueryCriteria(index)"
            />
          </v-slide-y-transition>
        </v-card>
      </v-col>
      <v-col :cols="12" class="pl-1 pt-3">
        <platform-button
          secondary
          class="mr-2"
          icon="globe-plus"
          text
          default-case
          @click="addQuery"
        >
          {{ $t('analysis.universeSelectorModal.addCriteriaButton') }}
        </platform-button>
      </v-col>
    </v-row>
    <v-row
      v-show="pendingFetch || fetchedResults || results.length"
      no-gutters
      class="flex-column pt-5"
    >
      <v-col class="shrink pb-3 d-flex align-center">
        <strong class="table-header">{{ $t('analysis.universeSelectorModal.resultsTitle') }}</strong>
        <div v-if="lastQueries && lastQueries.length" class="d-inline-block pl-5 grey--text text--darken-3" style="font-size: 11px">
          <span>(</span>
          <span v-for="(query, index) in lastQueries" :key="index">
        <template>{{ query.categoryName }}</template>
        <template v-if="index !== lastQueries.length - 1">, </template>
      </span>
          <span>)</span>
        </div>
      </v-col>
      <v-col :cols="12" class="results-preview shrink">
        <template v-if="pendingFetch">
          <platform-spinner class="ml-1" />
        </template>
        <span v-else-if="fetchedResults && results.length === 0" class="text-body-2 ml-1">
        {{ $t('analysis.universeSelectorModal.noResultsMessage') }}
      </span>
        <display-report-benchmark-scores
          v-if="results.length"
          :value="results"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import API from '@/services/API'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import UniverseQueryRow from '@/views/universe/UniverseRow.vue'
import { StatsResult, V2StatisticsQuery } from '@betterboards/shared/types/Statistic'
import { UniverseQuery } from '@betterboards/shared/types/Report'
import { doQueriesMatch, normalizeUniverseQueries } from '@betterboards/shared/helpers/statistics/queries'
import { ResultSection, SectionResults } from '@betterboards/shared/types/ResultSet'
import sortByDisplayOrder from '@betterboards/shared/helpers/util/sortByDisplayOrder'
import DisplayReportBenchmarkScores from '@/components/forms/reports/benchmarks/DisplayReportBenchmarkScores.vue'

export default Vue.extend({
  name: 'UniverseSelector',
  props: {
    value: { type: Array as PropType<UniverseQuery[]>, required: false },
    scores: { type: Array as PropType<StatsResult[]>, required: false },
    v2: { type: Boolean, default: false }
  },
  components: {
    DisplayReportBenchmarkScores,
    PlatformSpinner,
    UniverseQueryRow
  },
  data () {
    return {
      queries: [] as UniverseQuery[],
      lastQueries: [] as UniverseQuery[], // queries at the point runQuery was last called
      fetchedScores: <StatsResult[]>[],
      availableCriteria: [] as any[],
      pendingFetch: false,
      fetchedResults: false
    }
  },
  watch: {
    isValid (val): void {
      this.$emit('valid', val)
    },
    queries (queries: UniverseQuery[]): void {
      if (!doQueriesMatch(queries, this.lastQueries)) {
        if (this.isValid) {
          this.resetResults()
          this.$emit('input', queries)
          this.runQuery()
        }
      }
    }
  },
  computed: {
    results (): StatsResult[] {
      if (!this.fetchedScores || this.pendingFetch) {
        return []
      }
      const scores: StatsResult[] = []
      this.fetchedScores.forEach((score) => {
        if (!score.subSections || score.subSections.length === 0) {
          const sections: ResultSection[] = this.$store.state.Analysis.resultSet?.sections ?? []

          const targetSectionIndex: number = sections.findIndex((s) => {
            if (score.key) {
              return s.id === score.key
            }
            return s.masterSectionId === score.masterSectionId
          })
          const targetSection: SectionResults | undefined = sections[targetSectionIndex]
          if (targetSection?.name) {
            score.name = targetSection.name
            score.displayOrder = targetSection.displayOrder ?? targetSectionIndex
          }
          scores.push({
            key: score.key,
            masterSectionId: score.masterSectionId,
            displayOrder: score.displayOrder,
            name: score.name,
            value: score.value
          })
        }
        score.subSections?.forEach((subItem) => {
          // @TODO: Implement finding/creating a root score object for the Section, then insert these SubSection scores in its subSections array instead of in the root
          scores.push({
            key: subItem.key,
            masterSectionId: score.masterSectionId,
            subSectionId: subItem.key,
            displayOrder: subItem.displayOrder,
            name: subItem.name,
            value: subItem.value
          })
        })
      })
      return scores.sort(sortByDisplayOrder)
    },
    isValid (): boolean {
      if (!this.queries.length) {
        return false
      }
      return this.queries.every((q) => q.collection && q.category)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init (): void {
      if (this.value?.length) {
        this.queries.splice(
          0,
          this.queries.length,
          ...this.value
        )
        this.setLastQueries(this.queries)
      } else {
        this.addQuery()
      }

      if (this.scores?.length) {
        this.fetchedScores.splice(
          0,
          this.fetchedScores.length,
          ...this.scores
        )
      }
    },
    addQuery () {
      this.queries.push({
        collection: '',
        category: ''
      })
      this.$nextTick(() => {
        // Scroll the element for the new query into view
        const queryRows: Vue.Component[] = this.$refs.queryInput as Vue.Component[]
        const newestQueryRow: Vue.Component = queryRows[queryRows.length - 1]
        const newestQueryRowEl: HTMLElement = (newestQueryRow as any).$el
        newestQueryRowEl.scrollIntoView()
      })
    },
    removeQueryCriteria (index: number) {
      this.queries.splice(index, 1)
    },
    async runQuery () {
      this.resetResults()
      this.pendingFetch = true

      let endpointPath: string = '/stats/universe'
      let requestBody: UniverseQuery[] | { queries: V2StatisticsQuery[] } = this.queries

      if (this.v2) {
        endpointPath = '/stats/v2'
        console.debug('queries:', this.queries)
        const queries: V2StatisticsQuery[] = normalizeUniverseQueries(this.queries)
        requestBody = {
          queries
        }
      }

      let resultsResponse: StatsResult[] | { results: StatsResult[] }
      try {
        resultsResponse = await API.post(
          'backendfunctions',
          endpointPath,
          {
            body: requestBody
          }
        )
      } finally {
        this.pendingFetch = false
        this.fetchedResults = true
      }
      console.debug('Got universe query results:', resultsResponse)
      this.setLastQueries(this.queries)
      const results = Array.isArray(resultsResponse)
        ? resultsResponse
        : resultsResponse.results
      this.fetchedScores.splice(
        0,
        this.fetchedScores.length,
        ...results
      )
      this.$emit('queried', this.results)
    },
    setLastQueries (queries: UniverseQuery[]): void {
      this.lastQueries = JSON.parse(JSON.stringify(queries))
    },
    resetResults (): void {
      this.results.splice(0, this.results.length)
      this.lastQueries.splice(0, this.lastQueries.length)
      this.fetchedResults = false
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.queries-selector {
  min-height: 75px;
  max-height: 175px;
}
.results-preview {
  min-height: 100px;
  max-height: 300px;
}
</style>
