import * as d3 from 'd3'
import { normalizeFloat } from '../helpers'
import { GroupSelection } from '../types/d3'
import { CategoryGraphDataPoint, DotTypes, FontSizes, LineConfig } from '../types/Graph'
import { GraphConfig } from '../variants/Abstract'

export const DotSize = 12

export function drawDots (
  config: GraphConfig,
  data: CategoryGraphDataPoint[],
  group: GroupSelection,
  lineConfig: LineConfig,
  xAxis: d3.ScaleLinear<any, any> | ((val: number) => number) = (val: number): number => val,
  yAxis: d3.ScaleBand<string> | ((val: string) => string) = (val: string): string => val,
  alternateLegendStyle: boolean = false
): void {
  const alignLabel = lineConfig.alignLabel ?? 'top'
  const dots = group.append('g')
    .selectAll('dot')
    .raise()
    .data(data)
    .enter()

  const dotSize = lineConfig.dotSize ?? (config.scale * DotSize)

  switch (lineConfig.dot) {
    case DotTypes.Circle:
      dots.append('circle')
        .attr('cx', (d) => d.xVal ?? xAxis(d.x as number))
        .attr('cy', (d) => d.yVal ?? yAxis(d.category as string) as number)
        .attr('r', dotSize / 2)
        .style('fill', lineConfig.color)
        .style('stroke', 'white')
        .style('stroke-width', config.scale * 2)
      break
    case DotTypes.Rect:
      dots.append('rect')
        .attr('x', (d) => (d.xVal ?? xAxis(d.x as number)) - dotSize / 2)
        .attr('y', (d) => (d.yVal ?? yAxis(d.category as string) as number) - dotSize / 2)
        .attr('width', dotSize)
        .attr('height', dotSize)
        .style('fill', lineConfig.color)
        .style('stroke', 'white')
        .style('stroke-width', config.scale * 2)
      break
    case DotTypes.Range:
      dots.append('path')
        .attr('d', 'm -50 -8 l 0 14 A 1 1 0 0 0 -48 6 l 0 -6 l 46 0 l 0 6 A 1 1 0 0 0 0 6 l 0 -14 A 1 1 0 0 0 -2 -8 l 0 6 l -46 0 l 0 -6 A 1 1 0 0 0 -50 -8')
        .attr('fill', lineConfig.color)
        .attr('stroke', lineConfig.color)
        .attr('stroke-width', config.scale)
        .style('transform', `scale(${config.scale})`)
      break
    case DotTypes.RangeAlt:
      dots.append('path')
        .attr('d', 'm -44 -2 l 0 5 l 5 0 l 0 -1 l -4 0 l 0 -4 m 8 4 l 4 0 l 0 1 l -4 0 m 8 -1 l 4 0 l 0 1 l -4 0 m 8 -1 l 4 0 l 0 1 l -4 0 m 8 -1 l 4 0 l 0 -4 l 1 0 l 0 5 l -5 0')
        .attr('fill', lineConfig.color)
        .attr('stroke', lineConfig.color)
        .attr('stroke-width', config.scale)
        .style('transform', `scale(${config.scale})`)
      break
    case DotTypes.Dash:
      if (alternateLegendStyle) {
        dots.append('path')
          .attr('d', 'm -11 -2 l 0 2 l 4 0 l 0 -2 l -4 0 m 7 0 l 0 2 l 4 0 l 0 -2 l -4 0 m 7 0 l 0 2 l 4 0 l 0 -2 l -4 0')
          .attr('stroke', lineConfig.color)
          .attr('fill', lineConfig.color)
          .attr('stroke-width', config.scale)
          .style('transform', `scale(${config.scale})`)
        break
      }
      dots
        .append('g')
        .attr('transform', (d) => {
          const x = (d.xVal ?? xAxis(d.x as number)) - dotSize / 2
          const y = (d.yVal ?? yAxis(d.category as string) as number) - dotSize / 2
          return `translate(${x}, ${y})`
        })
        .append('path')
        .attr('d', 'm 1 0.6 l 2.7 0 m 1.0125 0 l 2.7 0 m 1.0125 0 l 2.7 0 m 0 0 l 0 2.7 m 0 1.0125 l 0 2.7 m 0 1.0125 l 0 2.7 m 0 0 l -2.7 0 m -1.0125 0 l -2.7 0 m -1.0125 0 l -2.7 0 m 0 0 l 0 -2.7 m 0 -1.0125 l 0 -2.7 m 0 -1.0125 l 0 -2.7')
        .attr('stroke', lineConfig.color)
        .attr('fill', 'transparent')
        .attr('stroke-width', config.scale)
        .style('transform', `scale(${config.scale})`)
      break
    case DotTypes.Dotted:
      if (alternateLegendStyle) {
        dots.append('path')
          .attr('d', 'm -7 -2 a 1 1 0 0 0 0 4 a 1 1 0 0 0 0 -4 m 6 0 a 1 1 0 0 0 0 4 a 1 1 0 0 0 0 -4 m 6 0 a 1 1 0 0 0 0 4 a 1 1 0 0 0 0 -4')
          .attr('stroke', lineConfig.color)
          .attr('fill', lineConfig.color)
          .attr('stroke-width', 1)
          .style('transform', `scale(${config.scale})`)
        break
      }
      dots
        .append('g')
        .attr('transform', (d) => {
          const x = (d.xVal ?? xAxis(d.x as number)) - dotSize / 2
          const y = (d.yVal ?? yAxis(d.category as string) as number) - dotSize / 2
          return `translate(${x}, ${y})`
        })
        .append('path')
        .attr('d', 'm 7 0.5 a 0.35 0.35 90 0 0 -2.1 0 a 0.35 0.35 90 0 0 2.1 0 m 2.8 0.861 a 0.35 0.35 90 0 0 -2.1 0 a 0.35 0.35 90 0 0 2.1 0 m 2.03 1.939 a 0.35 0.35 90 0 0 -2.1 0 a 0.35 0.35 90 0 0 2.1 0 m 0.77 2.8 a 0.35 0.35 90 0 0 -2.1 0 a 0.35 0.35 90 0 0 2.1 0 m -0.77 2.8 a 0.35 0.35 90 0 0 -2.1 0 a 0.35 0.35 90 0 0 2.1 0 m -2.03 1.939 a 0.35 0.35 90 0 0 -2.1 0 a 0.35 0.35 90 0 0 2.1 0 m -2.8 0.861 a 0.35 0.35 90 0 0 -2.1 0 a 0.35 0.35 90 0 0 2.1 0 m -4.9 -0.861 a 0.35 0.35 90 0 0 2.1 0 a 0.35 0.35 90 0 0 -2.1 0 m -2.03 -1.939 a 0.35 0.35 90 0 0 2.1 0 a 0.35 0.35 90 0 0 -2.1 0 m -0.77 -2.8 a 0.35 0.35 90 0 0 2.1 0 a 0.35 0.35 90 0 0 -2.1 0 m 0.77 -2.8 a 0.35 0.35 90 0 0 2.1 0 a 0.35 0.35 90 0 0 -2.1 0 m 2.03 -1.939 a 0.35 0.35 90 0 0 2.1 0 a 0.35 0.35 90 0 0 -2.1 0')
        .attr('fill', lineConfig.color)
        .style('transform', `scale(${config.scale})`)
      break
    case DotTypes.Line:
      dots.append('path')
        .attr('d', 'm -9 -2 l 0 2 l 15 0 l 0 -2 L -9 -2')
        .attr('stroke', lineConfig.color)
        .attr('fill', lineConfig.color)
        .style('transform', `scale(${config.scale})`)
        .attr('stroke-width', 1)
      break
    case DotTypes.Triangle:
      dots.append('path')
        .style('transform', `scale(${config.scale})`)
        .style('transform-origin', (d) => {
          const x: number = d.xVal ?? xAxis(d.x as number)
          const y: number = d.yVal ?? yAxis(d.category as string) as number
          return `${x.toString()}, ${y.toString()}`
        })
        .attr('d', 'M -4 4 L 0 4 L 4 4 L 0 -4 z')
        .attr('fill', lineConfig.color)
        .attr('stroke', lineConfig.color)
        .attr('stroke-width', 1)
      break
    default:
      console.error('Found a dot of an unknown type. Not sure what to do with it!', lineConfig)
      break
  }

  if (lineConfig.dataLabels !== false) {
    drawLabels(dots, xAxis, yAxis, config, lineConfig, alignLabel)
  }
}

function drawLabels (dots: d3.Selection<any, CategoryGraphDataPoint, SVGGElement, any>, xAxis: any, yAxis: any, config: GraphConfig, lineConfig: LineConfig, alignLabel: 'right' | 'top'): void {
  const labels = dots
    .append('text')
    .attr('class', 'label')
    .attr('x', (d: CategoryGraphDataPoint) => {
      const xVal = d.xVal ?? xAxis(d.x as number) as number
      const dotAlignmentOffset = d.xOffset ?? 0
      const globalAlignmentOffset = (d.alignLabel ? d.alignLabel === 'right' : alignLabel === 'right') ? 10 : 0
      return xVal + dotAlignmentOffset + globalAlignmentOffset
    })
    .attr('y', (d: CategoryGraphDataPoint) => (d.yVal ?? yAxis(d.category as string) as number) - (alignLabel === 'top' ? config.scale * FontSizes.DataLabel * 0.75 : 0))
    .attr('font-size', config.scale * FontSizes.DataLabel)
    .attr('dominant-baseline', 'middle')
    .attr('alignment-baseline', 'middle')
    .attr('text-anchor', alignLabel === 'right' ? 'left' : 'middle')
    .style('fill', lineConfig.color)
    .text((d: CategoryGraphDataPoint) => (d.label as string) ?? (d.x ? normalizeFloat(d.x) : undefined) ?? '')

  if (lineConfig.strokeLabel !== false) {
    labels
      .style('font-weight', '900')
      .style('stroke', 'white')
      .style('stroke-width', 1.0)
  }
}
