var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list',{staticClass:"analysis-sidebar pb-0"},[(_vm.resultSet)?_c('h2',{staticClass:"text-center mb-4"},[_vm._v(_vm._s(_vm.resultSet.name))]):_vm._e(),_vm._l((_vm.startLinks),function(link){return _c('v-list-item',{key:link.route,ref:`systemPage-${link.slug}`,refInFor:true,attrs:{"to":{
      name: link.route,
      params: {
        surveyId: _vm.$route.params.surveyId
      }
    }}},[_c('v-list-item-icon',[_c('platform-icon',{attrs:{"color":link.icon && (!link.requiresCompletion || link.completed) ? 'white' : 'grey darken-3',"name":link.icon || 'check-outline'}})],1),_c('v-list-item-content',[_c('span',{staticClass:"section-name"},[_vm._v(" "+_vm._s(link.name)+" ")])])],1)}),(_vm.pendingFetch)?_c('platform-spinner',{staticClass:"mx-auto my-3",attrs:{"size":20,"width":2}}):_vm._l((_vm.sections),function(section,index){return _c('v-list-item',{key:`${section ? section.id : ''}-${index}`,ref:"sectionLink",refInFor:true,class:{
        'v-list-item--active': _vm.$route.params.sectionId === section.id
      },attrs:{"to":{
        name: 'AnalysisSection',
        params: {
          surveyId: _vm.$route.params.surveyId,
          sectionId: section.id,
          tab: _vm.$route.params.tab
        }
      }}},[_c('v-list-item-icon',[_c('platform-icon',{staticClass:"section-completion",class:{
            completed: !!section.reviewed
          },attrs:{"name":"check-outline","color":!!section.reviewed ? 'secondary' : 'grey darken-3'}})],1),_c('v-list-item-content',[_c('span',{staticClass:"section-name"},[_vm._v(" "+_vm._s(section.name)+" ")])])],1)}),_vm._l((_vm.endLinks),function(link){return _c('v-list-item',{key:link.route,ref:`systemPage-${link.slug}`,refInFor:true,attrs:{"to":{
      name: link.route,
      params: {
        surveyId: _vm.$route.params.surveyId
      }
    }}},[_c('v-list-item-icon',[_c('platform-icon',{attrs:{"color":link.icon && (!link.requiresCompletion || link.completed) ? 'white' : 'grey darken-3',"name":link.icon || 'check-outline'}})],1),_c('v-list-item-content',[_c('span',{staticClass:"section-name"},[_vm._v(" "+_vm._s(link.name)+" ")])])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }