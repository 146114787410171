<template>
  <div>
    <platform-modal
      v-model="showModal"
      icon="team"
      :scrollable="false"
      :width="1000"
      :title="modalTitle"
      style="min-height: 65vh"
      :saving="pendingSave"
      :save-disabled="!isValid"
      @save="save"
    >
      <div>

        <v-tabs
          v-if="formData.teams"
          v-model="selectedTeamId"
        >
          <v-tab
            v-for="{ teamId, name } in formData.teams"
            :key="teamId"
            :href="`#${teamId}`"
          >
            {{ name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="formData.teams"
          v-model="selectedTeamId"
        >
          <v-tab-item
            v-for="team in formData.teams"
            :key="team.teamId"
            :id="team.teamId"
          >
            <div class="overflow-y-auto" style="max-height: 60vh;">
              <team-individuals-form
                v-model="team.individuals"
                :team-id="team.teamId"
                :saving="pendingSave"
                :company-id="selectedCompany.id"
                :creating="creating"
                :allow-create-individual="allowCreateIndividual"
                @valid="(val) => isValid = val"
              />
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </platform-modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import { CompanyTeamTypes } from '@/API'
import PlatformModal from '@/components/PlatformModal.vue'
import TeamIndividualsForm from '@/components/teams/TeamIndividualsForm.vue'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { FormTeam, FormTeamIndividual } from '@betterboards/shared/types/Team'

export default Vue.extend({
  name: 'ManageTeamsModal',
  components: {
    PlatformModal,
    TeamIndividualsForm
  },
  props: {
    originTeamId: { type: String as PropType<CompanyTeamTypes | null>, required: false },
    creating: { type: Boolean, default: false },
    allowCreateIndividual: { type: Boolean, default: false }
  },
  data () {
    return {
      showModal: true,
      isValid: false,
      pendingSave: false,
      formData: {
        teams: <FormTeam[]>[]
      },
      activeTabTeamId: <CompanyTeamTypes | null>null,
      activeTeamIndividualIds: <string[]>[]
    }
  },
  mounted () {
    this.initTeams()
  },
  watch: {
    showModal (val: boolean): void {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany']),
    selectedTeamId: {
      get (): CompanyTeamTypes {
        return this.activeTabTeamId ?? this.originTeamId ?? CompanyTeamTypes.Board
      },
      set (teamId: CompanyTeamTypes): void {
        this.activeTabTeamId = teamId
      }
    },
    modalTitle (): string {
      return this.$t('modal.teams.manage.title') as string
    },
    individualsList (): PlatformIndividual[] {
      return this.$store.getters['Individual/individualsList']
    }
  },
  methods: {
    initTeams (): void {
      const AvailableTeamTypes: CompanyTeamTypes[] = Object.values(CompanyTeamTypes) as CompanyTeamTypes[]
      this.formData.teams.push(
        ...AvailableTeamTypes.map((teamId: CompanyTeamTypes): FormTeam => {
          const individuals = this.individualsList
            .filter((i) => i.teams?.items?.some((t) => t.teamId === teamId))
            .map((i) => i.id)
          return {
            name: this.$t(`global.companyTeams.${teamId}`) as string,
            teamId,
            individuals
          } as FormTeam
        })
      )
    },
    async save () {
      const companyId = this.selectedCompany.id
      const newIndividuals: FormTeamIndividual[] = this.formData.teams.reduce(
        (newIndividuals: FormTeamIndividual[], team: FormTeam) => {
          if (team.individuals.length) {
            team.individuals.forEach((individualId) => {
              const alreadyOnTeam = this.individualsList.some(
                (i) => i.id === individualId && i.teams?.items?.some((t) => t.teamId === team.teamId)
              )
              if (alreadyOnTeam) {
                // Individual was already on this Team.
                return
              }
              // Individual has been added to Team
              newIndividuals.push({
                individualId,
                teamId: team.teamId,
                companyId
              })
            })
          }
          return newIndividuals
        },
        []
      )
      const deletedIndividuals: FormTeamIndividual[] = this.individualsList.reduce(
        (deletedIndividuals: FormTeamIndividual[], individual: PlatformIndividual) => {
          if (individual.teams?.items.length) {
            individual.teams.items.forEach(({ teamId }) => {
              const stillOnTeam = this.formData.teams.some(
                (t) => t.teamId === teamId && t.individuals.some((i) => i === individual.id)
              )
              if (stillOnTeam) {
                // Individual is still on this Team.
                return
              }
              // Individual has been deleted from Team
              deletedIndividuals.push({
                individualId: individual.id,
                teamId,
                companyId
              })
            })
          }
          return deletedIndividuals
        },
        []
      )
      if (!newIndividuals.length && !deletedIndividuals.length) {
        return
      }
      this.pendingSave = true
      const promises: any[] = []
      promises.push(
        ...newIndividuals.map(async (teamIndividualData: FormTeamIndividual) => {
          await this.$store.dispatch('Team/createTeamIndividual', teamIndividualData)
        })
      )
      promises.push(
        ...deletedIndividuals.map(async (teamIndividualData: FormTeamIndividual) => {
          await this.$store.dispatch('Team/deleteTeamIndividual', teamIndividualData)
        })
      )
      try {
        await Promise.all(promises)
        this.showModal = false
        this.$toasted.success(this.$t('modal.teams.manage.successMessage') as string)
        this.$emit('updated')
      } catch (err) {
        console.error('Caught an error while trying to update a team:', err)
        this.$toasted.error(this.$t('modal.teams.manage.errorMessage') as string)
      } finally {
        this.pendingSave = false
      }
    }
  }
})
</script>
