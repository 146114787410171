<template>
  <div :class="{ 'px-8 pt-4': padded }">
    <v-row no-gutters class="mb-5" justify="center">
      <package-preview
        :questionnaire-package="questionnairePackage"
        :new-group-questionnaire="existingPackageQuestionnaire"
        compact
      />
    </v-row>
    <v-form ref="form" v-model="isValid" @submit.prevent="">
      <v-form-base
        :model="questionnaire"
        :schema="formSchema"
        @input="handleInput"
      />
    </v-form>
  </div>
</template>

<script lang="ts">
import { Package, PackageCategories, PackageItem, PackageItemTypes, Questionnaire } from '@/API'
import { MegaMenuItem } from '@/helpers/forms'
import { DefaultQuestionnaireVariantName } from '@betterboards/shared/helpers/entities/Questionnaire/index'
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import PackagePreview from '@/components/questionnaire/packages/PackagePreview.vue'
import { NullPackageIdValue } from '@/helpers/packages'
import { PlatformPackage, QuestionnaireVariantCode } from '@/types/Platform'
import { parseQuestionnaire } from '@/helpers/questionnaire'
import { PlatformQuestionnaire } from '@betterboards/shared/types/Platform'

export default Vue.extend({
  components: {
    VFormBase,
    PackagePreview
  },
  props: {
    value: { type: Object, required: true },
    availablePackages: { type: Array as PropType<any>, required: true },
    companyQuestionnaires: { type: Array as PropType<Questionnaire[]>, required: true },
    padded: { type: Boolean, default: true },
    loading: { type: Boolean, default: true }
  },
  data: () => ({
    pendingFetchTemplates: false,
    packageCategories: [] as MegaMenuItem[],
    questionnairePackage: <Partial<PlatformPackage>>{
      id: NullPackageIdValue,
      categories: undefined,
      name: undefined,
      description: undefined,
      variants: undefined
    },
    selectedQuestionnaireTemplate: <PlatformQuestionnaire | undefined>undefined,
    isValid: false,
    NullPackageIdValue
  }),
  mounted () {
    this.getPackageItem(this.value.packageId)
  },
  watch: {
    'questionnairePackage.variants' (variants: QuestionnaireVariantCode[]): void {
      this.questionnaire.variants = {}
      variants.forEach((variantCode) => {
        if (variants.length <= 1) {
          this.questionnaire.variants[variantCode] = DefaultQuestionnaireVariantName
          return
        }
        this.questionnaire.variants[variantCode] = null
      })
    },
    isValid (val: boolean) {
      this.$emit('valid', val)
    }
  },
  computed: {
    questionnaire: {
      get (): any {
        return this.value
      },
      set (questionnaire: any) {
        this.$emit('input', questionnaire)
      }
    },
    templates (): any[] {
      if (!this.availablePackages) {
        return []
      }
      const availablePackages = this.availablePackages.reduce(
        (categories: MegaMenuItem[], questionnairePackage: Package) => {
          questionnairePackage.categories.forEach((categorySlug: PackageCategories | null) => {
            if (!categorySlug) {
              return
            }
            let index = categories.findIndex((c) => c.value === categorySlug)
            if (index === -1) {
              const categoryMenuItem: MegaMenuItem = {
                text: this.$t(`form.wizard.packages.${categorySlug}Packages`) as string,
                value: categorySlug,
                items: []
              }
              index = categories.push(categoryMenuItem) - 1
            }
            const packageMenuItem: MegaMenuItem = {
              text: questionnairePackage.name,
              value: questionnairePackage.id,
              items: undefined
            }
            categories[index].items?.push(packageMenuItem)
          })
          return categories
        },
        []
      )
      return availablePackages
    },
    formSchema (): any {
      const schema: any = {
        name: {
          type: 'BBTextField',
          label: this.$t('form.wizard.createQuestionnaire.questionnaireName'),
          col: { xs: 12, sm: 6 },
          required: true
        },
        packageId: {
          type: 'BBLayeredSelectField',
          label: this.$t('form.wizard.createQuestionnaire.questionnaireTemplate'),
          disabled: this.loading,
          loading: this.loading,
          required: true,
          items: [
            ...this.templates
          ],
          col: {
            xs: 12,
            sm: 6
          }
        }
      }
      if (this.questionnairePackage.variants?.length && this.questionnairePackage.variants?.length > 1) {
        schema.variants = {}
        const packageVariants = this.questionnairePackage.variants
        for (let index = 0; index < packageVariants.length; index++) {
          const variantCode = packageVariants[index]
          const variantName = this.$t(`global.languages.${variantCode}`)
          schema.variants[variantCode] = {
            type: 'BBTextField',
            label: `${variantName} Variant Name (${variantCode.toUpperCase()})`,
            col: {
              xs: 12,
              sm: 6
            },
            placeholder: this.selectedQuestionnaireTemplate?.variants.find((v) => v.variantCode === variantCode)?.name,
            required: true
          }

          schema.variants[`spacer${variantCode}`] = {
            type: 'spacer',
            col: {
              sm: 6
            }
          }
        }
      }
      return schema
    },
    existingPackageQuestionnaire (): Questionnaire | undefined {
      // If our Company already has a Questionnaire using the selected Package, return it.
      return this.companyQuestionnaires.find(
        (questionnaire: Questionnaire) => questionnaire.packageId === this.questionnaire.packageId
      )
    }
  },
  methods: {
    handleInput (input): void {
      if (input.on !== 'input') {
        return
      }
      if (input.key === 'packageId') {
        this.getPackageItem(input.value)
        this.$emit('selectPackage', input.value)
      }
      this.questionnaire = input.data
    },
    getPackageItem (packageId): void {
      if (!packageId || packageId === 'null' || packageId === this.questionnairePackage.id) {
        return
      }
      const questionnairePackage: PlatformPackage = this.availablePackages.find((p) => p.id === packageId)
      const questionnaireTemplate = questionnairePackage.packageItems?.items.find((pi: PackageItem | null) => pi?.type === PackageItemTypes.Questionnaire)?.content
      if (!questionnaireTemplate) {
        console.error(`Failed to get a Questionnaire PackageItem from Package "${packageId}".`, JSON.stringify({ questionnairePackage }))
        throw new Error('Failed to get Questionnaire template from requested Package.')
      }
      this.questionnairePackage = questionnairePackage
      const questionnaireData = parseQuestionnaire(questionnaireTemplate)
      for (const key of Object.keys(questionnaireData)) {
        if (key === 'name') {
          // Don't overwrite Questionnaire name with name of template variant
          continue
        }
        Vue.set(this.questionnaire, key, questionnaireData[key])
      }
      this.selectedQuestionnaireTemplate = typeof questionnaireTemplate === 'string'
        ? JSON.parse(questionnaireTemplate)
        : questionnaireTemplate
    },
    validate (): void {
      const form: any = this.$refs.form
      form.validate()
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../scss/mixins.scss';

.card-action {
  border-left: 1px solid var(--v-grey-lighten1);
  border-radius: 0 !important;
}

.package-description {
  @include line-clamp(3);
}
</style>
