import { Survey } from '@/API'
import { PlatformSurvey } from '@/types/Platform'
import API from '@/services/API'

export function calculateCompletion (survey: PlatformSurvey | Survey | undefined): number | undefined {
  if (!survey) {
    return undefined
  }
  if (survey.finishedAt) {
    return 100
  }
  if (survey.totals?.questionsCompleted && survey.totals?.questions) {
    return survey.totals.questionsCompleted / survey.totals.questions * 100
  }
  return 0
}

export async function resendQuestionnaireInvite (
  individualId: string,
  surveyGroupId: string,
  resetPassword: boolean = true
): Promise<void> {
  try {
    await API.post('backendfunctions', '/users/resend-invite', {
      body: {
        individualId,
        surveyGroupId,
        resetPassword
      }
    })
  } catch (err) {
    console.error('Failed to resend Questionnaire invite for Individual.', { individualId, surveyGroupId, resetPassword, err })
    throw err
  }
}
