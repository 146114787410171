import { Amplify } from 'aws-amplify'
import { LibraryOptions, ResourcesConfig } from '@aws-amplify/core'
import { fetchAuthSession } from 'aws-amplify/auth'
import amplifyConfig from '../amplifyconfiguration.json'

const amplifyResourceConfig: ResourcesConfig = {
  ...amplifyConfig as any
}

const amplifyLibraryOptions: LibraryOptions = {
  API: {
    GraphQL: {
      headers: async (): Promise<Record<string, string>> => {
        const session = await fetchAuthSession()
        if (!session) {
          return {}
        }
        return {
          Authorization: session.tokens?.accessToken?.toString() as string
        }
      }
    }
  }
}

Amplify.configure(
  amplifyResourceConfig,
  amplifyLibraryOptions
)
