import { ReportBenchmark } from '../../../types/ReportBenchmark'
import { UniverseQuery } from '../../../types/Report'
import getStatisticCategoryName from '../getStatisticCategoryName'
import { CompanyStatsCollection } from '../index'
import { V2StatisticsQuery } from '../../../types/Statistic'
import { isOrganisationV2StatsQuery } from '../queries'

export default function getUniverseBenchmarkSubTitle (
  benchmark: ReportBenchmark
): string | undefined {
  if (!benchmark.queries) {
    return undefined
  }
  if (benchmark.version === '1') {
    return getV1UniverseBenchmarkSubTitle(benchmark.queries)
  } else if (benchmark.version === '2') {
    return getV2UniverseBenchmarkSubTitle(benchmark.queries)
  }
  return undefined
}

export function getV1UniverseBenchmarkSubTitle (
  queries: UniverseQuery[]
): string | undefined {
  if (!queries.length) {
    return undefined
  }
  const queryDisplayValues: string[] = queries.map((q: UniverseQuery): string => {
    return q.categoryName ?? getStatisticCategoryName(
      q.collection as CompanyStatsCollection,
      q.category
    )
  })
  return queryDisplayValues.join(', ')
}

export function getV2UniverseBenchmarkSubTitle (
  queries: V2StatisticsQuery[]
): string | undefined {
  if (!queries.length) {
    return undefined
  }
  const queryDisplayValues: string[] = queries
    .filter(
      (q: V2StatisticsQuery): boolean => !isOrganisationV2StatsQuery(q)
    )
    .map((q: V2StatisticsQuery): string => getStatisticCategoryName(
      q.field as CompanyStatsCollection,
      q.value
    ))

  if (!queryDisplayValues.length) {
    return undefined
  }
  return queryDisplayValues.join(', ')
}
