<template>
  <v-select
    v-model="inputValue"
    ref="input"
    clear-icon="mdi-close"
    :hide-details="hideDetails"
    :items="items"
    :rules="validationRules"
    :loading="loading"
    :disabled="disabled"
    :menu-props="menuProps"
    :clearable="clearable"
    :outlined="outlined"
    :readonly="readOnly"
    :solo="solo"
    :flat="flat"
    :dense="dense"
    :prefix="prefix"
    :class="{
      'platform-select--no-caret': hideArrow
    }"
  >
    <input-label slot="label" :label="label" :required="required" :icon="icon" />
    <template v-if="$slots.selection || $scopedSlots.selection" #selection="{ item }">
      <slot
        name="selection"
        :item="item"
      />
    </template>
    <template v-if="$slots.item || $scopedSlots.item" #item="{ on, attrs, parent, item }">
      <slot
        name="item"
        :on="on"
        :attrs="attrs"
        :parent="parent"
        :item="item"
      />
    </template>
  </v-select>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { Validators } from '@/helpers/validation'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'
import { IconNames } from '@/components/shared/PlatformIcon.vue'

export default Vue.extend({
  name: 'PlatformSelectField',
  components: {
    InputLabel
  },
  props: {
    value: { type: String, required: false },
    items: { type: Array, required: true },
    label: { type: String, required: false },
    loading: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    rules: { type: Array, required: false },
    required: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false },
    hideArrow: { type: Boolean, default: false },
    solo: { type: Boolean, default: false },
    flat: { type: Boolean, default: false },
    dense: { type: Boolean, default: false },
    menuProps: { type: Object, required: false },
    prefix: { type: String, required: false },
    icon: { type: String as PropType<IconNames>, required: false }
  },
  computed: {
    inputValue: {
      set (val: string): void {
        this.$emit('input', val)
      },
      get (): string {
        return this.value
      }
    },
    validationRules (): any[] {
      const rules: any[] = []
      if (this.required) {
        rules.push(Validators.required)
      }
      if (Array.isArray(this.rules)) {
        rules.push(...this.rules)
      }
      return rules
    }
  },
  methods: {
    validate (force: boolean = false): void {
      const input: any = this.$refs.input
      input.validate(force)
    }
  }
})
</script>

<style scoped lang="scss">
.platform-select--no-caret::v-deep .v-input__icon--append {
  display: none;
}

</style>
