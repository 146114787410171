<template>
  <v-row no-gutters align="center" justify="end" class="status-indicator flex-nowrap">
    <div class="statuses mr-1">
      <span v-if="!iconOnly && loadingContent" class="status" :class="{ hidden: !loading || warning }">{{ loadingContent }}</span>
      <span v-if="!iconOnly" class="status alternate" :class="{ hidden: !saved || warning }">{{ $t('platformStatusIndicator.savedContent') }}</span>
      <span
        v-if="!iconOnly && warningContent"
        v-tooltip="warningTooltip"
        class="status alternate warning--text"
        :class="{ hidden: !warning }"
      >
        {{ warningContent }}
      </span>
    </div>
    <div class="icons">
      <div class="icon" :class="{ hidden: !loading || warning }" style="padding-right: 1px" >
        <platform-spinner
          color="grey"
          :size="14"
          :width="2"
        />
      </div>
      <div class="icon alternate" :class="{ hidden: !saved || warning }">
        <platform-icon
          name="check-outline"
          color="success"
          :size="16"
        />
      </div>
      <div class="icon alternate" :class="{ hidden: !warning }">
        <platform-icon
          v-tooltip="warningTooltip"
          name="alert-outline"
          color="warning"
          :size="16"
        />
      </div>
    </div>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

export default Vue.extend({
  name: 'PlatformStatusIndicator',
  props: {
    iconOnly: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      required: false
    },
    saved: {
      type: Boolean,
      required: false
    },
    warning: {
      type: Boolean,
      required: false
    },
    loadingContent: {
      type: String,
      required: false
    },
    warningContent: {
      type: String,
      required: false
    },
    warningTooltip: {
      type: String,
      required: false
    }
  },
  components: {
    PlatformSpinner
  }
})
</script>

<style lang="scss" scoped="scoped">
.status-indicator {
  font-size: 12px;

  .icons {
    position: relative;
    height: 16px;
  }

  .statuses {
    position: relative;
    height: 16px;
    line-height: 16px;
    color: var(--v-grey-base);
    white-space: nowrap;
  }

  .icons .icon,
  .statuses .status {
    transition: opacity ease-in-out 250ms;
    opacity: 1;

    &.alternate {
      position: absolute;
      right: 0;
      top: 0;
    }

    &.hidden {
      opacity: 0;
    }
  }
}
</style>
