import { ResultType } from '../../../types/ResultSet'
import { isTargetMultiTextResult } from './isMultiTextResult'
import { MultiTextGroup } from '../Question/index'

export default function shouldRenderMultiTextResultResponses (result: ResultType): boolean {
  if (result.data.directReportOutput !== undefined) {
    return result.data.directReportOutput
  }

  if (result.type === 'responses') {
    const isStrengthsAndSituResult: boolean = isTargetMultiTextResult(result, [MultiTextGroup.Strengths, MultiTextGroup.Situations])
    const isMoreLessMultiTextResult: boolean = isTargetMultiTextResult(result, [MultiTextGroup.More, MultiTextGroup.Less])
    return isStrengthsAndSituResult || isMoreLessMultiTextResult
  }

  return false
}
