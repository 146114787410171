<template>
  <platform-button
    primary
    v-tooltip="isSelected ? $t('shared.manageCompanyButton.companySelectedTooltip') :  $t('shared.manageCompanyButton.companyManageTooltip')"
    :text="isSelected"
    :icon="isSelected ? 'check-circle' : 'company'"
    @click.stop="manageCompany"
  >
    {{ buttonText }}
  </platform-button>
</template>

<script lang='ts'>
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import { Company } from '@/API'

export default Vue.extend({
  name: 'ManageCompanyButton',
  props: {
    company: { type: Object as PropType<Company>, required: true }
  },
  computed: {
    ...mapState('Company', ['list', 'selectedCompany']),
    buttonText (): string {
      return this.isSelected ? this.$t('shared.manageCompanyButton.selected') as string : this.$t('shared.manageCompanyButton.manage') as string
    },
    isSelected (): boolean {
      return this.company.id === this.selectedCompany.id
    }
  },
  methods: {
    ...mapActions('Company', ['selectCompany']),
    manageCompany (): void {
      this.selectCompany(this.company)
        .then(() => {
          this.$emit('select')
        })
    }
  }
})
</script>
