<template>
  <span>{{ displayDate }}</span>
</template>

<script lang="ts">
import Vue from 'vue'
import dayjs from 'dayjs'

export const ShortDateTimeDisplayFormat = 'DD/MM/YYYY h:mma'
export const LongDateTimeDisplayFormat = 'MMMM D YYYY h:mma'
export const LongDateDisplayFormat = 'MMMM D YYYY'
export const ShortDateDisplayFormat = 'DD-MM-YYYY'

export default Vue.extend({
  props: {
    dateTime: String,
    concise: { type: Boolean, default: false }
  },
  computed: {
    displayDate (): string {
      return dayjs(this.dateTime).format(this.concise ? ShortDateTimeDisplayFormat : LongDateTimeDisplayFormat)
    }
  }
})
</script>
