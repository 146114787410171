<template>
  <div>
    <template v-if="!reportSection || loading">
      <platform-spinner
        class="mt-4 mx-auto"
      />
    </template>
    <template v-else>
      <template v-if="resultGroup.results">
        <display-result
          v-for="(subResult, index) in resultGroup.results"
          :key="`subresult-${index}`"
          :result="subResult"
          :section="section"
          :config="getResultConfig(subResult, false, subResult.tickLabels)"
          :report-section="reportSection"
          :padded="!flex"
          @updateReportSection="(payload) => $emit('updateReportSection', payload)"
        />
      </template>
      <template v-else>
        <display-result
          :result="resultGroup"
          :section="section"
          :config="getResultConfig(resultGroup)"
          :report-section="reportSection"
          :padded="!flex"
          @updateReportSection="(payload) => $emit('updateReportSection', payload)"
        />
      </template>
      <v-row v-if="resultGroup.legendResult" no-gutters class="shrink results-legend" :class="{ 'pt-12': !flex }">
        <v-col class="result-legend">
          <display-result
            :result="resultGroup.legendResult"
            :section="section"
            :report-section="reportSection"
            :padded="!flex"
            :config="getResultConfig(resultGroup.legendResult, true)"
          />
        </v-col>
      </v-row>
      <div v-if="resultGroup.tip" class="results-tip text-center highlight--text text-body-2 pb-1">
        <span>{{ resultGroup.tip }}</span>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { RepeatForOptions } from '@/helpers/questionnaire/questions'
import { PlatformSection } from '@betterboards/shared/types/Platform'
import { ResultGroup } from '@/types/ResultSet'
import { GraphConfig } from '@betterboards/graphs/variants/Abstract'
import DisplayResult from '@/components/analysis/tabs/DisplayResult.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { ProgressSection, ResultSet } from '@betterboards/shared/types/ResultSet'
import {
  getDefaultGraphConfig,
  getGraphResultConfig
} from '@/helpers/questionnaire/results'
import { CompanyConfiguration, PlatformCompany } from '@betterboards/shared/types/Company'

export default Vue.extend({
  name: 'DisplayResultGroup',
  props: {
    resultGroup: {
      type: Object as PropType<ResultGroup>
    },
    reportSection: {
      type: Object as PropType<any>,
      required: false
    },
    section: {
      type: Object as PropType<PlatformSection>
    },
    config: {
      type: Object as PropType<Partial<GraphConfig>>
    },
    loading: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PlatformSpinner,
    DisplayResult
  },
  data: () => ({
    RepeatForOptions
  }),
  computed: {
    resultSetSections (): ProgressSection[] {
      return this.$store.getters['Report/resultSetSections']
    },
    resultSetSection (): ProgressSection | undefined {
      return this.resultSetSections?.find((s) => s.id === this.section.id)
    },
    selectedCompany (): PlatformCompany {
      return this.$store.state.Company.selectedCompany
    },
    companyConfiguration (): CompanyConfiguration {
      return this.selectedCompany.configuration ?? {}
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    },
    defaultGraphConfig (): any | undefined {
      const config: any = {
        scale: 1,
        comparison: !!this.comparisonResultSet,
        ...getDefaultGraphConfig(this.companyConfiguration),
        ...this.config
      }
      return config
    }
  },
  methods: {
    getResultConfig (result: ResultGroup, legend: boolean = false, tickLabels: boolean = false): any | undefined {
      return getGraphResultConfig(
        result,
        this.defaultGraphConfig,
        this.companyConfiguration,
        legend,
        tickLabels
      )
    }
  }
})
</script>
