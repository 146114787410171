import { VarianceResultData } from '../types/Graph'

export function findVarianceComparisonDataPoint <
  D extends VarianceResultData = VarianceResultData
> (
  targetDataPoint: D,
  datapoints: D[]
): D | undefined {
  return datapoints.find((d: D): boolean => {
    if (targetDataPoint.repeatForCriteriaId && d.repeatForCriteriaId !== targetDataPoint.repeatForCriteriaId) {
      return false
    }
    if (targetDataPoint.masterBlockId && d.masterBlockId === targetDataPoint.masterBlockId) {
      return true
    }
    if (targetDataPoint.questionText && d.questionText === targetDataPoint.questionText) {
      return true
    }
    return false
  })
}
