<template>
  <platform-modal
    close-button
    icon="company"
    :value="showModal"
    :width="780"
    :title="$t('modal.selectCompany.title')"
    :cancel="false"
    @close="close"
  >
    <v-row
      v-for="company in list"
      :key="company.id"
      @click="manageCompany(company)"
      no-gutters
      align="center"
      justify="center"
      class="mb-3 cursor-pointer"
    >
      <v-col :cols="isMobile ? undefined : '2'">
        <platform-avatar
          entity-type="company"
          :entity="company"
          :size="80"
          placeholder-size="xs"
          :border="company.id === selectedCompany.id ? 'primary' : ''"
        />
      </v-col>
      <v-col v-if="!isMobile" cols="6">{{ company.name }}</v-col>
      <v-col v-if="!isMobile" class="text-right">
        <manage-company-button
          :company="company"
          @select="manageCompany(company)"
        />
      </v-col>
      <v-col v-if="isMobile" class="text-center">
        <span>{{ company.name }}</span>
        <div class="pt-2">
          <manage-company-button
            :company="company"
            @select="manageCompany(company)"
          />
        </div>
      </v-col>
    </v-row>
  </platform-modal>
</template>

<script lang="ts">
import PlatformModal from '@/components/PlatformModal.vue'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import ManageCompanyButton from '@/components/companies/ManageCompanyButton.vue'
import { Company } from '@/API'

export default Vue.extend({
  name: 'CompanySelectorModal',
  components: {
    PlatformModal,
    PlatformAvatar,
    ManageCompanyButton
  },
  props: {
    showModal: Boolean
  },
  computed: {
    ...mapState('Company', ['list', 'selectedCompany']),
    isMobile (): boolean {
      return this.$vuetify.breakpoint.xsOnly
    }
  },
  methods: {
    ...mapActions('Company', ['selectCompany']),
    close (): void {
      this.$emit('close')
    },
    manageCompany (company: Company) {
      this.selectCompany(company)
        .then(() => {
          this.close()
        })
    }
  }
})
</script>
