<template>
  <platform-button
    v-tooltip="$t(value ? 'analysis.progress.setUnreviewedTooltip' : 'analysis.progress.setReviewedTooltip')"
    secondary
    default-case
    :icon="value ? 'progress-pencil' : 'check-outline'"
    @click="toggle"
  >
    {{ $t(value ? 'analysis.progress.setUnreviewedAction' : 'analysis.progress.setReviewedAction') }}
  </platform-button>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SignOffButton',
  props: {
    value: {
      type: Boolean
    }
  },
  methods: {
    toggle (): void {
      this.$emit('input', !this.value)
    }
  }
})
</script>
