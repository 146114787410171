<template>
  <div
    v-if="showEmpty || resultResponses.length > 0"
  >
    <v-row v-if="$slots.header" no-gutters>
      <slot name="header" />
    </v-row>
    <v-row>
      <v-col v-if="resultResponses.length === 0">
        <span class="grey--text text--darken-1 text-body-2">
          {{ $t('analysis.responses.noResponses') }}
        </span>
      </v-col>
      <v-col v-else>
        <v-row
          v-for="(response, index) in resultResponses"
          :key="index"
        >
          <v-col class="grow">
            <v-card
              class="mb-3"
            >
              <display-answer
                :response="response"
                :override="responseOverridesMap[response.blockId]"
                :result="result"
                @update="(payload) => $emit('update', payload)"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import {
  PlatformReportSection,
  ReportSectionResult
} from '@/types/Report'
import Vue, { PropType } from 'vue'
import DisplayAnswer from '@/components/analysis/results/answers/DisplayAnswer.vue'
import { GraphResult, ResponsesResult, ResponsesResultResponse } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'DisplayResponses',
  props: {
    result: {
      type: Object as PropType<GraphResult | ResponsesResult | undefined>,
      required: false
    },
    config: {
      type: Object as PropType<any>,
      required: false
    },
    reportSection: {
      type: Object as PropType<PlatformReportSection>,
      required: true
    },
    filter: {
      type: String as PropType<undefined | 'selfEvaluation' | 'nonSelfEvaluation'>,
      required: false
    },
    showEmpty: { // Whether to display anything when there are no responses
      type: Boolean,
      default: false
    }
  },
  components: {
    DisplayAnswer
  },
  data: () => ({
    //
  }),
  computed: {
    resultResponses (): ResponsesResultResponse[] {
      const result = this.result
      if (!result?.data || result.type !== 'responses') {
        return []
      }
      const responses = result.data.responses
      if (this.filter) {
        return responses.filter((r): boolean => {
          switch (this.filter) {
            case 'selfEvaluation':
              return !!r.selfEvaluation
            case 'nonSelfEvaluation':
              return !r.selfEvaluation
          }
          return false
        })
      }
      return responses
    },
    responseOverridesMap (): { [blockId: string]: any } {
      return this.resultResponses.reduce((overrides, response) => {
        overrides[response.blockId as string] = this.reportSection.results.find((o: ReportSectionResult) => {
          if (o.questionId) {
            return o.questionId === response.blockId
          }
          if (!o.blockId) {
            return false
          }
          return o.blockId === response.blockId
        })
        return overrides
      }, {})
    }
  }
})
</script>
