<template>
  <v-menu
    ref="menu"
    v-model="menuActive"
    transition="scale-transition"
    offset-y
    :close-on-content-click="false"
    :content-class="menuClass"
    :max-width="450"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-bind="attrs"
        v-on="on"
        ref="input"
        readonly
        append-icon="mdi-menu-down"
        class="mega-menu__input"
        :class="{ active: menuActive }"
        :value="selectedValue"
        :loading="loading"
        :disabled="disabled || menuActive"
        :rules="inputRules"
        :data-test-target="dataTestTarget"
        @click:append="on.click"
      >
        <input-label slot="label" :label="label" :required="required" />
      </v-text-field>
    </template>
    <template>
      <v-card
        class="mega-menu"
        :min-width="350"
      >
        <v-row
          no-gutters
          align="center"
          class="top-actions flex-nowrap min-width-0 pl-2 pr-3 text-body-2"
        >
          <v-col class="shrink cursor-pointer pr-3 py-2" @click="back">
            <v-row no-gutters align="center" class="flex-nowrap">
              <platform-icon
                name="arrow-left"
                class="mr-1"
                :color="enableBackButton ? 'secondary' : 'grey lighten-2'"
              />
              <span
                class="grey--text"
                :class="[enableBackButton ? 'text--darken-2' : 'text--lighten-1']"
              >
                {{ $t('global.back') }}
              </span>
            </v-row>
          </v-col>
          <v-col class="grow min-width-0">
            <v-fade-transition group class="d-flex justify-end align-center flex-nowrap text-no-wrap overflow-ellipses">
              <template v-for="(layer, index) in layerBreadcrumbs">
                <span
                  :key="index"
                  class="grey--text text--darken-1"
                  :class="{
                    'overflow-ellipses': index < layerBreadcrumbs.length - 1,
                    'font-weight-bold': index === layerBreadcrumbs.length - 1
                  }"
                >
                  {{ layer }}
                </span>
                <span :key="`${index}-divider`" v-if="index < layerBreadcrumbs.length - 1" class="grey--text px-1">
                  >
                </span>
              </template>
            </v-fade-transition>
          </v-col>
        </v-row>
        <v-slide-x-transition group class="choices__container overflow-y-auto overflow-scroll-stable" origin="left center 0" :style="{ height: `${height}px` }">
          <div
            v-for="(layer, index) in selectedLayers"
            :key="index"
            class="choices"
          >
            <v-list-item-group :value="choice">
              <v-list-item
                v-for="(item, index) in layer.items"
                :key="`${item.value}-${index}`"
                class="pl-4 pr-2"
                data-test-target="platformMegaMenuItem"
                :ref="`listItem-${item.value}`"
                :value="item.value"
                @click="selectCategory(item)"
              >
                <v-row no-gutters class="flex-nowrap min-width-0">
                  <v-col class="flex-basis-0 overflow-ellipses py-0">
                    <span class="text-no-wrap">
                      {{ item.text }}
                    </span>
                  </v-col>
                  <v-col v-if="item.items" class="shrink">
                    <platform-icon name="caret-right" color="secondary" />
                  </v-col>
                </v-row>
              </v-list-item>
            </v-list-item-group>
          </div>
        </v-slide-x-transition>
      </v-card>
    </template>
  </v-menu>
</template>

<script lang="ts">
import { MegaMenuItem } from '@/helpers/forms'
import Vue, { PropType } from 'vue'
import { Validators } from '@/helpers/validation'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'

/**
 * A "mega menu" component which works as a dropdown input field showing a list of items, each which may link to a child list.
 * - All values in the `items` array should have a `text`, implementing `MegaMenuItem`
 * - Any item that should open a sub-list should have a `items` key which is an array of `MegaMenuItem`s
 * - Any item that should be selectable should have a unique `value` key
 */
export default Vue.extend({
  name: 'PlatformMegaMenu',
  components: {
    InputLabel
  },
  props: {
    items: { type: Array as PropType<MegaMenuItem[]>, required: true },
    value: { type: String, required: false },
    label: { type: String, required: false },
    loading: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    required: { type: Boolean, default: false },
    stateful: { type: Boolean, default: false },
    height: { type: Number, default: 250 },
    menuClass: { type: String, required: false },
    dataTestTarget: { type: String, required: false }
  },
  data: () => ({
    menuActive: false,
    selectedLayers: <MegaMenuItem[]>[] // Path of current layers selected
  }),
  mounted () {
    this.init()
  },
  watch: {
    menuActive (val): void {
      if (!val) {
        this.validate()
        const input: any = this.$refs.input
        input.isFocused = false
        return
      }
      this.init()
    },
    choice (val) {
      if (val) {
        this.menuActive = false
        this.init()
      }
    },
    loading (val) {
      if (!val) {
        this.init()
      }
    }
  },
  computed: {
    rootLayer (): MegaMenuItem {
      return {
        text: '',
        value: 'root',
        items: this.items
      }
    },
    choice: {
      get (): string | undefined {
        return this.value
      },
      set (val: string) {
        this.$emit('input', val)
      }
    },
    selectedValue (): string | null {
      if (!this.currentLayerItems || !this.choice) {
        return null
      }
      return this.currentLayerItems.find((i) => i.value === this.choice)?.text ?? null
    },
    currentLayerItems (): MegaMenuItem[] | undefined {
      return this.selectedLayers[this.selectedLayers.length - 1]?.items
    },
    layerBreadcrumbs (): string[] {
      return this.selectedLayers
        .map((l) => l.text)
        .filter((l) => !!l)
    },
    enableBackButton (): boolean {
      return this.selectedLayers.length > 1
    },
    displayChoice (): string | undefined {
      if (!this.choice) {
        return undefined
      }
      return this.currentLayerItems?.find((i) => i.value === this.choice)?.text
    },
    inputRules (): any[] | undefined {
      if (!this.required) {
        return undefined
      }
      return [Validators.required]
    }
  },
  methods: {
    init (): void {
      if (!this.items) {
        return
      }
      if (this.choice) {
        this.setSelectedLayers()
        this.scrollToChoice()
        return
      }
      const deleteCount = this.stateful ? 1 : this.selectedLayers.length
      this.selectedLayers.splice(0, deleteCount, this.rootLayer)
    },
    validate (): void {
      const input: any = this.$refs.input
      input.validate(true)
    },
    selectCategory (item: MegaMenuItem): void {
      if (this.loading) {
        return
      }
      if (item.items) {
        this.selectedLayers.push(item)
        return
      }
      if (!item.value) {
        console.error('MegaMenu failed to process item due to missing items/value:', item)
        throw new Error('MegaMenu item has no child items and no value.')
      }
      if (this.choice === item.value) {
        this.menuActive = false
      }
      this.setChoice(item.value)
    },
    setChoice (val: string) {
      this.choice = val
    },
    setSelectedLayers (): void {
      if (this.loading || !this.choice) {
        return
      }
      const found = this.findItemPath(this.items, [this.rootLayer])
      if (found?.path) {
        this.selectedLayers.splice(0, this.selectedLayers.length, ...found.path)
      }
    },
    /** Recursive func to find the path to the current choice */
    findItemPath (items: MegaMenuItem[], path: MegaMenuItem[]): { match: boolean, path: MegaMenuItem[] } {
      const match = !!items.find((i) => {
        if (i.items) {
          const { match, path: newPath } = this.findItemPath(i.items, [...path, i])
          if (match) {
            path = newPath
          }
          return match
        }
        return i.value === this.choice
      })
      return {
        match,
        path
      }
    },
    back (): void {
      if (!this.enableBackButton) {
        return
      }
      this.selectedLayers.pop()
    },
    scrollToChoice (): void {
      if (!this.choice) {
        return
      }
      /** Try wait for element to be added to DOM */
      setTimeout(() => {
        const listItem: any = this.$refs[`listItem-${this.choice}`]?.[0]?.$el
        if (listItem) {
          listItem.scrollIntoView()
        }
      }, 200)
    }
  }
})
</script>

<style lang="scss" scoped>
.mega-menu {
  transition: all 175ms linear;

  .top-actions {
    border-bottom: 1px solid var(--v-grey-lighten2);
  }
  &__input {
    ::v-deep .v-icon {
      transition: transform 200ms linear;
    }
    &.active ::v-deep .v-icon {
      transform: rotate(180deg);
    }
  }
  .choices__container,
  .category {
    transition: all 175ms linear;
  }
  .choices {
    background-color: var(--v-background-lighten1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    min-height: 100%;

    &__container {
      display: block;
      position: relative;
    }
  }
}
</style>
