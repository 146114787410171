<template>
  <div class="question-block-form">
    <v-row :class="[$vuetify.breakpoint.xsOnly ? 'flex-column-reverse' : 'flex-nowrap']">
      <v-col :cols="12" :sm="9" :md="7" :lg="6">
        <v-select
          v-model="questionVariant"
          :items="availableQuestionTypes"
          :disabled="disabled"
          label="Question Type"
        >
          <template v-slot:item="{ item, parent }">
            <v-list-item :disabled="item.disabled" class="pointer-events--auto" @click="item.disabled ? undefined : parent.$emit('select', item.value)">
              <v-row v-tooltip:right="{ content: item.disabledError, nudgeLeft: 300 }">
                <v-col v-tooltip:top="{ content: $t(`form.questionnaire.questions.blankQuestionTooltipMessage.${item.value}`), nudgeLeft: 300 }">
                  {{ item.text }}
                </v-col>
              </v-row>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <v-row class="grow" no-gutters justify="end">
          <div>
            <slot name="actions" />
          </div>
        </v-row>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="formValid" lazy-validation @submit.prevent="">
      <v-form-base
        :model="questionData"
        :schema="schema"
        @click="onInput"
        @input="onInput"
      />
    </v-form>
    <component
      v-if="questionTypeComponent"
      :is="questionTypeComponent"
      v-model="questionData"
      v-bind="questionTypeComponentProps"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import VFormBase from 'vuetify-form-base'
import { MaxFieldLengths, Validators } from '@/helpers/validation'
import {
  getRepeatForPlaceholder,
  QuestionTextField,
  QuestionTypeItems,
  QuestionTypes,
  RepeatForOptions,
  RepeatForItems,
  RepeatForPlaceholderMap,
  TargetGroupItems,
  ScaleCriteriaOptions,
  ScaleCriteriaFriendlyNames
} from '@/helpers/questionnaire/questions'
import { getRepeatForCriteriaFriendlyName } from '@/helpers/questionnaire/results'
import TextQuestionForm from '@/components/forms/blocks/questions/TextQuestionForm.vue'
import MultiTextQuestionForm from '@/components/forms/blocks/questions/MultiTextQuestionForm.vue'
import MultiChoiceQuestionForm from '@/components/forms/blocks/questions/MultiChoiceQuestionForm.vue'

function isWhiteSpace (text) {
  return /\s/.test(text)
}

function appendRepeatForPlaceholder (field = '', placeholder) {
  if (!field || !field.length) {
    return getRepeatForPlaceholder(placeholder)
  }
  if (isWhiteSpace(field.charAt(field.length - 1))) {
    return field + getRepeatForPlaceholder(placeholder)
  }
  return field + ' ' + getRepeatForPlaceholder(placeholder)
}

export default Vue.extend({
  name: 'QuestionBlockForm',
  props: {
    type: { type: String, required: true },
    value: { type: [Object, String], required: false },
    allowed: { type: Array, required: false },
    disabled: { type: Boolean, default: false }
  },
  components: {
    VFormBase
  },
  data: () => ({
    formValid: false
  }),
  mounted () {
    this.checkSelfEvaluationEnabled()
    this.checkQuestionIntroField()
  },
  watch: {
    showEnableSelfEvaluationField () {
      this.checkSelfEvaluationEnabled()
    },
    showQuestionIntroField () {
      this.checkQuestionIntroField()
    },
    questionVariant (val) {
      if (!val?.includes(QuestionTypes.Scale)) {
        this.questionData.criteria = null
      }
    },
    formValid (val) {
      this.$emit('valid', val)
    }
  },
  computed: {
    questionData: {
      get () {
        if (typeof this.value === 'string') {
          return JSON.parse(this.value)
        }
        return {
          repeatTarget: [],
          recipientTarget: [],
          optionsTarget: [],
          ...this.value
        }
      },
      set (val) {
        this.$emit('input', {
          ...this.questionData,
          ...val
        })
      }
    },
    questionVariant: {
      get () {
        const variant = this.questionData?.variant
        if (variant === QuestionTypes.Scale) {
          if (this.questionData.criteria) {
            return `${variant}-${this.questionData.criteria}`
          }
        }
        return variant
      },
      set (val) {
        if (val.includes(`${QuestionTypes.Scale}-`)) {
          const [variant, criteria] = val.split('-')
          const questionData = { ...this.questionData }
          questionData.variant = variant
          questionData.criteria = criteria
          this.questionData = questionData
          return
        }
        const questionData = { ...this.questionData }
        questionData.variant = val
        this.questionData = questionData
      }
    },
    showQuestionIntroField () {
      if (!this.questionData) {
        return false
      }
      if (this.questionData.variant === QuestionTypes.Scale) {
        return true
      }
      return [RepeatForOptions.BoardMembers, RepeatForOptions.Roles, RepeatForOptions.KnowhowAreas].includes(this.questionData.repeatFor)
    },
    showEnableSelfEvaluationField () {
      if (!this.questionData?.repeatFor) {
        return false
      }
      return [RepeatForOptions.BoardMembers, RepeatForOptions.Committees].includes(this.questionData.repeatFor)
    },
    schema () {
      const schema = {
        repeatFor: {
          type: 'select',
          label: 'Repeat For',
          items: RepeatForItems,
          col: { xs: 12, sm: 7, lg: 6, xl: 3 },
          tooltip: this.$t('form.questionnaire.questions.repeatForTooltip'),
          disabled: this.disabled
        }
      }
      if (this.questionData?.repeatFor && [RepeatForOptions.BoardMembers, RepeatForOptions.Committees].includes(this.questionData.repeatFor)) {
        schema.repeatTarget = {
          type: 'BBTargetRules',
          label: this.$t('form.questionnaire.targetRules.editRepeatRulesAction'),
          col: { xs: 12, sm: 5, lg: 6, xl: 3 },
          criteriaValue: this.value?.repeatFor,
          disabled: this.disabled
        }
      }
      if (this.questionData?.repeatFor && this.questionData.repeatFor !== RepeatForOptions.None) {
        schema.groupByCriteriaValue = {
          type: 'switch',
          label: 'Group by Criteria Value',
          tooltip: `Repeated questions will be grouped together by each ${this.repeatForFriendlyNameSingle} value.`,
          col: { xs: 12, sm: 5, lg: 6, xl: 6 },
          disabled: this.disabled
        }
      }
      if (this.questionData?.repeatFor === RepeatForOptions.BoardMembers) {
        schema.targetGroup = {
          type: 'select',
          label: 'Display To',
          items: TargetGroupItems,
          col: { xs: 12, sm: 7, lg: 6, xl: 3 },
          tooltip: this.$t('form.questionnaire.questions.targetGroupTooltip'),
          clearable: true,
          disabled: this.disabled
        }
      }

      if (this.showEnableSelfEvaluationField) {
        schema.selfEvaluationEnabled = { type: 'switch', label: 'Enable Self Evaluation', col: { xs: 12, sm: 5, lg: 6, xl: 3 }, disabled: this.disabled }
      }

      const showAddRepeatForPlaceholder = !!this.repeatForPlaceholder

      const PlaceholderButtonColSizes = { xs: 12, md: 5, lg: 4 }
      const TextAreaColSizes = {
        xs: 12,
        md: showAddRepeatForPlaceholder ? (12 - PlaceholderButtonColSizes.md) : 12,
        lg: showAddRepeatForPlaceholder ? (12 - PlaceholderButtonColSizes.lg) : 12
      }
      const maxQuestionTextLength = this.questionData?.selfEvaluationEnabled ? MaxFieldLengths.TextQuestionSelfEvaluation : (this.questionData?.variant === QuestionTypes.Scale ? MaxFieldLengths.ScaleQuestionText : MaxFieldLengths.TextQuestionText)
      const maxQuestionIntroLength = this.questionData?.variant === QuestionTypes.Scale ? MaxFieldLengths.ScaleQuestionIntro : MaxFieldLengths.TextQuestionText
      const addPlaceholderFieldConfig = {
        type: 'btn',
        label: 'Add Placeholder',
        col: PlaceholderButtonColSizes,
        iconLeft: 'mdi-plus',
        class: {
          primary: true,
          'mt-3 ml-2': this.$vuetify.breakpoint.mdAndUp,
          'mb-10': this.$vuetify.breakpoint.smAndDown
        }
      }
      if (this.showQuestionIntroField) {
        schema.questionIntro = {
          type: 'textarea',
          label: 'Question Introduction',
          col: TextAreaColSizes,
          rules: [
            Validators.maxLength(maxQuestionIntroLength)
          ],
          disabled: this.disabled
        }
        if (showAddRepeatForPlaceholder) {
          schema.addRepeatForPlaceholderIntro = {
            ...addPlaceholderFieldConfig,
            tooltip: { label: `Appends the ${this.repeatForFriendlyName} placeholder "[${this.repeatForPlaceholder}]" to the question introduction field.`, top: true },
            disabled: this.disabled
          }
        }
      }

      schema.questionText = {
        ...QuestionTextField,
        col: TextAreaColSizes,
        rules: [
          Validators.required,
          Validators.maxLength(maxQuestionTextLength)
        ],
        disabled: this.disabled
      }
      if (showAddRepeatForPlaceholder) {
        schema.addRepeatForPlaceholderText = {
          ...addPlaceholderFieldConfig,
          tooltip: { label: `Appends the ${this.repeatForFriendlyName} placeholder "[${this.repeatForPlaceholder}]" to the question text field.`, top: true },
          disabled: this.disabled
        }
      }

      if (this.questionData?.selfEvaluationEnabled) {
        // Add self evaluation question intro and text fields with a dynamic label
        let selfEvaluationLabelSuffix
        switch (this.questionData.repeatFor) {
          case RepeatForOptions.BoardMembers:
            selfEvaluationLabelSuffix = '(when answering about themselves)'
            break
          case RepeatForOptions.Committees:
            selfEvaluationLabelSuffix = '(when answering about a committee they are on)'
        }

        if (selfEvaluationLabelSuffix) {
          if (this.showQuestionIntroField) {
            schema.questionIntroSelfEvaluation = {
              ...QuestionTextField,
              col: TextAreaColSizes,
              label: `Question Introduction ${selfEvaluationLabelSuffix}`,
              rules: [
                Validators.maxLength(MaxFieldLengths.ScaleQuestionIntroSelfEvaluation)
              ],
              disabled: this.disabled
            }
            if (showAddRepeatForPlaceholder) {
              schema.addRepeatForPlaceholderIntroSelfEval = {
                ...addPlaceholderFieldConfig,
                tooltip: { label: `Appends the ${this.repeatForFriendlyName} placeholder "[${this.repeatForPlaceholder}]" to the self evaluation question introduction field.`, top: true },
                disabled: this.disabled
              }
            }
          }
          schema.questionTextSelfEvaluation = {
            ...QuestionTextField,
            col: TextAreaColSizes,
            label: `Question Text ${selfEvaluationLabelSuffix}`,
            rules: [
              Validators.maxLength(MaxFieldLengths.ScaleQuestionTextSelfEvaluation)
            ],
            disabled: this.disabled
          }
          if (showAddRepeatForPlaceholder) {
            schema.addRepeatForPlaceholderTextSelfEval = {
              ...addPlaceholderFieldConfig,
              tooltip: { label: `Appends the ${this.repeatForFriendlyName} placeholder "[${this.repeatForPlaceholder}]" to the self evaluation question text field.`, top: true },
              disabled: this.disabled
            }
          }
        }
      }

      schema.recipientTarget = {
        type: 'BBTargetRules',
        label: this.$t('form.questionnaire.targetRules.editRecipientRulesAction'),
        col: { xs: 12, sm: 5, lg: 6, xl: 3 },
        criteriaValue: RepeatForOptions.BoardMembers,
        disabled: this.disabled
      }

      return schema
    },
    availableQuestionTypes () {
      const types = []
      for (const t of QuestionTypeItems) {
        const type = {
          value: t.value,
          text: t.text
        }
        if (this.allowed && !this.allowed.includes(type.value)) {
          type.disabled = true
          type.disabledError = 'Section limit reached for this question type.'
        }
        if (type.value === QuestionTypes.Scale) {
          Object.values(ScaleCriteriaOptions).forEach((criteria) => {
            types.push({
              text: `${ScaleCriteriaFriendlyNames[criteria]} ${type.text}`,
              value: `${type.value}-${criteria}`,
              disabled: type.disabled,
              disabledError: type.disabledError
            })
          })
          continue
        }
        types.push(type)
      }

      return types
    },
    questionTypeComponent () {
      switch (this.questionData?.variant) {
        case QuestionTypes.Text:
          return TextQuestionForm
        case QuestionTypes.MultiText:
          return MultiTextQuestionForm
        case QuestionTypes.Scale:
          return null // Temporarily disabled due to empty schema (which makes VFormBase automatically decide it)
        case QuestionTypes.MultipleChoice:
          return MultiChoiceQuestionForm
        default:
          return null
      }
    },
    repeatForFriendlyName () {
      if (!this.questionData.repeatFor) {
        return undefined
      }
      return getRepeatForCriteriaFriendlyName(this.questionData.repeatFor, true)
    },
    repeatForFriendlyNameSingle () {
      if (!this.questionData.repeatFor) {
        return undefined
      }
      return getRepeatForCriteriaFriendlyName(this.questionData.repeatFor)
    },
    repeatForPlaceholder () {
      if (!this.questionData?.repeatFor) {
        return undefined
      }
      return RepeatForPlaceholderMap[this.questionData.repeatFor]
    },
    questionTypeComponentProps () {
      return {
        disabled: this.disabled
      }
    }
  },
  methods: {
    onInput ({ data, on, key }) {
      if (on === 'input') {
        if (key === 'repeatFor' && this.questionData.repeatFor === RepeatForOptions.None) {
          this.questionData = {
            ...this.questionData,
            repeatFor: undefined
          }
          return
        }
        this.questionData = data
      }

      if (this.repeatForPlaceholder) {
        switch (key) {
          case 'addRepeatForPlaceholderIntro':
            this.questionData = {
              ...this.questionData,
              questionIntro: appendRepeatForPlaceholder(this.questionData.questionIntro, this.repeatForPlaceholder)
            }
            break
          case 'addRepeatForPlaceholderText':
            this.questionData = {
              ...this.questionData,
              questionText: appendRepeatForPlaceholder(this.questionData.questionText, this.repeatForPlaceholder)
            }
            break
          case 'addRepeatForPlaceholderIntroSelfEval':
            this.questionData = {
              ...this.questionData,
              questionIntroSelfEvaluation: appendRepeatForPlaceholder(this.questionData.questionIntroSelfEvaluation, this.repeatForPlaceholder)
            }
            break
          case 'addRepeatForPlaceholderTextSelfEval':
            this.questionData = {
              ...this.questionData,
              questionTextSelfEvaluation: appendRepeatForPlaceholder(this.questionData.questionTextSelfEvaluation, this.repeatForPlaceholder)
            }
            break
        }
      }
    },
    checkSelfEvaluationEnabled () {
      if (!this.questionData) {
        return
      }
      if (!this.showEnableSelfEvaluationField) {
        this.questionData.selfEvaluationEnabled = undefined
        return
      }
      if (this.questionData.selfEvaluationEnabled) {
        return
      }
      this.questionData.selfEvaluationEnabled = false
    },
    checkQuestionIntroField () {
      if (!this.showQuestionIntroField) {
        this.questionData.questionIntro = null
        this.questionData.questionIntroSelfEvaluation = null
      }
    }
  }
})
</script>
