var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.$route.name !== 'Auth')?_c('v-navigation-drawer',{attrs:{"id":"navigation-drawer","permanent":!_vm.mobile,"width":_vm.navWidth,"mini-variant-width":65,"app":"","dark":"","touchless":"","mini-variant":!_vm.mobile && !_vm.expanded,"color":"primary"},model:{value:(_vm.folded),callback:function ($$v) {_vm.folded=$$v},expression:"folded"}},[_c('v-row',{staticClass:"flex-column fill-height flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink"},[_c('v-row',{attrs:{"no-gutters":"","justify":_vm.folded ? 'end' : 'center'}},[_c('v-col',{staticClass:"shrink pt-2",class:{ 'pr-2': _vm.expanded }},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.folded = !_vm.folded}}})],1)],1),_c('div',{staticClass:"overflow-hidden my-3",style:({
          height: _vm.expanded ? `${_vm.navLogosHeight}px` : '0px',
          transition: 'height linear 200ms'
        })},[_c('div',{staticClass:"px-3 pb-3 d-flex flex-column",style:({ width: `${_vm.navWidth}px`, height: `${_vm.navLogosHeight}px` })},[_c('div',{staticClass:"flex-shrink-1"},[_c('router-link',{attrs:{"to":{ name: 'Dashboard' }}},[(_vm.whiteLabelCompanyId && _vm.whiteLabelCompanyLogo)?_c('file-viewer',{staticClass:"mx-auto",attrs:{"object-type":"clientAsset","max-width":"85%","max-height":64,"company-id":_vm.whiteLabelCompanyId,"object-id":_vm.whiteLabelCompanyLogo}}):_c('img',{staticClass:"d-block mx-auto",style:({
                  width: 'auto',
                  maxWidth: '100%',
                  height: '64px',
                }),attrs:{"src":require("@/assets/logo.png"),"alt":"Better Boards Logo"}})],1)],1),_c('a',{staticClass:"grow mt-4 mx-auto py-4 px-8 d-flex flex-column align-center",class:{
              'cursor-default': !_vm.showCompanyChanger
            },on:{"click":_vm.companyLogoAction}},[(_vm.selectedCompanyId)?_c('v-sheet',{staticClass:"white mx-auto",staticStyle:{"border-radius":"6px","height":"80px"}},[_c('file-viewer',{staticClass:"mx-auto",attrs:{"rounded":"","listen":"","object-type":"companyLogo","object-id":_vm.selectedCompanyId,"company-id":_vm.selectedCompanyId,"max-height":80,"max-width":300,"width":"auto","height":"100%"}})],1):_vm._e(),(_vm.showCompanyChanger)?_c('p',{staticClass:"smaller white--text mt-3 mb-0 text--smaller"},[_vm._v(_vm._s(_vm.$t('nav.changeSelectedCompany')))]):_vm._e()],1)])]),_c('v-divider')],1),_c('v-col',{staticClass:"grow overflow-y-auto"},[(_vm.routeCustomSidebar)?_c(_vm.routeCustomSidebar,_vm._b({tag:"component"},'component',{ expanded: _vm.expanded },false)):_vm._e(),(_vm.links)?_c('v-list',{class:{
          'pt-0': _vm.viewingAnalysis
        }},_vm._l((_vm.links),function([icon, text, target, id]){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(target === '/companies' || _vm.selectedCompany),expression:"target === '/companies' || selectedCompany"}],key:icon,attrs:{"exact":"","to":target,"link":"","data-bi-on":"click","data-bi-name":"navbarClick","data-bi-attrs":`target:${target},text:${text}`},on:{"click":function($event){return _vm.handleClickLink(id)}}},[_c('v-list-item-icon',[(_vm.showLinkLoadingSpinner(id))?_c('platform-spinner',{attrs:{"size":"24"}}):_c('platform-icon',{attrs:{"name":icon}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(`route.${text.replace(' ', '')}`))+" ")])],1)],1)}),1):_vm._e()],1),_c('v-divider'),_c('v-col',{staticClass:"shrink my-2"},[_c('v-row',{staticClass:"flex-column flex-nowrap",attrs:{"no-gutters":"","align":"center"}},[(_vm.showWhiteLabelPlatformLogo)?_c('img',{staticClass:"d-block mb-2 px-2",style:({
            maxHeight: '70px',
            maxWidth: _vm.expanded ? undefined : '100%'
          }),attrs:{"src":require("@/assets/images/logo-white-label-white.png"),"alt":"Powered by BetterBoards"}}):_vm._e(),_c('div',{style:({
            width: _vm.expanded ? `${_vm.navWidth}px` : '0 !important',
            transition: 'width linear 200ms',
          })},[_c('p',{staticClass:"footnote d-block text--compact grey--text text--lighten-2 mb-0 px-2 overflow-hidden",style:({
              width: `${_vm.navWidth}px`,
              height: _vm.expanded ? undefined : '0 !important',
              opacity: _vm.expanded ? '1' : '0',
              transition: 'opacity ease-in-out 125ms, width linear 200ms, height linear 200ms'
            })},[(_vm.viewingSurvey)?[_vm._v(" "+_vm._s(_vm.$t('nav.surveyCopyright'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('nav.copyright'))+" ")]],2)])])],1)],1),_c('company-selector-modal',{attrs:{"show-modal":_vm.showCompanyChangerModal},on:{"close":function($event){_vm.showCompanyChangerModal = false}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }