<template>
  <div class="d-flex flex-column overflow-hidden">
    <v-form ref="form" v-model="formValid" @submit.prevent="" class="d-flex flex-column">
      <v-form-base
        ref="questionnaireForm"
        :model="benchmark"
        :schema="schema"
        @input="handleInput"
      />
      <platform-switch
        v-if="showFilterByPackageCategoryField"
        :value="filterByPackageCategoryValue"
        :label="$t('modal.report.config.editScores.editScore.form.filterByPackageCategory')"
        @input="handleFilterByPackageCategoryInput"
      />
      <div v-if="showComparisonSelector">
        <div class="mb-3">
          <span class="grey--text text--darken-1">
            {{ $t('modal.questionnaire.releases.comparisonInstruction') }}
          </span>
        </div>
        <v-card class="survey-group--selector overflow-y-auto pa-2 mx-1 my-1">
          <survey-group-selector
            :value="benchmark.comparisonSurveyGroupId"
            :survey-groups="availableComparisonSurveyGroups"
            @input="handleSurveyGroupSelection"
          />
        </v-card>
      </div>
      <div v-if="showUniverseSelector">
        <div class="universe--selector overflow-y-hidden pt-6">
          <universe-selector
            :value="benchmark.queries"
            :scores="benchmark.scores"
            ref="universeSelector"
            :v2="benchmark.version === '2'"
            @input="(queries) => updateQueries(queries)"
            @queried="(scores) => updateScores(scores)"
          />
        </div>
      </div>
    </v-form>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import { ReportBenchmark, V1UniverseReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import { CompanyStatsCollection } from '@betterboards/shared/helpers/statistics/index'
import { findV2StatsQueryIndex } from '@betterboards/shared/helpers/statistics/queries'
import { StatsResult, V2StatisticsQuery } from '@betterboards/shared/types/Statistic'
import { ResultSet } from '@betterboards/shared/types/ResultSet'
import { PackageCategories } from '@betterboards/shared/types/API'
import {
  isV1ComparisonReportBenchmark,
  isV1ReportBenchmark,
  isV1UniverseReportBenchmark,
  isV2OrganisationReportBenchmark,
  isV2UniverseReportBenchmark
} from '@betterboards/shared/helpers/statistics/ReportBenchmark'
import SurveyGroupSelector from '@/components/questionnaire/releases/SurveyGroupSelector.vue'
import { SurveyGroupProgress } from '@/types/SurveyGroup'
import UniverseSelector from '@/components/analysis/universe/UniverseSelector.vue'
import { UniverseQuery } from '@betterboards/shared/types/Report'
import getUniverseBenchmarkSubTitle from '@betterboards/shared/helpers/statistics/benchmarks/getUniverseBenchmarkSubTitle'

export default Vue.extend({
  name: 'EditReportBenchmarkForm',
  components: {
    UniverseSelector,
    VFormBase,
    SurveyGroupSelector
  },
  props: {
    value: { type: Object as PropType<ReportBenchmark>, required: true }
  },
  data: () => ({
    formValid: false,
    formChanged: false
  }),
  computed: {
    resultSet (): ResultSet {
      return this.$store.state.Analysis.resultSet
    },
    availableComparisonSurveyGroups (): SurveyGroupProgress[] {
      return this.$store.getters['Analysis/availableComparisonSurveyGroups']
    },
    benchmark: {
      get (): ReportBenchmark {
        return {
          ...this.value
        }
      },
      set (val: ReportBenchmark): void {
        this.$emit('input', val)
      }
    },
    schema (): any {
      return {
        title: {
          type: 'BBTextField',
          label: this.$t('modal.report.config.editScores.editScore.form.title') as string,
          required: true,
          col: {
            xs: 12,
            sm: 6
          }
        },
        subTitle: {
          type: 'BBTextField',
          label: this.$t('modal.report.config.editScores.editScore.form.subTitle') as string,
          col: {
            xs: 12,
            sm: 6
          }
        }
      }
    },
    showComparisonSelector (): boolean {
      return isV1ComparisonReportBenchmark(this.benchmark)
    },
    isV1UniverseReportBenchmark (): boolean {
      return isV1UniverseReportBenchmark(this.benchmark)
    },
    isV2UniverseReportBenchmark (): boolean {
      return isV1UniverseReportBenchmark(this.benchmark)
    },
    showUniverseSelector (): boolean {
      return isV1UniverseReportBenchmark(this.benchmark) || isV2UniverseReportBenchmark(this.benchmark)
    },
    showFilterByPackageCategoryField (): boolean {
      return isV2OrganisationReportBenchmark(this.benchmark)
    },
    filterByPackageCategoryValue (): boolean {
      const benchmark: ReportBenchmark = this.benchmark
      if (isV1ReportBenchmark(benchmark)) {
        return false
      }
      const queries: V2StatisticsQuery[] | undefined = benchmark.queries
      if (!queries?.length) {
        return false
      }
      const packageCategoryQueries: V2StatisticsQuery[] = this.getPackageCategoryTagQueries(this.resultSet.tags)
      if (!packageCategoryQueries.length) {
        return false
      }
      return packageCategoryQueries.every((query: V2StatisticsQuery): boolean => {
        return findV2StatsQueryIndex(queries, query) !== -1
      })
    }
  },
  methods: {
    handleInput ({ data }): void {
      this.formChanged = true

      const benchmark = {
        ...this.benchmark,
        title: data.title,
        subTitle: data.subTitle
      }

      this.benchmark = benchmark
    },
    handleSurveyGroupSelection (surveyGroupId: string): void {
      this.benchmark = {
        ...this.benchmark,
        comparisonSurveyGroupId: surveyGroupId
      }
    },
    handleFilterByPackageCategoryInput (filterByPackageCategory: boolean): void {
      if (!this.benchmark || !isV2OrganisationReportBenchmark(this.benchmark) || !this.benchmark.queries) {
        return
      }
      const queries: V2StatisticsQuery[] = [...this.benchmark.queries]
      const benchmark: ReportBenchmark = {
        ...this.benchmark,
        queries
      }

      const packageCategoryQueries: V2StatisticsQuery[] = this.getPackageCategoryTagQueries(this.resultSet.tags)
      // Currently Packages only ever have one category so just add one query:
      packageCategoryQueries.forEach((query: V2StatisticsQuery): void => {
        const index: number = findV2StatsQueryIndex(queries, query)
        if (filterByPackageCategory) {
          if (index !== -1) {
            console.warn('Target query already exists, no need to create.')
            return
          }
          queries.push(query)
          return
        }
        if (index === -1) {
          console.warn('Target query does not exist, unable to disable.')
          return
        }
        queries.splice(index, 1)
      })

      this.benchmark = benchmark
    },
    getPackageCategoryTagQueries (tags: string[]): V2StatisticsQuery[] {
      // Extract the PackageCategory tags and convert them to v2 Stats Queries
      const packageCategories: PackageCategories[] = tags
        .filter((t: string): boolean => t.startsWith(`${CompanyStatsCollection.PackageCategory}#`))
        .map((t: string): PackageCategories => {
          const tagParts = t.split('#')
          return tagParts[1] as PackageCategories // Value of Package Category
        })

      return packageCategories.map((category: PackageCategories) => ({
        field: CompanyStatsCollection.PackageCategory,
        value: category
      }))
    },
    updateScores (scores: StatsResult[]): void {
      this.benchmark = {
        ...this.benchmark,
        scores
      }
    },
    updateQueries (queries: UniverseQuery[]): void {
      if (!isV1UniverseReportBenchmark(this.benchmark)) {
        return
      }
      const benchmark: V1UniverseReportBenchmark = {
        ...this.benchmark,
        queries
      }
      benchmark.subTitle = getUniverseBenchmarkSubTitle(benchmark)
      this.benchmark = benchmark
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.survey-group--selector {
  min-height: 150px;
  max-height: 300px;
}
.universe--selector {
  min-height: 150px;
  max-height: 600px;
}
</style>
