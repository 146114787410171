<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col
        :cols="12"
        :sm="6"
        :md="4"
        :xl="3"
        v-for="individual in individuals"
        :key="individual.id"
        class="d-flex flex-column"
      >
        <v-card outlined class="grow" rounded :elevation="1">
          <v-card-text>
            <v-row class="flex-md-nowrap" justify="end">
              <v-col>
                <span class="text-h5">
                  {{ getFullName(individual) }}
                </span>
              </v-col>
              <v-col class="shrink">
                <platform-avatar
                  entity-type="individual"
                  :entity="individual"
                  :size="60"
                />
              </v-col>
            </v-row>
            <v-progress-linear
              rounded
              class="mt-2 mb-2"
              :value="individual.progress"
              :height="20"
              v-tooltip:top="$t('analysis.responseStatus.individualProgressTooltips', [individual.totals && individual.totals.completedQuestions || 0, individual.totals && individual.totals.questions || 0])"
            >
              <span class="white--text text-caption">
                {{ individual.progress }}%
              </span>
            </v-progress-linear>
            <individual-response-totals
              v-if="individual.totals"
              class="mb-2"
              :totals="individual.totals"
            />
            <v-row no-gutters v-if="individual.finishedAt || (individual.totals && individual.totals.questions)" class="flex-column">
              <v-col v-if="individual.finishedAt || (individual.surveyUpdatedAt && individual.progress)">
                <template v-if="individual.finishedAt">
                  <div class="text-caption">
                    <span class="font-weight-bold secondary--text">{{ $t('analysis.systemPages.responseStatus.completed') }}</span>
                    {{ $t('analysis.systemPages.responseStatus.at') }}
                  </div>
                  <platform-date-display
                    v-if="individual.finishedAt"
                    :date-time="individual.finishedAt"
                    class="d-block text--compact font-weight-bold grey--text text--darken-2"
                  />
                </template>
                <template v-else-if="individual.surveyUpdatedAt && individual.progress">
                  <div class="text-caption">{{ $t('analysis.systemPages.responseStatus.lastUpdatedAt') }}</div>
                  <platform-date-display
                    v-if="individual.surveyUpdatedAt"
                    :date-time="individual.surveyUpdatedAt"
                    class="d-block text--compact font-weight-bold grey--text text--darken-2"
                  />
                </template>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { getFullName } from '@/helpers/individual'
import { SurveyStatus } from '@/API'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import PlatformDateDisplay from '@/components/PlatformDateDisplay.vue'
import { ResultsIndividual } from '@betterboards/shared/types/ResultSet'
import IndividualResponseTotals from '@/components/analysis/individuals/IndividualResponseTotals.vue'

interface ResponseStatusIndividual extends ResultsIndividual {
  progress?: number
}

export default Vue.extend({
  name: 'ResponseStatus',
  components: {
    PlatformAvatar,
    PlatformDateDisplay,
    IndividualResponseTotals
  },
  props: {
    results: Object
  },
  data () {
    return {
      SurveyStatus
    }
  },
  computed: {
    ...mapState('Analysis', ['resultSet']),
    individuals (): ResponseStatusIndividual[] {
      return [...this.resultSet.individuals]
        .sort((a, b) => {
          if (a.finishedAt && b.finishedAt) {
            return a.finishedAt.localeCompare(b.finishedAt)
          }
          if (a.finishedAt) {
            return -1
          }
          if (b.finishedAt) {
            return 1
          }
          if (a.surveyUpdatedAt && b.surveyUpdatedAt) {
            return a.surveyUpdatedAt.localeCompare(b.surveyUpdatedAt)
          }
          if (!a.totals?.completedQuestions) {
            return 1
          }
          if (!b.totals?.completedQuestions) {
            return -1
          }
          return 0
        })
        .map((individual: ResponseStatusIndividual) => {
          individual.progress = individual.totals?.completedQuestions && individual.totals.questions
            ? Math.round(individual.totals.completedQuestions / individual.totals.questions * 100)
            : 0
          return individual
        })
    }
  },
  methods: {
    ...mapActions('Survey', ['fetchReleases']),
    getFullName
  }
})
</script>
