<template>
  <v-card class="pa-5 fill-height">
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            <div class="overview-header">
              {{ $t('analysis.results.sectionTitle') }}
            </div>
          </th>
          <th
            v-for="(column, index) in overviewHeaderItems"
            :key="index"
            class="text-center"
          >
            <div class="overview-header">
              <div>
                {{ column.title }}
              </div>
              <div v-if="column.subTitle" class="text-caption">
                {{ column.subTitle }}
              </div>
            </div>
          </th>
        </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) in overviewItems"
            :key="rowIndex"
            :style="{ 'background-color': getRowColor(rowIndex)}"
          >
            <td
              class="py-4"
              :style="{ 'background-color': Colors.grey.lighter }"
            >
              {{ row.name }}
              <span v-if="row.criteria" class="text-no-wrap">
                –
                {{ row.criteria }}
              </span>
            </td>
            <template v-if="row.findings">
              <td :colspan="columnCount" class="px-3 py-4">
                <template v-if="row.findingsTitle">
                  {{ row.findingsTitle }} –
                </template>
                <span class="font-weight-bold">
                  {{ row.findings }}
                </span>
              </td>
            </template>
            <template v-else>
              <td
                v-for="(score, scoreIndex) in row.scores"
                :key="scoreIndex"
                class="font-weight-bold text-center px-3 py-4"
              >
                <template v-if="score && score.value">
                  {{ score.value }}
                </template>
                <template v-else>
                  -
                </template>
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import { ResultSet } from '@betterboards/shared/types/ResultSet'
import { PlatformReport, PlatformReportGroup } from '@betterboards/shared/types/Report'
import { ScoreCollectionGroup, ReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import buildOverviewItems, { buildOverviewHeaderItems, OverviewHeaderItem, OverviewItem } from '@betterboards/shared/helpers/entities/Result/buildOverviewItems'
import { Colors } from '@betterboards/shared/helpers/entities/Company/colors'

export default Vue.extend({
  name: 'ResultsOverview',
  data: () => ({
    Colors
  }),
  props: {
    results: {
      type: Object as PropType<ResultSet>,
      required: true
    },
    report: {
      type: Object as PropType<PlatformReport>,
      required: true
    },
    loadingComparisonData: {
      type: Boolean as PropType<boolean | undefined>,
      required: false
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany']),
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    },
    selectedReportGroup (): PlatformReportGroup | null {
      return this.$store.getters['Report/selectedReportGroup']
    },
    selectedReport (): PlatformReport {
      return this.$store.getters['Report/selectedReport']
    },
    enabledReportBenchmarks (): ReportBenchmark[] {
      return this.$store.getters['Report/enabledReportBenchmarks']
    },
    sectionScores (): ScoreCollectionGroup[] | null {
      return this.$store.getters['Analysis/sectionScores']
    },
    overviewHeaderItems (): OverviewHeaderItem[] {
      return buildOverviewHeaderItems(this.results, this.enabledReportBenchmarks, this.selectedReport)
    },
    overviewItems (): OverviewItem[] {
      return buildOverviewItems(this.results.sections, this.enabledReportBenchmarks)
    },
    columnCount (): number {
      const scoreItem: OverviewItem | undefined = this.overviewItems.find(
        (i: OverviewItem): boolean => !!i.scores?.length
      )
      return scoreItem?.scores?.length ?? 0
    },
    showComparisonScores (): boolean {
      return !!this.comparisonResultSet
    },
    systemAverageTitle (): string {
      return this.report?.configuration?.systemAverageTitle ?? this.$t('analysis.results.betterBoardsAverageText') as string
    },
    comparisonAverageTitle (): string {
      return this.$t('analysis.results.comparisonAverageText') as string
    },
    sectionAverageTitle (): string {
      return this.report?.configuration?.sectionAverageTitle ?? this.$t('analysis.results.averageBoardText') as string
    }
  },
  methods: {
    getRowColor (rowIndex: number): string {
      return rowIndex % 2 === 0 ? Colors.grey.lightest : Colors.white
    }
  }
})
</script>

<style lang="scss">
.overview-header {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-items: flex-end;
}
dl {
  dt, dd {
    margin-top: 10px;
  }
  dd {
    margin-left: 20px;
  }
}
</style>
