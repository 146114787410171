<template>
  <div class="pb-15" :class="{
    'px-15': $vuetify.breakpoint.smAndUp,
    'px-4': $vuetify.breakpoint.xsOnly
  }">
    <create-company-modal
      v-if="creatingCompany"
      @close="creatingCompany = false"
    />
    <v-row>
      <v-col>
        <v-card
          v-if="!selectedCompany"
          class="mx-auto mb-6"
          outlined
        >
          <v-card-title>Welcome to the Better Boards Portal!</v-card-title>
          <v-card-text>
            <p>Better Boards is an independent consultancy specialised in developing governing boards, Executive
              Committees and executives that work with boards and aspire to a board position.</p>
            <p>Better Boards uses for all its services – Onboarding, Board Evaluation, Board Reviews and Board
              Retreats – innovative, evidence-based, bespoke solutions. Our approach is proven, efficient, yet
              rigorous. It allows business continuity without disruption providing insights as well as practical and
              actionable solutions for the individual and collective.</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="selectedCompany">
      <h2 class="primary-header mt-8 mb-6">{{ $t('page.dashboard.header') }}</h2>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.label"
          :cols="12"
          :md="6"
        >
          <v-card
            hover
            class="d-flex flex-row align-stretch cursor-pointer"
            :style="{ minHeight: '120px' }"
            @click="$router.push(card.to)"
          >
            <v-row no-gutters align="center" class="shrink">
              <v-col
                class="grow pl-6 pr-3 pl-md-4 pr-md-3 pl-lg-7 pr-lg-3"
              >
                <platform-icon
                  :name="card.icon"
                  :size="iconSize"
                  color="primary"
                />
              </v-col>
            </v-row>
            <v-row no-gutters align="center">
              <v-col class="grow text-left pr-4">
                <span class="highlight--text font-weight-bold text-body-1 text-md-body-2 text-lg-body-1">{{ card.label }}</span>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="shrink card-action">
              <v-col
                class="grow px-3 px-md-1 px-lg-3"
              >
                <platform-icon
                  name="caret-right"
                  color="secondary"
                  :size="40"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import CreateCompanyModal from '@/components/companies/CreateCompanyModal'

export default Vue.extend({
  name: 'Dashboard',
  components: {
    CreateCompanyModal
  },
  data: () => ({
    creatingCompany: false
  }),
  computed: {
    ...mapState('Company', ['selectedCompany', 'list']),
    ...mapState('User', ['individual']),
    cards () {
      return [
        {
          label: this.$t('page.dashboard.viewSampleQuestionnaire'),
          icon: 'sample-questionnaire',
          to: { name: 'SampleSurvey' }
        },
        {
          label: this.$t('page.dashboard.viewSampleReport'),
          icon: 'sample-report',
          to: { name: 'PdfViewer', query: { file: 'BetterBoardsDemoData.pdf' } }
        },
        {
          label: this.$t('page.dashboard.startQuestionnaireWizard'),
          icon: 'create-questionnaire',
          to: {
            name: 'QuestionnaireWizard'
          }
        },
        {
          label: this.$t('page.dashboard.checkResponseStatus'),
          icon: 'check-response',
          to: {
            name: 'ResponseStatusList'
          }
        },
        {
          label: this.$t('page.dashboard.analyseResponses'),
          icon: 'analyse-response',
          to: {
            name: 'QuestionnaireResponseAnalysis'
          }
        },
        {
          label: this.$t('page.dashboard.sendReports'),
          icon: 'send-reports',
          to: {
            name: 'QuestionnairesReportList'
          }
        }
      ]
    },
    selectedItem: {
      get () {
        if (!this.selectedCompany) {
          return []
        }
        return this.selectedCompany
      },
      set (value) {
        if (!value) {
          return
        }
        this.selectCompany({ id: value })
      }
    },
    companyList () {
      return this.$store.state.Company.list.filter((company) => company.id && company.name)
    },
    iconSize () {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 80
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        return 60
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 65
      }
      return 60
    }
  },
  methods: {
    ...mapActions('Company', ['selectCompany', 'clearSelectedCompany'])
  }
})
</script>

<style lang="scss" scoped>
.card-action {
  border-left: 1px solid var(--v-grey-lighten1);
  border-radius: 0 !important;
}
</style>
