import { PartialReportBenchmark, ReportBenchmark, V1OrganisationReportBenchmark, V2ReportBenchmarkCondition } from '@betterboards/shared/types/ReportBenchmark'
import { ScoreCollection } from '@betterboards/shared/helpers/entities/Result/scores'
import { getReportBenchmarkDisplayInfo } from '@betterboards/shared/helpers/statistics/getReportBenchmarkDisplayInfo'
import { findReportBenchmark, isV1OrganisationReportBenchmark } from '@betterboards/shared/helpers/statistics/ReportBenchmark'
import { CompanyStatsCollection } from '@betterboards/shared/helpers/statistics/index'
import { PlatformCompany } from '@betterboards/shared/types/Company'
import { buildOrganisationV2StatsQuery } from '@betterboards/shared/helpers/statistics/queries'

export default function buildOrganisationBenchmarkOptions (
  existingBenchmarks: ReportBenchmark[],
  selectedCompany: PlatformCompany,
  companiesList: PlatformCompany[]
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  availableBenchmarks.push(
    ...buildV1OrganisationBenchmarkOptions(
      existingBenchmarks,
      selectedCompany,
      companiesList
    )
  )

  availableBenchmarks.push(
    ...buildV2OrganisationBenchmarkOptions(
      existingBenchmarks,
      selectedCompany,
      companiesList
    )
  )

  return availableBenchmarks
}

export function buildV1OrganisationBenchmarkOptions (
  existingBenchmarks: ReportBenchmark[],
  selectedCompany: PlatformCompany,
  companiesList: PlatformCompany[]
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  const existingV1OrganisationBenchmark: V1OrganisationReportBenchmark | undefined = existingBenchmarks.find(isV1OrganisationReportBenchmark)
  const organisationCompanyId: string | undefined = selectedCompany.parentId ?? undefined

  if (!organisationCompanyId) {
    return availableBenchmarks
  }

  const organisationCompany: PlatformCompany | undefined = companiesList.find(
    (c: PlatformCompany): boolean => c.id === organisationCompanyId
  )

  let v1OrganisationBenchmarkData: V1OrganisationReportBenchmark | PartialReportBenchmark | undefined
  if (existingV1OrganisationBenchmark?.companyId === organisationCompanyId) {
    // Existing v1 Org Benchmark matches current Org, show option.
    v1OrganisationBenchmarkData = existingV1OrganisationBenchmark
  } else {
    // Existing v1 Org Benchmark doesn't match current Org, just add placeholder option for current Org Benchmark.
    v1OrganisationBenchmarkData = {
      collection: ScoreCollection.OrganisationAverage,
      version: '1',
      companyId: organisationCompanyId
    }
  }

  const benchmark: ReportBenchmark | undefined = getReportBenchmarkDisplayInfo(
    v1OrganisationBenchmarkData,
    organisationCompany
  )
  if (benchmark) {
    availableBenchmarks.push(benchmark)
  }

  return availableBenchmarks
}

export function buildV2OrganisationBenchmarkOptions (
  existingBenchmarks: ReportBenchmark[],
  selectedCompany: PlatformCompany,
  companiesList: PlatformCompany[]
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  if (selectedCompany.tags?.length) {
    selectedCompany.tags.forEach((tag: string): void => {
      if (tag.includes(`${CompanyStatsCollection.Organisation}#`)) {
        const tagParts: string[] = tag.split('#')
        const organizationId = tagParts[1]
        const organizationCompany: PlatformCompany | undefined = companiesList.find(
          (c: PlatformCompany): boolean => c.id === organizationId
        )

        const targetScore: V2ReportBenchmarkCondition<ScoreCollection.OrganisationAverage> = {
          collection: ScoreCollection.OrganisationAverage,
          version: '2',
          companyId: organizationId,
          queries: [buildOrganisationV2StatsQuery(organizationId)]
        }
        const existingScore: ReportBenchmark | undefined = existingBenchmarks.length
          ? findReportBenchmark(existingBenchmarks, targetScore)
          : undefined
        const alreadyUsed: boolean = !!existingScore?.enabled
        const scoreDisplayInfo: ReportBenchmark | undefined = getReportBenchmarkDisplayInfo(
          {
            enabled: alreadyUsed,
            companyId: organizationId,
            ...targetScore,
            ...existingScore
          },
          organizationCompany
        )
        if (!scoreDisplayInfo) {
          return
        }
        availableBenchmarks.push(scoreDisplayInfo)
      }
    })
  }

  return availableBenchmarks
}
