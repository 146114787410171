<template>
  <div>
    <v-row justify="center" :class="{ 'mb-1': config.legend }">
      <svg
        ref="graph"
        :style="{
          maxWidth: width ? `${width}px` : undefined,
          maxHeight: height ? `${height}px` : undefined
        }"
        :width="width"
        :height="height"
      />
    </v-row>
    <div v-if="graphFootnote" class="results-tip text-center highlight--text text-body-2 pb-1">
      <span>{{ graphFootnote }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import { createGraph, LineGraph } from '@betterboards/graphs'
import { GraphTypes, MultipleChoiceVoteResult } from '@betterboards/graphs/types/Graph'
import { GraphVote, LineGraphConfig, LineGraphData } from '@betterboards/graphs/variants/LineGraph'
import { DotTypes } from '@/types/Graph'
import { getGraphLegendLabel, LineGraphProperty } from '@/helpers/questionnaire/results'
import { RepeatForOptions } from '@/helpers/questionnaire/questions'
import { normalizeVoteResult } from '@betterboards/shared/helpers/entities/Result/normalizeVoteResult'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { GraphsResultData } from '@betterboards/shared/types/ResultSet'
import getLineGraphFootnote from '@betterboards/shared/helpers/graphs/getLineGraphFootnote'

function DefaultData () {
  return {
    graph: undefined as undefined | LineGraph
  }
}

interface LineGraphBaseResult {
  votes?: MultipleChoiceVoteResult[]
}

interface LineGraphAppraisalResult extends LineGraphBaseResult {
  avg: number
  self: number
  questionId: string
  questionText: string
  questionTextSelfEvaluation: string
  masterBlockId?: string
}

interface LineGraphImportanceResult extends LineGraphBaseResult {
  Importance: number
  Development: number
  ImportanceExternal?: number
  DevelopmentExternal?: number
  repeatForCriteriaId?: string
  repeatForCriteriaValue: string
}

export default Vue.extend({
  name: 'LineGraph',
  props: {
    data: {
      type: Array as PropType<LineGraphAppraisalResult[] | LineGraphImportanceResult[]>,
      required: false
    },
    comparisonData: {
      type: Array as PropType<LineGraphAppraisalResult[] | LineGraphImportanceResult[] | undefined>,
      required: false
    },
    criteria: {
      type: String as PropType<RepeatForOptions>,
      required: false
    },
    config: {
      type: Object as PropType<Partial<LineGraphConfig>>,
      required: false
    }
  },
  data: DefaultData,
  mounted (): void {
    this.renderGraph()
  },
  watch: {
    data: 'renderGraph',
    comparisonData: 'renderGraph',
    'user.defaultLanguage': 'renderGraph'
  },
  computed: {
    ...mapState('User', ['user']),
    width (): number | undefined {
      return 800
    },
    height (): number | undefined {
      if (this.config.legend) {
        if (this.data.some((r) => r.ImportanceExternal || r.DevelopmentExternal)) {
          return 40
        }
        if (this.config.comparison || this.comparisonData) {
          return 40
        }
        return 20
      }
      return undefined
    },
    companyIndividuals (): PlatformIndividual[] {
      return this.$store.getters['Individual/individualsList']
    },
    graphFootnote (): string | undefined {
      if (this.config?.legend) {
        return undefined
      }
      const graphData: GraphsResultData = this.data
      if (!graphData) {
        return undefined
      }

      return getLineGraphFootnote(graphData)
    }
  },
  methods: {
    renderGraph (): any {
      let rowSize = 50
      const config: Partial<LineGraphConfig> & { margin?: any } = {
        ...this.config,
        scale: 0.8
      }

      const rawData: any[] = this.data ?? []
      const appraisalData: LineGraphAppraisalResult[] = rawData.filter((r: any) => !!r.avg || !!r.self)
      const importanceData: LineGraphImportanceResult[] = rawData.filter(
        (r: any) => !appraisalData.includes(r) && (r.Importance || r.Development)
      )
      let data: LineGraphData

      config.votes = rawData.some((r) => !!r.votes)
      if (!config.votesConfig) {
        config.votesConfig = {
          header: this.$t('shared.graphs.line.legend.votesHeader') as string
        }
      }

      if (appraisalData.length || !RepeatForOptions[this.criteria]) {
        rowSize = 70
        config.margin = {
          top: 32,
          bottom: 24,
          left: 288,
          right: 24
        }
        config.lines = [
          {
            property: LineGraphProperty.Average,
            label: getGraphLegendLabel(LineGraphProperty.Average),
            color: 'primary',
            dot: DotTypes.Circle,
            dataLabels: true
          },
          {
            property: LineGraphProperty.Self,
            label: getGraphLegendLabel(LineGraphProperty.Self),
            color: 'secondary',
            dot: DotTypes.Rect,
            dataLabels: true
          }
        ]
        if (this.config.comparison || this.comparisonData) {
          config.lines.push( // Prepend Comparison items to draw them underneath main data
            {
              property: LineGraphProperty.AverageComparison,
              label: getGraphLegendLabel(LineGraphProperty.AverageComparison),
              color: 'primaryAlternateTransparent',
              dot: DotTypes.Dotted,
              dataLabels: false
            },
            {
              property: LineGraphProperty.SelfComparison,
              label: getGraphLegendLabel(LineGraphProperty.SelfComparison),
              color: 'secondaryAlternateTransparent',
              dot: DotTypes.Dash,
              dataLabels: false
            }
          )
        }
        data = appraisalData.map((row) => {
          const comparisonAppraisalResults = this.comparisonData as LineGraphAppraisalResult[] | undefined
          const comparisonResultRow = Array.isArray(comparisonAppraisalResults)
            ? comparisonAppraisalResults.find((r: LineGraphAppraisalResult) => {
              if (r.masterBlockId && row.masterBlockId) {
                return r.masterBlockId === row.masterBlockId
              }
              return r.questionId === row.questionId
            })
            : undefined
          const avgComparison = comparisonResultRow?.[LineGraphProperty.Average]
          const selfComparison = comparisonResultRow?.[LineGraphProperty.Self]

          return {
            type: 'appraisal',
            avg: row.avg,
            self: row.self,
            avgComparison,
            selfComparison,
            category: row.questionText
          }
        })
      } else {
        config.margin = {
          top: config.votes ? 60 : 32,
          bottom: 24,
          left: 160,
          right: config.votes ? 176 : 24
        }
        config.lines = [
          {
            property: LineGraphProperty.Development,
            label: getGraphLegendLabel(LineGraphProperty.Development, this.criteria as RepeatForOptions),
            color: 'primary',
            dot: DotTypes.Circle,
            dataLabels: true
          },
          {
            property: LineGraphProperty.Importance,
            label: getGraphLegendLabel(LineGraphProperty.Importance, this.criteria as RepeatForOptions),
            color: 'secondary',
            dot: DotTypes.Rect,
            dataLabels: true
          }
        ]
        if (this.config.comparison || this.comparisonData) {
          config.lines.push( // Prepend Comparison items to draw them underneath main data
            {
              property: LineGraphProperty.DevelopmentComparison,
              label: getGraphLegendLabel(LineGraphProperty.DevelopmentComparison, this.criteria as RepeatForOptions),
              color: 'primaryAlternateTransparent',
              dot: DotTypes.Dotted,
              dataLabels: false
            },
            {
              property: LineGraphProperty.ImportanceComparison,
              label: getGraphLegendLabel(LineGraphProperty.ImportanceComparison, this.criteria as RepeatForOptions),
              color: 'secondaryAlternateTransparent',
              dot: DotTypes.Dash,
              dataLabels: false
            }
          )
        }
        if (this.data.some((r) => r.ImportanceExternal || r.DevelopmentExternal)) {
          config.lines.push({
            property: LineGraphProperty.DevelopmentExternal,
            label: getGraphLegendLabel(LineGraphProperty.DevelopmentExternal),
            color: 'primary',
            dot: DotTypes.Dash,
            dataLabels: false
          })
          config.lines.push({
            property: LineGraphProperty.ImportanceExternal,
            label: getGraphLegendLabel(LineGraphProperty.ImportanceExternal),
            color: 'secondary',
            dot: DotTypes.Dash,
            dataLabels: false
          })
        }
        data = importanceData.map((row) => {
          const comparisonImportanceResults = this.comparisonData as LineGraphImportanceResult[] | undefined
          const comparisonResultRow = Array.isArray(comparisonImportanceResults)
            ? comparisonImportanceResults.find((r: LineGraphImportanceResult) => {
              if (row.repeatForCriteriaId && r.repeatForCriteriaId) {
                return r.repeatForCriteriaId === row.repeatForCriteriaId
              }
              return r.repeatForCriteriaValue === row.repeatForCriteriaValue
            })
            : undefined

          return {
            type: 'importance',
            importance: row.Importance,
            development: row.Development,
            importanceExternal: row.ImportanceExternal,
            developmentExternal: row.DevelopmentExternal,
            importanceComparison: comparisonResultRow?.Importance,
            developmentComparison: comparisonResultRow?.Development,
            category: row.repeatForCriteriaValue,
            votes: row.votes?.map(
              (v: MultipleChoiceVoteResult): GraphVote => normalizeVoteResult(this.companyIndividuals, v)
            )
          }
        })
      }

      if (this.width) {
        config.width = this.width
      }
      if (this.height) {
        config.height = this.height
      } else {
        config.height = 100 + data.length * rowSize
      }
      const heightOffset = config.lines && config.lines.length > 2 ? 20 : 0
      if (this.config.legend) {
        config.margin = {
          top: -5,
          bottom: 30 + heightOffset,
          left: 0,
          right: 0
        }
      }

      const graph = this.graph = createGraph<GraphTypes.Line>(GraphTypes.Line, this.$refs.graph as SVGElement, config)
      graph.render(data)
    }
  }
})
</script>
