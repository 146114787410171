<template>
  <v-btn
    class="platform-button"
    exact
    :value="value"
    :to="to"
    :loading="loading"
    :disabled="disabled && !allowDisabledClick"
    :depressed="depressed"
    :color="color"
    :outlined="outlined"
    :text="text"
    :rounded="rounded"
    :elevation="0"
    :class="{
      'px-2': !compact && !iconOnly,
      'px-0': iconOnly && compact,
      'px-1': !iconOnly && compact,
      'mx-1 square': iconOnly && !compact,
      'v-btn--disabled pointer-events--auto': disabled && allowDisabledClick,
      'text-none': defaultCase,
      'justify-start': !iconOnly,
      compact,
      'platform-button--primary': primary,
      'platform-button--secondary': secondary
    }"
    :x-small="xSmall"
    :small="small"
    :x-large="large"
    :icon="iconOnly && text"
    :type="inputType"
    @click="(data) => $emit(disabled ? 'disabledClick' : 'click', data)"
  >
    <template #loader>
      <platform-spinner
        :color="loaderColor"
        :size="compact ? 16 : 24"
        :width="compact ? 2 : 3"
      />
    </template>
    <platform-icon
      v-if="icon"
      :color="secondary ? 'primary' : undefined"
      :reverse="iconReversed"
      :class="iconClasses"
      :size="iconSize"
      :name="icon"
    />
    <slot name="default" />
  </v-btn>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

export default Vue.extend({
  name: 'PlatformButton',
  components: { PlatformSpinner },
  props: {
    value: { type: [String, Number], required: false },
    to: { type: Object, required: false },
    icon: { type: String, required: false },
    iconReversed: { type: Boolean, required: false },
    iconSize: { type: [Number, String], required: false },
    forceColor: { type: String, required: false },
    primary: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    warning: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    depressed: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    defaultCase: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    text: { type: Boolean as PropType<Boolean | undefined>, required: false },
    xSmall: { type: Boolean, default: false },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    inputType: { type: String, required: false }
  },
  computed: {
    hasText (): boolean {
      return !!this.$slots.default
    },
    iconOnly (): boolean {
      return !!this.icon && !this.hasText
    },
    color (): string | undefined {
      if (this.forceColor) {
        return this.forceColor
      }
      if (this.primary) {
        return 'primary'
      }
      if (this.secondary) {
        return 'primary'
      }
      if (this.warning) {
        return 'warning'
      }
      return undefined
    },
    loaderColor (): string | undefined {
      if (this.secondary) {
        return 'primary'
      }
      if (this.text) {
        return this.color
      }
      return 'white'
    },
    outlined (): boolean {
      if (this.text) {
        return false
      }
      return this.secondary || this.warning
    },
    iconClasses (): any {
      return {
        'mr-1': this.hasText && (this.xSmall || this.small),
        'mr-2': this.hasText && !this.xSmall && !this.small
      }
    },
    // If a @disabledClick handler is set, emit that event if the user clicks the button while disabled.
    allowDisabledClick (): boolean {
      return !!this.$listeners.disabledClick
    }
  }
})
</script>

<style lang="scss" scoped>
.platform-button {
  touch-action: manipulation;
  &.square {
    width: 24px !important;
  }
  &.compact {
    min-height: 24px !important;
  }
  &--secondary {
    background-color: white;
  }
  &::v-deep {
    &.v-size--small {
      min-width: 28px !important;
      min-height: 28px !important;
    }
    &.v-size--x-small {
      min-width: 24px !important;
      min-height: 24px !important;
    }
  }
}
</style>

<style lang="scss">
.v-card__actions {
  .platform-button__container + .platform-button__container {
    margin-left: 8px;
  }
}
</style>
