<template>
  <platform-modal
    v-model="showModal"
    icon="user"
    :width="800"
    :title="$t('modal.admin.create.title')"
    :saving="pendingCreate"
    :save-disabled="!isValid"
    @save="save"
    @validate="$refs.form.validate()"
  >
    <user-form
      ref="form"
      v-model="user"
      editable-companies
      :company-id="selectedCompany.id"
      :manageable-companies="manageableCompanies"
      @valid="(val) => isValid = val"
    />
  </platform-modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import { DefaultUser } from '@/types/Platform'
import PlatformModal from '@/components/PlatformModal.vue'
import UserForm from '@/components/user/UserForm.vue'
import { trimStringFields } from '@/helpers/validation'

export default Vue.extend({
  name: 'CreateUserModal',
  components: {
    PlatformModal,
    UserForm
  },
  props: {
    manageableCompanies: Array as PropType<{ id: string, accountType: string, name: string }[]>
  },
  data () {
    return {
      showModal: true,
      user: {
        ...DefaultUser(),
        languageCode: 'en'
      },
      pendingCreate: false,
      selectedCompanies: [] as string[],
      isValid: false
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany'])
  },
  watch: {
    showModal (val: boolean): void {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  methods: {
    ...mapActions('User', ['createUser']),
    displayName: (individual) => {
      if (!individual) {
        return undefined
      }

      if (individual.preferredName) {
        return individual.preferredName
      }

      return `${individual.title} ${individual.familyName}`
    },
    async save () {
      if (!this.user || !this.user?.companies?.items) {
        return
      }
      this.pendingCreate = true
      const userData = trimStringFields({
        ...this.user,
        companies: this.user.companies.items
      })
      this.createUser(userData)
        .then((response) => {
          this.showModal = false
          const user = {
            ...this.user,
            id: response.id,
            cognitoId: response.cognitoId
          }
          this.$emit('created', user)
          this.$toasted.success('User created successfully')
        })
        .catch((err) => {
          console.error('Caught an error while trying to create a user:', err)
          this.$toasted.error('Failed to create the user, please try again or contact support.')
        })
        .finally(() => {
          this.pendingCreate = false
        })
    }
  }
})
</script>
