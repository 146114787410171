<template>
  <div>
    <platform-text-area
      v-if="editing"
      class="text-body-2"
      hide-details
      auto-grow
      outlined
      :value="answerOverride"
      :rows="1"
      v-model="answerOverride"
    />
    <div v-else class="text-body-2">
      <span v-if="override && override.value" v-tooltip="`Original: &quot;${response.value}&quot;`">
        {{ answerOverride }}<span class="secondary--text">*</span>
      </span>
      <span v-else>
        {{ response.value }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { QuestionAnswer } from '@/types/Question'
import PlatformTextArea from '@/components/shared/inputs/PlatformTextArea.vue'

export default Vue.extend({
  name: 'TextAnswerDisplay',
  components: {
    PlatformTextArea
  },
  props: {
    response: Object as PropType<QuestionAnswer>,
    editing: { type: Boolean, default: false },
    override: { type: Object as PropType<QuestionAnswer>, required: false }
  },
  data: () => ({
    answerOverride: null
  }),
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.emitOverrides()
  },
  watch: {
    'response.value': 'init',
    override: 'init',
    editing (editing: boolean): void {
      if (!editing) {
        this.emitOverrides()
      }
    }
  },
  methods: {
    init (): void {
      this.answerOverride = this.override?.value || this.response.value
    },
    emitOverrides (): void {
      this.$emit('input', this.answerOverride)
    }
  }
})
</script>
