import { PlatformQuestionnaire, PlatformQuestionnaireVariant, QuestionnaireVariantCode } from '../../../types/Platform'
import { DefaultQuestionnaireVariantName } from '../Questionnaire/index'

export function getQuestionnaireVariantName (
  questionnaire: PlatformQuestionnaire,
  variantCode: QuestionnaireVariantCode | null | undefined
): string | undefined {
  return getVariantName(
    questionnaire.variants,
    questionnaire.name,
    variantCode
  )
}

export function getVariantName (
  variants: PlatformQuestionnaireVariant[],
  name: string,
  variantCode?: QuestionnaireVariantCode | null | undefined
): string | undefined {
  if (!variants?.length || !variantCode) {
    return name
  }
  const targetVariant = variants?.find((v) => v.variantCode === variantCode)
  if (!targetVariant?.name) {
    throw new Error(`Failed to get survey name for variant "${variantCode}" as variant was not found or had no name.`)
  }
  if (targetVariant.name === DefaultQuestionnaireVariantName) {
    return name
  }
  return targetVariant.name
}
