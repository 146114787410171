<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
    <g fill="currentColor">
      <path d="M 15.84 10.2 L 14.83 11.21 L 12.76 9.18 L 13.77 8.16 C 13.97 7.95 14.31 7.94 14.55 8.16 L 15.84 9.41 C 16.05 9.62 16.06 9.96 15.84 10.2 M 8 13.91 L 12.17 9.72 L 14.24 11.8 L 10.08 16 H 8 V 13.91 M 11 2 C 9.04 2.18 7.19 2.95 5.67 4.2 L 7.1 5.74 C 8.22 4.84 9.57 4.26 11 4.06 V 2.06 M 4.26 5.67 C 3 7.19 2.24 9.04 2.05 11 H 4.05 C 4.24 9.58 4.8 8.23 5.69 7.1 L 4.26 5.67 M 2.06 13 C 2.26 14.96 3.03 16.81 4.27 18.33 L 5.69 16.9 C 4.81 15.77 4.24 14.42 4.06 13 H 2.06 M 7.06 18.37 L 5.67 19.74 C 7.18 21 9.04 21.79 11 22 V 20 C 9.58 19.82 8.23 19.25 7.1 18.37 H 7.06 ZM 13 2 C 14.96 2.18 16.81 2.95 18.33 4.2 L 16.9 5.74 C 15.78 4.84 14.43 4.26 13 4.06 V 2.06 M 19.74 5.67 C 21 7.19 21.76 9.04 21.95 11 H 19.95 C 19.76 9.58 19.2 8.23 18.31 7.1 L 19.74 5.67 M 21.94 13 C 21.74 14.96 20.97 16.81 19.73 18.33 L 18.31 16.9 C 19.19 15.77 19.76 14.42 19.94 13 H 21.94 M 16.94 18.37 L 18.33 19.74 C 16.82 21 14.96 21.79 13 22 V 20 C 14.42 19.82 15.77 19.25 16.9 18.37 H 16.94 Z"/>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'NoProgressPencilIcon'
})
</script>
