<template>
  <v-navigation-drawer
    v-if="$route.name !== 'Auth'"
    id="navigation-drawer"
    :permanent="!mobile"
    v-model="folded"
    :width="navWidth"
    :mini-variant-width="65"
    app
    dark
    touchless
    :mini-variant="!mobile && !expanded"
    color="primary"
  >
    <v-row no-gutters class="flex-column fill-height flex-nowrap">
      <v-col class="shrink">
        <v-row no-gutters :justify="folded ? 'end' : 'center'">
          <v-col class="shrink pt-2" :class="{ 'pr-2': expanded }">
            <v-app-bar-nav-icon
              @click="folded = !folded"
            />
          </v-col>
        </v-row>
        <div
          class="overflow-hidden my-3"
          :style="{
            height: expanded ? `${navLogosHeight}px` : '0px',
            transition: 'height linear 200ms'
          }"
        >
          <div
            :style="{ width: `${navWidth}px`, height: `${navLogosHeight}px` }"
            class="px-3 pb-3 d-flex flex-column"
          >
            <div class="flex-shrink-1">
              <router-link :to="{ name: 'Dashboard' }">
                <file-viewer
                  v-if="whiteLabelCompanyId && whiteLabelCompanyLogo"
                  object-type="clientAsset"
                  class="mx-auto"
                  max-width="85%"
                  :max-height="64"
                  :company-id="whiteLabelCompanyId"
                  :object-id="whiteLabelCompanyLogo"
                />
                <img
                  v-else
                  src="@/assets/logo.png"
                  alt="Better Boards Logo"
                  class="d-block mx-auto"
                  :style="{
                    width: 'auto',
                    maxWidth: '100%',
                    height: '64px',
                  }"
                />
              </router-link>
            </div>

            <a
              class="grow mt-4 mx-auto py-4 px-8 d-flex flex-column align-center"
              :class="{
                'cursor-default': !showCompanyChanger
              }"
              @click="companyLogoAction"
            >
              <v-sheet
                v-if="selectedCompanyId"
                class="white mx-auto"
                style="border-radius: 6px; height: 80px;"
              >
                <file-viewer
                  class="mx-auto"
                  rounded
                  listen
                  object-type="companyLogo"
                  :object-id="selectedCompanyId"
                  :company-id="selectedCompanyId"
                  :max-height="80"
                  :max-width="300"
                  width="auto"
                  height="100%"
                />
              </v-sheet>
              <p v-if="showCompanyChanger" class="smaller white--text mt-3 mb-0 text--smaller">{{ $t('nav.changeSelectedCompany') }}</p>
            </a>
          </div>
        </div>

        <v-divider />
      </v-col>
      <v-col class="grow overflow-y-auto">
        <component
          v-if="routeCustomSidebar"
          :is="routeCustomSidebar"
          v-bind="{ expanded }"
        />
        <v-list
          v-if="links"
          :class="{
            'pt-0': viewingAnalysis
          }"
        >
          <v-list-item
            v-for="[icon, text, target, id] in links"
            v-show="target === '/companies' || selectedCompany"
            exact
            :key="icon"
            :to="target"
            link
            data-bi-on='click'
            data-bi-name='navbarClick'
            :data-bi-attrs='`target:${target},text:${text}`'
            @click="handleClickLink(id)"
          >
            <v-list-item-icon>
              <platform-spinner
                size="24"
                v-if="showLinkLoadingSpinner(id)"
              />
              <platform-icon
                v-else
                :name="icon"
              />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t(`route.${text.replace(' ', '')}`) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-divider />
      <v-col
        class="shrink my-2"
      >
        <v-row no-gutters align="center" class="flex-column flex-nowrap">
          <img
            v-if="showWhiteLabelPlatformLogo"
            src="@/assets/images/logo-white-label-white.png"
            alt="Powered by BetterBoards"
            class="d-block mb-2 px-2"
            :style="{
              maxHeight: '70px',
              maxWidth: expanded ? undefined : '100%'
            }"
          />
          <div
            :style="{
              width: expanded ? `${navWidth}px` : '0 !important',
              transition: 'width linear 200ms',
            }"
          >
            <p
              class="footnote d-block text--compact grey--text text--lighten-2 mb-0 px-2 overflow-hidden"
              :style="{
                width: `${navWidth}px`,
                height: expanded ? undefined : '0 !important',
                opacity: expanded ? '1' : '0',
                transition: 'opacity ease-in-out 125ms, width linear 200ms, height linear 200ms'
              }"
            >
              <template v-if="viewingSurvey">
                {{ $t('nav.surveyCopyright') }}
              </template>
              <template v-else>
                {{ $t('nav.copyright') }}
              </template>
            </p>
          </div>
        </v-row>
      </v-col>
    </v-row>
    <company-selector-modal :show-modal="showCompanyChangerModal" @close="showCompanyChangerModal = false" />
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapState } from 'vuex'
import { MetaFlags } from '@/router'
import FileViewer from '@/components/FileViewer.vue'
import SurveySidebar from '@/components/layout/sidebar/SurveySidebar.vue'
import AnalysisSidebar from '@/components/layout/sidebar/AnalysisSidebar.vue'
import CompanySelectorModal from '@/components/companies/CompanySelectorModal.vue'
import handleZendeskAuthRedirect from '@/helpers/handleZendeskAuthRedirect'
import API from '@/services/API'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

export default Vue.extend({
  name: 'PlatformSidebar',
  components: {
    PlatformSpinner,
    CompanySelectorModal,
    SurveySidebar,
    FileViewer,
    AnalysisSidebar
  },
  props: {
    value: { type: Boolean },
    mobile: { type: Boolean }
  },
  data: () => ({
    navLogosHeight: 228, // 64px + 100px + pa-3 + mt-2 + 28px
    showCompanyChangerModal: false,
    loadingKBS: <boolean>false,
    MetaFlags
  }),
  computed: {
    ...mapState('Company', ['selectedCompany']),
    ...mapState('Navigation', ['links']),
    ...mapState('Analysis', ['survey']),
    routeCustomSidebar (): string | undefined {
      return this.$route.matched.find((route) => route.meta?.[MetaFlags.CustomSidebar])?.meta[MetaFlags.CustomSidebar]
    },
    navWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
          return 300
        case 'md':
          return 300
        default:
          return 330
      }
    },
    expanded: {
      get (): boolean {
        if (this.mobile) {
          return true
        }
        return this.value
      },
      set (val: boolean): void {
        this.$emit('input', val)
      }
    },
    folded: {
      get (): boolean {
        return this.value
      },
      set (val: boolean): void {
        this.expanded = val
      }
    },
    selectedCompanyId (): string {
      return this.selectedCompany?.id
    },
    companyConfig (): any {
      return this.selectedCompany?.configuration
    },
    appThemeConfig (): any {
      if (!this.companyConfig?.questionnaire) {
        return
      }
      return this.companyConfig.questionnaire
    },
    whiteLabelCompanyLogo (): string | undefined {
      return this.appThemeConfig?.assets?.logo
    },
    whiteLabelCompanyId (): string | undefined {
      return this.appThemeConfig?.assets?.companyId ?? this.selectedCompanyId
    },
    showWhiteLabelPlatformLogo (): boolean {
      return this.appThemeConfig?.showPoweredBy || false
    },
    showCompanyChanger (): boolean {
      if (this.$route.matched.some((route) => route.meta?.[MetaFlags.HideCompanySwitcher])) {
        return false
      }
      return this.$store.state.Company.list?.length > 1
    },
    viewingSurvey (): boolean {
      if (!this.$route.name) {
        return false
      }
      return ['ViewSurvey', 'PreviewSurvey', 'SampleSurvey'].includes(this.$route.name)
    },
    viewingAnalysis (): boolean {
      if (!this.$route.name) {
        return false
      }
      return this.$route.name.includes('Analysis')
    }
  },
  methods: {
    companyLogoAction (): void {
      if (this.showCompanyChanger) {
        this.showCompanyChangerModal = true
      }
    },
    handleClickLink (id: 'kbs' | undefined): void {
      if (id === 'kbs') {
        this.handleClickKBSLink()
      }
    },
    async handleClickKBSLink (): Promise<void> {
      let response: { token: string }
      this.loadingKBS = true
      try {
        response = await API.post('backendfunctions', '/users/zendesk/auth')
      } catch (err) {
        console.error('Failed to make request to auth user to access KBS.', err)
        this.displayKBSAuthError()
        return
      } finally {
        this.loadingKBS = false
      }
      if (!response.token) {
        console.error('Failed to auth user to access KBS, token is missing from response:', response)
        this.displayKBSAuthError()
        return
      }
      await handleZendeskAuthRedirect(response.token)
    },
    displayKBSAuthError (): void {
      const errorMessage: string = this.$t('global.nav.KBSAuthError') as string
      this.$toasted.error(errorMessage)
    },
    showLinkLoadingSpinner (id: string): boolean {
      return id === 'kbs' && this.loadingKBS
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
#navigation-drawer {
  border-left: 4px solid var(--v-secondary-base) !important;
}

.text--smaller {
  font-size: 90%
}

.footnote {
  font-size: 9px;
}
</style>
