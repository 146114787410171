import { generateSelectItems } from '@/helpers/forms'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { GraphQLError } from 'graphql'
import API from '@/services/API'
import { defaultContentByIdentifier } from '@/graphql/queries'
import { ContentType } from '@/API'
import { PlatformCompany } from '@betterboards/shared/types/Company'

export const fetchCompanySectorOptions = async (): Promise<Array<{ value: string, text: string }>> => {
  const response = await API.graphql({
    query: defaultContentByIdentifier,
    variables: {
      type: ContentType.Statistics,
      key: { eq: 'CompanySector' }
    }
  })
  const content = JSON.parse(response.data.defaultContentByIdentifier.items[0].content)
  return generateSelectItems(content.map((item) => item.id), content.map((item) => item.value))
}

export const fetchCompanyCountryOptions = async (): Promise<Array<{ value: string, text: string }>> => {
  const response = await API.graphql({
    query: defaultContentByIdentifier,
    variables: {
      type: ContentType.Statistics,
      key: { eq: 'Countries' }
    }
  })
  const content = JSON.parse(response.data.defaultContentByIdentifier.items[0].content)
  return generateSelectItems(content.map((item) => item.id), content.map((item) => item.value))
}

export const fetchCompanyEmployeeCountOptions = async (): Promise<Array<{ value: string, text: string }>> => {
  const response = await API.graphql({
    query: defaultContentByIdentifier,
    variables: {
      type: ContentType.Statistics,
      key: { eq: 'EmployeeCount' }
    }
  })
  const content = JSON.parse(response.data.defaultContentByIdentifier.items[0].content)
  return generateSelectItems(content.map((item) => item.id), content.map((item) => item.value))
}

export const fetchCompanyOwnershipOptions = async (): Promise<Array<{ id: string, value: string, country: string }>> => {
  const response = await API.graphql({
    query: defaultContentByIdentifier,
    variables: {
      type: ContentType.Statistics,
      key: { beginsWith: 'OwnershipStructure' }
    }
  })
  const content = JSON.parse(response.data.defaultContentByIdentifier.items[0].content)
  return content.map(({ id, value, country }) => ({
    value: id,
    text: value,
    country
  }))
}

export function sortCompanies (a: PlatformCompany, b: PlatformCompany): number {
  return sortByCreatedAt(a, b)
}

export function sortCompaniesList (selectedCompany: PlatformCompany): (a: PlatformCompany, b: PlatformCompany) => number {
  return (a: PlatformCompany, b: PlatformCompany): number => {
    if (a.id === selectedCompany.id) {
      return -1
    }
    if (b.id === selectedCompany.id) {
      return 1
    }
    return sortCompanies(a, b)
  }
}

export const fetchMarketCapOptions = async (): Promise<Array<{ value: string, text: string }>> => {
  const response = await API.graphql({
    query: defaultContentByIdentifier,
    variables: {
      type: ContentType.Statistics,
      key: { eq: 'MarketCap' }
    }
  })
  const content = JSON.parse(response.data.defaultContentByIdentifier.items[0].content)
  return generateSelectItems(content.map((item) => item.id), content.map((item) => item.value))
}

export enum CompanyStatsCollection {
  Ownership = 'ownership',
  Sector = 'sector',
  Country = 'country',
  EmployeeCount = 'employeeCount',
}

export function sortByCreatedAt (a: any, b: any): number {
  if (!a.createdAt) {
    return 1
  }
  if (!b.createdAt) {
    return -1
  }
  return b.createdAt.localeCompare(a.createdAt)
}

export function ignoreParentCompanyError (err: GraphQLResult | any): boolean {
  if (!err?.errors) {
    return false
  }
  return err.errors.every((e: GraphQLError) => e.errorType === 'Unauthorized' && e.path?.includes('parent'))
}
