<template>
  <platform-modal
    v-model="showModal"
    icon="company"
    :title="$t('modal.companies.createCompany.title')"
    :width="800"
    :saving="pendingCreate"
    :save-disabled="!isValid"
    @save="save"
    @validate="$refs.form.validate()"
    @close="$emit('close')"
  >
    <company-form
      ref="form"
      :company="formData"
      :loading="pendingCreate"
      @input="handleInput"
      @valid="(val) => isValid = val"
      @pendingFile="pendingFileUpload = true"
      @uploadedFile="pendingFileUpload = false"
    />
  </platform-modal>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { DefaultAddress } from '@/types/Platform'
import { trimStringFields } from '@/helpers/validation'
import PlatformModal from '@/components/PlatformModal.vue'
import CompanyForm from '@/components/companies/CompanyForm.vue'
import { ToastOptions } from 'vue-toasted'

export default Vue.extend({
  name: 'CreateCompanyModal',
  components: {
    PlatformModal,
    CompanyForm
  },
  props: {
    successNotificationAction: { type: Boolean, required: false }
  },
  data () {
    return {
      showModal: true,
      timerId: null,
      formData: {
        id: null,
        name: null,
        address: {
          ...DefaultAddress
        },
        parentId: null,
        configuration: null
      },
      pendingCreate: false,
      pendingFileUpload: false,
      isValid: false
    }
  },
  computed: {
    ...mapState('User', ['individual'])
  },
  watch: {
    pendingFileUpload (val, oldVal) {
      if (oldVal && !val) {
        this.companyCreated()
      }
    }
  },
  methods: {
    ...mapActions('Company', ['createCompany', 'selectCompany']),
    async handleInput (ev) {
      this.formData = {
        ...this.formData,
        ...ev.data
      }
    },
    async save () {
      if (this.pendingCreate) {
        return
      }
      const formData = trimStringFields({
        ...this.formData,
        parentId: this.formData.parentId ?? undefined,
        configuration: this.formData.configuration ?? undefined
      })
      this.pendingCreate = true
      try {
        this.formData = await this.createCompany(formData)
      } catch (err) {
        console.error('Failed to create company:', err)
        this.$toasted.global.error('create company')
        return
      } finally {
        this.pendingCreate = false
      }

      if (this.pendingFileUpload) {
        this.showModal = false
      } else {
        this.companyCreated()
      }
    },
    companyCreated (): void {
      this.$emit('created', this.formData)
      if (this.successNotificationAction) {
        this.$toasted.success(this.$t('modal.companies.createCompany.createNewCompanySuccessNotification') as string, {
          duration: 6000,
          keepOnHover: true,
          action: {
            text: this.$t('modal.companies.createCompany.notificationSelectCompanyAction') as string,
            onClick: (e, toastObject) => {
              this.selectCompany(this.formData)
              toastObject.goAway(0)
            }
          }
        } as ToastOptions & { keepOnHover?: boolean })
      } else {
        this.$toasted.success(this.$t('modal.companies.createCompany.createNewCompanySuccessNotification') as string)
      }
    }
  }
})
</script>
