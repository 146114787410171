var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-btn',{staticClass:"platform-button",class:{
    'px-2': !_vm.compact && !_vm.iconOnly,
    'px-0': _vm.iconOnly && _vm.compact,
    'px-1': !_vm.iconOnly && _vm.compact,
    'mx-1 square': _vm.iconOnly && !_vm.compact,
    'v-btn--disabled pointer-events--auto': _vm.disabled && _vm.allowDisabledClick,
    'text-none': _vm.defaultCase,
    'justify-start': !_vm.iconOnly,
    compact: _vm.compact,
    'platform-button--primary': _vm.primary,
    'platform-button--secondary': _vm.secondary
  },attrs:{"exact":"","value":_vm.value,"to":_vm.to,"loading":_vm.loading,"disabled":_vm.disabled && !_vm.allowDisabledClick,"depressed":_vm.depressed,"color":_vm.color,"outlined":_vm.outlined,"text":_vm.text,"rounded":_vm.rounded,"elevation":0,"x-small":_vm.xSmall,"small":_vm.small,"x-large":_vm.large,"icon":_vm.iconOnly && _vm.text,"type":_vm.inputType},on:{"click":(data) => _vm.$emit(_vm.disabled ? 'disabledClick' : 'click', data)},scopedSlots:_vm._u([{key:"loader",fn:function(){return [_c('platform-spinner',{attrs:{"color":_vm.loaderColor,"size":_vm.compact ? 16 : 24,"width":_vm.compact ? 2 : 3}})]},proxy:true}])},[(_vm.icon)?_c('platform-icon',{class:_vm.iconClasses,attrs:{"color":_vm.secondary ? 'primary' : undefined,"reverse":_vm.iconReversed,"size":_vm.iconSize,"name":_vm.icon}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }