<template>
  <user-form
    ref="form"
    v-if="user"
    v-model="user"
    @valid="(val) => $emit('valid', val)"
    @pending="() => $emit('pendingFile')"
    @uploadedFile="() => $emit('uploadedFile')"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformUser } from '@betterboards/shared/types/Platform'
import UserForm from '@/components/user/UserForm.vue'

export default Vue.extend({
  name: 'EditAccountProfile',
  components: {
    UserForm
  },
  props: {
    value: { type: Object as PropType<PlatformUser>, required: true }
  },
  data () {
    return {
      fileToUpload: null,
      pendingFileUpload: false,
      formValid: false
    }
  },
  computed: {
    user: {
      set (val: PlatformUser) {
        this.$emit('input', val)
      },
      get (): PlatformUser {
        return this.value
      }
    }
  }
})
</script>
