import { Colors } from '@/helpers/theme'
import { CompanyConfiguration } from '@betterboards/shared/types/Company'

export function getColor (
  companyConfig: CompanyConfiguration,
  color: string
): string {
  switch (color) {
    case 'primary':
      if (companyConfig?.primaryColor) {
        return companyConfig.primaryColor
      }
      return Colors.primary
    case 'secondary':
      if (companyConfig?.secondaryColor) {
        return companyConfig.secondaryColor
      }
      return Colors.secondary
    default:
      return color
  }
}
