import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import AuthComponent from '../views/Auth.vue'
import Dashboard from '../views/Dashboard.vue'
import QuestionnaireList from '../views/questionnaire/List.vue'
import ReportList from '../views/reports/List.vue'
import PlatformAdminList from '../views/admins/List.vue'
import QuestionnaireBuilder from '../views/questionnaire/QuestionnaireBuilder.vue'
import ViewSurvey from '../views/survey/ViewSurvey.vue'
import SurveyRelease from '../views/survey/Release.vue'
import CompanyHierarchy from '../views/companies/Hierarchy.vue'
import ResponseStatusList from '../views/survey/ResponseStatusList.vue'
import SurveyResponseStatus from '@/views/survey/SurveyResponseStatus.vue'
import Analysis from '../views/Analysis.vue'
import PdfViewer from '../views/PdfViewer.vue'
import QuestionnaireWizard from '../views/forms/QuestionnaireWizard.vue'
import Universe from '@/views/Universe.vue'
import AnalysisDashboard from '@/components/analysis/system-pages/AnalysisDashboard.vue'
import AnalysisProgress from '@/components/analysis/system-pages/AnalysisProgress.vue'
import AnalysisResponseStatus from '@/components/analysis/system-pages/ResponseStatus.vue'
import AnalysisEvaluationInformation from '@/components/analysis/system-pages/EvaluationInformation.vue'
import AnalysisResultsOverview from '@/components/analysis/system-pages/ResultsOverview.vue'
import AnalysisDiversity from '@/components/analysis/system-pages/Diversity.vue'
import AnalysisBoardTenure from '@/components/analysis/system-pages/BoardTenure.vue'
import AnalysisFocusAreas from '@/components/analysis/system-pages/FocusAreas.vue'
import AnalysisGenerateReport from '@/components/analysis/system-pages/GenerateReport.vue'
import { getCurrentUser } from 'aws-amplify/auth'

Vue.use(VueRouter)

export enum MetaFlags {
  HideAppBar = 'HideAppBar',
  CustomSidebar = 'CustomSidebar',
  NoScroll = 'NoScroll',
  HideCompanySwitcher = 'HideCompanySwitcher',
}

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Auth',
    component: AuthComponent
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/pdf',
    name: 'PdfViewer',
    component: PdfViewer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/companies',
    name: 'CompaniesList',
    component: async () => await import(/* webpackChunkName: "companieslist" */ '../views/companies/List.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/companies/hierarchy',
    name: 'CompaniesHierarchy',
    component: CompanyHierarchy,
    props: {
      useSelectedCompany: true
    },
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/participants',
    name: 'IndividualsList',
    component: async () => await import(/* webpackChunkName: "companiesmanage" */ '../views/individuals/List.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/committees',
    name: 'CommitteesList',
    component: async () => await import(/* webpackChunkName: "committeeslist" */ '../views/committees/List.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reports',
    name: 'ReportList',
    component: ReportList,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/questionnaires',
    name: 'QuestionnaireList',
    component: QuestionnaireList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/platform-administrators',
    name: 'PlatformAdmins',
    component: PlatformAdminList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/questionnaires/responses',
    name: 'ResponseStatusList',
    component: ResponseStatusList,
    meta: {
      requiresAuth: true,
      pageTitle: 'Questionnaire Responses',
      pageIcon: 'check-response'
    }
  },
  {
    path: '/questionnaires/:surveyId/responses',
    name: 'SurveyResponseStatus',
    component: SurveyResponseStatus,
    props: true,
    meta: {
      requiresAuth: true,
      pageTitle: 'Questionnaire Responses',
      pageIcon: 'check-response'
    }
  },
  {
    path: '/questionnaires/responses/analyse',
    name: 'QuestionnaireResponseAnalysis',
    component: ResponseStatusList,
    props: {
      isFinished: true,
      page: 'AnalysisDashboard'
    },
    meta: {
      requiresAuth: true,
      pageTitle: 'Questionnaire Responses',
      pageIcon: 'analyse-response'
    }
  },
  {
    path: '/questionnaires/responses/analyse',
    name: 'QuestionnairesReportList',
    component: ResponseStatusList,
    props: {
      isFinished: true,
      page: 'AnalysisGenerateReport'
    },
    meta: {
      requiresAuth: true,
      pageTitle: 'Questionnaire Reports'
    }
  },
  {
    path: '/questionnaires/wizard',
    name: 'QuestionnaireWizard',
    component: QuestionnaireWizard,
    meta: {
      requiresAuth: true,
      pageIcon: 'clipboard'
    }
  },
  {
    path: '/questionnaires/:id/edit',
    name: 'QuestionnaireEdit',
    component: QuestionnaireBuilder,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/questionnaires/:id/release',
    name: 'SurveyRelease',
    component: SurveyRelease,
    props: true,
    meta: {
      requiresAuth: true,
      pageTitle: 'Release Questionnaire',
      pageIcon: 'dispatch'
    }
  },
  {
    path: '/questionnaires/:id/release/:surveyGroupId',
    name: 'SurveyGroupRelease',
    component: SurveyRelease,
    props: true,
    meta: {
      requiresAuth: true,
      pageTitle: 'Release Questionnaire',
      pageIcon: 'dispatch'
    }
  },
  {
    path: '/questionnaires/example',
    name: 'SampleSurvey',
    component: ViewSurvey,
    props: {
      isSample: true
    },
    meta: {
      requiresAuth: true,
      pageTitle: null,
      pageIcon: null,
      [MetaFlags.CustomSidebar]: 'SurveySidebar',
      [MetaFlags.NoScroll]: true,
      [MetaFlags.HideCompanySwitcher]: true
    }
  },
  {
    path: '/questionnaires/:surveyId',
    name: 'ViewSurvey',
    component: ViewSurvey,
    props: true,
    meta: {
      requiresAuth: true,
      pageTitle: null,
      pageIcon: null,
      [MetaFlags.CustomSidebar]: 'SurveySidebar',
      [MetaFlags.NoScroll]: true,
      [MetaFlags.HideCompanySwitcher]: true
    }
  },
  {
    path: '/analysis/:surveyId?',
    name: 'Analysis',
    component: Analysis,
    props: true,
    meta: {
      requiresAuth: true,
      pageTitle: 'Analysis',
      pageIcon: 'analysis',
      [MetaFlags.CustomSidebar]: 'AnalysisSidebar',
      [MetaFlags.HideCompanySwitcher]: true
    },
    children: [
      {
        path: '/analysis/:surveyId/dashboard',
        name: 'AnalysisDashboard',
        component: AnalysisDashboard
      },
      {
        path: '/analysis/:surveyId/progress',
        name: 'AnalysisProgress',
        component: AnalysisProgress
      },
      {
        path: '/analysis/:surveyId/response-status',
        name: 'AnalysisResponseStatus',
        component: AnalysisResponseStatus
      },
      {
        path: '/analysis/:surveyId/evaluation-information',
        name: 'AnalysisEvaluationInformation',
        component: AnalysisEvaluationInformation
      },
      {
        path: '/analysis/:surveyId/results-overview',
        name: 'AnalysisResultsOverview',
        component: AnalysisResultsOverview
      },
      {
        path: '/analysis/:surveyId/diversity',
        name: 'AnalysisDiversity',
        component: AnalysisDiversity
      },
      {
        path: '/analysis/:surveyId/board-tenure',
        name: 'AnalysisBoardTenure',
        component: AnalysisBoardTenure
      },
      {
        path: '/analysis/:surveyId/focus-areas',
        name: 'AnalysisFocusAreas',
        component: AnalysisFocusAreas
      },
      {
        path: '/analysis/:surveyId/generate-report',
        name: 'AnalysisGenerateReport',
        component: AnalysisGenerateReport
      },
      {
        path: '/analysis/:surveyId/section/:sectionId/:tab?',
        name: 'AnalysisSection'
      }
    ]
  },
  {
    path: '/questionnaires/:surveyId/preview',
    name: 'PreviewSurvey',
    component: ViewSurvey,
    props: {
      isPreview: true
    },
    meta: {
      requiresAuth: true,
      pageTitle: null,
      pageIcon: null,
      [MetaFlags.CustomSidebar]: 'SurveySidebar',
      [MetaFlags.NoScroll]: true,
      [MetaFlags.HideCompanySwitcher]: true
    }
  },
  {
    path: '/universe',
    name: 'Universe',
    component: Universe,
    meta: {
      requiresAuth: true
    }
  }
]

const routerMode = process.env.BASE_URL === '/'
  ? 'history' // No subdirectory, use history mode
  : 'hash' // Base URL is a subdirectory (likely a playground build), use hash mode.

const router = new VueRouter({
  mode: routerMode,
  base: process.env.BASE_URL,
  routes
})

const initialTitle = document.title
router.beforeResolve((to, from, next) => {
  const pageTitle: string = to.meta?.pageTitle
  if (pageTitle) {
    document.title = `BetterBoards - ${pageTitle}`
  } else {
    document.title = initialTitle
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if current user is authed
    getCurrentUser()
      .then(() => {
        // Allow access to route
        next()
      })
      .catch(() => {
        // Redirect to root so user can auth
        next({
          path: '/'
        })
      })
  }
  next()
})

export default router
