<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 283.46 283.46" style="enable-background:new 0 0 283.46 283.46;" xml:space="preserve">
    <g>
      <path class="st0" d="M217.09,131.05c-6.89,0-12.49,5.6-12.49,12.49s5.6,12.49,12.49,12.49s12.49-5.6,12.49-12.49
        S223.98,131.05,217.09,131.05z M217.09,150.15c-3.64,0-6.6-2.96-6.6-6.6s2.96-6.6,6.6-6.6s6.6,2.96,6.6,6.6
        S220.74,150.15,217.09,150.15z"
            fill="currentColor"
      />
      <path class="st0" d="M217.09,118.03c-14.07,0-25.51,11.44-25.51,25.51s11.44,25.51,25.51,25.51s25.51-11.44,25.51-25.51
        S231.16,118.03,217.09,118.03z M217.09,163.17c-10.82,0-19.62-8.8-19.62-19.62s8.8-19.62,19.62-19.62s19.62,8.8,19.62,19.62
        S227.92,163.17,217.09,163.17z"
            fill="currentColor"
      />
      <path class="st0" d="M271.16,142.58c0.27-0.95,0.04-1.97-0.62-2.73c-0.26-0.3-6.46-7.36-16.03-14.52
        c-13.02-9.75-25.43-14.69-36.89-14.69c-11.45,0-24.02,4.94-37.39,14.67c-9.85,7.18-16.28,14.21-16.55,14.5
        c-0.69,0.76-0.93,1.8-0.66,2.77c-0.34,1-0.13,2.12,0.57,2.93c0.27,0.31,6.75,7.71,16.55,15.19c1.63,1.24,3.28,2.43,4.91,3.54
        l3.77,2.44c9,5.52,17.65,8.65,25.75,9.28l4.49,0.09h0.02c8.62-0.33,17.82-3.56,27.35-9.6l3.74-2.5c1.48-1.04,2.96-2.14,4.41-3.28
        c9.61-7.54,15.77-14.9,16.03-15.21C271.3,144.65,271.5,143.57,271.16,142.58z M265.12,142.65c-2.21,2.47-8.32,8.96-16.49,15.01
        c-11.14,8.25-21.58,12.43-31.01,12.43c-12.6,0-25.32-7.67-33.77-14.11c-7.23-5.51-12.7-11.13-14.75-13.33
        c2.31-2.37,7.56-7.47,14.6-12.59c8.48-6.18,21.25-13.54,33.92-13.54c12.63,0,25.07,7.32,33.29,13.46
        C257.9,135.21,263.15,140.55,265.12,142.65z"
            fill="currentColor"
      />
      <path class="st0" d="M70.72,93.46c0,1.5,1.22,2.72,2.72,2.72h57.98c1.5,0,2.72-1.22,2.72-2.72s-1.22-2.72-2.72-2.72H73.45
        C71.95,90.74,70.72,91.96,70.72,93.46z"
            fill="currentColor"
      />
      <path class="st0" d="M133.24,198.17l-2.88-2.56l0.88-1.49c0.99-1.58,1.92-3.24,2.78-4.95h15.4c4.96,0,5.02-4.81,5.02-4.85V84.12
        c0-1.76-0.9-3.93-2.15-5.17l-21.97-21.87L130.27,57l-0.04-0.02l-0.04-0.04c-1.24-1.24-3.41-2.13-5.16-2.13H54.44
        c-2.55,0-4.62,2.07-4.62,4.62v49.48c-6.93,3.92-12.96,9.08-17.92,15.35l-2.79,3.8c-4.43,6.59-7.49,13.85-9.08,21.61l-0.76,4.71
        c-0.27,2.13-0.42,4.3-0.46,6.47l0.09,4.69c0.59,10.11,3.75,20,9.14,28.61l3.19,4.61c11.65,15.27,29.29,24.03,48.41,24.03
        c11.33,0,22.39-3.16,32.01-9.13l31.41,31.4c0.51,0.51,1.2,0.8,1.92,0.8s1.41-0.28,1.92-0.8l14.69-14.69
        c0.51-0.51,0.8-1.21,0.8-1.92c0-0.73-0.28-1.41-0.8-1.92L133.24,198.17z M38.52,190.71c0.01,2.82,2.36,5.11,5.24,5.11h79.67
        c-10.6,13.68-26.52,21.51-43.8,21.51c-30.55,0-55.41-24.86-55.41-55.41c0-13.7,5.03-26.8,14.2-36.99v35.18L38.52,190.71z
         M128.94,84.95H149v96.4c-0.01,1.27-0.01,1.97-0.01,2.37h-12.55c2.25-5.83,3.57-11.95,3.94-18.22l0.09-4.69
        c-0.04-2.15-0.19-4.31-0.46-6.45l-0.76-4.72c-0.36-1.76-0.81-3.54-1.34-5.29c0.69-0.51,1.11-1.32,1.11-2.19v-12.59
        c0-1.5-1.22-2.72-2.72-2.72h-7l-1.92-2.62c-3.79-4.79-8.24-8.96-13.27-12.43h17.32c1.5,0,2.72-1.22,2.72-2.72s-1.22-2.72-2.72-2.72
        h-26.97c-7.84-3.52-16.19-5.31-24.82-5.31c-8.47,0-16.66,1.72-24.36,5.1V60.22h68.94v19.96C124.2,82.82,126.33,84.95,128.94,84.95z
         M129.65,79.5V64.08l15.48,15.43h-15.48V79.5z M126.4,132.23c1.63,2.56,3.06,5.28,4.26,8.09h-9.36v-8.09H126.4z M132.78,132.31
        h0.79v1.45C133.31,133.27,133.05,132.79,132.78,132.31z M122,126.25h-3.76c-1.69,0-3.06,1.34-3.06,2.99v14.08
        c0,1.65,1.37,2.99,3.06,2.99h14.56c1.49,5.06,2.24,10.31,2.24,15.61c0,9.82-2.61,19.46-7.56,27.91H44.64l-0.09-25.49v-45.27
        c9.88-8.1,22.33-12.56,35.09-12.56C96.03,106.51,111.41,113.69,122,126.25z M127.2,199.82l28.62,28.62l-10.84,10.84l-28.76-28.76
        C120.29,207.44,123.98,203.85,127.2,199.82z"
            fill="currentColor"
      />
      <path class="st0" d="M58.58,146.31h19.24c1.69,0,3.06-1.34,3.06-2.99v-14.08c0-1.65-1.37-2.99-3.06-2.99H58.58
        c-1.69,0-3.06,1.34-3.06,2.99v14.08C55.52,144.97,56.89,146.31,58.58,146.31z M74.77,132.23v8.09H61.64v-8.09H74.77z"
            fill="currentColor"
      />
      <path class="st0" d="M88.41,146.31h19.24c1.69,0,3.06-1.34,3.06-2.99v-14.08c0-1.65-1.37-2.99-3.06-2.99H88.41
        c-1.69,0-3.06,1.34-3.06,2.99v14.08C85.35,144.97,86.72,146.31,88.41,146.31z M104.6,132.23v8.09H91.47v-8.09H104.6z"
            fill="currentColor"
      />
      <path class="st0" d="M91.72,152.04H72.47c-1.69,0-3.06,1.34-3.06,2.99v14.08c0,1.65,1.37,2.99,3.06,2.99h19.24
        c1.69,0,3.06-1.34,3.06-2.99v-14.08C94.78,153.38,93.4,152.04,91.72,152.04z M75.53,166.12v-8.09h13.13v8.09H75.53z"
            fill="currentColor"
      />
      <path class="st0" d="M99.24,155.03v14.08c0,1.65,1.37,2.99,3.06,2.99h19.25c1.69,0,3.06-1.34,3.06-2.99v-14.08
        c0-1.65-1.37-2.99-3.06-2.99H102.3C100.61,152.04,99.24,153.38,99.24,155.03z M105.36,166.12v-8.09h13.13v8.09H105.36z"
            fill="currentColor"
      />
      <path class="st0" d="M141.73,0C63.58,0,0,63.58,0,141.73s63.58,141.73,141.73,141.73s141.73-63.58,141.73-141.73S219.88,0,141.73,0
        z M141.73,278.16C66.5,278.16,5.3,216.96,5.3,141.73C5.3,66.51,66.51,5.3,141.73,5.3s136.43,61.2,136.43,136.43
        C278.16,216.96,216.96,278.16,141.73,278.16z"
            fill="currentColor"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SampleReportIcon'
})
</script>
