<template>
  <div class="editor__container">
    <v-card class="ma-1">
      <v-toolbar
        flat
        color="grey lighten-4"
        :height="48"
      >
        <platform-icon
          v-for="(action, index) in editorActions"
          :key="index"
          class="mr-5"
          :name="action.icon"
          :size="24"
          @click="action.run()"
        />
      </v-toolbar>
      <div>
        <tiptap-editor-content
          id="text-editor"
          ref="editor"
          :editor="editor"
        />
      </div>
    </v-card>
    <v-row no-gutters align="center" justify="end" class="editor-info pt-2 pr-4" :class="{ 'has-limit': maxLength !== undefined }">
      <v-col class="shrink">
        <platform-status-indicator
          :loading-content="$t('platformStatusIndicator.loadingContent')"
          :warning-content="$t('platformStatusIndicator.warningContent')"
          :warning-tooltip="$t('platformStatusIndicator.warningTooltip')"
          :loading="saving"
          :saved="saved"
          :warning="surpassedMaxLength"
        />
      </v-col>
      <v-col class="shrink">
        <v-row no-gutters v-if="maxLength !== undefined" class="shrink character-count flex-nowrap grey--text text--darken-1 pl-4">
          <span :class="{
             'warning--text text--lighten-2': !surpassedMaxLength && nearMaxLength,
             'warning--text': surpassedMaxLength
          }">{{ contentLength }} &nbsp;</span>
          <span class="grey--text text--darken-2 px-1">/</span>
          <span>{{ maxLength }}</span>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { debounce } from 'lodash'
import { Editor as TiptapEditor, EditorContent as TiptapEditorContent } from '@tiptap/vue-2'
import TiptapStarterKit from '@tiptap/starter-kit'
import TiptapUnderline from '@tiptap/extension-underline'
import TiptapTextAlign from '@tiptap/extension-text-align'
import TiptapPlaceholder from '@tiptap/extension-placeholder'
import TiptapHardBreak from '@/helpers/tiptap/PageBreak'
import PlatformStatusIndicator from '@/components/shared/PlatformStatusIndicator.vue'
import { IconNames } from '@/components/shared/PlatformIcon.vue'

interface EditorAction {
  icon: IconNames
  run: () => void
}

const NewLineCharacterIncrease = 80

export default Vue.extend({
  name: 'PlatformWYSIWYG',
  props: {
    initialData: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    maxLength: {
      type: Number as PropType<number | undefined>,
      required: false
    }
  },
  components: {
    PlatformStatusIndicator,
    TiptapEditorContent
  },
  data () {
    return {
      ready: false,
      lastEmittedValue: <string | undefined>undefined,
      handleInput: undefined as any,
      editor: <TiptapEditor | undefined>undefined,
      editorData: '',
      saving: false,
      saved: false
    }
  },
  mounted () {
    this.initEditor()
    this.handleInput = debounce(this._handleInput, 1500, {
      leading: false,
      trailing: true
    })
    if (this.initialData) {
      this.setInitialData(this.initialData)
    }
  },
  beforeDestroy (): void {
    this.resetEditor()
  },
  watch: {
    editorData (input: string) {
      if (this.surpassedMaxLength) {
        return
      }
      if (input === this.lastEmittedValue) {
        return
      }
      this.saved = false
      this.saving = true
      this.handleInput()
    },
    initialData (val: string) {
      if (!this.saving) {
        this.setInitialData(val)
      }
      this.saving = false
      this.saved = true
    }
  },
  computed: {
    contentLength (): number {
      if (!this.editorData) {
        return 0
      }
      const content: string = this.editorData
      const newLines: number = content.match(/((\n\n)?(\n)?\n)/g)?.length ?? 0
      const val = content.replace(/(\*)|(__)|(<\/?[a-z]+>)|(\n\n((-|[0-9]\.) )?)|(-->)|(<!--)/g, '')
      const contentSize = val.length
      return contentSize + newLines * NewLineCharacterIncrease
    },
    nearMaxLength (): boolean {
      if (this.maxLength === undefined) {
        return false
      }
      return this.contentLength >= (this.maxLength * 0.75)
    },
    surpassedMaxLength (): boolean {
      if (this.maxLength === undefined) {
        return false
      }
      return this.contentLength > this.maxLength
    },
    editorActions (): EditorAction[] {
      return [
        {
          icon: IconNames.FormatBold,
          run: (): void => {
            this.editor?.chain().focus().toggleBold().run()
          }
        },
        {
          icon: IconNames.FormatItalic,
          run: (): void => {
            this.editor?.chain().focus().toggleItalic().run()
          }
        },
        {
          icon: IconNames.FormatUnderline,
          run: (): void => {
            this.editor?.chain().focus().toggleUnderline().run()
          }
        },
        {
          icon: IconNames.FormatBulletList,
          run: (): void => {
            this.editor?.chain().focus().toggleBulletList().run()
          }
        },
        {
          icon: IconNames.FormatOrderedList,
          run: (): void => {
            this.editor?.chain().focus().toggleOrderedList().run()
          }
        },
        {
          icon: IconNames.FormatAlignLeft,
          run: (): void => {
            this.editor?.chain().focus().setTextAlign('left').run()
          }
        },
        {
          icon: IconNames.FormatAlignCenter,
          run: (): void => {
            this.editor?.chain().focus().setTextAlign('center').run()
          }
        },
        {
          icon: IconNames.FormatAlignRight,
          run: (): void => {
            this.editor?.chain().focus().setTextAlign('right').run()
          }
        },
        {
          icon: IconNames.FormatAlignJustify,
          run: (): void => {
            this.editor?.chain().focus().setTextAlign('justify').run()
          }
        },
        {
          icon: IconNames.FormatPageBreak,
          run: (): void => {
            this.editor?.chain().focus().setPageBreak().run()
          }
        }
      ]
    }
  },
  methods: {
    _handleInput () {
      this.lastEmittedValue = this.editorData
      this.$emit('newInput', this.editorData)
    },
    initEditor (): void {
      this.editor = new TiptapEditor({
        extensions: [
          TiptapStarterKit.configure({
            hardBreak: false
          }),
          TiptapUnderline,
          TiptapTextAlign.configure({
            types: ['paragraph'],
            alignments: ['left', 'center', 'right', 'justify'],
            defaultAlignment: 'left'
          }),
          TiptapHardBreak,
          TiptapPlaceholder.configure({
            placeholder: this.placeholder
          })
        ],
        content: this.editorData,
        onUpdate: (): void => {
          if (!this.editor) {
            return
          }
          const editorContent: string = this.editor.getHTML()
          this.editorData = editorContent
        }
      })
    },
    resetEditor (): void {
      if (!this.editor) {
        return
      }
      this.editor.destroy()
      this.editor = undefined
    },
    setInitialData (initialData: string): void {
      this.setContent(initialData)
      this.lastEmittedValue = this.editorData // Don't trigger an emit
    },
    setContent (val: string): void {
      if (!this.editor) {
        return
      }
      this.editorData = val === '<p></p>'
        ? ''
        : val
      this.editor.commands.setContent(this.editorData, false)
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.editor__container {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;

  .editor-info {
    .character-count {
      font-size: 12px;
      line-height: 1;
    }
  }

  ::v-deep #text-editor {
    padding: 0 3px 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    min-height: 150px;
    max-height: 350px;

    .ProseMirror {
      height: 100%;
      flex-grow: 1;
      margin: 8px;
      padding: 0 8px;
      p {
        margin: 6px 0 !important;
        &.is-editor-empty:first-child::before {
          color: var(--v-grey-base);
          content: attr(data-placeholder);
          float: left;
          height: 0;
          pointer-events: none;
        }
      }
      &:focus,
      &:focus-visible {
        outline: 2px solid var(--v-secondary-base) !important;
      }
      div[data-page-break] {
        $font-size: 10px;
        $vertical-padding: 30px;
        position: relative;
        margin: $vertical-padding 0 0;
        padding: 0 0 $vertical-padding;
        opacity: 0.9;
        &::before {
          content: ' ';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          border: 1px dashed var(--v-primary-base);
          stroke-dasharray: 2, 2;
        }
        &::after {
          content: 'Page Break';
          color: var(--v-primary-base);
          font-size: $font-size;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: white;
          padding: 0 8px;
          text-align: center;
          font-weight: bold;
        }
      }
    }
  }
}
</style>
