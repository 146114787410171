<template>
  <v-form ref="form" v-model="formValid" @submit.prevent="">
    <v-form-base
      :model="variant"
      :schema="formSchema"
      @input="(input) => variant = input.data"
    />
  </v-form>
</template>

<script lang="ts">
import { LanguageCode, QuestionnaireVariant } from '@/API'
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'

export default Vue.extend({
  name: 'QuestionnaireVariantForm',
  components: {
    VFormBase
  },
  props: {
    value: { type: Object as PropType<QuestionnaireVariant> },
    availableLanguages: { type: Array as PropType<LanguageCode[]> }
  },
  data: () => ({
    saving: false,
    formValid: false
  }),
  watch: {
    formValid (val) {
      this.$emit('valid', val)
    }
  },
  computed: {
    variant: {
      set (value: any): void {
        this.$emit('input', value)
      },
      get (): any {
        return this.value
      }
    },
    formSchema (): any {
      return {
        name: {
          type: 'BBTextField',
          label: this.$t('form.questionnaire.variant.name'),
          col: {
            cols: 12,
            md: 6
          },
          required: true,
          disabled: this.saving
        },
        variantCode: {
          type: 'BBSelectField',
          items: this.availableLanguages.map((languageCode: string) => ({
            text: this.$t(`global.languages.${languageCode}`),
            value: languageCode
          })),
          label: this.$t('form.questionnaire.variant.languageCode'),
          col: {
            cols: 12,
            md: 6
          },
          required: true,
          disabled: this.saving
        }
      }
    }
  },
  methods: {
    validate (): void {
      const form: any = this.$refs.form
      form.validate()
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.modal--title {
  word-break: normal;
}
</style>
