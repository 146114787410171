<template>
  <platform-modal
    icon="text-box-plus"
    :value="showModal"
    :title="$t('modal.questionnaire.variant.create.title')"
    :width="600"
    :save-disabled="!isValid"
    @close="showModal = false"
    @save="createVariant"
    @validate="$refs.form.validate()"
  >
    <questionnaire-variant-form
      :value="variant"
      ref="form"
      :available-languages="availableLanguages"
      @valid="(val) => isValid = val"
    />
  </platform-modal>
</template>

<script lang="ts">
import { LanguageCode, QuestionnaireVariant } from '@/API'
import Vue, { PropType } from 'vue'
import PlatformModal from '@/components/PlatformModal.vue'
import QuestionnaireVariantForm from '@/components/questionnaire/variants/QuestionnaireVariantForm.vue'
import { PlatformQuestionnaire } from '@betterboards/shared/types/Platform'

export default Vue.extend({
  name: 'CreateQuestionnaireVariantModal',
  components: {
    QuestionnaireVariantForm,
    PlatformModal
  },
  props: {
    value: { type: Boolean, required: true },
    questionnaire: { type: Object as PropType<PlatformQuestionnaire> },
    availableLanguages: { type: Array as PropType<LanguageCode[]> }
  },
  data: () => ({
    isValid: false,
    variant: <QuestionnaireVariant & { name: null, variantCode: null }>{
      name: null,
      variantCode: null
    }
  }),
  computed: {
    showModal: {
      set (val: boolean) {
        this.$emit('input', val)
      },
      get (): boolean {
        return this.value
      }
    }
  },
  methods: {
    createVariant (): void {
      if (!this.isValid) {
        return
      }
      this.$emit('create', this.variant)
    }
  }
})
</script>
