<template>
  <div>
    <v-select
      :label="$t('form.admin.userRole')"
      :items="accountTypeItems"
      v-model="companyUser.accountType"
    />

    <div v-if="showPackageManagement">
      <div class="mb-3">
        <h3 class="primary-header sans-serif mt-6 mb-1">
          Packages
        </h3>
        <span class="text-caption grey--text text--darken-2 pl-1">
          <template v-if="pendingFetchCompanyUserPackages || pendingProcessCompanyUserPackages || companyUserPackageIds.length">
            This user has access to the following private packages within {{ company.name }}:
          </template>
          <template v-else-if="!companyUserPackageIds.length">
            This user has no private packages assigned.
          </template>
        </span>
      </div>
      <div class="company-user-packages--list">
        <template v-if="pendingFetchCompanyUserPackages || pendingProcessCompanyUserPackages">
          <platform-spinner class="mx-auto mb-3" />
        </template>
        <template v-else>
          <v-fade-transition group>
            <v-row
              no-gutters
              v-for="p in companyUserPackages"
              :key="p.id"
            >
              <platform-entity-card
                row
                compact
                flat
                inline-actions
                align="start"
                entity-type="package"
                placeholder-size="xs"
                :inner-margin="2"
                :entity="p"
                :avatar-size="52"
              >
                <small
                  class="grey--text darken-2 pl-1"
                >
                  {{ p.categories.join(', ') }}
                </small>
                <template #actions>
                  <v-row no-gutters align="center" class="flex-nowrap">
                    <v-col
                      v-if="packageIdsToAdd.includes(p.id)"
                    >
                      <small
                        class="grey--text text--lighten-1 pr-2 text-no-wrap"
                      >
                        Unsaved
                      </small>
                    </v-col>
                    <v-col>
                      <platform-icon
                        color="grey"
                        name="cross"
                        v-tooltip="`Click to remove ${user.displayName}'s access to this package.`"
                        :size="16"
                        @click="removePackage(p.id)"
                      />
                    </v-col>
                  </v-row>
                </template>
              </platform-entity-card>
            </v-row>
          </v-fade-transition>
        </template>
      </div>
      <platform-autocomplete
        class="mt-1 pointer-events--auto"
        prepend-label-icon="plus"
        label="Assign a Package"
        block
        v-model="packageIdToAdd"
        ref="addPackageField"
        v-tooltip:bottom="availablePrivatePackageOptions.length ? undefined : 'This company has no more private packages to assign to this user.'"
        :items="availablePrivatePackageOptions"
        :loading="pendingFetchCompanyPackages"
        :disabled="pendingFetchCompanyPackages || !availablePrivatePackageOptions.length"
        :item-avatar-size="36"
        @input="addPackage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { AccountType, Company, CompanyUserPackage } from '@/API'
import { PlatformCompanyPackage, PlatformCompanyUser, PlatformEntityTypes, PlatformPackage, PlatformUser } from '@/types/Platform'
import { generateSelectItems, SelectItem } from '@/helpers/forms'
import { listCompanyPackages, listCompanyUserPackages } from '@/helpers/packages'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformAutocomplete, { PlatformSelectItem } from '@/components/shared/inputs/PlatformAutocomplete.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

function sortPackages (a: PlatformPackage, z: PlatformPackage): number {
  const zCategory = z.categories[0]
  const aCategory = a.categories[0]
  if (!zCategory) {
    return -1
  }
  if (!aCategory) {
    return 1
  }
  return zCategory.localeCompare(aCategory)
}

export default Vue.extend({
  name: 'CompanyUserForm',
  components: {
    PlatformAutocomplete,
    PlatformSpinner,
    PlatformEntityCard
  },
  props: {
    value: { type: Object as PropType<PlatformCompanyUser>, required: true },
    user: { type: Object as PropType<PlatformUser>, required: true },
    editing: { type: Boolean, required: false }
  },
  data: () => ({
    companyUserPackageIds: <string[]>[],
    companyPackages: <PlatformCompanyPackage[]>[],
    packageIdsToAdd: <string[]>[],
    packageIdsToDelete: <string[]>[],
    pendingFetchCompanyPackages: false,
    pendingFetchCompanyUserPackages: false,
    packageIdToAdd: <string | null>null
  }),
  mounted (): void {
    void this.init()
  },
  computed: {
    companyUser: {
      set (val: PlatformCompanyUser) {
        this.$emit('input', val)
      },
      get (): PlatformCompanyUser {
        return this.value
      }
    },
    company (): Company {
      return this.companyUser.company
    },
    accountTypeItems (): SelectItem[] {
      const translationsMap = Object.keys(AccountType).reduce((map, accountType) => {
        map[accountType] = this.$t(`global.accountTypes.${accountType}`)
        return map
      }, {})

      return generateSelectItems(AccountType, translationsMap)
    },
    /**
     * targetUser - The User being edited in this modal
     */
    targetUserIsCompanyAdmin (): boolean {
      return this.companyUser.accountType === AccountType.Admin
    },
    /**
     * currentUser - The currently logged in User
     */
    currentUserIsCompanyAdmin (): boolean {
      const currentUserCompanyAccess: PlatformCompanyUser = this.$store.state.Company.companyAccessList.find((c) => c.id === this.companyUser.companyId)
      return currentUserCompanyAccess.accountType === AccountType.Admin
    },
    companyUserPackages (): PlatformPackage[] {
      return [
        ...this.companyUserPackageIds.filter((p) => !this.packageIdsToDelete.includes(p)), // Filter out CompanyUserPackages marked for deletion
        ...this.packageIdsToAdd
      ]
        .map(
          (packageId) => this.companyPackages.find((p) => p.packageId === packageId)?.package
        )
        .filter<PlatformPackage>((p: PlatformPackage | undefined): p is PlatformPackage => !!p)
        .sort(sortPackages)
    },
    availablePrivatePackages (): PlatformCompanyPackage[] {
      return this.companyPackages.filter(
        (p: PlatformCompanyPackage) => {
          if (p.public) {
            return false
          }
          if (this.companyUserPackageIds.includes(p.packageId)) {
            // If CompanyUser actually has this Package, only show this option if that record has been marked for deletion in this form.
            return this.packageIdsToDelete.includes(p.packageId)
          }
          // Otherwise just show any Packages that haven't already been marked to be added
          return !this.packageIdsToAdd.includes(p.packageId)
        }
      )
    },
    availablePrivatePackageOptions (): PlatformSelectItem[] {
      return this.availablePrivatePackages
        .map((p) => p.package)
        .sort(sortPackages)
        .map((p) => ({
          text: p.name,
          value: p.id,
          entityType: PlatformEntityTypes.Package,
          avatarEntity: {
            id: p.id,
            name: p.name
          }
        }))
    },
    pendingProcessCompanyUserPackages (): boolean {
      if (!this.companyUserPackageIds.length) {
        return true
      }
      return !this.companyUserPackages.length && !this.packageIdsToDelete.length
    },
    showPackageManagement (): boolean {
      if (!this.editing) {
        return false
      }
      return this.currentUserIsCompanyAdmin && !this.targetUserIsCompanyAdmin
    }
  },
  methods: {
    async init (): Promise<void> {
      if (!this.showPackageManagement) {
        return
      }
      // Get all options
      await Promise.all([
        this.fetchCompanyPackages(),
        this.fetchCompanyUserPackages()
      ])
    },
    async fetchCompanyPackages (): Promise<void> {
      this.pendingFetchCompanyPackages = true
      try {
        const companyPackages: PlatformCompanyPackage[] = await listCompanyPackages(this.companyUser.companyId)
        this.companyPackages.push(...companyPackages)
      } catch (err) {
        console.error('Failed to list CompanyPackages for PlatformCompanyUser.', JSON.stringify({
          companyId: this.companyUser.companyId,
          cognitoIdentityId: this.companyUser.cognitoIdentityId
        }))
        throw err
      } finally {
        this.pendingFetchCompanyPackages = false
      }
    },
    async fetchCompanyUserPackages (): Promise<void> {
      this.pendingFetchCompanyUserPackages = true
      try {
        const companyUserPackages: CompanyUserPackage[] = await listCompanyUserPackages(this.companyUser.companyId, this.companyUser.cognitoIdentityId)
        this.companyUserPackageIds.push(
          ...companyUserPackages.map((p) => p.packageId)
        )
      } catch (err) {
        console.error('Failed to list CompanyUserPackages.', JSON.stringify({
          companyId: this.companyUser.companyId,
          cognitoIdentityId: this.companyUser.cognitoIdentityId
        }))
        throw err
      } finally {
        this.pendingFetchCompanyUserPackages = false
      }
    },
    addPackage (packageId: string | null): void {
      if (!packageId) {
        return
      }
      this.$nextTick(() => {
        // Reset the autocomplete field's v-model so the cached selected item is cleared
        this.packageIdToAdd = null
      })

      const deleteItemIndex = this.packageIdsToDelete.indexOf(packageId)
      console.info('addPackage', packageId, deleteItemIndex, this.packageIdsToDelete)
      if (deleteItemIndex !== -1) {
        // CompanyUser already has this Package but it was marked for deletion in this form, so just undo this.
        this.packageIdsToDelete.splice(deleteItemIndex, 1)
        this.$emit('addPackage', packageId)
        return
      }
      this.packageIdsToAdd.push(packageId)
      this.$emit('addPackage', packageId)
      this.blurAddPackageField()
    },
    removePackage (packageId: string): void {
      const addItemIndex = this.packageIdsToAdd.indexOf(packageId)
      if (addItemIndex !== -1) {
        this.packageIdsToAdd.splice(addItemIndex, 1)
        this.$emit('removePackage', packageId)
        return
      }
      this.$emit('removePackage', packageId)
      this.packageIdsToDelete.push(packageId)
    },
    blurAddPackageField (): void {
      const addPackageFieldComponent: any = this.$refs.addPackageField
      const autocompleteComponent = addPackageFieldComponent?.$refs.autocomplete
      if (autocompleteComponent) {
        autocompleteComponent.blur()
      }
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.company-user-packages--list {
  min-height: 65px;
}
</style>
