<template>
  <v-container>
    <v-row>
      <v-col>
        <h1 class="mb-3 mt-0">{{ $t('page.report.list.title') }}</h1>
        <p v-show="reports.length <= 0">{{ $t('page.report.list.noResults') }}</p>
        <v-card v-show="reports.length > 0" v-for="report in reports" :key="report.id" class="mb-5">
          <v-card-title></v-card-title>
          <v-card-subtitle>Produced <platform-date-display :date-time="report.finishedAt" /></v-card-subtitle>
          <v-card-actions>
            <platform-button @click="() => downloadReport(report.companyId, report.surveyGroupId)">Download</platform-button>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import { ReportDocument } from '@/API'
import API from '@/services/API'
import PlatformDateDisplay from '@/components/PlatformDateDisplay.vue'

export default Vue.extend({
  name: 'ReportList',
  components: { PlatformDateDisplay },
  data () {
    return {
      reports: [] as ReportDocument[],
      nextToken: null as null | string
    }
  },
  async mounted () {
    this.setBoardMemberLinks()
    const { items, nextToken } = await this.listReportDocuments()
    this.reports = items
    this.nextToken = nextToken
  },
  methods: {
    ...mapMutations('Navigation', ['setBoardMemberLinks']),
    ...mapActions('Report', ['listReportDocuments']),
    async downloadReport (companyId: string, surveyGroupId: string) {
      const response = await API.get('backendfunctions', `/${companyId}/files?objectType=reports&objectId=${surveyGroupId}.pdf`)
      const link = document.createElement('a')
      link.href = response.link
      link.target = '_blank'
      link.download = 'report.pdf'
      link.dispatchEvent(new MouseEvent('click'))
    }
  }
})
</script>
