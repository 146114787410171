import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify, {
  VRow,
  VCol,
  VTextField,
  VTooltip,
  VCheckbox,
  VSelect,
  VFileInput,
  VBtn,
  VIcon,
  VCard,
  VCardTitle,
  VCardText,
  VTextarea,
  VSwitch,
  VSpacer
} from 'vuetify/lib'

import { Ripple, Intersect, Touch, Resize, ClickOutside } from 'vuetify/lib/directives'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  components: {
    VRow,
    VTooltip,
    VCol,
    VTextField,
    VCheckbox,
    VSelect,
    VFileInput,
    VBtn,
    VIcon,
    VCard,
    VCardTitle,
    VCardText,
    VTextarea,
    VSwitch,
    VSpacer
  },
  directives: { Ripple, Intersect, Touch, Resize, ClickOutside }
})

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#24235f',
        secondary: '#e44500',
        background: '#f0f0f0',
        error: '#ae3033',
        warning: '#ae3033',
        grey: colors.grey
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
})
