import { Individual, UserType } from '@/API'
import { generateMegaMenuItems, generateSelectItems, generateSelectItemsDynamic } from '@/helpers/forms'
import { TargetGroupOptions } from '@betterboards/shared/types/Block'
import { IndividualType, PlatformIndividual } from '@betterboards/shared/types/Individual'
import { i18n } from '@/plugins/i18n'
import { ResultsIndividual } from '@betterboards/shared/types/ResultSet'
import { PlatformCommitteeIndividual } from '@betterboards/shared/types/Committee'

export function getInitials (individual: Individual): string | null {
  const first = individual.firstName?.charAt(0)
  const last = individual.familyName?.charAt(0)
  return `${first ?? ''}${last ?? ''}`
}

export function getNameInitials (displayName: string): string | null {
  return displayName
    .split(' ')
    .map((namePart: string) => namePart.charAt(0))
    .join('')
}

/**
 * Returns the full name where possible, only including title if specified.
 * If first/family name weren't found, returns preferredName if set (without title)
 */
export function getFullName (individual: IndividualType, title: boolean = false): string {
  const parts: Array<string | null | undefined> = []

  if (individual.firstName) {
    parts.push(individual.firstName)
  }
  if (individual.familyName) {
    parts.push(individual.familyName)
  }
  if (parts.length > 0) {
    if (title) {
      parts.unshift(individual.title)
    }
  } else if (individual.preferredName) {
    parts.push(individual.preferredName)
  }

  return parts.filter((item) => item).join(' ')
}

export function getAllNames (individual: Individual, title: boolean = false): string | null {
  const parts: Array<string | null | undefined> = []
  if (title) {
    parts.push(individual.title)
  }
  parts.push(individual.firstName, individual.familyName)
  if (individual.preferredName) {
    parts.push(`(${individual.preferredName})`)
  }
  return parts.filter((item) => item).join(' ')
}

export enum IndividualEthnicDescription {
  EnglishWelshScottishNIBritish = 'EnglishWelshScottishNIBritish',
  WhiteEuropean = 'WhiteEuropean',
  WhiteAmerican = 'WhiteAmerican',
  Irish = 'Irish',
  OtherWhite = 'OtherWhite',
  MixedEthnicityBritish = 'MixedEthnicityBritish',
  MixedEthnicityEuropean = 'MixedEthnicityEuropean',
  MixedEthnicityAmerican = 'MixedEthnicityAmerican',
  WhiteBlackCaribbean = 'WhiteBlackCaribbean',
  WhiteBlackAfrican = 'WhiteBlackAfrican',
  WhiteAndAsian = 'WhiteAndAsian',
  OtherMixed = 'OtherMixed',
  AsianBritish = 'AsianBritish',
  AsianEuropean = 'AsianEuropean',
  AsianAmerican = 'AsianAmerican',
  Indian = 'Indian',
  Pakistani = 'Pakistani',
  Bangladeshi = 'Bangladeshi',
  Chinese = 'Chinese',
  OtherAsianBackground = 'OtherAsianBackground',
  BlackBritish = 'BlackBritish',
  BlackEuropean = 'BlackEuropean',
  BlackAmerican = 'BlackAmerican',
  African = 'African',
  Caribbean = 'Caribbean',
  OtherBlackAfricanCaribbean = 'OtherBlackAfricanCaribbean',
  Arab = 'Arab',
  Other = 'Other'
}

export enum IndividualEthnicDescriptionFriendlyNames {
  EnglishWelshScottishNIBritish = 'White British',
  WhiteEuropean = 'White European',
  WhiteAmerican = 'White American',
  Irish = 'Irish',
  OtherWhite = 'Any other White background',
  MixedEthnicityBritish = 'Mixed Ethnicity British',
  MixedEthnicityEuropean = 'Mixed Ethnicity European',
  MixedEthnicityAmerican = 'Mixed Ethnicity American',
  WhiteBlackCaribbean = 'White and Black Caribbean',
  WhiteBlackAfrican = 'White and Black African',
  WhiteAndAsian = 'White and Asian',
  OtherMixed = 'Any other Mixed or Multiple ethnic backgrounds',
  AsianBritish = 'Asian British',
  AsianEuropean = 'Asian European',
  AsianAmerican = 'Asian American',
  Indian = 'Indian',
  Pakistani = 'Pakistani',
  Bangladeshi = 'Bangladeshi',
  Chinese = 'Chinese',
  OtherAsianBackground = 'Any other Asian background',
  BlackBritish = 'Black British',
  BlackEuropean = 'Black European',
  BlackAmerican = 'Black American',
  African = 'African',
  Caribbean = 'Caribbean',
  OtherBlackAfricanCaribbean = 'Any other Black, African or Caribbean background',
  Arab = 'Arab',
  Other = 'Any other ethnic group'
}

export enum EthnicDesignationGroupFriendlyNames {
  White = 'White',
  MixedMultiple = 'Mixed or Multiple ethnic groups',
  AsianAsianBritish = 'Asian or Asian British',
  BlackAfricanCaribbeanBlackBritish = 'Black, African, Caribbean or Black British',
  Other = 'Other ethnic group'
}

const IndividualEthnicDescriptionGroups = {
  [EthnicDesignationGroupFriendlyNames.White]: {
    [IndividualEthnicDescription.EnglishWelshScottishNIBritish]: IndividualEthnicDescriptionFriendlyNames.EnglishWelshScottishNIBritish,
    [IndividualEthnicDescription.WhiteEuropean]: IndividualEthnicDescriptionFriendlyNames.WhiteEuropean,
    [IndividualEthnicDescription.WhiteAmerican]: IndividualEthnicDescriptionFriendlyNames.WhiteAmerican,
    [IndividualEthnicDescription.Irish]: IndividualEthnicDescriptionFriendlyNames.Irish,
    [IndividualEthnicDescription.OtherWhite]: IndividualEthnicDescriptionFriendlyNames.OtherWhite
  },
  [EthnicDesignationGroupFriendlyNames.MixedMultiple]: {
    [IndividualEthnicDescription.MixedEthnicityBritish]: IndividualEthnicDescriptionFriendlyNames.MixedEthnicityBritish,
    [IndividualEthnicDescription.MixedEthnicityEuropean]: IndividualEthnicDescriptionFriendlyNames.MixedEthnicityEuropean,
    [IndividualEthnicDescription.MixedEthnicityAmerican]: IndividualEthnicDescriptionFriendlyNames.MixedEthnicityAmerican,
    [IndividualEthnicDescription.WhiteBlackCaribbean]: IndividualEthnicDescriptionFriendlyNames.WhiteBlackCaribbean,
    [IndividualEthnicDescription.WhiteBlackAfrican]: IndividualEthnicDescriptionFriendlyNames.WhiteBlackAfrican,
    [IndividualEthnicDescription.WhiteAndAsian]: IndividualEthnicDescriptionFriendlyNames.WhiteAndAsian,
    [IndividualEthnicDescription.OtherMixed]: IndividualEthnicDescriptionFriendlyNames.OtherMixed
  },
  [EthnicDesignationGroupFriendlyNames.AsianAsianBritish]: {
    [IndividualEthnicDescription.AsianBritish]: IndividualEthnicDescriptionFriendlyNames.AsianBritish,
    [IndividualEthnicDescription.AsianEuropean]: IndividualEthnicDescriptionFriendlyNames.AsianEuropean,
    [IndividualEthnicDescription.AsianAmerican]: IndividualEthnicDescriptionFriendlyNames.AsianAmerican,
    [IndividualEthnicDescription.Indian]: IndividualEthnicDescriptionFriendlyNames.Indian,
    [IndividualEthnicDescription.Pakistani]: IndividualEthnicDescriptionFriendlyNames.Pakistani,
    [IndividualEthnicDescription.Bangladeshi]: IndividualEthnicDescriptionFriendlyNames.Bangladeshi,
    [IndividualEthnicDescription.Chinese]: IndividualEthnicDescriptionFriendlyNames.Chinese,
    [IndividualEthnicDescription.OtherAsianBackground]: IndividualEthnicDescriptionFriendlyNames.OtherAsianBackground
  },
  [EthnicDesignationGroupFriendlyNames.BlackAfricanCaribbeanBlackBritish]: {
    [IndividualEthnicDescription.BlackBritish]: IndividualEthnicDescriptionFriendlyNames.BlackBritish,
    [IndividualEthnicDescription.BlackEuropean]: IndividualEthnicDescriptionFriendlyNames.BlackEuropean,
    [IndividualEthnicDescription.BlackAmerican]: IndividualEthnicDescriptionFriendlyNames.BlackAmerican,
    [IndividualEthnicDescription.African]: IndividualEthnicDescriptionFriendlyNames.African,
    [IndividualEthnicDescription.Caribbean]: IndividualEthnicDescriptionFriendlyNames.Caribbean,
    [IndividualEthnicDescription.OtherBlackAfricanCaribbean]: IndividualEthnicDescriptionFriendlyNames.OtherBlackAfricanCaribbean
  },
  [EthnicDesignationGroupFriendlyNames.Other]: {
    [IndividualEthnicDescription.Arab]: IndividualEthnicDescriptionFriendlyNames.Arab,
    [IndividualEthnicDescription.Other]: IndividualEthnicDescriptionFriendlyNames.Other
  }
}

export const IndividualEthnicDescriptionGroupItems = generateMegaMenuItems(IndividualEthnicDescriptionGroups)

export enum IndividualReligionFriendlyNames {
  None = 'None',
  Christian = 'Christian',
  Buddhist = 'Buddhist',
  Hindu = 'Hindu',
  Jewish = 'Jewish',
  Muslim = 'Muslim',
  Sikh = 'Sikh',
  Other = 'Other'
}

export const IndividualReligionOptions = generateSelectItems(IndividualReligionFriendlyNames, IndividualReligionFriendlyNames)

export const IndividualRoleOptions = generateSelectItemsDynamic(
  UserType,
  (key: string) => i18n.t(`shared.individual.roles.${key}`) as string
)

export function getCompanyPosition (userRole: UserType): TargetGroupOptions | undefined {
  switch (userRole) {
    case UserType.CEO:
    case UserType.CFO:
    case UserType.CIO:
    case UserType.COO:
    case UserType.CMO:
    case UserType.CPO:
    case UserType.CSO:
    case UserType.CNO:
    case UserType.OtherExecutive:
    case UserType.MD:
      return TargetGroupOptions.Executives
    case UserType.Chair:
    case UserType.DeputyChair:
    case UserType.ViceChair:
    case UserType.SID:
    case UserType.NED:
    case UserType.AssociateNED:
    case UserType.OtherNonExecutive:
      return TargetGroupOptions.NonExecutives
    case UserType.ShareholderRepresentative:
      return TargetGroupOptions.ShareholderRepresentative
    case UserType.LabourRepresentative:
      return TargetGroupOptions.LabourRepresentative
    case UserType.InternalInvestor:
    case UserType.InternalUnion:
    case UserType.InternalStakeholder:
      return TargetGroupOptions.InternalStakeholders
    case UserType.ExternalInvestor:
    case UserType.ExternalUnion:
    case UserType.ExternalNGO:
    case UserType.ExternalStakeholder:
      return TargetGroupOptions.ExternalStakeholders
    default:
      return undefined
  }
}

export enum TenureLengthStatus {
  Danger = 'danger',
  Warning = 'warning',
  Okay = 'okay',
  None = 'none',
}

export function getTenureLengthStatus (role: UserType, tenureLength: number): TenureLengthStatus {
  if (tenureLength === undefined) {
    return TenureLengthStatus.None
  }
  if (role === UserType.CompanySecretary) {
    return TenureLengthStatus.None
  }
  const rolePosition = getCompanyPosition(role)
  if (rolePosition === TargetGroupOptions.Executives || rolePosition === TargetGroupOptions.LabourRepresentative) {
    return TenureLengthStatus.None
  }
  if (role === UserType.Chair) {
    // Chair is allowed one more year
    tenureLength -= 1
  }
  if (tenureLength >= 8) {
    return TenureLengthStatus.Danger
  }
  if (tenureLength >= 6) {
    return TenureLengthStatus.Warning
  }
  return TenureLengthStatus.Okay
}

export function sortByTenureLength (a, b): number {
  if (a.tenureLength === undefined) {
    return 1
  }
  if (b.tenureLength === undefined) {
    return -1
  }
  if (a.tenureLength === b.tenureLength) {
    const aName = a.fullName ?? a.firstName
    const bName = b.fullName ?? b.firstName
    if (!aName) {
      return 1
    }
    if (!bName) {
      return -1
    }
    return aName.localeCompare(bName)
  }
  return b.tenureLength - a.tenureLength
}

export function sortIndividuals (a: PlatformIndividual, b: PlatformIndividual): number {
  const aTeams = a.teams?.items
  const bTeams = b.teams?.items
  if (!!aTeams?.length || !!bTeams?.length) {
    if (!aTeams?.length) {
      // A has no Teams, move after B
      return 1
    }
    if (!bTeams?.length) {
      // B has no Teams, move before A
      return -1
    }
    // Both Individuals have Teams
    if (aTeams[0].teamId !== bTeams[0].teamId) {
      // If first team IDs differ, sort alphabetically
      return aTeams[0].teamId.localeCompare(bTeams[0].teamId)
    }
    if (aTeams.length > bTeams.length) {
      // A has more teams than B, move A after B
      return -1
    }
    if (aTeams.length < bTeams.length) {
      // B has more teams than A, move B before A
      return 1
    }
  }
  // Neither Individuals have Teams, just sort on createdAt or preferredName.
  if (a.createdAt && b.createdAt) {
    const dateComparison = b.createdAt.localeCompare(a.createdAt)
    if (dateComparison !== 0) {
      return dateComparison
    }
  }
  return b.preferredName.localeCompare(a.preferredName)
}

export function sortCommitteeIndividuals (a: PlatformCommitteeIndividual, b: PlatformCommitteeIndividual): number {
  if (a.createdAt && b.createdAt) {
    const dateComparison = b.createdAt.localeCompare(a.createdAt)
    if (dateComparison !== 0) {
      return dateComparison
    }
  }
  if (a.individual && b.individual) {
    return sortIndividuals(a.individual, b.individual)
  }
  return 0
}

export function getJobTitle (individual: ResultsIndividual): string | undefined {
  if (individual.jobTitle) {
    return individual.jobTitle
  }
  return i18n.t(`shared.individual.roles.${individual.role as string}`) as string
}
