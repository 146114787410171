<template>
  <v-row
    no-gutters
  >
    <v-col>
      <v-form-base
        class="no-col-gutters"
        ref="form"
        :model="targetRule"
        :schema="schema"
        @input="onInput"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
// @ts-ignore
import VFormBase from 'vuetify-form-base'
import Vue, { PropType } from 'vue'
import { CompanyTeamTypes } from '@/API'
import { SelectItem } from '@/helpers/forms'
import { IndividualRoleOptions } from '@/helpers/individual'
import { RepeatForOptions, TargetGroupItems } from '@/helpers/questionnaire/questions'
import { QuestionTargetRule, QuestionTargetRuleType } from '@betterboards/shared/types/Block'
import { CommitteeTag } from '@betterboards/shared/types/API'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { PlatformCommittee } from '@betterboards/shared/types/Committee'

const TargetRuleFilterSelectItems = [
  {
    text: 'Include',
    value: 'include'
  },
  {
    text: 'Exclude',
    value: 'exclude'
  }
]

export default Vue.extend({
  name: 'TargetRuleForm',
  props: {
    value: { type: Object as PropType<QuestionTargetRule>, required: true },
    criteriaValue: { type: String as PropType<RepeatForOptions>, required: false }
  },
  components: {
    VFormBase
  },
  watch: {
    targetRule: {
      deep: true,
      handler (targetRule: QuestionTargetRule): void {
        const form: any = this.$refs.form
        if (targetRule.type && !targetRule.filter) {
          const filterField: any = form.$refs.filterField
          filterField?.validate(true)
        }
        if (targetRule.filter) {
          const valueField: any = form.$refs.valueField
          valueField?.validate(true)
        }
      }
    }
  },
  computed: {
    companyIndividuals (): PlatformIndividual[] {
      return this.$store.getters['Individual/individualsList']
    },
    companyCommittees (): PlatformCommittee[] {
      return this.$store.getters['Committee/list']
    },
    targetRule: {
      get (): QuestionTargetRule {
        return this.value
      },
      set (data: QuestionTargetRule): void {
        this.$emit('input', data)
      }
    },
    targetRuleTypeOptions (): SelectItem[] {
      return Object.values(QuestionTargetRuleType)
        .filter((value: QuestionTargetRuleType): boolean => {
          switch (value) {
            case QuestionTargetRuleType.CriteriaId:
              return !!this.targetRuleCriteriaValueOptions?.length
            case QuestionTargetRuleType.EntityTag:
              return !!this.targetRuleTagOptions?.length
          }
          return true
        })
        .map((value: QuestionTargetRuleType): SelectItem => {
          const text: string = value === QuestionTargetRuleType.CriteriaId
            ? this.$t(`form.questionnaire.targetRules.menu.types.${value}.${this.criteriaValue}`) as string
            : this.$t(`form.questionnaire.targetRules.menu.types.${value}`) as string
          return {
            text,
            value
          }
        })
    },
    targetRuleValueOptions (): SelectItem[] | undefined {
      switch (this.targetRule.type) {
        case QuestionTargetRuleType.Role:
          return IndividualRoleOptions
        case QuestionTargetRuleType.RoleGroup:
          return TargetGroupItems
        case QuestionTargetRuleType.TeamMembers:
          return this.availableTeamTypes
        case QuestionTargetRuleType.CriteriaId:
          return this.targetRuleCriteriaValueOptions
        case QuestionTargetRuleType.EntityTag:
          return this.targetRuleTagOptions
      }
      return []
    },
    targetRuleCriteriaValueOptions (): SelectItem[] | undefined {
      switch (this.criteriaValue) {
        case RepeatForOptions.BoardMembers:
          return this.companyIndividuals.map((i): SelectItem => ({
            text: i.preferredName,
            value: i.id
          }))
        case RepeatForOptions.Committees:
          return this.companyCommittees.map((i): SelectItem => ({
            text: i.name,
            value: i.id
          }))
      }
      return []
    },
    targetRuleTagOptions (): SelectItem[] | undefined {
      switch (this.criteriaValue) {
        case RepeatForOptions.Committees:
          return this.allCommitteeTags.map((tag: CommitteeTag) => ({
            text: this.$t(`global.committeeTags.${tag}`) as string,
            value: tag
          }))
      }
      return []
    },
    allCommitteeTags (): CommitteeTag[] {
      return this.companyCommittees.reduce(
        (tags: CommitteeTag[], committee: PlatformCommittee): CommitteeTag[] => {
          committee.tags?.forEach((tag: CommitteeTag): void => {
            if (!tags.includes(tag)) {
              tags.push(tag)
            }
          })
          return tags
        },
        []
      )
    },
    schema () {
      const schema: any = {
        type: {
          type: 'BBSelectField',
          required: true,
          label: this.$t('form.questionnaire.targetRules.menu.ruleTypeLabel') as string,
          items: this.targetRuleTypeOptions,
          col: { xs: 12, sm: 4 },
          class: 'mr-2',
          hideDetails: true
        },
        filter: {
          type: 'BBSelectField',
          required: true,
          label: this.$t('form.questionnaire.targetRules.menu.filterLabel') as string,
          items: TargetRuleFilterSelectItems,
          col: { xs: 12, sm: 3 },
          class: 'mr-2',
          hideDetails: true,
          ref: 'filterField'
        },
        value: {
          type: 'BBSelectField',
          required: true,
          label: this.$t('form.questionnaire.targetRules.menu.valueLabel') as string,
          items: this.targetRuleValueOptions,
          col: { xs: 12, sm: 5 },
          disabled: !this.targetRule.type,
          hideDetails: true,
          ref: 'valueField'
        }
      }

      return schema
    },
    availableTeamTypes (): SelectItem[] {
      const AvailableTeamTypes: CompanyTeamTypes[] = Object.values(CompanyTeamTypes) as CompanyTeamTypes[]
      return AvailableTeamTypes.map((teamId: CompanyTeamTypes): SelectItem => {
        return {
          text: this.$t(`global.companyTeams.${teamId}`) as string,
          value: teamId
        } as SelectItem
      })
    }
  },
  methods: {
    onInput ({ on, data }: { on: string, data: any }): void {
      if (on === 'input') {
        this.targetRule = data
      }
    }
  }
})
</script>
