<template>
  <v-row
    no-gutters
    align="center"
    justify="center"
    class="text-caption grey--text text--darken-2 pt-1"
  >
    <span
      class="d-inline-block"
      v-if="finishedAt"
      v-tooltip:bottom="displayFinishedAt"
    >
      {{ $t('analysis.generateReport.reportDocuments.lastGenerated') }}
      <strong>{{ timeSinceCompletion }}</strong>.
    </span>
    <span
      class="d-inline-block warning--text"
      v-else-if="failedAt"
      v-tooltip:bottom="displayFailedAt"
    >
      {{ $t('analysis.generateReport.reportDocuments.processingFailed') }}
    </span>
    <span
      class="d-inline-block"
      v-else-if="startedProcessingAt"
      v-tooltip:bottom="displayStartedProcessingAt"
    >
      {{ $t('analysis.generateReport.reportDocuments.processing') }}...
    </span>
    <span
      class="d-inline-block"
      v-else-if="displayQueuedAt"
      v-tooltip:bottom="displayQueuedAt"
    >
      {{ $t('analysis.generateReport.reportDocuments.queued') }}...
    </span>
    <div
      v-else
      class="d-inline-block pr-1"
    >
      <platform-spinner color="grey" :size="12" :width="2" />
    </div>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { LongDateTimeDisplayFormat } from '@/components/PlatformDateDisplay.vue'
import dayjs from 'dayjs'

export default Vue.extend({
  name: 'ReportDocumentStatus',
  components: {
    PlatformSpinner
  },
  props: {
    finishedAt: {
      type: String,
      required: false
    },
    startedProcessingAt: {
      type: String,
      required: false
    },
    queuedAt: {
      type: String,
      required: false
    },
    failedAt: {
      type: String,
      required: false
    }
  },
  computed: {
    timeSinceCompletion (): string | undefined {
      if (this.finishedAt) {
        return dayjs(this.finishedAt).fromNow()
      }
      return undefined
    },
    displayFinishedAt (): string | undefined {
      if (this.finishedAt) {
        return this.$t(
          'analysis.generateReport.reportDocuments.status.generated',
          [dayjs(this.finishedAt).format(LongDateTimeDisplayFormat)]) as string
      }
      return undefined
    },
    displayStartedProcessingAt (): string | undefined {
      if (this.startedProcessingAt) {
        return this.$t(
          'analysis.generateReport.reportDocuments.status.startedProcessingAt',
          [dayjs(this.startedProcessingAt).format(LongDateTimeDisplayFormat)]) as string
      }
      return undefined
    },
    displayQueuedAt (): string | undefined {
      if (this.queuedAt) {
        return this.$t(
          'analysis.generateReport.reportDocuments.status.queuedAt',
          [dayjs(this.queuedAt).format(LongDateTimeDisplayFormat)]) as string
      }
      return undefined
    },
    displayFailedAt (): string | undefined {
      if (!this.failedAt) {
        return undefined
      }
      const displayFailedAt = dayjs(this.failedAt).format(LongDateTimeDisplayFormat)
      return this.$t(
        'analysis.generateReport.reportDocuments.status.failedAt',
        [displayFailedAt]
      ) as string
    }
  }
})
</script>
