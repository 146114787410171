<template>
  <div>
    <platform-modal
      :value="showModal"
      icon="document"
      close-button
      :title="$t('analysis.reportContent.recommendationsModal.title')"
      :cancel="false"
      :persistent="false"
      :loading="pendingFetchRecommendation"
      :width="showRecommendations ? 1000 : 550"
      @close="showModal = false"
    >
      <v-row>
        <v-col
          :cols="12"
          :md="showRecommendations ? 5 : undefined"
        >
          <v-row no-gutters justify="center" align="center" class="flex-column">
            <h3 v-if="showRecommendations" class="sans-serif primary-header text-center">
              {{ $t('analysis.reportContent.recommendationsModal.previewsHeader') }}
            </h3>
            <div class="px-8 pt-3 pb-10 report-document__images d-flex flex-column align-center">
              <component
                v-if="reportPreviewComponent"
                v-bind="reportPreviewComponentProps"
                class="report-document__image mb-4 elevation-6"
                :is="reportPreviewComponent"
              />
              <template v-if="images && images.length">
                <img
                  v-for="(imgSrc, index) in images"
                  :key="index"
                  :src="imgSrc"
                  :alt="imgAlt"
                  class="v-responsive report-document__image mb-4 elevation-6"
                />
              </template>
            </div>
          </v-row>
        </v-col>
        <v-col
          v-if="showRecommendations"
          :cols="12"
          :md="7"
          class="d-flex flex-column"
        >
          <div class="shrink text-center">
            <h3 class="sans-serif primary-header text-center mb-1">
              {{ $t('analysis.reportContent.recommendationsModal.recommendationsHeader') }}
            </h3>
            <span class="d-block text-caption text--compact-2 grey--text text--darken-1">
              {{ $t('analysis.reportContent.recommendationsModal.recommendationsInstruction') }}
            </span>
          </div>
          <div
            class="recommendations__container flex-basis-0 grow mt-3 px-3 py-2"
          >
            <platform-spinner
              v-if="pendingFetchRecommendation"
              class="mx-auto mt-3"
            />
            <template v-else-if="recommendationItems && recommendationItems.length">
              <div
                v-for="(recommendation, index) in recommendationItems"
                :key="index"
                class="mb-5"
              >
                <div v-if="recommendation.instruction" class="recommendation-instruction grey--text pb-1">
                  {{ recommendation.instruction }}
                </div>
                <v-card
                  class="px-3 py-2"
                  @click="handleInsertRecommendedContent(recommendation)"
                  @mouseover="setPreviewContent(recommendation.content)"
                  @mouseleave="clearPreviewContent"
                >
                  <display-markdown
                    :value="recommendation.content"
                    class="recommendation-content grey--text text--darken-3"
                  />
                  <v-row no-gutters justify="end" class="pt-2">
                    <platform-button
                      secondary
                      default-case
                      small
                      compact
                      icon="text-box-plus"
                      :icon-size="16"
                    >
                      {{ $t('analysis.reportContent.recommendationsModal.insertRecommendationAction') }}
                    </platform-button>
                  </v-row>
                </v-card>
              </div>
            </template>
            <span v-else class="grey--text text--darken-1">
              {{ $t('analysis.reportContent.recommendationsModal.noResults') }}
            </span>
          </div>
        </v-col>
      </v-row>
    </platform-modal>
    <platform-button
      v-tooltip:left="insertRecommendationsActionTooltip"
      secondary
      default-case
      :icon="insertRecommendationsActionIcon"
      @click="showModal = true"
    >
      {{ insertRecommendationsActionContent }}
    </platform-button>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { debounce } from 'lodash'
import { RecommendationContent, RecommendationResponse } from '@betterboards/shared/types/Recommendations'
import { PlatformSurveyGroup } from '@betterboards/shared/types/SurveyGroup'
import { PlatformReportSection } from '@betterboards/shared/types/Report'
import { PlatformReport, ReportSectionResult } from '@/types/Report'
import { GraphResult, ResponsesResult } from '@betterboards/shared/types/ResultSet'
import { PlatformSection, QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import { RecommendationContext } from '@betterboards/shared/helpers/entities/Report'
import { ReportContextItem, requestRecommendation } from '@/helpers/recommendations'
import DividerPageSummary from '@/assets/images/analysis/context/divider-page-summary.png'
import KeyFindings from '@/assets/images/analysis/context/key-findings.png'
import EvaluationInformation from '@/assets/images/analysis/context/evaluation-information.png'
import CommitteesStructurePage from '@/assets/images/analysis/context/committees-general.png'
import CommitteesPage from '@/assets/images/analysis/context/committees-page.png'
import IndividualStrengths from '@/assets/images/analysis/context/individual-strengths.png'
import SectionIntroduction from '@/assets/images/analysis/context/section-introduction.png'
import FocusAreas from '@/assets/images/analysis/context/focus-areas.png'
import PlatformModal from '@/components/PlatformModal.vue'
import DisplayMarkdown from '@/components/DisplayMarkdown.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import KeyFindingsReportPreview from '@/components/analysis/report-previews/KeyFindingsReportPreview.vue'
import FocusAreasReportPreview from '@/components/analysis/report-previews/FocusAreasReportPreview.vue'
import EvaluationInformationReportPreview from '@/components/analysis/report-previews/EvaluationInformationReportPreview.vue'
import SectionIntroductionReportPreview from '@/components/analysis/report-previews/SectionIntroductionReportPreview.vue'
import CommitteeStructureReportPreview from '@/components/analysis/report-previews/CommitteeStructureReportPreview.vue'

const debouncedFunc = debounce((callback) => callback(), 500, { trailing: true })

export default Vue.extend({
  name: 'ReportContextButton',
  components: {
    PlatformSpinner,
    DisplayMarkdown,
    PlatformModal
  },
  props: {
    value: { type: String as PropType<ReportContextItem>, required: true },
    result: { type: Object as PropType<ResponsesResult | GraphResult>, required: false },
    reportResult: { type: Object as PropType<ReportSectionResult>, required: false },
    section: { type: Object as PropType<PlatformSection>, required: false },
    subSection: { type: Object as PropType<{ name: string, slug: string }>, required: false },
    reportSection: { type: Object as PropType<PlatformReportSection>, required: false },
    masterSectionId: { type: String as PropType<string | undefined>, required: false },
    masterBlockId: { type: String as PropType<string | undefined>, required: false },
    criteriaId: { type: String as PropType<string | undefined>, required: false }
  },
  data: () => ({
    showModal: false,
    pendingFetchRecommendation: false,
    recommendationItems: <RecommendationContent[] | null>null,
    previewContent: <string | null>null
  }),
  watch: {
    showModal (val: boolean): void {
      if (!val) {
        return
      }
      this.fetchRecommendation()
    }
  },
  computed: {
    selectedVariant (): QuestionnaireVariantCode {
      return this.$store.state.Analysis.variantCode
    },
    surveyGroupId (): string {
      return this.$route.params.surveyId
    },
    selectedReport (): PlatformReport | null {
      return this.$store.getters['Report/selectedReport']
    },
    packageId (): string | undefined {
      const surveyGroup = this.$store.state.SurveyGroup.list.find(
        (s: PlatformSurveyGroup) => s.id === this.surveyGroupId
      )
      if (!surveyGroup) {
        return undefined
      }
      return surveyGroup.packageId
    },
    reportPreviewComponent (): Vue.Component | undefined {
      switch (this.value) {
        case ReportContextItem.KeyFindings:
          return KeyFindingsReportPreview
        case ReportContextItem.FocusAreas:
          return FocusAreasReportPreview
        case ReportContextItem.EvaluationInformation:
          return EvaluationInformationReportPreview
        case ReportContextItem.SectionIntroduction:
          return SectionIntroductionReportPreview
        case ReportContextItem.CommitteesStructurePage:
          return CommitteeStructureReportPreview
      }
      return undefined
    },
    reportPreviewComponentProps (): any {
      return {
        result: this.result,
        section: this.section,
        subSection: this.subSection,
        reportSection: this.reportSection,
        reportResult: this.reportResult,
        report: this.selectedReport,
        previewContent: this.previewContent
      }
    },
    images (): string[] | undefined {
      if (this.reportPreviewComponent) {
        return undefined
      }
      switch (this.value) {
        case ReportContextItem.DividerPageSummary:
          return [DividerPageSummary]
        case ReportContextItem.KeyFindings:
          return [KeyFindings]
        case ReportContextItem.EvaluationInformation:
          return [EvaluationInformation]
        case ReportContextItem.CommitteesPage:
          return [CommitteesPage, CommitteesStructurePage]
        case ReportContextItem.CommitteesStructurePage:
          return [CommitteesStructurePage]
        case ReportContextItem.IndividualStrengths:
          return [KeyFindings, IndividualStrengths]
        case ReportContextItem.SectionIntroduction:
          return [SectionIntroduction]
        case ReportContextItem.FocusAreas:
          return [FocusAreas]
        default:
          return undefined
      }
    },
    imgAlt (): string | undefined {
      switch (this.value) {
        default:
          return this.value.replace(/([A-Z])/g, ' $1').trim()
      }
    },
    recommendationContext (): RecommendationContext | undefined {
      switch (this.value) {
        case ReportContextItem.SectionIntroduction:
          return RecommendationContext.SectionIntroduction
        case ReportContextItem.DividerPageSummary:
          return RecommendationContext.DividerPageSummary
        case ReportContextItem.KeyFindings:
          return RecommendationContext.KeyFindings
        case ReportContextItem.CommitteesStructurePage:
          return RecommendationContext.CommitteesStructureSummary
        case ReportContextItem.FocusAreas:
          return RecommendationContext.FocusAreas
        case ReportContextItem.EvaluationInformation:
          return RecommendationContext.EvaluationInformation
      }
      return undefined
    },
    showRecommendations (): boolean {
      return !!this.recommendationContext && !!this.$listeners.insertRecommendation
    },
    insertRecommendationsActionContent (): string | undefined {
      if (this.showRecommendations) {
        return this.$t('analysis.reportContent.insertRecommendationsAction') as string
      }
      return this.$t('analysis.reportContent.viewExamplesAction') as string
    },
    insertRecommendationsActionIcon (): string | undefined {
      if (this.showRecommendations) {
        return 'text-box-plus'
      }
      return 'preview-text'
    },
    insertRecommendationsActionTooltip (): string | undefined {
      if (this.showRecommendations) {
        return this.$t('analysis.reportContent.insertRecommendationsTooltip') as string
      }
      return this.$t('analysis.reportContent.viewExamplesTooltip') as string
    }
  },
  methods: {
    async fetchRecommendation (): Promise<void> {
      if (!this.recommendationContext || this.pendingFetchRecommendation) {
        return
      }
      this.recommendationItems = null
      this.pendingFetchRecommendation = true
      let response: RecommendationResponse
      try {
        response = await requestRecommendation(
          this.surveyGroupId,
          this.packageId,
          this.selectedVariant,
          this.recommendationContext,
          this.masterSectionId,
          this.masterBlockId,
          this.criteriaId
        )
      } catch (err) {
        console.warn('Failed to find recommendation content for field:', err)
        throw err
      } finally {
        this.pendingFetchRecommendation = false
      }
      this.recommendationItems = response.recommendations
    },
    handleInsertRecommendedContent (recommendation: RecommendationContent): void {
      this.$emit('insertRecommendation', recommendation.content)
      this.showModal = false
    },
    setPreviewContent (recommendationItems: string): void {
      debouncedFunc(() => {
        this.previewContent = recommendationItems
      })
    },
    clearPreviewContent (): void {
      debouncedFunc(() => {
        this.previewContent = null
      })
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.report-document__images {
  overflow-y: auto;
  min-height: 55vh;
  max-height: 70vh;
  .report-document__image {
    max-height: 55vh;
  }
}

.recommendations__container {
  overflow-y: auto;
  .recommendation-instruction {
    font-size: 12px;
    line-height: 1.25;
  }
  .recommendation-content {
    font-size: 11px;
    line-height: 1.25;
  }
}
</style>
