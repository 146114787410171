import Vue from 'vue'
import { Validators } from '@/helpers/validation'

export const InputFieldMixin: any = Vue.extend({
  props: {
    value: { type: [String, Number], required: false },
    label: { type: String, required: false },
    loading: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    rules: { type: Array, required: false },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    outlined: { type: Boolean, default: false },
    solo: { type: Boolean, required: false },
    flat: { type: Boolean, required: false },
    hideDetails: { type: Boolean, required: false },
    prependIcon: { type: String, required: false },
    appendIcon: { type: String, required: false },
    attrs: { type: Object, required: false },
    on: { type: Object, required: false },
    placeholder: { type: String, required: false },
    persistentPlaceholder: { type: Boolean, default: false }
  },
  computed: {
    inputValue: {
      set (val: string | number): void {
        this.$emit('input', val)
      },
      get (): string | number {
        return this.value
      }
    },
    validationRules (): any[] {
      const rules: any[] = []
      if (this.required) {
        rules.push(Validators.required)
      }
      if (Array.isArray(this.rules)) {
        rules.push(...this.rules)
      }
      return rules
    }
  },
  methods: {
    validate (force: boolean = false): void {
      const input: any = this.$refs.input
      input.validate(force)
    }
  }
})
