<template>
  <v-container class="fill-height">
    <board-member-dashboard v-if="isBoardMember" />
    <default v-else />
  </v-container>
</template>

<script lang="ts">
import { mapActions, mapState } from 'vuex'
import Vue from 'vue'
import Default from '@/components/dashboard/Default.vue'
import BoardMemberDashboard from '@/components/dashboard/BoardMember.vue'
import { AccountType } from '@/API'

export default Vue.extend({
  name: 'DashboardContainer',
  components: {
    Default,
    BoardMemberDashboard
  },
  computed: {
    ...mapState('Company', ['selectedCompany', 'list', 'currentRole']),
    ...mapState('User', ['individual']),
    isBoardMember (): boolean {
      return this.currentRole === AccountType.BoardMember
    }
  },
  methods: {
    ...mapActions('Company', ['selectCompany', 'clearSelectedCompany']),
    ...mapActions('User', ['roleByCompanyId'])
  }
})
</script>
