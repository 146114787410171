<template>
  <survey-question
    v-if="questionData"
    :question="questionData"
    v-model="answer"
    :is-preview="isPreview"
    @next="$emit('next')"
    @valid="(val) => $emit('valid', val)"
  />
</template>

<script lang="ts">
import SurveyQuestion from '@/components/questionnaire/survey/blocks/variants/questions/Question.vue'
import { QuestionBlockData } from '@/types/Question'
import Vue from 'vue'

export default Vue.extend({
  name: 'SurveyQuestionBlock',
  components: {
    SurveyQuestion
  },
  props: {
    block: { type: Object, required: true },
    value: { type: [Object, Array, String, Number], required: false }, // answer
    isPreview: { type: Boolean, default: false }
  },
  computed: {
    questionData (): QuestionBlockData | null {
      if (!this.block?.data) {
        return null
      }
      return typeof this.block.data === 'string' ? JSON.parse(this.block.data) : this.block.data
    },
    answer: {
      get (): any {
        return this.value
      },
      set (val: any): void {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    //
  }
})
</script>
