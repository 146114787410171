import { generateSelectItems } from '@/helpers/forms'
import { TargetGroupOptions } from '@betterboards/shared/types/Block'

export enum QuestionTypes {
  Text = 'text',
  MultiText = 'multi-text',
  Scale = 'scale',
  MultipleChoice = 'MultipleChoice',
}

export enum QuestionFriendlyNames {
  Text = 'Text',
  MultiText = 'Multiple Text Fields',
  Scale = 'Scale',
  MultipleChoice = 'Multiple Choice',
}

export const QuestionFriendlyNamesMap = {
  [QuestionTypes.Text]: 'Text',
  [QuestionTypes.MultiText]: 'Multiple Text Fields',
  [QuestionTypes.Scale]: 'Scale',
  [QuestionTypes.MultipleChoice]: 'Multiple Choice'
}

export const QuestionTypeItems = generateSelectItems(QuestionTypes, QuestionFriendlyNames)

export const QuestionTextField = {
  type: 'textarea',
  label: 'Question Text'
}

export enum RepeatForOptions {
  None = 'None',
  BoardMembers = 'BoardMembers',
  Committees = 'Committees',
  KnowhowAreas = 'KnowhowAreas',
  Roles = 'Roles'
}

export const RepeatForFriendlyNames = {
  [RepeatForOptions.None]: 'None',
  [RepeatForOptions.BoardMembers]: 'Board Members',
  [RepeatForOptions.Committees]: 'Committees',
  [RepeatForOptions.KnowhowAreas]: 'Know-how Areas',
  [RepeatForOptions.Roles]: 'Role Behaviours'
}

export const RepeatForItems = generateSelectItems(RepeatForOptions, RepeatForFriendlyNames)

export enum RepeatForPlaceholder {
  KnowHowArea = 'know_how_area',
  Role = 'role',
  BoardMemberName = 'board_member_name',
  Committees = 'committee',
}

export const RepeatForPlaceholderMap: { [key: string]: RepeatForPlaceholder } = {
  [RepeatForOptions.KnowhowAreas]: RepeatForPlaceholder.KnowHowArea,
  [RepeatForOptions.Roles]: RepeatForPlaceholder.Role,
  [RepeatForOptions.BoardMembers]: RepeatForPlaceholder.BoardMemberName,
  [RepeatForOptions.Committees]: RepeatForPlaceholder.Committees
}

export const getRepeatForPlaceholder = (value: RepeatForPlaceholder): string => `[${value}]`

export const TargetGroupFriendlyNames = {
  [TargetGroupOptions.Executives]: 'Executives',
  [TargetGroupOptions.NonExecutives]: 'Non-Executives',
  [TargetGroupOptions.ShareholderRepresentative]: 'Shareholder Representatives',
  [TargetGroupOptions.LabourRepresentative]: 'Labour Representatives',
  [TargetGroupOptions.InternalStakeholders]: 'Internal Stakeholders',
  [TargetGroupOptions.ExternalStakeholders]: 'External Stakeholders'
}

export const TargetGroupItems = generateSelectItems(TargetGroupOptions, TargetGroupFriendlyNames)

export enum ScaleCriteriaOptions {
  Agreement = 'Agreement',
  Importance = 'Importance',
  Development = 'Development'
}

export const ScaleCriteriaFriendlyNames = {
  [ScaleCriteriaOptions.Agreement]: 'Agree',
  [ScaleCriteriaOptions.Importance]: 'Importance',
  [ScaleCriteriaOptions.Development]: 'Development'
}

export const ScaleCriteriaItems = generateSelectItems(ScaleCriteriaOptions, ScaleCriteriaFriendlyNames)
