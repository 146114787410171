<template>
  <div class="object-image" :class="classes">
    <img
      v-if="imgSrc"
      ref="image"
      :alt="placeholder || 'image'"
      :title="title || placeholder"
      :src="imgSrc"
      :style="imgStyle"
      @load="(evt) => $emit('loaded', evt)"
    />
    <span v-else class="placeholder">{{ placeholder }}</span>
  </div>
</template>

<script>
import Vue from 'vue'
import { loadImage, addImageListener, removeImageListener } from '@/helpers/forms'

export default Vue.extend({
  props: {
    objectType: {
      type: String,
      required: true
    },
    objectId: {
      type: String,
      required: true
    },
    companyId: {
      type: String,
      required: false
    },
    listen: {
      type: Boolean,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    placeholderSize: {
      type: String,
      default: 'md'
    },
    title: {
      type: String,
      required: false
    },
    width: {
      type: [Number, String],
      required: false
    },
    height: {
      type: [Number, String],
      required: false
    },
    maxWidth: {
      type: [Number, String],
      required: false
    },
    maxHeight: {
      type: [Number, String],
      required: false
    },
    rounded: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      imgSrc: null,
      lastCompanyId: undefined,
      loaded: false,
      failed: false,
      initializedListener: false
    }
  },
  mounted () {
    this.fetchImage()
    if (this.initListener()) {
      this.initializedListener = true
    }
    this.lastCompanyId = this.companyId
  },
  watch: {
    objectId (newVal, oldVal) {
      if (newVal === oldVal) {
        return
      }
      if (this.imageListener) {
        removeImageListener(this.objectType, oldVal, this.lastCompanyId, this.imageListener)
      }
      this.initializedListener = false
      this.fetchImage()
      this.initListener()
    },
    companyId (newVal) {
      this.lastCompanyId = newVal
    }
  },
  computed: {
    imgStyle () {
      return {
        width: this.width && Number.isInteger(this.width) ? `${this.width}px` : this.width,
        height: this.height && Number.isInteger(this.height) ? `${this.height}px` : this.height,
        maxWidth: this.maxWidth && Number.isInteger(this.maxWidth) ? `${this.maxWidth}px` : this.maxWidth,
        maxHeight: this.maxHeight && Number.isInteger(this.maxHeight) ? `${this.maxHeight}px` : this.maxHeight
      }
    },
    classes () {
      const classes = []
      if (this.rounded) {
        classes.push('rounded')
      }
      if (['xl', 'lg', 'md', 'sm', 'xs'].includes(this.placeholderSize)) {
        classes.push(`placeholder--${this.placeholderSize}`)
      }
      return classes
    }
  },
  methods: {
    async fetchImage () {
      if (this.objectType && this.objectId) {
        this.imgSrc = null
        this.loaded = false
        try {
          const link = await loadImage(this.objectType, this.objectId, this.companyId)
          if (link) {
            this.$emit('fetched')
            this.imgSrc = link
            this.loaded = true
          }
        } catch (e) {
          this.loaded = false
          this.failed = true
          this.$emit('failed')
        }
      }
    },
    initListener () {
      if (this.initializedListener) {
        return
      }
      if (this.listen && this.objectType && this.objectId) {
        this.imageListener = (value) => {
          this.imgSrc = value
        }
        addImageListener(this.objectType, this.objectId, this.companyId, this.imageListener)
        return true
      }
      return false
    }
  }
})
</script>

<style lang="scss">
.object-image {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
  }
  &.rounded {
    img {
      border-radius: 6px;
    }
  }
  &.placeholder--xl {
    font-size: 16px;
  }
  &.placeholder--lg {
    font-size: 14px;
  }
  &.placeholder--md {
    font-size: 12px;
  }
  &.placeholder--sm {
    font-size: 10px;
  }
  &.placeholder--xs {
    font-size: 8px;
  }
}
</style>
