var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"pt-8 pb-8"},_vm._l((_vm.question.groups),function(group){return _c('v-col',{key:group.id,staticClass:"d-flex flex-column align-center"},[_c('h2',{directives:[{name:"show",rawName:"v-show",value:(group.name !== 'Top'),expression:"group.name !== 'Top'"}]},[_vm._v(" "+_vm._s(group.name)+" ")]),_vm._l((_vm.answers[group.id]),function(input,index){return _c('platform-text-area',{key:index,class:{
        'px-4': _vm.$vuetify.breakpoint.mdAndUp,
        'px-12': _vm.$vuetify.breakpoint.xlOnly
      },style:({
        minWidth: '200px',
        width: '100%'
      }),attrs:{"clearable":"","auto-grow":"","rows":1,"disabled":_vm.isPreview,"label":(index + 1).toString(),"rules":_vm.form.validation.answer},on:{"blur":_vm.syncAnswers},model:{value:(_vm.answers[group.id][index]),callback:function ($$v) {_vm.$set(_vm.answers[group.id], index, $$v)},expression:"answers[group.id][index]"}})})],2)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }