<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 283.46 283.46" style="enable-background:new 0 0 283.46 283.46;" xml:space="preserve">
    <g>
      <path class="st0" d="M141.69,0.27C63.54,0.27-0.04,63.85-0.04,142s63.58,141.73,141.73,141.73S283.42,220.15,283.42,142
        S219.84,0.27,141.69,0.27z M141.69,278.43C66.46,278.43,5.26,217.23,5.26,142S66.46,5.57,141.69,5.57S278.12,66.77,278.12,142
        S216.92,278.43,141.69,278.43z"
            fill="currentColor"
      />
      <path class="st0" d="M108.71,106.55h66.95c1.46,0,2.65-1.19,2.65-2.65s-1.19-2.65-2.65-2.65h-66.95c-1.46,0-2.65,1.19-2.65,2.65
        S107.24,106.55,108.71,106.55z"
            fill="currentColor"
      />
      <path class="st0" d="M108.71,124.61h66.95c1.46,0,2.65-1.19,2.65-2.65s-1.19-2.65-2.65-2.65h-66.95c-1.46,0-2.65,1.19-2.65,2.65
        C106.05,123.42,107.24,124.61,108.71,124.61z"
            fill="currentColor"
      />
      <path class="st0" d="M201.66,222.8l10.32-10.26c0.05-0.05,0.04-0.11-0.02-0.13l-10.21-4.61V93.12c0-1.91-0.98-4.27-2.33-5.62
        l-0.09-0.09c0,0,0-0.01-0.01-0.01l-25.33-25.24l0,0l0,0l-0.09-0.09c-1.35-1.35-3.71-2.32-5.62-2.32H86.76
        c-2.67,0-4.84,2.17-4.84,4.84V177.5l0.1,31.61c0.01,2.67,2.19,4.84,4.85,4.84h91.3l9.93,22.19c0.02,0.03,0.04,0.05,0.07,0.05
        c0.02,0,0.04-0.01,0.06-0.03l9.66-9.61l15.47,15.47c0.52,0.52,1.2,0.78,1.88,0.78c0.68,0,1.36-0.26,1.88-0.78
        c1.04-1.04,1.04-2.71,0-3.75L201.66,222.8z M173.12,68.78l19.56,19.49h-19.56V68.78z M175.79,208.63H87.32l-0.09-26.76L87.22,65.05
        h80.59V88.6c0,2.75,2.24,4.98,4.98,4.98h23.65V205.4l-27.59-12.45c-0.02-0.01-0.03-0.01-0.04-0.01c-0.03,0-0.04,0.03-0.02,0.07
        L175.79,208.63z M189.86,227.06l-10.56-23.58l23.57,10.64L189.86,227.06z"
            fill="currentColor"
      />
      <path class="st0" d="M123.83,160.16v-14.54c0-1.47-1.19-2.65-2.65-2.65h-19.39c-1.46,0-2.65,1.19-2.65,2.65v14.54
        c0,1.47,1.19,2.65,2.65,2.65h19.39C122.64,162.81,123.83,161.63,123.83,160.16z M118.53,157.51h-14.08v-9.23h14.08V157.51z"
            fill="currentColor"
      />
      <path class="st0" d="M131.84,142.97c-1.46,0-2.65,1.19-2.65,2.65v14.54c0,1.47,1.19,2.65,2.65,2.65h19.39
        c1.46,0,2.65-1.19,2.65-2.65v-14.54c0-1.47-1.19-2.65-2.65-2.65H131.84z M148.58,157.51H134.5v-9.23h14.08V157.51z"
            fill="currentColor"
      />
      <path class="st0" d="M183.93,160.16v-14.54c0-1.47-1.19-2.65-2.65-2.65h-19.39c-1.46,0-2.65,1.19-2.65,2.65v14.54
        c0,1.47,1.19,2.65,2.65,2.65h19.39C182.74,162.81,183.93,161.63,183.93,160.16z M178.63,157.51h-14.08v-9.23h14.08V157.51z"
            fill="currentColor"
      />
      <path class="st0" d="M135.17,169.61h-19.39c-1.46,0-2.65,1.19-2.65,2.65v14.54c0,1.47,1.19,2.65,2.65,2.65h19.39
        c1.46,0,2.65-1.19,2.65-2.65v-14.54C137.83,170.79,136.64,169.61,135.17,169.61z M132.52,184.14h-14.08v-9.23h14.08V184.14z"
            fill="currentColor"
      />
      <path class="st0" d="M165.22,169.61h-19.39c-1.46,0-2.65,1.19-2.65,2.65v14.54c0,1.47,1.19,2.65,2.65,2.65h19.39
        c1.46,0,2.65-1.19,2.65-2.65v-14.54C167.88,170.79,166.69,169.61,165.22,169.61z M162.57,184.14h-14.08v-9.23h14.08V184.14z"
            fill="currentColor"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'CreateQuestionnaireIcon'
})
</script>
