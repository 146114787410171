<template>
  <v-btn-toggle
    v-if="visible"
    :value="disabled ? undefined : activeQuestionnaireVariant"
    :mandatory="!disabled && !stateless"
    :dark="!loading && color === 'primary'"
  >
    <platform-button
      v-for="variant in variants"
      :key="variant.variantCode"
      :value="variant.variantCode"
      :disabled="disabled || loading"
      :loading="loading && variant.variantCode === value"
      v-tooltip="tooltipContent ? $t(
        tooltipContent,
        [variant.name]
      ) : undefined"
      :compact="!large"
      :large="large"
      :primary="color === 'primary'"
      :secondary="color === 'secondary'"
      @click="activeQuestionnaireVariant = variant.variantCode"
    >
      {{ variant.variantCode }}
    </platform-button>
  </v-btn-toggle>
</template>

<script lang="ts">
import { PlatformEntityTypes, PlatformQuestionnaireVariant, QuestionnaireVariantCode } from '@/types/Platform'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'VariantPicker',
  props: {
    entityType: { type: String as PropType<PlatformEntityTypes>, required: true },
    value: { type: String as PropType<QuestionnaireVariantCode>, required: false },
    selectedQuestionnaireVariant: { type: String as PropType<QuestionnaireVariantCode>, required: false },
    variants: { type: Array as PropType<PlatformQuestionnaireVariant[]>, required: true },
    color: { type: String as PropType<'primary' | 'secondary'>, default: 'secondary' },
    disabled: { type: Boolean, default: false },
    stateless: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    tooltip: { type: Boolean, default: true },
    large: { type: Boolean, default: false }
  },
  data: () => ({
    activeQuestionnaireVariant: <QuestionnaireVariantCode | undefined>undefined
  }),
  mounted (): void {
    this.activeQuestionnaireVariant = this.value
    this.setSelectedQuestionnaireVariant()
  },
  watch: {
    value (val): void {
      if (this.disabled) {
        return
      }
      this.activeQuestionnaireVariant = val
    },
    selectedQuestionnaireVariant: 'setSelectedQuestionnaireVariant',
    activeQuestionnaireVariant (val: QuestionnaireVariantCode | undefined): void {
      if (this.disabled || !val) {
        return
      }
      if (this.stateless) {
        this.$nextTick(() => {
          this.activeQuestionnaireVariant = undefined
        })
      }
      this.$emit('input', val)
    }
  },
  computed: {
    visible (): boolean {
      return this.variants.length > 1
    },
    tooltipContent (): string | undefined {
      if (!this.tooltip) {
        return undefined
      }
      switch (this.entityType) {
        case PlatformEntityTypes.Section:
          return 'form.questionnaire.variant.pickSectionVariantTooltip'
        case PlatformEntityTypes.Block:
          return 'form.questionnaire.variant.pickBlockVariantTooltip'
        case PlatformEntityTypes.Questionnaire:
          return 'form.questionnaire.variant.pickQuestionnaireVariantTooltip'
        case PlatformEntityTypes.Result:
          return 'form.questionnaire.variant.pickResultVariantTooltip'
      }
      return undefined
    }
  },
  methods: {
    /**
     * setSelectedQuestionnaireVariant - Used to override the locally selected variant `activeQuestionnaireVariant` with the globally selected value
     */
    setSelectedQuestionnaireVariant (): void {
      if (this.selectedQuestionnaireVariant) {
        this.activeQuestionnaireVariant = this.selectedQuestionnaireVariant
      }
    }
  }
})
</script>
