import API from '@/services/API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { PlatformSurvey } from '@/types/Platform'
import { PlatformSurveyGroup, QuestionnaireListItem, SurveyGroupProgress } from '@/types/SurveyGroup'
import { GetFullSurveyGroupQuery, ListFullSurveyGroupsByQuestionnaireQuery } from '@/API'
import { getFullSurveyGroup } from '@/graphql/custom/getFullSurveyGroup'
import { listFullSurveyGroupsByQuestionnaire } from '@/graphql/custom/listFullSurveyGroupsByQuestionnaire'
import { calculateCompletion } from '@/helpers/survey'

export function calculateSurveyGroupCompletion (group: PlatformSurvey[]): number | undefined {
  if (!group?.length) {
    return undefined
  }
  const totalCompletion = group.reduce((total, survey) => total + (calculateCompletion(survey) ?? 0), 0)
  return totalCompletion / group.length
}

export function getSurveyGroupCompletionDisplayVal (group: PlatformSurvey[]): string | undefined {
  const val = calculateSurveyGroupCompletion(group)
  if (val === undefined) {
    return undefined
  }
  return `${Math.round(val)}%`
}

export function SortSurveyGroups (a: SurveyGroupProgress, z: SurveyGroupProgress): number {
  if (a.companyId !== z.companyId) {
    return 0
  }
  if (!a.releasedAt) {
    return 1
  }
  if (!z.releasedAt) {
    return -1
  }
  return z.releasedAt.localeCompare(a.releasedAt)
}

/**
 * Sort function for a list of QuestionnaireListItems (Questionnaires or SurveyGroups)
 */
export function SortQuestionnairesSurveyGroups (a: QuestionnaireListItem, z: QuestionnaireListItem): number {
  if (a.companyId !== z.companyId) {
    return 0
  }

  const aTimestamp = a.releasedAt ?? a.createdAt
  const zTimestamp = z.releasedAt ?? z.createdAt
  if (!aTimestamp) {
    return 1
  }
  if (!zTimestamp) {
    return -1
  }
  return zTimestamp.localeCompare(aTimestamp)
}

export async function getSurveyGroupById (surveyGroupId: string): Promise<PlatformSurveyGroup | undefined> {
  let response: GraphQLResult<GetFullSurveyGroupQuery>
  try {
    response = await API.graphql({
      query: getFullSurveyGroup,
      variables: {
        id: surveyGroupId
      }
    }) as GraphQLResult<GetFullSurveyGroupQuery>
  } catch (err) {
    console.error('Failed to query Survey Group by ID.', JSON.stringify({ surveyGroupId, err }, null, 2))
    throw err
  }
  if (response.errors) {
    console.error('Got errors while querying SurveyGroup by ID:', response.errors)
    return undefined
  }
  return response.data?.getSurveyGroup as PlatformSurveyGroup | undefined
}

export async function getSurveyGroupByQuestionnaire (questionnaireId: string): Promise<PlatformSurveyGroup | undefined> {
  let response: GraphQLResult<ListFullSurveyGroupsByQuestionnaireQuery>
  try {
    response = await API.graphql({
      query: listFullSurveyGroupsByQuestionnaire,
      variables: {
        questionnaireId
      }
    }) as GraphQLResult<ListFullSurveyGroupsByQuestionnaireQuery>
  } catch (err) {
    console.error('Failed to list Survey Groups by Questionnaire.', JSON.stringify({ questionnaireId, err }, null, 2))
    throw err
  }
  if (response.errors) {
    console.error('Got errors while listing SurveyGroups by Questionnaire:', response.errors)
    return undefined
  }
  return response.data?.listSurveyGroupsByQuestionnaire?.items?.[0] as PlatformSurveyGroup | undefined
}

export async function getSurveyGroup (questionnaireId: string, surveyGroupId?: string | undefined): Promise<PlatformSurveyGroup | undefined> {
  if (surveyGroupId) {
    return await getSurveyGroupById(surveyGroupId)
  }
  return await getSurveyGroupByQuestionnaire(questionnaireId)
}
