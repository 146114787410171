import { $t } from '@betterboards/shared/translations/index'
import { isArray } from 'lodash'

interface AuthError {
  [key: string]: string | string[]
}

const expectedErrors: AuthError = {
  UserDoesNotExist: 'User does not exist.',
  IncorrectCredentials: 'Incorrect username or password.',
  UserNotFound: 'Username/client id combination not found.',
  InvalidCode: [
    'Invalid verification code provided, please try again.',
    'Invalid code received for user',
    'Invalid code provided, please request a code again.'
  ],
  TemporaryPasswordExpired: 'Temporary password has expired and must be reset by an administrator.',
  SessionExpired: 'Invalid session for the user, session is expired.'
}

function findAuthKeyByValue (value: string): string | undefined {
  for (const [key, val] of Object.entries(expectedErrors)) {
    if (typeof val === 'string') {
      if (val === value) {
        return key
      }
    }

    if (isArray(val)) {
      if (val.includes(value)) {
        return key
      }
    }
  }
}

export default function getAuthErrorMessage (errorMessage: string): string | undefined {
  const errorKey: string | undefined = findAuthKeyByValue(errorMessage)
  if (!errorKey) {
    return $t('form.auth.errorMessage.unexpectedError') as string
  }

  return $t(`form.auth.errorMessage.${errorKey}`) as string
}
