export const NonBreakingSpace = '\xa0'

export async function clipboardCopy (text: string): Promise<void> {
  if (navigator?.clipboard) {
    try {
      await navigator.clipboard.writeText(text)
    } catch (err) {
      console.warn('Failed to copy to clipboard via navigator, err:', err)
    }
  }

  const textArea = document.createElement('textarea')
  textArea.value = text
  textArea.style.opacity = '0'
  textArea.style.position = 'absolute'
  textArea.style.bottom = '-100%'
  textArea.style.left = '-100%'
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()
  try {
    if (!document.execCommand('copy')) {
      throw new Error('Failed to execute command to copy text to clipboard.')
    }
  } catch (err) {
    console.error('Failed to use execCommand to copy text to clipboard.')
    throw err
  } finally {
    document.body.removeChild(textArea)
  }

  console.debug('Successfully copied content to clipboard:', text)
}
