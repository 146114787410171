<template>
  <div>
    <v-stepper v-model="currentStep" vertical>
      <v-row
        no-gutters
        align="center"
        class="flex-nowrap pt-4 pl-16"
        v-if="selectedCompany"
      >
        <platform-avatar
          entity-type="company"
          class="ml-5 mr-2"
          :pad-bottom="false"
          :entity="selectedCompany"
          :size="44"
        />
        <div class="shrink overflow-ellipses">
          <span class="text-body-1 grey--text text--darken-1 font-weight-regular text-no-wrap">
            {{ selectedCompany.name }}
          </span>
        </div>
      </v-row>

      <template v-for="step in steps">
        <v-stepper-step
          :key="`cws-${step.step}`"
          :step="step.step"
          :complete="step.completed"
          @click="jumpTo(step.step)"
          :class="{
            'cursor-pointer': canJumpToStep(step.step)
          }"
        >
          <v-row align="center" no-gutters>
            <v-col class="shrink">
              <span
                class="text-no-wrap"
                :class="{
                  'jump-step': canJumpToStep(step.step)
                }"
              >
                {{ step.label }}
              </span>
            </v-col>
          </v-row>
        </v-stepper-step>

        <v-stepper-content
          :key="`cwc-${step.step}`"
          :step="step.step"
          :complete="step.completed"
        >
          <component
            v-if="step.component && currentStep === step.step"
            :is="step.component"
            v-bind="{ fetchingCompany, selectedPackage }"
            @input="handleInput"
          />

          <v-row class="mt-6 px-3" justify="space-between" no-gutters>
            <platform-button
              secondary
              class="mr-2"
              @click="decrementStep"
            >
              {{ $t('form.wizard.previous') }}
            </platform-button>

            <platform-button
              primary
              :disabled="!canStepForward"
              @click="incrementStep"
            >
              {{ $t('form.wizard.next') }}
            </platform-button>
          </v-row>
        </v-stepper-content>
      </template>
    </v-stepper>
  </div>
</template>

<script lang="ts">
import BoardMembersForm from '@/components/forms/companies/BoardMembersForm.vue'
import CommitteesForm from '@/components/forms/companies/CommitteesForm.vue'
import { PlatformPackage, PlatformPackageItem } from '@/types/Platform'
import { SurveyConfiguration } from '@/types/Survey'
import Vue, { PropType } from 'vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { Company, CompanyTeamTypes, PackageItemTypes } from '@/API'

enum Steps {
  // Organization,
  BoardMembers = 1,
  Committees = 2,
}

interface CompanyWizardStep {
  label: string
  step: Steps
  component: any
  field?: string
  completed: boolean
}

export default Vue.extend({
  name: 'CompanyWizard',
  components: {
    PlatformAvatar
  },
  props: {
    completed: { type: Boolean, required: false },
    selectedPackage: { type: Object as PropType<PlatformPackage>, required: true },
    hideCommittees: { type: Boolean, default: false }
  },
  data: () => ({
    currentStep: <Steps>Steps.BoardMembers,
    hasInput: false,
    fetchingCompany: false,
    AvailableSteps: Steps
  }),
  mounted () {
    if (this.completed) {
      this.setAllStepsCompletion(true)
      if (this.hideCommittees) {
        this.currentStep = Steps.BoardMembers
        return
      }
      this.currentStep = Steps.Committees
    }
  },
  watch: {
    currentStep (newVal, oldVal) {
      if (newVal === oldVal + 1 && this.steps[oldVal - 1]) {
        this.steps[oldVal - 1].completed = true
      }
    },
    selectedCompany (company, oldCompany) {
      if (this.hasInput) {
        if (!company?.id) {
          this.setAllStepsCompletion(false)
          return
        }
        if (company.id === oldCompany?.id) {
          this.incrementStep()
          this.steps[this.currentStepIndex].completed = true
        } else {
          this.setAllStepsCompletion(false)
        }
      }
    }
  },
  computed: {
    steps (): CompanyWizardStep[] {
      const packageTargetTeam: CompanyTeamTypes | undefined = this.packageTargetTeams?.[0]
      const boardMembersLabel = packageTargetTeam
        ? this.$t('form.wizard.boardMembers.teamTitle', [packageTargetTeam]) as string
        : this.$t('form.wizard.boardMembers.title') as string
      const steps: CompanyWizardStep[] = []
      steps.push({
        label: boardMembersLabel,
        step: Steps.BoardMembers,
        component: BoardMembersForm,
        completed: false
      })
      if (!this.hideCommittees) {
        steps.push({
          label: this.$t('form.wizard.committees.title') as string,
          step: Steps.Committees,
          component: CommitteesForm,
          completed: false
        })
      }
      return steps
    },
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    currentStepIndex (): number {
      return this.currentStep - 1
    },
    isLastStep (): boolean {
      return this.currentStep >= this.steps.length
    },
    canStepForward (): boolean {
      if (this.isLastStep) {
        return true
      }
      return this.currentStep < this.steps.length
    },
    canStepBackward (): boolean {
      return this.currentStep > Steps.BoardMembers
    },
    surveyConfiguration (): SurveyConfiguration | undefined {
      if (!this.selectedPackage?.packageItems.items.length) {
        return undefined
      }
      return this.selectedPackage?.packageItems.items.find(
        (pi): pi is PlatformPackageItem<SurveyConfiguration> => pi.type === PackageItemTypes.SurveyConfiguration
      )?.content
    },
    packageTargetTeams (): CompanyTeamTypes[] | undefined {
      return this.surveyConfiguration?.targetTeams
    }
  },
  methods: {
    setAllStepsCompletion (val: boolean): void {
      this.steps.forEach((step) => {
        step.completed = val
      })
    },
    incrementStep (): void {
      if (!this.canStepForward) {
        return
      }
      if (this.isLastStep) {
        this.$emit('next')
        return
      }
      this.currentStep += 1
    },
    decrementStep (): void {
      if (this.currentStep === Steps.BoardMembers) {
        this.$emit('prev')
      } else {
        this.currentStep -= 1
      }
    },
    canJumpToStep (step: Steps): boolean {
      const index = this.steps.findIndex((s) => s.step === step)
      if (!this.steps[index]) {
        return false
      }
      if (step < this.currentStep) {
        return true
      }
      if (this.steps[index - 1] && this.steps[index - 1].completed) {
        return true
      }
      return this.steps[index].completed
    },
    jumpTo (step: Steps): void {
      const index = this.steps.findIndex((s) => s.step === step)
      if (!this.canJumpToStep(step)) {
        return
      }
      this.currentStep = this.steps[index].step
    },
    handleInput (): void {
      this.hasInput = true
    }
  }
})
</script>

<style lang="scss" scoped>
.jump-step {
  &:hover {
    opacity: 0.7;
  }
}
</style>
