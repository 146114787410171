<template>
  <div class="account-security d-flex flex-column">
    <add-mfa-method-modal
      v-if="addingMFAMethod"
      :current-cognito-user="currentUser"
      :method="addingMFAMethod"
      :icon="addMFAMethodModalIcon"
      @added="handleMFAMethodAdded"
      @close="addingMFAMethod = null"
    />
    <platform-modal
      v-if="showUntrustDeviceModal"
      :value="showUntrustDeviceModal"
      :title="$t('modal.account.security.trustedDevices.untrustModalTitle')"
      :icon="addMFAMethodModalIcon"
      :width="350"
      @confirm="untrustDevice(confirmUntrustDevice)"
      @close="confirmUntrustDevice = undefined"
    >
      <span>{{ $t('modal.account.security.trustedDevices.untrustModalText') }}</span>
    </platform-modal>
    <v-row>
      <v-col :cols="12" :sm="6" class="d-flex">
        <v-row no-gutters class="flex-column">
          <v-col class="grow">
            <h3 class="text-center sans-serif primary-header mb-2">
              {{ $t('modal.account.security.mfaTitle') }}
            </h3>
            <div>
              <div
                v-for="mfaMethod in availableMFAMethods"
                :key="mfaMethod.id"
              >
                <v-card class="mx-1 mb-4 px-3 py-2">
                  <v-row no-gutters align="center">
                    <v-col :order="2" :order-md="1" class="grow">
                      <div>
                        <platform-icon
                          color="grey darken-3"
                          class="mr-2"
                          :name="mfaMethod.icon"
                          :size="24"
                        />
                        <span class="grey--text text--darken-1 text-body-2">
                          <strong>{{ mfaMethod.method }}</strong>
                          <template v-if="mfaMethod.userIdentifier">
                            ({{ mfaMethod.userIdentifier }})
                          </template>
                        </span>
                      </div>
                      <div class="pt-1 pl-1">
                        <span class="grey--text text--darken-1 text-body-2">
                          {{ mfaMethod.name }}
                        </span>
                      </div>
                    </v-col>
                    <v-col :order="1" :order-md="2" class="shrink text-center">
                      <v-row no-gutters class="flex-column" align="stretch" justify="center">
                        <div class="d-flex justify-center">
                          <v-row v-if="mfaMethod.confirmed" no-gutters align="center" class="flex-nowrap">
                            <platform-icon
                              color="primary"
                              class="mr-1"
                              name="check-circle"
                              :size="16"
                            />
                            <strong class="primary--text">
                              {{ $t('modal.account.security.addedMethodStatus') }}
                            </strong>
                          </v-row>
                          <platform-button
                            v-else
                            v-tooltip:right="$t(mfaMethod.confirmed ? 'modal.account.security.addedMethodTooltip' : 'modal.account.security.addMethodTooltip')"
                            primary
                            small
                            compact
                            default-case
                            class="grow justify-center"
                            @click="showAddMethodModal(mfaMethod.id)"
                          >
                            {{ $t('modal.account.security.addMethodAction') }}
                          </platform-button>
                        </div>
                        <div v-if="mfaMethod.confirmed" class="d-flex justify-center pt-1">
                          <span
                            v-if="mfaMethod.preferred"
                            v-tooltip="$t('modal.account.security.preferredMethodTooltip')"
                            class="primary--text"
                          >
                            {{ $t('modal.account.security.preferredMethodStatus') }}
                          </span>
                          <platform-button
                            v-else
                            v-tooltip:right="$t('modal.account.security.preferMethodTooltip')"
                            compact
                            default-case
                            class="grow text-caption justify-center"
                            secondary
                            :loading="pendingUpdatePreferredMethod === mfaMethod.id"
                            @click="setMFAMethodPreference(mfaMethod.id)"
                          >
                            {{ $t('modal.account.security.preferMethodAction') }}
                          </platform-button>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </div>
            </div>
          </v-col>

          <v-col class="shrink d-flex justify-end">
            <platform-button
              v-tooltip:top="'Click here to disable MFA for your account, removing all confirmed methods.'"
              icon="cross"
              warning
              :loading="pendingDisableMFA"
              :disabled="pendingDisableMFA || !currentUserMFAEnabled"
              @click="disableMFA"
            >
              Disable MFA
            </platform-button>
          </v-col>
        </v-row>
      </v-col>
      <v-col :cols="12" :md="6" class="d-flex">
        <v-row no-gutters class="flex-column">
          <v-col class="grow">
            <h3 class="text-center sans-serif primary-header mb-2">
              {{ $t('modal.account.security.trustedDevices.title') }}
            </h3>
            <span v-if="!trustedDevices.length" class="grey--text text--darken-1">
              {{ $t('modal.account.security.trustedDevices.noResults') }}
            </span>
            <template v-else>
              <v-card
                v-for="(trustedDevice, index) in trustedDevices"
                :key="index"
                class="mx-1 mb-4 px-3 py-2"
              >
                <v-row no-gutters align="center" class="flex-column">
                  <v-col
                    v-tooltip:top="`Trusted at: ${trustedDevice.createdAt}`"
                    class="text-caption"
                  >
                    {{ $t('modal.account.security.trustedDevices.lastUsedLabel') }}
                    <span class="font-bold">{{ trustedDevice.lastAuthenticatedAt }}</span>
                  </v-col>
                  <v-col class="text-caption">
                    {{ $t('modal.account.security.trustedDevices.ipAddressLabel') }}
                    <span class="font-italic">{{ trustedDevice.lastIP }}</span>
                  </v-col>
                  <v-col
                    v-if="trustedDevice.currentDevice"
                    class="font-weight-bold text--smaller"
                  >
                    <span>({{ $t('modal.account.security.trustedDevices.currentDevice') }})</span>
                  </v-col>
                  <v-col class="d-flex justify-end">
                    <platform-button
                      v-tooltip:bottom="$t('modal.account.security.trustedDevices.forgetTooltip')"
                      secondary
                      compact
                      default-case
                      small
                      class="justify-center"
                      @click="confirmUntrustDevice = trustedDevice"
                    >
                      {{ $t('modal.account.security.trustedDevices.forgetAction') }}
                    </platform-button>
                  </v-col>
                </v-row>
              </v-card>
            </template>
          </v-col>
          <v-col class="shrink">
            <v-row no-gutters justify="start">
              <platform-button
                icon="trust-device"
                secondary
                :disabled="isCurrentDeviceTrusted"
                @click="trustCurrentDevice"
              >
                {{ $t('modal.account.security.trustedDevices.trustCurrentDeviceAction') }}
              </platform-button>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformUser } from '@betterboards/shared/types/Platform'
import { UserMFAMethod } from '@/types/Auth'
import AddMfaMethodModal from '@/components/user/account/mfa/AddMFAMethodModal.vue'
import {
  AuthUser,
  fetchAuthSession,
  fetchDevices,
  fetchMFAPreference,
  FetchMFAPreferenceOutput,
  fetchUserAttributes,
  forgetDevice,
  getCurrentUser,
  rememberDevice,
  signOut,
  updateMFAPreference,
  updateUserAttributes
} from 'aws-amplify/auth'
import dayjs from 'dayjs'
import { ShortDateTimeDisplayFormat } from '@/components/PlatformDateDisplay.vue'
import PlatformModal from '@/components/PlatformModal.vue'

interface AvailableMethod {
  id: UserMFAMethod
  method: string
  name: string
  icon: string
  userIdentifier?: string
  confirmed: boolean
  preferred: boolean
}

interface CognitoDeviceAttribute {
  [key: string]: string
}

interface CognitoDeviceType {
  id: string
  attributes: CognitoDeviceAttribute;
  createDate?: Date;
  lastAuthenticatedDate?: Date;
  lastModifiedDate?: Date;
}

interface TrustedDevice {
  deviceKey: string
  lastIP: string
  createdAt: string
  updatedAt: string
  lastAuthenticatedAt: string,
  currentDevice?: boolean
}

export default Vue.extend({
  name: 'EditAccountSecurity',
  components: {
    AddMfaMethodModal,
    PlatformModal
  },
  props: {
    value: { type: Object as PropType<PlatformUser>, required: true }
  },
  data: () => ({
    addingMFAMethod: <null | UserMFAMethod>null,
    pendingFileUpload: false,
    pendingDisableMFA: false,
    pendingUpdatePreferredMethod: <null | UserMFAMethod>null,
    formValid: false,
    totpMFAVerified: false,
    smsMFAVerified: false,
    preferredMFAMethod: <null | UserMFAMethod>null,
    verifiedPhoneNumber: <undefined | string>undefined,
    currentUser: <AuthUser | null>null,
    trustedDevices: <TrustedDevice[]>[],
    currentDeviceKey: <string | null>null,
    confirmUntrustDevice: <TrustedDevice | undefined>undefined
  }),
  mounted () {
    this.init()
  },
  computed: {
    availableMFAMethods (): AvailableMethod[] {
      return [
        {
          id: UserMFAMethod.TOTP,
          method: this.$t('global.auth.mfaMethods.TOTP') as string,
          name: this.$t('global.auth.mfaMethodNames.TOTP') as string,
          icon: 'phone',
          confirmed: this.totpMFAVerified,
          preferred: this.preferredMFAMethod === UserMFAMethod.TOTP
        },
        {
          id: UserMFAMethod.SMS,
          method: this.$t('global.auth.mfaMethods.SMS') as string,
          name: this.$t('global.auth.mfaMethodNames.SMS') as string,
          userIdentifier: this.verifiedPhoneNumber,
          icon: 'message',
          confirmed: this.smsMFAVerified,
          preferred: this.preferredMFAMethod === UserMFAMethod.SMS
        }
      ]
    },
    currentUserMFAEnabled (): boolean {
      return this.totpMFAVerified || this.smsMFAVerified
    },
    isCurrentDeviceTrusted (): boolean {
      if (!this.currentDeviceKey) {
        return false
      }
      return this.trustedDevices.some(
        (d: TrustedDevice) => d.deviceKey === this.currentDeviceKey
      )
    },
    addMFAMethodModalIcon (): string | undefined {
      return this.availableMFAMethods.find((m) => m.id === this.addingMFAMethod)?.icon
    },
    showUntrustDeviceModal (): boolean {
      return !!this.confirmUntrustDevice
    }
  },
  methods: {
    reset (): void {
      this.totpMFAVerified = false
      this.smsMFAVerified = false
      this.preferredMFAMethod = null
      this.verifiedPhoneNumber = undefined
      this.trustedDevices.splice(0, this.trustedDevices.length)
    },
    async init (): Promise<void> {
      this.currentUser = await getCurrentUser()
      if (!this.currentUser) {
        throw new Error('Failed to fetch current CognitoUser.')
      }
      this.reset()

      const mfaSettings: FetchMFAPreferenceOutput = await fetchMFAPreference()
      if (mfaSettings.preferred) {
        switch (mfaSettings.preferred) {
          case 'TOTP':
            this.preferredMFAMethod = UserMFAMethod.TOTP
            break
          case 'SMS':
            this.preferredMFAMethod = UserMFAMethod.SMS
            break
        }
      }

      if (mfaSettings.enabled?.includes(UserMFAMethod.TOTP)) {
        this.totpMFAVerified = true
      }

      const userAttributes = await fetchUserAttributes()
      if (userAttributes) {
        const phoneNumberVerified = Object.keys(userAttributes).some(
          (a: string) => a === 'phone_number_verified' && userAttributes[a] === 'true'
        )
        if (phoneNumberVerified) {
          this.verifiedPhoneNumber = Object.keys(userAttributes).find(
            (a: string) => a === 'phone_number'
          )
          this.smsMFAVerified = true
        }
      }

      await this.getCurrentDevice()
      await this.listTrustedDevices()
    },
    async disableMFA (): Promise<void> {
      if (!this.currentUser) {
        return
      }
      this.pendingDisableMFA = true

      if (this.smsMFAVerified) {
        const userAttributes = {
          phone_number: ''
        }
        try {
          await updateUserAttributes({
            userAttributes
          })
        } catch (err) {
          this.pendingDisableMFA = false
          console.error('Call to remove verified phone number for User failed, err:', err)
          throw new Error('Failed to clear verified phone number for current User')
        }
        console.debug('Cleared verified phone number for current user.')
      }

      try {
        await this.setMFAMethodPreference(UserMFAMethod.None)
      } catch (err) {
        console.error('Call to clear MFA preference failed, err:', err)
        throw new Error('Failed to clear MFA preference for current User')
      } finally {
        this.pendingDisableMFA = false
      }
      console.debug('Cleared MFA preference for current user.')
      this.$toasted.success(this.$t('modal.addMFADevice.disableSuccessMessage') as string)
    },
    async listTrustedDevices (): Promise<void> {
      if (!this.currentUser) {
        return
      }
      this.trustedDevices.splice(0, this.trustedDevices.length)
      const devices: CognitoDeviceType[] = await fetchDevices()

      devices.forEach((device: CognitoDeviceType) => {
        this.trustedDevices.push({
          deviceKey: device.id,
          lastIP: device.attributes?.last_ip_used,
          createdAt: dayjs(device.createDate).format(ShortDateTimeDisplayFormat),
          updatedAt: dayjs(device.lastModifiedDate).format(ShortDateTimeDisplayFormat),
          lastAuthenticatedAt: dayjs(device.lastAuthenticatedDate).format(ShortDateTimeDisplayFormat),
          currentDevice: device.id === this.currentDeviceKey
        })
      })
    },
    async setMFAMethodPreference (
      method: UserMFAMethod
    ): Promise<void> {
      if (method === this.preferredMFAMethod) {
        console.warn('Ignore request to change preference as value matches current.')
        return
      }

      this.pendingUpdatePreferredMethod = method
      try {
        switch (method) {
          case UserMFAMethod.SMS:
            await updateMFAPreference({ sms: 'PREFERRED' })
            break
          case UserMFAMethod.TOTP:
            await updateMFAPreference({ totp: 'PREFERRED' })
            break
          case UserMFAMethod.None:
          default:
            await updateMFAPreference({
              totp: 'DISABLED',
              sms: 'DISABLED'
            })
        }
      } catch (err) {
        this.pendingUpdatePreferredMethod = null
        console.error('Failed to set preferred MFA method, error:', err)
        this.$toasted.error(this.$t('modal.addMFADevice.preferenceErrorMessage') as string)
        throw err
      }

      console.debug('Set preferred MFA method to:', method)
      this.init()
        .catch((err) => {
          console.error('Failed to re-initialize after updating MFA preference, error:', err)
        })
        .finally(() => {
          this.pendingUpdatePreferredMethod = null
        })
    },
    showAddMethodModal (method: UserMFAMethod): void {
      if (this.addingMFAMethod) {
        return
      }
      this.addingMFAMethod = method
    },
    handleMFAMethodAdded (method: UserMFAMethod): void {
      this.setMFAMethodPreference(method)
    },
    async getCurrentDevice (): Promise<void> {
      if (!this.currentUser) {
        return
      }
      const authSession = await fetchAuthSession()

      if (authSession.tokens?.accessToken?.payload?.device_key) {
        this.currentDeviceKey = `${authSession.tokens?.accessToken.payload.device_key}`
      }
    },
    async trustCurrentDevice (): Promise<void> {
      if (!this.currentUser) {
        return
      }
      try {
        await rememberDevice()
      } catch (error) {
        console.error('Failed to trust device, error:', error)
      }

      console.debug('Successfully trusted current device')
      await this.getCurrentDevice()
      this.listTrustedDevices()
        .catch((err) => {
          console.error('Failed to re-initialize after trusting device, error:', err)
        })
    },
    async untrustDevice (trustedDevice: TrustedDevice | undefined): Promise<void> {
      this.confirmUntrustDevice = undefined
      if (!this.currentUser || !trustedDevice) {
        return
      }

      try {
        await forgetDevice({
          device: {
            id: trustedDevice.deviceKey
          }
        })
      } catch (error) {
        console.error('Failed to untrust current device, error:', error)
        return
      }

      const index = this.trustedDevices.findIndex((d) => d.deviceKey === trustedDevice.deviceKey)
      if (index !== -1) {
        this.trustedDevices.splice(index, 1)
      }

      if (trustedDevice.deviceKey === this.currentDeviceKey) {
        this.$emit('close')
        await signOut()
      }
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.account-security {
  min-height: 305px;
}

.trusted-device__name {
  max-width: 300px;
}
</style>
