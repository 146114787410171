var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"grow d-flex",class:{
    'flex-row': _vm.grid,
    'flex-column': !_vm.grid,
  },attrs:{"data-test-target":"platformEntityCard"}},[_c('v-card',_vm._g({staticClass:"grow entity d-flex",class:_vm.cardClasses,attrs:{"flat":_vm.flat,"hover":_vm.hover,"to":_vm.to,"ripple":!!_vm.$listeners.click}},_vm.$listeners.click ? { click: (evt) => _vm.$emit('click', evt) } : undefined),[(_vm.showImage)?_c('v-row',{class:{
        grow: _vm.grid,
        shrink: !_vm.grid
      },attrs:{"no-gutters":"","justify":_vm.align}},[_c('div',{class:{
          'ml-3': !_vm.compact && (_vm.align === 'start' || _vm.align === 'center'),
          'mr-3': !_vm.compact && (_vm.align === 'end' || _vm.align === 'center'),
          'my-3': !_vm.compactHeader && !_vm.compact,
          'mx-3': !_vm.compactHeader && _vm.compact,
          'pa-1': !_vm.compactHeader && !_vm.displayCompactButtons,
          'mt-2': _vm.compactHeader,
        },staticStyle:{"position":"relative"}},[(_vm.$listeners.edit && _vm.bubbleEdit)?_c('platform-icon',{staticClass:"bubble-action rounded-circle",attrs:{"name":"edit","color":"primary","size":12},on:{"click":function($event){$event.stopPropagation();return ((evt) => _vm.$emit('edit', evt)).apply(null, arguments)}}}):_vm._e(),(_vm.image)?_c('platform-avatar',{attrs:{"listen":_vm.avatarListen,"entity":_vm.entity,"entity-type":_vm.entityType,"pad-bottom":false,"size":_vm.avatarSize,"placeholder-size":_vm.placeholderSize}}):_vm._e(),_vm._t("headerImage")],2)]):_vm._e(),_c('v-row',{staticClass:"overflow-hidden",class:{
        'pt-2': _vm.listMembers && !_vm.inlineListMembers
      },attrs:{"no-gutters":"","align":_vm.row ? 'center' : undefined,"justify":_vm.compact ? undefined : 'space-between'}},[_c('v-col',{staticClass:"grow px-3 overflow-hidden",class:{
          'pt-3': _vm.inlineHeader && !_vm.compact,
          'pb-2': _vm.grid || !_vm.showActions
        },attrs:{"cols":_vm.inlineActions ? 10 : undefined,"order":1}},[(!_vm.hideTitle && (_vm.title || _vm.createdAt))?_c('v-row',{attrs:{"justify":_vm.align,"no-gutters":""}},[_c('v-col',{staticClass:"flex-grow-1 text-break"},[(_vm.title)?_c('v-row',{attrs:{"align":"start","justify":_vm.align,"no-gutters":""}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.title),expression:"title"}],staticClass:"entity-title overflow-ellipses",class:{
                  'single-line': _vm.row && _vm.$vuetify.breakpoint.smAndUp
                },attrs:{"data-test-target":"platformEntityCardTitle"}},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),(_vm.createdAt)?_c('v-row',{attrs:{"align":"center","justify":_vm.align,"no-gutters":""}},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.updatedAt ? `${_vm.$t('platformEntityCard.lastUpdated')} ${_vm.updatedAt}` : undefined),expression:"updatedAt ? `${$t('platformEntityCard.lastUpdated')} ${updatedAt}` : undefined"}],staticClass:"text-caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.$t('platformEntityCard.created'))+" "),_c('platform-date-display',{attrs:{"date-time":_vm.createdAt}})],1)]):_vm._e()],1)],1):_vm._e(),(_vm.subTitle && !_vm.hideSubTitle)?_c('v-row',{attrs:{"no-gutters":"","justify":_vm.align}},[_c('v-card-subtitle',{staticClass:"shrink pt-1 pb-0 pl-2"},[_c('v-row',{attrs:{"align":"center","justify":_vm.align,"no-gutters":""}},[(_vm.subTitleIcon)?_c('platform-icon',{staticClass:"mr-1",attrs:{"size":24,"name":_vm.subTitleIcon}}):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.subTitle)+" ")])],1)],1)],1):_vm._e(),(_vm.$slots.default)?_c('v-row',{attrs:{"align":"center","justify":_vm.align,"no-gutters":""}},[_vm._t("default")],2):_vm._e()],1),(_vm.listMembers)?_c('v-col',{class:{
          'shrink pr-4': !_vm.inlineActions,
          'grow px-3': _vm.inlineActions,
        },attrs:{"cols":_vm.inlineListMembers ? undefined : 12,"order":_vm.inlineActions ? 5 : 2}},[_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('individuals-preview-list',{attrs:{"individuals":_vm.entityMembers,"company-id":_vm.companyId,"loading":_vm.loadingMembers}})],1)],1):_vm._e(),(_vm.statuses)?_c('v-col',{staticClass:"px-2",attrs:{"cols":_vm.inlineActions && _vm.inlineListMembers ? 6 : 12,"order":_vm.showActions && _vm.inlineActions ? 4 : 3}},[_c('v-row',{staticClass:"pb-1",attrs:{"no-gutters":"","justify":_vm.align,"align":"end"}},_vm._l((_vm.statuses),function(status,index){return _c('v-col',{key:index,staticClass:"shrink"},[_c('status-button',_vm._g({attrs:{"value":status,"primary":status.primary,"secondary":status.secondary}},_vm.$listeners[status.clickEvent] ? { click: () => _vm.$emit(status.clickEvent, status.value) } : undefined))],1)}),1)],1):_vm._e(),(_vm.showActions)?_c('v-col',{staticClass:"shrink",attrs:{"cols":_vm.inlineActions ? 2 : 12,"order":3}},[_c('v-card-actions',{class:{
            'pt-0': _vm.grid,
            'py-0': _vm.compact
          }},[_c('v-row',{staticClass:"align-start",class:{
              'justify-center': !_vm.listMembers || _vm.mobileView,
              'justify-end': _vm.listMembers && !_vm.mobileView,
            },attrs:{"no-gutters":""}},[_c('v-col',{class:{
              'flex-grow-0': _vm.mobileView
            }},[_c('v-row',{class:{
                  'flex-column flex-nowrap': _vm.mobileView,
                  'flex-nowrap': _vm.inlineActions
                },attrs:{"no-gutters":"","justify":"end"}},[(_vm.$listeners.delete)?_c('v-col',{staticClass:"shrink d-flex",class:{ 'pa-1': !_vm.displayCompactButtons },attrs:{"order":1}},[_c('platform-button',{staticClass:"grow",attrs:{"warning":"","icon":"delete","loading":_vm.deleting,"disabled":_vm.deleting,"text":_vm.displayCompactButtons},on:{"click":_vm.showDeleteConfirmation}},[(!_vm.displayCompactButtons)?[_vm._v(" "+_vm._s(_vm.$t('global.delete'))+" ")]:_vm._e()],2)],1):_vm._e(),(!_vm.inlineActions)?_c('v-col',{staticClass:"grow d-flex",attrs:{"order":2}},[(_vm.$listeners.delete && (_vm.$slots.actions || (_vm.$listeners.edit && !_vm.bubbleEdit)))?_c('v-spacer'):_vm._e()],1):_vm._e(),_c('v-col',{staticClass:"shrink d-flex",attrs:{"order":3}},[_vm._t("actions")],2),_c('v-col',{staticClass:"shrink d-flex",attrs:{"order":_vm.mobileView ? 6 : 4}},[_vm._t("primaryActions")],2),(_vm.$listeners.edit && !_vm.bubbleEdit)?_c('v-col',{staticClass:"shrink d-flex",class:{ 'pa-1': !_vm.displayCompactButtons },attrs:{"order":5}},[_c('platform-button',{staticClass:"grow",attrs:{"secondary":"","icon":"edit","text":_vm.displayCompactButtons},on:{"click":(evt) => _vm.$emit('edit', evt)}},[(!_vm.displayCompactButtons)?[_vm._v(" "+_vm._s(_vm.$t('global.edit'))+" ")]:_vm._e()],2)],1):_vm._e()],1)],1)],1)],1)],1):_vm._e()],1)],1),(_vm.deleteConfirmationVisible)?_c('confirmation-modal',{attrs:{"action":"delete","entity":_vm.entity,"entity-type":_vm.entityType},on:{"confirm":_vm.confirmDelete,"cancel":function($event){_vm.deleteConfirmationVisible = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }