<template>
  <platform-modal
    icon="comparisons"
    :value="true"
    :width="650"
    :title="title || $t('modal.questionnaire.releases.title')"
    v-on="saveConfirmation ? { save: handleSave } : {}"
    :saving="loadingGroup !== undefined"
    @close="close"
  >
    <div class="mb-6">
      <span>
        {{ instruction || $t('modal.questionnaire.releases.instruction') }}
      </span>
    </div>

    <survey-group-selector
      :value="surveyGroupId === undefined ? value : surveyGroupId"
      :survey-groups="surveyGroups"
      :loading-group="saveConfirmation ? undefined : loadingGroup"
      :none-option="noneOption"
      :target-route="targetRoute"
      @input="handleInput"
    />
  </platform-modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { SurveyGroupProgress } from '@/types/SurveyGroup'
import PlatformModal from '@/components/PlatformModal.vue'
import SurveyGroupSelector from '@/components/questionnaire/releases/SurveyGroupSelector.vue'

export default Vue.extend({
  name: 'SurveyGroupSelectorModal',
  components: {
    PlatformModal,
    SurveyGroupSelector
  },
  props: {
    value: { type: String, required: false },
    surveyGroups: { type: Array as PropType<SurveyGroupProgress[]>, required: true },
    loadingGroup: { type: String, required: false },
    title: { type: String, required: false },
    instruction: { type: String, required: false },
    targetRoute: { type: String, required: false },
    noneOption: { type: Boolean, default: false },
    saveConfirmation: { type: Boolean, default: false }
  },
  data: () => ({
    surveyGroupId: <string | undefined | null>undefined
  }),
  methods: {
    close (): void {
      this.$emit('close')
    },
    handleInput (selectedComparisonGroup: string | null): void {
      if (this.saveConfirmation) {
        this.surveyGroupId = selectedComparisonGroup
        return
      }
      this.$emit('input', selectedComparisonGroup)
    },
    handleSave (): void {
      this.$emit('input', this.surveyGroupId)
    }
  }
})
</script>
