import _, { cloneDeep } from 'lodash'
import { v4 as uuid } from 'uuid'
import API from '@/services/API'
import {
  createBlock,
  createSection,
  updateBlock,
  updateSection
} from '@/graphql/mutations'
import {
  PlatformQuestionnaireVariant,
  PlatformSurvey,
  QuestionnaireVariantCode
} from '@/types/Platform'
import { QuestionnaireProgress } from '@/types/Survey'
import {
  Survey
} from '@/API'
import {
  CreateQuestionnaireRequest,
  DuplicateQuestionnaireRequest,
  UpdateQuestionnaireRequest
} from '@/types/Questionnaire'
import { PlatformQuestionnaire, PlatformSection, PlatformBlock } from '@betterboards/shared/types/Platform'
import { FormQuestionnaire } from '@betterboards/shared/types/Questionnaire'
import { CriteriaItem } from '@betterboards/shared/types/Content'

// Variants
export const NullVariantCodeValue = 'null'

export enum ReportDocumentStatusFields {
  QueuedAt = 'queuedAt',
  StartedProcessingAt = 'startedProcessingAt',
  FinishedAt = 'finishedAt'
}

export const DefaultQuestionnaireData = (): FormQuestionnaire => ({
  name: null as string | null,
  packageId: undefined as string | undefined,
  editDisabled: false,
  variants: {},
  groups: null,
  customContent: null,
  sections: [] as PlatformSection[]
})

export function parseQuestionnaire (rawQuestionnaire: string | Partial<PlatformQuestionnaire>): FormQuestionnaire {
  let questionnaire: Partial<PlatformQuestionnaire>
  if (typeof rawQuestionnaire === 'string') {
    questionnaire = JSON.parse(rawQuestionnaire, (key, value) => {
      if (typeof value?.data === 'string') {
        value.data = JSON.parse(value.data)
      }
      return value
    })
  } else {
    questionnaire = rawQuestionnaire
  }
  let sections = questionnaire.sections?.items ?? []
  if (sections) {
    sections = sections.map((section: PlatformSection) => {
      const otherVariantSection = sections.find((s) => s.id && s.slug === section.slug)
      if (!section.id) {
        // If another variant of this Section has already been assigned an ID, use that ID for this variant too.
        section.id = otherVariantSection?.id ?? uuid()
      }
      if (section?.blocks?.items) {
        section.blocks.items = section.blocks.items.map((block) => {
          if (!block) {
            return block
          }
          if (block.data && typeof block.data === 'string') {
            block.data = JSON.parse(block.data)
          }
          if (!block.id) {
            const otherVariantBlock = otherVariantSection?.blocks?.items?.find((b) => b.id && b.displayOrder === block.displayOrder)
            block.id = otherVariantBlock?.id ?? uuid()
          }
          if (!block.sectionId) {
            block.sectionId = section.id
          }
          return block
        })
      }
      return section
    })
  }
  return {
    ...questionnaire,
    name: questionnaire.name ?? null,
    variants: {},
    id: undefined,
    sections
  }
}

export function getLatestRelease (releases: Survey[]): any {
  const release = releases.reduce((current: Survey, r: Survey) => {
    if (r.groupId) {
      if (r.createdAt > current.createdAt) {
        return r
      }
    }
    return current
  }, releases[0])

  if (release) {
    return release
  }
  return releases.find((r) => r.groupId)
}

export async function persistSection (section: PlatformSection, companyId?: string, questionnaireId?: string): Promise<PlatformSection | undefined> {
  if (!section?.id || !section.variantCode) {
    console.warn('Failed to persist section due to missing ID or variantCode.', { section })
    return
  }
  const operation = section.createdAt ? updateSection : createSection
  let sectionResp
  try {
    sectionResp = await API.graphql({
      query: operation,
      variables: {
        input: {
          ...section,
          companyId: section.companyId ?? companyId,
          questionnaireId: section.questionnaireId ?? questionnaireId
        }
      }
    })
  } catch (err) {
    console.error(`Caught an error while updating section "${section.name ?? ''}" (${section.id ?? ''}):`, err)
    throw err
  }
  return sectionResp.data.createSection || sectionResp.data.updateSection || section
}

export async function persistBlock (block: PlatformBlock, companyId?: string, sectionId?: string): Promise<PlatformBlock> {
  const operation = block.createdAt ? updateBlock : createBlock

  let response
  try {
    response = await API.graphql({
      query: operation,
      variables: {
        input: {
          ...block,
          data: JSON.stringify(block.data),
          sectionId: block.sectionId ?? sectionId,
          companyId: block.companyId ?? companyId
        }
      }
    })
  } catch (err) {
    console.error('Caught an error while updating block:', err)
    throw err
  }
  return response.data.updateBlock || response.data.createBlock
}

export async function persistSectionAndBlocks (section: PlatformSection, companyId: string, questionnaireId: string): Promise<PlatformSection | undefined> {
  const blocks = section.blocks
  const newSection = await persistSection(section, companyId, questionnaireId)
  if (!blocks?.items) {
    return newSection
  }
  const sectionId = section.id ?? newSection?.id
  // console.log(`Creating/updating ${blocks.items?.length ?? ''} blocks for section "${section.name ?? ''}" (${section.id ?? ''}).`)
  const blockResps = blocks.items.map(async (block) => {
    if (block == null) {
      return
    }
    return await persistBlock(block, companyId, sectionId)
  })
  blocks.items = (await Promise.all(blockResps)).filter((b) => !!b) as PlatformBlock[]

  return newSection
}

export function getSectionVariantData (section: PlatformSection, variantCode: QuestionnaireVariantCode): PlatformSection & any {
  return {
    ...cloneDeep(section),
    variantCode,
    blocks: {
      items: []
    },
    __typename: undefined,
    createdAt: undefined,
    updatedAt: undefined
  }
}

function getSurveyGroupCount (questionnaire: QuestionnaireProgress): number | undefined {
  const surveyGroupIds = questionnaire.surveys?.map((s: PlatformSurvey) => s.groupId)
  const uniqueGroups: string[] | undefined = surveyGroupIds
    ? _.uniq(surveyGroupIds)
    : undefined

  return uniqueGroups?.length
}

export function getQuestionnaireVersionCount (questionnaire: QuestionnaireProgress): number | undefined {
  const surveyGroups: number | undefined = getSurveyGroupCount(questionnaire)
  if (!surveyGroups || surveyGroups === 1) {
    return undefined
  }

  return surveyGroups
}

export function getCriteriaItemVariantData (criteriaItem: CriteriaItem, variantCode: QuestionnaireVariantCode): CriteriaItem & any {
  return {
    ...cloneDeep(criteriaItem),
    variantCode
  }
}

export async function createQuestionnaire (questionnaireData: CreateQuestionnaireRequest): Promise<string> {
  const variants: QuestionnaireVariantCode[] = Object.keys(questionnaireData.variants) as QuestionnaireVariantCode[]

  let createQuestionnaireResponse
  try {
    createQuestionnaireResponse = await API.post('backendfunctions', '/questionnaires', {
      body: {
        ...questionnaireData,
        variants: variants.map((variantCode: QuestionnaireVariantCode): PlatformQuestionnaireVariant => ({
          name: questionnaireData.variants[variantCode],
          variantCode
        })),
        sections: undefined
      }
    })
  } catch (err) {
    console.warn('Error hitting create Questionnaire endpoint', JSON.stringify(err, null, 2))
    throw err
  }

  return createQuestionnaireResponse.questionnaireId
}

export async function duplicateQuestionnaire (questionnaireData: DuplicateQuestionnaireRequest): Promise<PlatformQuestionnaire> {
  const variants: QuestionnaireVariantCode[] = Object.keys(questionnaireData.variants) as QuestionnaireVariantCode[]

  let duplicateQuestionnaireResponse
  try {
    duplicateQuestionnaireResponse = await API.post('backendfunctions', '/questionnaires/duplicate', {
      body: {
        ...questionnaireData,
        variants: variants.map((variantCode: QuestionnaireVariantCode): PlatformQuestionnaireVariant => ({
          name: questionnaireData?.variants?.[variantCode],
          variantCode
        }))
      }
    })
  } catch (err) {
    console.warn('Error hitting duplicate Questionnaire endpoint', JSON.stringify(err, null, 2))
    throw err
  }

  return duplicateQuestionnaireResponse.questionnaire
}

export async function updateSectionsAndBlocks (body: UpdateQuestionnaireRequest): Promise<void> {
  const normalizedBody = normalizeUpdateQuestionnaireRequest(body)

  try {
    await API.put('backendfunctions', '/questionnaires', {
      body: normalizedBody
    })
  } catch (err) {
    console.log('Failed to update Questionnaire in helper', JSON.stringify(err, null, 2))
    throw err
  }
}

function normalizeUpdateQuestionnaireRequest (updateQuestionnaireData: UpdateQuestionnaireRequest): UpdateQuestionnaireRequest {
  const normalizedData: UpdateQuestionnaireRequest = {
    sections: {
      create: updateQuestionnaireData.sections?.create ?? [],
      update: updateQuestionnaireData.sections?.update ?? [],
      delete: updateQuestionnaireData.sections?.delete ?? []
    },
    blocks: {
      create: updateQuestionnaireData.blocks?.create ?? [],
      update: updateQuestionnaireData.blocks?.update ?? [],
      delete: updateQuestionnaireData.blocks?.delete ?? []
    },
    companyId: updateQuestionnaireData.companyId,
    id: updateQuestionnaireData.id
  }

  return normalizedData
}
