import API from '@/services/API'
import { Module } from 'vuex'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { IndividualSurveyProgress, PlatformSurveyGroup, SurveyGroupProgress } from '@/types/SurveyGroup'
import { ListFullSurveyGroupsByCompanyQuery } from '@/API'
import { listFullSurveyGroupsByCompany } from '@/graphql/custom/listFullSurveyGroupsByCompany'
import { calculateSurveyGroupCompletion, getSurveyGroupCompletionDisplayVal } from '@/helpers/surveyGroup'

export interface SurveyGroupState {
  list: PlatformSurveyGroup[]
}

const defaultState = (): SurveyGroupState => ({
  list: []
})

const SurveyGroupStore: Module<SurveyGroupState, any> = {
  namespaced: true,
  state: defaultState(),
  getters: {
    list (state: SurveyGroupState): SurveyGroupProgress[] {
      const list = state.list.filter((surveyGroup: PlatformSurveyGroup) => {
        return !!surveyGroup.surveys?.items?.length
      })
      return list.map((surveyGroup: PlatformSurveyGroup): SurveyGroupProgress => {
        const individuals: IndividualSurveyProgress[] = surveyGroup.surveys?.items
          .filter((survey) => !!survey.individual)
          .map((survey): IndividualSurveyProgress => ({
            individualId: survey.individual.id,
            firstName: survey.individual.firstName ?? null,
            familyName: survey.individual.familyName ?? null,
            preferredName: survey.individual.preferredName,
            incomplete: !survey.finishedAt
          }))
        const versions: number = list.filter((s) => s.questionnaireId === surveyGroup.questionnaireId).length
        const surveyGroupProgress: SurveyGroupProgress = {
          ...surveyGroup,
          completion: calculateSurveyGroupCompletion(surveyGroup.surveys.items),
          displayCompletion: getSurveyGroupCompletionDisplayVal(surveyGroup.surveys.items),
          versions,
          individuals: {
            items: individuals
          }
        }
        return surveyGroupProgress
      })
    }
  },
  mutations: {
    setList: (state: SurveyGroupState, items: PlatformSurveyGroup[]) => {
      state.list.splice(0, state.list.length, ...items)
    },
    resetList: (state: SurveyGroupState) => {
      state.list.splice(0, state.list.length)
    },
    addToList: (state: SurveyGroupState, item: PlatformSurveyGroup) => {
      state.list.push(item)
    }
  },
  actions: {
    async listSurveyGroups ({ commit }: any, { companyId, append = false }: { companyId: string, append: boolean }): Promise<void> {
      const result = await API.graphql({
        query: listFullSurveyGroupsByCompany,
        variables: {
          companyId
        }
      }) as GraphQLResult<ListFullSurveyGroupsByCompanyQuery>

      const surveyGroups: PlatformSurveyGroup[] | undefined = result?.data?.listSurveyGroupsByCompany?.items as PlatformSurveyGroup[] | undefined
      if (surveyGroups && append) {
        surveyGroups.forEach((surveyGroup) => {
          commit('addToList', surveyGroup)
        })
        return
      }
      commit('setList', surveyGroups)
    }
  }
}

export default SurveyGroupStore
