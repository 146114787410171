<template>
  <v-row
    no-gutters
    @keydown.enter="resetPasswordHandler"
  >
    <v-col class="d-flex flex-column">
      <h3 class="login-header pt-4 pb-8">{{ $t('form.auth.resetPassword.title') }}</h3>
      <span
        class="red--text"
        v-if="errorMessage"
      >
        {{ errorMessage }}
      </span>
      <v-form-base
        class="pt-6"
        :schema="formSchema"
        @input="handleInput"
      />

      <v-row no-gutters class="justify-space-between align-center">
        <span class="login-links">
          <a
            @click="$emit('signIn')"
          >
            {{ $t('form.auth.backToSignUp') }}
          </a>
        </span>
        <platform-button
          class="px-12 py-4"
          primary
          :loading="pendingPasswordReset"
          @click="resetPasswordHandler"
        >
          {{ buttonText }}
        </platform-button>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'
import VFormBase from 'vuetify-form-base'
import Logo from '../../assets/logo.png'
import {
  resetPassword,
  confirmResetPassword,
  ResetPasswordOutput
} from 'aws-amplify/auth'
import getAuthErrorMessage from '@/helpers/auth/getAuthErrorMessage'

export default Vue.extend({
  name: 'ResetPasswordForm',
  components: {
    VFormBase
  },
  data () {
    return {
      logo: Logo,
      email: <string | undefined>undefined,
      code: <string | undefined>undefined,
      password: <string | undefined>undefined,
      resetCodeSent: <boolean>false,
      pendingVerificationCode: <boolean>false,
      pendingReset: <boolean>false,
      errorMessage: <string | undefined>undefined
    }
  },
  computed: {
    formSchema () {
      const schema: any = {}

      if (!this.passwordChangeRequested) {
        schema.email = {
          type: 'BBTextField',
          label: this.$t('form.auth.emailLabel'),
          placeholder: this.$t('form.auth.emailPlaceholder'),
          persistentPlaceholder: true,
          outlined: true,
          col: 12
        }
      }

      if (this.passwordChangeRequested) {
        schema.code = {
          type: 'BBTextField',
          label: this.$t('form.auth.resetPassword.codeLabel'),
          placeholder: this.$t('form.auth.resetPassword.codePlaceholder'),
          persistentPlaceholder: true,
          outlined: true,
          col: 12
        }
        schema.password = {
          type: 'BBTextField',
          label: this.$t('form.auth.resetPassword.passwordLabel'),
          placeholder: this.$t('form.auth.resetPassword.passwordPlaceholder'),
          persistentPlaceholder: true,
          outlined: true,
          inputType: 'password',
          col: 12
        }
      }

      return schema
    },
    passwordChangeRequested (): boolean {
      return this.resetCodeSent
    },
    buttonText (): string {
      return this.passwordChangeRequested
        ? this.$t('modal.shared.confirm') as string
        : this.$t('form.auth.resetPassword.sendCode') as string
    },
    pendingPasswordReset (): boolean {
      return this.pendingVerificationCode || this.pendingReset
    }
  },
  methods: {
    handleInput (val: any) {
      if (!this.passwordChangeRequested) {
        this.email = val.data.email
      }
      if (this.passwordChangeRequested) {
        this.code = val.data.code
        this.password = val.data.password
      }
    },
    async resetPasswordHandler (): Promise<void> {
      if (!this.email) {
        console.error('No email provided')
        return
      }

      if (this.passwordChangeRequested) {
        await this.confirmReset()
        return
      }

      await this.sendCode()
    },
    async sendCode (): Promise<void> {
      if (this.pendingVerificationCode) {
        return
      }

      if (!this.email) {
        console.error('Missing email')
        return
      }
      this.pendingVerificationCode = true

      let response: ResetPasswordOutput | undefined
      try {
        response = await resetPassword({
          username: this.email
        })
      } catch (error) {
        this.pendingVerificationCode = false
        this.errorMessage = getAuthErrorMessage(error.message)
        console.error('Error sending password reset code to User', error)
      }

      if (response) {
        this.handleResetPasswordNextSteps(response)
      }
      this.pendingVerificationCode = false
    },
    handleResetPasswordNextSteps (output: ResetPasswordOutput): void {
      const { nextStep } = output
      if (nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        this.resetCodeSent = true
        const codeDeliveryDetails = nextStep.codeDeliveryDetails
        console.log(
          `Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium}`
        )
      }
    },
    async confirmReset (): Promise<void> {
      if (!this.email || !this.code || !this.password) {
        console.error(
          'Missing email, verification code or password',
          {
            email: this.email,
            code: this.code,
            password: this.password
          }
        )
        return
      }
      this.errorMessage = undefined
      this.pendingReset = true

      try {
        await confirmResetPassword({
          username: this.email,
          confirmationCode: this.code,
          newPassword: this.password
        })
      } catch (error) {
        this.pendingReset = false
        this.errorMessage = getAuthErrorMessage(error.message)
        return
      }

      this.pendingReset = false
      this.$emit('signIn')
    }
  }
})
</script>
