<template>
  <v-switch
    class="mt-0 pt-0"
    v-model="switchValue"
    :color="color"
    :dense="compact"
    :hide-details="hideDetails"
    :loading="loading"
    :label="label"
    :readonly="readOnly"
    @change="(evt) => $emit('change', evt)"
    @mousedown="(evt) => $emit('click', evt)"
  >
    <template #label v-if="label">
      <input-label
        :label="label"
        :class="labelClasses"
      />
    </template>
  </v-switch>
</template>

<script lang="ts">
import Vue from 'vue'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'

export default Vue.extend({
  name: 'PlatformSwitch',
  components: {
    InputLabel
  },
  props: {
    value: { type: Boolean, required: true },
    primary: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    compactLabel: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    forceColor: { type: String, required: false },
    label: { type: String, required: false },
    readOnly: { type: Boolean, default: false }
  },
  computed: {
    switchValue: {
      get (): boolean {
        return this.value
      },
      set (val: boolean): void {
        this.$emit('input', val)
      }
    },
    color (): string | undefined {
      if (this.forceColor) {
        return this.forceColor
      }
      if (this.primary) {
        return 'primary'
      }
      if (this.secondary) {
        return 'secondary'
      }
      return undefined
    },
    loaderColor (): string | undefined {
      if (this.secondary) {
        return 'primary'
      }
      return 'white'
    },
    labelClasses (): any {
      return {
        'text-body-2': this.compactLabel
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.platform-button {
  touch-action: manipulation;
  &.square {
    width: 24px !important;
  }
  &.compact {
    min-height: 24px !important;
  }
  &--secondary {
    background-color: white;
  }
}
</style>

<style lang="scss">
.v-card__actions {
  .platform-button__container + .platform-button__container {
    margin-left: 8px;
  }
}
</style>
