<template>
  <v-container class="px-0">
    <v-row class="grow">
      <v-col :cols="12">
        <v-card
          elevation="2"
          class="mb-3"
        >
          <v-card-text class="py-6">
            <report-documents-panel
              :survey-group-id="surveyGroupId"
              :individuals="individuals"
              :report-documents="reportDocuments"
              :available-variants="availableVariants"
              :selected-variant="selectedVariant"
              :fetching="pendingFetch"
              :pending-individual-reports="pendingIndividualReports"
              :pending-individual-reports-archive="pendingIndividualReportsArchive"
              @queue="handleQueuedReport"
              @queuedIndividualReports="handleQueuedIndividualReports"
              @download="downloadUnknownReport"
              @downloadIndividualReports="downloadAllIndividualReports"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="12">
        <v-expansion-panels v-model="showPreviousReports">
          <v-expansion-panel class="rounded">
            <v-expansion-panel-header class="header px-4">
              <h2 class="primary-header sans-serif text-center">{{ $t('analysis.generateReport.previousReports') }}</h2>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <platform-spinner v-if="pendingFetch" class="mx-auto mt-3" />
              <v-data-table
                v-else
                :headers="tableHeaders"
                :items="reportDocuments"
                class="text-body-2"
              >
                <template v-slot:[`item.status`]="{ item: reportDocument }">
                  <span
                    v-if="reportDocument.failedAt"
                    v-tooltip="$t('analysis.generateReport.reportDocuments.processingFailedTooltip')"
                    class="warning--text"
                  >
                    {{ $t('analysis.generateReport.reportDocuments.statuses.failed') }}
                  </span>
                  <span v-else-if="reportDocument.finishedAt">
                    {{ $t('analysis.generateReport.reportDocuments.statuses.finished') }}
                  </span>
                  <span v-else-if="reportDocument.startedProcessingAt">
                    {{ $t('analysis.generateReport.reportDocuments.statuses.processing') }}
                  </span>
                  <span v-else>
                    {{ $t('analysis.generateReport.reportDocuments.statuses.queued') }}
                  </span>
                </template>
                <template v-slot:[`item.lastUpdated`]="{ item: reportDocument }">
                  <platform-date-display
                    v-if="reportDocument.startedProcessingAt"
                    concise
                    :date-time="reportDocument.startedProcessingAt"
                  />
                  <platform-spinner v-else color="grey" :size="20" class="ml-2" />
                </template>
                <template v-slot:[`item.individual`]="{ item: reportDocument }">
                  {{ getIndividualName(reportDocument.targetIndividualId) }}
                </template>
                <template v-slot:[`item.variantCode`]="{ item: reportDocument }">
                  <template v-if="reportDocument.variantCode && reportDocument.variantCode !== NullVariantCodeValue">
                    {{ $t(`global.languages.${reportDocument.variantCode}`) }}
                  </template>
                </template>
                <template v-slot:[`item.reportId`]="{ item: reportDocument }">
                  <template v-if="reportDocument.reportId">
                    {{ getReportName(reportDocument.reportId) }}
                  </template>
                </template>
                <template v-slot:[`item.actions`]="{ item: reportDocument }">
                  <platform-button
                    secondary
                    text
                    icon="download"
                    class="my-2"
                    :disabled="!reportDocument.key || !reportDocument.finishedAt"
                    v-tooltip:top="$t('analysis.generateReport.reportDocuments.download.downloadActionTooltip')"
                    @click="downloadUnknownReport(reportDocument)"
                  />
                  <platform-button
                    warning
                    text
                    icon="delete"
                    class="ml-2"
                    v-tooltip:top="$t('analysis.generateReport.reportDocuments.delete.deleteActionTooltip')"
                    @click="showReportDocumentDeleteConfirmation(reportDocument)"
                  />
                </template>
              </v-data-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <report-document-progress-modal
      v-if="showReportDocumentProgressModal"
      :duration-estimate="reportGenerationDurationEstimate"
      :completed="reportDocumentProgressCompleted"
      :action="pendingIndividualReportsArchive ? 'download' : 'generate'"
      :multiple="!!pendingIndividualReports || !!pendingIndividualReportsArchive"
      @close="disableReportDocumentProgressModal"
      @download="handleDownloadPendingDocument"
    />
    <confirmation-modal
      v-if="deletingReportDocument"
      action="delete"
      entity-type="reportDocument"
      :entity="deletingReportDocument"
      @cancel="closeDeleteReportDocumentModal"
      @confirm="confirmDeleteReportDocument"
    >
      <template #default>
        <div class="text-body-1">
          <strong>
            {{ getIndividualName(deletingReportDocument.targetIndividualId) }}
          </strong>
          <span> - </span>
          <span v-if="deletingReportDocument.variantCode">
            {{ $t(`global.languages.${deletingReportDocument.variantCode}`) }}
          </span>
        </div>
      </template>

      <template #details>
        <v-row no-gutters align="end" justify="center" class="pt-1">
          <span class="text-caption mr-1">
            {{ $t('analysis.generateReport.reportDocuments.queued') }}
          </span>
          <platform-date-display
            v-if="deletingReportDocument.queuedAt"
            class="text-body-2 grey--text text--darken-3"
            :date-time="deletingReportDocument.queuedAt"
          />
        </v-row>
      </template>
    </confirmation-modal>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import API from '@/services/API'
import {
  PlatformReport,
  PlatformReportGroup,
  ReportsArchiveRequest
} from '@/types/Report'
import { PlatformReportDocument, ReportDocumentInfo } from '@betterboards/shared/types/Report'
import { DefaultQuestionnaireVariantName } from '@betterboards/shared/helpers/entities/Questionnaire/index'
import { ReportTemplate } from '@/API'
import { PlatformQuestionnaireVariant, QuestionnaireVariantCode } from '@/types/Platform'
import { SelectItem } from '@/helpers/forms'
import { NullVariantCodeValue } from '@/helpers/questionnaire'
import { updateReportDocument } from '@/graphql/mutations'
import { onCreateReportDocument, onUpdateReportDocument } from '@/graphql/subscriptions'
import ReportDocumentsPanel from '@/components/report/reportDocument/ReportDocumentsPanel.vue'
import ReportDocumentProgressModal from '@/components/analysis/report-documents/ReportDocumentProgressModal.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import PlatformDateDisplay from '@/components/PlatformDateDisplay.vue'
import PlatformButton from '@/components/shared/PlatformButton.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { getFullName } from '@/helpers/individual'
import { downloadReportDocument, getLatestReportDocument, listReportDocuments, ReportDocumentType } from '@/helpers/reportDocument'
import { getVariantName } from '@betterboards/shared/helpers/entities/Variants/getVariantName'
import { triggerFileDownload } from '@/helpers/file'
import { ResultSet, ResultsIndividual } from '@betterboards/shared/types/ResultSet'
import { GenericReportIndividualId } from '@betterboards/shared/helpers/entities/Report/index'
import { ReportsIndividual } from '@betterboards/shared/types/Individual'
import { GraphQLOptionsV6 } from '@aws-amplify/api-graphql'
import { OnCreateReportDocumentSubscription, OnUpdateReportDocumentSubscription } from '@betterboards/shared/types/API'

export default Vue.extend({
  name: 'GenerateReport',
  components: {
    ReportDocumentProgressModal,
    ReportDocumentsPanel,
    ConfirmationModal,
    PlatformSpinner,
    PlatformDateDisplay,
    PlatformButton
  },
  props: {
    results: { type: Object as PropType<ResultSet>, required: true },
    report: { type: Object as PropType<PlatformReport>, required: true },
    selectedVariant: { type: String as PropType<QuestionnaireVariantCode | undefined>, required: false }
  },
  data: () => ({
    reportDocuments: [] as PlatformReportDocument[],
    subscriptions: [] as any[],
    showPreviousReports: undefined,
    pendingFetch: false,
    pendingIndividualReports: <boolean | QuestionnaireVariantCode>false,
    pendingIndividualReportsArchive: <boolean | QuestionnaireVariantCode>false,
    pendingGenerateReportDocument: <PlatformReportDocument | null>null,
    showReportDocumentProgressModal: false,
    reportDocumentProgressCompleted: false,
    deletingReportDocument: <PlatformReportDocument | null>null,
    deletedReportDocuments: <string[]>[],
    individualReportsArchiveLink: <string | undefined>undefined,
    pendingGenerateReportDocumentSince: <number | undefined>undefined,
    pendingIndividualReportsSince: <number | undefined>undefined,
    pendingIndividualReportsArchiveSince: <number | undefined>undefined,
    GenericReportIndividualId,
    NullVariantCodeValue
  }),
  mounted (): void {
    this.init()
  },
  beforeDestroy (): void {
    this.resetSubscriptions()
  },
  watch: {
    selectedCompany (val): void {
      if (val) {
        this.resetSubscriptions()
        this.setupSubscriptions()
      }
    },
    async individuals (individuals: ReportsIndividual[]): Promise<void> {
      if (!this.pendingIndividualReports) {
        return
      }
      // Check all Individuals have completed the variant in `pendingIndividualReports`
      const allIndividualsCompletedVariant = individuals.every(
        (individual) => {
          const targetVariantCode = typeof this.pendingIndividualReports === 'string'
            ? this.pendingIndividualReports
            : (individual.variantCode ?? this.selectedVariant)
          if (!individual.reports.length) {
            return false
          }
          const reportsByCreation: PlatformReportDocument[] = individual.reports.sort(
            (a: PlatformReportDocument, b: PlatformReportDocument) => b.createdAt.localeCompare(a.createdAt)
          )
          const mostRecentReport: PlatformReportDocument | undefined = reportsByCreation[0]
          if (mostRecentReport.variantCode === targetVariantCode && mostRecentReport.finishedAt) {
            console.debug('Individual Report ready', individual.id, Date.now() - (this.pendingIndividualReportsSince ?? 0))
            return true
          }
          return false
        }
      )
      if (allIndividualsCompletedVariant) {
        console.debug('Individual Reports archive ready', Date.now() - (this.pendingIndividualReportsArchiveSince ?? 0))
        // All Individuals
        if (this.showReportDocumentProgressModal) {
          await this.downloadAllIndividualReports(this.pendingIndividualReports === true ? undefined : this.pendingIndividualReports)
        }
        this.pendingIndividualReports = false
      }
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany', 'currentRole']),
    ...mapState('User', ['entity']),
    surveyGroupId (): string {
      return this.$route.params.surveyId
    },
    resultSet (): ResultSet {
      return this.$store.state.Analysis.resultSet
    },
    tableHeaders (): Object {
      const headers = [
        {
          text: 'Status',
          align: 'start',
          sortable: true,
          value: 'status'
        },
        {
          text: 'Updated At',
          align: 'start',
          sortable: true,
          value: 'lastUpdated'
        },
        {
          text: this.$t('analysis.generateReport.individual') as string,
          align: 'start',
          sortable: true,
          value: 'individual'
        }
      ]

      if (this.hasMultipleReportGroups) {
        headers.push(
          {
            text: this.$t('analysis.generateReport.report') as string,
            align: 'start',
            sortable: true,
            value: 'reportId'
          }
        )
      }
      if (this.hasMultipleVariants) {
        headers.push(
          {
            text: this.$t('analysis.generateReport.variant') as string,
            align: 'start',
            sortable: true,
            value: 'variantCode'
          }
        )
      }

      headers.push(
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'actions'
        }
      )

      return headers
    },
    isAdmin (): boolean {
      return this.currentRole === 'Admin'
    },
    individualsList (): ResultsIndividual[] {
      return this.$store.getters['Analysis/individualsList']
    },
    individuals (): ReportsIndividual[] {
      return this.individualsList.map((individual: ResultsIndividual) => {
        const individualReports = this.reportDocuments.filter((r) => r.targetIndividualId === individual.id)
        return {
          ...individual,
          variantCode: individual?.variantCode ?? this.selectedVariant ?? NullVariantCodeValue,
          reports: individualReports
        }
      })
    },
    availableReportGroups (): PlatformReportGroup[] {
      return this.$store.state.Report.reportGroups
    },
    hasMultipleReportGroups (): boolean {
      return this.availableReportGroups.length > 1
    },
    availableReportGroupOptions (): SelectItem[] {
      return [...this.availableReportGroups]
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map((reportGroup: PlatformReportGroup) => {
          return {
            text: reportGroup.name,
            value: reportGroup.id
          }
        })
    },
    availableVariants (): QuestionnaireVariantCode[] {
      if (!this.results?.variants) {
        return []
      }
      return this.results.variants.map((v: PlatformQuestionnaireVariant) => v.variantCode)
    },
    hasMultipleVariants (): boolean {
      return this.availableVariants.length > 1
    },
    questionnaireName (): string | undefined {
      return this.resultSet.name
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    },
    selectedReportGroupId (): string | null {
      return this.$store.state.Report.selectedReportGroupId
    },
    selectedReportGroup (): PlatformReportGroup | null {
      return this.$store.state.Report.reportGroups.find(
        (reportGroup) => reportGroup.id === this.selectedReportGroupId
      ) ?? null
    },
    reportGenerationDurationEstimate (): number | undefined {
      const reportDocumentEstimates = this.selectedReportGroup?.configuration?.reportDocumentEstimates
      if (!reportDocumentEstimates) {
        return undefined
      }
      if (this.pendingIndividualReports) {
        return reportDocumentEstimates.generateAllIndividualsDuration ?? undefined
      }
      if (this.pendingIndividualReportsArchive) {
        return reportDocumentEstimates.downloadIndividualsArchiveDuration ?? undefined
      }
      if (!this.pendingGenerateReportDocument) {
        return undefined
      }
      if (!this.pendingGenerateReportDocument.targetIndividualId) {
        return reportDocumentEstimates.generateCollectiveDuration ?? undefined
      }
      if (this.pendingGenerateReportDocument.targetIndividualId === GenericReportIndividualId) {
        return reportDocumentEstimates.generateGenericDuration ?? undefined
      }
      return reportDocumentEstimates.generateIndividualDuration ?? undefined
    }
  },
  methods: {
    async init (): Promise<void> {
      this.pendingFetch = true
      let results
      try {
        results = await listReportDocuments(this.surveyGroupId)
      } finally {
        this.pendingFetch = false
      }

      this.reportDocuments.splice(
        0,
        this.reportDocuments.length,
        ...(results ?? [])
      )
      if (this.selectedCompany.id) {
        this.setupSubscriptions()
      }
    },
    resetSubscriptions (): void {
      if (!this.subscriptions.length) {
        return
      }
      this.subscriptions.forEach(
        (item) => item.unsubscribe()
      )
      this.subscriptions.splice(0, this.subscriptions.length)
    },
    setupSubscriptions (): void {
      const updateReportDocumentSubscriptionParams: GraphQLOptionsV6<OnUpdateReportDocumentSubscription, typeof onUpdateReportDocument> = {
        query: onUpdateReportDocument,
        variables: {
          companyId: this.selectedCompany.id,
          requesterId: this.entity.username
        }
      }
      const createReportDocumentSubscriptionParams: GraphQLOptionsV6<OnCreateReportDocumentSubscription, typeof onCreateReportDocument> = {
        query: onCreateReportDocument,
        variables: {
          companyId: this.selectedCompany.id,
          requesterId: this.entity.username
        }
      }

      const updateReportDocumentSubscription = API.graphql(updateReportDocumentSubscriptionParams)
        .subscribe({
          next: ({ data }) => {
            const reportDocument: PlatformReportDocument = data.onUpdateReportDocument as PlatformReportDocument
            if (!reportDocument) {
              return
            }
            if (reportDocument.surveyGroupId !== this.surveyGroupId || reportDocument.template !== ReportTemplate.Default) {
              return
            }
            if (reportDocument.deletedAt || this.deletedReportDocuments.includes(reportDocument.id)) {
              this.removeReportDocumentFromList(reportDocument.id)
              return
            }
            const index = this.reportDocuments.findIndex((report) => report.id === reportDocument.id)
            if (index === -1) {
              this.reportDocuments.unshift({ ...reportDocument })
              return
            }
            this.reportDocuments.splice(index, 1, { ...reportDocument })
            if (reportDocument.finishedAt) {
              if (this.pendingGenerateReportDocument && this.pendingGenerateReportDocument.id === reportDocument.id) {
                this.reportDocumentProgressCompleted = true
                console.debug('Report Document ready', Date.now() - (this.pendingGenerateReportDocumentSince ?? 0))
                this.handleDownloadPendingDocument()
              }
            }
          },
          error: error => console.warn(error)
        })
      this.subscriptions.push(updateReportDocumentSubscription)

      const createReportDocumentSubscription = API.graphql(createReportDocumentSubscriptionParams)
        .subscribe({
          next: ({ data }) => {
            const reportDocument: PlatformReportDocument = data.onCreateReportDocument as PlatformReportDocument
            if (!reportDocument) {
              return
            }
            if (reportDocument.deletedAt || reportDocument.surveyGroupId !== this.surveyGroupId || reportDocument.template !== ReportTemplate.Default) {
              return
            }
            this.reportDocuments.unshift(reportDocument)
          },
          error: error => console.warn(error)
        })
      this.subscriptions.push(createReportDocumentSubscription)
    },
    async downloadAllIndividualReports (variantCode: undefined | QuestionnaireVariantCode): Promise<void> {
      if (!this.selectedReportGroup) {
        return
      }

      const allVariantReports = this.individuals
        .map((individual) => {
          // Get ReportDocuments for the specified variant
          const targetVariantCode = variantCode ?? individual.variantCode
          const individualVariantReports = individual.reports.filter(
            (r): boolean => {
              return r.variantCode === targetVariantCode
            }
          )
          return {
            individual,
            report: getLatestReportDocument(individualVariantReports)
          }
        })
      const variantReports = allVariantReports
        .filter(({ report }) => !!report) as Array<{ individual: ResultsIndividual, report: ReportDocumentInfo }>

      const reportVariants = this.selectedReportGroup?.variants ?? this.resultSet.variants
      const reportName = this.selectedReportGroup?.name ?? this.resultSet.name
      const archiveName = getVariantName(
        reportVariants,
        reportName
      )

      this.individualReportsArchiveLink = undefined
      this.pendingIndividualReportsArchive = variantCode ?? true
      this.pendingIndividualReportsSince = Date.now()
      this.enableReportDocumentProgressModal()
      const path = `/${this.selectedCompany.id}/files?objectType=reportsArchive`
      const fileName = this.getReportFileName(ReportDocumentType.Individual, archiveName)
      const response = await API.post('backendfunctions', path, {
        body: {
          objectType: 'reportsArchive',
          reports: variantReports.map(({ individual, report }) => {
            const individualReportName = getVariantName(
              reportVariants,
              reportName,
              report.variantCode
            )
            if (!individualReportName) {
              throw new Error(`Failed to build Individual Reports as a valid variant could not be found matching code "${variantCode}".`)
            }
            return {
              key: `${report.surveyGroupId}/${report.targetIndividualId}/${report.id}`,
              fileName: `${getFullName(individual)} - ${reportName}`
            }
          }),
          fileName
        } as ReportsArchiveRequest
      })
      this.individualReportsArchiveLink = response.link
      this.handleDownloadIndividualReportsArchive()
      this.reportDocumentProgressCompleted = true
    },
    async downloadReport (reportDocument: ReportDocumentInfo, fileName?: string): Promise<void> {
      await downloadReportDocument(
        reportDocument,
        fileName
      )
    },
    getReportFileName (report: ReportDocumentType, name?: string): string {
      switch (report) {
        case ReportDocumentType.Individual:
          return `${this.$t('files.report.individualReports', [name]) as string}`
        case ReportDocumentType.Generic:
          return `${this.$t('files.report.generalReport', [name]) as string}`
        case ReportDocumentType.Collective:
          return `${this.$t('files.report.collectiveReport', [name]) as string}`
        default:
          return `${this.$t('files.report.betterBoardReport') as string}`
      }
    },
    downloadIndividualReport (report: ReportDocumentInfo, individual?: ReportsIndividual): void {
      if (!individual) {
        individual = this.individuals.find((i) => i.id === report.targetIndividualId)
        if (!individual) {
          throw new Error(`Failed to download Individual Report, no individual was found with ID "${report.targetIndividualId}"`)
        }
      }
      const variant = this.resultSet.variants?.find((r) => r.variantCode === report.variantCode)
      let name: string | undefined = variant?.name
      if (!name || name === DefaultQuestionnaireVariantName) {
        name = this.resultSet.name
      }
      this.downloadReport(report, `${getFullName(individual)} - ${name}`)
    },
    downloadCollectiveReport (report: ReportDocumentInfo): void {
      const variant = this.resultSet.variants?.find((r) => r.variantCode === report.variantCode)
      let name: string | undefined = variant?.name
      if (!name || name === DefaultQuestionnaireVariantName) {
        name = this.resultSet.name
      }
      this.downloadReport(report, this.getReportFileName(ReportDocumentType.Collective, name))
    },
    downloadGenericReport (report: ReportDocumentInfo): void {
      const variant = this.resultSet.variants?.find((r) => r.variantCode === report.variantCode)
      let name: string | undefined = variant?.name
      if (!name || name === DefaultQuestionnaireVariantName) {
        name = this.resultSet.name
      }
      this.downloadReport(report, this.getReportFileName(ReportDocumentType.Generic, name))
    },
    downloadUnknownReport (report: ReportDocumentInfo): void {
      if (!report.targetIndividualId) {
        this.downloadCollectiveReport(report)
        return
      }
      if (report.targetIndividualId === GenericReportIndividualId) {
        this.downloadGenericReport(report)
        return
      }
      this.downloadIndividualReport(report)
    },
    handleQueuedReport (reportDocument: PlatformReportDocument): void {
      if (this.pendingGenerateReportDocument || this.showReportDocumentProgressModal) {
        return
      }
      this.pendingGenerateReportDocument = reportDocument
      this.pendingGenerateReportDocumentSince = Date.now()
      this.enableReportDocumentProgressModal()
    },
    handleQueuedIndividualReports ({ variantCode }: { variantCode: QuestionnaireVariantCode | undefined }): void {
      this.individualReportsArchiveLink = undefined
      this.pendingIndividualReports = variantCode ?? true
      this.pendingIndividualReportsSince = Date.now()
      this.enableReportDocumentProgressModal()
    },
    enableReportDocumentProgressModal (): void {
      this.showReportDocumentProgressModal = true
      this.reportDocumentProgressCompleted = false
    },
    disableReportDocumentProgressModal (): void {
      this.showReportDocumentProgressModal = false
      this.reportDocumentProgressCompleted = false
      this.pendingGenerateReportDocument = null
      this.pendingIndividualReports = false
      this.pendingIndividualReportsArchive = false
      this.individualReportsArchiveLink = undefined
    },
    getReportName (reportId: string): string | undefined {
      const reportGroup = this.availableReportGroups.find(
        (reportGroup: PlatformReportGroup) => reportGroup.reports?.items.some(
          (report: PlatformReport) => report.id === reportId
        )
      )
      if (!reportGroup) {
        console.warn('Failed to find ReportGroup for Report with ID:', reportId)
        return 'Unknown'
      }
      const report = reportGroup.reports.items.find(
        (report: PlatformReport) => report.id === reportId
      )
      if (!report) {
        console.warn('Failed to find Report for Report with ID:', { reportId, reportGroup })
        return 'Unknown'
      }
      const reportGroupVariant = reportGroup.variants.find(
        (variant: PlatformQuestionnaireVariant) => variant.variantCode === report.variantCode
      )
      if (!reportGroupVariant?.name) {
        console.warn('Failed to get name for variant?', report.variantCode)
        return 'Unknown'
      }
      if (reportGroupVariant.name === DefaultQuestionnaireVariantName) {
        return reportGroup.name
      }
      return reportGroupVariant.name
    },
    removeReportDocumentFromList (reportDocumentId: string): void {
      const index = this.reportDocuments.findIndex((r) => r.id === reportDocumentId)
      if (index !== -1) {
        this.reportDocuments.splice(index, 1)
      }
      if (this.deletedReportDocuments.includes(reportDocumentId)) {
        return
      }
      this.deletedReportDocuments.push(reportDocumentId)
    },
    showReportDocumentDeleteConfirmation (reportDocument: PlatformReportDocument): void {
      this.deletingReportDocument = reportDocument
    },
    getIndividualName (targetIndividualId?: string): string | undefined {
      if (!targetIndividualId) {
        return this.$t('analysis.generateReport.allText') as string
      }
      if (targetIndividualId === GenericReportIndividualId) {
        return this.$t('analysis.generateReport.noneText') as string
      }
      const individual = this.individuals.find((i) => i.id === targetIndividualId)

      if (!individual) {
        return undefined
      }

      if (individual.firstName || individual.familyName) {
        return `${individual.firstName} ${individual.familyName}`
      }
      if (individual.preferredName) {
        return individual.preferredName
      }
      return undefined
    },
    async confirmDeleteReportDocument (): Promise<void> {
      if (!this.deletingReportDocument) {
        return
      }
      const reportDocumentId = this.deletingReportDocument.id
      try {
        await API.graphql({
          query: updateReportDocument,
          variables: {
            input: {
              id: reportDocumentId,
              deletedAt: (new Date().toISOString())
            }
          }
        })
      } catch (err) {
        this.$toasted.error(
          this.$t('analysis.generateReport.reportDocuments.delete.deleteErrorMessage') as string
        )
        console.error('Failed to soft delete ReportDocument:', reportDocumentId, err)
        throw err
      } finally {
        this.closeDeleteReportDocumentModal()
      }
      this.removeReportDocumentFromList(reportDocumentId)
      this.$toasted.success(
        this.$t('analysis.generateReport.reportDocuments.delete.deleteSuccessMessage') as string
      )
    },
    closeDeleteReportDocumentModal (): void {
      this.deletingReportDocument = null
    },
    handleDownloadIndividualReportsArchive (): void {
      if (!this.individualReportsArchiveLink) {
        return
      }
      triggerFileDownload(this.individualReportsArchiveLink)
    },
    handleDownloadPendingDocument (): void {
      const pendingReportDocument: PlatformReportDocument | null = this.pendingGenerateReportDocument
      setTimeout(() => {
        // Slight delay to show the User the completion status before the download actually triggers
        if (this.pendingIndividualReportsArchive) {
          this.handleDownloadIndividualReportsArchive()
          return
        }
        if (!pendingReportDocument || !this.reportDocumentProgressCompleted) {
          return
        }
        this.downloadUnknownReport(pendingReportDocument)
      }, 400)
    }
  }
})
</script>
