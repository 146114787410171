<template>
  <v-container>
    <platform-spinner v-if="pendingFetch" class="my-3 mx-auto" />
    <v-treeview
      v-if="items && items.length"
      open-all
      :items="items"
      :dense="dense"
    >
      <template v-slot:prepend="{ item }">
        <platform-avatar
          entity-type="company"
          placeholder-size="xs"
          :entity="item"
          :size="36"
        />
      </template>
    </v-treeview>
  </v-container>
</template>

<script lang="ts">
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import API from '@/services/API'
import { Company } from '@/API'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

export default Vue.extend({
  name: 'CompanyHierarchyView',
  components: {
    PlatformAvatar,
    PlatformSpinner
  },
  props: {
    company: { type: Object as PropType<Company>, required: false },
    useSelectedCompany: { type: Boolean, default: false },
    dense: { type: Boolean, default: false }
  },
  data: () => ({
    items: [] as any[],
    pendingFetch: false
  }),
  mounted () {
    void this.fetchResults()
  },
  watch: {
    selectedCompany (): void {
      if (!this.useSelectedCompany) {
        return
      }
      void this.fetchResults()
    },
    'company.id' (val): void {
      if (!val) {
        return
      }
      void this.fetchResults()
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany']),
    targetCompanyId (): string {
      if (this.useSelectedCompany) {
        return this.selectedCompany.id
      }
      return this.company?.id
    }
  },
  methods: {
    async fetchResults () {
      this.pendingFetch = true
      this.items.splice(0, this.items.length)
      try {
        const results = await API.post('backendfunctions', '/company/hierarchy', {
          body: {
            companyId: this.targetCompanyId
          }
        })
        this.items.push(results)
      } finally {
        this.pendingFetch = false
      }
    }
  }
})
</script>
