<template>
  <v-row align="center" no-gutters>
    <v-col cols="auto" class="flex-grow-0 flex-shrink-1">
      <platform-icon
        v-hide="position === 1"
        :color="disabled ? 'disabled' : 'primary'"
        name="arrow-up"
        :size="16"
        class="mr-1"
        v-tooltip="$t('form.orderControls.moveItemUp', [entity])"
        @click.stop="moveUp()"
      />
    </v-col>
    <v-col cols="auto" class="flex-grow-0 flex-shrink-1">
      <v-tooltip v-if="position" top>
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">{{ position }} / {{ max }}</span>
        </template>
        <span>{{ $t('form.orderControls.positionLabel', [entity, position, max]) }}</span>
      </v-tooltip>
    </v-col>
    <v-col cols="auto" class="flex-grow-0 flex-shrink-1">
      <platform-icon
        v-hide="position === max"
        :color="disabled ? 'disabled' : 'secondary'"
        name="arrow-down"
        :size="16"
        class="ml-1"
        v-tooltip="$t('form.orderControls.moveItemDown', [entity])"
        @click.stop="moveDown()"
      />
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'OrderControls',
  props: {
    entity: { type: String, required: true },
    position: { type: Number, required: true },
    max: { type: Number, required: true },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    moveUp () {
      this.$emit('moveUp')
    },
    moveDown () {
      this.$emit('moveDown')
    }
  }
})
</script>
