<template>
  <div class="px-6">
    <create-individual-modal
      v-if="creatingIndividual"
      :individual="creatingIndividual"
      @close="creatingIndividual = null"
      @created="individualCreated"
    />
    <edit-individual-modal
      v-if="editingIndividual"
      :value="editingIndividual"
      @close="editingIndividual = null"
      @updated="individualUpdated"
    />
    <manage-teams-modal
      v-if="editingTeamId"
      allow-create-individual
      :origin-team-id="editingTeamId"
      @close="closeManageTeamsModal"
      @updated="fetchIndividuals"
    />
    <v-row no-gutters>
      <platform-button
        primary
        class="mb-4 ml-1"
        @click="manageIndividualsAction"
      >
        {{ manageIndividualsActionText }}
      </platform-button>
    </v-row>
    <v-row v-if="teamIndividualsList">
      <template v-if="teamIndividualsList.length > 0">
        <v-col
          v-for="(individual, index) in teamIndividualsList"
          :key="index"
          :cols="12"
          :sm="6"
          :md="4"
          :lg="3"
          class="text-center d-flex flex-column"
        >
          <platform-entity-card
            grid
            compact-buttons
            avatar-listen
            entity-type="individual"
            :entity="individual"
            :inline-header="false"
            :inner-margin="0"
            @delete="removeIndividual(individual)"
            @edit="editIndividual(individual)"
          >
            <span class="text-caption grey--text text--darken-1">
              {{ getJobTitle(individual) }}
            </span>
          </platform-entity-card>
        </v-col>
      </template>
      <v-col v-else-if="pendingFetch" class="text-center py-4">
        <platform-spinner class="mx-auto" />
      </v-col>
      <v-col v-else>
        <span class="text-body-2 grey--text">
          {{ noResultsMessage }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Company, CompanyTeamTypes, PackageItemTypes } from '@/API'
import CreateIndividualModal from '@/components/individuals/CreateIndividualModal.vue'
import EditIndividualModal from '@/components/individuals/EditIndividualModal.vue'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import ManageTeamsModal from '@/components/teams/ManageTeamsModal.vue'
import { PlatformPackage, PlatformPackageItem } from '@/types/Platform'
import { SurveyConfiguration } from '@/types/Survey'
import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { getJobTitle } from '@/helpers/individual'

export default Vue.extend({
  name: 'BoardMembersForm',
  props: {
    selectedPackage: { type: Object as PropType<PlatformPackage>, required: true }
  },
  data: () => ({
    creatingIndividual: false,
    editingIndividual: null,
    pendingFetch: false,
    editingTeamId: <CompanyTeamTypes | null>null
  }),
  components: {
    ManageTeamsModal,
    PlatformEntityCard,
    CreateIndividualModal,
    EditIndividualModal,
    PlatformSpinner
  },
  watch: {
    selectedCompany () {
      if (this.selectedCompany?.id && !this.individualsList.length) {
        this.fetchIndividuals()
      }
    }
  },
  mounted () {
    if (this.selectedCompany?.id) {
      this.fetchIndividuals()
    }
  },
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    individualsList (): PlatformIndividual[] {
      return this.$store.getters['Individual/individualsList']
    },
    teamIndividualsList (): PlatformIndividual[] {
      const packageTargetTeams = this.packageTargetTeams
      if (!packageTargetTeams?.length) {
        return this.individualsList
      }
      return this.individualsList.filter((i) => {
        return i.teams?.items?.some((ti) => packageTargetTeams.includes(ti.teamId))
      })
    },
    surveyConfiguration (): SurveyConfiguration | undefined {
      if (!this.selectedPackage?.packageItems.items.length) {
        return undefined
      }
      return this.selectedPackage?.packageItems.items.find(
        (pi): pi is PlatformPackageItem<SurveyConfiguration> => pi.type === PackageItemTypes.SurveyConfiguration
      )?.content
    },
    packageTargetTeams (): CompanyTeamTypes[] | undefined {
      return this.surveyConfiguration?.targetTeams
    },
    manageIndividualsActionText (): string {
      if (this.packageTargetTeams?.length) {
        return this.$t('form.wizard.boardMembers.manageIndividualsAction') as string
      }
      return this.$t('form.wizard.boardMembers.addBoardMember') as string
    },
    noResultsMessage (): string | undefined {
      if (!this.individualsList.length) {
        return this.$t('form.wizard.boardMembers.noResults') as string
      }
      const teamId: CompanyTeamTypes | undefined = this.packageTargetTeams?.[0]
      if (!teamId) {
        return undefined
      }
      const teamName = this.$t(`global.companyTeams.${teamId as string}`) as string
      return this.$t('form.wizard.boardMembers.noTeamIndividuals', [teamName]) as string
    }
  },
  methods: {
    ...mapActions('Individual', ['loadList', 'deleteIndividual']),
    getJobTitle,
    fetchIndividuals (): void {
      this.pendingFetch = true
      this.loadList(true)
        .catch((err) => {
          this.$toasted.error(this.$t('form.wizard.boardMembers.fetchIndividualsNoResultsMessage') as string)
          console.error('Failed to fetch individuals list', err)
        })
        .finally(() => {
          this.pendingFetch = false
        })
    },
    editIndividual (individual): void {
      this.editingIndividual = individual
    },
    removeIndividual (individual): void {
      this.deleteIndividual(individual)
        .then(() => {
          this.$toasted.success(this.$t('form.wizard.boardMembers.deleteSuccessMessage') as string)
        })
    },
    individualUpdated (): void {
      this.$toasted.success(this.$t('form.wizard.boardMembers.updateSuccessMessage') as string)
      this.editingIndividual = null
      this.fetchIndividuals()
    },
    individualCreated (): void {
      this.$toasted.success(this.$t('form.wizard.boardMembers.createSuccessMessage') as string)
      this.creatingIndividual = false
      this.fetchIndividuals()
      this.$emit('create')
    },
    manageIndividualsAction (): void {
      if (this.packageTargetTeams?.length) {
        this.editingTeamId = this.packageTargetTeams[0]
        return
      }
      this.creatingIndividual = true
    },
    closeManageTeamsModal (): void {
      this.editingTeamId = null
    }
  }
})
</script>
