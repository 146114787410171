<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 283.46 283.46" style="enable-background:new 0 0 283.46 283.46;" xml:space="preserve">
    <g>
      <path class="st0" d="M141.69,0.27C63.54,0.27-0.04,63.85-0.04,142s63.58,141.73,141.73,141.73S283.42,220.15,283.42,142
        S219.84,0.27,141.69,0.27z M141.69,278.43C66.46,278.43,5.26,217.23,5.26,142S66.46,5.57,141.69,5.57S278.12,66.77,278.12,142
        S216.92,278.43,141.69,278.43z"
            fill="currentColor"
      />
      <path class="st0" d="M177.54,70.56h-8.65v-8.17c0-1.38-1.12-2.5-2.5-2.5H52.16c-1.38,0-2.5,1.12-2.5,2.5v149.37
        c0,1.38,1.12,2.5,2.5,2.5h8.65v8.17c0,1.38,1.12,2.5,2.5,2.5h81.72c0.08,0,0.17,0,0.25-0.01c0.06-0.01,0.12-0.02,0.17-0.03
        c0.02,0,0.05-0.01,0.07-0.01c0.07-0.01,0.14-0.03,0.2-0.05c0.01,0,0.02-0.01,0.03-0.01c0.07-0.02,0.13-0.05,0.2-0.07
        c0.01,0,0.02-0.01,0.03-0.01c0.06-0.03,0.12-0.05,0.18-0.08c0.01-0.01,0.03-0.01,0.04-0.02c0.05-0.03,0.1-0.06,0.15-0.09
        c0.02-0.01,0.04-0.02,0.06-0.04c0.04-0.03,0.09-0.06,0.13-0.09c0.02-0.02,0.05-0.03,0.07-0.05c0.04-0.03,0.08-0.07,0.12-0.11
        c0.02-0.02,0.04-0.03,0.06-0.05l32.51-32.48c0.47-0.47,0.73-1.1,0.73-1.77V73.05C180.03,71.68,178.91,70.56,177.54,70.56z
         M60.81,73.05v136.21h-6.15V64.89h109.23v5.67H63.31C61.93,70.56,60.81,71.68,60.81,73.05z M65.81,75.55h109.23v111.87h-30.01
        c-1.38,0-2.5,1.12-2.5,2.5v30.01H65.81V75.55z M171.53,192.42l-24.01,23.99v-23.99H171.53z"
            fill="currentColor"
      />
      <path class="st0" d="M86.94,101.67h66.95c1.38,0,2.5-1.12,2.5-2.5s-1.12-2.5-2.5-2.5H86.94c-1.38,0-2.5,1.12-2.5,2.5
        C84.45,100.55,85.57,101.67,86.94,101.67z"
            fill="currentColor"
      />
      <path class="st0" d="M86.94,119.73h66.95c1.38,0,2.5-1.12,2.5-2.5s-1.12-2.5-2.5-2.5H86.94c-1.38,0-2.5,1.12-2.5,2.5
        C84.45,118.61,85.57,119.73,86.94,119.73z"
            fill="currentColor"
      />
      <path class="st0" d="M102.43,154.89v-14.45c0-1.38-1.12-2.5-2.5-2.5H80.82c-1.38,0-2.5,1.12-2.5,2.5v14.45c0,1.38,1.12,2.5,2.5,2.5
        h19.11C101.31,157.38,102.43,156.26,102.43,154.89z M97.43,152.39H83.32v-9.45h14.12v9.45H97.43z"
            fill="currentColor"
      />
      <path class="st0" d="M110.87,137.94c-1.38,0-2.5,1.12-2.5,2.5v14.45c0,1.38,1.12,2.5,2.5,2.5h19.11c1.38,0,2.5-1.12,2.5-2.5v-14.45
        c0-1.38-1.12-2.5-2.5-2.5H110.87z M127.48,152.39h-14.12v-9.45h14.12V152.39z"
            fill="currentColor"
      />
      <path class="st0" d="M138.42,140.44v14.45c0,1.38,1.12,2.5,2.5,2.5h19.11c1.38,0,2.5-1.12,2.5-2.5v-14.45c0-1.38-1.12-2.5-2.5-2.5
        h-19.11C139.54,137.94,138.42,139.06,138.42,140.44z M143.41,142.94h14.12v9.45h-14.12V142.94z"
            fill="currentColor"
      />
      <path class="st0" d="M113.08,165.08H93.97c-1.38,0-2.5,1.12-2.5,2.5v14.45c0,1.38,1.12,2.5,2.5,2.5h19.11c1.38,0,2.5-1.12,2.5-2.5
        v-14.45C115.58,166.2,114.46,165.08,113.08,165.08z M110.58,179.52H96.47v-9.45h14.12v9.45H110.58z"
            fill="currentColor"
      />
      <path class="st0" d="M143.13,184.52c1.38,0,2.5-1.12,2.5-2.5v-14.45c0-1.38-1.12-2.5-2.5-2.5h-19.11c-1.38,0-2.5,1.12-2.5,2.5v14.45
        c0,1.38,1.12,2.5,2.5,2.5H143.13z M126.51,170.07h14.12v9.45h-14.12V170.07z"
            fill="currentColor"
      />
      <path class="st0" d="M223.61,190.44c-0.32,0-0.64-0.06-0.95-0.19c-0.94-0.39-1.55-1.3-1.55-2.31v-21.39h-32.82
        c-1.38,0-2.5-1.12-2.5-2.5v-42.23c0-1.38,1.12-2.5,2.5-2.5h32.82V97.93c0-1.01,0.61-1.92,1.55-2.31s2.01-0.17,2.73,0.55l44.77,44.94
        c0.97,0.97,0.97,2.55,0,3.53l-44.77,45.07C224.91,190.18,224.26,190.44,223.61,190.44z M190.79,161.55h32.82
        c1.38,0,2.5,1.12,2.5,2.5v17.83l38.74-39l-38.74-38.89v17.84c0,1.38-1.12,2.5-2.5,2.5h-32.82L190.79,161.55L190.79,161.55z"
            fill="currentColor"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SampleReportsIcon'
})
</script>
