import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const listFullSurveyGroupsByQuestionnaire = /* GraphQL */ `
  query ListFullSurveyGroupsByQuestionnaire(
    $questionnaireId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyGroupsByQuestionnaire(
      questionnaireId: $questionnaireId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionnaireId
        companyId
        packageId
        targetIndividualIds
        name
        variants {
          name
          variantCode
        }
        status
        createdAt
        updatedAt
        releasedAt
        dispatchedAt
        deletedAt
        tags
        surveys {
          nextToken
          items {
            id
            groupId
            companyId
            questionnaireId
            individualId
            owner
            key
            finishedAt
            dispatchedAt
            deletedAt
            totals {
              questions
              questionsCompleted
            }
            status
            invitation
            createdAt
            updatedAt
          }
        }
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.ListFullSurveyGroupsByQuestionnaireQueryVariables,
  APITypes.ListFullSurveyGroupsByQuestionnaireQuery
>
