<template>
  <v-card class="grow pt-12 pb-8 px-4">
    <v-container>
      <v-row class="fill-height flex-column flex-nowrap" justify="space-between" :class="{
        'px-16': $vuetify.breakpoint.lgAndUp,
        'mx-16': $vuetify.breakpoint.xlOnly,
      }">
        <v-row class="pb-3">
          <v-col :cols="7"></v-col>
          <v-col :cols="5" class="text-center">
            <span class="table-header large black--text">
              {{ $t('analysis.boardTenure.yearsOnBoard') }}
            </span>
          </v-col>
        </v-row>
        <v-row
          v-for="(tenureIndividual, index) in individualTenures"
          :key="`${tenureIndividual.id}-${index}`"
          no-gutters
          class="py-2"
          align="center"
        >
          <v-col :cols="7">
            <v-row align="center">
              <v-col class="shrink">
                <platform-avatar
                  entity-type="individual"
                  :entity="tenureIndividual"
                />
              </v-col>
              <v-col class="grow">
                <v-row no-gutters align="center">
                  <v-col class="shrink text-no-wrap text--compact font-weight-bold">
                    {{ tenureIndividual.fullName }}
                  </v-col>
                  <v-col class="pl-2 text-no-wrap text--compact">
                    -
                    <span class="text-caption grey--text text--darken-4">
                      <template v-if="tenureIndividual.jobTitle">
                        {{ tenureIndividual.jobTitle }}
                      </template>
                      <template v-else>
                        {{ $t(`shared.individual.roles.${tenureIndividual.role}`) }}
                      </template>
                    </span>
                  </v-col>
                </v-row>
                <v-row v-if="tenureIndividual.totalAppointments" no-gutters justify="start" class="pt-1">
                  <v-chip
                    x-small
                    outlined
                    color="grey lighten-1"
                    class="px-2"
                  >
                    <span class="grey--text text--darken-1">
                      <strong>
                        {{ tenureIndividual.totalAppointments }}
                      </strong>
                      {{ $t('analysis.boardTenure.totalAppointments') }}
                    </span>
                  </v-chip>
                  <div
                    v-for="(appointmentItem, a) in tenureIndividual.appointmentItems"
                    :key="a"
                    v-show="!!appointmentItem.count"
                    class="text-no-wrap ml-3 mb-1"
                    style="font-size: 10px;"
                  >
                    <span class="grey--text text--darken-1">
                      <strong>
                        {{ appointmentItem.count }}
                      </strong>
                      {{ $t(`shared.individual.boardAppointments.${appointmentItem.role}`) }}
                    </span>
                  </div>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="5" class="text-center">
            <span
              class="result-bubble white--text d-inline-block px-2 py-2 font-weight-bold"
              :class="[`status-${tenureIndividual.tenureLengthStatus}`]"
            >
              <template v-if="tenureIndividual.tenureLength === undefined || tenureIndividual.tenureLength === 0">
                {{ LessThanYearPlaceholder }}
              </template>
              <template v-else>
                {{ tenureIndividual.tenureLength }}
              </template>
            </span>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-row no-gutters class="legend flex-column pt-12" justify="center">
      <v-col cols="auto">
        <v-row v-if="legendItems.chair" no-gutters>
          <v-col :cols="12" :sm="3" :xl="2" class="font-weight-bold text-md-right">
            <span class="pr-10 text-no-wrap">
              {{ $t('report.tenure.chair.title') }}
            </span>
          </v-col>
          <v-col :class="{ 'text-no-wrap': $vuetify.breakpoint.lgAndUp }">
            <div class="d-inline-block pr-5">
              <span
                class="result-bubble small status-okay white--text d-inline-block px-3 py-2 mr-3"
              />
              <span class="text-no-wrap">{{ $t('report.tenure.chair.okay') }}</span>
            </div>
            <div class="d-inline-block pr-5">
              <span
                class="result-bubble small status-warning white--text d-inline-block px-3 py-2 mr-3"
              />
              <span class="text-no-wrap">{{ $t('report.tenure.chair.warning') }}</span>
            </div>
            <div class="d-inline-block pr-5">
              <span
                class="result-bubble small status-danger white--text d-inline-block px-3 py-2 mr-3"
              />
              <span class="text-no-wrap">{{ $t('report.tenure.chair.danger') }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="legendItems.nonExecutive" no-gutters>
          <v-col :cols="12" :sm="3" :xl="2" class="font-weight-bold text-md-right">
            <span class="pr-10 text-no-wrap">
              {{ $t('report.tenure.nonExecutive.title') }}
            </span>
          </v-col>
          <v-col :class="{ 'text-no-wrap': $vuetify.breakpoint.lgAndUp }">
            <div class="d-inline-block pr-5">
              <span
                class="result-bubble small status-okay white--text d-inline-block px-3 py-2 mr-3"
              />
              <span class="text-no-wrap">{{ $t('report.tenure.nonExecutive.okay') }}</span>
            </div>
            <div class="d-inline-block pr-5">
              <span
                class="result-bubble small status-warning white--text d-inline-block px-3 py-2 mr-3"
              />
              <span class="text-no-wrap">{{ $t('report.tenure.nonExecutive.warning') }}</span>
            </div>
            <div class="d-inline-block pr-5">
              <span
                class="result-bubble small status-danger white--text d-inline-block px-3 py-2 mr-3"
              />
              <span class="text-no-wrap">{{ $t('report.tenure.nonExecutive.danger') }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="legendItems.executive" no-gutters>
          <v-col :cols="12" :sm="3" :xl="2" class="font-weight-bold text-md-right">
            <span class="pr-10 text-no-wrap">
              {{ $t('report.tenure.executive.title') }}
            </span>
          </v-col>
          <v-col :class="{ 'text-no-wrap': $vuetify.breakpoint.lgAndUp }">
            <div class="shrink d-flex flex-nowrap align-center pr-5">
              <span
                class="result-bubble small status-none white--text d-inline-block px-3 py-2 mr-3"
              />
              <span class="text-no-wrap">{{ $t('report.tenure.executive.noLimit') }}</span>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import calculateIndividualTenures from '@betterboards/shared/helpers/entities/Individual/tenure/calculateIndividualTenures'
import calculateTenureLegendItems from '@betterboards/shared/helpers/entities/Individual/tenure/calculateTenureLegendItems'
import { TenureIndividual, TenureLegendConfig } from '@betterboards/shared/types/Individual'
import { ResultSet } from '@betterboards/shared/types/ResultSet'
import Vue, { PropType } from 'vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'

const LessThanYearPlaceholder = '< 1'

export default Vue.extend({
  name: 'BoardTenure',
  components: {
    PlatformAvatar
  },
  props: {
    results: { type: Object as PropType<ResultSet>, required: true }
  },
  data: () => ({
    LessThanYearPlaceholder
  }),
  computed: {
    individualTenures (): TenureIndividual[] {
      return calculateIndividualTenures(this.results.individuals)
    },
    legendItems (): TenureLegendConfig {
      return calculateTenureLegendItems(this.individualTenures)
    }
  }
})
</script>
