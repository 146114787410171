import { PlatformIndividual } from '../../../types/Individual'
import { GraphVote, MultipleChoiceVoteResult } from '../../../types/Graph'
import { getConcisePreferredName } from '../Individual/getConcisePreferredName'

export function normalizeVoteResult (individuals: PlatformIndividual[], v: MultipleChoiceVoteResult): GraphVote {
  const individual: PlatformIndividual | undefined = individuals.find((i: PlatformIndividual) => i.id === v.optionId)
  const displayName = individual
    ? getConcisePreferredName(individual, individuals)
    : undefined

  return {
    option: displayName ?? v.optionId,
    value: v.value
  }
}
