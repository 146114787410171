import { QuestionnaireVariantCode } from '@/types/Platform'
import { Module } from 'vuex'
import { Individual } from '@/API'
import API from '@/services/API'
import fetchUniverseData from '@/helpers/fetchUniverseData'
import { ReportBenchmark, ScoreCollectionGroup } from '@betterboards/shared/types/ReportBenchmark'
import { ResultSet, ResultsIndividual, SectionAveragesData, SectionResults } from '@betterboards/shared/types/ResultSet'
import { getJobTitle } from '@/helpers/individual'
import { StatsResult } from '@betterboards/shared/types/Statistic'
import { ScoreCollection } from '@betterboards/shared/helpers/entities/Result/scores'
import { SurveyGroupProgress } from '@/types/SurveyGroup'
import { getScoreCollectionTitle } from '@betterboards/shared/helpers/entities/Result/getScoreCollectionTitle'
import { PlatformReport } from '@betterboards/shared/types/Report'
import { PlatformCompany } from '@betterboards/shared/types/Company'

interface AnalysisState {
  surveyGroupId: string | null
  individuals: Individual[]
  resultSet: any
  comparisonResultSet: any
  variantCode: QuestionnaireVariantCode | undefined
  activeSectionId: string | undefined
  pendingFetch: boolean
}

export enum SystemPage {
  Dashboard = 'dashboard',
  Progress = 'progress',
  ResponseStatus = 'response-status',
  EvaluationInformation = 'evaluation-information',
  ResultsOverview = 'results-overview',
  FocusAreas = 'focus-areas',
  BoardTenure = 'board-tenure',
  Diversity = 'diversity',
  GenerateReport = 'generate-report',
}

const defaultState = (): AnalysisState => ({
  surveyGroupId: null as string | null,
  individuals: [],
  resultSet: null as ResultSet | null,
  comparisonResultSet: null as ResultSet | null,
  variantCode: undefined as QuestionnaireVariantCode | undefined,
  activeSectionId: undefined as string | undefined,
  pendingFetch: false
})

export interface SystemPageConfig {
  route: string
  slug: SystemPage
  component: string
  universePicker?: boolean
  configureScores?: boolean
  comparisonPicker?: boolean
  position: 'start' | 'end' // Shown before or after sections in list?
  icon?: string
}

export const SystemPages: { [key: string]: SystemPageConfig } = {
  [SystemPage.Dashboard]: { component: 'AnalysisDashboard', position: 'start', route: 'AnalysisDashboard', slug: SystemPage.Dashboard, icon: 'home' },
  [SystemPage.Progress]: { component: 'AnalysisProgress', position: 'start', route: 'AnalysisProgress', slug: SystemPage.Progress, icon: 'progress' },
  [SystemPage.ResponseStatus]: { component: 'ResponseStatus', position: 'start', route: 'AnalysisResponseStatus', slug: SystemPage.ResponseStatus, icon: 'response-status' },
  [SystemPage.ResultsOverview]: { component: 'ResultsOverview', universePicker: true, configureScores: true, comparisonPicker: true, position: 'start', route: 'AnalysisResultsOverview', slug: SystemPage.ResultsOverview, icon: 'results-overview' },
  [SystemPage.EvaluationInformation]: { component: 'EvaluationInformation', position: 'start', route: 'AnalysisEvaluationInformation', slug: SystemPage.EvaluationInformation, icon: 'text-box' },
  [SystemPage.FocusAreas]: { component: 'FocusAreas', position: 'end', route: 'AnalysisFocusAreas', slug: SystemPage.FocusAreas, icon: 'text-box' },
  [SystemPage.Diversity]: { component: 'Diversity', position: 'end', route: 'AnalysisDiversity', slug: SystemPage.Diversity, icon: 'crowd' },
  [SystemPage.BoardTenure]: { component: 'BoardTenure', position: 'end', route: 'AnalysisBoardTenure', slug: SystemPage.BoardTenure, icon: 'calendar-person' },
  [SystemPage.GenerateReport]: { component: 'GenerateReport', position: 'end', route: 'AnalysisGenerateReport', slug: SystemPage.GenerateReport, icon: 'document' }
}

const AnalysisStore: Module<AnalysisState, any> = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    reset: (state: AnalysisState) => {
      const defaultData = defaultState()
      for (const key of Object.keys(defaultData)) {
        state[key] = defaultData[key]
      }
    },
    setIndividuals: (state: AnalysisState, value: Individual[]) => (state.individuals = value),
    setSurveyGroupId: (state: AnalysisState, value: any) => (state.surveyGroupId = value),
    setResultSet: (state: AnalysisState, value: any) => (state.resultSet = value),
    setComparisonResultSet: (state: AnalysisState, value: any) => (state.comparisonResultSet = value),
    resetComparisonResultSet: (state: AnalysisState) => (state.comparisonResultSet = null),
    setActiveVariant: (state: AnalysisState, variantCode: QuestionnaireVariantCode) => (state.variantCode = variantCode),
    setActiveSection: (state: AnalysisState, sectionId: string) => (state.activeSectionId = sectionId),
    setPendingFetch: (state: AnalysisState, val: boolean) => (state.pendingFetch = val)
  },
  getters: {
    selectedSurveyGroup (state, getters, rootState, rootGetters): SurveyGroupProgress | undefined {
      const surveyGroupId = state.surveyGroupId
      const surveyGroupsList = rootGetters['SurveyGroup/list']
      const surveyGroup = surveyGroupsList.find((s) => s.id === surveyGroupId)
      if (!surveyGroup) {
        return undefined
      }
      return surveyGroup
    },
    availableComparisonSurveyGroups (state, getters, rootState, rootGetters): SurveyGroupProgress[] {
      const selectedSurveyGroup = getters.selectedSurveyGroup
      const surveyGroupsList = rootGetters['SurveyGroup/list']
      const surveyGroups = surveyGroupsList.filter(
        (s) => s.id !== selectedSurveyGroup.id
      )
      if (!surveyGroups.length) {
        return []
      }
      return surveyGroups
    },
    completion (state) {
      return {
        count: 0,
        total: 0
      }
    },
    individualsList (state: AnalysisState): ResultsIndividual[] {
      if (!state.resultSet) {
        return []
      }
      return state.resultSet.individuals.map((individual: ResultsIndividual) => {
        return {
          ...individual,
          roleName: getJobTitle(individual)
        }
      })
    },
    sectionScores (state: AnalysisState, getters, rootState, rootGetters): ScoreCollectionGroup[] | null {
      if (!state.activeSectionId) {
        return null
      }
      const selectedReport: PlatformReport = rootGetters['Report/selectedReport']
      if (!selectedReport) {
        return null
      }
      const section: SectionResults | undefined = state.resultSet?.sections?.find(
        (section: SectionResults) => section.id === state.activeSectionId
      )
      if (!section) {
        return null
      }

      const selectedCompany: PlatformCompany = rootState.Company.selectedCompany
      const enabledReportBenchmarks: ReportBenchmark[] = rootGetters['Report/enabledReportBenchmarks']
      if (!enabledReportBenchmarks?.length) {
        return null
      }

      const scores: ScoreCollectionGroup[] = []

      if (section.averages?.subSections?.length) {
        section.averages.subSections.forEach((subSectionScores: SectionAveragesData) => {
          const subSectionTitle: string = subSectionScores.name
          const subSectionScore: number | string | undefined = subSectionScores.average ?? undefined
          if (subSectionScore) {
            scores.push({
              sectionId: state.activeSectionId,
              masterSectionId: section.masterSectionId,
              subSectionId: subSectionScores.slug,
              scores: [
                {
                  collection: ScoreCollection.Average,
                  value: subSectionScore,
                  label: subSectionTitle,
                  tooltip: undefined
                }
              ]
            })
          }
        })
      } else {
        const sectionAverageTitle: string = getScoreCollectionTitle(
          ScoreCollection.Average,
          selectedReport,
          selectedCompany
        )
        const sectionAverage: number | string | undefined = section.averages?.average ?? undefined
        if (sectionAverage) {
          scores.push({
            sectionId: state.activeSectionId,
            masterSectionId: section.masterSectionId,
            scores: [
              {
                collection: ScoreCollection.Average,
                value: sectionAverage,
                label: sectionAverageTitle,
                tooltip: undefined
              }
            ]
          })
        }
      }

      enabledReportBenchmarks.forEach((reportScore: ReportBenchmark) => {
        if (!reportScore.scores?.length) {
          return
        }
        reportScore.scores.forEach((score: StatsResult) => {
          if (score.masterSectionId && section.masterSectionId) {
            if (score.masterSectionId !== section.masterSectionId) {
              return
            }
          } else {
            const matchingSubSectionScore: ScoreCollectionGroup | undefined = scores.find(
              (s: ScoreCollectionGroup): boolean => s.subSectionId === score.key
            )
            if (score.key !== state.activeSectionId && score.key !== section.slug && !matchingSubSectionScore) {
              return
            }
          }

          if (score.subSections?.length) {
            score.subSections.forEach((subSectionScore) => {
              console.debug('subSectionScore:', subSectionScore)
              let subSectionScoresGroup: ScoreCollectionGroup | undefined = scores.find(
                (g: ScoreCollectionGroup) => {
                  return g.subSectionId === subSectionScore.key && g.masterSectionId === section.masterSectionId
                }
              )
              if (!subSectionScoresGroup) {
                subSectionScoresGroup = {
                  sectionId: score.key,
                  masterSectionId: score.masterSectionId,
                  subSectionId: subSectionScore.key,
                  scores: []
                }
                scores.push(subSectionScoresGroup)
              }
              subSectionScoresGroup.scores.push({
                collection: reportScore.collection,
                label: reportScore.title,
                value: subSectionScore.value,
                tooltip: undefined
              })
            })
            return
          }

          let sectionScoresGroup: ScoreCollectionGroup | undefined = scores.find(
            (g: ScoreCollectionGroup): boolean => {
              if (g.subSectionId) {
                return g.sectionId === state.activeSectionId && g.subSectionId === score.key
              }
              return g.sectionId === state.activeSectionId
            }
          )
          if (!sectionScoresGroup) {
            sectionScoresGroup = {
              sectionId: score.key,
              masterSectionId: score.masterSectionId,
              scores: []
            }
            scores.push(sectionScoresGroup)
          }
          sectionScoresGroup.scores.push({
            collection: reportScore.collection,
            label: reportScore.title,
            value: score.value,
            tooltip: undefined
          })
        })
      })
      return scores
    }
  },
  actions: {
    async fetchResultSet ({ commit, state }, { surveyGroupId, variantCode }: { surveyGroupId: string, variantCode?: string }) {
      return await API.post('backendfunctions', `/questionnaire/results/${surveyGroupId}`, {
        body: {
          variantCode
        }
      })
    },
    async getResultSet ({ commit, state, dispatch }, payload: { surveyGroupId: string, variantCode?: string }) {
      commit('setSurveyGroupId', payload.surveyGroupId)
      const response = await dispatch('fetchResultSet', payload)
      commit('setResultSet', response)
    },
    async getComparisonResultSet ({ commit, state, dispatch }, payload: { surveyGroupId: string, variantCode?: string }) {
      const response = await dispatch('fetchResultSet', payload)
      commit('setComparisonResultSet', response)
    },
    async fetchUniverse ({ commit }, { key, queries }: { key: string, queries?: Array<{ collection: string, category: string }> }) {
      const results = await fetchUniverseData(queries)
      return results
    }
  }
}

export default AnalysisStore
