export default function buildTriangleIconPath (
  x: number,
  y: number,
  scale: number
): string {
  const horizontalSideLength: number = Math.round(6 * scale)
  const verticalSideLength: number = Math.round(10 * scale)
  const pathParts: string[] = [
    `M ${x} ${y}`, // Top middle corner
    `L ${x - horizontalSideLength} ${y + verticalSideLength}`, // Bottom left corner
    `L ${x + horizontalSideLength} ${y + verticalSideLength}`, // Bottom right corner
    `L ${x} ${y} z` // Back to top middle to close
  ]
  return pathParts.join(' ')
}
