import Vue from 'vue'
import VueToasted from 'vue-toasted'

Vue.use(VueToasted, {
  duration: 5000,
  iconPack: 'mdi'
})

const DismissAction = {
  text: 'Dismiss',
  onClick (e, toastObject) {
    toastObject.goAway(50)
  }
}

Vue.toasted.register('notify', (payload: string) => payload, {
  action: DismissAction
})

Vue.toasted.register('success', (payload: string) => payload, {
  className: 'success'
})
Vue.toasted.register('successQuick', (payload: string) => payload, {
  className: 'success',
  duration: 1500
})

Vue.toasted.register('error', (action: string) => {
  return `Failed to ${action}, please try again or contact support.`
}, {
  className: 'error'
})
