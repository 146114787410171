<template>
  <v-form ref="form" v-model="formValid" @submit.prevent="">
    <v-form-base
      :model="address"
      :schema="formSchema"
      @input="handleInput"
    />
  </v-form>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import { DefaultAddress } from '@/types/Platform'

interface CountryOptions {
  value: string
  text: string
}

export default Vue.extend({
  name: 'AddressForm',
  props: {
    value: { type: [Object, String], required: true },
    countryOptions: { type: Array as PropType<CountryOptions[]>, required: true },
    loadingCountries: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false }
  },
  components: {
    VFormBase
  },
  data: () => ({
    formValid: false
  }),
  watch: {
    formValid (val) {
      this.$emit('valid', val)
    }
  },
  computed: {
    formSchema () {
      const schema: any = {}

      const sharedProps = {
        disabled: this.disabled
      }

      schema.line1 = { type: 'text', label: this.$t('form.company.addressLine1') as string, col: { xs: 12, sm: 6 }, ...sharedProps }
      schema.line2 = { type: 'text', label: this.$t('form.company.addressLine2') as string, col: { xs: 12, sm: 6 }, ...sharedProps }
      schema.city = { type: 'text', label: this.$t('form.company.city') as string, col: { xs: 12, sm: 6 }, ...sharedProps }
      schema.region = { type: 'text', label: this.$t('form.company.region') as string, col: { xs: 12, sm: 6 }, ...sharedProps }
      schema.postalCode = { type: 'text', label: this.$t('form.company.postalCode') as string, col: { xs: 12, sm: 6 }, ...sharedProps }
      schema.country = {
        type: 'BBAutocomplete',
        label: this.$t('form.company.country') as string,
        required: true,
        items: this.countryOptionsTranslated,
        col: { xs: 12, sm: 6 },
        loading: this.loadingCountries,
        disabled: this.loadingCountries || this.disabled,
        'data-test-target': 'companyFormCountryField',
        menuProps: { contentClass: 'company-form__country-dropdown' }
      }
      return schema
    },
    countryOptionsTranslated (): CountryOptions[] {
      return this.countryOptions.map((item) => ({
        value: item.value,
        text: this.$t(`global.countries.${item.value}`) as string
      }))
    },
    address: {
      set (val: string | any): void {
        this.$emit('input', val)
      },
      get (): string | any {
        if (typeof this.value === 'string') {
          return {
            ...DefaultAddress,
            line1: this.value
          }
        }
        return this.value
      }
    }
  },
  methods: {
    handleInput (val: any) {
      this.address = val.data
    },
    validate () {
      const form: any = this.$refs.form
      form.validate()
    }
  }
})
</script>
