<template>
  <v-row no-gutters class="py-1 flex-nowrap overflow-hidden" align="center">
    <div
      v-if="reportScore.companyId || reportScore.icon"
      style="width: 32px;"
      class="mr-3"
    >
      <platform-avatar
        v-if="reportScore.companyId"
        entity-type="company"
        :entity="{ id: reportScore.companyId }"
        :size="iconSize"
        :pad-bottom="false"
      />
      <platform-icon
        v-else-if="reportScore.icon"
        color="primary"
        :name="reportScore.icon"
        :size="iconSize"
      />
    </div>

    <div class="flex-grow-1 text-no-wrap overflow-ellipses d-flex align-center">
      <strong class="primary--text">
        {{ reportScore.title }}
      </strong>
      <span
        class="text-caption ml-1"
        v-if="reportScore.subTitle"
        v-tooltip:top="reportScore.subTitle"
      >
        - {{ reportScore.subTitle }}
      </span>
    </div>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { ReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'

export default Vue.extend({
  name: 'DisplayReportBenchmark',
  components: {
    PlatformAvatar
  },
  props: {
    value: { type: Object as PropType<ReportBenchmark>, required: true }
  },
  data: () => ({

  }),
  computed: {
    reportScore (): ReportBenchmark {
      return this.value
    },
    iconSize (): number {
      return 36
    }
  },
  methods: {

  }
})
</script>
