<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 741 741" xml:space="preserve">
    <g>
      <path id="svg_170" fill="currentColor" d="m597.75,267.91l-11.65,12.51c-1.27,-1.64 -3.16,-2.76 -5.4,-2.76l-109.19,0c-3.83,0 -6.94,3.11 -6.94,6.94l0,109.19c0,3.83 3.11,6.94 6.94,6.94l109.19,0c3.83,0 6.94,-3.11 6.94,-6.94l0,-94.66l20.26,-21.76c2.61,-2.81 2.45,-7.2 -0.35,-9.81c-2.79,-2.6 -7.18,-2.45 -9.8,0.35zm-23.98,118.95l-95.32,0l0,-95.32l95.32,0l0,2.13l-47.68,51.21l-20.66,-25.25c-2.43,-2.96 -6.79,-3.4 -9.76,-0.98c-2.96,2.43 -3.4,6.8 -0.98,9.76l25.69,31.4c1.27,1.55 3.14,2.48 5.15,2.54c0.07,0 0.15,0 0.22,0c1.92,0 3.76,-0.8 5.08,-2.21l42.94,-46.12l0,72.84l0,0z" class="st43"/>
      <path id="svg_171" fill="currentColor" d="m597.75,424.57l-11.65,12.51c-1.27,-1.64 -3.17,-2.76 -5.4,-2.76l-109.19,0c-3.83,0 -6.94,3.11 -6.94,6.94l0,109.19c0,3.83 3.11,6.94 6.94,6.94l109.19,0c3.83,0 6.94,-3.11 6.94,-6.94l0,-94.66l20.26,-21.76c2.61,-2.81 2.45,-7.2 -0.35,-9.81c-2.79,-2.6 -7.18,-2.46 -9.8,0.35zm-23.98,118.94l-95.32,0l0,-95.32l95.32,0l0,2.14l-47.68,51.21l-20.66,-25.25c-2.43,-2.96 -6.79,-3.4 -9.76,-0.98c-2.96,2.43 -3.4,6.8 -0.98,9.76l25.69,31.4c1.27,1.55 3.14,2.48 5.15,2.54c0.07,0 0.15,0 0.22,0c1.92,0 3.76,-0.8 5.08,-2.21l42.94,-46.12l0,72.83l0,0z" class="st43"/>
      <path id="svg_172" fill="currentColor" d="m278.01,335.4c43.42,0 78.75,-35.33 78.75,-78.75s-35.33,-78.75 -78.75,-78.75s-78.75,35.32 -78.75,78.75s35.32,78.75 78.75,78.75zm0,-143.62c35.77,0 64.87,29.1 64.87,64.87s-29.1,64.87 -64.87,64.87s-64.87,-29.1 -64.87,-64.87s29.1,-64.87 64.87,-64.87z" class="st43"/>
      <path id="svg_173" fill="currentColor" d="m278.01,370.82c-72.78,0 -131.99,59.21 -131.99,131.99l0,47.64c0,3.83 3.11,6.94 6.94,6.94l250.11,0c3.83,0 6.94,-3.11 6.94,-6.94l0,-47.64c-0.01,-72.78 -59.22,-131.99 -132,-131.99zm118.11,172.69l-236.23,0l0,-40.7c0,-65.13 52.98,-118.11 118.11,-118.11s118.12,52.98 118.12,118.11l0,40.7z" class="st43"/>
      <path id="svg_174" fill="currentColor" d="m370.78,0c-204.45,0 -370.78,166.33 -370.78,370.78s166.33,370.78 370.78,370.78s370.78,-166.33 370.78,-370.78s-166.34,-370.78 -370.78,-370.78zm0,727.68c-196.8,0 -356.91,-160.11 -356.91,-356.91s160.11,-356.91 356.91,-356.91s356.91,160.11 356.91,356.91s-160.11,356.91 -356.91,356.91z" class="st43"/>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AnalyseResponseIcon'
})
</script>
