<template>
  <v-row class="flex-col">
    <v-col v-if="showLegend">
      <v-container>
        <v-row class="px-4 px-xl-16 pb-6">
          <v-col
            v-for="(legend, index) in legendData"
            :key="index"
            :cols="12"
            :lg="6"
            class="px-2 px-md-4 px-lg-6"
          >
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr class="table-header border highlight--text">
                    <th class="px-4 pb-2 col-8 text-left">{{ legend.field }}</th>
                    <th class="px-4 pb-2 col-4 text-center">{{ $t('report.results.importanceRankHeader') }}</th>
                    <th v-if="comparisonData" class="px-4 pb-2 col-4 text-center">
                      {{ $t('report.results.importanceRankComparisonHeader') }}
                    </th>
                  </tr>
                </thead>
                <tr
                  v-for="(item, itemIndex) in legend.items"
                  :key="itemIndex"
                  :class="{ 'font-weight-bold': item.highlight }"
                >
                  <td class="px-4 py-1">{{ item.name }}</td>
                  <td class="px-4 py-1 text-center">{{ item.rank }}</td>
                  <td
                    v-if="comparisonData"
                    class="px-4 py-1 text-center text-body-2"
                    :class="{
                      'font-weight-bold': item.comparisonHighlight
                    }"
                  >
                    {{ item.comparisonRank }}
                  </td>
                </tr>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <div>
          <p>{{ graphLegendNote }}</p>
        </div>
      </v-container>
    </v-col>
    <v-col v-else class="text-center">
      <svg
        ref="graph"
        class="scale--graph"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { createGraph, ScatterGraph } from '@betterboards/graphs'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import { ScatterGraphConfig, ScatterGraphData, ScatterGraphDataPoint } from '@betterboards/graphs/variants/ScatterGraph'
import { getRepeatForCriteriaFriendlyName } from '@/helpers/questionnaire/results'
import { RepeatForOptions } from '@/helpers/questionnaire/questions'
import { mapState } from 'vuex'

function DefaultData () {
  return {
    graph: undefined as undefined | ScatterGraph
  }
}

interface ScatterGraphResult {
  repeatForCriteriaId: string
  repeatForCriteriaValue: string
  label: string
  Importance: number
  Development: number
  opportunityArea: boolean
}

interface LegendItem {
  name: string
  rank: string
  highlight: boolean
  comparisonRank?: string
  comparisonHighlight?: boolean
}

interface LegendTable {
  field: string
  items: LegendItem[]
}

export default Vue.extend({
  name: 'ScatterGraph',
  props: {
    data: {
      type: Array as PropType<ScatterGraphResult[]>,
      required: false
    },
    comparisonData: {
      type: Array as PropType<ScatterGraphResult[] | undefined>,
      required: false
    },
    criteria: {
      type: String as PropType<RepeatForOptions>,
      required: false
    },
    config: {
      type: Object as PropType<Partial<ScatterGraphConfig>>,
      required: false
    }
  },
  data: DefaultData,
  mounted (): void {
    this.renderGraph()
  },
  watch: {
    data: 'renderGraph',
    'user.defaultLanguage': 'renderGraph'
  },
  computed: {
    ...mapState('User', ['user']),
    graphData (): ScatterGraphData {
      if (!this.data) {
        return []
      }
      return this.data.map((row): ScatterGraphDataPoint => ({
        x: row.Importance,
        y: row.Development,
        label: row.label,
        id: row.repeatForCriteriaId,
        value: row.repeatForCriteriaValue,
        opportunityArea: row.opportunityArea
      }))
    },
    showLegend (): boolean {
      return !!this.config.legend
    },
    legendData (): undefined | LegendTable[] {
      if (!this.graphData.some((r) => r.label)) {
        return []
      }
      const keys: LegendTable[] = []
      const field = getRepeatForCriteriaFriendlyName(this.criteria)
      let key: LegendTable = {
        field,
        items: []
      }
      keys.push(key)
      this.graphData.forEach((r: any, index) => {
        const comparisonRow = this.comparisonData?.find((d: ScatterGraphResult | undefined) => d?.repeatForCriteriaId === r.id)
        if (index === Math.ceil(this.graphData.length / 2)) {
          // Split to new table after reaching middle
          key = {
            field,
            items: []
          }
          keys.push(key)
        }
        if (r.label) {
          key.items.push({
            name: r.value,
            rank: r.label,
            highlight: r.opportunityArea,
            comparisonRank: comparisonRow?.label,
            comparisonHighlight: !!comparisonRow?.opportunityArea
          })
        }
      })
      return keys
    },
    graphLegendNote (): string {
      return this.comparisonData ? this.$t('shared.graphs.scatter.note.opportunityAreasComparison') as string : this.$t('shared.graphs.scatter.note.opportunityAreas') as string
    }
  },
  methods: {
    renderGraph (): any {
      if (this.showLegend) {
        // If rendering the legend, we don't render the graph
        return
      }

      const data = [...this.graphData]

      const config: any = {
        ...this.config,
        scale: 1.1,
        margin: {
          right: 36
        },
        legend: false // Handled within this component
      }
      const MinXVal = 2.5
      const MinYVal = 2.5
      if (!config.range) {
        config.range = {
          min: {
            x: MinXVal,
            y: MinYVal
          },
          max: {
            x: 5,
            y: 5
          }
        }
      }
      const minX = data.reduce((xVal, r) => (r.x < xVal ? r.x : xVal), MinXVal)
      const minY = data.reduce((yVal, r) => (r.y < yVal ? r.y : yVal), MinYVal)
      // Get the min of the mins and use it for both axes' mins:
      const minMin = minX < minY ? minX : minY
      config.range.min.x = minMin
      config.range.min.y = minMin

      const graph = this.graph = createGraph<GraphTypes.Scatter>(GraphTypes.Scatter, this.$refs.graph as SVGElement, config)

      graph.render(data)
    }
  }
})
</script>

<style lang="scss" scoped>
.scale--graph {
  max-height: 80vh;
}
</style>
