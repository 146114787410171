<template>
  <v-row class="flex-column flex-nowrap overflow-y-hidden" no-gutters>
    <survey-group-selector-modal
      v-if="selectingQuestionnaireSurveyGroup"
      :survey-groups="selectingSurveyGroupOptions"
      @input="(surveyGroupId) => triggerRedirect(surveyGroupId)"
      @close="selectingQuestionnaireSurveyGroup = false"
    />
    <v-col class="min-height-0 d-flex">
      <platform-infinite-scroll
        :fetching="pendingFetch || pendingRefresh"
        :fetched-all="fetchedAll"
        :activate-distance="300"
        @fetch="fetchPage"
      >
        <v-container>
          <v-card class="mb-8">
            <v-card-text>
              <v-row no-gutters align="end">
                <v-col class="grow">
                  <h1 class="highlight--text mb-2">
                    {{ isFinished ? $t('page.viewSurvey.responsesStatus.completedQuestionnaires') : $t('page.viewSurvey.responsesStatus.liveQuestionnaires') }}
                  </h1>
                  <span>
                    <template v-if="generateReports">
                      {{ $t('page.viewSurvey.responsesStatus.selectQuestionnaireGenerateReport') }}
                    </template>
                    <template v-else>
                      <template v-if="isFinished">
                        {{ $t('page.viewSurvey.responsesStatus.selectFinishedQuestionnaire') }}
                      </template>
                      <template v-else>
                        {{ $t('page.viewSurvey.responsesStatus.selectLiveQuestionnaire') }}
                      </template>
                    </template>
                  </span>
                </v-col>
                <v-col class="shrink">
                  <platform-spinner
                    v-if="pendingRefresh"
                    :size="30"
                  />
                  <platform-icon
                    v-else
                    name="refresh"
                    :size="32"
                    @click="refresh"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-col>
            <v-row
              v-for="(surveyGroup) in list"
              :key="surveyGroup.id"
            >
              <platform-entity-card
                align="start"
                entity-type="surveyGroup"
                hover
                row
                list-members
                :avatar-size="72"
                :entity="surveyGroup"
                :to="getSurveyGroupRoute(surveyGroup)"
                @displayVersions="displaySurveyGroupSelection(surveyGroup)"
              >
                <v-progress-linear :value="pendingArtificialDelay ? 0 : surveyGroup.completion" :height="12" rounded class="mb-4">
                  <span class="grey--text text--lighten-3" style="font-size: 8px;">
                    {{ surveyGroup.displayCompletion }}
                  </span>
                </v-progress-linear>
              </platform-entity-card>
            </v-row>
          </v-col>
        </v-container>
      </platform-infinite-scroll>
    </v-col>
    <v-col class="shrink">
      <v-toolbar :height="60">
        <v-container>
          <v-row justify="end">
            <v-col class="shrink">
              <platform-button
                primary
                @click="$router.push({ name: 'Dashboard' })"
              >
                {{ $t('page.viewSurvey.responsesStatus.back') }}
              </platform-button>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { RawLocation } from 'vue-router'
import { mapGetters } from 'vuex'
import { Company, Survey } from '@/API'
import { PlatformSurvey } from '@/types/Platform'
import { SurveyGroupProgress } from '@/types/SurveyGroup'
import { SystemPage } from '@/store/Analysis'
import { sortCompaniesList } from '@/helpers/company'
import { calculateCompletion } from '@/helpers/survey'
import { SortSurveyGroups } from '@/helpers/surveyGroup'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformInfiniteScroll from '@/components/shared/PlatformInfiniteScroll.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import SurveyGroupSelectorModal from '@/components/questionnaire/releases/SurveyGroupSelectorModal.vue'
import { PlatformCompany } from '@betterboards/shared/types/Company'
import { getPreferredName } from '@betterboards/shared/helpers/entities/Individual/getPreferredName'

// Number of companies to load per "page"
const PageSize = 3

export default Vue.extend({
  components: {
    SurveyGroupSelectorModal,
    PlatformInfiniteScroll,
    PlatformSpinner,
    PlatformEntityCard
  },
  props: {
    isFinished: {
      type: Boolean,
      default: false
    },
    page: {
      type: String as PropType<SystemPage>,
      required: false
    }
  },
  data () {
    return {
      nextCompanyIndex: 0,
      selectingQuestionnaireSurveyGroup: false,
      selectingSurveyGroupOptions: <SurveyGroupProgress[] | undefined>undefined,
      fetchedAll: false,
      pendingFetch: false,
      pendingArtificialDelay: false,
      pendingRefresh: false
    }
  },
  mounted () {
    this.reset()
    this.fetchPage()
    this.pendingArtificialDelay = true
    setTimeout(() => {
      this.pendingArtificialDelay = false
    }, 600)
  },
  computed: {
    ...mapGetters('SurveyGroup', { surveyGroupsList: 'list' }),
    selectedCompany (): PlatformCompany {
      return this.$store.state.Company.selectedCompany
    },
    list (): SurveyGroupProgress[] {
      return this.surveyGroupsList
        .filter((surveyGroup: SurveyGroupProgress) => {
          const completed: boolean = this.isGroupCompleted(surveyGroup.surveys.items)
          if (this.isFinished) {
            return completed
          }
          return !completed
        })
        .sort(SortSurveyGroups)
    },
    generateReports (): boolean {
      return this.page === 'generate-report'
    },
    allCompanies (): any[] {
      return [...this.$store.state.Company.list]
        .filter((c) => !c.deletedAt)
        .sort(sortCompaniesList(this.selectedCompany))
    }
  },
  methods: {
    getPreferredName,
    filter (obj, predicate) {
      return Object
        .keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {})
    },
    isGroupCompleted (surveyGroup: PlatformSurvey[]): boolean {
      return surveyGroup.every((survey) => !!survey.finishedAt)
    },
    reset () {
      this.nextCompanyIndex = 0
      this.fetchedAll = false
      this.$store.commit('SurveyGroup/resetList')
    },
    calculateCompletion (group: Survey[]): number | undefined {
      const totalCompletion = group.reduce((total, survey) => total + (calculateCompletion(survey) ?? 0), 0)

      return totalCompletion / group.length
    },
    getCompletionDisplayVal (group: Survey[]): string | undefined {
      const val = this.calculateCompletion(group)
      if (!val || isNaN(val as any)) {
        return '0%'
      }
      return `${Math.round(val)}%`
    },
    refresh (): void {
      this.pendingRefresh = true
      this.pendingArtificialDelay = true
      this.reset()
      this.fetchPage()
        .finally(() => {
          this.pendingRefresh = false
          this.pendingArtificialDelay = false
        })
    },
    displaySurveyGroupSelection (surveyGroup: SurveyGroupProgress): void {
      if (!surveyGroup.versions) {
        return
      }
      this.selectingSurveyGroupOptions = this.surveyGroupsList.filter((s) => s.questionnaireId === surveyGroup.questionnaireId)
      this.selectingQuestionnaireSurveyGroup = true
    },
    triggerRedirect (surveyGroupId: string): void {
      this.$router.push({
        name: this.page || 'SurveyResponseStatus',
        params: {
          surveyId: surveyGroupId
        }
      })
    },
    async fetchPage (): Promise<void> {
      if (this.pendingFetch) {
        return
      }

      this.pendingFetch = true
      const nextCompanies = this.allCompanies.slice(this.nextCompanyIndex, this.nextCompanyIndex + PageSize)
      const promises = nextCompanies.map(async (company: Company): Promise<void> => {
        try {
          await this.fetchSurveyGroups(company.id)
        } catch (err) {
          console.error('Failed to list SurveyGroups for Company:', company.id, 'error:', err)
        }
      })
      try {
        await Promise.all(promises)
      } finally {
        this.pendingFetch = false
      }

      this.nextCompanyIndex += PageSize
      if (this.nextCompanyIndex >= this.allCompanies.length) {
        this.fetchedAll = true
      }
    },
    async fetchSurveyGroups (companyId: string): Promise<void> {
      if (!companyId) {
        throw new Error('Failed to fetch SurveyGroups for list as no selected Company was found.')
      }
      await this.$store.dispatch('SurveyGroup/listSurveyGroups', {
        companyId,
        append: true
      })
    },
    getSurveyGroupRoute (surveyGroup: SurveyGroupProgress): RawLocation {
      return {
        name: this.page || 'SurveyResponseStatus',
        params: {
          surveyId: surveyGroup.id
        }
      }
    }
  }
})
</script>

<style lang="scss">
.response-status-user .completed {
  border: 4px solid green;
  position: relative;
  overflow: visible;

  &:after {
    content: '✔';
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    text-align: center;
    color: green;
    background: darken(green, 10%);
    border-radius: 50%;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.3);
    z-index: 9999;
  }
}
</style>
