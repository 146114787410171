import { ResultSet, SectionAveragesData, SectionResults, SectionScores } from '../../../types/ResultSet'
import { ReportBenchmark } from '../../../types/ReportBenchmark'
import { StatsResult } from '../../../types/Statistic'
import { $t } from '../../../translations'
import { normalizeAverageObject, normalizeFloat } from '../../util/numbers'
import { getEnabledReportBenchmarks, findSectionStatsResult } from '../../statistics/ReportBenchmark'
import { getScoreCollectionTitle } from './getScoreCollectionTitle'
import { ScoreCollection } from './scores'
import { PlatformReport } from '../../../types/Report'
import { PlatformCompany } from '../../../types/Company'

export type OverviewScore = Pick<StatsResult, 'masterSectionId' | 'key' | 'value'>

export interface OverviewHeaderItem {
  title: string
  subTitle?: string | undefined
  collection?: ScoreCollection
}

export interface OverviewItem {
  name: string
  criteria?: string
  findings?: string
  findingsTitle?: string
  averages?: SectionScores

  scores?: Array<OverviewScore | undefined>
  masterSectionId?: string
  subSectionId?: string
}

export default function buildOverviewItems <S extends SectionResults = SectionResults> (
  sections: S[],
  reportScores: ReportBenchmark[]
): OverviewItem[] {
  const items: OverviewItem[] = []
  const enabledReportBenchmarks: ReportBenchmark[] = getEnabledReportBenchmarks(reportScores)

  sections.forEach((rsSection: S) => {
    const masterSectionId: string | undefined = rsSection.masterSectionId

    if (rsSection.averages) {
      if (rsSection.averages.average) {
        // Create a row for the Section score if there is one
        const row: OverviewItem | undefined = buildOverviewItemRow(
          rsSection.name,
          undefined,
          masterSectionId,
          rsSection.slug,
          undefined,
          rsSection.averages.average,
          enabledReportBenchmarks
        )
        if (row) {
          items.push(row)
        }
      }

      if (rsSection.averages.subSections) {
        // Create a row for each SubSection score if there are any
        rsSection.averages.subSections.forEach((ss: SectionAveragesData) => {
          const row: OverviewItem | undefined = buildOverviewItemRow(
            rsSection.name,
            ss.name,
            masterSectionId,
            rsSection.slug,
            ss.slug,
            ss.average,
            enabledReportBenchmarks
          )
          if (row) {
            items.push(row)
          }
        })
      }
    }

    const opportunityAreasResults = rsSection.results?.filter((r) => Array.isArray(r.data) && r.data.some((d) => !!d.opportunityArea || !!d.highlight))
    if (opportunityAreasResults.length) {
      opportunityAreasResults.forEach((r) => {
        if (rsSection.averages) {
          normalizeAverageObject(rsSection.averages)
        }
        items.push({
          name: rsSection.name,
          findingsTitle: $t('shared.graphs.scatter.legend.opportunityAreasTitle') as string,
          findings: r.data
            .filter((o) => !!o.opportunityArea || !!o.highlight)
            .map((d) => d.repeatForCriteriaValue ?? d.value).join(', '),
          criteria: $t(`shared.blocks.repeatFor.options.${r.criteria as string}`, 2) as string
        })
      })
    }
  })

  return items
}

function buildOverviewItemRow (
  name: string,
  criteria: string | undefined,
  masterSectionId: string | undefined,
  sectionSlug: string | undefined,
  subSectionSlug: string | undefined,
  value: string | number | null,
  reportScores: ReportBenchmark[]
): OverviewItem | undefined {
  const sectionScore: string | undefined = normalizeFloat(value ?? undefined)
  const scoreIdentifier: string | undefined = subSectionSlug ?? sectionSlug ?? masterSectionId
  if (!scoreIdentifier) {
    console.warn('Score seems to have no identifier:', { name, masterSectionId, sectionSlug, subSectionSlug, value })
    return undefined
  }
  if (!sectionScore) {
    return undefined
  }
  const sectionScoreItem: OverviewScore | undefined = {
    value: sectionScore,
    key: scoreIdentifier,
    masterSectionId: masterSectionId
  }
  const scores: Array<OverviewScore | undefined> = [sectionScoreItem]
  scores.push(
    ...reportScores.map((reportScore: ReportBenchmark): OverviewScore | undefined => {
      const sectionScore: StatsResult | undefined = findSectionStatsResult(reportScore.scores, masterSectionId, sectionSlug, subSectionSlug)
      if (sectionScore?.value) {
        const normalizedValue: string | undefined = normalizeFloat(sectionScore.value)
        if (normalizedValue) {
          return {
            ...sectionScore,
            value: normalizedValue
          }
        }
      }
      return sectionScore
    })
  )

  return {
    name,
    criteria,
    scores,
    masterSectionId,
    subSectionId: subSectionSlug
  }
}

export function buildOverviewHeaderItems (
  resultSet: ResultSet,
  reportScores: ReportBenchmark[],
  report: PlatformReport
): OverviewHeaderItem[] {
  const enabledReportBenchmarks: ReportBenchmark[] = getEnabledReportBenchmarks(reportScores)
  const headerItems: OverviewHeaderItem[] = []

  const sectionScoreTitle: string = getScoreCollectionTitle(
    ScoreCollection.Average,
    report,
    resultSet.company as PlatformCompany
  )
  headerItems.push({
    title: sectionScoreTitle
  })

  enabledReportBenchmarks.forEach((score: ReportBenchmark): void => {
    headerItems.push({
      title: score.title,
      subTitle: score.subTitle,
      collection: score.collection
    })
  })

  return headerItems
}
