import {
  CriteriaGroup,
  CriteriaItem
} from '../../../types/Content'

export default function addCriteriaItem (
  criteriaItems: CriteriaItem[],
  criteriaGroupSlug: string,
  newCriteriaGroupItems: CriteriaGroup[]
): CriteriaGroup[] {
  let groupIndex: number = newCriteriaGroupItems.findIndex(
    (cg: CriteriaGroup): boolean => cg.slug === criteriaGroupSlug
  )
  if (groupIndex === -1) {
    groupIndex = newCriteriaGroupItems.push({
      slug: criteriaGroupSlug,
      items: []
    }) - 1
  }
  criteriaItems.forEach((criteriaItem: CriteriaItem): void => {
    const itemIndex: number = newCriteriaGroupItems[groupIndex].items.findIndex(
      (ci: CriteriaItem) => ci.slug === criteriaItem.slug && ci.variantCode === criteriaItem.variantCode
    )
    if (itemIndex === -1) {
      newCriteriaGroupItems[groupIndex].items.push(criteriaItem)
    }
  })

  return newCriteriaGroupItems
}
