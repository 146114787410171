<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 283.46 283.46" style="enable-background:new 0 0 283.46 283.46;" xml:space="preserve">
      <g>
        <path class="st0" d="M141.72,42.22c-7.44,0-13.5,6.06-13.5,13.5s6.06,13.5,13.5,13.5s13.5-6.06,13.5-13.5
          S149.16,42.22,141.72,42.22z M141.72,64.1c-4.62,0-8.38-3.76-8.38-8.38s3.76-8.38,8.38-8.38c4.62,0,8.38,3.76,8.38,8.38
          S146.34,64.1,141.72,64.1z"
              fill="currentColor"
        />
        <path class="st0" d="M170.14,55.72c0-15.67-12.75-28.42-28.42-28.42S113.3,40.05,113.3,55.72s12.75,28.42,28.42,28.42
          S170.14,71.39,170.14,55.72z M141.72,79.02c-12.85,0-23.3-10.45-23.3-23.3s10.45-23.3,23.3-23.3s23.3,10.45,23.3,23.3
          S154.56,79.02,141.72,79.02z"
              fill="currentColor"
        />
        <path class="st0" d="M143.99,92.16c9.76-0.37,20.12-4.02,30.91-10.86l4.27-2.86c1.66-1.17,3.33-2.41,5.02-3.73
          c10.88-8.54,17.95-16.96,18.24-17.31c0.67-0.8,0.77-1.87,0.37-2.76c0.33-0.86,0.19-1.87-0.46-2.61c-0.3-0.34-7.36-8.38-18.24-16.52
          c-14.77-11.06-28.83-16.67-41.78-16.67c-12.94,0-27.19,5.6-42.36,16.65C88.79,43.63,81.43,51.66,81.12,52
          c-0.68,0.74-0.83,1.77-0.49,2.65c-0.41,0.91-0.29,2,0.41,2.8c0.31,0.35,7.66,8.76,18.84,17.29c1.87,1.43,3.73,2.77,5.57,4.02
          l4.31,2.79c10.22,6.28,19.96,9.8,29.1,10.51L143.99,92.16z M85.62,54.69c2.06-2.15,8.52-8.63,17.36-15.07
          c9.81-7.15,24.6-15.67,39.34-15.67c14.69,0,29.11,8.48,38.63,15.59c8.66,6.48,14.94,13.03,16.89,15.15
          c-4.05,4.61-29.47,32.27-55.52,32.27c-14.67,0-29.4-8.88-39.18-16.33C94.16,63.78,87.59,56.85,85.62,54.69z"
              fill="currentColor"
        />
      </g>
    <path class="st0" d="M141.73,0C63.58,0,0,63.58,0,141.73s63.58,141.73,141.73,141.73s141.73-63.58,141.73-141.73S219.88,0,141.73,0z
        M141.73,278.16C66.5,278.16,5.3,216.96,5.3,141.73C5.3,66.51,66.51,5.3,141.73,5.3s136.43,61.2,136.43,136.43
        C278.16,216.96,216.96,278.16,141.73,278.16z"
          fill="currentColor"
    />
    <g>
        <path class="st0" d="M82.12,250.57c0,1.38,1.12,2.5,2.5,2.5h81.72c0.08,0,0.17,0,0.25-0.01c0.06-0.01,0.12-0.02,0.17-0.03
          c0.02,0,0.05-0.01,0.07-0.01c0.07-0.01,0.14-0.03,0.2-0.05c0.01,0,0.02-0.01,0.03-0.01c0.07-0.02,0.13-0.05,0.2-0.07
          c0.01,0,0.02-0.01,0.03-0.01c0.06-0.03,0.12-0.05,0.18-0.08c0.01-0.01,0.03-0.01,0.04-0.02c0.05-0.03,0.1-0.06,0.15-0.09
          c0.02-0.01,0.04-0.02,0.06-0.04c0.04-0.03,0.09-0.06,0.13-0.09c0.02-0.02,0.05-0.03,0.07-0.05c0.04-0.03,0.08-0.07,0.12-0.11
          c0.02-0.02,0.04-0.03,0.06-0.05l32.51-32.48c0.47-0.47,0.73-1.1,0.73-1.77V101.2c0-1.38-1.12-2.5-2.5-2.5h-8.65h-4.99H84.62
          c-1.38,0-2.5,1.12-2.5,2.5v136.2V250.57z M87.12,103.69h109.23v111.87h-30.01c-1.38,0-2.5,1.12-2.5,2.5v30.01H87.12V103.69z
           M192.84,220.56l-24.01,23.99v-23.99H192.84z"
              fill="currentColor"
        />
      <path
        class="st0"
        d="M108.26,129.81h66.95c1.38,0,2.5-1.12,2.5-2.5s-1.12-2.5-2.5-2.5h-66.95c-1.38,0-2.5,1.12-2.5,2.5
          C105.76,128.69,106.88,129.81,108.26,129.81z"
        fill="currentColor"
      />
      <path
        class="st0"
        d="M108.26,147.87h66.95c1.38,0,2.5-1.12,2.5-2.5s-1.12-2.5-2.5-2.5h-66.95c-1.38,0-2.5,1.12-2.5,2.5
            S106.88,147.87,108.26,147.87z"
        fill="currentColor"
      />
      <path
        class="st0"
        d="M123.74,183.03v-14.45c0-1.38-1.12-2.5-2.5-2.5h-19.11c-1.38,0-2.5,1.12-2.5,2.5v14.45
            c0,1.38,1.12,2.5,2.5,2.5h19.11C122.62,185.52,123.74,184.4,123.74,183.03z M118.74,180.53h-14.12v-9.45h14.12V180.53z"
        fill="currentColor"
      />
      <path
        class="st0"
        d="M132.18,166.08c-1.38,0-2.5,1.12-2.5,2.5v14.45c0,1.38,1.12,2.5,2.5,2.5h19.11c1.38,0,2.5-1.12,2.5-2.5v-14.45
            c0-1.38-1.12-2.5-2.5-2.5H132.18z M148.79,180.53h-14.12v-9.45h14.12V180.53z"
        fill="currentColor"
      />
      <path
        class="st0"
        d="M159.73,168.58v14.45c0,1.38,1.12,2.5,2.5,2.5h19.11c1.38,0,2.5-1.12,2.5-2.5v-14.45c0-1.38-1.12-2.5-2.5-2.5
            h-19.11C160.85,166.08,159.73,167.2,159.73,168.58z M164.72,171.08h14.12v9.45h-14.12V171.08z"
        fill="currentColor"
      />
      <path
        class="st0"
        d="M134.39,193.22h-19.11c-1.38,0-2.5,1.12-2.5,2.5v14.45c0,1.38,1.12,2.5,2.5,2.5h19.11c1.38,0,2.5-1.12,2.5-2.5
            v-14.45C136.89,194.34,135.77,193.22,134.39,193.22z M131.89,207.66h-14.12v-9.45h14.12V207.66z"
        fill="currentColor"
      />
      <path
        class="st0"
        d="M164.44,212.66c1.38,0,2.5-1.12,2.5-2.5v-14.45c0-1.38-1.12-2.5-2.5-2.5h-19.11c-1.38,0-2.5,1.12-2.5,2.5
             v14.45c0,1.38,1.12,2.5,2.5,2.5H164.44z M147.83,198.21h14.12v9.45h-14.12V198.21z"
        fill="currentColor"
      />
      </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SampleQuestionnaireIcon'
})
</script>
