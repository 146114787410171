<template>
  <v-container class="grow px-0">
    <v-row no-gutters class="fill-height flex-column">
      <v-col class="shrink">
        <div>
          <v-progress-linear
            color="primary"
            class="analysis-progress"
            rounded
            style="position: relative;"
            :height="20"
            :value="analysisCompletionProgress"
            :style="{
              'border-bottom-left-radius': 0,
              'border-bottom-right-radius': 0,
            }"
          >
            <div
              v-tooltip:top="completionProgressBarTooltip"
              class="grey--text text-center"
              style="position: absolute; top: 0; left: 0; line-height: 20px; transition: width 500ms; font-size: 13px;"
              :style="{
                width: `${analysisCompletionProgress > 15 ? analysisCompletionProgress : 100}%`
              }"
              :class="{
                'text--lighten-3': analysisCompletionProgress > 15,
                'text--darken-2': analysisCompletionProgress <= 15
              }"
            >
              <span>
                <strong>{{ analysisCompletionProgress }}%</strong>
                {{ $t('analysis.progress.analysedProgress') }}
              </span>
            </div>
          </v-progress-linear>
          <v-progress-linear
            rounded
            color="secondary"
            class="analysis-progress"
            style="border-top-left-radius: 0; border-top-right-radius: 0; position: relative;"
            :height="16"
            :value="analysisReviewedProgress"
          >
            <div
              v-tooltip:top="reviewProgressBarTooltip"
              class="grey--text text-center"
              style="position: absolute; top: 0; left: 0; line-height: 16px; transition: width 500ms; font-size: 12px;"
              :style="{
                width: `${analysisReviewedProgress > 15 ? analysisReviewedProgress : 100}%`
              }"
              :class="{
                'text--lighten-3': analysisReviewedProgress > 15,
                'text--darken-2': analysisReviewedProgress <= 15
              }"
            >
              <span>
                <strong>{{ analysisReviewedProgress }}%</strong>
                {{ $t('analysis.progress.reviewedProgress') }}
              </span>
            </div>
          </v-progress-linear>
        </div>
      </v-col>
      <v-col class="shrink py-4 px-5 grey--text text--darken-1">
        <v-row no-gutters justify="space-between" align="center">
          <v-col class="d-flex align-center justify-center">
            <platform-icon
              name="no-progress-pencil"
              class="reviewed--icon mr-2"
              :size="32"
            />
            <span class="grey--text text--darken-3">
              {{ $t('analysis.progress.legend.incomplete') }}
            </span>
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <platform-icon
              key="check-circle"
              color="primary"
              class="reviewed--icon mr-2"
              name="progress"
              :size="32"
            />
            <span class="grey--text text--darken-4">
              {{ $t('analysis.progress.legend.inProgress') }}
            </span>
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <platform-icon
              key="check-circle"
              color="secondary"
              class="reviewed--icon mr-2"
              name="check-outline"
              :size="32"
            />
            <span class="grey--text text--darken-4 font-weight-bold">
              {{ $t('analysis.progress.legend.signedOff') }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="shrink pb-5">
        <v-card
          v-for="(item, index) in reportItems"
          :key="index"
          :rounded="false"
          class="px-4 py-2 mb-3"
        >
          <v-row no-gutters align="center">
            <platform-icon
              key="check-circle"
              class="reviewed--icon mr-2"
              :color="item.reviewed ? 'secondary' : 'text--darken-2 darken-2 grey'"
              :name="item.reviewed ? 'check-outline' : 'no-progress-pencil'"
              :size="32"
            />
            <v-col :class="{
              'grey--text text--darken-3': !item.reviewed,
              'grey--text text--darken-4 font-weight-bold': item.reviewed,
            }">
              {{ $t(`nav.analysis.${item.slug}`) }}
            </v-col>
            <v-col v-if="item.content" class="shrink">
            <span
              class="text-caption text-no-wrap text--grey text--darken-3"
              v-tooltip:top="item.contentTooltip"
            >
              {{ item.content }}
            </span>
            </v-col>
            <v-col class="shrink">
              <router-link
                :to="{
                  name: item.route,
                  params: {
                    surveyId: $route.params.surveyId,
                  }
                }"
              >
                <platform-icon
                  v-tooltip:top="$t('analysis.progress.pageLinkTooltip')"
                  name="link"
                  class="link--icon cursor-pointer ml-2"
                />
              </router-link>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col class="shrink pb-2">
        <h2 class="primary-header">
          {{ $t('analysis.progress.sectionsHeader') }}
        </h2>
      </v-col>
      <v-col>
        <v-expansion-panels multiple class="flex-column flex-nowrap">
          <v-expansion-panel v-for="section in resultSetSections" :key="section.id" ref="section" class="mb-3 rounded">
            <v-expansion-panel-header class="pl-4 py-2 align-center">
              <div class="shrink d-flex mr-2" style="width: 32px; height: 32px;">
                <v-fade-transition group style="position: relative;">
                  <platform-spinner
                    v-if="pendingUpdateSections.includes(section.id)"
                    key="spinner"
                    style="position: absolute; top: 0; left: 0;"
                    :size="21"
                  />
                  <platform-icon
                    v-else
                    key="check-circle"
                    class="reviewed--icon"
                    :color="section.reviewed ? 'secondary' : (section.completed ? 'primary' : 'text--darken-2 darken-2 grey')"
                    :name="section.reviewed ? 'check-outline' : (section.completed ? 'progress' : 'no-progress-pencil')"
                    v-tooltip:top="getSectionTooltip(section)"
                    style="position: absolute; top: 0; left: 0;"
                    :size="32"
                  />
                </v-fade-transition>
              </div>
              <div>
                <span
                  class="text-body-1"
                  :class="{ 'grey--text text--darken-3': !section.reviewed, 'grey--text text--darken-4 font-weight-bold': section.reviewed }"
                >
                  {{ section.name }}
                </span>
              </div>
              <div class="shrink pr-2">
                <router-link
                  :to="{
                    name: 'AnalysisSection',
                    params: {
                      surveyId: $route.params.surveyId,
                      sectionId: section.id
                    }
                  }"
                >
                  <platform-icon
                    v-tooltip:top="$t('analysis.progress.sectionLinkTooltip')"
                    name="link"
                    class="link--icon cursor-pointer"
                  />
                </router-link>
              </div>
              <template #actions>
                <platform-icon
                  name="caret-down"
                  color="secondary"
                  :size="24"
                />
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="(group, gIndex) in section.reviewItems"
                :key="gIndex"
              >
                <h3 v-if="group.groupName" class="highlight--text mt-5 mb-2">
                  {{ group.groupName }}
                </h3>
                <v-row class="py-0 my-0">
                  <v-col
                    class="my-0 py-0 d-flex"
                    v-for="(item, index) in group.items"
                    :key="index"
                    :cols="12"
                    :sm="group.compact ? 6 : 12"
                    :md="group.compact ? 4 : 12"
                    :lg="group.compact ? 3 : 12"
                  >
                    <v-card
                      class="px-3 my-3 py-2 grow d-flex"
                    >
                      <v-row no-gutters :align="group.compact && item.avatarId ? 'start' : 'center'">
                        <v-col
                          :order="1"
                          class="shrink"
                          :class="{
                            'pr-2': !group.compact
                          }"
                        >
                          <platform-icon
                            key="check-circle"
                            class="reviewed--icon"
                            :color="!!item.reviewedAt ? 'secondary' : (item.completed ? 'primary' : 'text--darken-2 darken-2 grey')"
                            v-tooltip="$t(!!item.reviewedAt ? 'analysis.progress.completeResultTooltip' : item.completed ? 'analysis.progress.incompleteResultTooltip' : 'analysis.progress.unstartedResultTooltip')"
                            :name="!!item.reviewedAt ? 'check-outline' : (item.completed ? 'progress' : 'no-progress-pencil')"
                            :size="32"
                            :class="{
                              progress: !item.reviewedAt && !!item.completed,
                              reviewed: !!item.reviewedAt,
                            }"
                          />
                        </v-col>
                        <v-col
                          :order="item.avatarId ? (group.compact ? 4 : 2) : 2"
                          :cols="group.compact && item.avatarId ? 12 : undefined"
                          :class="{
                            'text-center overflow-ellipses': group.compact
                          }"
                        >
                          <span
                            class="grey--text text--darken-4 px-2 d-block"
                            :class="{
                              'text-body-2': group.compact
                            }"
                            v-tooltip:bottom="item.titleTooltip"
                          >
                            {{ item.title }}
                          </span>
                        </v-col>
                        <v-col
                          :order="group.compact ? 2 : 3"
                          :class="{
                            'text-no-wrap': $vuetify.breakpoint.smAndUp,
                            'text-center grow': group.compact,
                            'shrink': !group.compact || !item.avatarId,
                            'pl-2': !group.compact,
                          }"
                        >
                          <span
                            v-if="item.content"
                            class="text-caption text-no-wrap text--grey text--darken-2"
                            v-tooltip:top="item.contentTooltip"
                          >
                            <platform-icon
                              name="text-box-check"
                              :color="item.completed ? 'secondary' : 'grey darken-1'"
                              :size="24"
                            />
                            {{ item.content }}
                          </span>
                          <span
                            v-else-if="item.maxKeyFindings"
                            class="text-caption text-no-wrap text--grey text--darken-2"
                            v-tooltip:top="item.keyFindingsTooltip"
                          >
                            <platform-icon
                              name="text-box-check"
                              :color="item.summary ? 'secondary' : 'grey darken-1'"
                              :size="24"
                            />
                            <platform-icon
                              v-for="(keyFinding, index) in item.maxKeyFindings"
                              :key="index"
                              name="circle"
                              :color="item.keyFindings > index ? 'secondary' : 'grey darken-1'"
                              :size="12"
                            />
                          </span>
                          <platform-avatar
                            v-if="item.avatarId && selectedCompany && selectedCompany.id"
                            class="rounded ml-2"
                            internal-loader
                            small-loader
                            entity-type="individual"
                            :entity="{ id: item.avatarId, companyId: selectedCompany.id }"
                            :size="48"
                          />
                        </v-col>
                        <v-col
                          v-if="item.link"
                          :order="group.compact ? 3 : 4"
                          class="shrink pl-2"
                        >
                          <router-link
                            :to="{
                              name: 'AnalysisSection',
                              params: {
                                surveyId: $route.params.surveyId,
                                sectionId: item.link.sectionId,
                                tab: item.link.tab,
                                resultId: item.link.resultId,
                                criteriaId: item.link.criteriaId,
                              }
                            }"
                          >
                            <platform-icon
                              v-tooltip:top="$t('analysis.progress.resultLinkTooltip')"
                              name="link"
                              class="link--icon cursor-pointer"
                            />
                          </router-link>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Company } from '@/API'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { MaxFieldLengths } from '@/helpers/validation'
import { SystemPage, SystemPageConfig, SystemPages } from '@/store/Analysis'
import { QuestionnaireVariantCode } from '@/types/Platform'
import { PlatformReport } from '@/types/Report'
import Vue, { PropType } from 'vue'
import { ProgressSection, ResultSet } from '@betterboards/shared/types/ResultSet'

interface SystemPageProgress extends SystemPageConfig {
  content?: string
  contentTooltip?: string
  completed?: boolean
  reviewed?: boolean
}

export default Vue.extend({
  name: 'AnalysisProgress',
  components: {
    PlatformAvatar,
    PlatformSpinner
  },
  props: {
    results: { type: Object as PropType<ResultSet>, required: true },
    report: { type: Object as PropType<PlatformReport>, required: true },
    selectedVariant: { type: String as PropType<QuestionnaireVariantCode | undefined>, required: false }
  },
  data: () => ({
    pendingUpdateSections: <string[]>[]
  }),
  mounted (): void {
    if (this.$route.params.targetSectionId) {
      this.openSectionPanel(this.$route.params.targetSectionId)
    }
  },
  computed: {
    resultSetSections (): ProgressSection[] {
      return this.$store.getters['Report/resultSetSections']
        .filter((section: ProgressSection) => !!section.reviewItems.length)
        .map((section: ProgressSection) => {
          let sectionReviewItems = 0
          let sectionCompletedItems = 0
          section.reviewItems?.forEach((group) => {
            if (group.items?.length) {
              sectionReviewItems += group.items?.length
              sectionCompletedItems += group.items?.filter((item) => item.reviewedAt)?.length
            }
          })
          if (sectionReviewItems === sectionCompletedItems) {
            section.completed = true
          } else {
            section.completed = false
          }
          return section
        })
    },
    analysisReviewedProgress (): number {
      let totalReviewItems = 0
      let completedItems = 0
      this.reportItems.forEach((i: SystemPageProgress) => {
        totalReviewItems += 1
        if (i.reviewed) {
          completedItems += 1
        }
      })
      this.resultSetSections.forEach((s: ProgressSection) => {
        s.reviewItems?.forEach((group) => {
          if (group.items?.length) {
            totalReviewItems += group.items.length
            completedItems += group.items.filter((r) => !!r.reviewedAt).length
          }
        })
      })
      if (!completedItems) {
        return 0
      }
      const completion = completedItems / totalReviewItems
      return Math.round(completion * 100)
    },
    analysisCompletionProgress (): number {
      let totalReviewItems = 0
      let completedItems = 0
      this.reportItems.forEach((i: SystemPageProgress) => {
        totalReviewItems += 1
        if (i.reviewed) {
          completedItems += 1
        }
      })
      this.resultSetSections.forEach((s: ProgressSection) => {
        s.reviewItems?.forEach((group) => {
          if (group.items?.length) {
            totalReviewItems += group.items?.length
            completedItems += group.items?.filter((i) => i.completed)?.length
          }
        })
      })
      if (!completedItems) {
        return 0
      }
      const completion = completedItems / totalReviewItems
      return Math.round(completion * 100)
    },
    reviewProgressBarTooltip (): string {
      if (this.analysisCompletionProgress > 100) {
        return this.$tc('analysis.progress.reviewProgressBarTooltipComplete', this.analysisReviewedProgress, [this.analysisReviewedProgress]) as string
      }
      if (this.analysisCompletionProgress > 0) {
        return this.$tc('analysis.progress.reviewProgressBarTooltipIncomplete', this.analysisReviewedProgress, [this.analysisReviewedProgress]) as string
      }
      return this.$tc('analysis.progress.reviewProgressBarTooltipUnstarted', this.analysisReviewedProgress, [this.analysisReviewedProgress]) as string
    },
    completionProgressBarTooltip (): string {
      if (this.analysisCompletionProgress > 100) {
        return this.$tc('analysis.progress.completionProgressBarTooltipComplete', this.analysisCompletionProgress, [this.analysisCompletionProgress]) as string
      }
      if (this.analysisCompletionProgress > 0) {
        return this.$tc('analysis.progress.completionProgressBarTooltipIncomplete', this.analysisCompletionProgress, [this.analysisCompletionProgress]) as string
      }
      return this.$tc('analysis.progress.completionProgressBarTooltipUnstarted', this.analysisCompletionProgress, [this.analysisCompletionProgress]) as string
    },
    reportItems (): SystemPageProgress[] {
      return Object.values(SystemPages)
        .filter(
          (page) => [SystemPage.EvaluationInformation, SystemPage.FocusAreas].includes(page.slug)
        )
        .map((page) => {
          let content: string | undefined
          let contentTooltip: string | undefined
          let reviewed: boolean = false
          switch (page.slug) {
            case SystemPage.EvaluationInformation:
              content = this.$t('analysis.progress.fieldCharacterCount', [this.report.summary?.length ?? 0, MaxFieldLengths.EvaluationInformation]) as string
              contentTooltip = this.$tc('analysis.progress.fieldCharacterCountTooltip', this.report.summary?.length, [this.report.summary?.length ?? 0, MaxFieldLengths.EvaluationInformation]) as string
              reviewed = !!this.report?.summary
              break
            case SystemPage.FocusAreas:
              content = this.$t('analysis.progress.fieldCharacterCount', [this.report.focusAreas?.length ?? 0, MaxFieldLengths.FocusAreas]) as string
              contentTooltip = this.$tc('analysis.progress.fieldCharacterCountTooltip', this.report.focusAreas?.length, [this.report.focusAreas?.length ?? 0, MaxFieldLengths.FocusAreas]) as string
              reviewed = !!this.report?.focusAreas
              break
          }
          return {
            ...page,
            completed: !!content,
            content,
            contentTooltip,
            reviewed
          }
        })
    },
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    }
  },
  methods: {
    getSectionTooltip (section) {
      if (section.completed) {
        return this.$t('analysis.progress.completeResultSectionTooltip')
      }
      return this.$t('analysis.progress.incompleteResultSectionTooltip')
    },
    openSectionPanel (sectionId: string): void {
      const index = this.resultSetSections.findIndex((s) => s.id === sectionId)
      if (index === -1) {
        return
      }
      const sectionPanel: any = this.$refs.section?.[index]
      if (!sectionPanel) {
        return
      }
      sectionPanel.toggle()
      setTimeout(() => {
        sectionPanel.$el.scrollIntoView({
          block: 'start'
        })
      }, 500)
    }
  }
})
</script>

<style lang="scss" scoped>
.analysis-progress {
  transition: width 750ms, left 750ms, opacity 750ms ease-in;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.link--icon {
  ::v-deep i {
    transition: color 500ms;
    color: var(--v-grey-darken1) !important;
  }
  &:hover {
    ::v-deep i {
      color: var(--v-grey-darken4) !important;
    }
  }
}
</style>
