var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-avatar',{staticClass:"avatar",class:_vm.avatarClasses,staticStyle:{"position":"relative"},style:({
    'border-color': _vm.border ? `var(--v-${_vm.border}-base) !important` : undefined,
  }),attrs:{"size":_vm.size}},[(!_vm.failed && !_vm.loaded)?_c('platform-spinner',{style:({
      position: 'absolute',
      top: _vm.internalLoader ? `calc(50% - ${_vm.spinnerSize / 2}px)` : `-${_vm.spinnerWidth / 2}px`,
      left: _vm.internalLoader ? `calc(50% - ${_vm.spinnerSize / 2}px)` : `-${_vm.spinnerWidth / 2}px`,
    }),attrs:{"width":_vm.spinnerWidth,"size":_vm.spinnerSize}}):_vm._e(),(_vm.objectId && _vm.objectType)?_c('file-viewer',{ref:"image",attrs:{"object-id":_vm.objectId,"object-type":_vm.objectType,"company-id":_vm.companyId,"placeholder":_vm.placeholder,"placeholder-size":_vm.placeholderSize,"title":_vm.title,"listen":_vm.listen},on:{"loaded":function($event){_vm.loaded = true},"failed":function($event){_vm.failed = true}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }