import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getSurveysByQuestionnaireId = /* GraphQL */ `
  query getSurveysByQuestionnaireId(
    $questionnaireId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveysByQuestionnaireId(
      questionnaireId: $questionnaireId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        finishedAt
        dispatchedAt
        totals {
          questions
          questionsCompleted
        }
        status
        invitation
        createdAt
        updatedAt
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.SurveysByQuestionnaireIdQueryVariables,
  APITypes.SurveysByQuestionnaireIdQuery
>
