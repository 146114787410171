import * as d3 from 'd3'
import { GraphData } from '../types/Graph'

export const Colors = {
  primary: '#24235f',
  secondary: '#e44500',
  grey: {
    lightest: '#f2f3f2',
    lighter: '#ebeceb',
    light: '#c7c6c7',
    medium: '#727072',
    dark: '#323032'
  }
}

export interface GraphConfig {
  colors: {
    primary: string
    secondary: string
    primaryAlternate: string
    secondaryAlternate: string
    primaryAlternateTransparent: string
    secondaryAlternateTransparent: string
    grey: {
      lightest: string
      lighter: string
      light: string
      medium: string
      dark: string
    }
  }
  xScale: number // Resolution X scale
  yScale: number // Resolution Y scale
  scale: number // Content scale
  width: number
  height: number
  margin?: {
    top?: number
    left?: number
    right?: number
    bottom?: number
  }
  compact?: boolean // used by report
  comparison?: boolean
}

const GlobalDefaultConfig = {
  colors: Colors,
  scale: 1
}

export interface GraphInterface<DataType extends GraphData> {
  render: (data: DataType) => void
  getDefaultConfig: () => Partial<GraphConfig>
}

export abstract class AbstractGraph<Config extends GraphConfig> implements GraphInterface<any> {
  selector: SVGElement
  configOverrides: Partial<Config>
  config: Config

  constructor (selector: SVGElement, configOverrides: Partial<Config> = {}) {
    this.selector = selector
    this.configOverrides = configOverrides
    const graphVariantDefaultConfig = this.getDefaultConfig()
    this.config = {
      ...GlobalDefaultConfig,
      ...graphVariantDefaultConfig,
      ...configOverrides,
      colors: {
        ...GlobalDefaultConfig.colors,
        ...graphVariantDefaultConfig.colors,
        ...configOverrides.colors
      },
      margin: {
        ...graphVariantDefaultConfig.margin,
        ...configOverrides.margin
      }
    } as any
  }

  getDefaultConfig (): Partial<Config> {
    return {}
  }

  render (data: GraphData): void {
    if (!this.config.width || !this.config.height) {
      return
    }
    const svg = d3
      .select(this.selector)
      // .attr('width', this.config.width)
      // .attr('height', this.config.height)
    svg.attr('viewBox', `0 0 ${this.config.width} ${this.config.height}`)
  }

  toHTML (): string {
    return d3.select(this.selector).html()
  }
}
