<template>
  <component
    v-if="blockTypeComponent"
    :is="blockTypeComponent"
    v-model="answer"
    :block="block"
    :is-preview="isPreview"
    @next="$emit('next')"
    @valid="(val) => $emit('valid', val)"
  />
</template>

<script lang="ts">
import QuestionBlock from '@/components/questionnaire/survey/blocks/variants/QuestionBlock.vue'
import MarkdownBlock from '@/components/questionnaire/survey/blocks/variants/MarkdownBlock.vue'
import { BlockTypes } from '@/helpers/questionnaire/blocks'
import Vue, { Component } from 'vue'

export default Vue.extend({
  name: 'SurveyBlock',
  props: {
    block: { type: Object, required: true },
    value: { type: [Object, Array, String, Number], required: false }, // answer
    isPreview: { type: Boolean, default: false }
  },
  computed: {
    blockVariant (): BlockTypes {
      return this.block.variant
    },
    blockTypeComponent (): Component | null {
      switch (this.block.variant) {
        case BlockTypes.Question:
          return QuestionBlock
        case BlockTypes.Markdown:
          return MarkdownBlock
        default:
          throw new Error(`Block component received block of unknown type "${this.block.variant}".`)
      }
    },
    answer: {
      get (): any {
        if (this.block.variant !== BlockTypes.Question) {
          return undefined
        }
        return this.value
      },
      set (val: any): void {
        if (this.block.variant !== BlockTypes.Question) {
          return
        }
        this.$emit('input', val)
      }
    }
  },
  methods: {
    //
  }
})
</script>
