import { BaseReportBenchmark, PartialReportBenchmark, ReportBenchmark, ReportBenchmarkCondition, V1ReportBenchmarkCondition, V2ReportBenchmarkCondition, V1UniverseReportBenchmark, V2UniverseReportBenchmark, V1OrganisationReportBenchmark, V2OrganisationReportBenchmark, V1ComparisonReportBenchmark, V1ReportBenchmark, V2ReportBenchmark } from '../../types/ReportBenchmark'
import { BaseStatsResult, StatsResult, V2StatisticsQuery } from '../../types/Statistic'
import { ResultSection, SectionAveragesData, SectionResults } from '../../types/ResultSet'
import sortByDisplayOrder from '../util/sortByDisplayOrder'
import { ScoreCollection } from '../entities/Result/scores'
import { UniverseQuery, UniverseResult } from '../../types/Report'

export function findReportBenchmarkIndex <
  S extends BaseReportBenchmark | BaseReportBenchmark = ReportBenchmark | ReportBenchmark,
  T extends V2ReportBenchmarkCondition | ReportBenchmarkCondition = V2ReportBenchmarkCondition
> (
  scores: S[],
  targetScore: T
): number {
  return scores.findIndex(
    (s: S): boolean => {
      if (targetScore.id) {
        return s.id === targetScore.id
      }
      if (s.collection !== targetScore.collection) {
        return false
      }
      if (s.version !== targetScore.version) {
        return false
      }
      if (targetScore.companyId && s.companyId !== targetScore.companyId) {
        return false
      }
      if (!targetScore.queries?.length) {
        return !s.queries?.length
      }

      if (!s.queries?.length) {
        return false
      }

      // Check all queries match
      if (isV2ReportBenchmark(targetScore) && isV2ReportBenchmark(s)) {
        return targetScore.queries.every((query: V2StatisticsQuery): boolean => {
          return !!s.queries?.some(
            (q: V2StatisticsQuery) => q.field === query.field && q.value === query.value
          )
        })
      }

      if (isV1ReportBenchmark(targetScore) && isV1ReportBenchmark(s)) {
        return targetScore.queries.every((query: UniverseQuery): boolean => {
          return !!s.queries?.some(
            (q: UniverseQuery) => q.collection === query.collection && q.category === query.category
          )
        })
      }

      return false
    }
  )
}

export function findReportBenchmark <
  S extends BaseReportBenchmark | BaseReportBenchmark = ReportBenchmark | ReportBenchmark,
  T extends V2ReportBenchmarkCondition | ReportBenchmarkCondition = V2ReportBenchmarkCondition
> (
  scores: S[],
  targetScore: T
): S | undefined {
  const scoreIndex: number = findReportBenchmarkIndex<S, T>(scores, targetScore)
  if (scoreIndex === -1) {
    return undefined
  }
  return scores[scoreIndex]
}

/**
 * Uses the provided Sections array to set names of each Section/SubSection Score within the provided ReportBenchmark.
 */
export function normalizeReportBenchmark (reportScore: ReportBenchmark, sections: ResultSection[]): ReportBenchmark {
  reportScore.scores = reportScore.scores?.map((score: StatsResult): StatsResult => {
    if (sections) {
      const targetSectionIndex: number = sections.findIndex((s) => s.masterSectionId === score.masterSectionId)
      const targetSection: SectionResults | undefined = targetSectionIndex === -1
        ? undefined
        : sections[targetSectionIndex]
      score.subSections?.forEach((subSectionScore): void => {
        if (!subSectionScore.name) {
          const targetSubSection: SectionAveragesData | undefined = targetSection?.averages?.subSections?.find(
            (ss) => ss.slug === subSectionScore.key
          )
          if (targetSubSection?.name) {
            subSectionScore.name = targetSubSection.name
          }
        }
      })
      if (!score.name) {
        console.debug('targetSection:', score.masterSectionId, targetSection)
        if (targetSection?.name) {
          score.name = targetSection.name
          score.displayOrder = targetSection.displayOrder ?? targetSectionIndex
        }
      }
    }
    return score
  })
    .sort(sortByDisplayOrder)

  return reportScore
}

export function getEnabledReportBenchmarks (benchmarks: ReportBenchmark[]): ReportBenchmark[] {
  return benchmarks.filter(
    (b: ReportBenchmark): boolean => !!b.enabled && !!b.scores.length
  )
}

export function isV1ReportBenchmark (benchmark: BaseReportBenchmark | PartialReportBenchmark | ReportBenchmark | V1ReportBenchmarkCondition): benchmark is V1ReportBenchmark {
  return benchmark.version === '1'
}

export function isV2ReportBenchmark (benchmark: BaseReportBenchmark | PartialReportBenchmark | ReportBenchmark | V2ReportBenchmarkCondition): benchmark is V2ReportBenchmark {
  return benchmark.version === '2'
}

export function isV1ComparisonReportBenchmark (score: BaseReportBenchmark | PartialReportBenchmark | ReportBenchmark | V1ReportBenchmarkCondition): score is V1ComparisonReportBenchmark {
  return score.collection === ScoreCollection.ComparisonAverage && score.version === '1'
}

export function isV1UniverseReportBenchmark (score: BaseReportBenchmark | PartialReportBenchmark | ReportBenchmark | V1ReportBenchmarkCondition): score is V1UniverseReportBenchmark {
  return score.collection === ScoreCollection.UniverseAverage && score.version === '1'
}

export function isV2UniverseReportBenchmark (score: BaseReportBenchmark | PartialReportBenchmark | ReportBenchmark | V2ReportBenchmarkCondition): score is V2UniverseReportBenchmark {
  return score.collection === ScoreCollection.UniverseAverage && score.version === '2'
}

export function isV1OrganisationReportBenchmark (score: BaseReportBenchmark | PartialReportBenchmark | ReportBenchmark | V1ReportBenchmarkCondition): score is V1OrganisationReportBenchmark {
  return score.collection === ScoreCollection.OrganisationAverage && score.version === '1'
}

export function isV2OrganisationReportBenchmark (score: BaseReportBenchmark | PartialReportBenchmark | ReportBenchmark | V2ReportBenchmarkCondition): score is V2OrganisationReportBenchmark {
  return score.collection === ScoreCollection.OrganisationAverage && score.version === '2'
}

/**
 * Normalized v1 Universe Results into v2 Statistics ReportBenchmarks
 */
export function normalizeV2UniverseResults (scores: UniverseResult[]): StatsResult[] {
  return scores.reduce(
    (results: StatsResult[], result: UniverseResult): StatsResult[] => {
      // let subSections: BaseStatsResult[] | undefined
      // if (result.subSectionId) {
      //   if (!subSections) {
      //     subSections = []
      //   }
      //   const existingSectionResult: StatsResult | undefined = results.find((r) => {
      //     if (result.masterSectionId) {
      //       return r.masterSectionId === result.masterSectionId
      //     }
      //     return r.sectionSlug === result.key
      //   })
      //
      //   const subSectionResult: BaseStatsResult = {
      //     key: result.key,
      //     masterSectionId: result.masterSectionId,
      //     name: result.label,
      //     // sectionSlug: result.key,
      //     value: result.score,
      //     displayOrder: result.displayOrder
      //   }
      //   if (existingSectionResult) {
      //     if (!existingSectionResult.subSections) {
      //       existingSectionResult.subSections = []
      //     }
      //     existingSectionResult.subSections.push(subSectionResult)
      //   }
      // }

      results.push({
        key: result.key,
        name: result.label,
        value: result.score
        // masterSectionId: result.masterSectionId,
        // sectionSlug: result.key,
        // displayOrder: result.displayOrder
      })
      return results
    },
    []
  )
}

/**
 * Returns a boolean whether the provided score is for the provided Section/SubSection
 */
export function isSectionStatsResult (
  score: StatsResult,
  masterSectionId: string | undefined,
  sectionSlug: string | undefined,
  subSectionSlug?: string | undefined
): boolean {
  if (subSectionSlug) {
    if (score.key === subSectionSlug) {
      return true
    } else if (score.subSections?.length) {
      if (masterSectionId && score.masterSectionId && masterSectionId !== score.masterSectionId) {
        return false
      }
      if (sectionSlug && score.sectionSlug && sectionSlug !== score.sectionSlug) {
        return false
      }
      return score.subSections.some(
        (ss: BaseStatsResult): boolean => ss.key === subSectionSlug
      )
    }
    return false
  }
  if (masterSectionId && masterSectionId === score.key) {
    return true
  }
  return sectionSlug === score.key
}

/**
 * Finds the Stats Result matching the provided Section/SubSection
 */
export function findSectionStatsResult (
  scores: StatsResult[],
  masterSectionId: string | undefined,
  sectionSlug: string | undefined,
  subSectionSlug?: string | undefined
): StatsResult | undefined {
  const result: StatsResult | undefined = scores.find(
    (s: StatsResult): boolean => isSectionStatsResult(s, masterSectionId, sectionSlug, subSectionSlug)
  )

  if (result && subSectionSlug) {
    if (result.key === subSectionSlug) {
      return result
    }
    if (result.subSections && subSectionSlug) {
      return result.subSections.find(
        (s: StatsResult): boolean => isSectionStatsResult(s, masterSectionId, sectionSlug, subSectionSlug)
      )
    }
    return undefined
  }

  return result
}
