export type SidebarLink = [string, string, string] | [string, string, undefined, string]

interface NavigationState {
  links: SidebarLink[]
}

const defaultLinks: SidebarLink[] = [
  ['home', 'Home', '/dashboard'],
  ['company', 'Companies', '/companies'],
  ['users', 'Participants', '/participants'],
  ['lightbulb-group-outline', 'Committees', '/committees'],
  // ['mdi-account-group', 'Licensed Assessors', 'Dashboard'],
  // ['mdi-file-tree-outline', 'Organisations', 'Dashboard'],
  // ['mdi-text-box-multiple-outline', 'Projects', 'Dashboard'],
  ['clipboard', 'Questionnaires', '/questionnaires'],
  ['shield-account-outline', 'Platform Administrators', '/platform-administrators']
]

const persistentLinks: SidebarLink[] = [
  ['help-circle', 'FAQ', undefined, 'kbs'] // External Knowledge-base System (Zendesk)
]

const boardMemberLinks: SidebarLink[] = [
  ['gauge', 'Activity', '/dashboard']
  // ['document', 'Reports', '/reports']
]

const defaultState = (): NavigationState => ({
  links: defaultLinks
})

export default {
  namespaced: true,
  state: defaultState(),
  mutations: {
    setDefaultLinks: (state: NavigationState): void => {
      state.links = [...defaultLinks, ...persistentLinks]
    },
    setAnalysisLinks: (state: NavigationState): void => {
      state.links = [...persistentLinks]
    },
    setBoardMemberLinks: (state: NavigationState) => (state.links = boardMemberLinks)
  }
}
