<template>
  <v-app
    id="betterboards"
    :style="appTheme"
  >
    <edit-account-modal
      v-if="editingProfile"
      v-model="editingProfile"
    />

    <v-overlay :value="showSplashScreen" color="white" opacity="100">
      <v-row class="flex-column">
        <v-col class="pb-16">
          <img
            :src="loadingLogo"
            alt="Powered by BetterBoards"
            class="d-block mx-auto"
            :style="{
              height: '125px',
              maxWidth: '100%'
            }"
          />
        </v-col>
        <v-col class="text-center pb-16">
          <platform-spinner
            class="mx-auto"
            color="black"
            :size="50"
            :width="5"
          />
        </v-col>
      </v-row>
    </v-overlay>

    <template v-if="!showSplashScreen">
      <platform-sidebar
        v-if="signedIn && loaded"
        v-model="expandNavDrawer"
        :mobile="isMobile"
      />

      <v-main>
        <div
          class="d-flex flex-column"
          :style="appContentStyle"
        >
          <div
            v-if="!isAuthRoute && signedIn"
            v-show="!hideAppBar"
            class="shrink"
          >
            <v-app-bar
              class="app-bar"
              color="white accent-4"
              dense
              light
            >
              <div
                v-if="isMobile"
              >
                <v-app-bar-nav-icon
                  @click="expandNavDrawer = !expandNavDrawer"
                />
              </div>

              <template v-if="exitButtonText">
                <platform-button
                  primary
                  icon="exit"
                  icon-reversed
                  default-case
                  v-tooltip:bottom="exitButtonTooltip"
                  @click="displayExitConfirmationModal"
                >
                  {{ exitButtonText }}
                </platform-button>

                <v-spacer />
              </template>

              <platform-icon
                v-if="selectedLink[0]"
                class="mr-2"
                :name="selectedLink[0]"
                :size="30"
              />

              <v-toolbar-title v-show="selectedLink">
                {{ selectedLink[1] }}
              </v-toolbar-title>

              <v-spacer />

              <platform-avatar
                v-if="user"
                :entity-type="avatarEntityType"
                placeholder-size="lg"
                class="mr-2"
                listen
                :pad-bottom="false"
                :size="40"
                :entity="avatarEntityId"
              />

              <div>
                <v-row
                  no-gutters
                  align="center"
                  class="pr-4"
                  @click="editingProfile = true"
                >
                  <div style="font-size: 90%" class="pr-1">
                    {{ $t('global.greeting', [displayName]) }}
                  </div>
                  <platform-button
                    icon="user-settings"
                    text
                    v-tooltip="$t('topNav.editProfile')"
                  />
                </v-row>
              </div>

              <platform-button
                icon="logout"
                text
                v-tooltip:bottom="$t('topNav.logOut')"
                @click="displayConfirmUserLogoutModal"
              />
            </v-app-bar>
            <confirmation-modal
              v-if="confirmUserLogoutModal"
              action="logout"
              force-icon="logout"
              :entity-type="selectedLink[1]"
              :message="$t('topNav.logoutConfirmationMessage')"
              @confirm="confirmLogout"
              @cancel="confirmUserLogoutModal = false"
            />
            <confirmation-modal
              v-if="exitConfirmationModal"
              action="exit"
              force-icon="exit"
              icon-reversed
              :entity-type="exitButtonText"
              :message="$t('topNav.exitConfirmationMessage')"
              @confirm="confirmExit"
              @cancel="exitConfirmationModal = false"
            />
          </div>
          <div
            class="d-flex flex-column flex-basis-0 flex-grow-1"
            :class="{
              'overflow-y-auto': routeVerticalScroll,
              'overflow-y-hidden': !routeVerticalScroll
            }"
          >
            <router-view />
          </div>
        </div>
      </v-main>
    </template>
  </v-app>
</template>

<script lang="ts">
import { getCurrentUser, signOut } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { MetaFlags } from '@/router'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { AccountType } from '@/API'
import PlatformSidebar from '@/components/layout/sidebar/PlatformSidebar.vue'
import EditAccountModal from '@/components/user/account/EditAccountModal.vue'
import WhiteLabelLogoWhite from '@/assets/images/logo-white-label-white.png'
import WhiteLabelLogoBlack from '@/assets/images/logo-white-label-black.png'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import getPageTitle from '@/helpers/routes'

export default Vue.extend({
  name: 'App',
  components: {
    PlatformAvatar,
    PlatformSpinner,
    PlatformSidebar,
    EditAccountModal,
    ConfirmationModal
  },
  data: () => ({
    expandNavDrawer: false,
    signedIn: false,
    loaded: false,
    editingProfile: false,
    windowHeight: <undefined | number>undefined,
    exitConfirmationModal: false,
    confirmUserLogoutModal: false
  }),
  watch: {
    async signedIn (val) {
      if (val) {
        await this.$store.dispatch('User/setup')
        this.$store.dispatch('User/setupNewCompanyMembershipSubscription')
      }
    },
    companyConfig () {
      this.$vuetify.theme.themes.light.primary = this.primaryColor
      this.$vuetify.theme.themes.light.secondary = this.secondaryColor
      this.$vuetify.theme.themes.light.error = this.warningColor
      this.$vuetify.theme.themes.light.warning = this.warningColor
    },
    isBoardMember (val) {
      if (val) {
        this.setBoardMemberLinks()
        return
      }
      this.setDefaultLinks()
    },
    selectedCompany (val) {
      if (val) {
        this.loaded = true
        // void this.$initAnalytics()
      }
    }
  },
  computed: {
    ...mapGetters('User', ['displayName']),
    ...mapGetters('Survey', ['targetUser']),
    ...mapState('User', ['user']),
    ...mapState('Company', ['selectedCompany', 'currentRole']),
    ...mapState('Navigation', ['links']),
    appTheme (): any {
      return {
        '--color-highlight-text': this.highlightColor,
        '--color-selected-highlight': this.selectedHighlightColor,
        '--color-admin-selected-highlight': this.adminSelectedHighlightColor,
        '--color-selected-shadow': this.selectedShadowColor,
        'overflow-y': 'hidden',
        height: this.windowHeight ? `${this.windowHeight}px` : undefined
      }
    },
    appContentStyle (): any {
      return {
        height: this.windowHeight ? `${this.windowHeight}px` : '100%'
      }
    },
    avatarEntityId (): any {
      if (this.targetUser) {
        return this.targetUser
      }
      return this.user
    },
    avatarEntityType (): string {
      if (['ViewSurvey', 'PreviewSurvey'].includes(this.$route.name as string)) {
        return 'individual'
      }
      return 'user'
    },
    showSplashScreen (): boolean {
      if (this.isAuthRoute && this.loaded) {
        return false
      }
      return !this.isAuthRoute && !this.loaded
    },
    isBoardMember (): boolean {
      return this.currentRole === AccountType.BoardMember
    },
    isMobile (): boolean {
      return this.$vuetify.breakpoint.smAndDown
    },
    selectedLink (): any {
      const linkData = this.links.find((link: string[]) => this.$route.path.startsWith(link[2])) || this.links[0]
      if (!linkData) {
        return linkData
      }
      if (getPageTitle(this.$route.name as string) !== undefined) {
        return [this.$route.meta?.pageIcon, getPageTitle(this.$route.name as string) as string]
      }
      const link = [...linkData]
      const routeName = link[1]
      const displayRouteName = this.$t(`route.${routeName.replace(' ', '')}`)
      link[1] = displayRouteName
      if (this.selectedCompany?.name) {
        switch (routeName) {
          case 'Participants':
          case 'Committees':
          case 'Questionnaires':
            link[1] = `${this.selectedCompany.name} ${displayRouteName}`
        }
      }
      return link
    },
    selectedCompanyId (): string | undefined {
      return this.selectedCompany?.id
    },
    hideAppBar (): boolean | undefined {
      return this.$route.meta?.[MetaFlags.HideAppBar]
    },
    primaryColor (): string {
      return this.selectedCompany?.configuration?.primaryColor ?? '#24235f'
    },
    secondaryColor (): string {
      return this.selectedCompany?.configuration?.secondaryColor ?? '#e44500'
    },
    companyConfig (): any {
      return this.selectedCompany?.configuration
    },
    appThemeConfig (): any {
      if (!this.companyConfig?.questionnaire) {
        return
      }
      return this.companyConfig.questionnaire
    },
    adminThemeConfig (): any {
      if (!this.companyConfig?.admin) {
        return
      }
      return this.companyConfig.admin
    },
    highlightColor (): string {
      const value = this.appThemeConfig?.textHighlightColor
      const color = value ? this.getThemeColor(value) : undefined
      return color ?? '#000000'
    },
    warningColor (): string {
      const value = this.appThemeConfig?.warningColor
      const color = value ? this.getThemeColor(value) : undefined
      return color ?? '#ae3033'
    },
    selectedHighlightColor (): string {
      const value = this.appThemeConfig?.selectedHighlightColor
      const color = value ? this.getThemeColor(value) : undefined
      return color ?? this.secondaryColor
    },
    adminSelectedHighlightColor (): string {
      const value = this.adminThemeConfig?.adminSelectedHighlightColor
      const color = value ? this.getThemeColor(value) : undefined
      return color ?? this.secondaryColor
    },
    selectedShadowColor (): string | undefined {
      const value = this.appThemeConfig?.selectedShadowColor
      const color = value ? this.getThemeColor(value) : undefined
      return color ?? undefined
    },
    routeVerticalScroll (): boolean {
      if (this.$route.meta?.[MetaFlags.NoScroll]) {
        return false
      }
      return true
    },
    loadingLogo (): string {
      if (this.isAuthRoute) {
        return WhiteLabelLogoWhite
      }
      return WhiteLabelLogoBlack
    },
    isAuthRoute (): boolean {
      return this.$route.name === 'Auth'
    },
    exitButtonText (): string | undefined {
      if (this.$route.matched.some((route) => route.name === 'Analysis')) {
        return this.$t('topNav.exitAnalysisAction') as string
      }
      switch (this.$route.name) {
        case 'QuestionnaireWizard':
          return this.$t('topNav.exitQuestionnaireWizardAction') as string
        case 'PdfViewer':
          return this.$t('topNav.exitSampleReportAction') as string
        case 'ViewSurvey':
          return this.$t('topNav.exitQuestionnaireAction') as string
        case 'SampleSurvey':
          return this.$t('topNav.exitSampleSurveyAction') as string
        case 'ResponseStatusList':
          return this.$t('topNav.exitResponseStatusListAction') as string
        case 'QuestionnaireResponseAnalysis':
          return this.$t('topNav.exitQuestionnaireResponseAnalysisAction') as string
        case 'QuestionnairesReportList':
          return this.$t('topNav.exitQuestionnairesReportListAction') as string
        case 'SurveyResponseStatus':
          return this.$t('global.back') as string
        default:
          return undefined
      }
    },
    exitButtonTooltip (): string {
      switch (this.$route.name) {
        case 'SurveyResponseStatus':
          return this.$t('page.viewSurvey.returnToResponseStatusListAction') as string
        default:
          return this.$t('page.viewSurvey.returnToDashboard') as string
      }
    }
  },
  beforeDestroy () {
    this.$store.dispatch(('User/teardownNewCompanyMembershipSubscription'))
  },
  beforeMount () {
    this.handleResize()
    window.visualViewport.addEventListener('resize', () => this.handleResize())
    this.signedIn = false
    let redirect: string | undefined
    if (this.$route.query.redirect) {
      redirect = this.$route.query.redirect as string
    }
    const redirectTargetPath = redirect ? decodeURIComponent(redirect) : { name: 'Dashboard' }
    Hub.listen('auth', (data) => {
      console.log('data:', data)
      const { payload } = data
      if (payload.event === 'signedIn') {
        this.signedIn = true
        this.$router.push(redirectTargetPath)
      }
      if (payload.event === 'signedOut') {
        this.$router.push({ name: 'Auth' })
        this.reset()
      }
    })
    getCurrentUser()
      .then(() => {
        this.signedIn = true
        if (redirect) {
          this.$router.push(redirectTargetPath)
        }
      })
      .catch(() => {
        this.reset()
      })
  },
  methods: {
    ...mapActions('User', ['roleByCompanyId']),
    ...mapMutations('Navigation', ['setBoardMemberLinks', 'setDefaultLinks']),
    reset () {
      this.signedIn = false
      this.loaded = false
    },
    async signOut () {
      this.reset()
      await signOut()
      localStorage.removeItem('selectedCompany')
      this.$store.commit('reset')
    },
    getThemeColor (color: 'primary' | 'secondary' | string) {
      switch (color) {
        case 'primary':
          return this.primaryColor
        case 'secondary':
          return this.secondaryColor
        default:
          return color
      }
    },
    handleResize () {
      setTimeout(() => {
        const windowHeight = window.innerHeight
        if (windowHeight) {
          this.windowHeight = windowHeight
        }
      }, 100)
    },
    displayExitConfirmationModal () {
      switch (this.$route.name) {
        case 'PdfViewer':
        case 'SampleSurvey':
        case 'ResponseStatusList':
        case 'QuestionnaireResponseAnalysis':
        case 'QuestionnairesReportList':
          this.$router.push({ name: 'Dashboard' })
          break
        case 'SurveyResponseStatus':
          this.$router.push({ name: 'ResponseStatusList' })
          break
        default:
          this.exitConfirmationModal = true
      }
    },
    confirmExit () {
      this.exitConfirmationModal = false
      if (this.$route.matched.some((route) => route.name === 'Analysis')) {
        this.$router.push({
          name: 'Dashboard'
        })
        return
      }
      this.$router.push({ name: 'Dashboard' })
    },
    displayConfirmUserLogoutModal () {
      this.confirmUserLogoutModal = true
    },
    confirmLogout () {
      this.confirmUserLogoutModal = false
      this.signOut()
    }
  }
})
</script>

<style lang="scss" scoped>
.app-bar {
  z-index: 1;
}
</style>

<style lang="scss">
#betterboards {
  background-color: var(--v-background-base);
}

.v-select-list .v-list-item {
  transition: background-color ease-in-out 200ms;
  &:hover {
    background-color: #f3f3f9;
  }
}

.v-btn {
  min-width: 36px !important;
  min-height: 36px !important;
  height: auto !important;
}

.flex-basis-0 {
  flex-basis: 0;
}
</style>
