var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"results-panels"},[(_vm.reportContextButtonKey)?_c('v-row',{staticClass:"pb-2",attrs:{"justify":"end"}},[_c('v-col',{staticClass:"shrink"},[_c('report-context-button',{attrs:{"value":_vm.reportContextButtonKey,"section":_vm.section}})],1)],1):_vm._e(),_c('v-expansion-panels',{attrs:{"multiple":"","focusable":"","color":"primary"}},_vm._l((_vm.resultsList),function(result,index){return _c('v-expansion-panel',{key:`result-${index}`,ref:"resultPanel",refInFor:true,staticClass:"platform--expansion-panel mt-4 rounded"},[_c('v-expansion-panel-header',{staticClass:"header px-4 py-1"},[_c('v-row',{attrs:{"align":"center","justify":"space-between","no-gutters":""}},[_c('v-col',{staticClass:"shrink"},[(result.requiresReview)?_c('platform-icon',{staticClass:"reviewed--icon mr-2",attrs:{"color":!!result.reviewedAt ? 'secondary' : (result.completed ? 'primary' : 'text--darken-2 darken-2 grey'),"name":!!result.reviewedAt ? 'check-outline' : (result.completed ? 'progress' : 'no-progress-pencil'),"size":30}}):_vm._e()],1),_c('v-col',{staticClass:"py-2",class:{ shrink: !result.graphType && !(result.data && result.data.questionText)}},[(result.graphType)?_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('platform-icon',{staticClass:"mr-2",attrs:{"name":result.criteriaType === 'BoardMembers' ? _vm.GraphTypeIcons[_vm.GraphTypes.Appraisal] : _vm.GraphTypeIcons[result.graphType],"size":40,"color":"highlight"}}),_c('span',{staticClass:"highlight--text text-no-wrap"},[_vm._v(" "+_vm._s(_vm.getFriendlyGraphTypeName(result))+" ")])],1):(result.data && result.data.questionText)?_c('div',{staticClass:"highlight--text"},[_vm._v(" "+_vm._s(result.data.questionText)+" ")]):_c('platform-icon',{staticClass:"mr-2",attrs:{"size":40,"name":"committee","color":"highlight"}})],1),(result.subTitle)?_c('v-col',{staticClass:"d-flex align-center px-2",class:{
              'flex-grow-0 justify-end': result.graphType || (result.data && result.data.questionText)
            }},[_c('span',{staticClass:"text-no-wrap white pl-2 py-2",class:{
              'highlight--text': result.committee,
              'text-right primary--text': !result.committee
            },style:({
              'min-width': _vm.$vuetify.breakpoint.smAndUp ? '280px' : undefined
            })},[_vm._v(" "+_vm._s(result.subTitle)+" ")]),(result.criteriaId && result.criteriaType === _vm.RepeatForOptions.BoardMembers)?_c('div',{staticClass:"pl-2 py-1"},[_c('platform-avatar',{staticClass:"rounded",attrs:{"internal-loader":"","small-loader":"","entity-type":"individual","entity":{
                  id: result.criteriaId,
                  companyId: _vm.platformSelectedCompanyId,
                  preferredName: result.criteria,
                },"size":48}})],1):_vm._e()]):_vm._e()],1)],1),_c('v-expansion-panel-content',[_c('display-results-group',{attrs:{"result-group":result,"report-section":_vm.reportSection,"section":_vm.section,"loading":_vm.loading},on:{"updateReportSection":(payload) => _vm.$emit('updateReportSection', payload)}})],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }