<template>
  <platform-modal
    v-model="showModal"
    icon="sync"
    :width="550"
    :title="title"
    @confirm="handleConfirm"
    @close="showModal = false"
  >
    <v-row
      no-gutters
      align="center"
      class="flex-column"
    >
      <span class="grey--text text--darken-1 text-body-2">
        {{ confirmationNote }}
      </span>

      <div class="py-2 px-1 text-center">
        <display-report-benchmark
          v-if="value"
          :value="value"
        />
      </div>

      <span class="grey--text text--darken-1 text-caption">
        {{ instruction }}
      </span>
    </v-row>
  </platform-modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import PlatformModal from '@/components/PlatformModal.vue'
import DisplayReportBenchmark from '@/components/forms/reports/benchmarks/DisplayReportBenchmark.vue'

export default Vue.extend({
  name: 'ConfirmSyncBenchmarkModal',
  components: {
    DisplayReportBenchmark,
    PlatformModal
  },
  props: {
    value: { type: Object as PropType<ReportBenchmark>, required: true }
  },
  data: () => ({
    showModal: true
  }),
  watch: {
    showModal (showModal: boolean): void {
      this.$emit(showModal ? 'open' : 'close')
    }
  },
  computed: {
    title (): string {
      return this.$t('modal.report.config.editScores.synchronize.confirmationModal.title') as string
    },
    confirmationNote (): string {
      return this.$t('modal.report.config.editScores.synchronize.confirmationModal.confirmationNote') as string
    },
    instruction (): string {
      return this.$t('modal.report.config.editScores.synchronize.confirmationModal.instruction') as string
    }
  },
  methods: {
    handleConfirm (): void {
      this.$emit('confirm')
    }
  }
})
</script>
