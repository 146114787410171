<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fill-rule="evenodd">
      <g stroke="currentColor">
        <g>
          <g>
            <g>
              <path stroke-linecap="square" d="M5 35L35 35M5 35L5 5" transform="translate(-364 -1575) translate(348 1372) translate(0 188) translate(16 15)"/>
              <path stroke-linecap="round" d="M2 7L5 4 8 7" transform="translate(-364 -1575) translate(348 1372) translate(0 188) translate(16 15)"/>
              <path stroke-linecap="round" d="M31.5 36.5L34.5 33.5 37.5 36.5" transform="translate(-364 -1575) translate(348 1372) translate(0 188) translate(16 15) rotate(90 34.5 35)"/>
              <path stroke-linecap="round" d="M5 19c1.022.34 1.69 1.007 2.002 2 .469 1.49 1.758 5 3.23 5C13 26 14 15 17 15s3.393 11 6.196 11C26 26 25.92 9.43 29.46 9.43c1.98 0 2.256 5.105 3.091 9.57.66 3.524 2.009 6.559 2.45 7 .667.667 1.329.827 1.986.48" transform="translate(-364 -1575) translate(348 1372) translate(0 188) translate(16 15)"/>
              <path stroke-dasharray="2 1" d="M5 22c6.682.667 13.269 0 19.761-2C31.254 18 35 14.144 36 8.432" transform="translate(-364 -1575) translate(348 1372) translate(0 188) translate(16 15)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ScaleGraphIcon'
})
</script>
