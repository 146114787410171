<template>
  <v-row no-gutters align="center" class="flex-column pt-2">
    <v-row no-gutters align="center" justify="center" style="min-height: 125px;">
      <platform-spinner
        v-if="pendingInitTOTP"
        class="mx-auto mb-3"
      />
      <canvas
        ref="totpCanvas"
        class="mfa-qr-canvas"
      />
    </v-row>
    <v-row
      no-gutters
      class="cursor-pointer flex-nowrap"
      align="center"
      justify="center"
      v-tooltip:top="copyTOTPSecretTooltip"
      @click="copyTOTPSecret"
    >
      <platform-icon
        name="clipboard"
        color="grey darken-1"
        :size="24"
      />
      <v-col class="shrink pl-1 text--compact">
        <small
          v-for="(part, index) in displayTOTPSecretParts"
          :key="index"
          class="grey--text"
        >
          {{ part }}
        </small>
      </v-col>
    </v-row>
    <span class="grey--text text--darken-2 mt-2 mb-5 px-1">
      {{ $t('modal.addMFADevice.TOTP.instruction') }}
    </span>
    <v-form ref="form" v-model="formValid" @submit.prevent="">
      <platform-text-field
        required
        v-model="formData.totpCode"
        outlined
        :label="$t('modal.addMFADevice.TOTP.totpCodeFieldLabel')"
        :rules="totpCodeFieldValidators"
        :loading="pendingAddMethod"
      />
    </v-form>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import QRCode from 'qrcode'
import { PlatformCognitoUser } from '@/types/User'
import { Validators } from '@/helpers/validation'
import { clipboardCopy, NonBreakingSpace } from '@/helpers/browser'
import PlatformTextField from '@/components/shared/inputs/PlatformTextField.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { CognitoIdentity } from '@/API'
import {
  setUpTOTP,
  updateMFAPreference
} from 'aws-amplify/auth'

export default Vue.extend({
  name: 'AddTOTPMethodForm',
  components: {
    PlatformSpinner,
    PlatformTextField
  },
  props: {
    currentCognitoUser: { type: Object as PropType<PlatformCognitoUser>, required: true }
  },
  data: () => ({
    formData: {
      totpCode: <null | string>null
    },
    formValid: false,
    pendingAddMethod: false,
    pendingInitTOTP: false,
    totpSecretCode: <null | string>null
  }),
  mounted (): void {
    this.init()
  },
  watch: {
    formValid (val: boolean): void {
      this.$emit('valid', val)
    },
    'formData.totpCode' (val: string | null): void {
      this.$emit('input', val)
    }
  },
  computed: {
    currentUser (): CognitoIdentity {
      return this.$store.state.User.user
    },
    totpCodeFieldValidators (): any[] {
      return [
        Validators.maxLength(6)
      ]
    },
    displayTOTPSecretParts (): string[] | undefined {
      if (!this.totpSecretCode) {
        return
      }
      // Split into groups of 4 characters
      const groups: string[] | null = this.totpSecretCode.match(/.{1,4}/g)
      if (!groups?.length) {
        return [this.totpSecretCode]
      }
      const midIndex: number = Math.ceil(groups.length / 2)
      const firstHalf: string[] = groups.slice(0, midIndex)
      const secondHalf: string[] = groups.slice(midIndex, groups.length)
      return [
        firstHalf.join(NonBreakingSpace),
        secondHalf.join(NonBreakingSpace)
      ]
    },
    copyTOTPSecretTooltip (): string {
      return this.$t('modal.addMFADevice.TOTP.copyTOTPSecretTooltip') as string
    }
  },
  methods: {
    async init (): Promise<void> {
      if (!this.currentCognitoUser) {
        return
      }
      this.pendingInitTOTP = true

      const output = await setUpTOTP()
      this.totpSecretCode = output.sharedSecret
      const userEmail = encodeURIComponent(this.currentUser.email)
      const setupUri = output.getSetupUri('Better Boards', userEmail)
      const totpQRSource = `${setupUri}&image=https://app.better-boards.com/android-chrome-192x192.png`
      console.debug('Got TOTP string:', totpQRSource)

      const canvas = this.$refs.totpCanvas
      try {
        await QRCode.toCanvas(
          canvas,
          totpQRSource,
          {
            errorCorrectionLevel: 'H',
            width: 175
          }
        )
      } catch (err) {
        console.error('Failed to generate TOTP QR Image:', err)
        throw err
      } finally {
        await this.handleUpdateMFAPreference()
        this.pendingInitTOTP = false
      }
    },
    async handleUpdateMFAPreference () {
      try {
        await updateMFAPreference({ totp: 'PREFERRED' })
      } catch (error) {
        console.log('Error setting TOTP as preferred MFA method: ', error)
      }
    },
    async copyTOTPSecret (): Promise<void> {
      if (!this.totpSecretCode) {
        throw new Error('Failed to copy TOTP source as no value is set.')
      }
      await clipboardCopy(this.totpSecretCode)
      this.$toasted.success('MFA URL copied to clipboard.')
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.mfa-qr-canvas {
  display: block;
  width: 175px;
  height: 175px;
}
</style>
