import Vue from 'vue'
import SectionsList from '../components/forms/sections/SectionsList'
import BlocksList from '../components/forms/blocks/BlocksList'
import BlockForm from '../components/forms/BlockForm'
import TextQuestionForm from '../components/forms/blocks/questions/TextQuestionForm'
import MultiTextQuestionForm from '../components/forms/blocks/questions/MultiTextQuestionForm'
import ScaleQuestionForm from '../components/forms/blocks/questions/ScaleQuestionForm'
import FileUpload from '../components/FileUpload'
import AddressForm from '../components/forms/AddressForm'
import CompanyOwnershipForm from '../components/forms/companies/CompanyOwnershipForm'
import PlatformDatePicker from '../components/shared/inputs/PlatformDatePicker'
import PlatformTextField from '@/components/shared/inputs/PlatformTextField'
import PlatformTextArea from '@/components/shared/inputs/PlatformTextArea'
import PlatformSelectField from '@/components/shared/inputs/PlatformSelectField'
import PlatformMegaMenu from '@/components/shared/inputs/PlatformMegaMenu'
import PlatformAutocomplete from '@/components/shared/inputs/PlatformAutocomplete.vue'
import TargetRulesForm from '@/components/forms/blocks/target-rules/TargetRulesForm.vue'
import IndividualAppointmentsField from '@/components/individuals/IndividualAppointmentsField.vue'
import PlatformSwitch from '@/components/shared/PlatformSwitch.vue'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import PlatformButton from '@/components/shared/PlatformButton.vue'
import PlatformIcon from '@/components/shared/PlatformIcon.vue'

Vue.component('VSectionsList', SectionsList)
Vue.component('VBlocksList', BlocksList)
Vue.component('VBlockForm', BlockForm)
Vue.component('VTextQuestionForm', TextQuestionForm)
Vue.component('VMultiTextQuestionForm', MultiTextQuestionForm)
Vue.component('VScaleQuestionForm', ScaleQuestionForm)
Vue.component('VBBFileUpload', FileUpload)
Vue.component('VBBAddressForm', AddressForm)
Vue.component('VBBCompanyOwnershipForm', CompanyOwnershipForm)
Vue.component('VBBDatePicker', PlatformDatePicker)
Vue.component('VBBTextField', PlatformTextField)
Vue.component('VBBTextArea', PlatformTextArea)
Vue.component('VBBSelectField', PlatformSelectField)
Vue.component('VBBLayeredSelectField', PlatformMegaMenu)
Vue.component('VBBAutocomplete', PlatformAutocomplete)
Vue.component('VBBTargetRules', TargetRulesForm)
Vue.component('VBBIndividualAppointmentsField', IndividualAppointmentsField)

// Platform Components
Vue.component('PlatformButton', PlatformButton)
Vue.component('PlatformEntityCard', PlatformEntityCard)
Vue.component('PlatformAvatar', PlatformAvatar)
Vue.component('PlatformIcon', PlatformIcon)
Vue.component('PlatformSwitch', PlatformSwitch)
Vue.component('PlatformDatePicker', PlatformDatePicker)
Vue.component('PlatformTextField', PlatformTextField)
Vue.component('PlatformTextArea', PlatformTextArea)
Vue.component('PlatformSelectField', PlatformSelectField)
Vue.component('PlatformMegaMenu', PlatformMegaMenu)
