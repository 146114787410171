import { CreateCommitteeMutation, DeleteCommitteeMutation, UpdateCommitteeMutation } from '@/API'
import { listCommitteesExtended } from '@/graphql/custom/listCommitteesExtended'
import { onCreateCommittee, onDeleteCommittee, onUpdateCommittee } from '@/graphql/subscriptions'
import { ignoreParentCompanyError } from '@/helpers/company'
import { sortCommitteeIndividuals } from '@/helpers/individual'
import { Module } from 'vuex'
import Vue from 'vue'
import API from '@/services/API'
import {
  createCommittee,
  createCommitteeIndividual,
  deleteCommittee,
  deleteCommitteeIndividual,
  updateCommittee
} from '@/graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { PartialCommittee, PlatformCommittee, PlatformCommitteeIndividual } from '@betterboards/shared/types/Committee'
import { CommitteeTag } from '@betterboards/shared/types/API'

interface CommitteeState {
  list: PlatformCommittee[]
  listNextToken: string | undefined
  selectedCommittee: PlatformCommittee | null
  amplifySubscriptions: any[]
}

const defaultState = (): CommitteeState => ({
  list: [],
  listNextToken: undefined,
  selectedCommittee: null,
  amplifySubscriptions: []
})

const CommitteeStore: Module<CommitteeState, any> = {
  namespaced: true,
  state: defaultState(),
  getters: {
    list (state: CommitteeState, getters: any, rootState: any, rootGetters: any): any[] {
      const list = [...state.list]
      return list
        .map((committee) => {
          const committeeIndividuals = committee.individuals?.items
          if (committeeIndividuals) {
            const individuals = committeeIndividuals
              .sort(sortCommitteeIndividuals)
              .map((committeeIndividual) => {
                const individual = rootGetters['Individual/individualsList'].find((i) => i.id === committeeIndividual.individualId)
                if (!individual) {
                  console.warn('Failed to find Individual for CommitteeIndividual?', committeeIndividual)
                  return committeeIndividual
                }
                return {
                  ...committeeIndividual,
                  individual
                }
              })
              .filter((ci) => !!ci?.individual)
            return {
              ...committee,
              individuals: {
                items: individuals
              }
            }
          }
          return committee
        })
        .sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            // Sort newest at start
            return b.createdAt.localeCompare(a.createdAt)
          }
          return 0
        })
    }
  },
  mutations: {
    setList: (state: any, value: any[]) => {
      state.list = value
    },
    resetList (state: CommitteeState) {
      state.list.splice(0, state.list.length)
    },
    addSubscription: (state: any, subscription: any) => {
      state.amplifySubscriptions.push(subscription)
    },
    addCommittee: (state: any, committee: PlatformCommittee) => {
      const index = state.list.findIndex((item: PlatformCommittee) => item.id === committee.id)
      if (index !== -1) {
        state.list.splice(index, 1, committee)
        return
      }
      if (!committee.individuals?.items && state.list[index]?.individuals?.items) {
        committee.individuals = state.list[index].individuals
      }
      state.list.push(committee)
    },
    updateCommittee: (state: any, committee: PlatformCommittee) => {
      const index = state.list.findIndex((item: PlatformCommittee) => item.id === committee.id)
      if (index === -1) {
        return
      }
      if (!committee.individuals?.items && state.list[index]?.individuals?.items) {
        committee.individuals = state.list[index].individuals
      }
      state.list.splice(index, 1, committee)
    },
    removeCommittee: (state: any, committee: PlatformCommittee) => {
      const index = state.list.findIndex((item: PlatformCommittee) => item.id === committee.id)
      if (index === -1) {
        return
      }
      state.list.splice(index, 1)
    },
    teardownSubscriptions (state): void {
      state.amplifySubscriptions.forEach((sub) => sub.unsubscribe())
      state.amplifySubscriptions.splice(0, state.amplifySubscriptions.length)
    }
  },
  actions: {
    async setupSubscriptions ({ rootState, state, commit }) {
      const companyId = rootState.Company.selectedCompany.id

      let sub
      sub = API.graphql({
          query: onCreateCommittee,
          variables: {
            companyId
          }
        })
        .subscribe({
          next: ({ data }) => {
            state.list.push(data.onCreateCommittee as PlatformCommittee)
          }
        })
      commit('addSubscription', sub)

      sub = API.graphql({
        query: onUpdateCommittee,
        variables: {
          companyId
        }
      })
        .subscribe({
          next: ({ data }) => {
            const index = state.list.findIndex((item) => item.id === data.onUpdateCommittee.id)
            if (index !== -1) {
              const committee: PlatformCommittee = {
                ...data.onUpdateCommittee,
                company: undefined,
                tags: data.onUpdateCommittee.tags?.filter((t: CommitteeTag | null | undefined): t is CommitteeTag => !!t),
                individuals: state.list[index].individuals
              }
              state.list.splice(index, 1, committee)
            }
          }
        })
      commit('addSubscription', sub)

      sub = API.graphql({
        query: onDeleteCommittee,
        variables: {
          companyId
        }
      })
        .subscribe({
          next: ({ data }) => {
            const index = state.list.findIndex((item) => item.id === data.onDeleteCommittee.id)
            if (index !== -1) {
              state.list.splice(index, 1)
            }
          }
        })
      commit('addSubscription', sub)
    },
    async loadList ({ commit, state, rootState }, fresh?: boolean): Promise<void> {
      const companyId = rootState.Company.selectedCompany.id
      let committeesResponse
      try {
        committeesResponse = await API.graphql({
          query: listCommitteesExtended,
          variables: {
            companyId,
            nextToken: fresh ? undefined : state.listNextToken
          }
        })
      } catch (err) {
        console.error('Failed to fetch Committees state list.', err)
      }
      const rawCommittees = committeesResponse.data?.listCommitteesByCompanyId?.items
      if (!rawCommittees) {
        return
      }
      const committees = rawCommittees.map((c) => {
        return {
          ...c,
          individuals: {
            items: c.individuals?.items ?? []
          }
        }
      })
      commit('setList', committees)
    },
    async createCommittee (
      { rootState, commit }: any,
      { name, tags, individuals }: PartialCommittee
    ) {
      const companyId = rootState.Company.selectedCompany.id
      try {
        let committeeResp
        try {
          committeeResp = await API.graphql({
            query: createCommittee,
            variables: {
              input: {
                companyId,
                name,
                tags
              }
            }
          }) as GraphQLResult<CreateCommitteeMutation>
        } catch (err) {
          if (!ignoreParentCompanyError(err)) {
            throw err
          }
          committeeResp = err
        }
        const committee = committeeResp.data?.createCommittee
        if (!committee) {
          console.error('Failed to create committee for some reason, response:', committeeResp)
          throw new Error('Failed to create committee')
        }
        const promises = individuals.map(async (individual: PlatformCommitteeIndividual): Promise<PlatformCommitteeIndividual> => {
          let response
          try {
            response = await API.graphql({
              query: createCommitteeIndividual,
              variables: {
                input: {
                  committeeId: committee.id,
                  individualId: individual.individualId,
                  companyId
                }
              }
            })
          } catch (err) {
            if (!ignoreParentCompanyError(err)) {
              throw err
            }
            response = err
          }
          return response.data.createCommitteeIndividual as PlatformCommitteeIndividual
        })

        const newCommitteeIndividuals = await Promise.all(promises)
        const newCommittee = {
          ...committee,
          individuals: {
            items: newCommitteeIndividuals
          }
        }
        console.debug('Created Committee.', committee, newCommittee)
        commit('addCommittee', newCommittee)

        return committee
      } catch (e) {
        console.error('error creating committee', e)
        Vue.toasted.error('Error creating committee, please try again later')
      }
    },
    async deleteCommittee ({ commit }, committee: PlatformCommittee) {
      console.log('attempting to delete committee', committee)
      let removeCommitteeResponse
      try {
        removeCommitteeResponse = await API.graphql({
          query: deleteCommittee,
          variables: {
            input: {
              id: committee.id
            }
          }
        }) as GraphQLResult<DeleteCommitteeMutation>
      } catch (err) {
        if (!ignoreParentCompanyError(err)) {
          console.error('error deleting committee', err)
          Vue.toasted.error('There was an error deleting the committee. (1)')
          return
        }
        removeCommitteeResponse = err
      }

      console.log('removed committee response', removeCommitteeResponse)
      const individualsOnCommitee = removeCommitteeResponse.data?.deleteCommittee?.individuals?.items
      let promises: Array<Promise<any>> = []
      if ((individualsOnCommitee != null) && individualsOnCommitee.length > 0) {
        promises = individualsOnCommitee.map(async (item) => {
          if (item) {
            let response
            try {
              response = await API.graphql({
                query: deleteCommitteeIndividual,
                variables: {
                  input: {
                    individualId: item.individualId,
                    committeeId: item.committeeId
                  }
                }
              })
            } catch (err) {
              if (!ignoreParentCompanyError(err)) {
                console.error('error deleting CommitteeIndividuals', err)
                Vue.toasted.error('There was an error deleting the committee. (2)')
                return
              }
              response = err
            }
            return response
          }
        })
      }
      const responses = await Promise.allSettled(promises)
      commit('removeCommittee', committee)
      return responses
    },
    async updateCommittee (
      { rootState, commit },
      {
        formData,
        individualIds,
        removedIndividualIds
      }: {
        formData: any
        individualIds: string[]
        removedIndividualIds: string[]
      }
    ) {
      const companyId = rootState.Company.selectedCompany.id
      console.debug('Updating Committee...', formData)
      let committeeResp
      try {
        committeeResp = await API.graphql({
          query: updateCommittee,
          variables: {
            input: {
              ...formData,
              companyId
            }
          }
        }) as GraphQLResult<UpdateCommitteeMutation>
      } catch (err) {
        if (!ignoreParentCompanyError(err)) {
          console.error('error updating committee', err)
          Vue.toasted.error('Error updating the committee, please try again later')
          return
        }
        committeeResp = err
      }
      const committee = committeeResp.data?.updateCommittee
      if (!committee) {
        console.error('Failed to update committee for some reason, response:', committeeResp)
        throw new Error('Failed to update committee')
      }
      const deleteCommitteeIndividualPromises = removedIndividualIds.map(async (individualId: string) => {
        let response
        try {
          response = await API.graphql({
            query: deleteCommitteeIndividual,
            variables: {
              input: {
                committeeId: committee.id,
                individualId
              }
            }
          })
        } catch (err) {
          if (!ignoreParentCompanyError(err)) {
            console.error('error deleting CommitteeIndividual in updateCommittee', err)
            Vue.toasted.error('There was an error removing board members from the committee.')
            return undefined
          }
          response = err
        }
        return response
      })
      const createCommitteeIndividualPromises = individualIds.map(async (individualId: string) => {
        let response
        try {
          response = await API.graphql({
            query: createCommitteeIndividual,
            variables: {
              input: {
                committeeId: committee.id,
                companyId,
                individualId
              }
            }
          })
        } catch (err) {
          if (!ignoreParentCompanyError(err)) {
            console.error('error creating CommitteeIndividual in updateCommittee', err)
            Vue.toasted.error('There was an error adding board members to the committee')
            return undefined
          }
          response = err
        }
        return response?.data.createCommitteeIndividual
      })
      await Promise.all([
        ...createCommitteeIndividualPromises,
        ...deleteCommitteeIndividualPromises
      ])
      let updatedCommitteeIndividuals = committee.individuals?.items
        ? [...committee.individuals.items]
        : []
      if (removedIndividualIds.length) {
        updatedCommitteeIndividuals = updatedCommitteeIndividuals.filter((i) => !removedIndividualIds.includes(i.individualId))
      }
      const createdCommitteeIndividuals = await Promise.all(createCommitteeIndividualPromises) // Already resolved, just used for data.
      if (createdCommitteeIndividuals.length) {
        createdCommitteeIndividuals.forEach((individual) => {
          updatedCommitteeIndividuals.push({
            individualId: individual.individualId,
            companyId,
            createdAt: individual.createdAt
          })
        })
      }
      const updatedCommittee = {
        ...committee,
        individuals: {
          items: updatedCommitteeIndividuals
        }
      }
      console.debug('Updated Committee.', committee, updatedCommittee)
      commit('updateCommittee', updatedCommittee)
    }
  }
}

export default CommitteeStore
