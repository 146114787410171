import { render, staticRenderFns } from "./SectionScoreBalls.vue?vue&type=template&id=6fc8b2e8&scoped=true"
import script from "./SectionScoreBalls.vue?vue&type=script&lang=ts"
export * from "./SectionScoreBalls.vue?vue&type=script&lang=ts"
import style0 from "./SectionScoreBalls.vue?vue&type=style&index=0&id=6fc8b2e8&prod&lang=scss&scoped=scoped"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fc8b2e8",
  null
  
)

export default component.exports