<template>
  <img
    class="d-block"
    :alt="countryCode"
    :src="imageUrl"
    :style="{
      height: '15px',
    }"
  />
</template>

<script lang="ts">
import Vue from 'vue'
import { flagPicker } from '@/helpers/flags'

export default Vue.extend({
  name: 'CountryFlag',
  props: {
    countryCode: { type: String, required: false }
  },
  data () {
    return {
      imageUrl: ''
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    countryCode: 'init'
  },
  methods: {
    init (): void {
      if (!this.countryCode) {
        return
      }
      this.imageUrl = flagPicker(this.countryCode.toLowerCase())
    }
  }
})
</script>
