<template>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
       viewBox="0 0 283.46 283.46" xml:space="preserve">
    <g>
      <path class="st0" d="M202.89,43.34l-4.45,4.78c-0.48-0.63-1.21-1.06-2.06-1.06h-41.74c-1.46,0-2.65,1.19-2.65,2.65v41.74
        c0,1.46,1.19,2.65,2.65,2.65h41.74c1.46,0,2.65-1.19,2.65-2.65V55.27l7.75-8.32c1-1.07,0.94-2.75-0.13-3.75
        C205.57,42.21,203.89,42.27,202.89,43.34z M193.73,53.18L175.5,72.76l-7.9-9.65c-0.93-1.13-2.6-1.3-3.73-0.37
        c-1.13,0.93-1.3,2.6-0.37,3.73l9.82,12c0.48,0.59,1.2,0.95,1.97,0.97c0.03,0,0.06,0,0.09,0c0.74,0,1.44-0.31,1.94-0.84l16.41-17.63
        V88.8h-36.44V52.37h36.44L193.73,53.18L193.73,53.18z" fill="currentColor" />
      <path class="st0" d="M202.89,118.65l-4.45,4.78c-0.49-0.63-1.21-1.06-2.06-1.06h-41.74c-1.46,0-2.65,1.19-2.65,2.65v41.74
        c0,1.46,1.19,2.65,2.65,2.65h41.74c1.46,0,2.65-1.19,2.65-2.65v-36.18l7.75-8.32c1-1.07,0.94-2.75-0.13-3.75
        C205.57,117.52,203.89,117.58,202.89,118.65z M193.73,128.5l-18.23,19.58l-7.9-9.65c-0.93-1.13-2.6-1.3-3.73-0.37
        c-1.13,0.93-1.3,2.6-0.37,3.73l9.82,12c0.48,0.59,1.2,0.95,1.97,0.97c0.03,0,0.06,0,0.08,0c0.74,0,1.44-0.31,1.94-0.84l16.41-17.63
        v27.83h-36.44v-36.44h36.44L193.73,128.5L193.73,128.5z" fill="currentColor" />
      <path class="st0" d="M141.69,0.27C63.54,0.27-0.04,63.85-0.04,142s63.58,141.73,141.73,141.73S283.42,220.15,283.42,142
        S219.84,0.27,141.69,0.27z M141.69,278.43C66.46,278.43,5.26,217.23,5.26,142S66.46,5.57,141.69,5.57S278.12,66.77,278.12,142
        S216.92,278.43,141.69,278.43z" fill="currentColor" />
      <path class="st0" d="M110.22,59.18c9.13,0,16.56-7.43,16.56-16.56s-7.43-16.55-16.56-16.55S93.67,33.5,93.67,42.62
        C93.67,51.75,101.09,59.18,110.22,59.18z M110.22,31.53c6.12,0,11.1,4.98,11.1,11.09c0,6.12-4.98,11.09-11.1,11.09
        c-6.12,0-11.09-4.98-11.09-11.09C99.13,36.5,104.1,31.53,110.22,31.53z" fill="currentColor" />
      <path class="st0" d="M110.22,59.97c-14.78,0-26.81,12.03-26.81,26.81v4.63c0,1.51,1.22,2.73,2.73,2.73h48.15
        c1.51,0,2.73-1.22,2.73-2.73v-4.63C137.03,71.99,125,59.97,110.22,59.97z M110.22,65.43c11.77,0,21.35,9.58,21.35,21.35v1.9H88.88
        v-1.9C88.88,75,98.45,65.43,110.22,65.43z" fill="currentColor" />
      <path class="st0" d="M126.78,117.77c0-9.13-7.43-16.55-16.56-16.55s-16.55,7.43-16.55,16.55c0,9.13,7.43,16.56,16.55,16.56
        C119.35,134.33,126.78,126.9,126.78,117.77z M110.22,106.68c6.12,0,11.1,4.98,11.1,11.09c0,6.12-4.98,11.09-11.1,11.09
        c-6.12,0-11.09-4.98-11.09-11.09C99.13,111.66,104.1,106.68,110.22,106.68z" fill="currentColor" />
      <path class="st0" d="M110.22,135.12c-14.78,0-26.81,12.03-26.81,26.81v4.63c0,1.51,1.22,2.73,2.73,2.73h48.15
        c1.51,0,2.73-1.22,2.73-2.73v-4.63C137.03,147.14,125,135.12,110.22,135.12z M110.22,140.58c11.77,0,21.35,9.58,21.35,21.35v1.9
        H88.88v-1.9C88.88,150.15,98.45,140.58,110.22,140.58z" fill="currentColor" />
      <path class="st0" d="M126.78,190.73c0-9.13-7.43-16.55-16.56-16.55s-16.55,7.43-16.55,16.55c0,9.13,7.43,16.56,16.55,16.56
        C119.35,207.29,126.78,199.86,126.78,190.73z M110.22,179.64c6.12,0,11.1,4.98,11.1,11.09c0,6.12-4.98,11.09-11.1,11.09
        c-6.12,0-11.09-4.98-11.09-11.09C99.13,184.61,104.1,179.64,110.22,179.64z" fill="currentColor" />
      <path class="st0" d="M110.22,208.08c-14.78,0-26.81,12.03-26.81,26.81v4.63c0,1.51,1.22,2.73,2.73,2.73h48.15
        c1.51,0,2.73-1.22,2.73-2.73v-4.63C137.03,220.1,125,208.08,110.22,208.08z M110.22,213.54c11.77,0,21.35,9.58,21.35,21.35v1.9
        H88.88v-1.9C88.88,223.11,98.45,213.54,110.22,213.54z" fill="currentColor" />
      <path class="st0" d="M202.89,191.56l-4.45,4.78c-0.49-0.63-1.21-1.06-2.06-1.06h-41.74c-1.46,0-2.65,1.19-2.65,2.65v41.74
        c0,1.46,1.19,2.65,2.65,2.65h41.74c1.46,0,2.65-1.19,2.65-2.65V203.5l7.75-8.32c1-1.07,0.94-2.75-0.13-3.75
        C205.57,190.43,203.89,190.49,202.89,191.56z M193.73,201.41l-18.23,19.58l-7.9-9.65c-0.93-1.13-2.6-1.3-3.73-0.37
        c-1.13,0.93-1.3,2.6-0.37,3.73l9.82,12c0.48,0.59,1.2,0.95,1.97,0.97c0.03,0,0.06,0,0.08,0c0.74,0,1.44-0.31,1.94-0.84l16.41-17.63
        v27.83h-36.44v-36.44h36.44L193.73,201.41L193.73,201.41z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'CheckResponseIcon'
})
</script>
