import { SurveyBlockIndex } from '@/types/Survey'
import Vue from 'vue'
import { Module } from 'vuex'
import { Survey } from '@/API'
import { updateSurvey } from '@/graphql/mutations'
import { getSurveysByQuestionnaireId } from '@/graphql/custom/surveysByQuestionnaireId'
import { getSurvey } from '@/graphql/queries'
import API from '@/services/API'
import { PlatformQuestionnaire, PlatformSurvey } from '@betterboards/shared/types/Platform'
import sortByDisplayOrder from '@betterboards/shared/helpers/util/sortByDisplayOrder'

type Optional<T> = T | undefined

interface SurveyState {
  survey: Optional<Survey>
  sectionIndex: Optional<number>
  blockIndex: Optional<number>
  showSubmitStep: boolean
}

const defaultState = (): SurveyState => ({
  survey: undefined,
  sectionIndex: 0,
  blockIndex: 0,
  showSubmitStep: false
})

const SurveyStore: Module<SurveyState, any> = {
  namespaced: true,
  state: defaultState(),
  getters: {
    targetUser (state: SurveyState) {
      if (state.survey?.individual) {
        return state.survey.individual
      }
    }
  },
  mutations: {
    reset: (state: SurveyState) => {
      const resetState = defaultState()
      Object.keys(resetState).forEach((key: string): void => {
        state[key] = resetState[key]
      })
    },
    setSurvey: (state: SurveyState, survey: any) => {
      if (typeof survey.response === 'string') {
        survey.response = JSON.parse(survey.response)
      }
      if (survey.sections?.items) {
        survey.sections.items = survey.sections.items
          .map((section) => {
            if (section.blocks?.items) {
              section.blocks.items = section.blocks.items.sort(sortByDisplayOrder)
            }
            return section
          })
          .sort(sortByDisplayOrder)
      }
      state.survey = survey
    },
    setQuestionIndex (state: SurveyState, { sectionIndex, blockIndex = 0 }: Partial<SurveyBlockIndex>) {
      if (sectionIndex !== undefined) {
        state.sectionIndex = sectionIndex
      }
      state.blockIndex = blockIndex
    },
    setShowSubmitStep (state: SurveyState, showSubmitStep: boolean): void {
      state.showSubmitStep = showSubmitStep
    }
  },
  actions: {
    async setup ({ commit, rootState, dispatch }, surveyId) {
      const cognitoUser = rootState.User.entity

      await dispatch('initSurvey', { surveyId, cognitoId: cognitoUser.username })
      console.log('ready to go', cognitoUser)
    },
    async initSurvey ({ commit }, { surveyId, cognitoId }) {
      try {
        const surveyResponse = await API.graphql({
          query: getSurvey,
          variables: {
            id: surveyId
          }
        })

        const survey: PlatformSurvey = surveyResponse.data.getSurvey as unknown as PlatformSurvey
        const companyId: string | undefined = survey.companyId
        if (!companyId) {
          throw new Error('Failed to fetch Survey as no Company ID was found.')
        }

        const surveyDocument: PlatformQuestionnaire = await API.get<PlatformQuestionnaire>(
          'backendfunctions',
          `/${companyId}/files?objectType=surveyDocument&objectId=${survey.id}`
        )
        if (!surveyDocument?.id) {
          throw new Error('Failed to fetch Survey Document.')
        }

        survey.questionnaire = surveyDocument
        commit('setSurvey', survey)
      } catch (e) {
        console.error('Unable to set survey', e)
        Vue.toasted.error('Unable to fetch questionnaire, try again later')
      }
    },
    async fetchReleases ({ commit }, questionnaireId: string) {
      try {
        const response = await API.graphql({
          query: getSurveysByQuestionnaireId,
          variables: {
            questionnaireId
          }
        })
        return response.data.surveysByQuestionnaireId
      } catch (e) {
        console.error('Unable to get questionnaire surveys', e)
        Vue.toasted.error('Unable to fetch questionnaire releases, try again later')
      }
    },
    async updateSurvey (state, survey) {
      await API.graphql({
        query: updateSurvey,
        variables: {
          input: {
            ...survey,
            totals: {
              ...(survey?.totals ?? {}),
              __typename: undefined
            }
          }
        }
      })
    }
  }
}

export default SurveyStore
