import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getFullReport = /* GraphQL */ `
  query GetFullReport($id: ID!) {
    getReport(id: $id) {
      id
      companyId
      surveyGroupId
      comparisonSurveyGroupId
      createdAt
      updatedAt
      variantCode
      owner
      summary
      focusAreas
      configuration
      sections {
        items {
          id
          companyId
          reportId
          sectionId
          variantCode
          summary
          introduction
          subSectionIntroductions {
            slug
            value
            reviewedAt
          }
          results
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
` as GeneratedQuery<
  APITypes.GetFullReportQueryVariables,
  APITypes.GetFullReportQuery
>
