import { PartialReportBenchmark, ReportBenchmark, ReportBenchmarkCondition } from '../../types/ReportBenchmark'
import { ScoreCollection } from '../entities/Result/scores'
import { PlatformCompany } from '../../types/Company'
import { $t } from '../../translations/index'
import { StatsResult, V2StatisticsQuery } from '../../types/Statistic'
import { CompanyStatsCollection } from './index'
import {
  isV1ComparisonReportBenchmark,
  isV1OrganisationReportBenchmark,
  isV1UniverseReportBenchmark,
  isV2OrganisationReportBenchmark,
  isV2UniverseReportBenchmark
} from './ReportBenchmark'
import { getScoreCollectionTitle } from '../entities/Result/getScoreCollectionTitle'
import { PlatformReport } from '../../types/Report'
import getUniverseBenchmarkSubTitle from './benchmarks/getUniverseBenchmarkSubTitle'

/**
 * Takes in a ReportBenchmark with a specific collection and adds the default info for that collection.
 *  This is used for converting a ReportBenchmark (from a v2 Statistics Query or the ReportGroup.scores array) into
 *  a format ready for display in the Analysis/Report.
 */
export function getReportBenchmarkDisplayInfo <
  S extends PartialReportBenchmark | ReportBenchmarkCondition = ReportBenchmark
> (
  score: S,
  organizationCompany?: PlatformCompany,
  report?: PlatformReport
): ReportBenchmark | undefined {
  const collection: ScoreCollection = score.collection
  const version: '1' | '2' | undefined = score.version
  let title: string = score.title ?? getScoreCollectionTitle(collection, report)
  let subTitle: string | undefined = score.subTitle

  if (isV1ComparisonReportBenchmark(score)) {
    return {
      icon: 'comparisons',
      ...score,
      title
    }
  }

  if (isV1OrganisationReportBenchmark(score) || isV2OrganisationReportBenchmark(score)) {
    let scoreCompanyId: string | undefined = score.companyId
    const scores: StatsResult[] = score.scores ?? []

    if (isV2OrganisationReportBenchmark(score)) {
      // Get Company ID from v2 Org Score queries if it is not set
      const orgQuery: V2StatisticsQuery | undefined = version
        ? score.queries?.find((q) => q.field === CompanyStatsCollection.Organisation)
        : undefined
      const organizationId: string | undefined = orgQuery?.value
      if (!organizationId) {
        return undefined
      }
      if (!organizationCompany || organizationCompany.id !== organizationId) {
        return undefined
      }
      scoreCompanyId = organizationId
      title = score.title ?? $t('analysis.results.v2OrganisationBenchmarkText', [organizationCompany.name])
    } else if (scoreCompanyId && scoreCompanyId === organizationCompany?.id) {
      subTitle = subTitle ?? organizationCompany.name
    }

    // v1 and v2 Org Scores
    return {
      ...score,
      scores,
      title,
      subTitle,
      companyId: scoreCompanyId
    }
  }

  if (isV1UniverseReportBenchmark(score) || isV2UniverseReportBenchmark(score)) {
    let subTitle: string | undefined

    if (score.queries) {
      subTitle = getUniverseBenchmarkSubTitle(score)
    }

    return {
      ...score,
      title,
      subTitle: score.subTitle ?? subTitle,
      icon: 'universe'
    }
  }

  return {
    enabled: false,
    icon: 'scores',
    ...(score as ReportBenchmark),
    title
  }
}
