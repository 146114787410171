<template>
  <platform-menu
    v-model="menuActive"
    :max-width="500"
    :disabled="disabled"
  >
    <template #activator="{ on, attrs }">
      <span
        v-bind="attrs"
        v-on="on"
      >
        <platform-button
          icon="target-user"
          class="my-3"
          :secondary="isValid"
          :warning="!isValid"
          :disabled="disabled"
        >
          {{ labelText }} ({{ validTargetRules ? validTargetRules.length : 0 }})
        </platform-button>
      </span>
    </template>
    <template #default>
      <v-row no-gutters align="center" class="d-inline-block mb-3">
        <strong class="primary--text">
          {{ validTargetRules ? validTargetRules.length : 0 }}
        </strong>
        <span>
          {{ $t('form.questionnaire.targetRules.menu.title') }}
        </span>
        <span class="text-body-2 grey--text text--darken-2">
          (<strong :class="targetRulesValidityTextClasses">{{ invalidTargetRulesCount }}</strong>
          {{ $t('form.questionnaire.targetRules.menu.titleInvalid') }})
        </span>
      </v-row>
      <v-row
        v-for="(targetRule, index) in targetRules"
        :key="index"
        no-gutters
        class="flex-nowrap py-1"
        align="end"
      >
        <v-col class="grow">
          <target-rule-form
            v-model="targetRules[index]"
            :criteria-value="criteriaValue"
          />
        </v-col>
        <v-col class="shrink">
          <platform-icon
            color="warning"
            name="delete"
            class="ml-2"
            @click="removeTargetRule(index)"
          />
        </v-col>
      </v-row>
      <v-row no-gutters justify="end" class="pt-3 pb-1">
        <platform-button
          primary
          text
          icon="create"
          v-tooltip:bottom="$t('form.questionnaire.targetRules.menu.addRuleTooltip')"
          @click="addTargetRule"
        />
        <platform-button
          text
          icon="check-outline"
          :primary="isValid"
          :warning="!isValid"
          @click="closeMenu"
        />
      </v-row>
    </template>
  </platform-menu>
</template>

<script lang="ts">
import { RepeatForOptions } from '@/helpers/questionnaire/questions'
import Vue, { PropType } from 'vue'
import { QuestionTargetRule, QuestionTargetRules } from '@betterboards/shared/types/Block'
import TargetRuleForm from '@/components/forms/blocks/target-rules/TargetRuleForm.vue'
import PlatformMenu from '@/components/shared/inputs/PlatformMenu.vue'

export default Vue.extend({
  name: 'TargetRulesForm',
  props: {
    value: { type: Array as PropType<QuestionTargetRules>, required: false },
    label: { type: String, required: false },
    criteriaValue: { type: String as PropType<RepeatForOptions>, required: false },
    disabled: { type: Boolean, default: false }
  },
  components: {
    TargetRuleForm,
    PlatformMenu
  },
  data: () => ({
    menuActive: false,
    targetRules: <QuestionTargetRules>[]
  }),
  mounted (): void {
    if (this.value?.length) {
      this.targetRules.splice(
        0,
        this.targetRules.length,
        ...this.value
      )
    }
  },
  watch: {
    validTargetRules (validTargetRules: QuestionTargetRules): void {
      this.$emit('input', validTargetRules)
    },
    menuActive (val: boolean) {
      if (!val) {
        const indexesToRemove = this.targetRules
          .map((rule: QuestionTargetRule, index: number) => !rule.type ? index : -1)
          .filter((index: number) => index !== -1)

        const newTargetRules: QuestionTargetRules = this.targetRules.filter(
          (rule: QuestionTargetRule, i: number) => !indexesToRemove.includes(i)
        )

        this.targetRules.splice(
          0,
          this.targetRules.length,
          ...newTargetRules
        )
      }
    }
  },
  computed: {
    validTargetRules (): QuestionTargetRules {
      return this.targetRules.filter(
        (r: QuestionTargetRule) => !!r.filter && !!r.type && !!r.value
      )
    },
    invalidTargetRulesCount (): number {
      return this.targetRules.length - this.validTargetRules.length
    },
    labelText (): string {
      return this.label ?? this.$t('form.questionnaire.targetRules.editRulesAction') as string
    },
    isValid (): boolean {
      return this.targetRules.length === this.validTargetRules.length
    },
    targetRulesValidityTextClasses (): any {
      return {
        'warning--text': this.invalidTargetRulesCount
      }
    }
  },
  methods: {
    addTargetRule (): void {
      this.targetRules.push(
        {
          type: undefined,
          filter: undefined,
          value: undefined
        } as unknown as QuestionTargetRule
      )
    },
    removeTargetRule (index: number): void {
      this.targetRules.splice(index, 1)
    },
    closeMenu (): void {
      if (!this.isValid) {
        return
      }
      this.menuActive = false
    }
  }
})
</script>
