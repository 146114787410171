<template>
  <div
    class="grow d-flex"
    data-test-target="platformEntityCard"
    :class="{
      'flex-row': grid,
      'flex-column': !grid,
    }"
  >
    <v-card
      class="grow entity d-flex"
      :flat="flat"
      :hover="hover"
      :to="to"
      :class="cardClasses"
      :ripple="!!$listeners.click"
      v-on="$listeners.click ? { click: (evt) => $emit('click', evt) } : undefined"
    >
      <v-row
        v-if="showImage"
        no-gutters
        :justify="align"
        :class="{
          grow: grid,
          shrink: !grid
        }"
      >
        <div
          style="position: relative;"
          :class="{
            'ml-3': !compact && (align === 'start' || align === 'center'),
            'mr-3': !compact && (align === 'end' || align === 'center'),
            'my-3': !compactHeader && !compact,
            'mx-3': !compactHeader && compact,
            'pa-1': !compactHeader && !displayCompactButtons,
            'mt-2': compactHeader,
          }"
        >
          <platform-icon
            v-if="$listeners.edit && bubbleEdit"
            name="edit"
            color="primary"
            class="bubble-action rounded-circle"
            :size="12"
            @click.stop="(evt) => $emit('edit', evt)"
          />
          <platform-avatar
            v-if="image"
            :listen="avatarListen"
            :entity="entity"
            :entity-type="entityType"
            :pad-bottom="false"
            :size="avatarSize"
            :placeholder-size="placeholderSize"
          />
          <slot name="headerImage" />
        </div>
      </v-row>

      <v-row
        no-gutters
        class="overflow-hidden"
        :class="{
          'pt-2': listMembers && !inlineListMembers
        }"
        :align="row ? 'center' : undefined"
        :justify="compact ? undefined : 'space-between'"
      >
        <v-col
          class="grow px-3 overflow-hidden"
          :class="{
            'pt-3': inlineHeader && !compact,
            'pb-2': grid || !showActions
          }"
          :cols="inlineActions ? 10 : undefined"
          :order="1"
        >
          <v-row v-if="!hideTitle && (title || createdAt)" :justify="align" no-gutters>
            <v-col class="flex-grow-1 text-break">
              <v-row v-if="title" align="start" :justify="align" no-gutters>
                <span
                  class="entity-title overflow-ellipses"
                  data-test-target="platformEntityCardTitle"
                  v-tooltip="title"
                  :class="{
                    'single-line': row && $vuetify.breakpoint.smAndUp
                  }"
                >
                  {{ title }}
                </span>
              </v-row>
              <v-row v-if="createdAt" align="center" :justify="align" no-gutters>
                <span
                  class="text-caption grey--text text--darken-1"
                  v-tooltip="updatedAt ? `${$t('platformEntityCard.lastUpdated')} ${updatedAt}` : undefined"
                >
                  {{ $t('platformEntityCard.created') }} <platform-date-display :date-time="createdAt" />
                </span>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="subTitle && !hideSubTitle" no-gutters :justify="align">
            <v-card-subtitle class="shrink pt-1 pb-0 pl-2">
              <v-row align="center" :justify="align" no-gutters>
                <platform-icon
                  v-if="subTitleIcon"
                  :size="24"
                  :name="subTitleIcon"
                  class="mr-1"
                />
                <span>
                  {{ subTitle }}
                </span>
              </v-row>
            </v-card-subtitle>
          </v-row>
          <v-row v-if="$slots.default" align="center" :justify="align" no-gutters>
            <slot name="default" />
          </v-row>
        </v-col>

        <v-col
          v-if="listMembers"
          :class="{
            'shrink pr-4': !inlineActions,
            'grow px-3': inlineActions,
          }"
          :cols="inlineListMembers ? undefined : 12"
          :order="inlineActions ? 5 : 2"
        >
          <v-row justify="end" no-gutters>
            <individuals-preview-list
              :individuals="entityMembers"
              :company-id="companyId"
              :loading="loadingMembers"
            />
          </v-row>
        </v-col>

        <v-col
          v-if="statuses"
          class="px-2"
          :cols="inlineActions && inlineListMembers ? 6 : 12"
          :order="showActions && inlineActions ? 4 : 3"
        >
          <v-row no-gutters :justify="align" align="end" class="pb-1">
            <v-col
              v-for="(status, index) in statuses"
              :key="index"
              class="shrink"
            >
              <status-button
                :value="status"
                :primary="status.primary"
                :secondary="status.secondary"
                v-on="$listeners[status.clickEvent] ? { click: () => $emit(status.clickEvent, status.value) } : undefined"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col
          v-if="showActions"
          class="shrink"
          :cols="inlineActions ? 2 : 12"
          :order="3"
        >
          <v-card-actions
            :class="{
              'pt-0': grid,
              'py-0': compact
            }"
          >
            <v-row
              no-gutters
              class="align-start"
              :class="{
                'justify-center': !listMembers || mobileView,
                'justify-end': listMembers && !mobileView,
              }"
            >
              <v-col :class="{
                'flex-grow-0': mobileView
              }">
                <v-row
                  no-gutters
                  justify="end"
                  :class="{
                    'flex-column flex-nowrap': mobileView,
                    'flex-nowrap': inlineActions
                  }"
                >
                  <v-col
                    v-if="$listeners.delete"
                    :class="{ 'pa-1': !displayCompactButtons }"
                    :order="1"
                    class="shrink d-flex"
                  >
                    <platform-button
                      warning
                      icon="delete"
                      :loading="deleting"
                      :disabled="deleting"
                      :text="displayCompactButtons"
                      @click="showDeleteConfirmation"
                      class="grow"
                    >
                      <template v-if="!displayCompactButtons">
                        {{ $t('global.delete') }}
                      </template>
                    </platform-button>
                  </v-col>
                  <v-col v-if="!inlineActions" :order="2" class="grow d-flex">
                    <v-spacer v-if="$listeners.delete && ($slots.actions || ($listeners.edit && !bubbleEdit))" />
                  </v-col>
                  <v-col :order="3" class="shrink d-flex">
                    <slot name="actions" />
                  </v-col>
                  <v-col :order="mobileView ? 6 : 4" class="shrink d-flex">
                    <slot name="primaryActions" />
                  </v-col>
                  <v-col
                    :order="5"
                    class="shrink d-flex"
                    v-if="$listeners.edit && !bubbleEdit"
                    :class="{ 'pa-1': !displayCompactButtons }"
                  >
                    <platform-button
                      class="grow"
                      secondary
                      icon="edit"
                      :text="displayCompactButtons"
                      @click="(evt) => $emit('edit', evt)"
                    >
                      <template v-if="!displayCompactButtons">
                        {{ $t('global.edit') }}
                      </template>
                    </platform-button>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>
    <confirmation-modal
      v-if="deleteConfirmationVisible"
      action="delete"
      :entity="entity"
      :entity-type="entityType"
      @confirm="confirmDelete"
      @cancel="deleteConfirmationVisible = false"
    />
  </div>
</template>

<script lang="ts">
import StatusButton from '@/components/shared/StatusButton.vue'
import { SurveyGroupProgress } from '@/types/SurveyGroup'
import dayjs from 'dayjs'
import Vue, { PropType } from 'vue'
import { EntityStatus, PlatformEntityStatuses, PlatformEntityTypes } from '@/types/Platform'
import { getFullName } from '@/helpers/individual'
import PlatformDateDisplay, { LongDateTimeDisplayFormat } from '@/components/PlatformDateDisplay.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import IndividualsPreviewList from '@/components/individuals/IndividualsPreviewList.vue'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { PlatformTeamIndividual } from '@betterboards/shared/types/Team'

export default Vue.extend({
  components: {
    StatusButton,
    IndividualsPreviewList,
    PlatformDateDisplay,
    ConfirmationModal,
    PlatformAvatar
  },
  props: {
    entityType: { type: String as PropType<PlatformEntityTypes>, required: true },
    align: { type: String as PropType<'start' | 'center' | 'end'>, default: 'center' },
    entity: { type: Object as PropType<any>, required: true },
    visibleStatuses: { type: Array as PropType<PlatformEntityStatuses[]>, required: false },
    image: { type: Boolean, default: true },
    to: { type: Object as PropType<any>, required: false },
    deleting: { type: Boolean, required: false },
    compact: { type: Boolean, default: false },
    compactButtons: { type: Boolean, default: false },
    compactHeader: { type: Boolean, default: false },
    grid: { type: Boolean, default: false },
    row: { type: Boolean, default: false },
    inlineHeader: { type: Boolean, default: true },
    flat: { type: Boolean, default: false },
    innerMargin: { type: Number, default: 6 },
    hover: { type: Boolean, default: false },
    listMembers: { type: Boolean, default: false },
    bubbleEdit: { type: Boolean, default: false },
    placeholderSize: { type: String as PropType<'xl' | 'lg' | 'md' | 'sm' | 'xs'>, default: 'md' },
    avatarSize: { type: Number, required: false },
    hideTitle: { type: Boolean, default: false },
    hideSubTitle: { type: Boolean, default: false },
    inlineActions: { type: Boolean, default: false },
    loadingMembers: { type: Boolean, default: false },
    avatarListen: { type: Boolean, default: false }
  },
  data () {
    return {
      timerId: null,
      formData: null,
      deleteConfirmationVisible: false,
      PlatformEntityTypes
    }
  },
  computed: {
    showImage (): boolean {
      if (this.$slots.headerImage) {
        return true
      }
      if (!this.image) {
        return false
      }
      if (this.entityType === PlatformEntityTypes.Committee) {
        return false
      }
      return true
    },
    companyId (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.Committee:
        case PlatformEntityTypes.Individual:
        case PlatformEntityTypes.Questionnaire:
        case PlatformEntityTypes.SurveyGroup:
          return this.entity.companyId
        case PlatformEntityTypes.Company:
        case PlatformEntityTypes.CompanyUser:
          return this.entity.id
      }
      return undefined
    },
    title (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
          return getFullName(this.entity.individual ?? this.entity) ?? undefined
        case PlatformEntityTypes.User:
          return this.entity.displayName
        case PlatformEntityTypes.CompanyUser:
          return this.entity.company?.name
        case PlatformEntityTypes.Company:
        case PlatformEntityTypes.Committee:
        case PlatformEntityTypes.Questionnaire:
        case PlatformEntityTypes.SurveyGroup:
        case PlatformEntityTypes.Package:
          return this.entity.name
      }
      return undefined
    },
    subTitleIcon (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.Committee:
          return 'users'
      }
      return undefined
    },
    subTitle (): string | undefined {
      if (this.hideSubTitle) {
        return undefined
      }
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
          return undefined
        case PlatformEntityTypes.Company:
          if (this.compact) {
            return undefined
          }
          return this.entity.sector
        case PlatformEntityTypes.Committee:
          return `${this.entity.individuals?.items?.length ?? 0} members`
      }
      return undefined
    },
    statuses (): EntityStatus[] | undefined {
      let statuses: EntityStatus[] | undefined
      switch (this.entityType) {
        case PlatformEntityTypes.SurveyGroup:
          if (this.entity.versions > 1) {
            const surveyGroup = this.entity as SurveyGroupProgress
            statuses = [
              {
                status: PlatformEntityStatuses.Versions,
                text: this.$t('questionnaire.versions') as string,
                value: surveyGroup.versions,
                clickEvent: 'displayVersions',
                clickTooltip: this.$t('questionnaire.versionsTooltip', [surveyGroup.versions]) as string,
                icon: 'comparisons'
              }
            ]
          }
          break
        case PlatformEntityTypes.Individual:
          if (this.entity.teams?.items?.length > 0 && this.visibleStatuses?.includes(PlatformEntityStatuses.Team)) {
            const individual = this.entity as PlatformIndividual
            statuses = individual.teams?.items?.map((team: PlatformTeamIndividual) => {
              const teamName = this.$t(`global.companyTeams.${team.teamId}`) as string
              return {
                status: PlatformEntityStatuses.Team,
                text: teamName,
                value: team.teamId,
                hideValue: true,
                tooltip: this.$t('platformEntityCard.individual.teamTooltip', [individual.preferredName, teamName]) as string,
                clickEvent: 'manageTeam',
                clickTooltip: this.$t('platformEntityCard.individual.manageTeamTooltip', [individual.preferredName, teamName]) as string,
                icon: 'team'
              }
            })
          }
          break
      }
      return statuses
    },
    createdAt (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.SurveyGroup:
          return this.entity.releasedAt ?? this.entity.createdAt
        case PlatformEntityTypes.Questionnaire:
          return this.entity.createdAt
      }
      return undefined
    },
    updatedAt (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.Questionnaire:
        case PlatformEntityTypes.SurveyGroup:
          return dayjs(this.entity.updatedAt).format(LongDateTimeDisplayFormat)
      }
      return undefined
    },
    cardClasses (): any {
      const classes: any = {
        'flex-row': this.inlineHeader,
        'flex-column justify-space-between': !this.inlineHeader
      }
      if (this.align === 'center') {
        classes['text-center'] = true
      }
      if (this.innerMargin) {
        classes[`mb-${this.innerMargin}`] = true
      }
      return classes
    },
    showActions (): boolean {
      return !!(this.$listeners.delete || this.$slots.actions || (this.$listeners.edit && !this.bubbleEdit))
    },
    entityMembers (): any[] {
      if (!this.entity?.individuals?.items) {
        return []
      }
      return this.entity.individuals.items
    },
    inlineListMembers (): boolean {
      return this.$vuetify.breakpoint.lgAndUp
    },
    displayCompactButtons (): boolean {
      return !!this.compact || !!this.compactButtons
    },
    mobileView (): boolean {
      return this.$vuetify.breakpoint.xsOnly && !this.displayCompactButtons
    }
  },
  methods: {
    showDeleteConfirmation (): void {
      this.deleteConfirmationVisible = true
    },
    confirmDelete (evt): void {
      this.$emit('delete', evt)
      this.deleteConfirmationVisible = false
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.entity {
  .entity-title {
    word-break: keep-all;
    &.single-line {
      white-space: nowrap;
    }
  }
}
.avatar-shield {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.bubble-action {
  position: absolute;
  top: 1px;
  right: 1px;
  padding: 3px;
  z-index: 1;
  border: 1px solid var(--v-primary-base);
  background-color: var(--v-grey-lighten5);
  transition: background-color ease-in-out 300ms;
  &:hover {
    background-color: var(--v-grey-lighten3);
  }
}
</style>
