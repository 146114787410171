import { CriteriaGroup, CriteriaItem } from '../../../types/Content'

export default function updateCriteriaItem (
  criteriaItem: CriteriaItem,
  criteriaGroupSlug: string,
  changedCriteriaGroupItems: CriteriaGroup[]
): CriteriaGroup[] {
  let groupIndex: number = changedCriteriaGroupItems.findIndex(
    (cg: CriteriaGroup): boolean => cg.slug === criteriaGroupSlug
  )
  if (groupIndex === -1) {
    groupIndex = changedCriteriaGroupItems.push({
      slug: criteriaGroupSlug,
      items: []
    }) - 1
  }
  const itemIndex: number = changedCriteriaGroupItems[groupIndex].items.findIndex(
    (ci: CriteriaItem) => ci.slug === criteriaItem.slug && ci.variantCode === criteriaItem.variantCode
  )
  if (itemIndex === -1) {
    changedCriteriaGroupItems[groupIndex].items.push(criteriaItem)
    return changedCriteriaGroupItems
  }
  changedCriteriaGroupItems[groupIndex].items.splice(itemIndex, 1, criteriaItem)

  return changedCriteriaGroupItems
}
