<template>
  <div class="report-preview__page" :class="{ 'page-margin': pageMargin }">
    <div class="page__header-container">
      <report-preview-page-header
        :title="title"
        :sub-title="subTitle"
      />
    </div>
    <div class="page__content">
      <slot name="default" />
    </div>
    <div class="page__footer-container">
      <report-preview-page-footer />
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import ReportPreviewPageHeader from '@/components/analysis/report-previews/partials/PageHeader.vue'
import ReportPreviewPageFooter from '@/components/analysis/report-previews/partials/PageFooter.vue'

export default Vue.extend({
  name: 'ReportPreviewPage',
  components: {
    ReportPreviewPageFooter,
    ReportPreviewPageHeader
  },
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, required: false },
    pageMargin: { type: Boolean, default: true }
  }
})
</script>

<style lang="scss" scoped="scoped">
.report-preview__page {
  width: 325px;
  height: 460px;
  min-height: 460px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  font-size: 8px;
  line-height: 1.2;
  padding-top: 12px;

  .page__header-container {
    flex-grow: 0;
    padding: 0 12px;
  }
  .page__content {
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .page__footer-container {
    flex-grow: 0;
    padding: 0 12px;
  }

  &.page-margin {
    padding-left: 12px;
    padding-right: 12px;

    .page__header-container {
      padding: 0;
    }
    .page__footer-container {
      padding: 0;
    }
  }
}
</style>
