<template>
  <v-container id="login-container" fill-height fluid primary :style="`background-image: url(${background})`">
    <v-row class="justify-center align-center">
      <v-col
        class="d-flex flex-column align-center"
        justify="center"
        :xl="3"
        :lg="4"
        :md="5"
        :sm="8"
      >
        <v-img slot="greetings" alt="BetterBoards" class="mb-5" contain height="78px" position="center" :src="logo" />
        <div class="auth-card">
          <component
            :is="page.component"
            v-bind="{ mfaType }"
            @signIn="changeLoginPage('sign-in')"
            @resetPassword="changeLoginPage('reset-password')"
            @mfa="(val) => changeLoginPage('mfa', val)"
            @changeTemporaryPassword="changeLoginPage('change-temporary-password')"
          />
        </div>
        <p class="white--text caption">
          <a href="https://better-boards.com/privacy-policy/" target="_blank" class="white--text">Privacy Policy &amp; Terms of Service</a>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import { getCurrentUser } from 'aws-amplify/auth'
import Background from '../assets/login-bg.png'
import Logo from '../assets/logo.png'
import LoginForm from '@/components/auth/LoginForm.vue'
import ResetPasswordForm from '@/components/auth/ResetPasswordForm.vue'
import MultiFactorAuthForm from '@/components/auth/MultiFactorAuthForm.vue'
import TemporaryPasswordChangeForm from '@/components/auth/TemporaryPasswordChangeForm.vue'
import { PlatformMFAType } from '@betterboards/shared/helpers/auth'

export interface LoginView {
  key: string
  component: string
}

export default Vue.extend({
  name: 'Auth',
  components: {
    LoginForm,
    ResetPasswordForm,
    MultiFactorAuthForm,
    TemporaryPasswordChangeForm
  },
  data () {
    return {
      logo: Logo,
      background: Background,
      loginPages: <LoginView[]>[
        { key: 'sign-in', component: 'LoginForm' },
        { key: 'reset-password', component: 'ResetPasswordForm' },
        { key: 'mfa', component: 'MultiFactorAuthForm' },
        { key: 'change-temporary-password', component: 'TemporaryPasswordChangeForm' }
      ],
      page: <LoginView | null>null,
      mfaType: <PlatformMFAType | undefined>undefined
    }
  },
  beforeCreate () {
    let redirect: string | undefined
    if (this.$route.query.redirect) {
      redirect = this.$route.query.redirect as string
    }
    const redirectTargetPath = redirect ? decodeURIComponent(redirect) : { name: 'Dashboard' }
    getCurrentUser()
      .then(() => this.$router.push(redirectTargetPath))
      .catch(() => ({}))
  },
  created () {
    this.page = this.loginPages[0]
  },
  methods: {
    changeLoginPage (key: string, mfa?: PlatformMFAType): void {
      const newPage: LoginView | undefined = this.loginPages.find((page: LoginView) => key === page.key)
      if (!newPage) {
        throw new Error(`Missing login page invalid key: ${key}`)
      }
      if (key === 'mfa') {
        if (mfa === PlatformMFAType.TOTP) {
          this.mfaType = PlatformMFAType.TOTP
        }
        if (mfa === PlatformMFAType.SMS) {
          this.mfaType = PlatformMFAType.SMS
        }
      }
      this.page = newPage
    }
  }
})
</script>

<style lang="scss">
#login-container {
  background-size: cover;
  background-repeat: no-repeat;
}

.login-header {
  font-family: sans-serif;
  font-weight: 700;
}

.login-links {
  font-size: 13px;
  a {
    color: var(--v-primary-base);
  }
}

.auth-card {
  display: flex;
  margin-bottom: 20px;
  background-color: white;
  padding: 35px 40px;
  text-align: left;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

</style>
