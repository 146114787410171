<template>
  <div class="pt-6 pb-8">
    <create-individual-modal
      v-if="creatingIndividual"
      :individual="creatingIndividual"
      @close="creatingIndividual = false"
      @created="individualCreated"
    />
    <v-row no-gutters justify="center">
      <platform-spinner v-if="loading" class="my-3 mx-auto" />
      <template v-else>
        <v-col
          v-for="individual in companyIndividuals"
          :key="individual.id"
          class="individual--column cursor-pointer"
          @mouseenter="focusIndividual(individual.id)"
          @mouseleave="unfocusIndividual"
          @click="toggleIndividual(individual)"
        >
          <platform-entity-card
            align="center"
            compact-header
            entity-type="individual"
            class="individual--card"
            :class="{
              'individual--card__hidden': focusing && focusedIndividualId === individual.id
            }"
            :entity="individual"
            :avatar-size="90"
            :inline-header="false"
            :image="false"
            :inner-margin="0"
            :visible-statuses="visibleEntityCardStatuses"
          >
            <span class="text-caption grey--text text--darken-1">
              {{ getJobTitle(individual) }}
            </span>
            <template #headerImage>
              <div
                class="text-center individual--avatar"
                :class="{
                  selected: isIndividualSelected(individual),
                  disabled: saving
                }"
                data-test-target="committeeFormAvatarField"
              >
                <platform-avatar
                  class="avatar"
                  entity-type="individual"
                  :entity="individual"
                  :size="100"
                />
              </div>
            </template>
          </platform-entity-card>
          <div
            class="individual--preview text-center"
          >
            <div
              class="text-center individual--avatar"
              :class="{
                selected: isIndividualSelected(individual),
                disabled: saving
              }"
              data-test-target="committeeFormAvatarField"
            >
              <platform-avatar
                class="avatar"
                entity-type="individual"
                :entity="individual"
                :size="100"
              />
            </div>
            <span>
              {{ getPreferredName(individual) }}
            </span>
          </div>
        </v-col>
        <div v-if="allowCreateIndividual" class="individual--column create-individual--column">
          <div
            v-tooltip="$t('page.individuals.selector.createTooltip')"
            class="create-individual--container d-flex flex-column align-center justify-center cursor-pointer"
            @click="openCreateIndividualModal"
          >
            <div class="create-individual--button elevation-3 rounded-circle mb-1">
              <platform-icon
                name="user-plus"
                color="primary lighten-1"
                :size="68"
                class="pa-4"
              />
            </div>
            <span class="create-individual--label text-body-2">
              New Participant
            </span>
          </div>
        </div>
      </template>
    </v-row>
  </div>
</template>

<script lang="ts">
import CreateIndividualModal from '@/components/individuals/CreateIndividualModal.vue'
import Vue, { PropType } from 'vue'
import { PlatformEntityStatuses } from '@/types/Platform'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { mapActions } from 'vuex'
import { getPreferredName } from '@betterboards/shared/helpers/entities/Individual/getPreferredName'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { getJobTitle } from '@/helpers/individual'

export default Vue.extend({
  components: {
    CreateIndividualModal,
    PlatformEntityCard,
    PlatformSpinner,
    PlatformAvatar
  },
  props: {
    value: { type: Array as PropType<string[]>, required: true },
    companyIndividuals: { type: Array as PropType<PlatformIndividual[]>, required: true },
    loading: { type: Boolean, required: false },
    saving: { type: Boolean, required: false },
    showCreate: { type: Boolean, default: false }
  },
  data: () => ({
    creatingIndividual: false,
    focusing: false,
    focusedIndividualId: <string | null>null
  }),
  watch: {
    isValid (val) {
      this.$emit('valid', val)
    }
  },
  computed: {
    selectedIndividualIds: {
      get (): string[] {
        return this.value
      },
      set (individualIds: string[]) {
        this.$emit('input', individualIds)
      }
    },
    isValid (): boolean {
      return !!this.selectedIndividualIds.length
    },
    visibleEntityCardStatuses (): PlatformEntityStatuses[] {
      return [PlatformEntityStatuses.Team]
    },
    /**
     * A button to create a new Individual is shown if a listener is provided for the created event, or if the showCreate prop is set.
     * */
    allowCreateIndividual (): boolean {
      return !!this.$listeners.created || !!this.showCreate
    }
  },
  methods: {
    ...mapActions('Individual', ['loadList', 'deleteIndividual']),
    getPreferredName,
    getJobTitle,
    toggleIndividual (individual: PlatformIndividual): void {
      if (!individual?.id) {
        throw new Error('Failed to toggle Individual checked status as an invalid item was provided.')
      }
      this.unfocusIndividual()
      const index = this.selectedIndividualIds.indexOf(individual.id)
      if (index === -1) {
        this.selectedIndividualIds.push(individual.id)
        return
      }
      this.selectedIndividualIds.splice(index, 1)
    },
    focusIndividual (individualId: string): void {
      if (this.focusedIndividualId && individualId === this.focusedIndividualId) {
        return
      }
      this.focusing = true
      this.focusedIndividualId = individualId
    },
    unfocusIndividual (): void {
      this.focusedIndividualId = null
      this.focusing = false
    },
    isIndividualSelected (individual: PlatformIndividual): boolean {
      return this.selectedIndividualIds.includes(individual.id)
    },
    fetchIndividuals (): void {
      this.loadList(true)
        .catch((err) => {
          this.$toasted.error(this.$t('form.wizard.boardMembers.fetchIndividualsNoResultsMessage') as string)
          console.error('Failed to re-fetch Individuals list', err)
        })
    },
    openCreateIndividualModal (): void {
      this.creatingIndividual = true
    },
    individualCreated (individual: PlatformIndividual): void {
      this.creatingIndividual = false
      this.fetchIndividuals()
      this.$emit('created', individual)
    }
  }
})
</script>

<style lang="scss">
.individual {
  $column-width: 175px;

  &--column {
    width: $column-width;
    flex-basis: $column-width;
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;
    padding: 8px 4px 4px !important;
    position: relative;
    min-height: 175px;

    &:hover {
      .individual--avatar:not(.disabled) {
        &.selected .avatar {
          border-width: 3px;
        }
        &:not(.selected) .avatar {
          border-width: 0;
        }
      }
    }
  }

  &--avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: filter 75ms linear;

    .avatar {
      position: relative;
      border-color: var(--color-admin-selected-highlight);
      border-style: solid;
      border-width: 0;
      transition: border-color ease-in-out 150ms, border-width ease-in-out 150ms, bottom ease-in-out 300ms;
      bottom: 0;
    }
    &:not(.disabled) {
      cursor: pointer;
    }
    &.selected .avatar {
      border-width: 4px;
      border-color: var(--color-admin-selected-highlight) !important;
      bottom: 2px;
    }
    &.disabled {
      filter: brightness(85%);
    }
  }

  &--card {
    display: flex;
    flex-direction: column;
    min-height: 200px;
    z-index: 10;
    position: absolute;
    top: 0;
    left: -8px;
    right: -8px;
    bottom: 0;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    &__hidden {
      opacity: 1;
    }
  }

  &--preview {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.create-individual--column {
  min-height: auto;
}
.create-individual--container {
  transition: background-color linear 100ms;
  &:hover {
    .create-individual--button {
      background-color: var(--v-grey-lighten2);
    }
    .create-individual--label {
      color: var(--v-grey-darken3);
    }
  }
  .create-individual--button {
    background-color: var(--v-grey-lighten5);
    background-clip: content-box;
    overflow: hidden;
    transition: background-color linear 100ms;
  }
  .create-individual--label {
    color: var(--v-grey-darken2);
    transition: color linear 100ms;
  }
}
</style>
