<template>
  <div class="report-group-scores--form d-flex flex-column overflow-hidden flex-grow-1 my-5">
    <manage-report-benchmarks-form
      v-model="reportScores"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import dayjs from 'dayjs'
import { PlatformReportGroup } from '@/types/Report'
import ManageReportBenchmarksForm from '@/components/forms/reports/benchmarks/ManageReportBenchmarksForm.vue'
import { ShortDateTimeDisplayFormat } from '@/components/PlatformDateDisplay.vue'
import { ReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'

export default Vue.extend({
  name: 'ReportBenchmarksForm',
  components: {
    ManageReportBenchmarksForm
  },
  props: {
    value: { type: Object as PropType<PlatformReportGroup>, required: true }
  },
  data: () => ({
    //
  }),
  computed: {
    reportGroup: {
      get (): PlatformReportGroup {
        return this.value
      },
      set (reportGroup: PlatformReportGroup) {
        this.$emit('input', reportGroup)
      }
    },
    reportScores: {
      get (): ReportBenchmark[] {
        if (!this.reportGroup?.scores?.length) {
          return []
        }
        return this.reportGroup.scores.map(
          (scoreGroup: ReportBenchmark): ReportBenchmark => {
            const displayUpdatedAt = dayjs(scoreGroup.updatedAt).format(ShortDateTimeDisplayFormat)
            return {
              ...scoreGroup,
              displayUpdatedAt
            }
          }
        )
      },
      set (scores: ReportBenchmark[]) {
        this.reportGroup = {
          ...this.reportGroup,
          scores
        }
      }
    }
  },
  methods: {
    // handleAddScores (reportScore: ReportBenchmark): void {
    //   this.addingScoresGroup = false
    //   reportScore.scores.sort(SortByDisplayOrder)
    //   this.$store.dispatch('Report/cacheReportBenchmark', { reportScore })
    // }
  }
})
</script>

<style lang="scss" scoped="scoped">
.report-group-scores--form {
  min-height: 320px;
}
</style>
