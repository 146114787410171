import { render, staticRenderFns } from "./DisplayMarkdown.vue?vue&type=template&id=1a8b7e21&scoped=true"
import script from "./DisplayMarkdown.vue?vue&type=script&lang=ts"
export * from "./DisplayMarkdown.vue?vue&type=script&lang=ts"
import style0 from "./DisplayMarkdown.vue?vue&type=style&index=0&id=1a8b7e21&prod&lang=scss&scoped=scoped"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a8b7e21",
  null
  
)

export default component.exports