import { EthnicDesignationGroupFriendlyNames, IndividualReligionFriendlyNames } from '../entities/Individual/index'

export enum CompanyStatsCollection {
  Ownership = 'ownership',
  Sector = 'sector',
  Country = 'country',
  EmployeeCount = 'employeeCount',
  Organisation = 'Organisation',
  PackageCategory = 'PackageCategory',
  MarketCap = 'marketCap'
}

export enum AverageProperty {
  EthnicDesignation = 'ethnicDesignation',
  Religion = 'religion',
  Gender = 'gender',
}

export function getCategoryFriendlyName (property: AverageProperty, category: string): IndividualReligionFriendlyNames | string {
  switch (property) {
    case AverageProperty.EthnicDesignation:
      return EthnicDesignationGroupFriendlyNames[category]
    case AverageProperty.Religion:
      return IndividualReligionFriendlyNames[category]
    default:
      return category
  }
}

export enum NotListedCompanyOwnershipStructure {
  PEBacked = 'PE-backed',
  VCBacked = 'VC-backed',
  PrivatelyOwned = 'Privately owned',
  FamilyOwned = 'Family Owned'
}

export enum PublicCompanyOwnershipStructure {
  NHS = 'NHS',
  CentralGovernment = 'Central Government',
  LocalGovernment = 'Local Government',
  HigherEducation = 'Higher Education',
  Schools = 'Schools',
  PrivatePublicServices = 'Privately-Run Public Services',
}

export const ThirdSectorOwnershipStructureId: string = 'ThirdSector'
