<template>
  <v-card
    class="d-flex"
    :class="{
      'package-preview__card-no-button': compact,
      'package-preview__card': !compact
    }"
    :hover="!!$listeners.click"
    :style="{
      width: $vuetify.breakpoint.smAndUp ? '700px' : undefined
    }"
    v-on="cardActions"
  >
    <v-row no-gutters align="stretch" class="flex-nowrap">
      <v-col class="shrink d-flex">
        <v-row
          no-gutters
          align="center"
          class="shrink pl-4 pr-1 pl-md-4 pr-md-3 pl-lg-7 pr-lg-3"
        >
          <v-col
            class="grow"
            :style="{
              width: iconSize
            }"
          >
            <platform-avatar
              v-if="hasSelectedPackage"
              entity-type="package"
              placeholder-size="xs"
              :pad-bottom="false"
              :size="iconSize"
              :entity="questionnairePackage"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="d-flex py-3 grow px-4">
        <v-row no-gutters class="flex-column flex-nowrap">
          <v-col class="shrink">
            <span
              class="font-weight-bold text-body-1"
              :class="{
                'text-body-2': $vuetify.breakpoint.xsOnly,
                'text-body-1': $vuetify.breakpoint.smAndUp
              }"
            >
              <template v-if="hasSelectedPackage">
                {{ questionnairePackage.name }}
              </template>
              <template v-else>
                {{ $t('packages.previewCard.noSelection.title') }}
              </template>
            </span>
          </v-col>
          <v-col class="flex-shrink-1 overflow-hidden">
            <span
              class="text-caption pr-2 package-description"
              v-tooltip="questionnairePackage.description"
            >
              <template v-if="hasSelectedPackage">
                {{ questionnairePackage.description }}
              </template>
              <template v-else>
                {{ $t('packages.previewCard.noSelection.description') }}
              </template>
            </span>
          </v-col>
          <v-col class="shrink">
            <div
              v-if="newGroupQuestionnaire"
              v-tooltip="comparisonTooltipText"
              class="text--compact"
            >
              <platform-icon
                name="comparisons"
                class="mr-1"
                color="primary"
                :size="20"
              />
              <span
                class="text-caption pr-2"
              >
                <strong>{{ $t('packages.previewCard.comparisonText') }} </strong>
              </span>
            </div>
            <v-row class="py-1" no-gutters>
              <v-col
                class="shrink pr-2"
                v-for="variant in questionnairePackage.variants"
                :key="variant"
              >
                <country-flag
                  :countryCode="variant"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-if="$listeners.click"
        class="shrink d-flex"
      >
        <v-row no-gutters align="center" class="shrink card-action">
          <v-col class="grow px-1 px-md-1 px-lg-3 px-xs-1">
            <platform-icon
              name="caret-right"
              color="secondary"
              :size="$vuetify.breakpoint.xs ? 35 : 40"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import { PlatformPackage } from '@/types/Platform'
import Vue, { PropType } from 'vue'
import { flagPicker } from '@/helpers/flags'
import CountryFlag from '@/components/shared/CountryFlag.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { NullPackageIdValue } from '@/helpers/packages'
import { Questionnaire } from '@/API'

export default Vue.extend({
  name: 'PackagePreview',
  components: {
    PlatformAvatar,
    CountryFlag
  },
  props: {
    questionnairePackage: { type: Object as PropType<PlatformPackage>, required: true },
    newGroupQuestionnaire: { type: Object as PropType<Questionnaire>, required: false },
    compact: { type: Boolean, default: false }
  },
  computed: {
    iconSize (): number {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 80
      }
      return 60
    },
    hasSelectedPackage (): boolean {
      return !!this.questionnairePackage.id && this.questionnairePackage.id !== NullPackageIdValue
    },
    cardActions (): any {
      return this.$listeners.click
        ? {
            click: () => this.$emit('click')
          }
        : {}
    },
    comparisonTooltipText (): string {
      return `${this.$t('packages.previewCard.comparisonTooltip')} ${this.newGroupQuestionnaire.name}`
    }
  },
  methods: {
    flagPicker
  }
})
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins.scss';

.card-action {
  border-left: 1px solid var(--v-grey-lighten1);
  border-radius: 0 !important;
}

.package-preview__card {
  @media #{map-get($display-breakpoints, 'xl-only')} {
    height: 130px;
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    height: 175px;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    height: 140px;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    height: 140px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 200px;
  }
}

.package-preview__card-no-button {
  @media #{map-get($display-breakpoints, 'xl-only')} {
    height: 110px;
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    height: 130px;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    height: 130px;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    height: 130px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    height: 190px;
  }
}

.package-description {
  @include line-clamp(2);
}

</style>
