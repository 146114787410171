<template>
  <div class="question grow d-flex flex-column text-center">
    <div class="flex-basis-0 flex-grow-1 d-flex flex-column justify-start pt-2">
      <h2 class="intro grey--text text--darken-3">
        {{ questionIntro }}
      </h2>
      <v-row v-if="hasImage" justify="center" class="py-2">
        <v-col class="shrink">
          <platform-avatar
            entity-type="individual"
            class="avatar"
            :pad-bottom="false"
            :size="avatarSize"
            :entity="repeatForTargetIndividual"
          />
        </v-col>
      </v-row>
    </div>

    <div class="flex-basis-0 flex-grow-1 d-flex flex-column justify-center pt-2">
      <v-row>
        <v-col class="grow">
          <h2 class="text grey--text text--darken-4">
            {{ questionText || question.repeatForCriteriaValue }}
          </h2>
          <h3 class="description grey--text text--darken-2 font-weight-light">
            {{ question.repeatForCriteriaDescription }}
          </h3>
        </v-col>
      </v-row>
    </div>

    <div class="flex-basis-0 flex-grow-1 d-flex flex-row align-center justify-center">
      <component
        v-if="questionTypeComponent"
        :is="questionTypeComponent"
        v-model="answer"
        :question="question"
        :is-preview="isPreview"
        @next="$emit('next')"
        @valid="(val) => $emit('valid', val)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import MultipleChoiceQuestion from '@/components/questionnaire/survey/blocks/variants/questions/variants/MultipleChoiceQuestion.vue'
import MultiTextQuestion from '@/components/questionnaire/survey/blocks/variants/questions/variants/MultiTextQuestion.vue'
import ScaleQuestion from '@/components/questionnaire/survey/blocks/variants/questions/variants/ScaleQuestion.vue'
import TextQuestion from '@/components/questionnaire/survey/blocks/variants/questions/variants/TextQuestion.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { QuestionTypes, RepeatForOptions } from '@/helpers/questionnaire/questions'
import { QuestionBlockData } from '@/types/Question'
import Vue, { Component, PropType } from 'vue'

export default Vue.extend({
  name: 'SurveyQuestion',
  components: {
    PlatformAvatar
  },
  props: {
    question: { type: Object as PropType<QuestionBlockData>, required: true },
    value: { type: [Object, Array, String, Number], required: false }, // answer
    isPreview: { type: Boolean, default: false }
  },
  computed: {
    questionVariant (): QuestionTypes {
      return this.question.variant
    },
    questionTypeComponent (): Component | null {
      switch (this.question.variant) {
        case QuestionTypes.Text:
          return TextQuestion
        case QuestionTypes.MultiText:
          return MultiTextQuestion
        case QuestionTypes.Scale:
          return ScaleQuestion
        case QuestionTypes.MultipleChoice:
          return MultipleChoiceQuestion
        default:
          throw new Error(`Question component received question of unknown type "${this.question.variant}".`)
      }
    },
    platformSelectedCompanyId (): string | undefined {
      return this.$store.state.Company.selectedCompany?.id
    },
    answer: {
      get (): any {
        return this.value
      },
      set (val: any): void {
        this.$emit('input', val)
      }
    },
    questionText (): string | undefined {
      if (this.question.isSelfEvaluation) {
        return this.question.questionTextSelfEvaluation
      }
      return this.question.questionText
    },
    questionIntro (): string | undefined {
      if (this.question.isSelfEvaluation) {
        return this.question.questionIntroSelfEvaluation
      }
      return this.question.questionIntro
    },
    hasImage (): boolean {
      if (this.question.repeatFor !== RepeatForOptions.BoardMembers) {
        return false
      }
      if (!this.question.repeatForCriteriaId || !this.platformSelectedCompanyId) {
        return false
      }
      return true
    },
    avatarSize (): number {
      if (this.$vuetify.breakpoint.xlOnly) {
        return 120
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return 100
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        return 80
      }
      return 75
    },
    repeatForTargetIndividual (): any {
      const individual: any = {
        id: this.question.repeatForCriteriaId,
        companyId: this.platformSelectedCompanyId
      }
      const preferredName = this.question.repeatForCriteriaValue
      if (this.question.repeatFor === RepeatForOptions.BoardMembers && preferredName) {
        individual.preferredName = preferredName
        const names = preferredName.split(' ')
        if (names.length > 1) {
          individual.firstName = names.shift()
          individual.familyName = names.join(' ')
        }
      }
      return individual
    }
  },
  methods: {
    //
  }
})
</script>

<style lang="scss" scoped="scoped">
.question {
  @media #{map-get($display-breakpoints, 'xl-only')} {
    font-size: 1em;
  }
  @media #{map-get($display-breakpoints, 'lg-only')} {
    font-size: 1em;
  }
  @media #{map-get($display-breakpoints, 'md-only')} {
    font-size: 0.8em;
  }
  @media #{map-get($display-breakpoints, 'sm-only')} {
    font-size: 0.75em;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    font-size: 0.7em;
  }
  .intro,
  .title,
  .description {
    font-weight: normal;
  }
  .intro {
    font-size: 1.4em;
  }
  .text {
    font-size: 1.55em;
    line-height: 1.35;
    @media #{map-get($display-breakpoints, 'xl-only')} {
      font-size: 1.7em;
    }
    @media #{map-get($display-breakpoints, 'lg-only')} {
      font-size: 1.6em;
    }
  }
  .description {
    font-size: 1em;
  }
}
</style>
