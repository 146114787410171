<template>
  <component
    v-if="blockTypeComponent"
    v-model="blockData"
    :is="blockTypeComponent"
    :type="type"
    :allowed="allowed"
    :disabled="disabled"
    @valid="(val) => $emit('valid', val)"
  >
    <template v-slot:actions>
      <v-row class="flex-column pl-6 pr-1">
        <v-col shrink>
          <variant-picker
            v-model="activeQuestionnaireVariant"
            entity-type="block"
            :selected-questionnaire-variant="selectedQuestionnaireVariant"
            :variants="variants"
          />
        </v-col>
        <v-col shrink>
          <slot name="actions" />
        </v-col>
      </v-row>
    </template>
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import { QuestionBlockData } from '@/types/Question'
import { PlatformBlock, PlatformQuestionnaireVariant, QuestionnaireVariantCode } from '@/types/Platform'
import { BlockTypes, BlockTypeItems, EditableBlockVariantDataFields } from '@/helpers/questionnaire/blocks'
import QuestionBlockForm from '@/components/forms/blocks/QuestionBlockForm.vue'
import VariantPicker from '@/components/shared/VariantPicker.vue'

export default Vue.extend({
  name: 'BlockForm',
  props: {
    type: { type: String, required: true },
    blockVariants: { type: Object as PropType<{ [variantCode: string]: PlatformBlock }>, required: true }, // Array of variants of this block
    allowed: { type: Array, required: false },
    selectedQuestionnaireVariant: { type: String as PropType<QuestionnaireVariantCode | undefined>, required: false },
    sectionId: { type: String, required: true },
    variants: { type: Array as PropType<PlatformQuestionnaireVariant[]>, required: true },
    disabled: { type: Boolean, default: false }
  },
  components: {
    VariantPicker,
    VFormBase
  },
  data: () => ({
    activeQuestionnaireVariant: <QuestionnaireVariantCode | undefined>undefined,
    BlockTypes,
    BlockTypeItems
  }),
  mounted (): void {
    this.activeQuestionnaireVariant = this.selectedQuestionnaireVariant
  },
  computed: {
    block: {
      get (): PlatformBlock | undefined {
        if (!this.activeQuestionnaireVariant) {
          return
        }
        const defaultVariant = this.variants[0]?.variantCode
        const block = this.blockVariants[this.activeQuestionnaireVariant]
        if (defaultVariant === this.activeQuestionnaireVariant) {
          return block
        }
        const defaultBlock = this.blockVariants[defaultVariant]
        if (!defaultBlock || !block) {
          return
        }
        const data = {
          ...defaultBlock.data
        }
        EditableBlockVariantDataFields.forEach((field) => {
          data[field] = block.data?.[field] ?? defaultBlock.data?.[field]
        })
        return {
          ...block,
          data
        }
      },
      set (block: PlatformBlock): void {
        this.syncBlockVariantChanges(block)
        this.$emit('input', {
          ...block,
          companyId: this.$store.state.Company.selectedCompany.id,
          variantCode: this.activeQuestionnaireVariant ?? block.variantCode
        })
      }
    },
    blockData: {
      get (): QuestionBlockData | undefined {
        return this.block?.data
      },
      set (data: QuestionBlockData): void {
        this.block = {
          ...this.block,
          data
        } as any
      }
    },
    blockTypeComponent (): Vue.Component | null {
      if (!this.block) {
        return null
      }
      switch (this.block.variant) {
        case BlockTypes.Question:
          return QuestionBlockForm
        default:
          return null
      }
    }
  },
  methods: {
    /**
     * Synchronizes any changes made to a block variant to the default variant of that block, as the `block` getter in this component
     *  uses the default block's data as the current state of all variants of that block. These changes are synced across all blocks
     *  in QuestionnaireBuilder:syncChangedBlockVariants() upon submitting the form.
     */
    syncBlockVariantChanges (block: PlatformBlock): void {
      const defaultVariant = this.variants[0]?.variantCode
      if (!block?.data || defaultVariant === this.activeQuestionnaireVariant) {
        return
      }
      const defaultBlock = this.blockVariants[defaultVariant]
      if (!defaultBlock) {
        return
      }
      const data = {
        ...defaultBlock.data
      }
      let changed = false
      Object.keys(block.data).forEach((field) => {
        if (EditableBlockVariantDataFields.includes(field)) {
          return
        }
        if (data[field] !== block.data[field]) {
          changed = true
        }
        data[field] = block.data[field]
      })
      if (changed) {
        this.$emit('input', {
          ...defaultBlock,
          data
        })
      }
    }
  }
})
</script>
