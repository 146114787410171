import { PartialIndividual, PlatformIndividual } from '../../../types/Individual'

/**
 * Gets the "concise" version of an Individual's name, eg:
 *  - If there is only one Luke: "Luke Roberts" -> "Luke"
 *  - If conflicting Individuals have different surnames: "Luke Roberts" -> "Luke R.", "Luke Jones" -> "Luke J."
 *  - If conflicting Individuals have similar surnames: "Heinrich Schneider" -> "Heinrich Schn.", "Heinrich Schulz" -> "Heinrich Schu."
 *  - If conflicting Individuals share multiple names: "User Name One" -> "User Name O.", "User Name Two" -> "User Name T."
 */
export function getConcisePreferredName <I extends PartialIndividual = PlatformIndividual> (individual: I, allIndividuals: I[]): string {
  const nameParts: string[] = individual.preferredName.split(' ')

  const firstName: string = nameParts[0]
  if (nameParts.length === 1) {
    return firstName
  }

  // Check if any other Individuals have the same first name
  const matchingFirstNameIndividuals: I[] = allIndividuals.filter(
    (i: I): boolean => i.id !== individual.id && i.preferredName.startsWith(`${firstName} `)
  )
  const uniqueFirstName: boolean = !matchingFirstNameIndividuals.length
  if (uniqueFirstName) {
    // Only Individual with this first name, just use that
    return firstName
  }

  // Find the smallest substring within the preferred name that is unique to this Individual
  let uniquePreferredName: string = firstName
  for (let i = firstName.length; i <= individual.preferredName.length; i++) {
    const nameSubString = individual.preferredName.substring(0, i)
    const isUnique: boolean = !matchingFirstNameIndividuals.some(
      (i: I): boolean => i.preferredName.startsWith(nameSubString)
    )

    uniquePreferredName = nameSubString

    // Prevent splitting in the middle of a multi-word surname:
    const currentWordStartIndex: number = individual.preferredName.lastIndexOf(' ', i)
    const currentWordEndIndex: number = individual.preferredName.indexOf(' ', i + 1)
    const currentWord: string | undefined = individual.preferredName.substring(
      currentWordStartIndex === -1 ? 0 : currentWordStartIndex,
      currentWordEndIndex === -1 ? undefined : currentWordEndIndex
    )
    if (currentWordEndIndex !== -1 && currentWord.toLowerCase() === currentWord) {
      // Current word is fully lowercase, likely a connective word so set
      //  uniquePreferredName to current word plus first letter of next word
      const preferredName: string = individual.preferredName
      uniquePreferredName = `${currentWord}${preferredName.charAt(currentWordEndIndex + 1)}`
      i = currentWordEndIndex + 1
      continue
    }

    // Name is unique, use this
    if (isUnique) {
      break
    }
  }

  if (uniquePreferredName === individual.preferredName) {
    // Couldn't find a unique substring, return untransformed preferred name
    return individual.preferredName
  }

  // Append a dot to represent the trimmed characters
  return `${uniquePreferredName.trim()}.`
}
