import { PlatformReport } from '../../../types/Report'
import { Report } from '../../../types/API'

export default function normalizeReport (report: PlatformReport): PlatformReport {
  if (typeof report.configuration === 'string') {
    report.configuration = JSON.parse(report.configuration)
  }
  return report
}

export function denormalizeReport (report: Partial<PlatformReport<any>> | PlatformReport | Report): Report {
  if (typeof report.configuration !== 'string') {
    report.configuration = JSON.stringify(report.configuration)
  }
  return report as Report
}
