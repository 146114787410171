import { GraphTypes } from '@betterboards/graphs/types/Graph'
import { ScaleGraphData } from '@betterboards/graphs/variants/ScaleGraph'
import { ScatterGraphData } from '@betterboards/graphs/variants/ScatterGraph'
import { LineGraphData } from '@betterboards/graphs/variants/LineGraph'
import { QuestionBlockData } from '@/types/Question'

export type GraphData = ScaleGraphData | ScatterGraphData | LineGraphData

export interface GraphInfo<DataType = GraphData> {
  variant: GraphTypes
  data: DataType
  question?: QuestionBlockData
}

export enum DotTypes {
  Circle = 'circle',
  Rect = 'rect',
  Range = 'range',
  Dash = 'dash',
  Dotted = 'dotted',
}

export enum GraphColors {
  Primary = 'primary',
  Secondary = 'secondary',
}

export interface LineConfig {
  property: string
  color: 'primary' | 'secondary' | string
  dot: DotTypes
  alignLabel?: 'top' | 'right'
  label?: string
  strokeLabel?: boolean
}

export interface CategoryGraphDataPoint {
  x?: number // Value on X axis
  category?: string // Value on Y axis
  xVal?: number // Actual x coordinate
  yVal?: number // Actual y coordinate
  label?: string
}
