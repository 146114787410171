import { GraphColors } from '../types/Graph'
import { GraphConfig } from '../variants/Abstract'

export function normalizeFloat (val: number): string {
  return val.toFixed(2)
}

export function getGraphColor (color: GraphColors | string, config: GraphConfig): string {
  if (config.colors[color as GraphColors]) {
    return config.colors[color as GraphColors]
  }
  return color
}
