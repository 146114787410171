import { render, staticRenderFns } from "./SurveyGroupSelectorModal.vue?vue&type=template&id=35b21617"
import script from "./SurveyGroupSelectorModal.vue?vue&type=script&lang=ts"
export * from "./SurveyGroupSelectorModal.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports