import { ApiError } from 'aws-amplify/api'
import API from '@/services/API'

export interface SelectItem {
  value: string
  text: string
}

export interface MegaMenuItem {
  text: string
  value?: string
  items?: MegaMenuItem[]
}

export function generateSelectItems (values: { [key: string]: string }, labels: { [key: string]: string }): SelectItem[] {
  return Object.keys(values).map((key) => ({
    value: values[key],
    text: labels[key]
  }))
}

export function generateSelectItemsDynamic (values: { [key: string]: string }, labelCallback: (key: string) => string): SelectItem[] {
  return Object.keys(values).map((key) => ({
    value: values[key],
    text: labelCallback(key)
  }))
}

export function generateMegaMenuItems (values: { [category: string]: { [itemKey: string]: string } }): MegaMenuItem[] {
  return Object.keys(values).map((category) => ({
    value: category,
    text: category,
    items: Object.keys(values[category]).map((itemKey) => ({
      value: itemKey,
      text: values[category][itemKey]
    }))
  }))
}

interface CacheItem {
  time: number
  link?: string
  promise?: Promise<string>
}

const cache: {
  [key: string]: CacheItem | undefined
} = {}

const listeners: { [key: string]: Array<(value: string) => void> } = {}

function getCacheKey (objectType: string, objectId: string, companyId: string): string {
  return `${objectType}-${objectId}-${companyId}`
}

function isExpired (cacheItem: CacheItem): boolean {
  return Date.now() - cacheItem.time > 900000
}

export function invalidateCachedImage (objectType: string, objectId: string, companyId: string = 'null'): void {
  const key = getCacheKey(objectType, objectId, companyId)
  if (cache[key]) {
    cache[key] = undefined
  }
  void loadImage(objectType, objectId, companyId)
}

export function addImageListener (objectType: string, objectId: string, companyId: string | undefined, callback: (value: string) => void): void {
  const key = getCacheKey(objectType, objectId, companyId ?? 'null')
  if (!listeners[key]) {
    listeners[key] = []
  }
  listeners[key].push(callback)
}

export function removeImageListener (objectType: string, objectId: string, companyId: string | undefined, callback: (value: string) => void): void {
  const key = getCacheKey(objectType, objectId, companyId ?? 'null')
  if (!listeners[key]) {
    return
  }
  const index = listeners[key].indexOf(callback)
  if (index !== -1) {
    listeners[key].splice(index, 1)
    if (listeners[key].length === 0) {
      delete listeners[key]
    }
  }
}

/**
 * companyId defaults to 'null' as it's actually optional, but is a path param
 */
export async function loadImage (objectType: string, objectId: string, companyId: string = 'null'): Promise<string> {
  const key = getCacheKey(objectType, objectId, companyId)
  const cacheItem = cache[key]
  if (cacheItem) {
    if (isExpired(cacheItem)) {
      // console.debug('Cache found for key:', key, ', but item is expired.')
      cache[key] = undefined
    } else {
      // younger than 15 mins
      const promise = cacheItem.promise
      const link = cacheItem.link
      if (promise) {
        // console.debug('Cache found for key:', key, ', awaiting previous promise...')
        return await promise
      }
      if (link) {
        // console.debug('Cache found for key:', key, ', returning link.')
        return link
      }
      console.error('Weird cache item is missing a link or a promise:', key, cacheItem)
      throw new Error('Cache item has no link and no promise!')
    }
  }

  // console.debug('No cache found for key:', key, ', fetching...')
  const promise: Promise<string> = new Promise((resolve, reject) => {
    API
      .get<string>('backendfunctions', `/${companyId}/files?objectType=${objectType}&objectId=${objectId}`, undefined, false)
      .then((result: string) => {
        const value = `data:image/jpeg;base64,${result}`
        if (listeners[key]) {
          listeners[key].forEach((listener) => {
            listener(value)
          })
        }
        resolve(value)
      })
      .catch((err) => {
        // console.warn('Failed to fetch image:', { err, companyId, objectType, objectId })
        reject(err)
      })
  })
  const time = Date.now()
  cache[key] = {
    time,
    promise
  }
  const response: string = await promise
  if (response) {
    cache[key] = {
      time,
      link: response
    }
  }
  return response
}

export function precacheImage (objectType: string, objectId: string, companyId: string): void {
  const key = getCacheKey(objectType, objectId, companyId)
  const cacheItem = cache[key]
  if (cacheItem && !isExpired(cacheItem)) {
    return
  }
  loadImage(objectType, objectId, companyId)
    .then((url) => {
      const id = `asset-cache-${key}`
      const exists = document.getElementById(id)
      if (exists) {
        exists.remove()
      }
      const link = document.createElement('link')
      link.id = id
      link.rel = 'prefetch'
      link.as = 'image'
      link.href = url
      document.head.appendChild(link)
    })
    .catch((err) => {
      if (err instanceof ApiError) {
        if (err?.response?.statusCode === 404) {
          return
        }
        console.warn('Failed to pre-cache image', err.response?.statusCode, err)
        return
      }
      console.warn('Failed to pre-cache image', err)
    })
}
