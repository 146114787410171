import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getFullSurveyGroup = /* GraphQL */ `
  query GetFullSurveyGroup($id: ID!) {
    getSurveyGroup(id: $id) {
      id
      questionnaireId
      companyId
      packageId
      targetIndividualIds
      name
      variants {
        name
        variantCode
      }
      status
      createdAt
      updatedAt
      releasedAt
      dispatchedAt
      deletedAt
      tags
      surveys {
        nextToken
        items {
          id
          groupId
          companyId
          questionnaireId
          individualId
          owner
          key
          variantCode
          dispatchedAt
          finishedAt
          deletedAt
          totals {
            questions
            questionsCompleted
          }
          status
          invitation
          createdAt
          updatedAt
          individual {
            id
            companyId
            firstName
            contactNumber
            preferredName
            familyName
            title
            role
            jobTitle
            accountStatus
          }
          questionnaire {
            name
          }
        }
      }
    }
  }
` as GeneratedQuery<
  APITypes.GetFullSurveyGroupQueryVariables,
  APITypes.GetFullSurveyGroupQuery
>
