export enum ObjectType {
  CompanyLogo = 'companyLogo',
  UserAvatar = 'userAvatar', // Individuals avatar
  Reports = 'reports',
  PlatformUserAvatar = 'platformUserAvatar', // User avatar
  ClientAsset = 'clientAsset',
  ReportsArchive = 'reportsArchive',
  PackageImage = 'packageImage',
  SurveyDocument = 'surveyDocument',
  PackageAssets = 'packageAssets'
}
