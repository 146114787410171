<template>
  <platform-modal
    v-model="showModal"
    :warning="warning"
    :icon-reversed="iconReversed"
    :icon="icon"
    :width="550"
    :title="title"
    :saving="loading"
    :confirm-disabled="loading"
    @[actionEvent]="$emit('confirm')"
    @close="$emit('cancel')"
  >
    <h3 class="sans-serif">
      <template v-if="confirmationMessage">{{ confirmationMessage }}</template>
      <template v-else-if="entityTypeName">Are you sure you want to {{ friendlyActionPhrase }} the following {{ entityTypeName }}?</template>
      <template v-else>Are you sure you want to {{ friendlyActionPhrase }} this?</template>
    </h3>
    <v-row no-gutters align="center" justify="center" class="pt-4" v-if="action !== 'exit' && action !== 'logout'">
      <platform-avatar
        v-if="image && imageEntity"
        :entity="imageEntity"
        :entity-type="imageEntityType"
        class="mr-4"
      />
      <span>
        {{ entityName }}
      </span>
      <slot name="default" />
      <platform-avatar
        v-if="altImageEntity"
        border="primary"
        class="ml-4"
        :entity="altImageEntity"
        :entity-type="altImageEntityType"
      />
    </v-row>
    <slot name="details" />
  </platform-modal>
</template>

<script lang="ts">
import PlatformModal from '@/components/PlatformModal.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { getFullName } from '@/helpers/individual'
import { PlatformEntityTypes } from '@/types/Platform'
import Vue, { PropType } from 'vue'
import { GenericReportIndividualId } from '@betterboards/shared/helpers/entities/Report/index'

function titleCase (val: string): string {
  return `${val.charAt(0).toUpperCase()}${val.slice(1)}`
}

export default Vue.extend({
  components: {
    PlatformAvatar,
    PlatformModal
  },
  props: {
    action: { type: String as PropType<'delete' | 'resetPassword' | 'exit' | 'logout'>, required: false },
    forceTitle: { type: String, required: false },
    message: { type: String, required: false },
    iconReversed: { type: Boolean, required: false },
    entityType: { type: String as PropType<PlatformEntityTypes>, required: true },
    entity: { type: Object as PropType<any>, required: false },
    forceIcon: { type: String, required: false },
    image: { type: Boolean, default: true },
    loading: { type: Boolean, required: false }
  },
  data () {
    return {
      showModal: true
    }
  },
  watch: {
    showModal (val) {
      if (!val) {
        this.$emit('cancel')
      }
    }
  },
  computed: {
    entityTypeName (): string {
      const entityTypeTranslationKey = `shared.entities.${this.entityType}`
      return this.$t(entityTypeTranslationKey) as string
    },
    title (): string | undefined {
      if (this.forceTitle) {
        return this.forceTitle
      }
      const entityType = this.entityTypeName ? titleCase(this.entityTypeName) : ''
      switch (this.action) {
        case 'resetPassword':
          return this.$t('modal.resetPassword.title', [this.entityName]) as string
        case 'delete':
          switch (this.entityType) {
            case PlatformEntityTypes.CompanyUser:
              return 'Remove User from Company'
            case PlatformEntityTypes.ReportDocument:
              return this.$t('analysis.generateReport.reportDocuments.delete.modalTitle') as string
            default:
              return `${this.titleAction} ${entityType}`
          }
        case 'exit':
          return `${this.entityType}`
        case 'logout':
          return `${this.$t('modal.logout.title') as string}`
        default:
          return `${this.titleAction} ${entityType}`
      }
    },
    icon (): string | undefined {
      if (this.forceIcon) {
        return this.forceIcon
      }
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
          return 'user'
        case PlatformEntityTypes.Company:
        case PlatformEntityTypes.CompanyUser:
          return 'company'
        case PlatformEntityTypes.Block:
        case PlatformEntityTypes.Section:
        case PlatformEntityTypes.Questionnaire:
          return 'questionnaire'
        case PlatformEntityTypes.Report:
          return 'edit-report'
      }
      switch (this.action) {
        case 'delete':
          return 'delete'
      }
      return undefined
    },
    titleAction (): string | undefined {
      if (!this.action) {
        return undefined
      }
      return titleCase(this.action)
    },
    entityName (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
          return getFullName(this.entity) || undefined
        case PlatformEntityTypes.CompanyUser:
          return this.entity.user?.displayName
        case PlatformEntityTypes.Block:
        case PlatformEntityTypes.ReportDocument:
          return undefined
        default:
          return this.entity.name
      }
    },
    imageEntity (): any | undefined {
      if (!this.imageEntityType) {
        return undefined
      }
      switch (this.entityType) {
        case PlatformEntityTypes.CompanyUser:
          return this.entity?.user
        case PlatformEntityTypes.Committee:
          return undefined
        case PlatformEntityTypes.ReportDocument:
          return { id: this.entity.targetIndividualId, companyId: this.entity.companyId }
        default:
          return this.entity
      }
    },
    imageEntityType (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.CompanyUser:
          return PlatformEntityTypes.User
        case PlatformEntityTypes.ReportDocument:
          if (this.entity.targetIndividualId && this.entity.targetIndividualId !== GenericReportIndividualId) {
            return PlatformEntityTypes.Individual
          }
          return undefined
        default:
          return this.entityType
      }
    },
    altImageEntity (): any | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.CompanyUser:
          return {
            id: this.entity.companyId,
            ...this.entity.company
          }
        default:
          return undefined
      }
    },
    altImageEntityType (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.CompanyUser:
          return PlatformEntityTypes.Company
        default:
          return undefined
      }
    },
    friendlyActionPhrase (): string {
      switch (this.action) {
        case 'resetPassword':
          return 'reset the password for'
        case 'delete':
          if (this.entityType === PlatformEntityTypes.CompanyUser) {
            return 'remove'
          }
          return this.action
        default:
          return this.action
      }
    },
    confirmationMessage (): string | undefined {
      if (this.message) {
        return this.message
      }
      switch (this.action) {
        case 'delete':
          switch (this.entityType) {
            case PlatformEntityTypes.CompanyUser:
              return `Are you sure you want to remove ${this.entityName} from ${this.entity.company?.name}?`
            case PlatformEntityTypes.ReportDocument:
              return this.$t('analysis.generateReport.reportDocuments.delete.modalConfirmationMessage') as string
          }
      }
      return undefined
    },
    warning (): boolean {
      return this.action === 'delete' || this.action === 'resetPassword'
    },
    actionEvent (): string {
      // This is used to dynamically attach an event listener
      //  to either the delete or the confirm event of PlatformModal
      return this.action === 'delete' ? 'delete' : 'confirm'
    }
  }
})
</script>
