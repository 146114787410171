<template>
  <div class="page__footer">
    <hr class="footer__divider" />
    <div class="footer__content">
      <div>
        {{ $t('report.footer.copyrightNotice') }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ReportPreviewPageFooter',
  props: {
    // pageNumber: { type: Number, required: false }
  }
})
</script>

<style lang="scss" scoped="scoped">
.page__footer {
  padding: 6px 0 4px;

  .footer__divider {
    border: none;
    border-top-width: 1px;
    border-top-style: solid;
    border-top-color: var(--v-grey-darken1);
    margin-bottom: 5px;
  }
  .footer__content {
    font-size: 0.39em;
    display: flex;
    justify-content: center;
    color: var(--v-grey-darken3);
  }
}
</style>
