<template>
  <div class="d-flex flex-fill">
    <v-container v-if="loadingImages" class="d-flex align-center justify-center">
      <platform-spinner
        color="primary"
        :size="50"
        :width="5"
      />
    </v-container>
    <div v-else class="d-flex flex-row">
      <display-markdown
        :value="markdownContent"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { MarkdownBlock } from '@betterboards/shared/types/Platform'
import { MarkdownBlockData } from '@betterboards/shared/types/Question'
import DisplayMarkdown from '@/components/DisplayMarkdown.vue'
import { loadImage } from '@/helpers/forms'
import { ObjectType } from '@betterboards/shared/types/Files/index'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

export default Vue.extend({
  name: 'SurveyMarkdownBlock',
  components: { PlatformSpinner, DisplayMarkdown },
  props: {
    block: { type: Object as PropType<MarkdownBlock>, required: true },
    isPreview: { type: Boolean, default: false }
  },
  data () {
    return {
      content: <string | undefined>undefined,
      loadingImages: <boolean>false
    }
  },
  mounted () {
    this.content = this.markdownContent
    this.loadingImages = true
    this.getContentImageIds()
  },
  computed: {
    markdownData (): MarkdownBlockData | null {
      if (!this.block?.data) {
        return null
      }
      return typeof this.block.data === 'string' ? JSON.parse(this.block.data) : this.block.data
    },
    markdownContent (): string | undefined {
      return this.content ?? this.markdownData?.content
    }
  },
  methods: {
    async getContentImageIds (): Promise<void> {
      if (this.markdownContent) {
        const DataObjectIDAttributePattern: RegExp = /(?:data-object-id="([a-zA-Z\d\s\w-]+)")+/g
        const objectIdAttributes: RegExpMatchArray | null = this.markdownContent.match(DataObjectIDAttributePattern)
        if (!objectIdAttributes) {
          this.loadingImages = false
          return
        }

        await Promise.all(objectIdAttributes.map(
          async (objectIdAttribute: string): Promise<void> => {
            const DataObjectIDAttributeValuePattern: RegExp = /data-object-id="([^"]+)"/g
            const objectIdMatches: RegExpExecArray | null = DataObjectIDAttributeValuePattern.exec(objectIdAttribute)
            if (!objectIdMatches) {
              return
            }

            const objectId = objectIdMatches[1] // First capture group from DataObjectIDAttributeValuePattern
            const base64Image: string = await loadImage(ObjectType.PackageAssets, objectId)

            this.content = this.content?.replace(objectIdAttribute, `src="${base64Image}"`)
          }
        ))
      }
      this.loadingImages = false
    }
  }
})
</script>
