import { CommitteeTag, CompanyTeamTypes, UserType, Block } from './API'
import { QuestionData } from './Question'

export enum QuestionTargetRuleType {
  Role = 'Role',
  RoleGroup = 'RoleGroup',
  TeamMembers = 'TeamMembers',
  CriteriaId = 'CriteriaId',
  EntityTag = 'EntityTag',
}

export interface BaseQuestionTargetRule {
  filter: 'include' | 'exclude'
  type: QuestionTargetRuleType
  value: any // Value used to perform the rule's comparison, ie the Role enum value from UserType or the Target Group enum value from TargetGroupOptions
}

// Target Recipient Options
export interface IndividualRoleTargetRule extends BaseQuestionTargetRule {
  type: QuestionTargetRuleType.Role
  value: UserType
}

export enum TargetGroupOptions {
  NonExecutives = 'NonExecutives',
  Executives = 'Executives',
  ShareholderRepresentative = 'ShareholderRepresentative',
  LabourRepresentative = 'LabourRepresentative',
  InternalStakeholders = 'InternalStakeholders',
  ExternalStakeholders = 'ExternalStakeholders',
}

export interface IndividualRoleGroupTargetRule extends BaseQuestionTargetRule {
  type: QuestionTargetRuleType.RoleGroup
  value: TargetGroupOptions
}

export interface IndividualTeamTargetRule extends BaseQuestionTargetRule {
  type: QuestionTargetRuleType.TeamMembers
  value: CompanyTeamTypes
}

export interface RepeatForCriteriaIdTargetRule extends BaseQuestionTargetRule {
  type: QuestionTargetRuleType.CriteriaId
  value: string
}

export interface EntityTagTargetRule extends BaseQuestionTargetRule {
  type: QuestionTargetRuleType.EntityTag
  value: CommitteeTag
}

export type QuestionTargetRule = IndividualRoleTargetRule | IndividualRoleGroupTargetRule | IndividualTeamTargetRule | RepeatForCriteriaIdTargetRule | EntityTagTargetRule

export type QuestionTargetRules = QuestionTargetRule[]

export type BlockVariant<DataType> = Omit<Block, 'data'> & { data: DataType }

export type QuestionBlock<D extends QuestionData = QuestionData> = BlockVariant<D>
