var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',_vm._g({staticClass:"d-flex",class:{
    'package-preview__card-no-button': _vm.compact,
    'package-preview__card': !_vm.compact
  },style:({
    width: _vm.$vuetify.breakpoint.smAndUp ? '700px' : undefined
  }),attrs:{"hover":!!_vm.$listeners.click}},_vm.cardActions),[_c('v-row',{staticClass:"flex-nowrap",attrs:{"no-gutters":"","align":"stretch"}},[_c('v-col',{staticClass:"shrink d-flex"},[_c('v-row',{staticClass:"shrink pl-4 pr-1 pl-md-4 pr-md-3 pl-lg-7 pr-lg-3",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"grow",style:({
            width: _vm.iconSize
          })},[(_vm.hasSelectedPackage)?_c('platform-avatar',{attrs:{"entity-type":"package","placeholder-size":"xs","pad-bottom":false,"size":_vm.iconSize,"entity":_vm.questionnairePackage}}):_vm._e()],1)],1)],1),_c('v-col',{staticClass:"d-flex py-3 grow px-4"},[_c('v-row',{staticClass:"flex-column flex-nowrap",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink"},[_c('span',{staticClass:"font-weight-bold text-body-1",class:{
              'text-body-2': _vm.$vuetify.breakpoint.xsOnly,
              'text-body-1': _vm.$vuetify.breakpoint.smAndUp
            }},[(_vm.hasSelectedPackage)?[_vm._v(" "+_vm._s(_vm.questionnairePackage.name)+" ")]:[_vm._v(" "+_vm._s(_vm.$t('packages.previewCard.noSelection.title'))+" ")]],2)]),_c('v-col',{staticClass:"flex-shrink-1 overflow-hidden"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.questionnairePackage.description),expression:"questionnairePackage.description"}],staticClass:"text-caption pr-2 package-description"},[(_vm.hasSelectedPackage)?[_vm._v(" "+_vm._s(_vm.questionnairePackage.description)+" ")]:[_vm._v(" "+_vm._s(_vm.$t('packages.previewCard.noSelection.description'))+" ")]],2)]),_c('v-col',{staticClass:"shrink"},[(_vm.newGroupQuestionnaire)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.comparisonTooltipText),expression:"comparisonTooltipText"}],staticClass:"text--compact"},[_c('platform-icon',{staticClass:"mr-1",attrs:{"name":"comparisons","color":"primary","size":20}}),_c('span',{staticClass:"text-caption pr-2"},[_c('strong',[_vm._v(_vm._s(_vm.$t('packages.previewCard.comparisonText'))+" ")])])],1):_vm._e(),_c('v-row',{staticClass:"py-1",attrs:{"no-gutters":""}},_vm._l((_vm.questionnairePackage.variants),function(variant){return _c('v-col',{key:variant,staticClass:"shrink pr-2"},[_c('country-flag',{attrs:{"countryCode":variant}})],1)}),1)],1)],1)],1),(_vm.$listeners.click)?_c('v-col',{staticClass:"shrink d-flex"},[_c('v-row',{staticClass:"shrink card-action",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{staticClass:"grow px-1 px-md-1 px-lg-3 px-xs-1"},[_c('platform-icon',{attrs:{"name":"caret-right","color":"secondary","size":_vm.$vuetify.breakpoint.xs ? 35 : 40}})],1)],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }