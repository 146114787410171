export default function sortByDisplayOrder (a: { displayOrder?: number }, b: { displayOrder?: number }): number {
  if (!a.displayOrder && !b.displayOrder) {
    return 0
  }
  if (!a.displayOrder) {
    return 1
  }
  if (!b.displayOrder) {
    return -1
  }
  return a.displayOrder - b.displayOrder
}
