import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getFullUserByCognitoId = /* GraphQL */ `
  query GetFullUserByCognitoId(
    $cognitoId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCognitoIdentityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByCognitoId(
      cognitoId: $cognitoId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        userCreatedBy
        createdAt
        updatedAt
        displayName
        email
        defaultLanguage
        avatar {
          bucket
          region
          key
        }
        companies {
          items {
            companyId
            accountType
            company {
              name
              deletedAt
              createdAt
              sector
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.GetFullUserByCognitoIdQueryVariables,
  APITypes.GetFullUserByCognitoIdQuery
>
