<template>
  <div class="d-flex flex-column">
    <v-row
      no-gutters
      class="grow flex-column"
      :justify="flex ? 'space-between' : 'start'"
    >
      <div
        v-for="(subResult, index) in resultGroup.results"
        :key="`subresult-${index}`"
        :class="{ shrink: !flex }"
      >
        <display-graph
          :graph="subResult"
          :section="section"
          :config="getResultConfig(subResult, false, subResult.tickLabels)"
          :report-section="reportSection"
          :padded="!flex"
        />
      </div>
    </v-row>
    <v-row v-if="resultGroup.legendResult" no-gutters class="shrink results-legend" :class="{ 'pt-12': !flex }">
      <v-col class="result-legend">
        <display-graph
          :graph="resultGroup.legendResult"
          :section="section"
          :config="getResultConfig(resultGroup.legendResult, true)"
          :report-section="reportSection"
          :padded="!flex"
        />
      </v-col>
    </v-row>
    <div v-if="resultGroup.tip" class="results-tip text-center highlight--text text-body-2 pb-1">
      <span>{{ resultGroup.tip }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformSection } from '@betterboards/shared/types/Platform'
import { ProgressSection, ResultSet } from '@betterboards/shared/types/ResultSet'
import { GraphConfig } from '@betterboards/graphs/variants/Abstract'
import { ResultGroup } from '@/types/ResultSet'
import { getDefaultGraphConfig, getGraphResultConfig } from '@/helpers/questionnaire/results'
import { RepeatForOptions } from '@/helpers/questionnaire/questions'
import DisplayGraph from '@/components/graphs/DisplayGraph.vue'

export default Vue.extend({
  name: 'DisplayGraphs',
  props: {
    resultGroup: {
      type: Object as PropType<ResultGroup>
    },
    reportSection: {
      type: Object as PropType<any>,
      required: false
    },
    section: {
      type: Object as PropType<PlatformSection>
    },
    config: {
      type: Object as PropType<Partial<GraphConfig> | undefined>,
      required: false
    },
    legendConfig: {
      type: Object as PropType<Partial<GraphConfig> | undefined>,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DisplayGraph
  },
  data: () => ({
    RepeatForOptions
  }),
  computed: {
    resultSetSections (): ProgressSection[] {
      return this.$store.getters['Report/resultSetSections']
    },
    resultSetSection (): ProgressSection | undefined {
      return this.resultSetSections?.find((s) => s.id === this.section.id)
    },
    selectedCompany (): any | undefined {
      return this.$store.state.Company.selectedCompany
    },
    companyConfiguration (): any | undefined {
      return this.selectedCompany?.configuration
    },
    reportConfig (): any | undefined {
      return this.companyConfiguration?.report
    },
    graphsConfig (): any {
      return this.reportConfig?.graphs
    },
    defaultGraphConfig (): any | undefined {
      return {
        scale: 1,
        comparison: !!this.comparisonResultSet,
        ...getDefaultGraphConfig(this.companyConfiguration)
      }
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    }
  },
  methods: {
    getResultConfig (result: ResultGroup, legend: boolean = false, tickLabels: boolean = false): any | undefined {
      const config = {
        ...this.defaultGraphConfig,
        ...(legend ? this.legendConfig : this.config)
      }
      return getGraphResultConfig(
        result,
        config,
        this.companyConfiguration,
        legend,
        tickLabels
      )
    }
  }
})
</script>
