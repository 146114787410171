import API from '@/services/API'
import Vue from 'vue'
import { Module } from 'vuex'
import { Block } from '@/API'
import { deleteBlock } from '@/graphql/mutations'
import { persistBlock } from '@/helpers/questionnaire'
import { PlatformBlock } from '@betterboards/shared/types/Platform'

interface BlockState {
  placeholder?: undefined
}

const defaultState = (): BlockState => ({
  //
})

const BlockStore: Module<BlockState, any> = {
  namespaced: true,
  state: defaultState(),
  actions: {
    deleteBlock ({ commit }, block: Block | undefined) {
      if (!block?.id || !block?.variantCode) {
        return
      }
      API.graphql({
        query: deleteBlock,
        variables: {
          input: {
            id: block.id,
            variantCode: block.variantCode
          }
        }
      })
        .catch((e: Error) => {
          console.error('error deleting block', e)
          Vue.toasted.error('There was an error deleting the block')
        })
    },
    async updateBlock ({ commit }: any, block: PlatformBlock): Promise<PlatformBlock> {
      return await persistBlock(block)
    }
  }
}

export default BlockStore
