export const Colors = {
  primary: '#24235f',
  secondary: '#e44500',
  grey: {
    lightest: '#f2f3f2',
    lighter: '#ebeceb',
    light: '#c7c6c7',
    medium: '#727072',
    dark: '#323032'
  }
}
