<template>
  <div class="px-3 px-md-5">
    <v-row no-gutters class="flex-column" align="center">
      <span class="grey--text text--darken-1 mb-5">
        {{ $t('analysis.reportGroup.manageModal.instruction') }}
      </span>
      <report-group-selector
        v-model="selectedReportGroupId"
        :available-options="availableReportGroupOptions"
        @create="displayCreateReportGroupModal"
      />
    </v-row>
    <create-report-group-modal
      v-if="creatingReportGroup"
      :selected-report-group="selectedReportGroup"
      @close="creatingReportGroup = false"
      @created="reportGroupCreated"
    />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { PlatformReportGroup } from '@betterboards/shared/types/Report'
import { QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import ReportGroupSelector from '@/components/analysis/report-groups/ReportGroupSelector.vue'
import CreateReportGroupModal from '@/components/analysis/report-groups/CreateReportGroupModal.vue'
import { SelectItem } from '@/helpers/forms'

export default Vue.extend({
  name: 'ManageReportGroupsForm',
  components: {
    CreateReportGroupModal,
    ReportGroupSelector
  },
  props: {
    //
  },
  data: () => ({
    creatingReportGroup: false
  }),
  computed: {
    selectedReportGroup (): PlatformReportGroup {
      return this.$store.getters['Report/selectedReportGroup']
    },
    selectedVariant (): QuestionnaireVariantCode | undefined {
      return this.$store.state.Analysis.variantCode
    },
    availableReportGroups (): PlatformReportGroup[] {
      return this.$store.state.Report.reportGroups
    },
    availableReportGroupOptions (): SelectItem[] {
      return [...this.availableReportGroups]
        .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
        .map((reportGroup: PlatformReportGroup) => {
          return {
            text: reportGroup.name,
            value: reportGroup.id
          }
        })
    },
    selectedReportGroupId: {
      get (): string | null {
        return this.$store.state.Report.selectedReportGroupId
      },
      set (selectedReportGroupId: string) {
        this.selectReportGroup(selectedReportGroupId)
      }
    }
  },
  methods: {
    displayCreateReportGroupModal (): void {
      this.creatingReportGroup = true
    },
    selectReportGroup (id: string): void {
      this.$store.commit('Report/setSelectedReportGroup', {
        id,
        variantCode: this.selectedVariant
      })
    },
    async reportGroupCreated (reportGroup: PlatformReportGroup): Promise<void> {
      await this.$store.dispatch('Report/fetchReportGroup', reportGroup.id)
      this.selectedReportGroupId = reportGroup.id
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.report-group-scores--form {
  min-height: 320px;
}
</style>
