<template>
  <v-form-base
    :model="question"
    :schema="schema"
    @click="onInput"
    @input="onInput"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import { v4 as uuid } from 'uuid'
import { MultiTextQuestionData } from '@betterboards/shared/types/Question'
import { MultiTextQuestionGroup } from '@/types/Question'
import { MaxFieldLengths, Validators } from '@/helpers/validation'
import { MultiTextGroup } from '@betterboards/shared/helpers/entities/Question/index'

const Tooltips = {
  AddGroup: 'Adds a group of input fields for the user to enter multiple answers.'
}

const DefaultGroup: MultiTextQuestionGroup = {
  name: '',
  fields: 1
}

export default Vue.extend({
  name: 'MultiTextQuestionForm',
  props: {
    value: { type: Object as PropType<MultiTextQuestionData>, required: true },
    disabled: { type: Boolean, required: false }
  },
  components: {
    VFormBase
  },
  computed: {
    question: {
      get (): MultiTextQuestionData {
        return this.value
      },
      set (val: MultiTextQuestionData) {
        const groups: MultiTextQuestionGroup[] = (val.groups ?? this.question.groups)?.map((g: MultiTextQuestionGroup) => ({
          id: g.id ?? uuid(),
          ...g
        }))
        console.debug('MultiText question set', groups, val, this.question)
        this.$emit('input', {
          ...this.question,
          ...val,
          groups
        })
      }
    },
    schema (): any {
      const schema = {
        addGroup: {
          type: 'btn',
          label: 'Add Answer Group',
          col: 12,
          align: 'right',
          iconLeft: 'mdi-plus',
          class: 'primary mt-4 mb-1',
          tooltips: Tooltips.AddGroup,
          disabled: this.disabled
        },
        directReportOutput: {
          type: 'switch',
          label: 'Direct Report Output',
          tooltip: 'Enable this to display responses directly in the Report, or leave disabled to input Key Findings in the Analysis.',
          value: this.question.directReportOutput,
          col: { xs: 12, sm: 12, md: 12, lg: 12 },
          disabled: this.disabled
        },
        groups: {
          type: 'array',
          col: 12,
          key: 'id',
          class: 'pt-2',
          schema: {
            slug: {
              type: 'select',
              label: 'Group',
              items: [
                {
                  text: 'Strengths',
                  value: MultiTextGroup.Strengths
                },
                {
                  text: 'Situations',
                  value: MultiTextGroup.Situations
                },
                {
                  text: 'Top',
                  value: MultiTextGroup.Top
                },
                {
                  text: 'More',
                  value: MultiTextGroup.More
                },
                {
                  text: 'Less',
                  value: MultiTextGroup.Less
                },
                {
                  text: 'Other',
                  value: MultiTextGroup.Other
                }
              ],
              col: { xs: 12, sm: 6, md: 2 },
              class: 'pr-3',
              disabled: this.disabled
            },
            name: {
              type: 'BBTextField',
              label: 'Group Label',
              col: { xs: 12, sm: 6, md: 5 },
              rules: [
                Validators.maxLength(MaxFieldLengths.MultiTextQuestionHeader)
              ],
              class: 'pr-3',
              disabled: this.disabled
            },
            fields: {
              type: 'number',
              label: 'Number of fields in group',
              col: { xs: 10, md: 4 },
              min: 2,
              max: 10,
              class: 'pr-3',
              disabled: this.disabled
            },
            deleteGroup: {
              type: 'btn',
              col: { xs: 2, md: 1 },
              iconCenter: 'mdi-delete',
              tooltip: 'Delete Answer Group',
              color: 'warning',
              class: 'mt-4 mb-1',
              disabled: this.disabled
            }
          }
        }
      }

      return schema
    }
  },
  methods: {
    onInput (val: any): void {
      const { key, index, on, data } = val
      const groupIndex = index ? index[0] : null
      const question: MultiTextQuestionData = { ...this.question }

      if (on === 'input') {
        question.groups[groupIndex] = data
      }

      switch (key) {
        case 'addGroup':
          question.groups.push({
            ...DefaultGroup,
            id: uuid()
          })
          break
        case 'deleteGroup':
          question.groups.splice(groupIndex, 1)
          break
      }

      this.question = question
    }
  }
})
</script>
