<template>
  <v-row
    v-if="value.length"
    class="score-previews"
    no-gutters
    :class="containerClasses"
  >
    <v-col
      v-for="({ value, name, key }, index) in scores"
      :key="`${index}-${key}`"
      :cols="4"
      :md="3"
      class="score-column px-4 py-4"
    >
      <v-row
        class="flex-column section-score mx-0 text-center"
        align="center"
        justify="start"
      >
        <div class="shrink py-1 text-center">
          <span class="score rounded-circle secondary white--text">
            {{ normalizeFloat(value) }}
          </span>
        </div>
        <div class="label grow flex-basis-0">
          <span class="primary--text">
            {{ name }}
          </span>
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { BaseStatsResult, StatsResult } from '@betterboards/shared/types/Statistic'
import { normalizeFloat } from '@betterboards/shared/helpers/util/numbers'

export default Vue.extend({
  name: 'DisplayReportBenchmarkScores',
  components: {},
  props: {
    value: { type: Array as PropType<StatsResult[]>, required: true },
    sm: { type: Boolean, default: false }
  },
  data: () => ({

  }),
  computed: {
    containerClasses (): any {
      return {
        small: this.sm
      }
    },
    scores (): StatsResult[] {
      return this.value.reduce(
        (scores: StatsResult[], score: StatsResult): StatsResult[] => {
          if (score.value) {
            scores.push(score)
          }
          if (score.subSections?.length) {
            score.subSections.forEach((subSectionScore: BaseStatsResult): void => {
              if (subSectionScore.value) {
                scores.push(subSectionScore)
              }
            })
          }
          return scores
        },
        []
      )
    }
  },
  methods: {
    normalizeFloat
  }
})
</script>

<style lang="scss">
.score-previews {
  $ball-size: 40px;
  $font-size: 14px;
  &.small {
    $ball-size: 32px;
    $font-size: 11px;
  }
  font-size: $font-size;

  .score-column {
    border-color: var(--v-grey-lighten2);
    border-style: solid;
    border-width: 0;

    .section-score {
      .label {
        font-size: 1.1em;
        line-height: 1.4;
      }
      .score {
        display: block;
        width: $ball-size;
        height: $ball-size;
        line-height: $ball-size;
        text-align: center;
        font-size: 1.15em;
      }
    }
  }
}
</style>
