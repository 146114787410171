<template>
  <platform-modal
    :value="showModal"
    :width="800"
    :title="$t('form.wizard.createQuestionnaire.newTitle')"
    icon="create-questionnaire"
    :confirm-disabled="!isValid"
    :saving="pendingCreate"
    @validate="$refs.form.validate()"
    @close="showModal = false"
    @confirm="submit"
  >
    <template v-if="selectedCompany" slot="header-append">
      <div class="grow flex-basis-auto">
        <v-row no-gutters class="flex-nowrap text-body-2 grey--text text--darken-2" align="center" justify="center">
          <span class="text-no-wrap">{{ selectedCompany.name }}</span>
          <platform-avatar
            entity-type="company"
            class="ml-2"
            placeholder-size="xs"
            :entity="selectedCompany"
            :size="36"
            :pad-bottom="false"
          />
        </v-row>
      </div>
    </template>
    <questionnaire-form
      v-if="showModal"
      v-model="questionnaire"
      :padded="false"
      :available-packages="availablePackages"
      :company-questionnaires="companyQuestionnaires"
      :loading="pendingFetchTemplates"
      ref="form"
      @valid="(val) => isValid = val"
    />
  </platform-modal>
</template>

<script lang="ts">
import { Questionnaire } from '@/API'
import { listCompanyPackages } from '@/helpers/packages'
import Vue from 'vue'
import { mapState } from 'vuex'
import {
  PlatformCompanyPackage,
  PlatformPackage
} from '@/types/Platform'
import PlatformModal from '@/components/PlatformModal.vue'
import QuestionnaireForm from '@/components/questionnaire/QuestionnaireForm.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import {
  createQuestionnaire,
  DefaultQuestionnaireData
} from '@/helpers/questionnaire'
import API from '@/services/API'
import { questionnairesByCompanyId } from '@/graphql/queries'

export default Vue.extend({
  components: {
    PlatformAvatar,
    QuestionnaireForm,
    PlatformModal
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    questionnaire: DefaultQuestionnaireData(),
    availablePackages: <PlatformPackage[]>[],
    pendingFetchTemplates: false,
    pendingFetchCompanyQuestionnaire: false,
    isValid: false,
    pendingCreate: false,
    companyQuestionnaires: <Questionnaire[]>[]
  }),
  created () {
    this.pendingFetchTemplates = true
    this.fetchAvailablePackages()
      .finally(() => {
        this.pendingFetchTemplates = false
      })
    this.pendingFetchCompanyQuestionnaire = true
    this.getCompanyQuestionnaires()
      .finally(() => {
        this.pendingFetchCompanyQuestionnaire = false
      })
  },
  watch: {
    showModal (val: boolean) {
      if (!val) {
        this.questionnaire = DefaultQuestionnaireData()
      }
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany']),
    showModal: {
      set (val: boolean) {
        this.$emit('input', val)
      },
      get (): boolean {
        return this.value
      }
    }
  },
  methods: {
    async submit (): Promise<void> {
      await this.createQuestionnaire()
      const params: any = {
        id: this.questionnaire.id
      }
      void this.$router.push({
        name: 'QuestionnaireEdit',
        params
      })
    },
    async fetchAvailablePackages (): Promise<void> {
      const selectedCompanyId: string = this.selectedCompany.id
      if (!selectedCompanyId) {
        throw new Error('Failed to fetch Packages as no Company is selected.')
      }

      let companyPackages: PlatformCompanyPackage[]
      this.pendingFetchTemplates = true
      try {
        companyPackages = await listCompanyPackages(selectedCompanyId)
      } catch (err) {
        this.$toasted.error(this.$t('form.wizard.createQuestionnaire.getCompanyPackagesError') as string)
        console.error('Error fetching Company Packages:', JSON.stringify(err, null, 2))
        throw err
      } finally {
        this.pendingFetchTemplates = false
      }

      const packages: PlatformPackage[] = companyPackages
        .map((p: PlatformCompanyPackage): PlatformPackage => p.package)
        .filter((p: PlatformPackage | undefined): p is PlatformPackage => !!p)
      this.availablePackages.splice(0, this.availablePackages.length, ...packages)
    },
    async getCompanyQuestionnaires (): Promise<void> {
      const companyQuestionnaires = await API.graphql({
        query: questionnairesByCompanyId,
        variables: {
          companyId: this.selectedCompany.id
        }
      })
      this.companyQuestionnaires = companyQuestionnaires.data.questionnairesByCompanyId?.items as Questionnaire[]
    },
    async createQuestionnaire (): Promise<void> {
      if (!this.selectedCompany.id) {
        throw new Error('Failed to create questionnaire as no company ID is available.')
      }
      if (!this.questionnaire.name) {
        throw new Error('Failed to create questionnaire as no questionnaire name is available.')
      }
      if (!this.questionnaire.packageId) {
        throw new Error('Failed to create questionnaire as no package ID is available.')
      }

      this.pendingCreate = true
      let questionnaireId: string
      try {
        questionnaireId = await createQuestionnaire({
          name: this.questionnaire.name,
          packageId: this.questionnaire.packageId,
          variants: this.questionnaire.variants,
          companyId: this.selectedCompany.id
        })
      } catch (err) {
        this.$toasted.error(this.$t('form.wizard.createQuestionnaire.createQuestionnaireError') as string)
        console.error('Error when creating a new Questionnaire', JSON.stringify(err, null, 2))
        throw err
      } finally {
        this.pendingCreate = false
      }

      this.questionnaire.id = questionnaireId
    }
  }
})
</script>
