import { PlatformReport, PlatformReportGroup, PlatformReportDocument } from '@betterboards/shared/types/Report'
import { ReportDocumentStatusFields } from '@/helpers/questionnaire'

export function getDefaultReport (reportGroup: PlatformReportGroup): PlatformReport {
  const defaultVariant = reportGroup.variants?.[0]?.variantCode
  if (!defaultVariant) {
    // No variants, default to oldest
    return reportGroup.reports.items.reduce((selectedReport: PlatformReport, report: PlatformReport): PlatformReport => {
      if (selectedReport.createdAt.localeCompare(report.createdAt) < 0) {
        console.debug('getDefaultReport 1', selectedReport, report)
        return selectedReport
      }
      console.debug('getDefaultReport 2', selectedReport, report)
      return report
    })
  }
  const report = reportGroup.reports.items.find(
    (report: PlatformReport) => report.variantCode === defaultVariant
  )
  if (!report) {
    console.error('getDefaultReport failed to pick a default Report.')
    return reportGroup.reports.items[0]
  }
  return report
}

export function getLatestIndividualReport (reports: PlatformReportDocument[], sortKey: ReportDocumentStatusFields): PlatformReportDocument {
  return reports.reduce((latestReport: PlatformReportDocument, report: PlatformReportDocument) => {
    const reportField = report[sortKey]
    const latestReportField = latestReport[sortKey]
    if (!reportField || !latestReportField) {
      return latestReport
    }
    if (reportField.localeCompare(latestReportField) <= 0) {
      return latestReport
    }
    return report
  }, reports[0])
}
