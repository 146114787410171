<template>
  <v-menu
    ref="menu"
    v-model="menuActive"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
    content-class="platform-date-picker"
  >
    <template v-slot:activator="{ on, attrs }">
      <platform-text-field
        ref="input"
        readonly
        :attrs="attrs"
        :on="on"
        :required="required"
        :value="displayDate"
        :loading="loading"
        :disabled="disabled || menuActive"
        :prepend-icon="icon ? 'mdi-calendar' : undefined"
        :rules="inputRules"
        :label="label"
      />
    </template>
    <v-date-picker
      v-model="date"
      :active-picker.sync="pickerType"
      :type="month ? 'month' : undefined"
      :max="maxValue"
      :min="minValue"
      no-title
      scrollable
    />
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue'
import dayjs from 'dayjs'
import { Validators } from '@/helpers/validation'
import PlatformTextField from '@/components/shared/inputs/PlatformTextField.vue'

const DataDateFormat = 'YYYY-MM'
const DisplayDateFormat = 'MMM YYYY'
const OutputDateFormat = 'YYYY-MM-DD'

export default Vue.extend({
  name: 'PlatformDatePicker',
  components: {
    PlatformTextField
  },
  props: {
    value: { type: String, required: false },
    label: { type: String, required: false },
    loading: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    month: { type: Boolean, default: false },
    icon: { type: Boolean, default: true },
    birthday: { type: Boolean, default: false },
    required: { type: Boolean, default: false }
  },
  data: () => ({
    menuActive: false,
    pickerType: 'YEAR'
  }),
  watch: {
    menuActive (val): void {
      if (val) {
        if (this.month) {
          // Select year first
          this.$nextTick(() => {
            this.pickerType = 'YEAR'
          })
        }
      } else {
        const input: any = this.$refs.input
        input.validate(true)
        input.isFocused = false
      }
    },
    date (val) {
      if (val) {
        this.menuActive = false
      }
    }
  },
  computed: {
    date: {
      set (val: string): void {
        this.$emit('input', dayjs(val, DataDateFormat).format(OutputDateFormat))
      },
      get (): string | undefined {
        if (!this.value) {
          return undefined
        }
        return dayjs(this.value, OutputDateFormat).format(DataDateFormat)
      }
    },
    displayDate (): string | undefined {
      if (!this.date) {
        return undefined
      }
      return dayjs(this.date, DataDateFormat).format(DisplayDateFormat)
    },
    maxYearDiff (): number {
      if (this.birthday) {
        // Must be at least 18 years ago
        return -18
      }
      return 0
    },
    range (): number {
      return 100
    },
    maxValue (): string {
      return dayjs().add(this.maxYearDiff, 'years').toISOString()
    },
    minValue (): string {
      return dayjs().add(this.maxYearDiff - this.range, 'years').toISOString()
    },
    inputRules (): any[] | undefined {
      if (!this.required) {
        return undefined
      }
      return [Validators.required]
    }
  }
})
</script>
