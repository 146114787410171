<template>
  <div class="px-6 d-flex">
    <create-company-modal
      v-if="creatingCompany"
      @close="creatingCompany = false"
      @created="companyCreated"
    />
    <v-row
      no-gutters
      class="select-company min-width-0 align-center flex-lg-nowrap"
      style="flex-grow: 1; flex-shrink: 1;"
      :class="{
        'flex-column': !row
      }"
    >
      <v-select
        v-model="selectedCompany"
        class="company-select"
        :items="companyList"
        :loading="fetchingCompany"
        item-text="name"
        item-value="id"
        :label="$t('form.wizard.selectCompany.title')"
        solo
        clearable
        hide-details
        style="min-width: 400px;"
      >
        <template #selection="{ item }">
          <v-row align="center" class="flex-nowrap min-width-0 pr-6">
            <platform-avatar
              entity-type="company"
              class="mx-2"
              placeholder-size="xs"
              :pad-bottom="false"
              :entity="item"
              :size="40"
            />
            <div class="shrink overflow-ellipses">
              <span class="text-no-wrap">{{ item.name }}</span>
            </div>
          </v-row>
        </template>
        <template #item="{ item }">
          <v-row align="center" class="flex-nowrap">
            <platform-avatar
              entity-type="company"
              class="mx-2"
              placeholder-size="xs"
              :pad-bottom="false"
              :entity="item"
              :size="36"
            />
            <div class="company-select__item shrink">
              <span>{{ item.name }}</span>
            </div>
          </v-row>
        </template>
      </v-select>
      <template v-if="createAction">
        <p class="text-center my-4 mx-4">
          {{ $t('form.wizard.selectCompany.or') }}
        </p>
        <platform-button
          secondary
          @click="creatingCompany = true"
        >
          {{ $t('form.wizard.selectCompany.createCompanyAction') }}
        </platform-button>
      </template>
    </v-row>
  </div>
</template>

<script lang="ts">
import { ignoreParentCompanyError } from '@/helpers/company'
import Vue from 'vue'
import CreateCompanyModal from '@/components/companies/CreateCompanyModal.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'

export default Vue.extend({
  name: 'SelectCompanyForm',
  props: {
    value: { type: String, required: false },
    fetchingCompany: { type: Boolean, required: false },
    createAction: { type: Boolean, default: true },
    row: { type: Boolean, default: false }
  },
  data: () => ({
    creatingCompany: false
  }),
  components: {
    PlatformAvatar,
    CreateCompanyModal
  },
  computed: {
    selectedCompany: {
      get (): string {
        return this.value
      },
      async set (id: string): Promise<void> {
        try {
          this.$store.commit('Company/setTargetSelectCompanyId', id)
          await this.$store.dispatch('Company/selectCompany', { id })
        } catch (err) {
          if (!ignoreParentCompanyError(err)) {
            throw err
          }
        }
        this.$emit('input', id)
      }
    },
    companyList (): any[] {
      return this.$store.state.Company.list.filter((company) => company.id && company.name)
    }
  },
  methods: {
    companyCreated (company): void {
      this.selectedCompany = company.id
      this.creatingCompany = false
    }
  }
})
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins.scss';

.select-company {
  max-width: 450px;
}
.company-select {
  min-width: 300px;

  &__item {
    @include line-clamp(2);
  }
  ::v-deep {
    .v-select__selections input {
      display: none;
    }
  }
}
</style>
