import { CompanyUserPackage, ListCompanyUserPackagesByCompanyUserQuery, ListFullCompanyPackagesQuery, PackageItemTypes } from '@/API'
import { getFullCompanyPackageItems } from '@/graphql/custom/getFullCompanyPackageItems'
import { listFullCompanyPackages } from '@/graphql/custom/listFullCompanyPackages'
import { listCompanyUserPackagesByCompanyUser as listCompanyUserPackagesQuery } from '@/graphql/queries'
import {
  createCompanyUserPackage as createCompanyUserPackageMutation,
  deleteCompanyUserPackage as deleteCompanyUserPackageMutation
} from '@/graphql/mutations'
import { PlatformCompanyPackage, PlatformPackage, PlatformPackageItem } from '@/types/Platform'
import { GraphQLResult } from '@aws-amplify/api-graphql'
// import { Amplify } from 'aws-amplify'
import API from '@/services/API'
import { fetchAuthSession } from 'aws-amplify/auth'
import { SurveyConfiguration } from '@/types/Survey'

export enum SurveyTagType {
  PackageCategory = 'PackageCategory'
}

export const NullPackageIdValue = 'null'

function normalizePackageItems (packageItems: PlatformPackageItem[]): PlatformPackageItem[] {
  return packageItems.map((pi) => {
    if (typeof pi.content === 'string') {
      try {
        pi.content = JSON.parse(pi.content)
      } catch (err) {
        console.warn('Failed to parse JSON content of PackageItem.', { pi, err })
      }
    }
    return pi
  })
}

function normalizePackage (p: PlatformPackage): PlatformPackage {
  if (p.packageItems?.items) {
    p.packageItems.items = normalizePackageItems(p.packageItems.items)
  }
  return p
}

export async function listCompanyPackages (companyId: string): Promise<PlatformCompanyPackage[]> {
  const session = await fetchAuthSession()
  const idToken: string | undefined = session.tokens?.idToken?.toString()
  if (!idToken) {
    throw new Error('Failed to authenticate request to delete CompanyUserPackage.')
  }

  const companyPackagesResponse: GraphQLResult<ListFullCompanyPackagesQuery> = await API.graphql({
    query: listFullCompanyPackages,
    variables: {
      companyId: companyId
    },
    authMode: 'lambda',
    authToken: `Bear2r ${idToken}`
  }) as GraphQLResult<ListFullCompanyPackagesQuery>
  if (companyPackagesResponse.errors) {
    console.error('Failed to fetch Company Packages. Response:', JSON.stringify(companyPackagesResponse))
    throw new Error('Failed to list Company Packages.')
  }
  const companyPackages = companyPackagesResponse.data?.listCompanyPackages?.items as PlatformCompanyPackage[] | undefined
  if (!companyPackages?.length) {
    console.warn('Company has no Packages.')
    return []
  }

  return companyPackages.map((companyPackage: PlatformCompanyPackage) => {
    if (companyPackage?.package) {
      companyPackage.package = normalizePackage(companyPackage.package)
    }
    return companyPackage
  })
}

export async function getCompanyPackageItems (companyId: string, packageId: string, type: PackageItemTypes | undefined, key?: string): Promise<PlatformCompanyPackage> {
  const session = await fetchAuthSession()
  const idToken: string | undefined = session.tokens?.idToken?.toString()
  if (!idToken) {
    throw new Error('Failed to authenticate request to delete CompanyUserPackage.')
  }

  const companyPackageResponse = await API.graphql({
    query: getFullCompanyPackageItems,
    variables: {
      companyId,
      packageId,
      type: type ? { eq: type } : undefined,
      key: key ? { eq: key } : undefined
    },
    authMode: 'lambda',
    authToken: `Bear2r ${idToken}`
  })
  if (companyPackageResponse.errors) {
    console.error('Failed to fetch Company Package Item. Response:', JSON.stringify(companyPackageResponse))
    throw new Error('Failed to get Company Package Item.')
  }
  const companyPackage: PlatformCompanyPackage = companyPackageResponse.data.getCompanyPackage as unknown as PlatformCompanyPackage
  if (!companyPackage) {
    console.warn('Company has no Packages.')
  }

  if (companyPackage?.package) {
    companyPackage.package = normalizePackage(companyPackage.package)
  }

  return companyPackage
}

export async function listCompanyUserPackages (companyId: string, cognitoIdentityId: string): Promise<CompanyUserPackage[]> {
  const session = await fetchAuthSession()
  const idToken: string | undefined = session.tokens?.idToken?.toString()
  if (!idToken) {
    throw new Error('Failed to authenticate request to delete CompanyUserPackage.')
  }

  const companyPackagesResponse = await API.graphql({
    query: listCompanyUserPackagesQuery,
    variables: {
      cognitoIdentityId,
      companyId: {
        eq: companyId
      }
    },
    authMode: 'lambda',
    authToken: `Bear2r ${idToken}`
  }) as { data?: ListCompanyUserPackagesByCompanyUserQuery, errors?: any }
  const companyPackages = companyPackagesResponse.data?.listCompanyUserPackagesByCompanyUser?.items as CompanyUserPackage[] | undefined
  if (companyPackagesResponse.errors || !companyPackages) {
    console.error('Failed to fetch Company User Packages. Response:', JSON.stringify(companyPackagesResponse))
    throw new Error('Failed to list Company User Packages.')
  }
  if (!companyPackages.length) {
    console.warn('CompanyUser has no Packages.')
  }

  return companyPackages
}

export async function createCompanyUserPackage (companyId: string, cognitoIdentityId: string, packageId: string): Promise<void> {
  const session = await fetchAuthSession()
  const idToken: string | undefined = session.tokens?.idToken?.toString()
  if (!idToken) {
    throw new Error('Failed to authenticate request to delete CompanyUserPackage.')
  }

  const createCompanyUserPackageResponse = await API.graphql({
    query: createCompanyUserPackageMutation,
    variables: {
      input: {
        companyId,
        cognitoIdentityId,
        packageId
      }
    },
    authMode: 'lambda',
    authToken: `Bear2r ${idToken}`
  })
  console.info('createCompanyUserPackageResponse', createCompanyUserPackageResponse)
  if (createCompanyUserPackageResponse.errors) {
    console.error('Failed to create CompanyUserPackage. Response:', JSON.stringify(createCompanyUserPackageResponse))
    throw new Error('Failed to create CompanyUserPackage.')
  }
}

export async function deleteCompanyUserPackage (companyId: string, cognitoIdentityId: string, packageId: string): Promise<void> {
  const session = await fetchAuthSession()
  const idToken: string | undefined = session.tokens?.idToken?.toString()
  if (!idToken) {
    throw new Error('Failed to authenticate request to delete CompanyUserPackage.')
  }

  const deleteCompanyUserPackageResponse = await API.graphql({
    query: deleteCompanyUserPackageMutation,
    variables: {
      input: {
        companyId,
        cognitoIdentityId,
        packageId
      }
    },
    authMode: 'lambda',
    authToken: `Bear2r ${idToken}`
  })
  console.info('deleteCompanyUserPackageResponse', deleteCompanyUserPackageResponse)
  if (deleteCompanyUserPackageResponse.errors) {
    console.error('Failed to delete CompanyUserPackage. Response:', JSON.stringify(deleteCompanyUserPackageResponse))
    throw new Error('Failed to delete CompanyUserPackage.')
  }
}

export async function fetchSurveyConfiguration (companyId: string, packageId: string): Promise<SurveyConfiguration | undefined> {
  if (!packageId || !companyId) {
    return
  }
  const companyPackage: PlatformCompanyPackage = await getCompanyPackageItems(
    companyId,
    packageId,
    PackageItemTypes.SurveyConfiguration
  )
  const packageItems: PlatformPackageItem[] = companyPackage.package.packageItems?.items
  if (!packageItems) {
    return
  }
  const surveyConfiguration: PlatformPackageItem<SurveyConfiguration | null> | undefined = packageItems.find<PlatformPackageItem<SurveyConfiguration | null>>(
    (pi: PlatformPackageItem): pi is PlatformPackageItem<SurveyConfiguration> => pi.type === PackageItemTypes.SurveyConfiguration
  )
  if (!surveyConfiguration?.content) {
    return
  }
  return surveyConfiguration.content
}
