var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pt-6 pb-8"},[(_vm.creatingIndividual)?_c('create-individual-modal',{attrs:{"individual":_vm.creatingIndividual},on:{"close":function($event){_vm.creatingIndividual = false},"created":_vm.individualCreated}}):_vm._e(),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[(_vm.loading)?_c('platform-spinner',{staticClass:"my-3 mx-auto"}):[_vm._l((_vm.companyIndividuals),function(individual){return _c('v-col',{key:individual.id,staticClass:"individual--column cursor-pointer",on:{"mouseenter":function($event){return _vm.focusIndividual(individual.id)},"mouseleave":_vm.unfocusIndividual,"click":function($event){return _vm.toggleIndividual(individual)}}},[_c('platform-entity-card',{staticClass:"individual--card",class:{
            'individual--card__hidden': _vm.focusing && _vm.focusedIndividualId === individual.id
          },attrs:{"align":"center","compact-header":"","entity-type":"individual","entity":individual,"avatar-size":90,"inline-header":false,"image":false,"inner-margin":0,"visible-statuses":_vm.visibleEntityCardStatuses},scopedSlots:_vm._u([{key:"headerImage",fn:function(){return [_c('div',{staticClass:"text-center individual--avatar",class:{
                selected: _vm.isIndividualSelected(individual),
                disabled: _vm.saving
              },attrs:{"data-test-target":"committeeFormAvatarField"}},[_c('platform-avatar',{staticClass:"avatar",attrs:{"entity-type":"individual","entity":individual,"size":100}})],1)]},proxy:true}],null,true)},[_c('span',{staticClass:"text-caption grey--text text--darken-1"},[_vm._v(" "+_vm._s(_vm.getJobTitle(individual))+" ")])]),_c('div',{staticClass:"individual--preview text-center"},[_c('div',{staticClass:"text-center individual--avatar",class:{
              selected: _vm.isIndividualSelected(individual),
              disabled: _vm.saving
            },attrs:{"data-test-target":"committeeFormAvatarField"}},[_c('platform-avatar',{staticClass:"avatar",attrs:{"entity-type":"individual","entity":individual,"size":100}})],1),_c('span',[_vm._v(" "+_vm._s(_vm.getPreferredName(individual))+" ")])])],1)}),(_vm.allowCreateIndividual)?_c('div',{staticClass:"individual--column create-individual--column"},[_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('page.individuals.selector.createTooltip')),expression:"$t('page.individuals.selector.createTooltip')"}],staticClass:"create-individual--container d-flex flex-column align-center justify-center cursor-pointer",on:{"click":_vm.openCreateIndividualModal}},[_c('div',{staticClass:"create-individual--button elevation-3 rounded-circle mb-1"},[_c('platform-icon',{staticClass:"pa-4",attrs:{"name":"user-plus","color":"primary lighten-1","size":68}})],1),_c('span',{staticClass:"create-individual--label text-body-2"},[_vm._v(" New Participant ")])])]):_vm._e()]],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }