<template>
  <v-row
    no-gutters
    ref="viewerWrapper"
    class="viewer-wrapper mx-auto flex-column flex-nowrap px-4 py-3"
    justify="center"
  >
    <platform-spinner
      v-if="pendingLoad"
      class="mx-auto"
      color="black"
      :size="50"
      :width="5"
    />
    <div class="pdf-padded pb-1">
      <platform-button
        primary
        text
        icon="arrow-left"
        @click="previousPage"
      />
      <v-chip outlined color="primary" class="px-1">
        <div class="d-inline-flex flex-nowrap align-center text-body-2 mx-3">
          <platform-number-field
            hide-details
            hide-spin-buttons
            v-model.number="currentPage"
            class="page-number--input mt-0 pt-0"
          />
          <div class="d-inline-flex flex-nowrap align-center text-no-wrap pl-1">
            / {{ totalPages }}
          </div>
        </div>
      </v-chip>
      <platform-button
        primary
        text
        icon="arrow-right"
        @click="nextPage"
      />
      <platform-button
        v-if="showZoom"
        primary
        text
        icon="zoom-out"
        @click="zoomOut"
      />
      <platform-button
        v-if="showZoom"
        primary
        text
        icon="zoom-in"
        @click="zoomIn"
      />
    </div>
    <div class="pdf-wrapper">
      <div
        v-show="!pendingLoad"
        class="pdf-container pdf-padded d-flex flex-column"
        ref="pdfContainer"
      >
        <div
          class="pdf-viewer"
          ref="pdfViewer"
        />
      </div>
    </div>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'
// import * as pdfjs from 'pdfjs-dist'
import * as pdfjs from 'pdfjs-dist/webpack.mjs'
// import * as pdfWorker from 'pdfjs-dist/build/pdf.worker.mjs'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'
import { OnProgressParameters, PDFDocumentLoadingTask } from 'pdfjs-dist'
import * as PDFJSViewer from 'pdfjs-dist/web/pdf_viewer'
import 'pdfjs-dist/web/pdf_viewer.css'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import PlatformNumberField from '@/components/shared/inputs/PlatformNumberField.vue'

// pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker
const eventBus = new PDFJSViewer.EventBus()
const pdfLinkService = new PDFJSViewer.PDFLinkService({
  eventBus
})

const ScrollNudgeAmount: number = 100

export default Vue.extend({
  components: {
    PlatformNumberField,
    PlatformSpinner
  },
  data () {
    return {
      pdf: <PDFDocumentProxy | undefined>undefined,
      pdfViewer: <PDFJSViewer.PDFViewer | undefined>undefined,
      loadedBytes: 0,
      totalBytes: 0,
      pendingLoad: false
    }
  },
  mounted (): void {
    this.init()
  },
  beforeDestroy (): void {
    this.reset()
  },
  computed: {
    currentPage: {
      set (currentPage: number): void {
        if (!this.pdfViewer) {
          throw new Error('Unable to change page as PDF is not yet initialized.')
        }
        this.pdfViewer.currentPageNumber = currentPage
      },
      get (): number | undefined {
        return this.pdfViewer?.currentPageNumber
      }
    },
    fileName (): string {
      const path = this.$route.query.file
      return `${window.location.protocol}//${window.location.host}/${path}`
    },
    totalPages (): number | undefined {
      return this.pdfViewer?.pagesCount || undefined
    },
    showZoom (): boolean {
      return false
    }
  },
  methods: {
    async init (): Promise<void> {
      document.addEventListener('keydown', this.onKeyDown)
      const container: HTMLDivElement | undefined = this.$refs.pdfContainer as HTMLDivElement | undefined
      const viewer: HTMLDivElement | undefined = this.$refs.pdfViewer as HTMLDivElement | undefined
      if (!container || !viewer) {
        throw new Error('Unable to initialize PDF page before viewer elements are rendered.')
      }
      this.pdfViewer = new PDFJSViewer.PDFViewer({
        container,
        viewer,
        eventBus,
        linkService: pdfLinkService
      })
      pdfLinkService.setViewer(this.pdfViewer)
      const pdfLoadingTask: PDFDocumentLoadingTask = pdfjs.getDocument(this.fileName)
      this.pendingLoad = true
      pdfLoadingTask.onProgress = (progress: OnProgressParameters): void => {
        if (progress.loaded >= progress.total) {
          this.pendingLoad = false
          return
        }
        this.loadedBytes = progress.loaded
        this.totalBytes = progress.total
      }
      this.pdf = await pdfLoadingTask.promise
      this.pdfViewer.setDocument(this.pdf)
      pdfLinkService.setDocument(this.pdf, null)

      // await this.renderPage(1)

      this.pdfViewer.eventBus.on('pagesloaded', (evt: any): void => {
        console.log('pagesloaded fired', evt) // deleted `evt` from the trace below
        const containerStyle = window.getComputedStyle(container, null)
        const paddingLeft: number = parseInt(containerStyle.getPropertyValue('padding-left').replace('px', '')) * 2
        const paddingRight: number = parseInt(containerStyle.getPropertyValue('padding-right').replace('px', '')) * 2
        const newWidth: number = container.offsetWidth + paddingLeft + paddingRight
        console.debug('container width:', container?.clientWidth, container?.offsetWidth, window.getComputedStyle(container, null).getPropertyValue('padding-left'))
        console.debug('newWidth:', newWidth)
        const viewerWrapper: any | undefined = this.$refs.viewerWrapper
        if (viewerWrapper) {
          viewerWrapper.style.width = `${newWidth}px`
        }
      })
      this.$nextTick(() => {
      })
    },
    reset (): void {
      document.removeEventListener('keydown', this.onKeyDown)
    },
    onKeyDown (event: KeyboardEvent): void {
      const container: HTMLDivElement | undefined = this.$refs.pdfContainer as HTMLDivElement | undefined
      if (!this.pdfViewer || !container) {
        return
      }
      switch (event.key) {
        case 'ArrowDown':
          container.scrollTop += ScrollNudgeAmount
          break
        case 'ArrowRight':
          this.nextPage()
          break
        case 'ArrowUp':
          container.scrollTop -= ScrollNudgeAmount
          break
        case 'ArrowLeft':
          this.previousPage()
          break
      }
    },
    previousPage (): void {
      this.pdfViewer?.previousPage()
    },
    nextPage (): void {
      this.pdfViewer?.nextPage()
    },
    zoomIn (): void {
      if (!this.pdfViewer) {
        return
      }
      // const scaleFactor: number = this.pdfViewer.currentScale > 2
      //   ? 0.5
      //   : 1
      const scaleFactor: number = 1.0
      console.debug('zoomed in, old scale:', this.pdfViewer.currentScale)
      this.pdfViewer.increaseScale({
        scaleFactor,
        steps: 1,
        drawingDelay: 300
      })
      console.debug('zoomed in,  new scale:', this.pdfViewer.currentScale)
    },
    zoomOut (): void {
      if (!this.pdfViewer) {
        return
      }
      const scaleFactor: number = 1.0
      console.debug('zoomed out, old scale:', this.pdfViewer.currentScale)
      this.pdfViewer.decreaseScale({
        scaleFactor,
        steps: 1,
        drawingDelay: 300
      })
      console.debug('zoomed out,  new scale:', this.pdfViewer.currentScale)
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.viewer-wrapper {
  //flex-grow: 0;
  overflow: hidden;
  min-width: 850px;

  .page-number--input {
    width: 25px;
    border: 1px solid #eeeeee;

    ::v-deep input {
      text-align: right;
    }
  }

  .pdf-padded {
    padding: 12px;
  }

  .pdf-wrapper {
    position: relative;
    height: 100%;
    border-radius: 3px;
    background-color: white;

    .pdf-container {
      overflow-y: auto;
      overflow-x: visible;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      .pdf-viewer {
        &::v-deep .page {
          position: relative;
          margin-bottom: 12px;
          box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2),
                      0 3px 4px 0 rgba(0, 0, 0, 0.14),
                      0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
        }
      }
    }
  }
}
</style>
