import { GraphResult, ProgressSection, ResultType, SectionResults } from '@betterboards/shared/types/ResultSet'
import { RepeatForOptions } from '@/helpers/questionnaire/questions'
import { i18n } from '@/plugins/i18n'
import { ResultGroup } from '@/types/ResultSet'
import { CompanyConfiguration } from '@betterboards/shared/types/Company'
import { getColor } from '@/helpers/company/configuration'
import { getColorTransparent, lightenColor } from '@/helpers/colors'
import { GraphColors, GraphTypes } from '@betterboards/graphs/types/Graph'

export function getRepeatForCriteriaFriendlyName (criteria: RepeatForOptions, plural: boolean = false): string {
  return i18n.tc(`shared.blocks.repeatFor.options.${criteria}`, plural ? 2 : undefined)
}

export function getTip (criteria: RepeatForOptions): string | undefined {
  const tip = i18n.t(`shared.graphs.repeatFor.tips.${criteria as string}`) as string
  return `* ${tip}`
}

export function hasTip (result: ResultType): boolean {
  if (result.graphType) {
    if (Array.isArray(result.data) && result.data.some((d) => !!d.votes)) {
      return true
    }
  }
  return false
}

export function hasLegend (result: ResultType): boolean {
  if (result.graphType) {
    return true
  }
  return false
}

export enum LineGraphProperty {
  Self = 'self',
  Average = 'avg',
  SelfComparison = 'selfComparison',
  AverageComparison = 'avgComparison',
  Importance = 'importance',
  Development = 'development',
  ImportanceExternal = 'importanceExternal',
  DevelopmentExternal = 'developmentExternal',
  ImportanceComparison = 'importanceComparison',
  DevelopmentComparison = 'developmentComparison',
}

export function getGraphLegendLabel (property: LineGraphProperty, criteria?: RepeatForOptions): string | undefined {
  if (criteria && !RepeatForOptions[criteria]) {
    return undefined
  }
  const key = criteria
    ? `shared.graphs.line.legend.${property}.${criteria}`
    : `shared.graphs.line.legend.${property}`
  return i18n.t(key) as string
}

export type ResultSection = SectionResults | ProgressSection

export function getDefaultGraphConfig (
  companyConfig: CompanyConfiguration
): any {
  const config: any = {
    scale: 1
  }
  const primaryBaseVal = companyConfig.report?.graphs?.primaryColor ?? companyConfig.report?.primaryColor ?? 'primary'
  const secondaryBaseVal = companyConfig.report?.graphs?.secondaryColor ?? companyConfig.report?.secondaryColor ?? 'secondary'
  const primaryAlternate = companyConfig.report?.primaryAlternateColor
  const secondaryAlternate = companyConfig.report?.secondaryAlternateColor
  const primary = getColor(companyConfig, primaryBaseVal)
  const secondary = getColor(companyConfig, secondaryBaseVal)
  if (primary || secondary) {
    config.colors = {
      ...GraphColors
    }
    if (primary) {
      config.colors.primary = primary
      config.colors.primaryAlternate = primaryAlternate ?? lightenColor(primary, 0.4)
      config.colors.primaryAlternateTransparent = getColorTransparent(config.colors.primaryAlternate, 0.8)
    }
    if (secondary) {
      config.colors.secondary = secondary
      config.colors.secondaryAlternate = secondaryAlternate ?? lightenColor(secondary, 0.4)
      config.colors.secondaryAlternateTransparent = getColorTransparent(config.colors.secondaryAlternate, 0.8)
    }
  }
  return config
}

export function getGraphResultConfig (
  result: GraphResult | ResultGroup,
  baseConfig: any | undefined,
  companyConfig: CompanyConfiguration,
  legend: boolean = false,
  tickLabels: boolean = false
): any | undefined {
  if (!result?.graphType) {
    return undefined
  }
  const config = JSON.parse(JSON.stringify(
    baseConfig ?? getDefaultGraphConfig(companyConfig)
  ))
  const key = result.graphType.toLowerCase()
  const graphTypeConfig = companyConfig.report?.graphs?.[key]
  if (graphTypeConfig) {
    if (graphTypeConfig.primaryColor) {
      config.colors.primary = getColor(companyConfig, graphTypeConfig.primaryColor)
      config.colors.primaryAlternate = lightenColor(config.colors.primary, 0.4)
      config.colors.primaryAlternateTransparent = getColorTransparent(config.colors.primaryAlternate, 0.8)
    }
    if (graphTypeConfig.secondaryColor) {
      config.colors.secondary = getColor(companyConfig, graphTypeConfig.secondaryColor)
      config.colors.secondaryAlternate = lightenColor(config.colors.secondary, 0.4)
      config.colors.secondaryAlternateTransparent = getColorTransparent(config.colors.secondaryAlternate, 0.8)
    }
  }
  if (legend) {
    config.legend = true
  }
  if (tickLabels) {
    config.tickLabels = true
  }
  switch (result.graphType) {
    case GraphTypes.Variance:
    case GraphTypes.Scale:
      if (!config.properties) {
        config.properties = {}
      }
      config.properties.answersVariance = i18n.t('shared.graphs.scale.legend.answersVariance') as string
      config.properties.answersVarianceComparison = i18n.t('shared.graphs.scale.legend.answersVarianceComparison') as string
      config.properties.average = i18n.t('shared.graphs.scale.legend.average') as string
      config.properties.averageComparison = i18n.t('shared.graphs.scale.legend.averageComparison') as string
      break
    case GraphTypes.Scatter:
      if (!config.axes) {
        config.axes = {
          x: {
            label: i18n.t('shared.graphs.scatter.legend.importance') as string
          },
          y: {
            label: i18n.t('shared.graphs.scatter.legend.development') as string
          }
        }
      }
      if (!config.highlight) {
        config.highlight = {
          minX: 4,
          minY: 4,
          label: i18n.t('shared.graphs.scatter.legend.opportunityAreasTitle') as string,
          sublabel: i18n.t('shared.graphs.scatter.legend.opportunityAreasSublabel') as string
        }
      }
      break
  }
  return config
}
