<template>
  <div
    v-html="htmlContent"
    class="markdown-content"
  />
</template>

<script lang="ts">
import Vue from 'vue'
import showdown from 'devextreme-showdown'

const converter = new showdown.Converter()

export default Vue.extend({
  name: 'DisplayMarkdown',
  props: {
    value: { type: String, required: true }
  },
  computed: {
    htmlContent (): string {
      return converter.makeHtml(this.value)
        .trim()
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.markdown-content {
  &::v-deep p {
    margin-bottom: 4px !important;
  }
}
</style>
