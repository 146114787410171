import PlatformButton from '@/components/shared/PlatformButton.vue'
import PlatformIcon from '@/components/shared/PlatformIcon.vue'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './scss/index.scss'
import vuetify from './plugins/vuetify'
import './plugins/amplify'
import './plugins/toasted'
import './plugins/tooltip'
import './plugins/dayjs'
import './helpers/GlobalComponents'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import { ExtraErrorData as ExtraErrorDataIntegration } from '@sentry/integrations'
import { i18n } from '@/plugins/i18n'
import AnalyticsPlugin from '@/plugins/analytics'

Vue.config.productionTip = false

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

Vue.directive('hide', (el, binding) => {
  el.style.visibility = binding.value ? 'hidden' : 'visible'
})

Vue.component('platform-button', PlatformButton)
Vue.component('platform-icon', PlatformIcon)

Vue.use(AnalyticsPlugin, {
  disabled: isLocalhost
})

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

if (!isLocalhost) {
  Sentry.init({
    Vue,
    dsn: 'https://714f87322ee5488398cd665e998cde42@o563150.ingest.sentry.io/5709617',
    logErrors: !!isLocalhost,
    release: `betterboards-nouveau@${window.location.hostname}`,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'app.test.better-boards.io', 'app.staging.better-boards.io', 'app.better-boards.com', /^\//]
      }),
      new ExtraErrorDataIntegration({
        depth: 10
      })
    ],
    environment: process.env.VUE_APP_PLATFORM_ENV,
    tracesSampleRate: 1.0,
    normalizeDepth: 10
  })
}
