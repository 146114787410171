<template>
  <v-form-base
    :model="question"
    :schema="schema"
    @click="onInput"
    @input="onInput"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import { MultipleChoiceQuestionData } from '@/types/Question'
import { RepeatForOptions } from '@/helpers/questionnaire/questions'

export default Vue.extend({
  name: 'MultiChoiceQuestionForm',
  props: {
    value: { type: Object as PropType<MultipleChoiceQuestionData>, required: false },
    disabled: { type: Boolean, required: false }
  },
  components: {
    VFormBase
  },
  computed: {
    question () {
      return {
        ...this.value
      }
    },
    schema () {
      const schema = {
        optionsType: {
          type: 'select',
          col: 6,
          label: 'Answer Choices',
          items: [
            RepeatForOptions.BoardMembers
          ],
          disabled: this.disabled
        },
        // maxAnswers: {
        //   type: 'number',
        //   label: 'Maximum Number of Choices',
        //   col: 6,
        //   min: 1,
        //   max: 99
        // }
        optionsTarget: {
          type: 'BBTargetRules',
          label: 'Choices Targeting',
          col: { xs: 12, sm: 5, lg: 6, xl: 3 },
          criteriaValue: this.value?.optionsType,
          disabled: this.disabled
        }
      }

      return schema
    }
  },
  methods: {
    onInput (val) {
      this.$emit('input', val.data)
    }
  }
})
</script>
