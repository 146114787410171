<template>
  <div class="individual-appointments-field d-inline-flex">
    <platform-menu
      v-model="menuActive"
      :max-width="350"
      :nudge-top="20"
    >
      <template #default>
        <individual-appointments-form
          v-model="appointments"
          :total-appointments="totalAppointments"
          @close="menuActive = false"
        />
      </template>
      <template #activator="{ on, attrs }">
        <platform-text-field
          readonly
          append-icon="mdi-menu-down"
          class="appointments__input"
          :on="on"
          :attrs="attrs"
          :class="{ active: menuActive }"
          :label="inputLabel"
          :placeholder="inputPlaceholder"
          :value="inputValueHint"
          @click:append="on.click"
        />
      </template>
    </platform-menu>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformIndividualAppointments } from '@betterboards/shared/types/Individual'
import PlatformMenu from '@/components/shared/inputs/PlatformMenu.vue'
import PlatformTextField from '@/components/shared/inputs/PlatformTextField.vue'
import IndividualAppointmentsForm from '@/components/individuals/IndividualAppointmentsForm.vue'

export default Vue.extend({
  name: 'IndividualAppointmentsField',
  components: {
    IndividualAppointmentsForm,
    PlatformTextField,
    PlatformMenu
  },
  props: {
    value: { type: Object as PropType<PlatformIndividualAppointments>, required: true }
  },
  data: () => ({
    menuActive: false
  }),
  computed: {
    appointments: {
      get (): PlatformIndividualAppointments {
        return this.value
      },
      set (appointments: PlatformIndividualAppointments): void {
        this.$emit('input', appointments)
      }
    },
    inputLabel (): string {
      return this.$t('form.individual.boardAppointments') as string
    },
    inputPlaceholder (): string {
      return this.$t('form.individual.boardAppointmentsHint', [this.totalAppointments]) as string
    },
    inputValueHint (): string | undefined {
      if (!this.totalAppointments) {
        return undefined
      }
      return this.$t('form.individual.boardAppointmentsHint', [this.totalAppointments]) as string
    },
    totalAppointments (): number {
      if (!this.value) {
        return 0
      }
      return Object.values(this.value).reduce(
        (total: number, count: number): number => total + count,
        0
      )
    }
  },
  methods: {
    //
  }
})
</script>

<style lang="scss" scoped="scoped">
.individual-appointments-field {
  width: 100%;
}
.appointments__input {
  ::v-deep .v-icon {
    transition: transform 200ms linear;
  }
  &.active ::v-deep .v-icon {
    transform: rotate(180deg);
  }
}
</style>
