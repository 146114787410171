/* tslint:disable */
/* eslint-disable */
import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const listFullCompanyPackages = /* GraphQL */ `
  query ListFullCompanyPackages(
    $companyId: ID
    $packageId: ModelIDKeyConditionInput
    $filter: ModelCompanyPackageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyPackages(
      companyId: $companyId
      packageId: $packageId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        packageId
        inherited
        public
        package {
          id
          categories
          name
          description
          variants
          createdAt
          updatedAt
          packageItems {
            items {
              packageId
              type
              key
              content
              createdAt
              updatedAt
            }
            nextToken
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.ListFullCompanyPackagesQueryVariables,
  APITypes.ListFullCompanyPackagesQuery
>
