import * as vuex from 'vuex'
import API from '@/services/API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { CreateTeamIndividualMutation, DeleteTeamIndividualMutation } from '@/API'
import { createTeamIndividual, deleteTeamIndividual } from '@/graphql/mutations'
import { PlatformTeamIndividual } from '@betterboards/shared/types/Team'

const TeamStore: vuex.Module<any, any> = {
  namespaced: true,
  actions: {
    async createTeamIndividual ({ rootState }, payload: Partial<PlatformTeamIndividual>): Promise<PlatformTeamIndividual> {
      const { companyId, individualId, teamId } = payload
      if (!companyId || !individualId || !teamId) {
        throw new Error('Unable to create TeamIndividual as required field was not provided.')
      }
      let response
      try {
        response = await API.graphql({
          query: createTeamIndividual,
          variables: {
            input: {
              companyId,
              individualId,
              teamId
            }
          }
        }) as GraphQLResult<CreateTeamIndividualMutation>
      } catch (err) {
        console.error('Failed to create TeamIndividual.', { payload, err })
        throw err
      }

      const teamIndividual = response.data?.createTeamIndividual

      return teamIndividual as PlatformTeamIndividual
    },
    async deleteTeamIndividual ({ rootState }, payload: Partial<PlatformTeamIndividual>): Promise<PlatformTeamIndividual> {
      const { companyId, individualId, teamId } = payload
      if (!companyId || !individualId || !teamId) {
        throw new Error('Unable to delete TeamIndividual as required field was not provided.')
      }
      let response
      try {
        response = await API.graphql({
          query: deleteTeamIndividual,
          variables: {
            input: {
              companyId,
              individualId,
              teamId
            }
          }
        }) as GraphQLResult<DeleteTeamIndividualMutation>
      } catch (err) {
        console.error('Failed to delete TeamIndividual.', { payload, err })
        throw err
      }

      const teamIndividual = response.data?.deleteTeamIndividual

      return teamIndividual as PlatformTeamIndividual
    }
  }
}

export default TeamStore
