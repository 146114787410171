import { UserType } from '../../../types/API'
import { TargetGroupOptions } from '../../../types/Block'
import { ResultsIndividual } from '../../../types/ResultSet'
import { $t } from '../../../translations/index'

export enum IndividualReligionFriendlyNames {
  None = 'None',
  Christian = 'Christian',
  Buddhist = 'Buddhist',
  Hindu = 'Hindu',
  Jewish = 'Jewish',
  Muslim = 'Muslim',
  Sikh = 'Sikh',
  Other = 'Other'
}

export enum EthnicDesignationGroupFriendlyNames {
  White = 'White',
  MixedMultiple = 'Mixed or Multiple ethnic groups',
  AsianAsianBritish = 'Asian or Asian British',
  BlackAfricanCaribbeanBlackBritish = 'Black, African, Caribbean or Black British',
  Other = 'Other ethnic group'
}

export enum TenureLengthStatus {
  Danger = 'danger',
  Warning = 'warning',
  Okay = 'okay',
  None = 'none',
}

export const IndividualAppointmentsOrder: string[] = [
  'Chair',
  'SID',
  'NonExecutive',
  'CommitteeChair',
  'Other'
]

export const IndividualTenureRoleOrder: string[] = [
  'chair',
  'nonExecutive',
  'executive'
]

export const IndividualRoleOrder: UserType[] = Object.values(UserType)

export const TargetGroupsOrder: TargetGroupOptions[] = Object.values(TargetGroupOptions)

export function getCompanyPosition (userRole: UserType): TargetGroupOptions | undefined {
  switch (userRole) {
    case UserType.CEO:
    case UserType.CFO:
    case UserType.CIO:
    case UserType.COO:
    case UserType.CMO:
    case UserType.CPO:
    case UserType.CSO:
    case UserType.CNO:
    case UserType.OtherExecutive:
    case UserType.MD:
      return TargetGroupOptions.Executives
    case UserType.Chair:
    case UserType.DeputyChair:
    case UserType.ViceChair:
    case UserType.SID:
    case UserType.NED:
    case UserType.AssociateNED:
    case UserType.OtherNonExecutive:
      return TargetGroupOptions.NonExecutives
    case UserType.ShareholderRepresentative:
      return TargetGroupOptions.ShareholderRepresentative
    case UserType.LabourRepresentative:
      return TargetGroupOptions.LabourRepresentative
    case UserType.InternalInvestor:
    case UserType.InternalUnion:
    case UserType.InternalStakeholder:
      return TargetGroupOptions.InternalStakeholders
    case UserType.ExternalInvestor:
    case UserType.ExternalUnion:
    case UserType.ExternalNGO:
    case UserType.ExternalStakeholder:
      return TargetGroupOptions.ExternalStakeholders
    default:
      return undefined
  }
}

export function sortByTenureLength (a, b): number {
  const rolePositionOrder: number = sortByRolePosition(a, b)
  if (rolePositionOrder !== 0) {
    return rolePositionOrder
  }
  if (a.tenureLength === undefined) {
    return 1
  }
  if (b.tenureLength === undefined) {
    return -1
  }
  if (a.tenureLength === b.tenureLength) {
    const aName = a.fullName ?? a.firstName
    const bName = b.fullName ?? b.firstName
    if (!aName) {
      return 1
    }
    if (!bName) {
      return -1
    }
    return aName.localeCompare(bName)
  }
  return b.tenureLength - a.tenureLength
}

export function sortByRolePosition (a, b): number {
  const aPosition: TargetGroupOptions | undefined = getCompanyPosition(a.role)
  const bPosition: TargetGroupOptions | undefined = getCompanyPosition(b.role)
  if (!aPosition) {
    return 1
  }
  if (!bPosition) {
    return -1
  }
  if (aPosition === bPosition) {
    return 0
  }
  const aPositionIndex: number = TargetGroupsOrder.indexOf(aPosition)
  const bPositionIndex: number = TargetGroupsOrder.indexOf(bPosition)
  if (aPositionIndex === bPositionIndex) {
    return 0
  }
  return aPositionIndex - bPositionIndex
}

export function getTenureLengthStatus (role: UserType, tenureLength: number): TenureLengthStatus {
  if (tenureLength === undefined) {
    return TenureLengthStatus.None
  }
  const rolePosition: TargetGroupOptions | undefined = getCompanyPosition(role)
  if (rolePosition !== TargetGroupOptions.NonExecutives) {
    return TenureLengthStatus.None
  }
  if (role === UserType.Chair) {
    // Chair is allowed one more year
    tenureLength -= 1
  }
  if (tenureLength >= 8) {
    return TenureLengthStatus.Danger
  }
  if (tenureLength >= 6) {
    return TenureLengthStatus.Warning
  }
  return TenureLengthStatus.Okay
}

export function getJobTitle (individual: ResultsIndividual): string | undefined {
  if (individual.jobTitle) {
    return individual.jobTitle
  }
  return $t(`shared.individual.roles.${individual.role as string}`) as string
}
