const ZendeskRedirectURL: string = 'https://betterboards.zendesk.com/access/jwt'

export default async function handleZendeskAuthRedirect (jwt: string): Promise<void> {
  const form = document.createElement('form')
  form.setAttribute('hidden', 'true')
  form.setAttribute('action', ZendeskRedirectURL)
  form.setAttribute('method', 'POST')
  form.setAttribute('target', '_blank')

  const jwtField = document.createElement('input')
  jwtField.setAttribute('type', 'text')
  jwtField.setAttribute('name', 'jwt')
  jwtField.setAttribute('value', jwt)
  form.appendChild(jwtField)

  document.body.appendChild(form)
  form.submit()
}
