import { GraphsResultData } from '../../types/ResultSet'
import { $t } from '../../translations/index'

/**
 * Returns a footnote for line graphs based on the self evaluation data being present
 */
export function getLineGraphSelfEvaluationFootnote (
  graphData: GraphsResultData
): string | undefined {
  const allQuestionsAnswered: boolean = graphData.every(
    (d: GraphsResultData): boolean => d.self
  )
  if (allQuestionsAnswered) {
    return undefined
  }

  const notSelfEvaluationQuestion: boolean = graphData.some(
    (d: GraphsResultData): boolean => d.self === undefined
  )
  if (notSelfEvaluationQuestion) {
    return undefined
  }

  let footnote: string | undefined
  const noSelfAnswers = graphData.every(
    (d: GraphsResultData): boolean => d.self === null
  )
  if (noSelfAnswers) {
    footnote = $t('shared.graphs.line.footnote.selfEvaluation', 1) as string
  }

  const someSelfAnswers = graphData.some(
    (d: GraphsResultData): boolean => !!d.self
  )
  if (someSelfAnswers) {
    footnote = $t('shared.graphs.line.footnote.selfEvaluation', 2) as string
  }

  if (!footnote) {
    return undefined
  }

  return footnote
}
