<template>
  <v-row class="pt-8 pb-8">
    <v-col
      v-for="group in question.groups"
      :key="group.id"
      class="d-flex flex-column align-center"
    >
      <h2 v-show="group.name !== 'Top'">
        {{ group.name }}
      </h2>
      <platform-text-area
        v-for="(input, index) in answers[group.id]"
        :key="index"
        v-model="answers[group.id][index]"
        clearable
        auto-grow
        :rows="1"
        :disabled="isPreview"
        :label="(index + 1).toString()"
        :style="{
          minWidth: '200px',
          width: '100%'
        }"
        :class="{
          'px-4': $vuetify.breakpoint.mdAndUp,
          'px-12': $vuetify.breakpoint.xlOnly
        }"
        :rules="form.validation.answer"
        @blur="syncAnswers"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { MultiTextQuestionData, MultiTextQuestionGroup } from '@/types/Question'
import { Validators, MaxFieldLengths } from '@/helpers/validation'
import PlatformTextArea from '@/components/shared/inputs/PlatformTextArea.vue'

export default Vue.extend({
  name: 'SurveyMultiTextQuestion',
  components: {
    PlatformTextArea
  },
  props: {
    question: { type: Object as PropType<MultiTextQuestionData>, required: true },
    value: { type: [String, Object], required: false }, // answers
    isPreview: { type: Boolean, default: false }
  },
  data: () => ({
    answers: {},
    form: {
      validation: {
        answer: [
          Validators.maxLength(MaxFieldLengths.MultiTextQuestionResponse)
        ]
      }
    }
  }),
  mounted () {
    this.initAnswers()
  },
  watch: {
    question: {
      deep: true,
      handler () {
        this.initAnswers()
      }
    }
  },
  methods: {
    initAnswers () {
      console.log('initAnswers', this.value, this.answers)
      if (!this.question?.groups) {
        console.log('no groups')
        return
      }
      this.answers = {}
      this.question.groups.forEach((group) => {
        if (!group.id) {
          console.warn('MultiTextQuestion: question group has no ID, ignoring...', group)
          return
        }
        Vue.set(this.answers, group.id, (new Array(group.fields)).fill(null))
      })
      if (this.value) {
        if (typeof this.value === 'object') {
          Object.keys(this.value).forEach((groupId) => {
            Vue.set(this.answers, groupId, this.value[groupId])
          })
        }
      }
    },
    getSingleGroupName (group: MultiTextQuestionGroup): string {
      if (group.name.charAt(group.name.length - 1) === 's') {
        return group.name.slice(0, group.name.length - 1)
      }
      return group.name
    },
    syncAnswers () {
      console.log('syncing answers', this.answers)
      this.$emit('input', this.answers)
      console.log('synced answers', this.value)
    }
  }
})
</script>
