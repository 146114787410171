import dayjs from 'dayjs'
import { i18n } from '@/plugins/i18n'
import { LongDateTimeDisplayFormat } from '@/components/PlatformDateDisplay.vue'
import API from '@/services/API'
import { createReportDocument } from '@/graphql/mutations'
import { PartialReportDocument, PlatformReportDocument, ReportDocumentInfo } from '@betterboards/shared/types/Report'
import { reportDocumentsByDate } from '@/graphql/queries'
import { ModelSortDirection, ReportDocumentsByDateQuery, ReportTemplate } from '@/API'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { triggerFileDownload } from '@/helpers/file'
import { QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import { NullVariantCodeValue } from '@/helpers/questionnaire'

export enum ReportDocumentType {
  Collective = 'Collective',
  Individual = 'Individual',
  Generic = 'Generic',
}

export function getReportDocumentDownloadTooltip (report: ReportDocumentInfo | undefined): string {
  if (!report) {
    return i18n.t('analysis.generateReport.reportDocuments.notYetGenerated') as string
  }
  if (report.finishedAt) {
    const timeSinceCompletion = report.timeSinceCompletion ?? dayjs(report.finishedAt).fromNow()
    return `${i18n.t('analysis.generateReport.reportDocuments.lastGenerated') as string} ${timeSinceCompletion}.`
  }
  if (report.startedProcessingAt) {
    const displayStartedProcessingAt = report.displayStartedProcessingAt ?? dayjs(report.startedProcessingAt).format(LongDateTimeDisplayFormat)
    return `${i18n.t('analysis.generateReport.reportDocuments.startedProcessingAt') as string} ${displayStartedProcessingAt}.`
  }
  if (report.queuedAt) {
    const displayQueuedAt = report.displayQueuedAt ?? dayjs(report.queuedAt).format(LongDateTimeDisplayFormat)
    return `${i18n.t('analysis.generateReport.reportDocuments.queuedAt') as string} ${displayQueuedAt}.`
  }
  return i18n.t('analysis.generateReport.reportDocuments.notYetGenerated') as string
}

export function getLatestReportDocument (reportDocuments: PlatformReportDocument[]): PlatformReportDocument | undefined {
  return reportDocuments.reduce<PlatformReportDocument>(
    (latestReportDocument: PlatformReportDocument, reportDocument: PlatformReportDocument) => {
      if (!latestReportDocument?.createdAt || reportDocument.createdAt > latestReportDocument.createdAt) {
        // This item is newer than the current latest, return it
        return reportDocument
      }
      return latestReportDocument
    },
    reportDocuments[0]
  )
}

/**
 * Returns the latest ReportDocument matching the provided variant
 */
export function getVariantLatestReportDocument (
  reportDocuments: PlatformReportDocument[],
  targetVariantCode: QuestionnaireVariantCode | typeof NullVariantCodeValue | undefined
): PlatformReportDocument | undefined {
  return getLatestReportDocument(
    reportDocuments.filter(
      (r: PlatformReportDocument) => {
        if (!targetVariantCode || targetVariantCode === NullVariantCodeValue) {
          return !r.variantCode
        }
        return r.variantCode === targetVariantCode
      }
    )
  )
}

export async function queueReportDocument (payload: PartialReportDocument): Promise<PlatformReportDocument> {
  let response
  try {
    response = await API.graphql({
      query: createReportDocument,
      variables: {
        input: {
          requesterId: payload.requesterId,
          reportId: payload.reportId,
          companyId: payload.companyId,
          surveyGroupId: payload.surveyGroupId,
          comparisonSurveyGroupId: payload.comparisonSurveyGroupId,
          targetIndividualId: payload.targetIndividualId,
          variantCode: payload.variantCode,
          template: payload.template
        }
      }
    })
  } catch (err) {
    console.error('Failed to queue ReportDocument:', payload, err)
    throw err
  }
  console.debug('Queued ReportDocument, response:', response)
  return response?.data?.createReportDocument
}

const DefaultReportTemplateFilter = {
  or: [
    { template: { eq: ReportTemplate.Default } },
    { template: { ne: ReportTemplate.ResponseStatus } } // @TODO: Improve/fix this filter when we have any other kind of ReportTemplate
  ]
}

export async function listReportDocuments (surveyGroupId: string, template: ReportTemplate = ReportTemplate.Default): Promise<PlatformReportDocument[] | undefined> {
  let response: GraphQLResult<ReportDocumentsByDateQuery>
  const filter = template === ReportTemplate.Default
    ? DefaultReportTemplateFilter
    : { template: { eq: template } }
  try {
    response = await API.graphql({
      query: reportDocumentsByDate,
      variables: {
        surveyGroupId,
        sortDirection: ModelSortDirection.DESC,
        filter
      }
    })
  } catch (err) {
    console.error(
      'Failed to list ReportDocuments:',
      { surveyGroupId, template, err }
    )
    throw err
  }
  const reportDocuments = response?.data?.reportDocumentsByDate?.items as PlatformReportDocument[] | undefined
  if (!reportDocuments || response.errors) {
    console.error(
      'Failed to fetch list of ReportDocuments:',
      { surveyGroupId, template, response }
    )
    throw new Error('Failed to list ReportDocuments.')
  }
  // console.debug('Fetched ReportDocuments list:', reportDocuments)
  return reportDocuments
}

export async function downloadReportDocument (
  reportDocument: PlatformReportDocument,
  fileName?: string
): Promise<void> {
  const surveyGroupId: string = reportDocument.surveyGroupId
  const reportDocumentId: string = reportDocument.id
  const targetIndividualId: string | null | undefined = reportDocument.targetIndividualId

  if (!surveyGroupId || !reportDocumentId) {
    console.error('Failed to download ReportDocument, missing SurveyGroup ID or ReportDocument ID:', reportDocument)
    throw new Error('Failed to download ReportDocument as provided object is missing fields.')
  }

  const reportObjectId = targetIndividualId
    ? `${surveyGroupId}/${targetIndividualId}/${reportDocumentId}`
    : `${surveyGroupId}/${reportDocumentId}`
  const companyId: string = reportDocument.companyId
  let path = `/${companyId}/files?objectType=reports&objectId=${reportObjectId}.pdf&download=true`
  if (fileName) {
    path += `&fileName=${encodeURIComponent(fileName)}`
  }
  const response = await API.get('backendfunctions', path)
  triggerFileDownload(response.link)
}
