<template>
  <v-container class="grow">
    <v-row no-gutters align="center" justify="center" class="fill-height flex-column">
      <v-row class="shrink">
        <v-col
          v-for="link in links"
          :key="link.label"
          :cols="12"
          :md="6"
        >
          <v-card
            hover
            class="cursor-pointer d-flex"
            :style="{ minHeight: '120px' }"
            :to="{
              name: link.route,
              params: {
                surveyId: $route.params.surveyId
              }
            }"
          >
            <v-row no-gutters>
              <v-col class="shrink d-flex align-center pl-4 pr-2">
                <platform-icon
                  color="primary"
                  :name="link.icon"
                  :size="56"
                />
              </v-col>
              <v-col class="grow text-left d-flex align-center">
                <span class="highlight--text font-weight-bold text-body-1 text-md-body-2 text-lg-body-1 d-block">
                  {{ link.label }}
                </span>
              </v-col>
              <v-col class="shrink card-action d-flex align-center px-1">
                <platform-icon
                  name="caret-right"
                  color="secondary"
                  :size="40"
                />
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { QuestionnaireVariantCode } from '@/types/Platform'
import { PlatformReport } from '@/types/Report'
import { SystemPage, SystemPageConfig, SystemPages } from '@/store/Analysis'
import { ResultSet } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'AnalysisDashboard',
  props: {
    results: { type: Object as PropType<ResultSet>, required: true },
    report: { type: Object as PropType<PlatformReport>, required: true },
    selectedVariant: { type: String as PropType<QuestionnaireVariantCode | undefined>, required: false }
  },
  data: () => ({
    SystemPage
  }),
  computed: {
    links (): Array<SystemPageConfig & { label: string }> {
      return Object.values(SystemPages)
        .filter((page) => page.slug !== SystemPage.Dashboard)
        .map((page) => ({
          ...page,
          label: this.$t(`nav.analysis.${page.slug}`) as string
        }))
    }
  }
})
</script>

<style lang="scss" scoped>
.card-action {
  border-left: 1px solid var(--v-grey-lighten1);
  border-radius: 0 !important;
}
</style>
