<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fill-rule="evenodd">
      <g stroke="currentColor">
        <g>
          <g>
            <path stroke-linecap="square" d="M5 35L35 35M5 35L5 5" transform="translate(-364 -397) translate(348 382) translate(16 15)"/>
            <path stroke-linecap="round" d="M2 7L5 4 8 7" transform="translate(-364 -397) translate(348 382) translate(16 15)"/>
            <path stroke-linecap="round" d="M31.5 36.5L34.5 33.5 37.5 36.5" transform="translate(-364 -397) translate(348 382) translate(16 15) rotate(90 34.5 35)"/>
            <path stroke-linecap="round" d="M8 27L10.225 29 15.473 22.549 19 25.774 26 14.36 29 16.073 32.422 8.706" transform="translate(-364 -397) translate(348 382) translate(16 15)"/>
            <path stroke-linecap="round" d="M8 18.2L9.776 19 16 15 21 20M23.22 22L25.008 24 28 20.515 33 26" transform="translate(-364 -397) translate(348 382) translate(16 15)"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'LineGraphIcon'
})
</script>
