import API from '@/services/API'

export default async function fetchUniverseData (queries?: Array<{ collection: string, category: string }> | { companyId: string }): Promise<any> {
  return await API.post(
    'backendfunctions',
    '/stats/universe',
    {
      body: queries ?? []
    }
  )
}
