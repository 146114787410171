<template>
  <v-container class="fill-height">
    <v-row class="fill-height">
      <v-row class="flex-column">
        <v-col class="shrink">
          <v-row class="pr-2">
            <v-col class="grow">
              <h1 class="highlight--text">{{ $t('page.dashboard.boardMember.questionnaires') }}</h1>
            </v-col>
            <v-col class="shrink">
              <platform-spinner v-if="fetching" :size="24" color="grey darken-1" />
              <platform-icon
                v-else
                name="refresh"
                color="grey darken-1"
                v-tooltip:left="$t('page.dashboard.boardMember.refreshAction')"
                :size="32"
                @click="fetchSurveys"
              />
            </v-col>
          </v-row>
          <div v-if="!incompleteSurveys.length && !unstartedSurveys.length && !completeSurveys.length" class="pt-12">
            <template v-if="fetching">
              <platform-spinner class="mx-auto" />
            </template>
            <template v-else>
              You have not been invited to any released Questionnaires.
            </template>
          </div>
        </v-col>
        <v-col v-if="incompleteSurveys.length">
          <h3 class="highlight--text sans-serif font-weight-bold mb-2">{{ $t('page.dashboard.boardMember.inProgressQuestionnaires') }}</h3>

          <v-card v-for="survey in incompleteSurveys" :key="survey.id" :to="{ name: 'ViewSurvey', params: { surveyId: survey.id }}" class="mb-5">
            <v-card-subtitle class="pb-1">
              <platform-date-display :date-time="survey.createdAt" concise />
            </v-card-subtitle>
            <v-card-title class="py-0">{{ survey.name }}</v-card-title>
            <v-card-actions>
              <v-spacer />
              <platform-button primary default-case>
                <template v-if="survey.totals && survey.totals.questionsCompleted">
                  Resume
                </template>
                <template v-else>
                  Start
                </template>
                Questionnaire
              </platform-button>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="unstartedSurveys.length">
          <h3 class="highlight--text sans-serif font-weight-bold mb-2">{{ $t('page.dashboard.boardMember.unansweredQuestionnaires') }}</h3>

          <v-card v-for="survey in unstartedSurveys" :key="survey.id" :to="{ name: 'ViewSurvey', params: { surveyId: survey.id }}" class="mb-5">
            <v-card-subtitle class="pb-1">
              <platform-date-display :date-time="survey.createdAt" concise />
            </v-card-subtitle>
            <v-card-title class="py-0">{{ survey.name }}</v-card-title>
            <v-card-actions>
              <v-spacer />
              <platform-button primary default-case>
                <template v-if="survey.totals && survey.totals.questionsCompleted">
                  {{ $t('page.dashboard.boardMember.resumeQuestionnaire') }}
                </template>
                <template v-else>
                  {{ $t('page.dashboard.boardMember.startQuestionnaire') }}
                </template>
              </platform-button>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col v-if="completeSurveys.length">
          <h3 class="highlight--text sans-serif font-weight-bold mb-2">{{ $t('page.dashboard.boardMember.answeredQuestionnaires') }}</h3>

          <v-card v-for="survey in completeSurveys" :key="survey.id" class="mb-5">
            <v-card-subtitle class="pb-1">
              <platform-date-display :date-time="survey.createdAt" concise />
            </v-card-subtitle>
            <v-card-title class="py-0">{{ survey.name }}</v-card-title>
            <v-card-actions>
              <v-spacer />
              <platform-icon name="check-outline" :size="40" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import API from '@/services/API'
import { PlatformQuestionnaire } from '@betterboards/shared/types/Platform'
import { PlatformSurvey } from '@/types/Platform'
import { getFullSurveyByOwner } from '@/graphql/custom/getFullSurveyByOwner'
import { getQuestionnaireVariantName } from '@betterboards/shared/helpers/entities/Variants/getVariantName'
import PlatformDateDisplay from '@/components/PlatformDateDisplay.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

interface DashboardSurvey extends Omit<PlatformSurvey, 'questionnaire'> {
  name: string

  questionnaire: PlatformQuestionnaire
}

export default Vue.extend({
  name: 'BoardMemberDashboard',
  components: {
    PlatformSpinner,
    PlatformDateDisplay
  },
  data () {
    return {
      surveys: {
        items: [] as PlatformSurvey[],
        nextToken: null as string | null
      },
      initialized: false,
      fetching: false
    }
  },
  mounted () {
    this.init()
  },
  watch: {
    entity (val) {
      if (val.username) {
        this.init()
      }
    }
  },
  computed: {
    ...mapState('User', ['entity']),
    surveysList (): DashboardSurvey[] {
      return this.surveys.items.reduce((surveys: DashboardSurvey[], survey: PlatformSurvey, index: number) => {
        try {
          const surveyName = getQuestionnaireVariantName(survey.questionnaire, survey.variantCode) as string
          if (surveyName) {
            surveys.push({
              ...survey,
              name: surveyName
            })
          }
        } catch (err) {
          console.error('Failed to get name for Survey.', { survey, index })
        }
        return surveys
      }, [])
    },
    completeSurveys (): DashboardSurvey[] {
      return this.surveysList.filter((item) => item.questionnaire && !!item.finishedAt)
    },
    incompleteSurveys (): DashboardSurvey[] {
      return this.surveysList.filter((item) => item.questionnaire && !item.finishedAt && item.totals?.questionsCompleted)
    },
    unstartedSurveys (): DashboardSurvey[] {
      return this.surveysList.filter((item) => item.questionnaire && !item.finishedAt && !item.totals?.questionsCompleted)
    }
  },
  methods: {
    ...mapMutations('Navigation', ['setBoardMemberLinks']),
    ...mapActions('Company', ['pickLowestAccessCompany']),
    async init (): Promise<void> {
      if (this.initialized || !this.entity.username) {
        return
      }

      this.initialized = true
      this.setBoardMemberLinks()

      await this.fetchSurveys()
    },
    async fetchSurveys (): Promise<void> {
      this.fetching = true
      try {
        const results = await API.graphql({
          query: getFullSurveyByOwner,
          variables: {
            // nextToken: this.surveys?.nextToken,
            owner: this.entity.username
          }
        })
        console.log('loaded results', results)
        const surveysResult = results.data?.surveysByOwner
        const surveys: PlatformSurvey[] = surveysResult?.items?.filter(
          (survey: any): survey is PlatformSurvey => !!survey.questionnaire?.name
        ) as unknown[] as PlatformSurvey[]
        if (surveys) {
          if (this.surveys.nextToken) {
            this.surveys.items.push(...surveys)
          } else {
            this.surveys.items = surveys
          }
          this.surveys.nextToken = surveysResult.nextToken ?? null
        }
      } catch (err) {
        if (!err.errors?.length || err.errors.some((e) => e.errorType !== 'Unauthorized' || !(e.path?.[3] === 'questionnaire' || e.path?.[3] === 'individual'))) {
          console.error('Caught an error while trying to fetch data:', err)
          throw err
        }
        console.warn('Ignoring expected unauthorized error:', err)
        this.surveys = { ...err.data.surveysByOwner }
      } finally {
        this.fetching = false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.surveys-group {
  min-height: 8em;
}
</style>
