<template>
  <platform-modal
    v-if="companyUser && companyUser.companyId"
    :value="true"
    icon="company"
    :width="650"
    :title="companyUserModalTitle"
    @save="submit"
    @close="$emit('close')"
  >
    <template v-if="companyUser" slot="header-append">
      <div class="grow flex-basis-auto">
        <v-row no-gutters class="flex-nowrap text-body-2 grey--text text--darken-2" align="center" justify="center">
          <span class="text-no-wrap">{{ companyUser.company.name }}</span>
          <platform-avatar
            entity-type="company"
            class="ml-2"
            placeholder-size="xs"
            :entity="{ id: companyUser.companyId, ...companyUser.company }"
            :size="36"
            :pad-bottom="false"
          />
        </v-row>
      </div>
    </template>
    <company-user-form
      v-model="companyUser"
      :user="user"
      :editing="editing"
      @addPackage="addCompanyUserPackage"
      @removePackage="removeCompanyUserPackage"
    />
  </platform-modal>
</template>

<script lang="ts">
import { createCompanyUserPackage, deleteCompanyUserPackage } from '@/helpers/packages'
import Vue, { PropType } from 'vue'
import { PlatformCompanyUser, PlatformUser } from '@/types/Platform'
import PlatformModal from '@/components/PlatformModal.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import CompanyUserForm from '@/components/user/company-user/CompanyUserForm.vue'

export default Vue.extend({
  name: 'CompanyUserModal',
  components: {
    PlatformModal,
    CompanyUserForm,
    PlatformAvatar
  },
  props: {
    value: { type: Object as PropType<PlatformCompanyUser>, required: true },
    user: { type: Object as PropType<PlatformUser>, required: true },
    editing: { type: Boolean, required: false }
  },
  data: () => ({
    packagesToAdd: <string[]>[],
    packagesToDelete: <string[]>[],
    pendingFetchCompanyPackages: false,
    pendingFetchCompanyUserPackages: false
  }),
  computed: {
    companyUser: {
      set (val: PlatformCompanyUser) {
        this.$emit('input', val)
      },
      get (): PlatformCompanyUser {
        return this.value
      }
    },
    companyUserModalTitle (): string {
      const companyName = this.companyUser?.company?.name
      if (this.editing) {
        const userDisplayName = this.user.displayName
        return this.$t('modal.admin.companyUser.editTitle', [userDisplayName]) as string
      }
      return this.$t('modal.admin.addToCompany.title', [companyName]) as string
    }
  },
  methods: {
    async submit (): Promise<void> {
      const companyId = this.companyUser.companyId
      if (!this.user.companies?.items || !companyId) {
        throw new Error('Missing companyId or user missing companies')
      }

      const promises = [
        ...this.packagesToAdd.map(async (packageId) => {
          return await createCompanyUserPackage(companyId, this.user.id, packageId)
        }),
        ...this.packagesToDelete.map(async (packageId) => {
          return await deleteCompanyUserPackage(companyId, this.user.id, packageId)
        })
      ]
      await Promise.allSettled(promises)

      this.$emit('update', companyId)
      this.$emit('close')
    },
    addCompanyUserPackage (packageId: string): void {
      const deleteItemIndex = this.packagesToDelete.indexOf(packageId)
      if (deleteItemIndex !== -1) {
        // CompanyUser already has this Package but it was marked for deletion in this form, so just undo this.
        this.packagesToDelete.splice(deleteItemIndex, 1)
        return
      }
      this.packagesToAdd.push(packageId)
    },
    removeCompanyUserPackage (packageId: string): void {
      const addItemIndex = this.packagesToAdd.indexOf(packageId)
      if (addItemIndex !== -1) {
        // CompanyUserPackage was only marked for creation in this form, just undo this.
        this.packagesToAdd.splice(addItemIndex, 1)
        return
      }
      this.packagesToDelete.push(packageId)
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.company-user-packages--list {
  min-height: 65px;
}
</style>
