<template>
  <report-preview-page :title="pageTitle">
    <display-markdown
      v-if="focusAreasContent"
      class="pb-1"
      :value="focusAreasContent"
    />
    <display-markdown
      v-if="previewContent"
      :value="previewContent"
    />
  </report-preview-page>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import ReportPreviewPage from '@/components/analysis/report-previews/partials/Page.vue'
import DisplayMarkdown from '@/components/DisplayMarkdown.vue'
import { PlatformReport } from '@betterboards/shared/types/Report'

export default Vue.extend({
  name: 'FocusAreasReportPreview',
  components: {
    DisplayMarkdown,
    ReportPreviewPage
  },
  props: {
    report: { type: Object as PropType<PlatformReport | undefined>, required: false },
    previewContent: { type: String as PropType<string | undefined>, required: false }
  },
  computed: {
    pageTitle (): string {
      return this.$t('report.page.focusAreas') as string
    },
    focusAreasContent (): string | undefined {
      if (!this.report?.focusAreas) {
        if (this.previewContent) {
          return undefined
        }
        // Report has no focus areas, display example content
        return this.$t('analysis.reportContent.recommendationsModal.exampleContent.focusAreasContent') as string
      }
      return this.report.focusAreas
    }
  }
})
</script>
