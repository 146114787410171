<template>
  <div>
    <template v-if="showCommitteeSummary">
      <v-row no-gutters class="pt-5">
        <key-finding-answer
          :fields="0"
          :title="committeeSummaryTitle"
          :result="result"
          :report-result="committeeSummaryResult"
          :section="section"
          @update="updateReportSectionValue"
        />
      </v-row>
      <div v-if="!resultReviewItem || !resultReviewItem.requiresReview" class="text-right pb-6">
        <sign-off-button
          :value="resultReviewItem && !!resultReviewItem.reviewedAt"
          @input="toggleReportResultReviewed"
        />
      </div>
    </template>
    <template v-if="result.graphType">
      <display-graph
        :graph="result"
        :config="config"
        :comparison-result="comparisonResult"
        :padded="padded"
      />
    </template>
    <template v-else>
      <template v-if="allResponses">
        <v-row class="py-4">
          <template v-if="result.committee">
            <v-col
              :cols="12"
              :md="showKeyFindings ? 6 : 12"
              :lg="showKeyFindings ? 8 : 12"
            >
              <v-row>
                <v-col :cols="12" :lg="showKeyFindings ? 12 : 4" :class="{ 'text-lg-right': !showKeyFindings }">
                  <v-row class="flex-column">
                    <v-col class="table-header highlight--text">
                      Question
                    </v-col>
                    <v-col class="pl-6">
                      {{ result.data.questionText }}
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="hasCommitteeSelfEvalResponses"
                  :cols="12"
                  :lg="4"
                >
                  <v-row class="flex-column">
                    <v-col class="table-header highlight--text" :class="{ 'text-lg-center': !showKeyFindings }">
                      {{ $t('shared.graphs.variance.committeeSelfEvaluationHeader') }}
                    </v-col>
                    <v-col>
                      <display-responses
                        show-empty
                        filter="selfEvaluation"
                        :result="result"
                        :config="config"
                        :report-section="reportSection"
                        @update="updateReportSectionValue"
                      />
                    </v-col>
                    <v-col v-if="displayComparisonResponses">
                      <display-responses
                        filter="selfEvaluation"
                        :result="comparisonResult"
                        :config="config"
                        :report-section="reportSection"
                        @update="updateReportSectionValue"
                      >
                        <template
                          #header
                          v-if="identifyComparisonResponses"
                        >
                          <h4 class="primary--text sans-serif font-weight-bold">
                            {{ $t('analysis.responses.comparisonsTitle') }}
                          </h4>
                        </template>
                      </display-responses>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  :cols="12"
                  :lg="showKeyFindings ? 12 : (hasCommitteeSelfEvalResponses ? 4 : 8)"
                >
                  <v-row class="flex-column">
                    <v-col class="table-header highlight--text" :class="{ 'text-lg-center': !showKeyFindings }">
                      <template v-if="hasCommitteeSelfEvalResponses">
                        {{ $t('shared.graphs.variance.committeeExternalEvaluationHeader') }}
                      </template>
                      <template v-else>
                        Responses
                      </template>
                    </v-col>
                    <v-col>
                      <display-responses
                        show-empty
                        filter="nonSelfEvaluation"
                        :result="result"
                        :config="config"
                        :report-section="reportSection"
                        @update="updateReportSectionValue"
                      />
                    </v-col>
                    <v-col v-if="displayComparisonResponses">
                      <display-responses
                        filter="nonSelfEvaluation"
                        :result="comparisonResult"
                        :config="config"
                        :report-section="reportSection"
                        @update="updateReportSectionValue"
                      >
                        <template
                          #header
                          v-if="identifyComparisonResponses"
                        >
                          <h4 class="primary--text sans-serif font-weight-bold">
                            {{ $t('analysis.responses.comparisonsTitle') }}
                          </h4>
                        </template>
                      </display-responses>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              v-if="showKeyFindings"
              :cols="12"
              :md="6"
              :lg="4"
            >
              <key-finding-answer
                :result="result"
                :report-result="reportResult"
                :section="section"
                @update="updateReportSectionValue"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col :cols="12" :lg="showKeyFindings ? 6 : undefined" class="pr-6">
              <display-responses
                show-empty
                :result="result"
                :config="config"
                :report-section="reportSection"
                @update="updateReportSectionValue"
              >
                <template #header>
                  <h3 class="sans-serif font-weight-bold primary--text">{{ $t('analysis.responses.title') }}</h3>
                </template>
              </display-responses>
              <display-responses
                v-if="displayComparisonResponses"
                class="mt-6"
                :result="comparisonResult"
                :config="config"
                :report-section="reportSection"
                @update="updateReportSectionValue"
              >
                <template
                  #header
                  v-if="identifyComparisonResponses"
                >
                  <h3 class="sans-serif font-weight-bold primary--text">
                    {{ $t('analysis.responses.comparisonsTitle') }}
                  </h3>
                </template>
              </display-responses>
            </v-col>
            <v-col
              v-if="showKeyFindings"
              :cols="12"
              :lg="6"
            >
              <key-finding-answer
                :result="result"
                :report-result="reportResult"
                :section="section"
                @update="updateReportSectionValue"
              />
            </v-col>
          </template>
        </v-row>
      </template>
    </template>
    <div v-if="resultReviewItem && resultReviewItem.requiresReview" class="text-right pb-5">
      <sign-off-button
        :value="resultReviewItem && !!resultReviewItem.reviewedAt"
        @input="toggleReportResultReviewed"
      />
    </div>
  </div>
</template>

<script lang="ts">
import SignOffButton from '@/components/analysis/SignOffButton.vue'
import {
  CommitteeSummaryField,
  PlatformReportGroup,
  PlatformReportSection,
  ReportResult,
  ReportSectionResult
} from '@/types/Report'
import Vue, { PropType } from 'vue'
import { GraphTypeIcons } from '@betterboards/graphs/index'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import { ReportField } from '@betterboards/shared/helpers/entities/Report'
import { RepeatForFriendlyNames, RepeatForOptions } from '@/helpers/questionnaire/questions'
import KeyFindingAnswer from '@/components/analysis/partials/KeyFindingAnswer.vue'
import DisplayGraph from '@/components/graphs/DisplayGraph.vue'
import DisplayResponses from '@/components/analysis/results/DisplayResponses.vue'
import { PlatformSection } from '@betterboards/shared/types/Platform'
import {
  GraphResult,
  ProgressSection,
  ResponsesResult,
  ResponsesResultResponse,
  ResultSet,
  ResultType,
  SectionReviewItem,
  SectionReviewItemGroup
} from '@betterboards/shared/types/ResultSet'
import { MultiTextGroup } from '@betterboards/shared/helpers/entities/Question/index'
import { isTargetMultiTextResult } from '@betterboards/shared/helpers/entities/Result/isMultiTextResult'
import shouldRenderMultiTextResultResponses from '@betterboards/shared/helpers/entities/Result/shouldRenderMultiTextResultResponses'
import { findComparisonResultSection } from '@betterboards/shared/helpers/entities/Result/findComparisonResultSection'
import { findComparisonResult } from '@betterboards/shared/helpers/entities/Result/findComparisonResult'

export default Vue.extend({
  name: 'DisplayResult',
  props: {
    result: {
      type: Object as PropType<GraphResult | ResponsesResult>
    },
    section: {
      type: Object as PropType<PlatformSection>,
      required: true
    },
    config: {
      type: Object as PropType<any>,
      required: false
    },
    reportSection: {
      type: Object as PropType<PlatformReportSection>
    },
    padded: {
      type: Boolean,
      default: true
    }
  },
  components: {
    SignOffButton,
    KeyFindingAnswer,
    DisplayGraph,
    DisplayResponses
  },
  data: () => ({
    GraphTypes,
    GraphTypeIcons,
    RepeatForFriendlyNames,
    RepeatForOptions
  }),
  computed: {
    resultSetSections (): ProgressSection[] {
      return this.$store.getters['Report/resultSetSections']
    },
    resultSetSection (): ProgressSection | undefined {
      if (!this.reportSection?.sectionId) {
        return undefined
      }
      return this.resultSetSections?.find((s) => s.id === this.reportSection.sectionId)
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    },
    comparisonResult (): ResultType | undefined {
      if (!this.reportSection?.sectionId) {
        return
      }
      const section = findComparisonResultSection(this.comparisonResultSet?.sections, this.resultSetSection)
      return findComparisonResult(section, this.result)
    },
    allResponses (): ResponsesResultResponse[] {
      const result = this.result
      if (result.type !== 'responses' || !result?.data) {
        return []
      }
      return result.data.responses
    },
    responseOverridesMap (): { [blockId: string]: any } {
      return this.allResponses.reduce((overrides, response) => {
        overrides[response.blockId as string] = this.reportSection.results.find((o: ReportSectionResult) => {
          if (o.questionId) {
            return o.questionId === response.blockId
          }
          if (!o.blockId) {
            return false
          }
          return o.blockId === response.blockId
        })
        return overrides
      }, {})
    },
    nonSelfEvaluationResponses (): ResponsesResultResponse[] {
      return this.allResponses
        .filter((r) => !r.selfEvaluation)
    },
    selfEvaluationResponses (): ResponsesResultResponse[] {
      return this.allResponses
        .filter((r) => r.selfEvaluation)
    },
    hasSelfEvaluationResponses (): boolean {
      return this.selfEvaluationResponses.length > 0
    },
    isStrengthsAndSituations (): boolean {
      const result = this.result
      if (result.type !== 'responses' || !result.data.groups?.length) {
        return false
      }
      return isTargetMultiTextResult(result, [MultiTextGroup.Strengths, MultiTextGroup.Situations])
    },
    isMoreLessMultiText (): boolean {
      const result = this.result
      if (result.type !== 'responses' || !result.data.groups?.length) {
        return false
      }
      return isTargetMultiTextResult(result, [MultiTextGroup.More, MultiTextGroup.Less])
    },
    isCommitteeResult (): boolean {
      return !!this.result.committee
    },
    /**
     * Is a RepeatFor Committee Result, with the Committee ID in result.criteriaId
     */
    isTargetedCommitteeResult (): boolean {
      return this.isCommitteeResult && !!this.result.criteriaId
    },
    /**
     * Is a Generic Committee Result (Structure of the Committees)
     */
    isGenericCommitteeResult (): boolean {
      return this.isCommitteeResult && !this.result.criteriaId
    },
    /**
     * Is a Committee Result with responses for "On the Committee" and "Not on the Committee"?
     */
    hasCommitteeSelfEvalResponses (): boolean {
      return this.result?.type === 'responses' && this.isTargetedCommitteeResult
    },
    showKeyFindings (): boolean {
      if (this.isCommitteeResult) {
        return this.result?.type === 'responses' && this.isGenericCommitteeResult
      }
      return !shouldRenderMultiTextResultResponses(this.result)
    },
    showCommitteeSummary (): boolean {
      return this.isGenericCommitteeResult && this.result?.type === 'responses'
    },
    resultReviewItem (): SectionReviewItem | undefined {
      if (!this.resultSetSection) {
        return undefined
      }
      let reviewItem: SectionReviewItem | undefined
      if (this.result.data.questionId) {
        const resultGroup = this.resultSetSection.reviewItems?.find((g: SectionReviewItemGroup) => {
          return g.items.some((i) => i.link.resultId === this.result.data.questionId)
        })
        reviewItem = resultGroup?.items.find((i) => {
          if (i.link.resultId !== this.result.data.questionId) {
            return false
          }
          return !this.result.criteriaId || i.link.criteriaId === this.result.criteriaId
        })
      }
      return reviewItem
    },
    reportResult (): ReportResult {
      const questionId = this.result.data.questionId
      const questionText = this.result.data.questionText
      const masterSectionId = this.resultSetSection?.masterSectionId
      const masterBlockId = this.result.data.masterBlockId
      const criteriaId = this.result.criteriaId
      const groupId = this.result?.groupId

      const index = this.reportSection?.results.findIndex((item: any): boolean => {
        if (questionId) {
          if (!item.questionId || item.questionId !== questionId) {
            return false
          }
        }
        if (criteriaId) {
          if (!item.criteriaId || item.criteriaId !== criteriaId) {
            return false
          }
        }
        return true
      })

      if (this.reportSection && index !== -1) {
        if (!this.reportSection.results[index].masterBlockId) {
          return {
            ...this.reportSection.results[index],
            masterSectionId,
            masterBlockId
          }
        }
        return this.reportSection.results[index]
      }

      return {
        questionId,
        questionText,
        masterSectionId,
        masterBlockId,
        criteriaId,
        groupId
      }
    },
    committeeSummaryTitle (): string {
      return this.$t('shared.graphs.committees.summaryTitle') as string
    },
    committeeSummaryResult (): CommitteeSummaryField {
      const committeeSummaryResult: CommitteeSummaryField | undefined = this.reportSection?.results.find<CommitteeSummaryField>(
        (r: ReportSectionResult | undefined): r is CommitteeSummaryField => r?.field === ReportField.CommitteeSummary
      )
      if (committeeSummaryResult) {
        return committeeSummaryResult
      }
      return {
        field: ReportField.CommitteeSummary,
        summary: '',
        values: []
      }
    },
    selectedReportGroupId (): string | null {
      return this.$store.state.Report.selectedReportGroupId
    },
    selectedReportGroup (): PlatformReportGroup | null {
      return this.$store.state.Report.reportGroups.find(
        (reportGroup) => reportGroup.id === this.selectedReportGroupId
      ) ?? null
    },
    displayComparisonResponses (): boolean {
      return !this.selectedReportGroup?.configuration?.hideComparisonResponses
    },
    identifyComparisonResponses (): boolean {
      return !this.selectedReportGroup?.configuration?.combineComparisonResponses
    }
  },
  methods: {
    /**
     * Update one of the objects in the `ReportSection.results` array - either a Key Findings object attached to a question
     *  by questionId/criteriaId, or a response override, keyed by blockId/sectionId.
     */
    updateReportSectionValue (newResponse: ReportSectionResult): void {
      const reportSectionResults = [...this.reportSection?.results]
      if (!reportSectionResults) {
        return
      }
      const index = reportSectionResults.findIndex((r: ReportSectionResult) => {
        if (r.questionId && newResponse.questionId) {
          if (r.questionId !== newResponse.questionId) {
            return false
          }
          const newResponseCriteriaId = newResponse.criteriaId
          if (newResponseCriteriaId) {
            return r.criteriaId && r.criteriaId === newResponseCriteriaId
          }
          return true
        }
        if (newResponse.field || r.field) {
          return r.field === newResponse.field
        }
        return r.blockId && r.blockId === newResponse.blockId
      })
      if (index === -1) {
        reportSectionResults.push(newResponse)
      } else {
        reportSectionResults.splice(index, 1, newResponse)
      }
      const payload = {
        ...this.reportSection,
        results: reportSectionResults
      }
      this.$emit('updateReportSection', payload)
    },
    toggleReportResultReviewed (): void {
      const reportResult = this.reportResult
      const resultReviewItem = this.resultReviewItem
      this.updateReportSectionValue({
        ...reportResult,
        reviewedAt: resultReviewItem?.reviewedAt ? null : (new Date()).toISOString()
      })
    }
  }
})
</script>
