import { SectionAveragesData } from '../../types/ResultSet'

export function normalizeFloat (val: number | string | undefined): string | undefined {
  if (!val) {
    return val as undefined
  }
  if (typeof val === 'string') {
    val = Number.parseFloat(val)
  }
  return val.toFixed(2)
}

export function normalizeAverageObject (averages: SectionAveragesData<any>): SectionAveragesData<string> {
  if (averages.average) {
    const normalizedVal = normalizeFloat(averages.average)
    if (normalizedVal) {
      averages.average = normalizedVal
    }
  }
  if (averages.systemAverage) {
    const normalizedVal = normalizeFloat(averages.systemAverage)
    if (normalizedVal) {
      averages.systemAverage = normalizedVal
    }
  }
  return averages
}
