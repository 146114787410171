<template>
  <v-row no-gutters>
    <v-col class="d-flex flex-column">
      <h3 class="login-header pt-4 pb-8">{{ $t('form.auth.login.title') }}</h3>
      <span
        class="red--text"
        v-if="errorMessage"
      >
        {{ errorMessage }}
      </span>
      <v-form ref="form" v-model="formValid" @submit.prevent="handleSubmit">
        <v-form-base
          class="pt-6"
          :model="formData"
          :schema="formSchema"
        />
        <platform-button
          input-type="submit"
          class="d-none"
        />
      </v-form>

      <span class="login-links">
        {{ $t('form.auth.login.forgetPassword') }}
        <a
          @click="$emit('resetPassword')"
        >
          {{ $t('form.auth.login.resetPassword') }}
        </a>
      </span>

      <v-row no-gutters class="justify-end">
        <platform-button
          class="px-12 py-4 mt-8"
          primary
          :loading="pendingSignIn"
          @click="signIn"
        >
          {{ $t('form.auth.login.signIn') }}
        </platform-button>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'
import VFormBase from 'vuetify-form-base'
import Logo from '../../assets/logo.png'
import { signIn } from 'aws-amplify/auth'
import { PlatformMFAType } from '@betterboards/shared/helpers/auth'
import getAuthErrorMessage from '@/helpers/auth/getAuthErrorMessage'
import { Validators } from '@/helpers/validation'

interface SignInParameters {
  email: string
  password: string
}

export default Vue.extend({
  name: 'LoginForm',
  components: {
    VFormBase
  },
  data () {
    return {
      logo: Logo,
      formData: <SignInParameters>{
        email: <string>'',
        password: <string>''
      },
      pendingSignIn: <boolean>false,
      errorMessage: <string | undefined>undefined,
      formValid: <boolean>false
    }
  },
  computed: {
    formSchema () {
      const schema: any = {}

      schema.email = {
        type: 'BBTextField',
        label: this.$t('form.auth.emailLabel'),
        placeholder: this.$t('form.auth.emailPlaceholder'),
        rules: [Validators.email, Validators.allowedEmailCharacters],
        persistentPlaceholder: true,
        outlined: true,
        required: true,
        autofocus: true,
        col: 12
      }
      schema.password = {
        type: 'BBTextField',
        label: this.$t('form.auth.login.passwordLabel'),
        placeholder: this.$t('form.auth.login.passwordPlaceholder'),
        persistentPlaceholder: true,
        outlined: true,
        required: true,
        inputType: 'password',
        col: 12
      }

      return schema
    }
  },
  methods: {
    async signIn () {
      if (!this.formValid) {
        this.validate()
        return
      }

      if (this.pendingSignIn) {
        return
      }

      if (!this.formData?.email || !this.formData?.password) {
        return
      }

      this.errorMessage = undefined
      this.pendingSignIn = true
      try {
        const response = await signIn({
          username: this.formData.email,
          password: this.formData.password,
          options: {
            authFlowType: 'USER_SRP_AUTH'
          }
        })
        if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_TOTP_CODE') {
          this.$emit('mfa', PlatformMFAType.TOTP)
        }
        if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {
          this.$emit('mfa', PlatformMFAType.SMS)
        }
        if (response.nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
          this.$emit('changeTemporaryPassword')
        }
      } catch (error) {
        this.pendingSignIn = false
        this.errorMessage = getAuthErrorMessage(error.message)
        throw new Error(`Error Signing in ${error}`)
      }

      this.pendingSignIn = false
    },
    validate (): void {
      const form: any = this.$refs.form
      form.validate()
    },
    handleSubmit () {
      this.signIn()
    }
  }
})
</script>
