<template>
  <v-row justify="center">
    <v-col :cols="12" :lg="4" :order="3" :order-lg="1" class="d-flex flex-column">
      <v-row no-gutters align="center" class="flex-column grow">
        <h2 class="primary-header sans-serif text-center mb-9">{{ $t('analysis.generateReport.individualReports') }}</h2>
        <report-document-actions
          icon="users"
          :report-documents="individualReportDocuments"
          :selected-variant="selectedVariant"
          :available-variants="availableVariants"
          :pending="fetching || pendingIndividualReports === true || pendingIndividualReportsArchive === true"
          @queue="({ variantCode }) => queueAllIndividualReports(variantCode)"
          @download="emitDownloadAllIndividualReports"
        />
      </v-row>
    </v-col>
    <v-col :cols="12" :sm="6" :lg="4" :order="1" :order-lg="2">
      <v-row no-gutters align="center" justify="center" class="flex-column">
        <v-col>
          <h2 class="primary-header sans-serif text-center">{{ $t('analysis.generateReport.collectiveBoardReports') }}</h2>
          <p class="text-center text-body-2 grey--text text--darken-1 mb-4">{{ $t('analysis.generateReport.allPagesSubText') }}</p>
        </v-col>
        <report-document-actions
          icon="company"
          :report-documents="collectiveReportDocuments"
          :selected-variant="selectedVariant"
          :available-variants="availableVariants"
          :pending="fetching || pendingIndividualReportsArchive === true"
          @queue="({ variantCode }) => queueCollectiveReport(variantCode)"
          @download="emitDownload"
        />
      </v-row>
    </v-col>
    <v-col :cols="12" :sm="6" :lg="4" :order="2" :order-lg="3">
      <v-row no-gutters align="center" class="flex-column">
        <h2 class="primary-header sans-serif text-center">{{ $t('analysis.generateReport.generalBoardReport') }}</h2>
        <p class="text-center text-body-2 grey--text text--darken-1 mb-4">{{ $t('analysis.generateReport.withoutPersonalPagesSubText') }}</p>
        <report-document-actions
          icon="graph-document"
          :report-documents="genericReportDocuments"
          :selected-variant="selectedVariant"
          :available-variants="availableVariants"
          :pending="fetching || pendingIndividualReportsArchive === true"
          @queue="({ variantCode }) => queueGenericReport(variantCode)"
          @download="emitDownload"
        />
      </v-row>
    </v-col>
    <v-col :cols="12" :order="4">
      <individual-report-documents-panel
        :report-documents="individualReportDocuments"
        :selected-variant="selectedVariant"
        :available-variants="availableVariants"
        :individuals="individuals"
        :fetching="fetching"
        @queue="({ individualId, variantCode }) => queueSingleReport(individualId, variantCode)"
        @download="emitDownload"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import { PartialReportDocument, PlatformReportDocument } from '@betterboards/shared/types/Report'
import { PlatformReport, PlatformReportGroup } from '@/types/Report'
import { NullVariantCodeValue, ReportDocumentStatusFields } from '@/helpers/questionnaire'
import { getLatestIndividualReport } from '@/helpers/report'
import { queueReportDocument } from '@/helpers/reportDocument'
import ReportDocumentActions from '@/components/report/reportDocument/ReportDocumentActions.vue'
import IndividualReportDocumentsPanel from '@/components/report/reportDocument/IndividualReportDocumentsPanel.vue'
import { Company, ReportTemplate } from '@/API'
import { ResultSet } from '@betterboards/shared/types/ResultSet'
import { GenericReportIndividualId } from '@betterboards/shared/helpers/entities/Report/index'
import { ReportsIndividual } from '@betterboards/shared/types/Individual'

export default Vue.extend({
  name: 'ReportDocumentsPanel',
  components: {
    IndividualReportDocumentsPanel,
    ReportDocumentActions
  },
  data: () => ({
    pendingCollectiveReport: false,
    pendingGenericReport: false
  }),
  props: {
    surveyGroupId: { type: String, required: true },
    reportDocuments: { type: Array as PropType<PlatformReportDocument[]>, required: true },
    availableVariants: { type: Array as PropType<QuestionnaireVariantCode[]>, required: true },
    individuals: { type: Array as PropType<ReportsIndividual[]>, required: true },
    selectedVariant: { type: String as PropType<QuestionnaireVariantCode | undefined>, required: false },
    queueIcon: { type: String, default: 'report' },
    fetching: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    pendingIndividualReports: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, default: false },
    pendingIndividualReportsArchive: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, default: false },
    pendingVariantQueue: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, required: false },
    pendingVariantDownload: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, required: false }
  },
  watch: {
    latestCollectiveReports (latestCollectiveReports): void {
      if (!this.selectedVariant) {
        return
      }
      if (latestCollectiveReports[this.selectedVariant]?.finishedAt) {
        this.pendingCollectiveReport = false
      }
    },
    latestGenericReports (latestGenericReports): void {
      if (!this.selectedVariant) {
        return
      }
      if (latestGenericReports[this.selectedVariant]?.finishedAt) {
        this.pendingGenericReport = false
      }
    }
  },
  computed: {
    selectedReportGroup (): PlatformReportGroup | null {
      return this.$store.getters['Report/selectedReportGroup']
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    },
    selectedCompany (): Company {
      return this.$store.state.Company.selectedCompany
    },
    resultSet (): ResultSet {
      return this.$store.state.Analysis.resultSet
    },
    hasMultipleVariants (): boolean {
      return this.availableVariants.length > 1
    },
    collectiveReportDocuments (): PlatformReportDocument[] {
      return this.reportDocuments.filter((reportDocument) => {
        return !reportDocument.targetIndividualId
      })
    },
    genericReportDocuments (): PlatformReportDocument[] {
      return this.reportDocuments.filter((r) => r.targetIndividualId === GenericReportIndividualId)
    },
    individualReportDocuments (): PlatformReportDocument[] {
      return this.reportDocuments.filter(
        (r) => r.targetIndividualId && r.targetIndividualId !== GenericReportIndividualId
      )
    },
    latestIndividualReport (): PlatformReportDocument | undefined {
      const finishedReports: PlatformReportDocument[] = []
      const startedProcessingReports: PlatformReportDocument[] = []
      const queuedReports: PlatformReportDocument[] = []
      const variantCode = this.selectedVariant ?? 'en'

      this.individuals.forEach((individual) => {
        const report = individual.reports[variantCode]
        if (report?.finishedAt) {
          finishedReports.push(report)
        } else if (report?.startedProcessingAt) {
          startedProcessingReports.push(report)
        } else if (report?.queuedAt) {
          queuedReports.push(report)
        }
      })
      if (finishedReports.length === this.individuals.length) {
        return getLatestIndividualReport(finishedReports, ReportDocumentStatusFields.FinishedAt)
      } else if (startedProcessingReports.length) {
        return getLatestIndividualReport(startedProcessingReports, ReportDocumentStatusFields.StartedProcessingAt)
      } else if (queuedReports.length === this.individuals.length) {
        return getLatestIndividualReport(queuedReports, ReportDocumentStatusFields.QueuedAt)
      }

      return undefined
    }
  },
  methods: {
    emitQueue (payload: PlatformReportDocument): void {
      this.$emit('queue', payload)
    },
    emitDownload (reportDocument: PlatformReportDocument): void {
      this.$emit('download', reportDocument)
    },
    emitDownloadAllIndividualReports (): void {
      this.$emit('downloadIndividualReports')
    },
    getReportByVariant (variantCode?: string): PlatformReport | undefined {
      const reports: PlatformReport[] | undefined = this.selectedReportGroup?.reports?.items
      if (!reports?.length) {
        return undefined
      }
      if (!variantCode) {
        if (reports.length === 1) {
          return reports[0]
        }
        console.error(
          'No variantCode provided to getReportByVariant yet ReportGroup has multiple Reports:',
          {
            variantCode,
            selectedReportGroup: this.selectedReportGroup
          }
        )
        throw new Error('Unsure which Report to pick as no variant was provided.')
      }
      return reports.find(
        (report: PlatformReport) => report.variantCode === variantCode
      )
    },
    async queueReport (targetIndividualId?: string, variantCode?: QuestionnaireVariantCode | typeof NullVariantCodeValue): Promise<PlatformReportDocument> {
      const report = this.getReportByVariant(variantCode)
      if (!report) {
        console.error('Failed to find matching Report for given variantCode:', variantCode)
        throw new Error('Report entity not found for requested variant.')
      }
      const payload: PartialReportDocument = {
        requesterId: this.$store.state.User.entity.username,
        reportId: report.id,
        companyId: this.selectedCompany.id,
        surveyGroupId: report.surveyGroupId,
        comparisonSurveyGroupId: this.comparisonResultSet?.id,
        targetIndividualId,
        variantCode: variantCode && variantCode !== NullVariantCodeValue ? variantCode : null,
        template: ReportTemplate.Default
      }
      return await queueReportDocument(payload)
    },
    async queueSingleReport (targetIndividualId?: string, variantCode?: QuestionnaireVariantCode | typeof NullVariantCodeValue): Promise<void> {
      const queuedReportDocument = await this.queueReport(targetIndividualId, variantCode)
      this.emitQueue(queuedReportDocument)
    },
    async queueCollectiveReport (variantCode?: QuestionnaireVariantCode): Promise<void> {
      this.pendingCollectiveReport = true
      await this.queueSingleReport(undefined, variantCode)
    },
    async queueGenericReport (variantCode?: QuestionnaireVariantCode): Promise<void> {
      this.pendingGenericReport = true
      await this.queueSingleReport(GenericReportIndividualId, variantCode)
    },
    async queueAllIndividualReports (variantCode?: QuestionnaireVariantCode): Promise<void> {
      if (!this.individuals.length) {
        return
      }
      await Promise.all(this.individuals.map(async (individual) => {
        return await this.queueReport(individual.id, variantCode ?? individual.variantCode)
      }))
      this.$emit('queuedIndividualReports', { variantCode })
    }
    // getIndividualReportsDownloadTooltip (variantCode: undefined | QuestionnaireVariantCode): string | undefined {
    //   const individualReports = this.individualsReportVariants.find(({ individual, reports }: IndividualReportVariants) => reports.some(
    //     (r) => r.finishedAt && variantCode ? r.variantCode === variantCode : r.variantCode === individual.variantCode
    //   ))
    //   const variantReports = individualReports?.reports.find((reports) => reports.variantCode === variantCode)
    //   return variantReports ? getReportDocumentDownloadTooltip(variantReports) : undefined
    // },
    // canDownloadAllIndividualReports (variantCode: undefined | QuestionnaireVariantCode): boolean {
    //   return this.individualsReportVariants.every(
    //     ({ individual, reports }: IndividualReportVariants) => reports.some(
    //       (r) => r.finishedAt && variantCode ? r.variantCode === variantCode : r.variantCode === individual.variantCode
    //     )
    //   )
    // }
  }
})
</script>
