import { render, staticRenderFns } from "./PlatformAutocomplete.vue?vue&type=template&id=c9274df0&scoped=true"
import script from "./PlatformAutocomplete.vue?vue&type=script&lang=ts"
export * from "./PlatformAutocomplete.vue?vue&type=script&lang=ts"
import style0 from "./PlatformAutocomplete.vue?vue&type=style&index=0&id=c9274df0&prod&lang=scss&scoped=scoped"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9274df0",
  null
  
)

export default component.exports