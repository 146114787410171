<template>
  <div class="d-flex align-center">
    <platform-icon
      v-if="icon"
      :name="icon"
      :size="iconSize"
      style="margin-right: 2px;"
    />
    <span>{{ label }}</span>
    <span v-if="required" class="warning--text" style="margin-left: 3px;">*</span>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { IconNames } from '@/components/shared/PlatformIcon.vue'

export default Vue.extend({
  name: 'InputLabel',
  props: {
    label: { type: String, required: false },
    required: { type: Boolean, default: false },
    icon: { type: String as PropType<IconNames>, required: false },
    iconSize: { type: Number, default: 20 }
  }
})
</script>
