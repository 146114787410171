<template>
  <v-col>
    <v-row no-gutters class="mt-1">
      <span>{{ $t('form.teams.individualsTitle', [teamId]) }}</span>
    </v-row>
    <individuals-selector
      v-model="selectedIndividualIds"
      :company-individuals="companyIndividuals"
      :loading="fetchingIndividuals"
      :saving="saving"
      class="mt-3"
      v-on="allowCreateIndividual ? { created: (i) => handleIndividualCreated(i) } : undefined"
    />
  </v-col>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { Company } from '@/API'
import IndividualsSelector from '@/components/individuals/IndividualsSelector.vue'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'

export default Vue.extend({
  name: 'TeamForm',
  components: {
    IndividualsSelector
  },
  props: {
    value: { type: Array as PropType<string[]>, required: true },
    teamId: { type: String, required: true },
    saving: { type: Boolean, required: false },
    creating: { type: Boolean, default: false },
    allowCreateIndividual: { type: Boolean, default: false }
  },
  data: () => ({
    fetchingIndividuals: false,
    hasChanged: false
  }),
  watch: {
    isValid (val) {
      this.$emit('valid', val)
    },
    selectedIndividualIds (): void {
      // this.team.individuals = selectedIndividualIds
      this.hasChanged = true
    }
  },
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    selectedIndividualIds: {
      get (): string[] {
        return this.value
      },
      set (selectedIndividualIds: string[]) {
        this.$emit('input', selectedIndividualIds)
      }
    },
    isValid (): boolean {
      return this.hasChanged
    },
    companyIndividuals (): PlatformIndividual[] {
      return this.$store.getters['Individual/individualsList']
    }
  },
  methods: {
    handleIndividualCreated (individual: PlatformIndividual): void {
      if (!individual.id) {
        return
      }
      this.selectedIndividualIds.push(individual.id)
    }
  }
})
</script>
