import Vue from 'vue'
import Vuex from 'vuex'
import User from './User'
import Company from './Company'
import Individual from '@/store/Individual'
import Questionnaire from '@/store/Questionnaire'
import Block from '@/store/Block'
import Section from '@/store/Section'
import Navigation from '@/store/Navigation'
import Committee from '@/store/Committee'
import Survey from '@/store/Survey'
import SurveyGroup from '@/store/SurveyGroup'
import Analysis from '@/store/Analysis'
import Report from '@/store/Report'
import Team from '@/store/Team'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
    reset () {
      this.commit('User/reset')
      this.commit('Company/reset')
    }
  },
  actions: {
  },
  modules: {
    User,
    Company,
    Individual,
    Questionnaire,
    Block,
    Section,
    Navigation,
    Committee,
    Survey,
    SurveyGroup,
    Analysis,
    Report,
    Team
  }
})
