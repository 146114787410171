import { ScoreCollection } from './scores'
import { $t } from '../../../translations/index'
import { PlatformReport } from '../../../types/Report'
import { PlatformCompany } from '../../../types/Company'

export function getScoreCollectionTitle (
  collection: ScoreCollection,
  report?: PlatformReport, // Used for Section and System Scores
  company?: PlatformCompany // Used for Section Scores
): string {
  switch (collection) {
    case ScoreCollection.SystemAverage:
      if (report?.configuration?.systemAverageTitle) {
        return report.configuration.systemAverageTitle
      }
      return $t('analysis.results.betterBoardsAverageText') as string
    case ScoreCollection.Average:
      if (report?.configuration?.sectionAverageTitle) {
        return report.configuration?.sectionAverageTitle
      }
      return company?.name ?? $t('analysis.results.sectionScoreText') as string
    case ScoreCollection.ComparisonAverage:
      return $t('analysis.results.comparisonAverageText') as string
    case ScoreCollection.OrganisationAverage:
      return $t('analysis.results.organisationAverageText') as string
    case ScoreCollection.UniverseAverage:
      return $t('analysis.results.universeAverageText') as string
  }
}
