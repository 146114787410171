import { $t } from '../../translations/index'
import { GraphsResultData } from '../../types/ResultSet'

/**
 * Returns a footnote for line graphs based on the other board members evaluation data being present
 */
export function getLineGraphOtherEvaluationFootnote (
  graphData: GraphsResultData
): string | undefined {
  const allQuestionsAnswered: boolean = graphData.every(
    (d: GraphsResultData): boolean => d.avg
  )
  if (allQuestionsAnswered) {
    return undefined
  }

  let footnote: string | undefined
  const noSelfAnswers: boolean = graphData.every(
    (d: GraphsResultData): boolean => d.avg === null
  )
  if (noSelfAnswers) {
    footnote = $t('shared.graphs.line.footnote.otherEvaluation', 2) as string
  }

  const someBoardAnswers: boolean = graphData.some(
    (d: GraphsResultData): boolean => !!d.avg
  )
  if (someBoardAnswers) {
    const missingSingleQuestion: boolean = graphData.filter((d: GraphsResultData) => !!d.avg).length === graphData.length - 1
    footnote = $t('shared.graphs.line.footnote.otherEvaluation', missingSingleQuestion ? 1 : 2) as string
  }

  if (!footnote) {
    return undefined
  }

  return footnote
}
