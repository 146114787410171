<template>
  <v-row
    no-gutters
    justify="end"
    class="flex-md-nowrap pb-3"
  >
    <platform-select-field
      v-model="reportGroupId"
      v-tooltip="$t('analysis.reportGroup.picker.tooltip')"
      hide-details
      icon="document-edit"
      style="max-width: 400px;"
      :label="$t('analysis.reportGroup.picker.title')"
      :items="availableOptions"
    />
    <platform-button
      primary
      class="ml-2 mt-3"
      icon="create"
      v-tooltip:top="$t('analysis.reportGroup.createTooltip')"
      @click="$emit('create')"
    />
  </v-row>
</template>

<script lang="ts">
import PlatformSelectField from '@/components/shared/inputs/PlatformSelectField.vue'
import Vue, { PropType } from 'vue'
import { SelectItem } from '@/helpers/forms'

export default Vue.extend({
  name: 'ReportGroupSelector',
  components: { PlatformSelectField },
  props: {
    availableOptions: { type: Array as PropType<SelectItem[]>, required: true },
    value: { type: String, required: true }
  },
  computed: {
    reportGroupId: {
      set (val: string): void {
        this.$emit('input', val)
      },
      get (): string {
        return this.value
      }
    }
  }
})
</script>

<style scoped>

</style>
