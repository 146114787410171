<template>
  <div class="overflow-y-hidden d-flex flex-column">
    <v-row no-gutters class="pb-2">
      <v-col class="grow">
        <h2>{{ $t('analysis.summary.title') }}</h2>
      </v-col>
      <v-col class="shrink">
        <report-context-button
          :value="reportContextButtonKey"
          :master-section-id="section.masterSectionId"
          @insertRecommendation="handleInsertRecommendedContent"
        />
      </v-col>
    </v-row>
    <platform-spinner
      v-if="loading || !ready"
      class="mt-4 mx-auto"
    />
    <PlatformWYSIWYG
      v-else-if="reportSection"
      :placeholder="tooltip"
      :initial-data="currentContent"
      :max-length="MaxFieldLengths.SectionSummary"
      @newInput="saveUpdate"
    />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { Section } from '@/API'
import { MaxFieldLengths } from '@/helpers/validation'
import PlatformWYSIWYG from '@/components/PlatformWYSIWYG.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import ReportContextButton from '@/components/analysis/ReportContextButton.vue'
import { ReportContextItem } from '@/helpers/recommendations'
import { parseMarkdownContent } from '@/helpers/text'

export default Vue.extend({
  components: {
    PlatformSpinner,
    ReportContextButton,
    PlatformWYSIWYG
  },
  props: {
    section: Object as PropType<Section>,
    survey: Object,
    responses: Array,
    reportSection: { type: Object, required: false },
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      MaxFieldLengths
    }
  },
  computed: {
    ready (): boolean {
      return !!this.reportSection
    },
    currentContent (): string | undefined {
      if (this.reportSection?.summary && this.reportSection?.summary.length > 0) {
        return this.reportSection.summary
      }

      return undefined
    },
    tooltip (): string {
      return this.$t('analysis.summary.tooltip') as string
    },
    reportContextButtonKey (): ReportContextItem.DividerPageSummary {
      return ReportContextItem.DividerPageSummary
    }
  },
  methods: {
    saveUpdate (val) {
      if (!this.reportSection) {
        console.error('unable to save as no report section loaded')
        return
      }
      const reportSection = {
        ...this.reportSection,
        summary: val
      }
      this.$emit('updateReportSection', reportSection)
    },
    handleInsertRecommendedContent (content: string): void {
      const newContent = this.currentContent
        ? `${this.currentContent}\n\n${content}`
        : content
      this.saveUpdate(
        parseMarkdownContent(newContent)
      )
    }
  }
})
</script>
