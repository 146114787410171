import { ReportBenchmark } from '../../../types/ReportBenchmark'

export enum ScoreCollection {
  Average = 'average',
  ComparisonAverage = 'comparisonAverage',
  OrganisationAverage = 'organisationAverage',
  UniverseAverage = 'universeAverage',
  SystemAverage = 'systemAverage'
}

export const ScoreCollectionOrder: ScoreCollection[] = Object.values(ScoreCollection)

export function sortReportBenchmarks (benchmarks: ReportBenchmark[]): ReportBenchmark[] {
  return benchmarks.sort((a: ReportBenchmark, b: ReportBenchmark): number => {
    if (a.collection === b.collection) {
      if (a.version === b.version) {
        return 0
      }
      if (a.version === '1') {
        return -1
      }
      if (b.version === '1') {
        return 1
      }
    }
    return ScoreCollectionOrder.indexOf(a.collection) - ScoreCollectionOrder.indexOf(b.collection)
  })
}
