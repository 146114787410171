import * as i18n from '@intlify/core'
import { CoreContext } from '@intlify/core-base'
import EnglishTranslations from './locales/en.json'
import GermanTranslations from './locales/de.json'
import AmericanEnglishTranslations from './locales/us.json'

export const TranslationMessages = {
  en: EnglishTranslations,
  de: GermanTranslations,
  us: AmericanEnglishTranslations
}

export type TranslatorMethod = (key: string, ...args: unknown[]) => string | number

let TranslatorMethodOverride: TranslatorMethod | undefined
let TranslationContext: CoreContext<any, any, any, string>

/**
 * Sets the current selected locale
 */
export function setSelectedLocale (locale: string = 'en', fallbackLocale: string = 'en'): void {
  if (TranslationContext) {
    TranslationContext.locale = locale
    TranslationContext.fallbackLocale = fallbackLocale
    return
  }
  TranslationContext = i18n.createCoreContext({
    fallbackLocale,
    locale,
    messages: TranslationMessages
  })
}

export function setTranslatorMethod (method: TranslatorMethod): void {
  TranslatorMethodOverride = method
}

/**
 * Sets the given translations map `messages` for the provided `locale`.
 * @param locale
 * @param messages
 */
export function addLocaleMessages (locale: string, messages: any): void {
  TranslationContext.messages = {
    ...TranslationMessages,
    [locale]: messages
  }
}

export const $t: TranslatorMethod = (key: string, ...args: [any, any]): string | number => {
  if (TranslationContext) {
    return i18n.translate(TranslationContext, key, ...args)
  }
  if (!TranslatorMethodOverride) {
    throw new Error('Unable to provide translation as no context or translator override has been provided.')
  }
  return TranslatorMethodOverride(key, ...args)
}

/**
 * Creates a translation function for the provided locale `variantCode`.
 * @param variantCode
 */
export const createTranslator = (variantCode: string = 'en'): TranslatorMethod => {
  const context = i18n.createCoreContext({
    fallbackLocale: 'en',
    locale: variantCode,
    messages: TranslationMessages
  })
  return (key: string, ...args): string | number => i18n.translate(context, key, ...args)
}
