<template>
  <div>
    <v-row
      no-gutters
      align="center"
      justify="start"
      v-tooltip:top="hasClickListener ? value.clickTooltip : value.tooltip"
    >
      <platform-button
        v-if="value.clickEvent"
        :icon="value.icon"
        :icon-size="20"
        :primary="primary"
        :secondary="secondary"
        :force-color="primary || secondary ? undefined : 'grey darken-1'"
        text
        compact
        default-case
        class="text-body-2"
        @click="$emit('click')"
      >
        <strong v-if="!value.hideValue" style="margin-right: 2px;">
          {{ value.value }}
        </strong>
        {{ value.text }}
      </platform-button>
      <template v-else-if="value.chip">
        <v-chip
          small
          class="mt-1 mx-1"
          :color="value.primary ? 'primary' : (value.secondary ? 'secondary' : undefined)"
        >
          <platform-icon
            v-if="value.icon"
            :size="20"
            :name="value.icon"
            class="mr-1"
            color="white"
          />
          <span>
            <strong v-if="!value.hideValue">
              {{ value.value }}
            </strong>
            {{ value.text }}
          </span>
        </v-chip>
      </template>
      <template v-else>
        <platform-icon
          v-if="value.icon"
          :size="20"
          :name="value.icon"
          class="mr-1"
          color="primary lighten-2"
        />
        <span class="text-body-2 primary--text text--lighten-2">
          <strong v-if="!value.hideValue">
            {{ value.value }}
          </strong>
          {{ value.text }}
        </span>
      </template>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { EntityStatus } from '@/types/Platform'

export default Vue.extend({
  name: 'StatusButton',
  props: {
    value: {
      type: Object as PropType<EntityStatus>,
      required: true
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    hasClickListener (): boolean {
      const listeners: any = this.$listeners
      return !!listeners.click
    }
  }
})
</script>

<style scoped>

</style>
