import {
  ProgressSection,
  ResultSection,
  ResultType,
  SectionResults,
  SectionReviewItem,
  SectionReviewItemGroup
} from '@betterboards/shared/types/ResultSet'
import { GetFullReportQuery, GetReportGroupQuery, ListReportGroupsBySurveyGroupQuery, ReportDocument, ReportSection } from '@/API'
import { getFullReport } from '@/graphql/custom/getFullReport'
import { getFullReportGroup } from '@/graphql/custom/getFullReportGroup'
import { getReportSectionBySection } from '@/graphql/custom/getReportSectionBySection'
import { listFullReportGroupsBySurveyGroup } from '@/graphql/custom/listFullReportGroupsBySurveyGroup'
import { updateReport, updateReportSection } from '@/graphql/mutations'
import { listReportDocuments } from '@/graphql/queries'
import { isResultSectionReviewed } from '@/helpers/isResultSectionReviewed'
import { RepeatForOptions } from '@/helpers/questionnaire/questions'
import { getDefaultReport } from '@/helpers/report'
import { getDefaultReportGroup, updateReportGroup } from '@/helpers/reportGroup'
import { MaxFieldLengths } from '@/helpers/validation'
import { i18n } from '@/plugins/i18n'
import { QuestionnaireVariantCode } from '@/types/Platform'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import {
  PlatformReport,
  PlatformReportGroup,
  PlatformReportSection,
  ReportSectionField,
  ReportSectionResult,
  UpdateReportGroupRequestBody,
  UpdateReportGroupResponse
} from '@betterboards/shared/types/Report'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import API from '@/services/API'
import { MultiTextGroup } from '@betterboards/shared/helpers/entities/Question/index'
import { denormalizeReport } from '@betterboards/shared/helpers/entities/Report/normalizeReport'
import { SubSectionIntroduction, UpdateReportSectionInput } from '@betterboards/shared/types/API'
import omitNonUpdatableFields from '@betterboards/shared/helpers/aws/GraphQL/meta/omitNonUpdatableFields'
import { ReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import { getEnabledReportBenchmarks, normalizeReportBenchmark } from '@betterboards/shared/helpers/statistics/ReportBenchmark'

interface ReportState {
  reportGroups: PlatformReportGroup[]
  selectedReportGroupId: string | null
  selectedReportId: string | null
  selectedReportSections: PlatformReportSection[]
}

const defaultState = (): ReportState => ({
  reportGroups: [],
  selectedReportGroupId: null,
  selectedReportId: null,
  selectedReportSections: []
})

async function findReportSection (companyId: string, reportId: string, sectionId: string, variantCode: QuestionnaireVariantCode): Promise<ReportSection> {
  console.debug('Fetching ReportSections...', { companyId, reportId, sectionId })
  const result = await API.graphql({
    query: getReportSectionBySection,
    variables: {
      // companyId, // @TODO: Re-add this
      reportId,
      sectionId: { eq: sectionId },
      filter: variantCode
        ? {
            variantCode: {
              eq: variantCode
            }
          }
        : undefined
    }
  })
  return result.data.reportSectionsBySectionId.items?.[0]
}

const ResultsGroupId = 'results'
const StrengthsResultsGroupId = 'results-strengths'
const CommitteesResultsGroupId = 'results-committees'

/**
 * Builds a list of Sections based on a ResultSet Section, and adding some info from the associated ReportSection such as completion
 *  and reviewed status (based on the contents and statuses of all Results/Report Fields (Section Introductions) within the Section.)
 */
function buildReviewItems (section: SectionResults, reportSection?: PlatformReportSection): SectionReviewItemGroup[] {
  const groups: SectionReviewItemGroup[] = []

  // groups.push({
  //   title: i18n.t('analysis.sectionTabs.dividerPage'),
  //   completed: !!reportSection?.summary,
  //   content: i18n.t('analysis.progress.fieldCharacterCount', [reportSection?.summary?.length ?? 0, MaxFieldLengths.SectionSummary]) as string,
  //   contentTooltip: i18n.tc('analysis.progress.fieldCharacterCountTooltip', reportSection?.summary?.length ?? 0, [reportSection?.summary?.length ?? 0, MaxFieldLengths.SectionSummary]) as string,
  //   bold: true,
  //   link: {
  //     sectionId: section.id,
  //     tab: 'divider-page'
  //   }
  // } as SectionReviewItem)

  const reportResults = reportSection && typeof reportSection.results === 'string' ? JSON.parse(reportSection.results) : reportSection?.results

  if (section.results.some((r) => r.type === 'graphs' && r.graphType === GraphTypes.Scale)) {
    const subSections = section?.averages?.subSections?.map((ss) => ss.slug)
    if (subSections?.length) {
      subSections.forEach((slug) => {
        const ssIntro = reportSection?.subSectionIntroductions?.find((ss) => ss?.slug === slug)
        const subSectionName = section.averages?.subSections?.find((ss) => ss?.slug === slug)?.name
        if (!subSectionName) {
          console.error('Found no subsection or subsection has no name?', slug, section.averages?.subSections)
          throw new Error('Failed to get info for subsection')
        }
        const content = i18n.t('analysis.progress.fieldCharacterCount', [ssIntro?.value?.length ?? 0, MaxFieldLengths.SectionIntroductionCompact]) as string
        const contentTooltip = i18n.tc('analysis.progress.fieldCharacterCountTooltip', ssIntro?.value?.length, [ssIntro?.value?.length ?? 0, MaxFieldLengths.SectionIntroductionCompact])
        const reviewItem: SectionReviewItem = {
          title: `${i18n.t('analysis.sectionTabs.sectionIntroduction') as string} - ${subSectionName}`,
          completed: !!ssIntro?.value,
          reviewedAt: ssIntro?.reviewedAt ?? undefined,
          content,
          contentTooltip,
          link: {
            sectionId: section.id,
            tab: 'section-intro'
          }
        }
        const group: SectionReviewItemGroup = {
          group: undefined,
          items: [reviewItem]
        }
        groups.push(group)
      })
    } else {
      const content = i18n.t('analysis.progress.fieldCharacterCount', [reportSection?.introduction?.length ?? 0, MaxFieldLengths.SectionIntroduction]) as string
      const contentTooltip = i18n.tc('analysis.progress.fieldCharacterCountTooltip', reportSection?.introduction?.length ?? 0, [reportSection?.introduction?.length ?? 0, MaxFieldLengths.SectionIntroduction])

      const sectionIntroReportResult = reportResults?.find((r: ReportSectionResult) => {
        if (r.questionId) {
          return false
        }
        const reportSectionFieldResult: ReportSectionField = {
          ...r as ReportSectionField
        }
        return reportSectionFieldResult.field === 'section-intro'
      })
      const reviewItem: SectionReviewItem = {
        title: i18n.t('analysis.sectionTabs.sectionIntroduction') as string,
        completed: !!reportSection?.introduction,
        reviewedAt: sectionIntroReportResult?.reviewedAt,
        content,
        contentTooltip,
        link: {
          sectionId: section.id,
          tab: 'section-intro'
        }
      }
      const group: SectionReviewItemGroup = {
        group: undefined,
        items: [reviewItem]
      }
      groups.push(group)
    }
  }

  section.results.forEach((result: ResultType) => {
    if (result.type === 'responses') {
      const reportResult = reportResults?.find((r) => r.questionId === result.data.questionId && r.criteriaId === result.criteriaId)
      const isStrengthsAndSituResult: boolean = !!result.data.groups?.length && result.data.groups.every((g) => {
        if (!g.slug && g.id) {
          // Older questionnaires don't have slugs in these groups so use the ID
          return g.id.includes(MultiTextGroup.Strengths) || g.id.includes(MultiTextGroup.Situations)
        }
        return g.slug === MultiTextGroup.Strengths || g.slug === MultiTextGroup.Situations
      })
      const isCommitteeReviewResult: boolean = result.criteriaType === RepeatForOptions.Committees
      const hasKeyFindings: boolean = !isStrengthsAndSituResult && !isCommitteeReviewResult
      const keyFindingsSummary: boolean = hasKeyFindings && !!reportResult?.summary
      const keyFindings: number = hasKeyFindings ? (reportResult?.values?.length ?? 0) : 0
      const keyFindingsTooltip: string = keyFindingsSummary
        ? i18n.tc('analysis.progress.keyFindingsSummaryTooltip', keyFindings, [keyFindings])
        : i18n.tc('analysis.progress.keyFindingsNoSummaryTooltip', keyFindings, [keyFindings])
      const isStrengthOrCommittee: boolean = result.criteriaType === RepeatForOptions.Committees || result.criteriaType === RepeatForOptions.BoardMembers

      const newItem: SectionReviewItem = {
        title: isStrengthsAndSituResult || isCommitteeReviewResult ? result.criteria : result.data.questionText as string,
        titleTooltip: isStrengthsAndSituResult || isCommitteeReviewResult ? result.criteria : undefined,
        summary: keyFindingsSummary,
        completed: isStrengthOrCommittee ? !!reportResult?.reviewedAt : !!(keyFindingsSummary || keyFindings),
        reviewedAt: reportResult?.reviewedAt,
        requiresReview: !!result.data.groups?.length || hasKeyFindings || isCommitteeReviewResult,
        keyFindings,
        maxKeyFindings: hasKeyFindings ? 5 : undefined,
        keyFindingsTooltip,
        avatarId: result.criteriaType === RepeatForOptions.BoardMembers ? result.criteriaId : undefined,
        link: {
          sectionId: section.id,
          tab: result.committee ? 'committees' : 'response-analysis',
          resultId: result.data.questionId,
          criteriaId: result.criteriaId
        }
      }

      const shouldGroupResults: boolean = !hasKeyFindings
      if (shouldGroupResults) {
        const groupId = isStrengthsAndSituResult
          ? StrengthsResultsGroupId
          : CommitteesResultsGroupId
        const groupName = i18n.t(
          isStrengthsAndSituResult
            ? 'analysis.progress.strengthsReviewItemHeader'
            : 'analysis.progress.committeesReviewItemHeader'
        ) as string
        const groupIndex = groups.findIndex((g) => g.group === groupId && (g.resultId ? g.resultId === newItem.link.resultId : true))
        if (groupIndex === -1) {
          const group: SectionReviewItemGroup = {
            group: groupId,
            resultId: newItem.link.resultId,
            groupName,
            compact: true,
            items: [newItem]
          }
          groups.push(group)
          return
        }
        groups[groupIndex].items.push(newItem)
        return
      }

      const groupIndex = groups.findIndex((g) => g.group === ResultsGroupId)
      if (groupIndex === -1) {
        const group: SectionReviewItemGroup = {
          group: ResultsGroupId,
          groupName: 'Results',
          items: [newItem]
        }
        groups.push(group)
        return
      }
      groups[groupIndex].items.push(newItem)
    }
  })

  return groups
}

export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    selectedReportGroup (state: ReportState): PlatformReportGroup | undefined {
      return state.reportGroups.find(
        (reportGroup: PlatformReportGroup) => reportGroup.id === state.selectedReportGroupId
      )
    },
    selectedReport (state: ReportState, getters): PlatformReport | undefined {
      const selectedReportGroup: PlatformReportGroup | undefined = getters.selectedReportGroup
      if (!selectedReportGroup) {
        return undefined
      }
      return selectedReportGroup.reports.items.find(
        (report: PlatformReport) => report.id === state.selectedReportId
      )
    },
    resultSetSections (state: ReportState, getters, rootState): ProgressSection[] {
      const selectedVariant = rootState.Analysis.variantCode
      if (!selectedVariant) {
        return []
      }
      if (!getters.selectedReport) {
        return []
      }
      const sections: ProgressSection[] = rootState.Analysis.resultSet.sections.map((s: SectionResults) => {
        const reportSection: PlatformReportSection | undefined = state.selectedReportSections.find(
          (rs) => rs?.sectionId === s.id
        ) as PlatformReportSection ?? undefined
        const reviewItems = buildReviewItems(s, reportSection)

        const sectionProgress: ProgressSection = {
          ...s,
          reviewItems,
          reviewed: isResultSectionReviewed(s, reviewItems),
          completed: reviewItems.every((g) => g.items.every((i) => i.completed))
        }
        return sectionProgress
      })
      return sections
    },
    reportBenchmarks (state: ReportState, getters, rootState): ReportBenchmark[] {
      const selectedReportGroup: PlatformReportGroup | undefined = getters.selectedReportGroup
      if (!selectedReportGroup?.scores?.length) {
        return []
      }
      const sections: ResultSection[] = rootState.Analysis.resultSet.sections
      return selectedReportGroup.scores.map(
        (score: ReportBenchmark) => normalizeReportBenchmark(score, sections)
      )
    },
    enabledReportBenchmarks (state: ReportState, getters, rootState): ReportBenchmark[] {
      return getEnabledReportBenchmarks(getters.reportBenchmarks)
    }
  },
  mutations: {
    reset (state: ReportState) {
      state.reportGroups.splice(0, state.reportGroups.length)
      state.selectedReportGroupId = null
      state.selectedReportId = null
      state.selectedReportSections.splice(0, state.selectedReportSections.length)
    },
    setSelectedReportGroup (
      state: ReportState,
      { id: reportGroupId, variantCode }: { id: string, variantCode?: QuestionnaireVariantCode }
    ) {
      const selectedReportGroup = state.reportGroups.find(
        (rg) => rg.id === reportGroupId
      )
      if (!selectedReportGroup) {
        console.error('Selected ReportGroup is not known in state.', reportGroupId)
        throw new Error('Failed to select unknown ReportGroup.')
      }
      state.selectedReportGroupId = reportGroupId

      // Check if selected Report is a child of the new selected ReportGroup
      const report = selectedReportGroup.reports?.items.find(
        (r) => r.id === state.selectedReportId
      )
      if (report) {
        // Current selected Report is a child of the new ReportGroup, nothing to do.
        return
      }
      // Try finding a Report matching the requested variantCode.
      const matchingVariantReport = selectedReportGroup.reports.items.find(
        (r) => r.variantCode === variantCode
      )
      state.selectedReportId = matchingVariantReport?.id ??
        getDefaultReport(selectedReportGroup)?.id ??
        null
    },
    setSelectedReport: (state: ReportState, { reportId }: { reportId: string }) => {
      state.selectedReportId = reportId
    },
    setSelectedReportSections: (state: ReportState, { reportSections }: { reportSections: PlatformReportSection[] }) => {
      state.selectedReportSections.splice(
        0,
        state.selectedReportSections.length,
        ...reportSections
      )
    },
    setReportGroups: (state: ReportState, { reportGroups }: { reportGroups: PlatformReportGroup[] }) => {
      state.reportGroups.splice(
        0,
        state.reportGroups.length,
        ...reportGroups.map((reportGroup) => {
          if (typeof reportGroup.scores === 'string') {
            reportGroup.scores = JSON.parse(reportGroup.scores)
          }
          return reportGroup
        })
      )
    },
    setReport: (state: ReportState, { report }: { report: PlatformReport }) => {
      const formattedReport: PlatformReport = {
        ...report,
        sections: {
          items: []
        }
      }
      if (typeof report.configuration === 'string') {
        formattedReport.configuration = JSON.parse(report.configuration)
      }

      // Update Reports list on selected ReportGroup
      const selectedReportGroup = state.reportGroups.find(
        (g) => g.id === state.selectedReportGroupId
      )
      if (!selectedReportGroup) {
        console.error('Failed to find selected ReportGroup', formattedReport, state.selectedReportGroupId)
        return
      }
      const index = selectedReportGroup.reports.items.findIndex(
        (r) => r.id === formattedReport.id
      )
      if (index === -1) {
        console.error('Failed to find selected Report', selectedReportGroup, formattedReport)
        return
      }
      selectedReportGroup.reports.items.splice(index, 1, formattedReport)
    },
    setReportGroup: (state, item: PlatformReportGroup) => {
      item.scores = typeof item.scores === 'string' ? JSON.parse(item.scores) : item.scores
      const index = state.reportGroups.findIndex((lookup) => lookup.id === item.id)
      if (item.deletedAt) {
        if (index === -1) {
          return
        }
        state.reportGroups.splice(index, 1)
        return
      }
      if (index === -1) {
        state.reportGroups.push(item)
        return
      }

      const existingReportGroup: PlatformReportGroup = state.reportGroups[index]
      // Merge new data over existing data
      state.reportGroups.splice(
        index,
        1,
        {
          ...existingReportGroup,
          ...item
        }
      )
    },
    updateReportSection (state: ReportState, reportSection: PlatformReportSection) {
      if (typeof reportSection.results === 'string') {
        reportSection.results = JSON.parse(reportSection.results)
      }
      const index = state.selectedReportSections.findIndex((rs) => rs?.id === reportSection.id)
      if (index === -1) {
        state.selectedReportSections.push(reportSection)
        return
      }
      state.selectedReportSections.splice(index, 1, reportSection)
    }
  },
  actions: {
    async loadReportGroups (
      { commit, state, rootState }: { state: ReportState, commit: any, rootState: any },
      { surveyGroupId }: { surveyGroupId: string }
    ) {
      const companyId = rootState.Company.selectedCompany?.id
      if (!companyId) {
        throw new Error('Failed to load report as no company is selected.')
      }
      const filter = {
        companyId: {
          eq: companyId
        }
      }
      const reportGroupsResponse = await API.graphql({
        query: listFullReportGroupsBySurveyGroup,
        variables: {
          surveyGroupId,
          filter
        }
      }) as GraphQLResult<ListReportGroupsBySurveyGroupQuery>

      const reportGroups: PlatformReportGroup[] | undefined = reportGroupsResponse.data?.listReportGroupsBySurveyGroup?.items as PlatformReportGroup[] | undefined
      if (!reportGroups?.length) {
        console.error('Failed to find ReportGroups', reportGroupsResponse)
        throw new Error('Failed to fetch Report Groups.')
      }
      commit('setReportGroups', {
        reportGroups: reportGroups.map((reportGroup) => {
          if (!reportGroup.scores) {
            reportGroup.scores = []
          }
          return reportGroup
        })
      })
      const defaultSelectedReportGroup = getDefaultReportGroup(reportGroups)
      commit('setSelectedReportGroup', {
        id: defaultSelectedReportGroup.id
      })
    },
    async fetchReportGroup ({ commit, state }: { state: ReportState, commit: any }, reportGroupId: string): Promise<PlatformReportGroup> {
      const reportGroupResponse = await API.graphql({
        query: getFullReportGroup,
        variables: {
          id: reportGroupId
        }
      }) as GraphQLResult<GetReportGroupQuery>

      const reportGroup: PlatformReportGroup | undefined = reportGroupResponse.data?.getReportGroup as PlatformReportGroup | undefined
      if (!reportGroup) {
        console.error('Failed to fetch ReportGroup', { reportGroupId, reportGroupResponse })
        throw new Error('Failed to fetch Report Group.')
      }

      if (!reportGroup.scores) {
        reportGroup.scores = []
      }

      commit('setReportGroup', reportGroup)

      return reportGroup
    },
    async selectReport (
      { commit, state, rootState }: { state: ReportState, commit: any, rootState: any },
      { reportId }: { reportId: string }
    ): Promise<void> {
      const result = await API.graphql({
        query: getFullReport,
        variables: {
          id: reportId
        }
      }) as GraphQLResult<GetFullReportQuery>

      const report: PlatformReport | undefined = result.data?.getReport as PlatformReport | undefined
      if (!report) {
        return
      }
      commit('setReport', { report })
      commit('setSelectedReport', { reportId: report.id })
      commit('setSelectedReportSections', {
        reportSections: report.sections?.items ?? []
      })
    },
    async saveReport ({ commit }, report: PlatformReport<string>) {
      const result = await API.graphql({
        query: updateReport,
        variables: {
          input: {
            ...denormalizeReport(report as any)
          }
        }
      })

      commit('setReport', {
        report: result.data.updateReport
      })
    },
    async updateAllReportVariants ({ state, commit }: { state: ReportState, commit: any }, updateFields: Partial<PlatformReport>) {
      const selectedReportGroup: PlatformReportGroup | undefined = state.reportGroups.find(
        (reportGroup) => reportGroup.id === state.selectedReportGroupId
      )
      if (!selectedReportGroup) {
        return
      }
      const reports = selectedReportGroup.reports?.items
      if (!reports?.length) {
        return
      }
      const promises = reports.map(async (report) => {
        const result = await API.graphql({
          query: updateReport,
          variables: {
            input: {
              ...denormalizeReport(updateFields as any),
              id: report.id
            }
          }
        })
        commit('setReport', {
          report: result.data.updateReport
        })
      })
      return await Promise.all(promises)
    },
    async loadReportSection ({ commit, rootState }, { reportId, sectionId, variantCode }): Promise<ReportSection> {
      const section = await findReportSection(
        rootState.Company.selectedCompany.id,
        reportId,
        sectionId,
        variantCode
      )
      section.results = JSON.parse(section.results ?? '[]')
      commit('updateReportSection', section)

      return section
    },
    async saveReportSection ({ commit }, reportSection: ReportSection) {
      const subSectionIntroductions: Array<SubSectionIntroduction | null> | undefined = reportSection.subSectionIntroductions?.map(
        (intro: SubSectionIntroduction | null): SubSectionIntroduction | null => {
          if (!intro) {
            return null
          }

         return omitNonUpdatableFields(intro)
        }
      )

      const input: Partial<ReportSection> & UpdateReportSectionInput = omitNonUpdatableFields(
        {
          ...reportSection,
          subSectionIntroductions
        },
        ['report']
      )
      if (reportSection.results && typeof reportSection.results !== 'string') {
        input.results = JSON.stringify(reportSection.results)
      }
      const updateReportSectionResponse = await API.graphql({
        query: updateReportSection,
        variables: {
          input
        }
      })
      const updatedReportSection = updateReportSectionResponse.data.updateReportSection
      commit('updateReportSection', updatedReportSection)
      return updatedReportSection
    },
    async listReportDocuments () {
      const response = await API.graphql({
        query: listReportDocuments
      })

      return response.data.listReportDocuments as { items: ReportDocument[], nextToken: null | string }
    },
    async updateReportGroup ({ commit }: { state: ReportState, getters: any, commit: any, dispatch: any }, reportGroupData: UpdateReportGroupRequestBody): Promise<PlatformReportGroup> {
      if (!reportGroupData.reportGroupId) {
        console.error('Call to updateReportGroup failed as ReportGroup has no ID:', reportGroupData)
        throw new Error('Unable to update ReportGroup without an ID')
      }
      let updateReportGroupResponse: UpdateReportGroupResponse
      try {
        updateReportGroupResponse = await updateReportGroup(reportGroupData)
        // await dispatch('updateAllReportVariants', { comparisonSurveyGroupId })
      } catch (err) {
        console.error('Failed to update ReportGroup.', { err, reportGroupData })
        throw err
      }

      if (!updateReportGroupResponse) {
        console.error('Got no response from updating ReportGroup?', updateReportGroupResponse)
        throw new Error('Failed to update ReportGroup')
      }
      const updatedReportGroup: PlatformReportGroup = {
        ...updateReportGroupResponse.reportGroup,
        id: reportGroupData.reportGroupId
      }
      commit('setReportGroup', updatedReportGroup)

      return updatedReportGroup
    },
    async saveComparisonResultSet (
      { state, getters, commit, dispatch }: { state: ReportState, getters: any, commit: any, dispatch: any },
      { comparisonSurveyGroupId }: { comparisonSurveyGroupId: string | null }
    ) {
      const selectedReportGroup: PlatformReportGroup = getters.selectedReportGroup
      const reportGroupData = {
        reportGroupId: selectedReportGroup.id,
        surveyGroupId: selectedReportGroup.surveyGroupId,
        scores: undefined,
        comparisonSurveyGroupId
      }
      let updateReportGroupResponse: UpdateReportGroupResponse
      try {
        updateReportGroupResponse = await updateReportGroup(reportGroupData)
      } catch (err) {
        console.error('Failed to save comparison result set for all variants!', { reportGroupData, err })
        throw err
      }

      const updatedReports = updateReportGroupResponse?.reports
      if (!updatedReports) {
        console.error('Update ReportGroup response missing updated Reports data?', updateReportGroupResponse)
        throw new Error('Failed to update Comparison Result Set')
      }
      updatedReports.forEach((report: PlatformReport) => {
        commit('setReport', { report })
      })
    }
  }
}
