import { TargetGroupOptions } from '../../../../types/Block'
import { IndividualAppointmentItems, TenureIndividual } from '../../../../types/Individual'
import { ResultsIndividual } from '../../../../types/ResultSet'
import { getCompanyPosition, getJobTitle, getTenureLengthStatus, IndividualAppointmentsOrder, sortByTenureLength, TenureLengthStatus } from '../index'

const AvailableTargetGroupOptions: TargetGroupOptions[] = [TargetGroupOptions.Executives, TargetGroupOptions.NonExecutives]

export default function calculateIndividualTenures (
  individuals: ResultsIndividual[],
  targetGroups: TargetGroupOptions[] = AvailableTargetGroupOptions
): TenureIndividual[] {
  return individuals
    .filter((ri: ResultsIndividual): boolean => {
      const individualCompanyPosition: TargetGroupOptions | undefined = getCompanyPosition(ri.role)
      if (!individualCompanyPosition) {
        return false
      }
      return targetGroups.includes(individualCompanyPosition)
    })
    .map((i: ResultsIndividual): TenureIndividual => {
      let tenureLength
      let tenureLengthStatus
      if (i.tenureLength === undefined) {
        tenureLengthStatus = TenureLengthStatus.None
      } else {
        tenureLength = i.tenureLength
        tenureLengthStatus = getTenureLengthStatus(i.role, i.tenureLength)
      }

      let appointmentItems: IndividualAppointmentItems[] = []
      let totalAppointments: number | undefined
      if (i.appointments) {
        const appointments = Object.keys(i.appointments)
        totalAppointments = appointments.reduce(
          (total: number, role: string): number => {
            const count: number = i.appointments?.[role] ?? 0
            return total + count
          },
          0
        )
        appointmentItems = appointments.reduce(
          (appointmentItems: IndividualAppointmentItems[], role: string): IndividualAppointmentItems[] => {
            if (i.appointments?.[role]) {
              appointmentItems.push({
                role,
                count: i.appointments?.[role]
              })
            }
            return appointmentItems
          },
          []
        )
          .sort((a, b): number => {
            return IndividualAppointmentsOrder.indexOf(a.role) - IndividualAppointmentsOrder.indexOf(b.role)
          })
      }

      return {
        ...i,
        roleName: getJobTitle(i),
        tenureLength,
        tenureLengthStatus,
        appointmentItems,
        totalAppointments
      }
    })
    .sort(sortByTenureLength)
}
