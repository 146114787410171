<template>
  <div class="px-2">
    <v-row v-if="result.data.groups.length > 1">
      <v-col v-for="(group, index) in result.data.groups" :key="index" class="font-weight-bold grey--text text--darken-2 pb-2 text-body-1">
        {{ group.name }}
      </v-col>
    </v-row>
    <v-row v-for="(a, index) in response.value" :key="`row-${index}`" class="response">
      <v-col
        v-for="(group, fIndex) in result.data.groups" :key="`${a[group.id]}-${fIndex}`"
        class="py-2"
      >
        <platform-text-area
          v-if="editing && answerOverride && answerOverride[index]"
          class="text-body-2"
          hide-details
          auto-grow
          outlined
          :rows="1"
          v-model="answerOverride[index][group.name]"
        />
        <span v-else class="text-body-2">
          <span v-if="answerOverride[index] && answerOverride[index][group.name] && answerOverride[index][group.name] !== a[group.name]" v-tooltip="`Original: &quot;${a[group.name]}&quot;`">
            {{ answerOverride[index][group.name] }}<span class="secondary--text">*</span>
          </span>
          <span v-else>
            {{ a[group.name] }}
          </span>
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { MultiTextQuestionAnswer, QuestionAnswer } from '@/types/Question'
import _ from 'lodash'
import PlatformTextArea from '@/components/shared/inputs/PlatformTextArea.vue'
import { MultiTextResponsesResult } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'MultiTextAnswerDisplay',
  components: {
    PlatformTextArea
  },
  props: {
    response: Object as PropType<MultiTextQuestionAnswer>,
    result: { type: Object as PropType<MultiTextResponsesResult>, required: true },
    editing: { type: Boolean, default: false },
    override: { type: Object as PropType<QuestionAnswer>, required: false }
  },
  data: () => ({
    answerOverride: <any[]>[]
  }),
  mounted () {
    this.init()
  },
  beforeDestroy () {
    this.emitOverrides()
  },
  watch: {
    'response.value': 'init',
    override: 'init',
    editing (editing: boolean): void {
      if (!editing) {
        this.emitOverrides()
      }
    }
  },
  computed: {
    fields (): string[] {
      return this.response.value.reduce<string[]>((fields: string[], answer: { [groupName: string]: string[] }) => {
        Object.keys(answer).forEach((field: string) => {
          if (!fields.includes(field)) {
            fields.push(field)
          }
        })
        return fields
      }, [])
    }
  },
  methods: {
    init (): void {
      this.answerOverride = _.cloneDeep(this.override?.value || this.response.value)
    },
    emitOverrides (): void {
      this.$emit('input', this.answerOverride)
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.response:not(:nth-child(1)) {
  border-top: 1px solid var(--v-grey-base);
}
</style>
