<template>
  <v-row>
    <v-col
      v-for="(individual, index) in individuals"
      :key="`${individual.id}-${index}`"
      class="d-flex flex-column"
      :cols="12"
      :md="6"
      :xl="4"
    >
      <platform-entity-card
        entity-type="individual"
        align="center"
        :row="$vuetify.breakpoint.smAndDown"
        :entity="individual"
        :inner-margin="0"
      >
        <template #default>
          <v-row no-gutters justify="center" align="center" class="flex-column">
            <v-col :cols="12" class="pb-4">
              <span class="text-body-2 grey--text text--darken-2">
                {{ individual.roleName }}
              </span>
            </v-col>

            <report-document-actions
              class="pb-2"
              icon="user"
              :report-documents="individual.reports"
              :selected-variant="selectedVariant"
              :available-variants="availableVariants"
              :pending="fetching || pendingIndividualReportsArchive === true"
              @queue="({ variantCode }) => emitQueue(individual, variantCode)"
              @download="emitDownload"
            />
          </v-row>
        </template>
      </platform-entity-card>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import { PlatformReportDocument } from '@betterboards/shared/types/Report'
import { PlatformReportGroup } from '@/types/Report'
import ReportDocumentActions from '@/components/report/reportDocument/ReportDocumentActions.vue'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import { ResultSet } from '@betterboards/shared/types/ResultSet'
import { ReportsIndividual } from '@betterboards/shared/types/Individual'

export default Vue.extend({
  name: 'IndividualReportDocumentsPanel',
  components: {
    PlatformEntityCard,
    ReportDocumentActions
  },
  data: () => ({
    pendingIndividualReportsArchive: false
  }),
  props: {
    reportDocuments: { type: Array as PropType<PlatformReportDocument[]>, required: true },
    availableVariants: { type: Array as PropType<QuestionnaireVariantCode[]>, required: true },
    individuals: { type: Array as PropType<ReportsIndividual[]>, required: true },
    selectedVariant: { type: String as PropType<QuestionnaireVariantCode | undefined>, required: false },
    fetching: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    pendingVariantQueue: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, required: false },
    pendingVariantDownload: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, required: false }
  },
  computed: {
    selectedReportGroup (): PlatformReportGroup | null {
      return this.$store.getters['Report/selectedReportGroup']
    },
    resultSet (): ResultSet {
      return this.$store.state.Analysis.resultSet
    },
    hasMultipleVariants (): boolean {
      return this.availableVariants.length > 1
    }
  },
  methods: {
    emitQueue (individual: ReportsIndividual, variantCode?: QuestionnaireVariantCode): void {
      this.$emit('queue', {
        individualId: individual.id,
        variantCode: variantCode ?? individual.variantCode
      })
    },
    emitDownload (reportDocument: PlatformReportDocument): void {
      this.$emit('download', reportDocument)
    }
  }
})
</script>
