<template>
  <v-textarea
    v-model="inputValue"
    class="platform-text-area"
    ref="input"
    clear-icon="mdi-close"
    :hide-details="hideDetails"
    :full-width="fullWidth"
    :readonly="readonly"
    :label="required ? `${label || ''} *` : label"
    :rules="validationRules"
    :loading="loading"
    :disabled="disabled"
    :outlined="outlined"
    :auto-grow="autoGrow"
    :prepend-icon="prependIcon"
    :placeholder="placeholder"
    :suffix="suffix"
    :rows="rows"
    :clearable="clearable"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
  >
    <template #label v-if="$slots.label">
      <input-label :label="label" :required="required" />
    </template>
    <template #append v-if="$slots.append">
      <slot name="append" />
    </template>
  </v-textarea>
</template>

<script lang="ts">
import Vue from 'vue'
import { Validators } from '@/helpers/validation'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'

export default Vue.extend({
  name: 'PlatformTextArea',
  components: {
    InputLabel
  },
  props: {
    value: { type: String, required: false },
    label: { type: String, required: false },
    loading: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    required: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    outlined: { type: Boolean, required: false },
    autoGrow: { type: Boolean, required: false },
    clearable: { type: Boolean, required: false },
    hideDetails: { type: Boolean, required: false },
    fullWidth: { type: Boolean, required: false },
    prependIcon: { type: String, required: false },
    placeholder: { type: String, required: false },
    suffix: { type: String, required: false },
    rows: { type: Number, required: false },
    rules: { type: Array, required: false }
  },
  computed: {
    inputValue: {
      set (val: string): void {
        this.$emit('input', val)
      },
      get (): string {
        return this.value
      }
    },
    validationRules (): any[] {
      const rules: any[] = []
      if (this.required) {
        rules.push(Validators.required)
      }
      if (Array.isArray(this.rules)) {
        rules.push(...this.rules)
      }
      return rules
    },
    valid (): boolean {
      const input: any = this.$refs.input
      return input?.valid ?? false
    }
  },
  methods: {
    validate (force: boolean = false): boolean {
      const input: any = this.$refs.input
      return input.validate(force)
    }
  }
})
</script>

<style lang="scss" scoped>
.platform-text-area::v-deep {
  .v-input__slot {
    min-height: 40px; // Override of 56px default

    .v-input__append-inner {
      margin-top: 15px;
    }
    .v-text-field__slot {
      .v-label {
        top: 12px;
      }
      textarea {
        max-height: 7em;
        overflow: auto; // Add inner scroll
        margin-top: 6px;
        margin-bottom: 4px;
      }
    }
  }
}
</style>
