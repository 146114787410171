import { V2StatisticsQuery } from '../../types/Statistic'
import { ReportBenchmark } from '../../types/ReportBenchmark'
import { CompanyStatsCollection } from './index'
import { UniverseQuery } from '../../types/Report'

export function isOrganisationV2StatsQuery (query: V2StatisticsQuery): boolean {
  return query.field === CompanyStatsCollection.Organisation
}

export function buildOrganisationV2StatsQuery (organisationCompanyId: string): V2StatisticsQuery {
  return {
    field: CompanyStatsCollection.Organisation,
    value: organisationCompanyId
  }
}

export function buildReportBenchmarkV2StatsQueries (score: ReportBenchmark): V2StatisticsQuery[] {
  if (score.companyId) {
    return [buildOrganisationV2StatsQuery(score.companyId)]
  }
  return []
}

export function findV2StatsQueryIndex (queries: V2StatisticsQuery[], targetQuery: V2StatisticsQuery): number {
  return queries.findIndex(
    (query: V2StatisticsQuery): boolean => {
      return query.field === targetQuery.field && query.value === targetQuery.value
    }
  )
}

export function findV2StatsQuery (queries: V2StatisticsQuery[], targetQuery: V2StatisticsQuery): V2StatisticsQuery | undefined {
  const index: number = findV2StatsQueryIndex(queries, targetQuery)
  if (index === -1) {
    return undefined
  }
  return queries[index]
}

/**
 * Normalizes v1 Universe Queries into v2 Statistics Queries
 */
export function normalizeUniverseQueries (queries: UniverseQuery[]): V2StatisticsQuery[] {
  return queries
    .map((q: UniverseQuery): V2StatisticsQuery => {
      return {
        field: q.collection,
        value: q.category
      }
    })
}
/**
 * Reverts v2 Statistics Queries back into v1 Universe Queries
 */
export function denormalizeUniverseQueries (queries: V2StatisticsQuery[]): UniverseQuery[] {
  return queries
    .map((q: V2StatisticsQuery): UniverseQuery => {
      return {
        collection: q.field,
        category: q.value
      }
    })
}

/**
 * Returns whether two arrays of queries match exactly.
 */
export function doQueriesMatch (a: UniverseQuery[], b: UniverseQuery[]): boolean {
  if (!a.length && !b.length) {
    return true
  }
  if (!a.length || !b.length) {
    return false
  }
  if (a.length !== b.length) {
    return false
  }
  return a.every((q: UniverseQuery, index: number): boolean => {
    return q.collection === b[index].collection && q.category === b[index].category
  })
}
