<template>
  <v-form-base
    v-if="enabled"
    :model="question"
    :schema="schema"
    @click="onInput"
    @input="onInput"
  />
</template>

<script>
import Vue from 'vue'
import VFormBase from 'vuetify-form-base'

export default Vue.extend({
  name: 'TextQuestionForm',
  props: {
    value: { type: Object, required: false }
  },
  data: () => ({
    enabled: false // As schema currently has nothing in it
  }),
  components: {
    VFormBase
  },
  computed: {
    question () {
      return {
        ...this.value
      }
    },
    schema () {
      return {}
    }
  },
  methods: {
    onInput (val) {
      this.$emit('input', val.data)
    }
  }
})
</script>
