<template>
  <div class="px-8 pt-10 text-center">
    <h3 class="sans-serif">
      {{ $t('form.wizard.reviewQuestionnaire.successMessage') }}
    </h3>
    <v-row no-gutters class="text-center align-center justify-center mt-2 mb-3">
      <platform-avatar
        entity-type="company"
        class="ml-5 mr-2"
        :pad-bottom="false"
        :entity="selectedCompany"
        :size="44"
      />
      <h2 class="sans-serif">{{ questionnaire.name }}</h2>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <div class="text-left">
        <span class="text-caption grey--text text--darken-1">
          {{ $t('form.wizard.reviewQuestionnaire.packageLabel') }}
        </span>
        <v-row no-gutters align="center" justify="center" class="text-center px-2">
          <platform-avatar
            entity-type="package"
            placeholder-size="xs"
            :pad-bottom="false"
            :size="40"
            :entity="selectedPackage"
          />
          <h4 class="sans-serif ml-2">{{ selectedPackage.name }}</h4>
        </v-row>
      </div>
    </v-row>
    <v-row
      v-if="linkedQuestionnaire"
      no-gutters
      class="text-center align-center justify-center"
    >
      <span class="mt-3 text-body-2 grey--text text--darken-3">
        {{ linkedQuestionnaireText }}
      </span>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformPackage } from '@/types/Platform'
import { Company, Questionnaire } from '@/API'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'

export default Vue.extend({
  components: {
    PlatformAvatar
  },
  props: {
    value: { type: Object as PropType<Questionnaire>, required: true },
    selectedPackage: { type: Object as PropType<PlatformPackage>, required: false },
    linkedQuestionnaire: { type: Object as PropType<Questionnaire>, required: false }
  },
  data: () => ({
    //
  }),
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    questionnaire (): Questionnaire {
      return this.value
    },
    linkedQuestionnaireText (): string {
      return this.$t('form.wizard.reviewQuestionnaire.linkedSurveyText', [this.linkedQuestionnaire?.name]) as string
    }
  }
})
</script>
