<template>
  <div>
    <platform-modal
      v-model="showModal"
      icon="committee"
      :width="800"
      :title="$t('modal.committees.edit.title', [committee && committee.name || $t('shared.entities.committee')])"
      style="min-height: 60vh"
      :saving="saving"
      :save-disabled="!isValid"
      @save="save"
      @delete="deleteConfirmationVisible = true"
    >
      <committee-form
        v-if="formData && formData.id"
        v-model="formData"
        :saving="saving"
        :company-id="selectedCompany.id"
        @valid="(val) => isValid = val"
      />
    </platform-modal>
    <confirmation-modal
        v-if="deleteConfirmationVisible"
        action="delete"
        :entity="committee"
        entity-type="committee"
        @confirm="confirmDelete"
        @cancel="deleteConfirmationVisible = false"
      />
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import PlatformModal from '@/components/PlatformModal.vue'
import CommitteeForm from '@/components/committees/CommitteeForm.vue'
import { trimStringFields } from '@/helpers/validation'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'

export default Vue.extend({
  name: 'EditCommitteeModal',
  components: {
    PlatformModal,
    CommitteeForm,
    ConfirmationModal
  },
  props: {
    committee: { type: Object as PropType<{ individuals: { items: PlatformIndividual[] } }>, required: true }
  },
  mounted (): void {
    this.formData = JSON.parse(JSON.stringify(this.committee))
  },
  data () {
    return {
      timerId: null,
      showModal: true,
      formData: <any>{
        id: null,
        tags: [],
        individuals: {
          items: []
        }
      },
      nextToken: null,
      ready: false,
      saving: false,
      isValid: false,
      deleteConfirmationVisible: false
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany'])
  },
  watch: {
    showModal (val: boolean): void {
      if (!val) {
        this.ready = false
        this.$emit('close')
      }
    },
    committee (val: any): void {
      if (val) {
        this.formData = JSON.parse(JSON.stringify(this.committee))
      }
    }
  },
  methods: {
    ...mapActions('Committee', ['updateCommittee', 'deleteCommittee']),
    async save () {
      const originalIndividuals = this.committee.individuals?.items ?? []
      const originalIndividualIds: string[] = originalIndividuals.map(
        (i: any) => i.individualId
      )
      const individuals: string[] = this.formData.individuals.items.map(
        (i) => i.individualId
      )
      const newIndividualIds = individuals.filter(
        (individualId) => !originalIndividualIds.includes(individualId)
      )
      const removedIndividualIds = originalIndividualIds.filter(
        (individualId) => !individuals.includes(individualId)
      )

      const formData = trimStringFields({
        id: this.formData.id,
        name: this.formData.name,
        tags: this.formData.tags
      })
      this.saving = true
      try {
        await this.updateCommittee({ formData, individualIds: newIndividualIds, removedIndividualIds })
      } catch (err) {
        console.error('Caught an error while trying to update a committee:', err)
        this.$toasted.global.error('update the committee')
      } finally {
        this.saving = false
      }

      this.showModal = false
      const newCommittee = {
        ...formData,
        individuals: {
          items: this.formData.individuals.items
        },
        companyId: this.selectedCompany.id
      }
      this.$emit('save', newCommittee)
      this.$toasted.global.success(this.$t('modal.committees.edit.successMessage'))
    },
    confirmDelete (): void {
      this.$emit('delete', this.committee)
      this.deleteConfirmationVisible = false
    }
  }
})
</script>
