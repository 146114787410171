<template>
  <v-row
    no-gutters
    align="center"
    justify="end"
    class="flex-column shrink"
  >
    <v-row
      no-gutters
      align="center"
      justify="end"
      class="flex-column shrink"
    >
      <v-col class="shrink d-flex pb-2">
        <report-document-action
          action="queue"
          :icon="icon"
          :selected-variant="selectedVariant"
          :report-documents="reportDocuments"
          :variants="availableVariants"
          :pending-variant="pendingVariantQueue"
          @click="emitQueue"
        />
      </v-col>
      <v-col class="grow d-flex">
        <report-document-action
          action="download"
          icon="download"
          :selected-variant="selectedVariant"
          :report-documents="reportDocuments"
          :variants="availableVariants"
          :pending-variant="pendingVariantDownload"
          @click="emitDownload"
        />
      </v-col>
    </v-row>
    <v-col class="pt-1">
      <report-document-status
        v-if="latestReportDocument"
        :finished-at="latestReportDocument.finishedAt"
        :queued-at="latestReportDocument.queuedAt"
        :started-processing-at="latestReportDocument.startedProcessingAt"
        :failed-at="latestReportDocument.failedAt"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import ReportDocumentAction from '@/components/report/reportDocument/ReportDocumentAction.vue'
import ReportDocumentStatus from '@/components/report/reportDocument/ReportDocumentStatus.vue'
import { NullVariantCodeValue } from '@/helpers/questionnaire'
import { PlatformReportDocument } from '@betterboards/shared/types/Report'
import { getVariantLatestReportDocument } from '@/helpers/reportDocument'
import { ResultSet } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'ReportDocumentActions',
  components: {
    ReportDocumentStatus,
    ReportDocumentAction
  },
  data: () => ({
    pendingFetch: false,
    pendingCollectiveReport: false
  }),
  props: {
    availableVariants: { type: Array as PropType<QuestionnaireVariantCode[]>, required: false },
    reportDocuments: { type: Array as PropType<PlatformReportDocument[]>, required: false },
    selectedVariant: { type: String as PropType<QuestionnaireVariantCode | undefined>, required: false },
    icon: { type: String, default: 'report' },
    disabled: { type: Boolean, default: false },
    pendingVariantQueue: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, required: false },
    pendingVariantDownload: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, required: false }
  },
  computed: {
    resultSet (): ResultSet {
      return this.$store.state.Analysis.resultSet
    },
    hasMultipleVariants (): boolean {
      if (!this.availableVariants) {
        return false
      }
      return this.availableVariants.length > 1
    },
    /** Latest ReportDocument of the selected Variant **/
    latestReportDocument (): PlatformReportDocument | undefined {
      return getVariantLatestReportDocument(
        this.reportDocuments,
        this.selectedVariant
      )
    }
  },
  methods: {
    emitQueue (payload: { variantCode: QuestionnaireVariantCode | typeof NullVariantCodeValue }): void {
      this.$emit('queue', payload)
    },
    emitDownload (payload: { variantCode: QuestionnaireVariantCode | typeof NullVariantCodeValue }): void {
      const reportDocument = getVariantLatestReportDocument(
        this.reportDocuments,
        payload.variantCode
      )
      if (!reportDocument) {
        console.error('Failed to find Report to download.', payload)
        return
      }
      this.$emit('download', reportDocument)
    }
  }
})
</script>
