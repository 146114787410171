import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getReportSectionBySection = /* GraphQL */ `
  query ReportSectionBySection(
    $reportId: ID!
    $sectionId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReportSectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reportSectionsBySectionId(
      reportId: $reportId
      sectionId: $sectionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        reportId
        sectionId
        variantCode
        summary
        introduction
        subSectionIntroductions {
          slug
          value
          reviewedAt
        }
        results
        createdAt
        updatedAt
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.ReportSectionBySectionQueryVariables,
  APITypes.ReportSectionBySectionQuery
>
