<template>
  <span class="d-inline-block" :class="{ reverse }" style="line-height: 0; vertical-align: middle;">
    <component
      v-if="icon.component"
      :is="icon.component"
      :style="{
        width: `${size}px`,
        height: `${size}px`,
      }"
      :class="`${color}--text`"
      class="d-inline-block"
    />
    <img
      v-else-if="icon.src"
      :src="icon.src"
      :style="imgStyle"
      alt="Icon"
      class="d-block"
    >
    <template
      v-else
    >
      <v-icon
        v-if="$listeners.click"
        class="platform-icon"
        :color="color"
        :size="size"
        @click.stop="(evt) => $emit('click', evt)"
      >
        {{ icon }}
      </v-icon>
      <v-icon
        v-else
        class="platform-icon"
        :color="color"
        :size="size"
      >
        {{ icon }}
      </v-icon>
    </template>
  </span>
</template>

<script lang="ts">
import Vue from 'vue'
import SampleQuestionnaire from '@/components/shared/icons/SampleQuestionnaire.vue'
import SampleReport from '@/components/shared/icons/SampleReport.vue'
import CreateQuestionnaire from '@/components/shared/icons/CreateQuestionnaire.vue'
import CheckResponse from '@/components/shared/icons/CheckResponse.vue'
import AnalyseResponse from '@/components/shared/icons/AnalyseResponse.vue'
import SendReports from '@/components/shared/icons/SendReports.vue'
import AgreeScaleGraph from '@/components/shared/icons/ScaleGraph.vue'
import VarianceGraph from '@/components/shared/icons/VarianceGraph.vue'
import ScatterGraph from '@/components/shared/icons/ScatterGraph.vue'
import LineGraph from '@/components/shared/icons/LineGraph.vue'
import AppraisalGraph from '@/components/shared/icons/AppraisalGraph.vue'
import NoProgressPencilIcon from '@/components/shared/icons/NoProgressPencil.vue'
import StateModifiedAsteriskIcon from '@/components/shared/icons/StateModifiedAsterisk.vue'

export enum IconNames {
  Company = 'company',
  Committee = 'committee',
  Questionnaire = 'questionnaire',
  Team = 'team',
  Delete = 'delete',
  Duplicate = 'duplicate',
  Plus = 'plus',
  Create = 'create',
  Edit = 'edit',
  StateModified = 'state-modified',
  Save = 'save',
  Check = 'check',
  CheckCircle = 'check-circle',
  CheckOutline = 'check-outline',
  AlertOutline = 'alert-outline',
  AlertCircleOutline = 'alert-circle-outline',
  CaretLeft = 'caret-left',
  CaretRight = 'caret-right',
  CaretUp = 'caret-up',
  CaretDown = 'caret-down',
  ArrowUp = 'arrow-up',
  ArrowRight = 'arrow-right',
  ArrowDown = 'arrow-down',
  ArrowLeft = 'arrow-left',
  Eye = 'eye',
  EyeClosed = 'eye-closed',
  Return = 'return',
  Exit = 'exit',
  User = 'user',
  Users = 'users',
  SampleQuestionnaire = 'sample-questionnaire',
  SampleReport = 'sample-report',
  CreateQuestionnaire = 'create-questionnaire',
  CheckResponse = 'check-response',
  AnalyseResponse = 'analyse-response',
  SendReports = 'send-reports',
  AgreeScaleGraph = 'agree-scale-graph',
  VarianceGraph = 'variance-graph',
  ScatterGraph = 'scatter-graph',
  LineGraph = 'line-graph',
  AppraisalGraph = 'appraisal-graph',
  Page = 'page',
  Phone = 'phone',
  Print = 'print',
  Text = 'text',
  Tablet = 'tablet',
  LogOut = 'logout',
  UserSettings = 'user-settings',
  UserPlus = 'user-plus',
  Download = 'download',
  Upload = 'upload',
  Lock = 'lock',
  OpenLock = 'lock-open',
  Document = 'document',
  GraphDocument = 'graph-document',
  EditReport = 'edit-report',
  DocumentEdit = 'document-edit',
  GraphScatterPlot = 'graph-scatter-plot',
  Scores = 'scores',
  Clipboard = 'clipboard',
  TextBox = 'text-box',
  TextBoxCheck = 'text-box-check',
  TextBoxPlus = 'text-box-plus',
  ShieldAccountOutline = 'shield-account-outline',
  ShieldKey = 'shield-key',
  CellphoneKey = 'cellphone-key',
  Key = 'key',
  LightbulbGroupOutline = 'lightbulb-group-outline',
  Home = 'home',
  Gauge = 'gauge',
  Close = 'close',
  Cross = 'cross',
  Refresh = 'refresh',
  Analysis = 'analysis',
  Preview = 'preview',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out',
  PreviewGlobe = 'preview-globe',
  PreviewText = 'preview-text',
  Dispatch = 'dispatch',
  GlobePlus = 'globe-plus',
  Universe = 'universe',
  Hierarchy = 'hierarchy',
  Sync = 'sync',
  Progress = 'progress',
  Link = 'link',
  Star = 'star',
  Circle = 'circle',
  HelpCircle = 'help-circle',
  Alert = 'alert',
  ProgressAlert = 'progress-alert',
  ProgressPencil = 'progress-pencil',
  NoProgressPencil = 'no-progress-pencil',
  Smile = 'smile',
  Sad = 'sad',
  CalendarPerson = 'calendar-person',
  Crowd = 'crowd',
  ResponseStatus = 'response-status',
  ResultsOverview = 'results-overview',
  FileClockOutline = 'file-clock-outline',
  Comparisons = 'comparisons',
  Cancel = 'cancel',
  Target = 'target',
  TargetUser = 'target-user',
  Message = 'message',
  MessageCheck = 'message-check',
  Send = 'send',
  SendCheck = 'send-check',
  TrustDevice = 'trust-device',
  FormatBold = 'format-bold',
  FormatItalic = 'format-italic',
  FormatUnderline = 'format-underline',
  FormatBulletList = 'format-list-bulleted',
  FormatOrderedList = 'format-list-ordered',
  FormatAlignLeft = 'format-align-left',
  FormatAlignCenter = 'format-align-center',
  FormatAlignRight = 'format-align-right',
  FormatAlignJustify = 'format-align-justify',
  FormatPageBreak = 'format-page-break',
}

export default Vue.extend({
  name: 'PlatformIcon',
  props: {
    name: { type: String, required: true },
    color: { type: String, required: false },
    size: { type: [Number, String], required: false },
    reverse: { type: Boolean, default: false }
  },
  computed: {
    icon (): string | { src: string } | { component: any } {
      switch (this.name) {
        case IconNames.Delete:
          return 'mdi-delete'
        case IconNames.Duplicate:
          return 'mdi-content-duplicate'
        case IconNames.Create:
        case IconNames.Plus:
          return 'mdi-plus'
        case IconNames.Edit:
          return 'mdi-pencil'
        case IconNames.Save:
          return 'mdi-content-save'
        case IconNames.Check:
          return 'mdi-check'
        case IconNames.CheckCircle:
          return 'mdi-check-circle'
        case IconNames.CheckOutline:
          return 'mdi-check-circle-outline'
        case IconNames.AlertOutline:
          return 'mdi-alert-outline'
        case IconNames.AlertCircleOutline:
          return 'mdi-alert-circle-outline'
        case IconNames.CaretLeft:
          return 'mdi-chevron-left'
        case IconNames.CaretRight:
          return 'mdi-chevron-right'
        case IconNames.CaretUp:
          return 'mdi-chevron-up'
        case IconNames.CaretDown:
          return 'mdi-chevron-down'
        case IconNames.ArrowUp:
          return 'mdi-arrow-up'
        case IconNames.ArrowRight:
          return 'mdi-arrow-right'
        case IconNames.ArrowDown:
          return 'mdi-arrow-down'
        case IconNames.ArrowLeft:
          return 'mdi-arrow-left'
        case IconNames.Return:
          return 'mdi-keyboard-return'
        case IconNames.Eye:
          return 'mdi-eye'
        case IconNames.EyeClosed:
          return 'mdi-eye-closed'
        case IconNames.Exit:
          return 'mdi-exit-to-app'
        case IconNames.User:
          return 'mdi-account'
        case IconNames.Users:
        case IconNames.Team:
          return 'mdi-account-group'
        case IconNames.Company:
          return 'mdi-domain'
        case IconNames.Committee:
          return 'mdi-human-capacity-decrease'
        case IconNames.SampleQuestionnaire:
          return { component: SampleQuestionnaire }
        case IconNames.SampleReport:
          return { component: SampleReport }
        case IconNames.CreateQuestionnaire:
          return { component: CreateQuestionnaire }
        case IconNames.CheckResponse:
          return { component: CheckResponse }
        case IconNames.AnalyseResponse:
          return { component: AnalyseResponse }
        case IconNames.SendReports:
          return { component: SendReports }
        case IconNames.AgreeScaleGraph:
          return { component: AgreeScaleGraph }
        case IconNames.VarianceGraph:
          return { component: VarianceGraph }
        case IconNames.ScatterGraph:
          return { component: ScatterGraph }
        case IconNames.LineGraph:
          return { component: LineGraph }
        case IconNames.AppraisalGraph:
          return { component: AppraisalGraph }
        case IconNames.Page:
          return 'mdi-page-next-outline'
        case IconNames.Phone:
          return 'mdi-cellphone'
        case IconNames.Print:
          return 'mdi-printer'
        case IconNames.Tablet:
          return 'mdi-tablet-android'
        case IconNames.Text:
          return 'mdi-text'
        case IconNames.LogOut:
          return 'mdi-logout'
        case IconNames.UserSettings:
          return 'mdi-account-cog'
        case IconNames.UserPlus:
          return 'mdi-account-plus'
        case IconNames.Download:
          return 'mdi-cloud-download-outline'
        case IconNames.Upload:
          return 'mdi-cloud-upload-outline'
        case IconNames.Lock:
          return 'mdi-lock'
        case IconNames.OpenLock:
          return 'mdi-lock-open'
        case IconNames.Questionnaire:
        case IconNames.Document:
          return 'mdi-file-document-outline'
        case IconNames.GraphDocument:
          return 'mdi-file-chart-outline'
        case IconNames.EditReport:
        case IconNames.DocumentEdit:
          return 'mdi-file-document-edit-outline'
        case IconNames.Clipboard:
          return 'mdi-clipboard-text'
        case IconNames.Scores:
        case IconNames.GraphScatterPlot:
          return 'mdi-chart-scatter-plot'
        case IconNames.TextBox:
          return 'mdi-text-box-outline'
        case IconNames.TextBoxCheck:
          return 'mdi-text-box-check-outline'
        case IconNames.TextBoxPlus:
          return 'mdi-text-box-plus-outline'
        case IconNames.ShieldAccountOutline:
          return 'mdi-shield-account-variant-outline'
        case IconNames.ShieldKey:
          return 'mdi-shield-key-outline'
        case IconNames.CellphoneKey:
          return 'mdi-cellphone-key'
        case IconNames.Key:
          return 'mdi-key'
        case IconNames.LightbulbGroupOutline:
          return 'mdi-lightbulb-group-outline'
        case IconNames.Home:
          return 'mdi-home'
        case IconNames.Gauge:
          return 'mdi-speedometer'
        case IconNames.Close:
        case IconNames.Cross:
          return 'mdi-close'
        case IconNames.Refresh:
          return 'mdi-refresh'
        case IconNames.Analysis:
          return 'mdi-finance'
        case IconNames.Preview:
          return 'mdi-magnify'
        case IconNames.ZoomIn:
          return 'mdi-magnify-plus'
        case IconNames.ZoomOut:
          return 'mdi-magnify-minus'
        case IconNames.PreviewGlobe:
          return 'mdi-search-web'
        case IconNames.PreviewText:
          return 'mdi-text-search-variant'
        case IconNames.Dispatch:
          return 'mdi-email-send-outline'
        case IconNames.GlobePlus:
          return 'mdi-earth-plus'
        case IconNames.Universe:
          return 'mdi-atom-variant'
        case IconNames.Hierarchy:
          return 'mdi-sitemap-outline'
        case IconNames.Sync:
          return 'mdi-sync'
        case IconNames.Progress:
          return 'mdi-progress-check'
        case IconNames.Link:
          return 'mdi-link'
        case IconNames.Star:
          return 'mdi-star'
        case IconNames.Circle:
          return 'mdi-circle'
        case IconNames.HelpCircle:
          return 'mdi-help-circle-outline'
        case IconNames.Alert:
          return 'mdi-alert'
        case IconNames.ProgressAlert:
          return 'mdi-progress-alert'
        case IconNames.ProgressPencil:
          return 'mdi-progress-pencil'
        case IconNames.NoProgressPencil:
          return { component: NoProgressPencilIcon }
        case IconNames.StateModified:
          return { component: StateModifiedAsteriskIcon }
        case IconNames.Smile:
          return 'mdi-emoticon-excited-outline'
        case IconNames.Sad:
          return 'mdi-emoticon-sad-outline'
        case IconNames.CalendarPerson:
          return 'mdi-calendar-account-outline'
        case IconNames.Crowd:
          return 'mdi-crowd'
        case IconNames.ResponseStatus:
          return 'mdi-chart-gantt'
        case IconNames.ResultsOverview:
          return 'mdi-table-search'
        case IconNames.Comparisons:
        case IconNames.FileClockOutline:
          return 'mdi-file-clock-outline'
        case IconNames.Cancel:
          return 'mdi-cancel'
        case IconNames.Target:
          return 'mdi-target'
        case IconNames.TargetUser:
          return 'mdi-target-account'
        case IconNames.Message:
          return 'mdi-message-outline'
        case IconNames.MessageCheck:
          return 'mdi-message-check-outline'
        case IconNames.Send:
          return 'mdi-send'
        case IconNames.SendCheck:
          return 'mdi-send-check'
        case IconNames.TrustDevice:
          return 'mdi-monitor-cellphone-star'
        case IconNames.FormatBold:
          return 'mdi-format-bold'
        case IconNames.FormatItalic:
          return 'mdi-format-italic'
        case IconNames.FormatUnderline:
          return 'mdi-format-underline'
        case IconNames.FormatBulletList:
          return 'mdi-format-list-bulleted'
        case IconNames.FormatOrderedList:
          return 'mdi-format-list-numbered'
        case IconNames.FormatAlignLeft:
          return 'mdi-format-align-left'
        case IconNames.FormatAlignCenter:
          return 'mdi-format-align-center'
        case IconNames.FormatAlignRight:
          return 'mdi-format-align-right'
        case IconNames.FormatAlignJustify:
          return 'mdi-format-align-justify'
        case IconNames.FormatPageBreak:
          return 'mdi-format-page-break'
        default:
          throw new Error(`Unrecognized icon key "${this.name}" passed to PlatformIcon.`)
      }
    },
    imgStyle () {
      const size = this.size && Number.isInteger(this.size) ? `${this.size}px` : this.size
      return {
        width: size,
        height: size
      }
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.reverse {
  transform: scaleX(-1);
}
.platform-icon {
  transition: color 250ms ease-in-out;
}
</style>
