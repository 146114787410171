<template>
  <v-row
    no-gutters
    @keydown.enter="signInWithMFA"
  >
    <v-col class="d-flex flex-column">
      <h3 class="login-header pt-4 pb-8">{{ title }}</h3>
      <span
        class="red--text"
        v-if="errorMessage"
      >
        {{ errorMessage }}
      </span>
      <v-form-base
        class="pt-6"
        :schema="formSchema"
        @input="handleInput"
      />

      <v-row no-gutters class="justify-space-between align-center">
        <span class="login-links">
          <a
            @click="$emit('signIn')"
          >
            {{ $t('form.auth.backToSignUp') }}
          </a>
        </span>
        <platform-button
          class="px-12 py-4"
          primary
          :loading="pendingMFAConfirm"
          @click="signInWithMFA"
        >
          {{ $t('modal.shared.confirm') }}
        </platform-button>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import Logo from '../../assets/logo.png'
import { confirmSignIn } from 'aws-amplify/auth'
import { PlatformMFAType } from '@betterboards/shared/helpers/auth'
import getAuthErrorMessage from '@/helpers/auth/getAuthErrorMessage'

export default Vue.extend({
  name: 'MultiFactorAuthForm',
  components: {
    VFormBase
  },
  props: {
    mfaType: { type: String as PropType<PlatformMFAType>, required: true }
  },
  data () {
    return {
      logo: Logo,
      mfaCode: <string | undefined>undefined,
      pendingMFAConfirm: <boolean>false,
      errorMessage: <string | undefined>undefined
    }
  },
  computed: {
    formSchema () {
      const schema: any = {}

      schema.mfaCode = {
        type: 'BBTextField',
        label: this.$t('form.auth.MFA.verificationCodeLabel'),
        placeholder: this.$t('form.auth.MFA.verificationCodePlaceholder'),
        persistentPlaceholder: true,
        outlined: true,
        col: 12
      }

      return schema
    },
    title (): string {
      return this.mfaType === PlatformMFAType.TOTP
        ? this.$t('form.auth.MFA.totpTitle') as string
        : this.$t('form.auth.MFA.smsTitle') as string
    }
  },
  methods: {
    handleInput (val: any) {
      this.mfaCode = val.data.mfaCode
    },
    async signInWithMFA () {
      if (this.pendingMFAConfirm) {
        return
      }

      if (!this.mfaCode) {
        console.error('Missing MFA code')
        return
      }

      this.errorMessage = undefined
      this.pendingMFAConfirm = true
      try {
        await confirmSignIn({
          challengeResponse: this.mfaCode
        })
      } catch (error) {
        this.pendingMFAConfirm = false
        this.errorMessage = getAuthErrorMessage(error.message)
        throw new Error(`Error Signing in with MFA: ${error}`)
      }

      this.pendingMFAConfirm = false
    }
  }
})
</script>
