<template>
  <v-row class="flex-column" no-gutters>
    <file-upload
      :title="$t('form.company.companyLogo')"
      icon="company"
      object-type="companyLogo"
      :object-id="company ? company.id : undefined"
      :company-id="company ? company.id : undefined"
      :loading="loading"
      @pending="$emit('pendingFile')"
      @uploaded="$emit('uploadedFile')"
    />
    <v-form ref="form" v-model="formValid" @submit.prevent="">
      <v-form-base
        :model="company"
        :schema="formSchema"
        @input="(ev) => $emit('input', ev)"
      />
      <v-row class="mt-0">
        <v-col :cols="12" :sm="6" class="pt-0" v-tooltip="$t('form.companies.ownership')">
          <company-ownership-form
            ref="ownershipForm"
            v-model="ownershipStructure"
            menu-class="company-form__ownership-structure-dropdown"
            data-test-target="companyFormOwnershipStructureField"
            :disabled="loading"
            :listed-options="ownershipOptions"
            :loading-options="fetchingOwnershipOptions"
          />
        </v-col>
        <v-col :cols="12" :sm="6" class="pt-0" v-tooltip="$t('form.companies.organisation')">
          <company-select-field
            simple
            clearable
            :label="$t('form.company.organisation')"
            v-model="organisation"
            :items="availableOrganisations"
          />
        </v-col>
      </v-row>
      <address-form
        ref="addressForm"
        v-model="address"
        :disabled="loading"
        :countryOptions="countryOptions"
        :loadingCountries="fetchingCountries"
        @valid="(val) => addressValid = val"
      />
    </v-form>
  </v-row>
</template>

<script lang="ts">
import AddressForm from '@/components/forms/AddressForm.vue'
import CompanySelectField from '@/components/shared/inputs/CompanySelectField.vue'
import Vue from 'vue'
import VFormBase from 'vuetify-form-base'
import {
  fetchCompanyCountryOptions,
  fetchCompanyEmployeeCountOptions,
  fetchCompanyOwnershipOptions,
  fetchCompanySectorOptions,
  fetchMarketCapOptions,
  sortByCreatedAt
} from '@/helpers/company'
import FileUpload from '@/components/FileUpload.vue'
import CompanyOwnershipForm from '@/components/forms/companies/CompanyOwnershipForm.vue'

export default Vue.extend({
  name: 'CompanyForm',
  data () {
    return {
      companySectorOptions: [] as { value: string, text: string }[],
      countryOptions: [] as { value: string, text: string }[],
      employeeCountOptions: [] as { value: string, text: string }[],
      ownershipOptions: [] as { id: string, value: string, country: string }[],
      marketCapOptions: [] as { value: string, text: string }[],
      fetchingSectors: false,
      fetchingCountries: false,
      fetchingEmployeeCount: false,
      fetchingOwnershipOptions: false,
      fetchingMarketCapOptions: false,
      formValid: false,
      addressValid: false
    }
  },
  components: {
    CompanySelectField,
    AddressForm,
    CompanyOwnershipForm,
    FileUpload,
    VFormBase
  },
  props: {
    company: { type: Object, required: true },
    loading: { type: Boolean, required: false }
  },
  watch: {
    isValid (val) {
      this.$emit('valid', val)
    }
  },
  async mounted () {
    this.fetchingSectors = true
    fetchCompanySectorOptions()
      .then((response) => {
        this.companySectorOptions = response
      })
      .finally(() => {
        this.fetchingSectors = false
      })
    this.fetchingCountries = true
    fetchCompanyCountryOptions()
      .then((response) => {
        this.countryOptions = response
      })
      .finally(() => {
        this.fetchingCountries = false
      })
    this.fetchingEmployeeCount = true
    fetchCompanyEmployeeCountOptions()
      .then((response) => {
        this.employeeCountOptions = response
      })
      .finally(() => {
        this.fetchingEmployeeCount = false
      })
    this.fetchingOwnershipOptions = true
    fetchCompanyOwnershipOptions()
      .then((response) => {
        this.ownershipOptions = response
      })
      .finally(() => {
        this.fetchingOwnershipOptions = false
      })
    fetchMarketCapOptions()
      .then((response) => {
        this.marketCapOptions = response.map(item => {
          if (item.value === 'NotApplicable') {
            return {
              text: this.$t('form.company.notApplicable') as string,
              value: item.value
            }
          }
          return item
        })
      })
      .finally(() => {
        this.fetchingMarketCapOptions = false
      })
  },
  computed: {
    availableOrganisations (): any[] {
      return [...this.$store.state.Company.list]
        .filter((c) => !c.deletedAt && c.id !== this.company?.id)
        .sort(sortByCreatedAt)
    },
    formSchema () {
      const schema: any = {}

      const sharedProps = {
        disabled: this.loading
      }

      schema.name = { type: 'BBTextField', label: this.$t('form.company.companyName') as string, col: 6, required: true, 'data-test-target': 'companyFormNameField', ...sharedProps }
      schema.registrationNumber = { type: 'BBTextField', label: this.$t('form.company.companyRegistrationNumber') as string, col: 6, ...sharedProps }
      schema.phoneNumber = { type: 'BBTextField', label: this.$t('form.company.phoneNumber') as string, col: { xs: 12, sm: 6, md: 3 }, ...sharedProps }
      schema.employeeCount = {
        type: 'BBSelectField',
        label: this.$t('form.company.employeeCount') as string,
        required: true,
        items: this.employeeCountOptions,
        col: { xs: 12, sm: 6, md: 3 },
        loading: this.fetchingEmployeeCount,
        disabled: this.fetchingEmployeeCount || this.loading,
        'data-test-target': 'companyFormEmployeeField',
        menuProps: { contentClass: 'company-form__employee-dropdown' },
        tooltip: this.$t('form.companies.employeeCount')
      }

      schema.sector = {
        type: 'BBSelectField',
        label: this.$t('form.company.sector') as string,
        required: true,
        items: this.companySectorOptions,
        col: { xs: 12, sm: 6, md: 3 },
        loading: this.fetchingSectors,
        disabled: this.fetchingSectors || this.loading,
        'data-test-target': 'companyFormSectorField',
        menuProps: { contentClass: 'company-form__sector-dropdown' },
        tooltip: this.$t('form.companies.sector')
      }

      schema.marketCap = {
        type: 'BBSelectField',
        label: this.$t('form.company.marketCap') as string,
        required: true,
        items: this.marketCapOptions,
        col: { xs: 12, sm: 6, md: 3 },
        loading: this.fetchingMarketCapOptions,
        disabled: this.fetchingMarketCapOptions || this.loading,
        'data-test-target': 'companyFormMarketCapField',
        menuProps: { contentClass: 'company-form__market-cap-dropdown' }
      }

      return schema
    },
    ownershipStructure: {
      get (): string {
        return this.company?.ownershipStructure
      },
      set (ownershipStructure: string): void {
        this.$emit('input', {
          data: {
            ...this.company,
            ownershipStructure
          }
        })
      }
    },
    organisation: {
      get (): string {
        return this.company?.parentId
      },
      set (parentId: string): void {
        this.$emit('input', {
          data: {
            ...this.company,
            parentId
          }
        })
      }
    },
    address: {
      get (): any {
        return this.company?.address
      },
      set (address: any): void {
        this.$emit('input', {
          data: {
            ...this.company,
            address
          }
        })
      }
    },
    isValid (): boolean {
      return this.formValid && this.addressValid
    }
  },
  methods: {
    validate () {
      const form: any = this.$refs.form
      const ownershipForm: any = this.$refs.ownershipForm
      const addressForm: any = this.$refs.addressForm
      form.validate()
      ownershipForm.validate()
      addressForm.validate()
    }
  }
})
</script>
