<template>
  <div class="page__header">
    <span class="header__title primary--text serif font-weight-light">
      {{ title }}
    </span>
    <span v-if="subTitle" class="header__sub-title primary--text serif font-weight-light">
      {{ subTitle }}
    </span>
    <hr class="header__divider" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ReportPreviewPageHeader',
  props: {
    title: { type: String, required: true },
    subTitle: { type: String, required: false }
  }
})
</script>

<style lang="scss" scoped="scoped">
.header__title {
  display: block;
  font-size: 2.05em;
  line-height: 1.1;
}
.header__sub-title {
  display: block;
  font-size: 1.22em;
  line-height: 1.05;
}
.header__divider {
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--v-secondary-base);
  margin-top: 2px;
  margin-bottom: 18px;
}
</style>
