<template>
  <div>
    <v-row no-gutters>
      <span class="grey--text text-darken-1 text-body-2 text-center pb-2">
        {{ $t('form.committees.instruction') }}
      </span>
      <v-form ref="form" v-model="formValid" @submit.prevent="" class="flex-grow-1">
        <v-row>
          <v-col :cols="12" :sm="6">
            <platform-select-field
              v-model="committeeType"
              clearable
              :label="$t('form.committees.committeeType')"
              :items="committeeTypeOptions"
            />
          </v-col>
          <v-col :cols="12" :sm="6">
            <v-form-base
              :model="committee"
              :schema="formSchema"
              @input="handleInput"
            >
              <template #slot-top-key-form-base-slot-tags="{ obj, id, index }">
                <pre>{{ { obj, id, index } }}</pre>
              </template>
            </v-form-base>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
    <v-row no-gutters>
      <p>{{ $t('form.committees.individualsTitle') }}</p>
    </v-row>
    <individuals-selector
      v-model="selectedIndividualIds"
      :company-individuals="companyIndividuals"
      :loading="fetchingIndividuals"
      :saving="saving"
      class="mt-3"
    />
  </div>
</template>

<script lang="ts">
import { Company } from '@/API'
import IndividualsSelector from '@/components/individuals/IndividualsSelector.vue'
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import { getPreferredName } from '@betterboards/shared/helpers/entities/Individual/getPreferredName'
import { SelectItem } from '@/helpers/forms'
import { CommitteeTag } from '@betterboards/shared/types/API'
import PlatformSelectField from '@/components/shared/inputs/PlatformSelectField.vue'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { PlatformCommittee, PlatformCommitteeIndividual } from '@betterboards/shared/types/Committee'

export default Vue.extend({
  name: 'CommitteeForm',
  components: {
    PlatformSelectField,
    IndividualsSelector,
    VFormBase
  },
  props: {
    value: { type: Object as PropType<PlatformCommittee>, required: true },
    saving: { type: Boolean, required: false },
    showTypeOption: { type: Boolean, default: false }
  },
  data: () => ({
    fetchingIndividuals: false,
    companyIndividuals: <PlatformIndividual[]>[],
    selectedIndividualIds: <string[]>[],
    formValid: false
  }),
  watch: {
    isValid (val) {
      this.$emit('valid', val)
    },
    selectedIndividualIds (selectedIndividualIds): void {
      if (!this.committee.individuals) {
        this.committee.individuals = { items: [] }
      }
      this.committee.individuals.items = this.companyIndividuals
        .filter(
          (i) => selectedIndividualIds.includes(i.id)
        )
        .map((individual: PlatformIndividual): PlatformCommitteeIndividual => {
          return {
            committeeId: this.committee.id,
            companyId: this.committee.companyId,
            individualId: individual.id,
            createdAt: individual.createdAt, // Not technically correct and a bit hacky but works for now
            individual
          }
        })
    }
  },
  mounted (): void {
    this.init()
  },
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    committee: {
      get (): PlatformCommittee {
        return this.value
      },
      set (committee: PlatformCommittee) {
        this.$emit('input', committee)
      }
    },
    formSchema (): any {
      return {
        name: {
          type: 'BBTextField',
          label: this.$t('form.committees.committeeName'),
          col: { xs: 12 },
          required: true,
          disabled: this.saving
        }
      }
    },
    isValid (): boolean {
      return this.formValid
    },
    individualsList (): PlatformIndividual[] {
      return this.$store.getters['Individual/individualsList']
    },
    committeeTypeOptions (): SelectItem[] {
      const options: SelectItem[] = [
        ...Object.values(CommitteeTag).map((tag: CommitteeTag): SelectItem => ({
          text: this.$t(`global.committeeTypeNames.${tag as string}`) as string,
          value: tag
        }))
      ]
      return options
    },
    committeeType: {
      get (): CommitteeTag | undefined {
        return this.committee.tags?.[0]
      },
      set (committeeType: CommitteeTag): void {
        const oldCommitteeType: CommitteeTag | undefined = this.committeeType
        const oldCommitteeName = this.$t(`global.committeeTypeNames.${oldCommitteeType as string}`)
        if (!this.committee.tags) {
          this.committee.tags = []
        }
        this.committee.tags.splice(0, this.committee.tags.length)
        if (committeeType && !this.committee.tags.includes(committeeType)) {
          this.committee.tags.push(committeeType)
        }
        const translationKey: string = `global.committeeTypeNames.${committeeType as string}`
        if (!this.$te(translationKey)) {
          return
        }
        if (this.committee.name && this.committee.name !== oldCommitteeName) {
          return
        }
        this.committee.name = this.$t(translationKey) as string
      }
    }
  },
  methods: {
    getPreferredName,
    validate () {
      const form: any = this.$refs.form
      form.validate()
    },
    handleInput (input): void {
      this.committee = input.data
    },
    init (): void {
      const selectedCompany = this.selectedCompany
      if (!selectedCompany) {
        return
      }
      this.companyIndividuals = this.$store.getters['Individual/individualsList']
        .map((companyIndividual) => ({
          ...companyIndividual,
          individualId: companyIndividual.individualId ?? companyIndividual.id,
          companyId: companyIndividual.companyId ?? selectedCompany.id
        }))
      if (this.committee.individuals?.items) {
        this.committee.individuals.items.forEach((individual) => {
          if (this.selectedIndividualIds.includes(individual.individualId)) {
            return
          }
          this.selectedIndividualIds.push(individual.individualId)
        })
      }
    }
  }
})
</script>
