<template>
  <component
    :is="simple ? 'VAutocomplete' : 'VOverflowBtn'"
    v-model="inputValue"
    class="cursor-pointer"
    label="Add to a Company"
    item-text="name"
    item-value="id"
    ref="input"
    :rules="validationRules"
    :items="items"
    :loading="loading"
    :disabled="disabled"
    :clearable="clearable"
    @focus="focused = true"
    @blur="focused = false"
    @input="unfocus"
  >
    <input-label slot="label" :label="label" :required="required" />
    <template #item="{ item: company }">
      <v-row no-gutters align="center">
        <v-col class="shrink pr-2">
          <platform-avatar
            entity-type="company"
            :entity="company"
            :size="40"
            placeholder-size="xs"
          />
        </v-col>
        <v-col class="grow">
          <span>{{ company.name }}</span>
        </v-col>
      </v-row>
    </template>
    <template #selection="{ item: company }">
      <v-row
        v-show="!focused"
        v-tooltip:bottom="company.name"
        no-gutters
        class="flex-nowrap overflow-hidden"
      >
        <platform-avatar
          border="primary"
          entity-type="company"
          placeholder-size="xs"
          :entity="company"
          :size="simple ? 22 : 30"
          :pad-bottom="false"
          :class="{
            'ml-2 mr-1': simple,
            'ml-4 mr-3': !simple
          }"
        />
        <span class="text-no-wrap overflow-ellipses">
          {{ company.name }}
        </span>
      </v-row>
    </template>
  </component>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { VAutocomplete, VOverflowBtn } from 'vuetify/lib'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { Validators } from '@/helpers/validation'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'

export default Vue.extend({
  name: 'CompanySelectField',
  components: {
    VAutocomplete,
    VOverflowBtn,
    PlatformAvatar,
    InputLabel
  },
  data: () => ({
    focused: false
  }),
  props: {
    simple: { type: Boolean, default: false },
    value: { type: String, required: false },
    items: { type: Array as PropType<Array<{ name: string, id: string }>>, required: true },
    label: { type: String, required: false },
    loading: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    clearable: { type: Boolean, required: false },
    rules: { type: Array, required: false },
    required: { type: Boolean, default: false }
  },
  computed: {
    inputValue: {
      set (val: string): void {
        this.$emit('input', val)
      },
      get (): string {
        return this.value
      }
    },
    validationRules (): any[] {
      const rules: any[] = []
      if (this.required) {
        rules.push(Validators.required)
      }
      if (Array.isArray(this.rules)) {
        rules.push(...this.rules)
      }
      return rules
    }
  },
  methods: {
    unfocus (): void {
      this.focused = false
      const input: any = this.$refs.input
      input.blur()
    }
  }
})
</script>
