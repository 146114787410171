var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-list',[(_vm.questionnaire)?_c('h2',{staticClass:"serif text-center mb-4 overflow-ellipses",class:{
      'text-body-2': !_vm.expanded
    }},[_vm._v(" "+_vm._s(_vm.questionnaireName)+" ")]):_vm._e(),_vm._l((_vm.displaySections),function(section,index){return _c('v-list-item',{key:`${section ? section.id : ''}-${index}`,ref:"sectionLink",refInFor:true,staticClass:"py-2",class:{
      'v-list-item--active': _vm.activeSection && section.id === _vm.activeSection.id && !_vm.showSubmitStep
    },attrs:{"disabled":!_vm.sectionHasQuestions(section)},on:{"click":function($event){return _vm.setActiveSection(section)}}},[_c('platform-icon',{staticClass:"mr-2 section-completion",class:{
        completed: _vm.checkSectionCompleted(section)
      },attrs:{"name":"check-outline","color":_vm.checkSectionCompleted(section) ? 'secondary' : (_vm.activeSection && section.id === _vm.activeSection.id ? 'grey darken-3' : 'grey darken-2'),"size":30}}),_c('span',{staticClass:"section-name"},[_vm._v(" "+_vm._s(section.name)),_c('br')])],1)}),_c('v-list-item',{staticClass:"py-2",class:{
      'v-list-item--active': _vm.showSubmitStep
    },on:{"click":function($event){return _vm.setShowSubmitStep(true)}}},[_c('platform-icon',{staticClass:"mr-2 section-completion",attrs:{"name":"text-box-check","color":"white","size":30}}),_c('span',{staticClass:"section-name"},[_vm._v(" "+_vm._s(_vm.$t('page.viewSurvey.submitStep'))+" ")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }