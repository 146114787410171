<template>
  <v-card class="grow pa-5">
    <v-container :class="{
      'px-16': $vuetify.breakpoint.smAndUp,
    }">
      <v-row class="fill-height flex-column flex-nowrap" justify="space-between" v-if="results.company" :class="{
        'px-16': $vuetify.breakpoint.lgAndUp,
      }">
        <v-col
          v-for="(dataSet, index) in dataSets"
          :key="`${dataSet.property}-${index}`"
          class="d-flex flex-column pb-16"
        >
          <v-row class="pb-3">
            <v-col :cols="8" :lg="6">
              <span class="table-header large highlight--text">
                {{ $t(`analysis.diversity.${dataSet.property}Title`) }}
              </span>
            </v-col>
            <v-col :cols="4" :lg="6" class="text-center">
              <span class="table-header large highlight--text">
                {{ results.company.name }}
              </span>
            </v-col>
          </v-row>
          <v-row
            v-for="(item, index2) in dataSet.items"
            :key="`${item.category}-${index2}`"
            no-gutters
            class="py-2"
            align="center"
          >
            <v-col :cols="8" :lg="6">
              <span
                class="pl-4 d-block"
                :class="{
                  'ml-2': $vuetify.breakpoint.smAndUp
                }"
              >
                {{ $t(`analysis.diversity.item.${item.categoryValue}`) }}
              </span>
            </v-col>
            <v-col :cols="4" :lg="6" class="text-center">
                  <span class="result-bubble primary white--text d-inline-block px-2 py-2 font-weight-bold">
                    {{ item.companyPercentage }}%
                  </span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformReport } from '@/types/Report'
import { AverageProperty, getCategoryFriendlyName } from '@betterboards/shared/helpers/statistics/index'
import { ResultSet } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'Diversity',
  props: {
    results: { type: Object as PropType<ResultSet>, required: true },
    report: { type: Object as PropType<PlatformReport>, required: false }
  },
  data: () => ({
    AverageProperty
  }),
  computed: {
    dataSets (): any[] {
      const properties = Object.keys(this.results.averages) as AverageProperty[]
      return properties.map((property: AverageProperty) => {
        const propertyData = this.results.averages[property]
        const maxDatapoints = propertyData.collectionCount
        const items = Object.keys(propertyData.propertyMap)
          .map((categoryValue) => {
            const category = !categoryValue || categoryValue === 'undefined' ? 'Not Disclosed' : getCategoryFriendlyName(property, categoryValue)
            const companyPercentage = Math.round(propertyData.propertyMap[categoryValue] / maxDatapoints * 100)
            return {
              category,
              categoryValue,
              companyPercentage
            }
          })
          .sort((a, b) => {
            if (b.companyPercentage !== a.companyPercentage) {
              return b.companyPercentage - a.companyPercentage
            }
            if (!a.category) {
              return -1
            }
            if (!b.category) {
              return 1
            }
            return a.category.localeCompare(b.category)
          })
        return {
          property,
          items
        }
      })
    }
  }
})
</script>
