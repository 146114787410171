<template>
  <platform-modal
    v-model="showModal"
    icon="document"
    close-button
    :width="500"
    :title="titleText"
    :cancel="false"
  >
    <v-row
      align="center"
      justify="space-between"
      class="generating-container flex-column"
    >
      <v-col class="text-center text-body-2 grey--text text--darken-1">
        <span>
          {{ instructionText }}
        </span>
      </v-col>
      <v-col class="grow d-flex flex-column align-center justify-end">
        <div class="progress__container">
          <v-progress-circular
            class="d-block mx-auto"
            color="secondary"
            :rotate="-90"
            :value="reportProgressDisplay"
            :width="8"
            :size="140"
          />
          <v-fade-transition>
            <div
              v-show="completed"
              class="progress-value progress-100"
            >
              <platform-icon
                name="check"
                color="primary"
                :size="100"
              />
            </div>
          </v-fade-transition>
          <span class="progress-value" :class="reportProgressValueClass">
            <v-fade-transition>
              <strong
                v-show="!completed"
              >
                {{ reportProgressDisplay }}%
              </strong>
            </v-fade-transition>
          </span>
        </div>
        <div
          v-if="statusText"
          class="status-text text-body-2 font-weight-bold pt-2"
          :class="{
            'grey--text text--darken-2': !completed,
            'primary--text': completed,
          }"
        >
          {{ statusText }}
        </div>
      </v-col>
      <v-col class="shrink text-center">
        <div
          class="download-tip grey--text text-caption cursor-pointer"
          :class="{ hidden: !completed }"
          @click="handleTriggerDownload"
        >
          {{ $t('analysis.generateReport.reportDocuments.progressModal.downloadTipMessage') }}
          <span class="text-decoration-underline">
            {{ $t('analysis.generateReport.reportDocuments.progressModal.downloadTipAction') }}
          </span>
        </div>
      </v-col>
    </v-row>
  </platform-modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import PlatformModal from '@/components/PlatformModal.vue'

export default Vue.extend({
  name: 'ReportDocumentProgressModal',
  components: {
    PlatformModal
  },
  props: {
    value: { type: Boolean, default: true },
    completed: { type: Boolean, default: false },
    durationEstimate: { type: Number as PropType<number | undefined>, required: false },
    multiple: { type: Boolean, default: false },
    action: { type: String as PropType<'generate' | 'download'>, default: 'generate' }
  },
  data () {
    return {
      showModal: true,
      reportProgress: 0
    }
  },
  mounted (): void {
    this.reportProgress = 0
    this.init()
  },
  watch: {
    'showModal' (val: boolean): void {
      if (!val) {
        this.$emit('input', false)
        this.$emit('close')
      }
    }
  },
  computed: {
    reportProgressDisplay (): number {
      if (this.completed) {
        return 100
      }
      return this.reportProgress
    },
    reportProgressValueClass (): string[] {
      const progressPoint: number = Math.ceil(this.reportProgress / 10) * 10
      const progressClass: string = progressPoint > 100
        ? 'progress-100'
        : `progress-${progressPoint}`
      return [progressClass]
    },
    slowGeneration (): boolean {
      return this.reportProgress >= 100 && !this.completed
    },
    translationQuantity (): number {
      // Just used to return the plural translation if the multiple prop is enabled.
      return this.multiple ? 2 : 1
    },
    titleText (): string {
      if (this.action === 'download') {
        return this.$tc('analysis.generateReport.reportDocuments.progressModal.downloadingTitle', this.translationQuantity) as string
      }
      return this.$tc('analysis.generateReport.reportDocuments.progressModal.generatingTitle', this.translationQuantity) as string
    },
    instructionText (): string {
      if (this.action === 'download') {
        if (this.completed) {
          return this.$tc('analysis.generateReport.reportDocuments.progressModal.downloadReadyInstruction', this.translationQuantity) as string
        }
        return this.$tc('analysis.generateReport.reportDocuments.progressModal.preparingDownloadInstruction', this.translationQuantity) as string
      }
      if (this.completed) {
        return this.$tc('analysis.generateReport.reportDocuments.progressModal.generatedInstruction', this.translationQuantity) as string
      }
      return this.$tc('analysis.generateReport.reportDocuments.progressModal.generatingInstruction', this.translationQuantity) as string
    },
    statusText (): string | undefined {
      if (this.action === 'download') {
        if (this.completed) {
          return this.$tc('analysis.generateReport.reportDocuments.progressModal.downloadReadyStatusMessage', this.translationQuantity) as string
        }
        return this.$tc('analysis.generateReport.reportDocuments.progressModal.preparingDownloadStatusMessage', this.translationQuantity) as string
      }
      if (this.completed) {
        return this.$tc('analysis.generateReport.reportDocuments.progressModal.completedStatusMessage', this.translationQuantity) as string
      }
      if (this.slowGeneration) {
        return this.$tc('analysis.generateReport.reportDocuments.progressModal.slowGenerationMessage', this.translationQuantity) as string
      }
      return this.$tc('analysis.generateReport.reportDocuments.progressModal.generatingStatusMessage', this.translationQuantity) as string
    }
  },
  methods: {
    init (): void {
      this.increment()
    },
    increment (): void {
      const durationEstimateSeconds: number = this.durationEstimate ?? 60
      const incrementIntervalMs: number = durationEstimateSeconds / 100 * 1000
      setTimeout(() => {
        this.reportProgress += 1
        if (this.reportProgress >= 100 || this.completed) {
          this.reportProgress = 100
          return
        }
        this.increment()
      }, incrementIntervalMs)
    },
    handleTriggerDownload (): void {
      this.$emit('download')
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
@import '~vuetify/src/styles/generic/_colors.scss';

.generating-container {
  min-height: 300px;
  $transition-time: 300ms;

  .download-tip {
    transition: opacity ease-in-out $transition-time;
    opacity: 1;
    &.hidden {
      opacity: 0;
    }
  }
  .progress__container {
    position: relative;

    .status-text {
      transition: color ease-in-out $transition-time;
    }
    .progress-value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 26px;
      transition: opacity linear $transition-time, color linear $transition-time;
      color: var(--v-grey-darken2);
      opacity: 0.5;
      &.progress-10 {
        opacity: 0.55;
        color: color-mix(in srgb, var(--v-primary-base) 10%, var(--v-grey-darken2) 90%);
      }
      &.progress-20 {
        opacity: 0.6;
        color: color-mix(in srgb, var(--v-primary-base) 20%, var(--v-grey-darken2) 80%);
      }
      &.progress-30 {
        opacity: 0.65;
        color: color-mix(in srgb, var(--v-primary-base) 30%, var(--v-grey-darken2) 70%);
      }
      &.progress-40 {
        opacity: 0.7;
        color: color-mix(in srgb, var(--v-primary-base) 40%, var(--v-grey-darken2) 60%);
      }
      &.progress-50 {
        opacity: 0.75;
        color: color-mix(in srgb, var(--v-primary-base) 50%, var(--v-grey-darken2) 50%);
      }
      &.progress-60 {
        opacity: 0.8;
        color: color-mix(in srgb, var(--v-primary-base) 60%, var(--v-grey-darken2) 40%);
      }
      &.progress-70 {
        opacity: 0.85;
        color: color-mix(in srgb, var(--v-primary-base) 70%, var(--v-grey-darken2) 30%);
      }
      &.progress-80 {
        opacity: 0.9;
        color: color-mix(in srgb, var(--v-primary-base) 80%, var(--v-grey-darken2) 20%);
      }
      &.progress-90 {
        opacity: 0.95;
        color: color-mix(in srgb, var(--v-primary-base) 90%, var(--v-grey-darken2) 10%);
      }
      &.progress-100 {
        opacity: 1;
        color: var(--v-primary-base);
      }
    }
  }
}
</style>
