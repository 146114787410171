<template>
  <v-container class="fill-height pt-8 align-start justify-start">
    <v-row
      :class="{
        'px-8': $vuetify.breakpoint.smAndUp,
        'px-0': $vuetify.breakpoint.xsOnly
      }"
    >
      <v-col>
        <v-stepper
          v-model="currentStep"
          :flat="hideBackground"
          :class="{
            transparent: hideBackground
          }"
        >
          <v-stepper-header class="flex-nowrap white">
            <template v-for="(step, index) in steps">
              <v-stepper-step
                :key="`qws-${step.step}`"
                :step="step.step"
                :complete="step.completed"
                class="overflow-ellipses"
                @click="jumpTo(step.step)"
                :class="{
                  'cursor-pointer jump-step': canJumpToStep(step.step)
                }"
              >
                {{ step.label }}
              </v-stepper-step>

              <v-divider v-if="index !== steps.length - 1" :key="`qwd-${step.step}`" />
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="step in steps"
              :key="`qwi-${step.step}`"
              :step="step.step"
              :complete="step.completed"
              class="px-0 pt-1"
              :class="{
                transparent: hideBackground
              }"
            >
              <component
                v-if="step.component && currentStep === step.step"
                :is="step.component"
                v-bind="{
                  completed: step.completed,
                  availablePackages,
                  loading: pendingFetchTemplates,
                  linkedQuestionnaire: selectedPackageQuestionnaire,
                  selectedPackage,
                  companyQuestionnaires,
                  hideCommittees
                }"
                v-model="formData[step.field]"
                ref="stepComponent"
                @next="incrementStep"
                @prev="decrementStep"
                @valid="(val) => isValid = val"
                @input="handleInput"
                @selectPackage="handlePackageSelection"
              />

              <v-row
                v-if="showStepButtons(step)"
                justify="space-between"
                no-gutters
                class="pt-6 px-6 pb-2"
              >
                <v-col class="grow">
                  <platform-button
                    v-if="!step.hidePreviousButton"
                    secondary
                    class="mr-2"
                    :disabled="!canStepBackward"
                    @click="decrementStep"
                  >
                    {{ $t('form.wizard.previous') }}
                  </platform-button>
                </v-col>
                <v-col class="shrink">
                  <platform-button
                    v-if="step.nextButton"
                    primary
                    :disabled="!canStepForward"
                    :loading="pendingCreate"
                    @click="incrementStep"
                    @disabledClick="attemptValidate"
                  >
                    {{ step.nextButton }}
                  </platform-button>
                </v-col>
              </v-row>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Company, CompanyTeamTypes, PackageItem, PackageItemTypes, Questionnaire } from '@/API'
import { SurveyConfiguration } from '@/types/Survey'
import Vue from 'vue'
import {
  PlatformCompanyPackage,
  PlatformPackage,
  PlatformPackageItem
} from '@/types/Platform'
import { listCompanyPackages } from '@/helpers/packages'
import {
  createQuestionnaire,
  DefaultQuestionnaireData
} from '@/helpers/questionnaire'
import CompanyWizard from '@/components/forms/companies/CompanyWizard.vue'
import ReviewCompany from '@/components/forms/companies/ReviewCompany.vue'
import QuestionnaireForm from '@/components/questionnaire/QuestionnaireForm.vue'
import ReviewQuestionnaire from '@/components/questionnaire/ReviewQuestionnaire.vue'
import PackageSelector from '@/components/questionnaire/packages/PackageSelector.vue'
import API from '@/services/API'
import { questionnairesByCompanyId } from '@/graphql/queries'
import { WizardConfiguration } from '@/types/Package'

enum Steps {
  Package = 1,
  Company = 2,
  ReviewCompany = 3,
  CreateQuestionnaire = 4,
  ReviewQuestionnaire = 5
}

interface QuestionnaireWizardStep {
  label: string
  step: Steps
  completed: boolean
  component?: any
  field?: string
  handleInput?: boolean
  data?: any
  nextButton?: string
  hidePreviousButton?: boolean
}

export default Vue.extend({
  name: 'QuestionnaireWizard',
  data: () => ({
    currentStep: 1,
    pendingCreate: false,
    pendingFetchTemplates: false,
    availablePackages: <PlatformPackage[]>[],
    questionnaireId: <string | undefined>undefined,
    formData: {
      questionnaire: DefaultQuestionnaireData()
    },
    isValid: false,
    companyQuestionnaires: <Questionnaire[]>[],
    selectedPackage: <PlatformPackage | undefined>undefined
  }),
  mounted (): void {
    void this.fetchAvailablePackages()
    void this.getCompanyQuestionnaires()
  },
  watch: {
    'selectedCompany.id' () {
      void this.fetchAvailablePackages()
      void this.getCompanyQuestionnaires()
    },
    'formData.questionnaire.packageId' () {
      this.selectedPackage = this.availablePackages.find((p) => p.id === this.formData.questionnaire.packageId)
    }
  },
  computed: {
    selectedCompany (): Company {
      return this.$store.state.Company.selectedCompany
    },
    steps (): QuestionnaireWizardStep[] {
      return [
        {
          label: this.$t('form.wizard.packages.packageSelectorTitle') as string,
          step: Steps.Package,
          component: PackageSelector,
          field: 'packageId',
          handleInput: true,
          completed: false,
          hidePreviousButton: true
        },
        {
          label: this.$t('form.wizard.company.title') as string,
          step: Steps.Company,
          component: CompanyWizard,
          completed: false,
          hidePreviousButton: true
        },
        {
          label: this.$t('form.wizard.reviewCompany.title') as string,
          step: Steps.ReviewCompany,
          component: ReviewCompany,
          nextButton: this.$t('form.wizard.reviewCompany.buttonAction') as string,
          completed: false
        },
        {
          label: this.$t('form.wizard.createQuestionnaire.title') as string,
          step: Steps.CreateQuestionnaire,
          component: QuestionnaireForm,
          field: 'questionnaire',
          nextButton: this.$t('form.wizard.createQuestionnaire.buttonAction') as string,
          completed: false
        },
        {
          label: this.$t('form.wizard.reviewQuestionnaire.title') as string,
          step: Steps.ReviewQuestionnaire,
          component: ReviewQuestionnaire,
          field: 'questionnaire',
          nextButton: this.$t('form.wizard.reviewQuestionnaire.buttonAction') as string,
          hidePreviousButton: true,
          completed: false
        }
      ]
    },
    currentStepIndex (): number {
      return this.steps.findIndex((s) => s.step === this.currentStep)
    },
    canStepForward (): boolean {
      if (this.currentStep === Steps.ReviewQuestionnaire) {
        return true
      }
      if (this.requiresValidation && !this.isValid) {
        return false
      }
      return this.currentStep < this.steps.length
    },
    canStepBackward (): boolean {
      return this.currentStep > Steps.Package
    },
    hideBackground (): boolean {
      return this.currentStep === Steps.Package
    },
    requiresValidation (): boolean {
      return this.currentStep === Steps.CreateQuestionnaire
    },
    selectedPackageQuestionnaire (): Questionnaire | undefined {
      return this.companyQuestionnaires.find(
        (questionnaire: Questionnaire) => questionnaire.packageId === this.formData.questionnaire.packageId
      )
    },
    surveyConfiguration (): SurveyConfiguration | undefined {
      if (!this.selectedPackage?.packageItems.items.length) {
        return undefined
      }
      return this.selectedPackage?.packageItems.items.find(
        (pi): pi is PlatformPackageItem<SurveyConfiguration> => pi.type === PackageItemTypes.SurveyConfiguration
      )?.content
    },
    packageTargetTeams (): CompanyTeamTypes[] | undefined {
      return this.surveyConfiguration?.targetTeams
    },
    packageWizardConfiguration (): WizardConfiguration | undefined {
      if (!this.selectedPackage) {
        return undefined
      }
      const packageItem: PlatformPackageItem<WizardConfiguration | string> | undefined = this.selectedPackage.packageItems.items.find(
        (pi: PackageItem) => pi?.type === PackageItemTypes.WizardConfiguration
      )
      if (!packageItem?.content) {
        return undefined
      }
      return typeof packageItem.content === 'string'
        ? JSON.parse(packageItem.content)
        : packageItem.content
    },
    hideCommittees (): boolean {
      return !!this.packageWizardConfiguration?.hideCommittees
    }
  },
  methods: {
    handleInput (value: any | undefined): any {
      if (!this.steps[this.currentStepIndex]?.handleInput) {
        return
      }

      if (this.currentStep === Steps.Package && value) {
        this.handlePackageSelection(value)

        this.incrementStep()
      }
    },
    handlePackageSelection (packageId: string): void {
      this.formData.questionnaire.packageId = packageId
      this.steps.forEach((step) => {
        step.completed = false
      })
    },
    async incrementStep (): Promise<void> {
      if (!this.canStepForward) {
        return
      }
      switch (this.currentStep) {
        case Steps.CreateQuestionnaire:
          await this.createQuestionnaire()
          break
        case Steps.ReviewQuestionnaire:
          if (this.questionnaireId) {
            void this.$router.push({
              name: 'QuestionnaireEdit',
              params: {
                id: this.questionnaireId as string
              }
            })
          }
          return
      }

      this.steps[this.currentStepIndex].completed = true
      this.currentStep += 1
    },
    decrementStep (): void {
      if (!this.canStepBackward) {
        return
      }
      switch (this.currentStep) {
        case Steps.ReviewCompany:
          break
      }
      this.currentStep -= 1
    },
    async fetchAvailablePackages (): Promise<void> {
      const selectedCompanyId = this.selectedCompany?.id
      if (!selectedCompanyId) {
        throw new Error('Failed to fetch Packages as no Company is selected.')
      }
      this.pendingFetchTemplates = true
      try {
        const companyPackages = await listCompanyPackages(selectedCompanyId)
        const packages = companyPackages.map((p: PlatformCompanyPackage) => p.package)
        this.availablePackages.splice(0, this.availablePackages.length, ...packages)
      } finally {
        this.pendingFetchTemplates = false
      }
    },
    async createQuestionnaire (): Promise<void> {
      const selectedCompanyId = this.selectedCompany?.id
      if (!selectedCompanyId) {
        throw new Error('Failed to create questionnaire as no company ID is available.')
      }
      if (!this.formData.questionnaire.name) {
        throw new Error('Failed to create questionnaire as no questionnaire name is available.')
      }
      if (!this.formData.questionnaire.packageId) {
        throw new Error('Failed to create questionnaire as no package ID is available.')
      }

      let questionnaireId: string
      this.pendingCreate = true
      try {
        questionnaireId = await createQuestionnaire({
          name: this.formData.questionnaire.name,
          packageId: this.formData.questionnaire.packageId,
          variants: this.formData.questionnaire.variants,
          companyId: this.selectedCompany.id
        })
      } catch (err) {
        this.$toasted.error(this.$t('form.wizard.createQuestionnaire.createQuestionnaireError') as string)
        console.error('Error when creating a new Questionnaire', JSON.stringify(err, null, 2))
        throw err
      } finally {
        this.pendingCreate = false
      }

      this.questionnaireId = questionnaireId
    },
    canJumpToStep (step: Steps): boolean {
      const index = this.steps.findIndex((s) => s.step === step)
      if (!this.steps[index]) {
        return false
      }
      if (step < this.currentStep) {
        return true
      }
      if (this.steps[index - 1] && this.steps[index - 1].completed) {
        return true
      }
      return this.steps[index].completed
    },
    jumpTo (step: Steps): void {
      const index = this.steps.findIndex((s) => s.step === step)
      if (!this.canJumpToStep(step)) {
        return
      }
      this.currentStep = this.steps[index].step
    },
    attemptValidate (): void {
      if (this.requiresValidation) {
        const stepComponents: any[] = this.$refs.stepComponent as any[]
        // Only a single component is rendered at a time so 0 index is always referring to current step
        stepComponents[0].validate()
      }
    },
    async getCompanyQuestionnaires (): Promise<void> {
      const companyId = this.selectedCompany?.id
      if (!companyId) {
        return
      }

      const questionnairesResponse = await API.graphql({
        query: questionnairesByCompanyId,
        variables: {
          companyId,
          filter: {
            or: [
              {
                deletedAt: { eq: null }
              },
              {
                deletedAt: { attributeExists: false }
              }
            ]
          }
        }
      })

      const questionnaires: Questionnaire[] | undefined = questionnairesResponse.data.questionnairesByCompanyId?.items as Questionnaire[] | undefined
      if (!questionnaires) {
        return
      }
      this.companyQuestionnaires.splice(
        0,
        this.companyQuestionnaires.length,
        ...questionnaires
      )
    },
    showStepButtons (step: QuestionnaireWizardStep): boolean {
      if (step.step === Steps.ReviewQuestionnaire) {
        return true
      }
      return !step.hidePreviousButton && !!step.nextButton
    }
  }
})
</script>

<style lang="scss" scoped>
.jump-step {
  &:hover {
    opacity: 0.7;
  }
}
</style>
