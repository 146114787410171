<template>
  <platform-modal
    v-model="showModal"
    icon="user"
    :width="800"
    :title="$t('modal.admin.edit.title', [companyUser.user && companyUser.user.displayName || $t('shared.entities.user')])"
    :saving="pendingSave"
    :save-disabled="!isValid"
    @save="save"
    @close="$emit('close')"
    @validate="$refs.form.validate()"
  >
    <user-form
      ref="form"
      v-if="companyUser.user"
      v-model="companyUser.user"
      editable-companies
      :manageable-companies="manageableCompanies"
      :company-id="companyUser.companyId"
      :loading-user-companies="pendingFetchCompanies"
      @updateCompanies="updateCompanies"
      @valid="(val) => isValid = val"
    />
  </platform-modal>
</template>

<script lang="ts">
import { CognitoIdentity, CompanyUser } from '@/API'
import { getFullCompanyUser } from '@/graphql/custom/getFullCompanyUser'
import API from '@/services/API'
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import { DefaultUser } from '@/types/Platform'
import PlatformModal from '@/components/PlatformModal.vue'
import UserForm from '@/components/user/UserForm.vue'

export default Vue.extend({
  name: 'EditUserModal',
  components: {
    PlatformModal,
    UserForm
  },
  props: {
    content: Object as PropType<CompanyUser>,
    manageableCompanies: Array as PropType<{ id: string, accountType: string, name: string }[]>
  },
  data () {
    return {
      showModal: true,
      companyUser: <Partial<CompanyUser>>{
        companyId: undefined,
        user: DefaultUser()
      },
      companyChanges: <any[]>[],
      pendingSave: false,
      pendingFetchCompanies: false,
      isValid: false
    }
  },
  mounted () {
    this.companyUser = this.content
    this.fetchUserCompanies()
  },
  computed: {
    ...mapState('Company', ['selectedCompany'])
  },
  watch: {
    showModal (val: boolean): void {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  methods: {
    ...mapActions('User', ['updatePlatformUser', 'fetchJoinedCompanies']),
    updateCompanies (company) {
      if (company.action === 'remove') {
        const index = this.companyChanges.findIndex((c) => c.companyId === company.companyId)
        if (index !== -1 && this.companyChanges[index].action === 'add') {
          this.companyChanges.splice(index, 1)
          return
        }
      }
      this.companyChanges.push(company)
    },
    fetchUserCompanies (): void {
      if (!this.companyUser?.cognitoIdentityId || !this.companyUser.companyId) {
        return
      }
      this.pendingFetchCompanies = true

      API.graphql({
        query: getFullCompanyUser,
        variables: {
          cognitoIdentityId: this.companyUser.cognitoIdentityId,
          companyId: this.companyUser.companyId
        }
      })
        .then((response) => {
          const user = response.data?.getCompanyUser?.user as CognitoIdentity
          if (user) {
            if (user?.companies?.items) {
              user.companies.items = user.companies.items.filter((companyUser) => !companyUser?.company?.deletedAt)
            }
            this.companyUser.user = user
          }
        })
        .catch((err) => {
          console.error('Error fetching user', err)
          this.$toasted.global.error('fetch companies')
        })
        .finally(() => {
          this.pendingFetchCompanies = false
        })
    },
    async save () {
      if (!this.companyUser.user?.id) {
        return
      }
      const payload = {
        ...this.companyUser.user,
        companies: this.companyChanges
      }
      this.pendingSave = true
      this.updatePlatformUser(payload)
        .then((response) => {
          this.showModal = false
          this.$emit('updated', response)
          this.$toasted.success('User updated successfully')
        })
        .catch((err) => {
          console.error('Caught an error while trying to update a user:', err)
          this.$toasted.error('Failed to update the user, please try again or contact support.')
        })
        .finally(() => {
          this.pendingSave = false
        })
    }
  }
})
</script>
