var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"grow pa-5"},[_c('v-container',{class:{
    'px-16': _vm.$vuetify.breakpoint.smAndUp,
  }},[(_vm.results.company)?_c('v-row',{staticClass:"fill-height flex-column flex-nowrap",class:{
      'px-16': _vm.$vuetify.breakpoint.lgAndUp,
    },attrs:{"justify":"space-between"}},_vm._l((_vm.dataSets),function(dataSet,index){return _c('v-col',{key:`${dataSet.property}-${index}`,staticClass:"d-flex flex-column pb-16"},[_c('v-row',{staticClass:"pb-3"},[_c('v-col',{attrs:{"cols":8,"lg":6}},[_c('span',{staticClass:"table-header large highlight--text"},[_vm._v(" "+_vm._s(_vm.$t(`analysis.diversity.${dataSet.property}Title`))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":4,"lg":6}},[_c('span',{staticClass:"table-header large highlight--text"},[_vm._v(" "+_vm._s(_vm.results.company.name)+" ")])])],1),_vm._l((dataSet.items),function(item,index2){return _c('v-row',{key:`${item.category}-${index2}`,staticClass:"py-2",attrs:{"no-gutters":"","align":"center"}},[_c('v-col',{attrs:{"cols":8,"lg":6}},[_c('span',{staticClass:"pl-4 d-block",class:{
                'ml-2': _vm.$vuetify.breakpoint.smAndUp
              }},[_vm._v(" "+_vm._s(_vm.$t(`analysis.diversity.item.${item.categoryValue}`))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":4,"lg":6}},[_c('span',{staticClass:"result-bubble primary white--text d-inline-block px-2 py-2 font-weight-bold"},[_vm._v(" "+_vm._s(item.companyPercentage)+"% ")])])],1)})],2)}),1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }