<template>
  <div class="d-flex flex-column">
    <v-autocomplete
      v-model="inputValue"
      ref="autocomplete"
      :class="{
        'no-input': avatars
      }"
      :outlined="block"
      :items="items"
      :rules="validationRules"
      :loading="loading"
      :disabled="disabled"
      :menu-props="menuProps"
      :label="label"
      :required="required"
      :hide-details="hideDetails"
      :multiple="multiple"
      :filter="filter"
    >
      <input-label
        slot="label"
        :label="label"
        :required="required"
        :icon="prependLabelIcon"
        :icon-size="20"
      />
      <template v-if="avatars || multiple || $slots.item || $scopedSlots.item" #item="{ on, attrs, parent, item }">
        <slot
          name="item"
          :on="on"
          :attrs="attrs"
          :parent="parent"
          :item="item"
        >
          <v-list-item v-on="on" v-bind="attrs">
            <v-row no-gutters align="center">
              <v-col v-if="!avatars" class="shrink pr-2">
                <v-simple-checkbox
                  :value="isSelected(item)"
                  color="primary"
                  class="d-flex"
                />
              </v-col>
              <v-col v-if="item.avatarEntity" class="shrink pr-2">
                <platform-avatar
                  v-if="item.avatarEntity"
                  placeholder-size="xs"
                  :entity-type="item.entityType"
                  :entity="item.avatarEntity"
                  :size="itemAvatarSize"
                  :pad-bottom="false"
                />
              </v-col>
              <v-col class="grow">
                <div class="d-flex flex-col align-center">
                  <span class="text-no-wrap overflow-ellipses pr-1">{{ item.text }}</span>
                  <span v-if="item.subText" class="text-body-2 grey--text text--darken-2">- {{ item.subText }}</span>
                </div>
              </v-col>
            </v-row>
          </v-list-item>
        </slot>
      </template>
      <template v-if="avatars || multiple || $slots.selection || $scopedSlots.selection" #selection="{ on, attrs, parent, item, index }">
        <template v-if="multiple && index === 0">
          <span class="grey--text text--lighten-1 text-body-2">
            {{ $t('global.selectedItems', [selectedItems.length]) }}
          </span>
        </template>
        <slot
          name="selection"
          class="shrink"
          :on="on"
          :attrs="attrs"
          :parent="parent"
          :item="item"
        >
          <v-row
            v-if="!chipsBelow"
            v-tooltip:bottom="item.text"
            no-gutters
            align="center"
            class="flex-nowrap overflow-hidden py-2"
          >
            <platform-avatar
              v-if="item.avatarEntity"
              border="primary"
              placeholder-size="xs"
              class="ml-4 mr-3"
              :entity-type="item.entityType"
              :entity="item.avatarEntity"
              :size="itemAvatarSize + 2"
              :pad-bottom="false"
            />
            <div class="d-flex flex-row">
              <span class="pr-1 text-no-wrap overflow-ellipses">
                {{ item.text }}
              </span>
              <span v-if="item.subText" class="text-no-wrap overflow-ellipses text-body-2 grey--text text--darken-2"> - {{ item.subText }}</span>
            </div>
          </v-row>
        </slot>
      </template>
    </v-autocomplete>
    <v-row v-if="chipsBelow" no-gutters class="selected-chips pt-1">
      <div
        v-for="(item, index) in selectedItems"
        :key="index"
        class="pt-1 px-1"
      >
        <v-chip
          small
          color="primary"
        >
          <v-row
            v-tooltip:bottom="item.text"
            no-gutters
            align="center"
            class="flex-nowrap overflow-hidden py-2"
          >
            <platform-avatar
              v-if="item.avatarEntity"
              border="primary"
              placeholder-size="xs"
              class="ml-4 mr-3"
              :entity-type="item.entityType"
              :entity="item.avatarEntity"
              :size="itemAvatarSize + 2"
              :pad-bottom="false"
            />
            <span class="text-no-wrap overflow-ellipses">
              {{ item.text }}
            </span>
          </v-row>
        </v-chip>
      </div>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformEntityTypes } from '@/types/Platform'
import { Validators } from '@/helpers/validation'
import { SelectItem } from '@/helpers/forms'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'

export interface PlatformSelectItem extends SelectItem {
  entityType?: PlatformEntityTypes
  avatarEntity?: any
  subText?: string
}

export default Vue.extend({
  name: 'PlatformAutocomplete',
  components: {
    PlatformAvatar,
    InputLabel
  },
  props: {
    value: { type: [String, Array] as PropType<string | string[]>, required: false },
    items: { type: Array as PropType<PlatformSelectItem[]>, required: true },
    label: { type: String, required: false },
    filter: { type: Function, required: false },
    block: { type: Boolean, default: false },
    loading: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false },
    rules: { type: Array, required: false },
    required: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    chipsBelow: { type: Boolean, default: false },
    menuProps: { type: Object, required: false },
    prependLabelIcon: { type: String, required: false },
    itemAvatarSize: { type: Number, default: 28 },
    avatars: { type: Boolean, default: false }
  },
  computed: {
    inputValue: {
      set (val: string | string[]): void {
        this.$emit('input', val)
      },
      get (): string | string[] {
        return this.value
      }
    },
    selectedItems (): SelectItem[] {
      return this.items.filter(this.isSelected)
    },
    validationRules (): any[] {
      const rules: any[] = []
      if (this.required) {
        rules.push(Validators.required)
      }
      if (Array.isArray(this.rules)) {
        rules.push(...this.rules)
      }
      return rules
    }
  },
  methods: {
    isSelected (item: SelectItem): boolean {
      if (Array.isArray(this.inputValue)) {
        return this.inputValue.includes(item.value)
      }
      return this.inputValue === item.value
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.selected-chips {
  min-height: 33px; // Prevent the container from resizing when an item is selected
}

.no-input::v-deep.v-input {
  &:not(.v-input--is-focused) {
    input {
      width: 0;
    }
  }
}
</style>
