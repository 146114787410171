import { UserType } from '../../../../types/API'
import { TargetGroupOptions } from '../../../../types/Block'
import {
  TenureIndividual,
  TenureLegendConfig
} from '../../../../types/Individual'
import { getCompanyPosition } from '../index'

export default function calculateTenureLegendItems (tenureIndividuals: TenureIndividual[]): TenureLegendConfig {
  if (!tenureIndividuals.length) {
    return {
      chair: false,
      executive: false,
      nonExecutive: false
    }
  }
  return {
    chair: tenureIndividuals.some(
      (individualTenure: TenureIndividual) => individualTenure.role === UserType.Chair
    ),
    executive: tenureIndividuals.some(
      (individualTenure: TenureIndividual) => getCompanyPosition(individualTenure.role) === TargetGroupOptions.Executives
    ),
    nonExecutive: tenureIndividuals.some(
      (individualTenure: TenureIndividual) => {
        if (!individualTenure.role || individualTenure.role === UserType.Chair) {
          return false
        }
        return getCompanyPosition(individualTenure.role) === TargetGroupOptions.NonExecutives
      }
    )
  }
}
