<template>
  <v-row
    no-gutters
    @keydown.enter="changePassword"
  >
    <v-col class="d-flex flex-column">
      <h3 class="login-header pt-4 pb-8">{{ $t('form.auth.temporaryPassword.title') }}</h3>
      <span
        class="red--text"
        v-if="errorMessage"
      >
        {{ errorMessage }}
      </span>
      <v-form-base
        class="pt-6"
        :schema="formSchema"
        @input="handleInput"
      />

      <v-row no-gutters class="justify-space-between align-center">
        <span class="login-links">
          <a
            @click="$emit('signIn')"
          >
            {{ $t('form.auth.backToSignUp') }}
          </a>
        </span>
        <platform-button
          class="px-12 py-4"
          primary
          :loading="pendingChangeTemporaryPassword"
          @click="changePassword"
        >
          {{ $t('modal.shared.confirm') }}
        </platform-button>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'
import VFormBase from 'vuetify-form-base'
import Logo from '../../assets/logo.png'
import { confirmSignIn } from 'aws-amplify/auth'
import getAuthErrorMessage from '@/helpers/auth/getAuthErrorMessage'

export default Vue.extend({
  name: 'TemporaryPasswordChangeForm',
  components: {
    VFormBase
  },
  data () {
    return {
      logo: Logo,
      password: <string | undefined>undefined,
      errorMessage: <string | undefined>undefined,
      pendingChangeTemporaryPassword: <boolean>false
    }
  },
  computed: {
    formSchema () {
      const schema: any = {}

      schema.password = {
        type: 'BBTextField',
        label: this.$t('form.auth.temporaryPassword.newPasswordLabel'),
        placeholder: this.$t('form.auth.temporaryPassword.newPasswordPlaceholder'),
        persistentPlaceholder: true,
        outlined: true,
        inputType: 'password',
        col: 12
      }

      return schema
    }
  },
  methods: {
    handleInput (val: any) {
      this.password = val.data.password
    },
    async changePassword (): Promise<void> {
      if (this.pendingChangeTemporaryPassword) {
        return
      }

      if (!this.password) {
        console.error('Missing password')
        return
      }
      this.errorMessage = undefined
      this.pendingChangeTemporaryPassword = true

      try {
        await confirmSignIn({
          challengeResponse: this.password
        })
      } catch (error) {
        this.pendingChangeTemporaryPassword = false
        this.errorMessage = getAuthErrorMessage(error.message)
        return
      }

      this.pendingChangeTemporaryPassword = false
      this.$emit('signIn')
    }
  }
})
</script>
