import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const listCommitteesExtended = /* GraphQL */ `
  query ListCommitteesExtended(
    $companyId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCommitteeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommitteesByCompanyId(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        name
        tags
        createdAt
        individuals {
          items {
            individualId
            companyId
            createdAt
          }
          nextToken
        }
        company {
          id
          owner
          name
          createdAt
          address {
            line1
            line2
            city
            region
            postalCode
            country
          }
          phoneNumber
          registrationNumber
          numberOfEmployees
          brandLogo
          sector
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.ListCommitteesQueryVariables,
  APITypes.ListCommitteesQuery
>
