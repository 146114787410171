<template>
  <v-form-base
    :model="question"
    :schema="schema"
    @click="onInput"
    @input="onInput"
  />
</template>

<script lang="ts">
import { ScaleQuestionData } from '@/types/Question'
import Vue from 'vue'
// @ts-ignore
import VFormBase from 'vuetify-form-base'

export default Vue.extend({
  name: 'ScaleQuestionForm',
  props: {
    value: { type: Object, required: false }
  },
  components: {
    VFormBase
  },
  computed: {
    question: {
      get (): ScaleQuestionData {
        return this.value
      },
      set (data: ScaleQuestionData): void {
        this.$emit('input', data)
      }
    },
    schema () {
      const schema: any = {}

      return schema
    }
  },
  methods: {
    onInput ({ on, data }: { on: string, data: any }): void {
      if (on === 'input') {
        this.question = data
      }
    }
  }
})
</script>
