<template>
  <div>
    <file-upload
      :title="$t('form.admin.displayPicture')"
      icon="user"
      object-type="platformUserAvatar"
      :object-id="user ? user.id : undefined"
      :company-id="selectedCompany ? selectedCompany.id : undefined"
      @pending="$emit('pendingFile')"
      @uploaded="uploadedFile"
    />
    <company-user-modal
      v-if="addToCompanyModal"
      v-model="companyToAdd"
      :user="user"
      :editing="editingCompanyUser"
      @update="updateCompanyUser"
      @close="closeCompanyUserModal"
    />
    <v-form ref="form" v-model="formValid" @submit.prevent="">
      <v-form-base
        v-if="user"
        :model="user"
        :schema="formSchema"
        @input="handleInput"
      />
    </v-form>

    <div
      v-if="editableCompanies"
      class="user-companies"
    >
      <span class="table-header d-block mt-6 mb-3">
        {{ $t('form.admin.companiesHeader') }}
      </span>
      <template v-if="userCompanies.length > 0">
        <platform-entity-card
          v-for="(companyUser, index) in userCompanies"
          :key="`${companyUser.companyId}-${index}`"
          row
          compact
          flat
          inline-actions
          align="start"
          entity-type="companyUser"
          placeholder-size="xs"
          :inner-margin="3"
          :entity="companyUser"
          :avatar-size="44"
          @edit="editCompany(companyUser)"
          @delete="removeFromCompany(companyUser)"
        >
          <small
            v-if="companyUser.accountType"
            class="grey--text darken-2 pl-1"
          >
            {{ $t(`global.accountTypes.${companyUser.accountType}`) }}
          </small>
        </platform-entity-card>
      </template>
      <div v-else-if="loadingUserCompanies">
        <platform-spinner class="mx-auto mb-3" />
      </div>
      <company-select-field
        v-if="unselectedCompanies && unselectedCompanies.length"
        v-model="companyToAdd.companyId"
        ref="companySelector"
        class="mt-0 mb-2"
        :label="$t('form.admin.addToCompanyLabel')"
        :items="unselectedCompanies"
        :loading="loadingUserCompanies"
      />
    </div>
  </div>
</template>

<script lang="ts">
import CompanySelectField from '@/components/shared/inputs/CompanySelectField.vue'
import CompanyUserModal from '@/components/user/company-user/CompanyUserModal.vue'
import { AccountTypes } from '@/helpers/users'
import { PlatformUser } from '@betterboards/shared/types/Platform'
import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import VFormBase from 'vuetify-form-base'
import { CognitoIdentity, LanguageCode } from '@/API'
import { Validators } from '@/helpers/validation'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { invalidateCachedImage } from '@/helpers/forms'
import FileUpload from '@/components/FileUpload.vue'

const AccountTypeIndexes = Object.values(AccountTypes)

const DefaultCompanyUser = () => ({
  companyId: <string | null>null,
  cognitoIdentityId: <string | null>null,
  accountType: <string | null>null,
  company: {
    name: <string | null>null
  }
})

export default Vue.extend({
  name: 'UserForm',
  components: {
    CompanyUserModal,
    CompanySelectField,
    PlatformSpinner,
    PlatformEntityCard,
    FileUpload,
    VFormBase
  },
  props: {
    value: { type: Object as PropType<CognitoIdentity & { companies: { items: any[] } }>, required: false },
    manageableCompanies: { type: Array as PropType<Array<{ id: string, accountType: string, name: string }>> },
    companyId: { type: String, required: false },
    editableCompanies: { type: Boolean, default: false },
    loadingUserCompanies: { type: Boolean, default: false }
  },
  data () {
    return {
      currentAvatarKey: '',
      dialog: false,
      timerId: null,
      addToCompanyModal: false,
      editingCompanyUser: false,
      companyToAdd: DefaultCompanyUser(),
      formValid: false
    }
  },
  watch: {
    formValid (val) {
      this.$emit('valid', val)
    },
    'companyToAdd.companyId' (companyId: string | null) {
      if (companyId) {
        const existingCompanyUser = this.user.companies?.items?.find((c) => c && c.companyId === companyId)
        let companyUser
        if (existingCompanyUser) {
          this.editingCompanyUser = true
          companyUser = existingCompanyUser
        } else {
          this.editingCompanyUser = false
          companyUser = this.manageableCompanies?.find((c) => c.id === companyId)
        }
        if (companyUser) {
          this.companyToAdd = {
            companyId,
            cognitoIdentityId: this.user.id,
            accountType: companyUser.accountType,
            company: {
              name: companyUser.company?.name ?? companyUser.name
            }
          }
        }
        this.addToCompanyModal = true
        return
      }
      this.addToCompanyModal = false
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany']),
    formSchema (): any {
      const config: any = {}

      config.displayName = { type: 'BBTextField', label: this.$t('form.admin.displayName'), col: { xs: 12, sm: 6 }, required: true }
      config.email = { type: 'BBTextField', label: this.$t('form.admin.email'), col: { xs: 12, sm: 6 }, disabled: !!this.user?.id, required: true, rules: [Validators.email, Validators.allowedEmailCharacters] }
      config.defaultLanguage = {
        type: 'select',
        label: this.$t('form.admin.defaultLanguage'),
        col: { xs: 12, sm: 6 },
        items: Object.values(LanguageCode).map((languageCode: string) => ({
          text: this.$t(`global.languages.${languageCode}`),
          value: languageCode
        }))
      }

      return config
    },
    user: {
      set (val: PlatformUser) {
        this.$emit('input', val)
      },
      get (): PlatformUser {
        return this.value
      }
    },
    userCompanies (): { companyId: string, accountType: string, company: { name: string } }[] {
      if (!this.user?.companies?.items) {
        return []
      }
      return this.user.companies.items
        .map((cu: any) => ({
          companyId: cu.companyId,
          cognitoIdentityId: cu.cognitoIdentityId,
          accountType: cu.accountType,
          company: {
            name: cu.company?.name ?? this.manageableCompanies?.find((c) => c.id === cu.companyId)?.name as string
          },
          user: this.user
        }))
        .sort((a, b) => {
          const aIndex = AccountTypeIndexes.indexOf(a.accountType)
          const bIndex = AccountTypeIndexes.indexOf(b.accountType)
          if (aIndex === -1) {
            return 1
          }
          if (bIndex === -1) {
            return -1
          }
          return aIndex - bIndex
        })
    },
    unselectedCompanies (): any[] | undefined {
      if (!this.manageableCompanies) {
        return undefined
      }
      return this.manageableCompanies?.filter((company) => {
        const found = this.userCompanies.find((c) => c.companyId === company.id)
        if (found) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    ...mapActions('User', ['updateUser', 'uploadAvatar']),
    validate () {
      const form: any = this.$refs.form
      form.validate()
    },
    handleInput (input?: any): void {
      if (input) {
        this.user = input.data
      }
      const companies = this.user.companies?.items?.map(({ companyId, accountType }: any) => ({
        companyId,
        accountType
      })) ?? []
      const user = {
        ...this.user,
        companies: {
          items: companies
        }
      }
      this.$emit('input', user)
    },
    uploadedFile () {
      this.$emit('uploadedFile')
      if (this.user?.id && this.selectedCompany?.id) {
        invalidateCachedImage('platformUserAvatar', this.user.id, this.selectedCompany.id)
      }
    },
    editCompany (company: any): void {
      this.companyToAdd.companyId = company.companyId
    },
    removeFromCompany (company: any): void {
      if (!this.user.companies?.items) {
        return
      }
      const index = this.user.companies.items.findIndex((c) => c?.companyId && c.companyId === company.companyId)
      if (index === -1) {
        throw new Error('Failed to remove company as it was not found in list.')
      }
      this.user.companies.items.splice(index, 1)
      this.$emit('updateCompanies', {
        companyId: company.companyId,
        action: 'remove'
      })
      this.handleInput()
    },
    updateCompanyUser (companyId: string): void {
      if (!this.user.companies?.items) {
        throw new Error('Failed to update CompanyUser, User has no Companies.')
      }
      const index = this.user.companies.items.findIndex((c) => c?.companyId && c.companyId === companyId)
      const company: any = { ...this.companyToAdd }
      if (index === -1) {
        this.$emit('updateCompanies', {
          companyId,
          action: 'add',
          accountType: company.accountType
        })
        this.user.companies.items.push(company)
      } else {
        this.$emit('updateCompanies', {
          companyId,
          action: 'update',
          accountType: company.accountType
        })
        this.user.companies.items.splice(index, 1, company)
      }
      this.handleInput()
    },
    closeCompanyUserModal (): void {
      this.companyToAdd = DefaultCompanyUser()
      this.addToCompanyModal = false
      this.editingCompanyUser = false
      // Deselect the input element
      this.$nextTick(() => {
        // I'm not so proud of this but it does its job:
        const companySelectFieldComponent: any = this.$refs.companySelector
        const companySelectField = companySelectFieldComponent?.$refs.input
        if (companySelectField.$refs.input) {
          companySelectField.$refs.input.blur()
        }
      })
    }
  }
})
</script>
