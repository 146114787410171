import omitObjectFields from '../../../util/omitObjectFields'

export const NonUpdatableEntityFields: string[] = ['createdAt', 'updatedAt', '__typename']

export default function omitNonUpdatableFields<
  R extends Record<string, any> = Record<string, any>,
> (target: R, extraFields: string[] = []): R {
  return omitObjectFields(
    target,
    [...NonUpdatableEntityFields, ...extraFields]
  )
}
