<template>
  <platform-mega-menu
    v-model="ownership"
    ref="input"
    :label="$t('form.company.ownership')"
    required
    :height="150"
    :items="items"
    :loading="loadingOptions"
    :disabled="disabled || loadingOptions"
    :menu-class="menuClass"
    :data-test-target="dataTestTarget"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import PlatformMegaMenu from '@/components/shared/inputs/PlatformMegaMenu.vue'
import {
  NotListedCompanyOwnershipStructure,
  PublicCompanyOwnershipStructure,
  ThirdSectorOwnershipStructureId
} from '@betterboards/shared/helpers/statistics/index'

export default Vue.extend({
  name: 'CompanyOwnershipForm',
  components: {
    PlatformMegaMenu
  },
  props: {
    value: { type: String, required: false },
    listedOptions: { type: Array as PropType<Array<{ id: string, value: string, country: string }>>, required: false },
    loadingOptions: { type: Boolean, required: false },
    disabled: { type: Boolean, default: false },
    menuClass: { type: String, required: false },
    dataTestTarget: { type: String, required: false }
  },
  data: () => ({
    listedActiveButton: <number | null>null
  }),
  mounted () {
    this.checkIsValueListed()
  },
  watch: {
    ownership () {
      this.checkIsValueListed()
    }
  },
  computed: {
    isListed (): boolean {
      return this.listedActiveButton === 0
    },
    ownership: {
      set (val: string): void {
        this.$emit('input', val)
      },
      get (): string | undefined {
        return this.value
      }
    },
    items () {
      return [
        {
          text: this.$t('universe.companyOwnership.categories.private'),
          value: 'Private',
          items: [
            {
              text: this.$t('universe.companyOwnership.categories.listed'),
              value: 'Listed',
              items: this.listedOptions.map((option) => ({
                text: `${option.country} - ${option.value}`,
                value: option.value
              }))
            },
            {
              text: this.$t('universe.companyOwnership.categories.notListed'),
              value: 'NotListed',
              items: Object.values(NotListedCompanyOwnershipStructure).map((value: NotListedCompanyOwnershipStructure) => ({
                text: this.$t(`universe.companyOwnership.notListedOptions.${value}`),
                value
              }))
            }
          ]
        },
        {
          text: this.$t('universe.companyOwnership.categories.public'),
          value: 'Public',
          items: Object.values(PublicCompanyOwnershipStructure).map((value: PublicCompanyOwnershipStructure) => ({
            text: this.$t(`universe.companyOwnership.publicOptions.${value}`),
            value
          }))
        },
        {
          text: this.$t('universe.companyOwnership.categories.thirdSector'),
          value: ThirdSectorOwnershipStructureId
        }
      ]
    }
  },
  methods: {
    checkIsValueListed () {
      if (this.ownership) {
        if (Object.values(NotListedCompanyOwnershipStructure).includes(this.ownership as NotListedCompanyOwnershipStructure)) {
          this.setNotListed(false)
          return
        }
        this.setListed(false)
      }
    },
    setListed (clear: boolean = true) {
      this.listedActiveButton = 0
      if (clear) {
        this.ownership = undefined
      }
    },
    setNotListed (clear: boolean = true) {
      this.listedActiveButton = 1
      if (clear) {
        this.ownership = undefined
      }
    },
    validate () {
      const input: any = this.$refs.input
      input.validate()
    }
  }
})
</script>
