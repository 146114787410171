<template>
  <v-avatar
    class="avatar"
    style="position: relative;"
    :size="size"
    :class="avatarClasses"
    :style="{
      'border-color': border ? `var(--v-${border}-base) !important` : undefined,
    }"
  >
    <platform-spinner
      v-if="!failed && !loaded"
      :width="spinnerWidth"
      :style="{
        position: 'absolute',
        top: internalLoader ? `calc(50% - ${spinnerSize / 2}px)` : `-${spinnerWidth / 2}px`,
        left: internalLoader ? `calc(50% - ${spinnerSize / 2}px)` : `-${spinnerWidth / 2}px`,
      }"
      :size="spinnerSize"
    />
    <file-viewer
      v-if="objectId && objectType"
      ref="image"
      :object-id="objectId"
      :object-type="objectType"
      :company-id="companyId"
      :placeholder="placeholder"
      :placeholder-size="placeholderSize"
      :title="title"
      :listen="listen"
      @loaded="loaded = true"
      @failed="failed = true"
    />
  </v-avatar>
</template>

<script lang="ts">
import FileViewer from '@/components/FileViewer.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { getInitials, getNameInitials, getFullName } from '@/helpers/individual'
import { PlatformEntityTypes } from '@/types/Platform'
import Vue, { PropType } from 'vue'

export default Vue.extend({
  name: 'PlatformAvatar',
  data: () => ({
    loaded: false,
    failed: false
  }),
  components: {
    PlatformSpinner,
    FileViewer
  },
  props: {
    entityType: { type: String as PropType<PlatformEntityTypes>, required: true },
    entity: { type: Object as PropType<any>, required: true },
    size: { type: Number, default: 64 },
    listen: { type: Boolean, default: false },
    padBottom: { type: Boolean, default: true },
    shield: { type: Boolean, default: false },
    placeholderSize: { type: String as PropType<'xl' | 'lg' | 'md' | 'sm' | 'xs'>, default: 'md' },
    border: { type: String, required: false },
    internalLoader: { type: Boolean, default: false },
    smallLoader: { type: Boolean, default: false }
  },
  computed: {
    objectId (): string {
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
          return this.entity.individualId ?? this.entity.id
        case PlatformEntityTypes.Questionnaire:
        case PlatformEntityTypes.SurveyGroup:
        case PlatformEntityTypes.CompanyUser:
          return this.entity.companyId
      }
      return this.entity.id
    },
    companyId (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
        case PlatformEntityTypes.Questionnaire:
        case PlatformEntityTypes.SurveyGroup:
        case PlatformEntityTypes.CompanyUser:
          return this.entity.companyId
        case PlatformEntityTypes.Company:
          return this.entity.id
      }
      return undefined
    },
    objectType (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
          return 'userAvatar'
        case PlatformEntityTypes.User:
          return 'platformUserAvatar'
        case PlatformEntityTypes.Questionnaire:
        case PlatformEntityTypes.SurveyGroup:
        case PlatformEntityTypes.Company:
        case PlatformEntityTypes.CompanyUser:
          return 'companyLogo'
        case PlatformEntityTypes.Package:
          return 'packageImage'
      }
      return undefined
    },
    placeholder (): string | undefined | null {
      const individual = this.entity.individual ?? this.entity
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
          if (individual.firstName && individual.familyName) {
            return getInitials(individual)
          }
          if (!individual.preferredName) {
            return undefined
          }
          return getNameInitials(individual.preferredName)
        case PlatformEntityTypes.User:
          return getNameInitials(this.entity.displayName)
        case PlatformEntityTypes.Company:
        case PlatformEntityTypes.Package:
          return this.entity.name
        case PlatformEntityTypes.Questionnaire:
        case PlatformEntityTypes.SurveyGroup:
        case PlatformEntityTypes.CompanyUser:
          return this.entity.company?.name ?? this.entity.name
      }
      return undefined
    },
    spinnerWidth (): number {
      let width = this.size ? this.size / 12 : 3
      if (this.smallLoader) {
        width *= 0.75
      }
      return width
    },
    spinnerSize (): number | string {
      let size = this.size ? (this.internalLoader ? this.size : this.size + this.spinnerWidth) : 40
      if (this.smallLoader) {
        size *= 0.75
      }
      return size
    },
    title (): string | undefined {
      switch (this.entityType) {
        case PlatformEntityTypes.Individual:
          return getFullName(this.entity.individual ?? this.entity) ?? undefined
      }
      return undefined
    },
    avatarClasses (): any {
      return {
        'avatar-shield': this.shield,
        'mb-1': this.padBottom,
        border: !!this.border
      }
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.avatar {
  background-color: white;
  &.border {
    border-width: 1px;
    border-style: solid;
    border-radius: 50%;
  }
}
.avatar-shield {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
</style>
