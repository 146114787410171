<template>
  <v-row
    no-gutters
    align="center"
    justify="center"
    class="display-graph flex-column"
    :class="{ 'pt-4': padded && !config.legend, 'pt-2 pb-1': graph.tickLabels }"
  >
    <v-col>
      <template v-if="graph.graphType === GraphTypes.Scale">
        <v-row
          no-gutters
          align="center"
          justify="center"
        >
          <v-col
            v-if="config.legend ? false : ((graph.data && graph.data.questionText) || graph.tickLabels)"
            :class="{ 'pr-5': padded, 'pr-2': !padded }"
          >
            <p class="mb-0">{{ graph.data.questionText }}</p>
          </v-col>
          <v-col class="shrink">
            <scale-graph
              :data="graph.data"
              :config="config"
              :comparison-data="comparisonResult ? comparisonResult.data : undefined"
            />
          </v-col>
        </v-row>
      </template>
      <template v-else-if="graph.graphType === GraphTypes.Scatter">
        <scatter-graph
          :data="graph.data"
          :criteria="graph.criteria"
          :config="config"
          :comparison-data="comparisonResult ? comparisonResult.data : undefined"
        />
      </template>
      <template v-else-if="graph.graphType === GraphTypes.Line">
        <line-graph
          :data="graph.data"
          :criteria="graph.criteria"
          :config="config"
          :comparison-data="comparisonResult ? comparisonResult.data : undefined"
        />
      </template>
      <template v-else-if="graph.graphType === GraphTypes.Variance">
        <variance-graph
          :data="graph.data"
          :config="config"
          :comparison-data="comparisonResult ? comparisonResult.data : undefined"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import { GraphConfig } from '@betterboards/graphs/variants/Abstract'
import ScaleGraph from '@/components/graphs/ScaleGraph.vue'
import ScatterGraph from '@/components/graphs/ScatterGraph.vue'
import LineGraph from '@/components/graphs/LineGraph.vue'
import VarianceGraph from '@/components/graphs/VarianceGraph.vue'
import { GraphResult } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'DisplayGraph',
  components: {
    ScaleGraph,
    ScatterGraph,
    LineGraph,
    VarianceGraph
  },
  props: {
    graph: {
      type: Object as PropType<GraphResult>,
      required: false
    },
    config: {
      type: Object as PropType<Partial<GraphConfig>>,
      required: false
    },
    comparisonResult: {
      type: Object as PropType<GraphResult | undefined>,
      required: false
    },
    padded: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    GraphTypes
  })
})
</script>
