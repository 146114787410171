<template>
  <platform-modal
    v-model="showModal"
    icon="committee"
    :width="800"
    :save-disabled="!isValid"
    :title="$t('modal.committees.create.title')"
    @save="save"
    @validate="$refs.form.validate()"
  >
    <committee-form
      ref="form"
      v-model="committee"
      show-type-option
      :company-id="selectedCompany.id"
      :saving="saving"
      @valid="(val) => isValid = val"
    />
  </platform-modal>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import PlatformModal from '@/components/PlatformModal'
import CommitteeForm from '@/components/committees/CommitteeForm'
import { trimStringFields } from '@/helpers/validation'

export default Vue.extend({
  name: 'CreateCommitteeModal',
  components: {
    PlatformModal,
    CommitteeForm
  },
  data () {
    return {
      timerId: null,
      showModal: true,
      committee: {
        name: null,
        tags: [],
        individuals: {
          items: []
        },
        companyId: null
      },
      nextToken: null,
      ready: false,
      saving: false,
      isValid: false
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany'])
  },
  watch: {
    showModal: function (val) {
      if (!val) {
        this.ready = false
        this.$emit('close')
      }
    }
  },
  methods: {
    ...mapActions('Committee', ['createCommittee']),
    async save () {
      if (this.saving) {
        return
      }

      this.saving = true
      this.createCommittee({
        name: this.committee.name,
        tags: this.committee.tags,
        individuals: this.committee.individuals.items
      })
        .then((response) => {
          this.dialog = false
          const committee = trimStringFields({
            ...this.committee,
            companyId: response.companyId,
            id: response.id,
            tags: response.tags,
            individuals: {
              items: this.committee.individuals.items.map((i) => ({ individualId: i.individualId }))
            }
          })
          this.$emit('created', committee)
          this.$toasted.success(this.$t('modal.committees.create.successMessage'))
        })
        .catch((err) => {
          console.error('Caught an error while trying to create a committee:', err)
          this.$toasted.error(this.$t('modal.committees.create.errorMessage'))
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
})
</script>
