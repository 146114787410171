<template>
  <v-row>
    <v-col :cols="10">
      <v-form ref="form" v-model="formValid" @submit.prevent="">
        <v-form-base
          class="no-col-gutters"
          :model="criteriaItem"
          :schema="criteriaItemSchema"
          @input="onInput"
        />
      </v-form>
    </v-col>
    <v-col :cols="2" class="text-center">
      <v-row class="pt-3" justify="end">
        <v-col v-show="hasMultipleVariants" shrink :cols="12" md="auto">
          <v-row justify="end" align="start" class="flex-wrap pb-2 pr-2">
            <variant-picker
              v-model="activeQuestionnaireVariant"
              entity-type="criteriaItem"
              :selected-questionnaire-variant="selectedQuestionnaireVariant"
              :variants="variants"
            />
          </v-row>
        </v-col>
        <v-col shrink :cols="12" :md="hasMultipleVariants ? 'auto' : 12">
          <v-row justify="end" align="start" class="flex-wrap">
            <platform-button
              warning
              icon="delete"
              class="ml-2 mb-2"
              v-tooltip="$t('form.questionnaire.customContent.deleteCriteria')"
              :disabled="disabled"
              @click="$emit('delete', criteriaItem)"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { LanguageCode } from '@/API'
import VariantPicker from '@/components/shared/VariantPicker.vue'
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import {
  PlatformQuestionnaireVariant,
  QuestionnaireVariantCode
} from '@/types/Platform'
import { CriteriaItem } from '@betterboards/shared/types/Content'

export default Vue.extend({
  name: 'CriteriaItemForm',
  props: {
    value: { type: Object as PropType<{ [variantCode: string]: CriteriaItem }>, required: true }, // VariantMap this CriteriaItem
    selectedQuestionnaireVariant: { type: String as PropType<QuestionnaireVariantCode>, required: false },
    variants: { type: Array as PropType<PlatformQuestionnaireVariant[]>, required: true },
    questionnaireId: { type: String, required: false },
    disabled: { type: Boolean, default: false }
  },
  components: {
    VariantPicker,
    VFormBase
  },
  data: () => ({
    activeQuestionnaireVariant: <QuestionnaireVariantCode | undefined>undefined,
    formValid: false
  }),
  watch: {
    isValid (val: boolean): void {
      this.$emit('valid', val)
    }
  },
  mounted (): void {
    this.activeQuestionnaireVariant = this.selectedQuestionnaireVariant
  },
  computed: {
    defaultVariant (): QuestionnaireVariantCode | undefined {
      return this.variants?.[0]?.variantCode as QuestionnaireVariantCode | undefined
    },
    criteriaItem: {
      get (): CriteriaItem | undefined {
        if (!this.activeQuestionnaireVariant || !this.defaultVariant) {
          return undefined
        }
        const criteriaItem = this.value[this.activeQuestionnaireVariant]
        if (criteriaItem) {
          return criteriaItem
        }
        const defaultCriteriaItem = this.value[this.defaultVariant]

        if (!defaultCriteriaItem) {
          return undefined
        }

        return {
          ...defaultCriteriaItem,
          variantCode: this.activeQuestionnaireVariant as LanguageCode
        } as Partial<CriteriaItem> as CriteriaItem
      },
      set (val: CriteriaItem): void {
        this.$emit('updateCriteriaItem', val)
      }
    },
    criteriaItemSchema (): any {
      return {
        name: { type: 'BBTextField', label: 'Name', col: 12, disabled: this.disabled, required: true },
        description: { type: 'BBTextArea', label: 'Description', col: 12, disabled: this.disabled, required: false }
      }
    },
    hasMultipleVariants (): boolean {
      return this.variants.length > 1
    },
    isValid (): boolean {
      return this.formValid
    }
  },
  methods: {
    onInput ({ data }: any): void {
      this.criteriaItem = data
    }
  }
})
</script>
