<template>
  <v-col>
    <div
      class="scale-buttons justify-center d-flex row no-gutters"
      :class="{
        'flex-nowrap': $vuetify.breakpoint.smAndUp
      }"
    >
      <div
        v-for="button in buttons"
        :key="button.label"
        class="scale-button__container d-flex"
      >
        <platform-button
          primary
          default-case
          class="scale-button d-block grow"
          :class="{
            active: button.value === answer
          }"
          :disabled="isPreview"
          @click="answerQuestion(button.value)"
        >
          <platform-spinner
            class="mx-auto"
            color="white"
            :size="36"
            :width="5"
            :style="{ opacity: button.value === answer && pendingDelay ? 1 : 0 }"
            style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); transition: opacity linear 200ms;"
          />
          <span
            class="mx-auto"
            style="transition: opacity linear 100ms;"
            :style="{ opacity: button.value === answer && pendingDelay ? 0 : 1 }"
          >
            {{ button.label }}
          </span>
        </platform-button>
      </div>
    </div>
  </v-col>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ScaleCriteriaOptions } from '@/helpers/questionnaire/questions'
import { ScaleQuestionData } from '@/types/Question'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

export default Vue.extend({
  name: 'SurveyScaleQuestion',
  components: {
    PlatformSpinner
  },
  props: {
    question: { type: Object as PropType<ScaleQuestionData>, required: true },
    value: { type: [String, Number], required: false }, // answer
    isPreview: { type: Boolean, default: false }
  },
  data: () => ({
    pendingDelay: false
  }),
  computed: {
    answer: {
      get (): string | number {
        return this.value
      },
      set (val: string | number): void {
        this.$emit('input', val)
      }
    },
    buttons (): any[] {
      const ScaleButtons = {
        [ScaleCriteriaOptions.Agreement]: [
          { value: 1, label: this.$t('page.viewSurvey.questions.scale.StronglyDisagree') },
          { value: 2, label: this.$t('page.viewSurvey.questions.scale.Disagree') },
          { value: 3, label: this.$t('page.viewSurvey.questions.scale.NeitherAgreement') },
          { value: 4, label: this.$t('page.viewSurvey.questions.scale.Agree') },
          { value: 5, label: this.$t('page.viewSurvey.questions.scale.StronglyAgree') }
        ],
        [ScaleCriteriaOptions.Development]: [
          { value: 1, label: this.$t('page.viewSurvey.questions.scale.VeryUndeveloped') },
          { value: 2, label: this.$t('page.viewSurvey.questions.scale.Undeveloped') },
          { value: 3, label: this.$t('page.viewSurvey.questions.scale.NeitherDevelopment') },
          { value: 4, label: this.$t('page.viewSurvey.questions.scale.Developed') },
          { value: 5, label: this.$t('page.viewSurvey.questions.scale.VeryDeveloped') }
        ],
        [ScaleCriteriaOptions.Importance]: [
          { value: 1, label: this.$t('page.viewSurvey.questions.scale.VeryUnimportant') },
          { value: 2, label: this.$t('page.viewSurvey.questions.scale.Unimportant') },
          { value: 3, label: this.$t('page.viewSurvey.questions.scale.NeitherImportance') },
          { value: 4, label: this.$t('page.viewSurvey.questions.scale.Important') },
          { value: 5, label: this.$t('page.viewSurvey.questions.scale.VeryImportant') }
        ]
      }
      return ScaleButtons[this.question.criteria]
    },
    criteria (): ScaleCriteriaOptions {
      return this.question.criteria
    }
  },
  methods: {
    answerQuestion (answer: any): void {
      if (this.pendingDelay) {
        return
      }
      this.pendingDelay = true
      this.answer = answer
      setTimeout(() => {
        // Artificial delay to allow user to see their answer chosen
        this.$emit('next')
        this.pendingDelay = false
      }, 400)
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
@import '~vuetify/src/styles/settings/_variables';

.scale-buttons {
  .scale-button__container {
    padding: 8px;
    flex-basis: 0;
    flex-grow: 1;
    display: flex;

    .scale-button {
      font-size: 0.9vw;
    }
    @media #{map-get($display-breakpoints, 'xl-only')} {
      height: 160px;
      .scale-button {
        font-size: 1.3em;
      }
    }
    @media #{map-get($display-breakpoints, 'lg-only')} {
      height: 140px;
      .scale-button {
        font-size: 1vw;
      }
    }
    @media #{map-get($display-breakpoints, 'md-only')} {
      height: 120px;
      .scale-button {
        font-size: 1.1vw;
      }
    }
    @media #{map-get($display-breakpoints, 'sm-only')} {
      height: 110px;
      .scale-button {
        font-size: 1.5vw;
      }
    }
    @media #{map-get($display-breakpoints, 'xs-only')} {
      height: 90px;
      max-width: 130px;
      .scale-button {
        font-size: 1.0em;
      }
    }

    .scale-button {
      padding: 8px !important;
      white-space: normal;
      font-weight: bold;
      flex-basis: 0;
      flex-grow: 1;
      border-radius: 10px;

      &.active {
        margin: 0 0;
        border: 4px solid var(--v-secondary-base);
        border-color: var(--v-secondary-base) !important;
      }
    }
  }
}
</style>
