<template>
  <v-card class="pa-5 overflow-y-hidden d-flex flex-column">
    <v-row
      v-if="reportContextButtonKey"
      no-gutters
      justify="end"
      class="pb-2"
    >
      <report-context-button
        :value="reportContextButtonKey"
        :disabled="loadingReport"
        @insertRecommendation="handleInsertRecommendedContent"
      />
    </v-row>
    <template v-if="loadingReport">
      <platform-spinner
        class="my-4 mx-auto"
      />
    </template>
    <PlatformWYSIWYG
      v-else
      :placeholder="tooltip"
      :initial-data="currentContent"
      :max-length="textFieldLimit"
      @newInput="saveUpdate"
    />
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'
import { PlatformReport } from '@/types/Report'
import { MaxFieldLengths } from '@/helpers/validation'
import PlatformWYSIWYG from '@/components/PlatformWYSIWYG.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import ReportContextButton from '@/components/analysis/ReportContextButton.vue'
import { ReportContextItem } from '@/helpers/recommendations'
import { parseMarkdownContent } from '@/helpers/text'
import { InputFields } from '@betterboards/shared/types/Platform'

export default Vue.extend({
  components: {
    ReportContextButton,
    PlatformSpinner,
    PlatformWYSIWYG
  },
  props: {
    report: { type: Object as PropType<PlatformReport>, required: false },
    loadingReport: { type: Boolean, default: false }
  },
  data: () => ({
    //
  }),
  computed: {
    currentContent (): string | undefined {
      const focusAreas = this.report?.focusAreas
      if (focusAreas?.length) {
        return focusAreas ?? undefined
      }

      return undefined
    },
    tooltip (): string {
      return this.$t('analysis.focusAreas.tooltip') as string
    },
    reportContextButtonKey (): ReportContextItem | undefined {
      return ReportContextItem.FocusAreas
    },
    textFieldLimit (): number {
      const focusAreasFieldOverride: number | undefined = this.report?.configuration?.maxFieldLengths?.[InputFields.FocusAreas]
      if (focusAreasFieldOverride) {
        return focusAreasFieldOverride
      }
      return MaxFieldLengths[InputFields.FocusAreas]
    }
  },
  methods: {
    ...mapActions('Report', ['saveReport']),
    async saveUpdate (val) {
      if (!this.report) {
        console.error('no report loaded')
        return
      }
      await this.saveReport({
        id: this.report.id,
        focusAreas: val
      })
    },
    handleInsertRecommendedContent (content: string): void {
      const newContent = this.currentContent
        ? `${this.currentContent}\n\n${content}`
        : content
      this.saveUpdate(
        parseMarkdownContent(newContent)
      )
    }
  }
})
</script>
