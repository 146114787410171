import { Company, GetCompanyQuery } from '@/API'
import { getFullCompany } from '@/graphql/custom/getFullCompany'
import { ignoreParentCompanyError } from '@/helpers/company'
import { precacheImage } from '@/helpers/forms'
import { Module } from 'vuex'
import Vue from 'vue'
import API from '@/services/API'
import { updateCompany as updateCompanyMutation } from '@/graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api-graphql'

interface CompanyState {
  list: Company[]
  listNextToken: string | undefined
  selectedCompany: Company | null
  nextSelectedCompanyId: string | null // Used to track the ID of a newly created Company, to be selected once the User is re-authed.
  companyMembershipSubscriptions: any[]
  companyAccessList: any[]
  currentRole: string
}

const defaultState = (): CompanyState => ({
  list: [],
  listNextToken: undefined,
  selectedCompany: null,
  nextSelectedCompanyId: null,
  companyMembershipSubscriptions: [],
  companyAccessList: [],
  currentRole: 'BoardMember'
})

const deJSONCompanyObject = function (company: Company): any {
  return {
    ...company,
    configuration: company.configuration
      ? {
          ...company.configuration
        }
      : undefined
  }
}

const CompanyStore: Module<CompanyState, any> = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    reset (state: CompanyState) {
      const newState = defaultState()
      for (const key of Object.keys(newState)) {
        state[key] = newState[key]
      }
    },
    setTargetSelectCompanyId (state: CompanyState, companyId: string | null): void {
      state.nextSelectedCompanyId = companyId
    },
    setList: (state: any, value: any[]) => {
      state.list = value
    },
    resetList: (state: any) => {
      state.list = []
    },
    setListToken: (state: any, nextToken: string) => {
      state.listNextToken = nextToken
    },
    addCompany: (state: CompanyState, company: Company) => {
      if (!company) {
        return
      }
      const newCompanyObject = deJSONCompanyObject(company)
      const index = state.list.findIndex((item) => item.id === newCompanyObject.id)
      if (index === -1) {
        state.list.push(newCompanyObject)
        return newCompanyObject
      }
      state.list.splice(index, 1, newCompanyObject)
    },
    addCompanyAccess: (state: CompanyState, company: { id: string, accountType: string, name: string }) => {
      const index = state.companyAccessList.findIndex((item) => item.id === company.id)
      if (index === -1) {
        state.companyAccessList.push(company)
        return
      }
      state.companyAccessList.splice(index, 1, company)
    },
    removeCompanyAccess: (state: CompanyState, companyId: string) => {
      const index = state.companyAccessList.findIndex((item) => item.id === companyId)
      if (index === -1) {
        return
      }
      state.companyAccessList.splice(index, 1)
    },
    updateCompany: (state: any, company: Company) => {
      if (!company) {
        return
      }
      const index = state.list.findIndex((item: Company) => item.id === company.id)
      const companyData = deJSONCompanyObject(company)
      if (index === -1) {
        state.list.push(companyData)
        return
      }
      state.list.splice(index, 1, companyData)
    },
    removeCompany: (state: any, company: Company) => {
      if (!company) {
        return
      }
      const index = state.list.findIndex((item: Company) => item.id === company.id)
      if (index === -1) {
        return
      }
      state.list.splice(index, 1)
    },
    setSelectedCompany: (state: CompanyState, company: Company) => {
      if (company.configuration) {
        company.configuration.report = typeof company.configuration.report === 'string' ? JSON.parse(company.configuration.report) : company.configuration.report
        company.configuration.questionnaire = typeof company.configuration.questionnaire === 'string' ? JSON.parse(company.configuration.questionnaire) : company.configuration.questionnaire
        company.configuration.admin = typeof company.configuration.admin === 'string' ? JSON.parse(company.configuration.admin) : company.configuration.admin
      }
      state.selectedCompany = company
      if (state.companyAccessList.length > 0) {
        const item = state.companyAccessList.find((item) => item.id === company.id)
        if (item) {
          state.currentRole = item.accountType
          return
        }
      }
      state.currentRole = 'BoardMember'
    },
    setNewCompanyMembershipSubscription: (state: CompanyState, value: any) => (state.companyMembershipSubscriptions = value),
    pushCompanyMembershipSubscription: (state: CompanyState, subscription: any) => (state.companyMembershipSubscriptions.push(subscription))
  },
  actions: {
    addCompanyToList ({ state, commit, dispatch }, companyData) {
      // precacheImage('companyLogo', companyData.id, companyData.id)
      commit('addCompany', companyData)
      if (state.nextSelectedCompanyId && state.nextSelectedCompanyId === companyData.id) {
        dispatch('selectCompany', companyData)
          .catch((err) => {
            console.error('Failed to select newly joined Company.', companyData.id, err)
            throw err
          })
          .finally(() => {
            commit('setTargetSelectCompanyId', null)
          })
      }
    },
    async addCompanyAccessPermission ({ state, commit, dispatch }, company: { id: string, accountType: string, name: string }) {
      if (!company.name) {
        let companyMatch = state.list.find((c) => c.id === company.id)
        if (!companyMatch) {
          companyMatch = await dispatch('fetchCompanyById', company.id)
        }
        if (companyMatch) {
          company.name = companyMatch.name
        }
      }
      commit('addCompanyAccess', company)
    },
    async createCompany ({ state, commit, dispatch, rootState }, companyData): Promise<Company> {
      const selectedCompanyId = state.selectedCompany?.id
      if (!selectedCompanyId) {
        throw new Error('Failed to create Company as a Company must be selected.')
      }
      let company
      try {
        company = await API.post('backendfunctions', '/companies', {
          body: {
            selectedCompanyId,
            company: companyData
          }
        })
      } catch (e) {
        console.error('error creating company', e)
        Vue.toasted.error('Error creating company, please try again later')
      }
      return company
    },
    async deleteCompany ({ commit, state }, company: Company) {
      if (!company) {
        return
      }
      console.log('deleting company', company)
      if (company.id === state.selectedCompany?.id) {
        Vue.toasted.info('You cannot delete your selected company, select another and try again')
        return
      }
      try {
        await API.graphql({
          query: updateCompanyMutation,
          variables: {
            input: {
              id: company.id,
              deletedAt: (new Date()).toISOString()
            }
          }
        })
      } catch (err) {
        if (!ignoreParentCompanyError(err)) {
          Vue.toasted.error('Error deleting company, please try again or contact support.')
          console.error('error deleting company', err)
          throw err
        }
      }
      commit('removeCompany', company)
    },
    async updateCompany ({ rootState, commit }, companyData): Promise<any> {
      let company
      try {
        company = await API.put('backendfunctions', '/companies', {
          body: {
            ...companyData,
            updatedAt: undefined,
            createdAt: undefined
          }
        })
      } catch (err) {
        console.error('Failed to update Company', err)
        throw err
      }
      return company
    },
    clearSelectedCompany ({ commit }) {
      localStorage.removeItem('selectedCompany')
      commit('setSelectedCompany', null)
    },
    async fetchCompanyById ({ commit }, companyId: string): Promise<undefined | Company> {
      let response
      try {
        response = await API.graphql({
          query: getFullCompany,
          variables: {
            id: companyId
          }
        }) as GraphQLResult<GetCompanyQuery>
      } catch (err) {
        const ignoreError = err.errors?.find((e) => e.path?.includes('parent'))
        if (ignoreError) {
          response = err
        } else {
          throw err
        }
      }
      const company = response.data?.getCompany
      commit('updateCompany', company)

      if (!response.data || !company) {
        return undefined
      }

      if (company.individuals?.items) {
        company.individuals.items = company.individuals.items.filter((i) => !!i?.individual)
      }

      return company as Company
    },
    async refreshSelectedCompany ({ state, commit, dispatch }): Promise<void> {
      if (!state.selectedCompany?.id) {
        return
      }
      const company = await dispatch('fetchCompanyById', state.selectedCompany.id)
      commit('setSelectedCompany', company)
    },
    async selectCompany ({ commit, state, dispatch }: { state: CompanyState, commit: any, dispatch: any }, company: Company) {
      if (!company.id) {
        console.warn('Failed to selectCompany as the id property is not set in the provided company object.')
        return
      }
      localStorage.setItem('selectedCompany', company.id)
      let freshCompany
      try {
        freshCompany = await dispatch('fetchCompanyById', company.id)
      } catch (err) {
        console.error('Failed to fetch Company:', err)
        commit('setTargetSelectCompanyId', company.id)
        throw err
      }
      if (!freshCompany) {
        throw new Error('Failed to fetch company')
      }
      commit('setSelectedCompany', freshCompany)
      const committees = freshCompany.committees?.items
      if (committees) {
        commit('Committee/setList', committees, { root: true })
      }
      const individuals = freshCompany.individuals?.items.map((i) => ({
        id: i.individualId,
        companyId: company.id,
        ...i.individual
      }))
      commit('Individual/setList', individuals, { root: true })
      individuals?.forEach((individual) => {
        precacheImage('userAvatar', individual.id, company.id)
      })
    },
    pickLowestAccessCompany ({ state, dispatch }: { dispatch: any, state: CompanyState }) {
      const company = state.companyAccessList.find((item) => item.accountType === 'BoardMember') ?? state.companyAccessList[0]
      dispatch('selectCompany', { id: company.id })
    }
  }
}

export default CompanyStore
