<template>
  <report-preview-page :title="pageTitle">
    <display-markdown
      v-if="evaluationInformationSystemContent"
      :value="evaluationInformationSystemContent"
    />
    <display-markdown
      v-if="evaluationInformationUserContent"
      :value="evaluationInformationUserContent"
    />
    <display-markdown
      v-if="previewContent"
      :value="previewContent"
    />
  </report-preview-page>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import ReportPreviewPage from '@/components/analysis/report-previews/partials/Page.vue'
import DisplayMarkdown from '@/components/DisplayMarkdown.vue'
import { PlatformReport } from '@betterboards/shared/types/Report'

export default Vue.extend({
  name: 'EvaluationInformationReportPreview',
  components: {
    DisplayMarkdown,
    ReportPreviewPage
  },
  props: {
    report: { type: Object as PropType<PlatformReport | undefined>, required: false },
    previewContent: { type: String as PropType<string | undefined>, required: false }
  },
  computed: {
    pageTitle (): string {
      return this.$t('report.page.evaluationInformation') as string
    },
    evaluationInformationSystemContent (): string | undefined {
      if (!this.report?.configuration?.pages?.evaluationInformation?.content) {
        return undefined
      }
      const content = this.report.configuration.pages.evaluationInformation.content
      return content.replace('height="700"', 'height="200"')
    },
    evaluationInformationUserContent (): string | undefined {
      if (!this.report?.summary) {
        if (this.previewContent) {
          return undefined
        }
        // Report has no evaluation information, display example content
        return this.$t('analysis.reportContent.recommendationsModal.exampleContent.evaluationInformationContent') as string
      }
      return this.report.summary
    }
  }
})
</script>
