import { CompanyTeamTypes } from '@/API'
import { PlatformTeamIndividual } from '@betterboards/shared/types/Team'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'

export const TeamsOrder: CompanyTeamTypes[] = Object.values(CompanyTeamTypes) as CompanyTeamTypes[]

export function sortTeamIndividuals (a: PlatformTeamIndividual, b: PlatformTeamIndividual): number {
  const aIndex = TeamsOrder.indexOf(a.teamId)
  const bIndex = TeamsOrder.indexOf(b.teamId)
  if (aIndex === -1) {
    console.error('Sort failed, Team ID not found?:', a.teamId, JSON.stringify(a))
    return 1
  }
  if (bIndex === -1) {
    console.error('Sort failed, Team ID not found?:', b.teamId, JSON.stringify(b))
    return -1
  }
  return aIndex - bIndex
}

export function filterTeamIndividuals (individuals: PlatformIndividual[], targetTeams: CompanyTeamTypes[]): PlatformIndividual[] {
  const companyHasTeams: boolean = individuals.some(
    (ci: PlatformIndividual) => !!ci.teams?.items?.length
  )

  return individuals.filter((individual: PlatformIndividual): boolean => {
    if (targetTeams && companyHasTeams) {
      return targetTeams.some(
        (team: CompanyTeamTypes) => individual.teams?.items.some(
          (ti: PlatformTeamIndividual): boolean => ti.teamId === team
        )
      )
    }
    return true
  })
}
