<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>Query Builder</h1>
        <platform-button
          primary
          default-case
          @click="queries.push({ collection: '', category: '' })"
        >
          Add Query
        </platform-button>
        <platform-button
          primary
          default-case
          @click="runQuery"
        >
          Run Query
        </platform-button>
      </v-col>
      <v-col>
        <universe-query-row
          v-for="(row, index) in queries"
          v-model="queries[index]"
          :key="`${index}_${row.collection}`"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <pre>{{ formattedResults }}</pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import API from '@/services/API'
import UniverseQueryRow from '@/views/universe/UniverseRow.vue'

export default Vue.extend({
  name: 'UniversePage',
  components: { UniverseQueryRow },
  data () {
    return {
      queries: [] as { collection: string, category: string }[],
      response: undefined as any,
      availableCriteria: [] as any[]
    }
  },
  computed: {
    formattedResults (): string {
      if (!this.response) {
        return ''
      }

      return JSON.stringify(this.response, null, 2)
    }
  },
  mounted () {
    this.queries.push({
      collection: '',
      category: ''
    })
  },
  methods: {
    async runQuery () {
      const results = await API.post(
        'backendfunctions',
        '/stats/universe',
        {
          body: this.queries
        }
      )
      console.log('items', results)
      this.response = results
    }
  }
})
</script>
