import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const listFullSurveyGroupsByCompany = /* GraphQL */ `
  query ListFullSurveyGroupsByCompany(
    $companyId: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyGroupsByCompany(
      companyId: $companyId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questionnaireId
        companyId
        packageId
        name
        variants {
          name
          variantCode
        }
        status
        createdAt
        updatedAt
        releasedAt
        dispatchedAt
        deletedAt
        tags
        surveys {
          items {
            id
            groupId
            companyId
            questionnaireId
            individualId
            finishedAt
            deletedAt
            totals {
              questions
              questionsCompleted
            }
            status
            invitation
            createdAt
            updatedAt
            individual {
              id
              companyId
              firstName
              contactNumber
              preferredName
              familyName
              title
              accountStatus
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.ListFullSurveyGroupsByCompanyQueryVariables,
  APITypes.ListFullSurveyGroupsByCompanyQuery
>
