import { render, staticRenderFns } from "./ReportConfigForm.vue?vue&type=template&id=074dad38&scoped=true"
import script from "./ReportConfigForm.vue?vue&type=script&lang=ts"
export * from "./ReportConfigForm.vue?vue&type=script&lang=ts"
import style0 from "./ReportConfigForm.vue?vue&type=style&index=0&id=074dad38&prod&lang=scss&scoped=scoped"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "074dad38",
  null
  
)

export default component.exports