import { getLineGraphOtherEvaluationFootnote } from './getLineGraphOtherEvaluationFootnote'
import { getLineGraphSelfEvaluationFootnote } from './getLineGraphSelfEvaluationFootnote'
import { $t } from '../../translations/index'
import { GraphsResultData } from '../../types/ResultSet'

export default function getLineGraphFootnote (graphData: GraphsResultData): string | undefined {
  const otherFootnote: string | undefined = getLineGraphOtherEvaluationFootnote(graphData)
  const selfFootnote: string | undefined = getLineGraphSelfEvaluationFootnote(graphData)

  let footnote: string | undefined
  if (selfFootnote && otherFootnote) {
    footnote = $t('shared.graphs.line.footnote.missingBothEvaluations') as string
  } else if (selfFootnote) {
    footnote = selfFootnote
  } else if (otherFootnote) {
    footnote = otherFootnote
  }

  if (!footnote) {
    return undefined
  }

  return footnote
}
