<template>
  <div
    v-if="report && report.configuration"
    class="report-config-form grow d-flex flex-column"
  >
    <div class="grow d-flex align-stretch flex-nowrap pb-2">
      <v-col class="form--left-col d-flex pt-6">
        <v-row class="flex-column" justify="start">
          <div class="primary-header text-center mb-2">
            {{ $t('modal.report.config.general.optionalPagesTitle') }}
          </div>
          <v-col class="shrink">
            <platform-switch
              hide-details
              compact-label
              v-model="showDiversity"
              v-tooltip="showDiversity ? 'Disable to hide the Diversity page from the report' : 'Enable to show the Diversity page in the report'"
              style="max-width: 200px;"
              class="mx-auto"
              label="Diversity Page"
            />
          </v-col>
          <v-col class="shrink">
            <platform-switch
              hide-details
              compact-label
              v-model="showTenure"
              v-tooltip="showTenure ? 'Disable to hide the Board tenure page from the report' : 'Enable to show the Board tenure page in the report'"
              style="max-width: 200px;"
              class="mx-auto"
              label="Board Tenure Page"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col class="form--right-col d-flex pt-6">
        <v-row class="flex-column" justify="start">
          <div class="primary-header text-center mb-2">
            {{ $t('modal.report.config.general.outputConfigTitle') }}
          </div>
          <v-col class="shrink">
            <platform-select-field
              hide-details
              v-model="pageSize"
              v-tooltip="$t('modal.report.config.pageSize.tooltip')"
              style="max-width: 175px;"
              class="mx-auto"
              :items="availableReportPageSizes"
              :label="$t('modal.report.config.pageSize.label')"
            />
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script lang="ts">
import { SelectItem } from '@/helpers/forms'
import { ReportConfiguration, ReportPageSize } from '@betterboards/shared/types/Report'
import Vue, { PropType } from 'vue'
import { PlatformReport } from '@/types/Report'
import { ResultSet } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'ReportConfigForm',
  components: {
    //
  },
  props: {
    value: { type: Object as PropType<PlatformReport>, required: false }
  },
  data: () => ({
    //
  }),
  computed: {
    resultSet (): ResultSet | null {
      return this.$store.state.Analysis.resultSet
    },
    availableReportPageSizes (): SelectItem[] {
      return Object.values(ReportPageSize).map((pageSize: string): SelectItem => ({
        value: pageSize,
        text: this.$t(`modal.report.config.pageSize.${pageSize}`) as string
      }))
    },
    report: {
      set (val: PlatformReport): void {
        this.$emit('input', val)
      },
      get (): PlatformReport {
        return this.value
      }
    },
    showDiversity: {
      set (showDiversity: boolean): void {
        this.updateReportConfiguration({
          showDiversity
        })
      },
      get (): boolean {
        return !!this.report.configuration?.showDiversity
      }
    },
    showTenure: {
      set (showTenure: boolean): void {
        this.updateReportConfiguration({
          showTenure
        })
      },
      get (): boolean {
        return !!this.report.configuration?.showTenure
      }
    },
    pageSize: {
      set (pageSize: ReportPageSize): void {
        this.updateReportConfiguration({
          pageSize
        })
      },
      get (): ReportPageSize {
        if (!this.report.configuration?.pageSize) {
          return ReportPageSize.A4
        }
        return this.report.configuration?.pageSize
      }
    }
  },
  methods: {
    updateReport (reportData: Partial<PlatformReport>): void {
      this.report = {
        ...this.report,
        ...reportData
      }
    },
    updateReportConfiguration (configData: Partial<ReportConfiguration>): void {
      this.updateReport({
        configuration: {
          ...this.report.configuration,
          ...configData
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.report-config-form {
  min-height: 250px;

  $col-padding: 30px;
  $border-width: 2px;
  .form--left-col {
    border-right: $border-width solid var(--v-grey-lighten3);
    padding-right: #{$col-padding - $border-width};
  }
  .form--right-col {
    padding-left: $col-padding;
  }
}
</style>
