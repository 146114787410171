<template>
  <v-row
    v-if="displayedMembersCount"
    no-gutters
    class="flex-nowrap py-2"
    align="center"
    :justify="justify"
    style="min-width: 48px; min-height: 40px;"
    :style="{ width: `${displayedMembersCount * 32}px` }"
  >
    <v-col
      v-for="(individual, index) in visibleIndividuals"
      :key="`${individual.individualId}-${index}`"
      class="shrink"
      :style="{ width: '32px' }"
    >
      <template v-if="individual.overflowItem">
        <strong class="rounded-circle grey lighten-2 grey--text text--darken-1 text-center d-block" style="width: 40px; height: 40px; line-height: 40px;">
          +{{ individual.count }}
        </strong>
      </template>
      <v-skeleton-loader
        v-else-if="individual.loading"
        class="rounded-circle"
        type="image"
        :width="40"
        :height="40"
      />
      <platform-avatar
        v-else
        entity-type="individual"
        :style="{ opacity: individual.incomplete ? 0.45 : undefined }"
        :size="40"
        :entity="{ ...individual, id: individual.individualId || individual.id, companyId }"
        :pad-bottom="false"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import Vue from 'vue'

export default Vue.extend({
  components: {
    PlatformAvatar
  },
  props: {
    individuals: { type: Array, required: true },
    companyId: { type: String, required: true },
    justify: { type: String, default: 'end' },
    loading: { type: Boolean, default: false }
  },
  computed: {
    visibleIndividuals (): any[] {
      const items = this.individuals.slice(0, this.maxDisplayedMembers)
      if (this.loading) {
        // Push a few loading items to show temporary members list
        items.push(
          {
            loading: true
          },
          {
            loading: true
          },
          {
            loading: true
          }
        )
      }
      const overflowCount = this.individuals.length - this.maxDisplayedMembers
      if (overflowCount > 0) {
        items.push({
          overflowItem: true,
          count: overflowCount
        })
      }
      return items
    },
    maxDisplayedMembers (): number {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 4
        default:
          return 9
      }
    },
    displayedMembersCount (): number {
      return this.visibleIndividuals.length
    }
  }
})
</script>
