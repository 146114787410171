import { render, staticRenderFns } from "./TargetRuleForm.vue?vue&type=template&id=54d2673f"
import script from "./TargetRuleForm.vue?vue&type=script&lang=ts"
export * from "./TargetRuleForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports