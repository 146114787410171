<template>
  <div class="results-panels">
    <v-row v-if="reportContextButtonKey" justify="end" class="pb-2">
      <v-col class="shrink">
        <report-context-button
          :value="reportContextButtonKey"
          :section="section"
        />
      </v-col>
    </v-row>
    <v-expansion-panels multiple focusable color="primary">
      <v-expansion-panel
        v-for="(result, index) in resultsList"
        :key="`result-${index}`"
        ref="resultPanel"
        class="platform--expansion-panel mt-4 rounded"
      >
        <v-expansion-panel-header class="header px-4 py-1">
          <v-row align="center" justify="space-between" no-gutters>
            <v-col class="shrink">
              <platform-icon
                v-if="result.requiresReview"
                class="reviewed--icon mr-2"
                :color="!!result.reviewedAt ? 'secondary' : (result.completed ? 'primary' : 'text--darken-2 darken-2 grey')"
                :name="!!result.reviewedAt ? 'check-outline' : (result.completed ? 'progress' : 'no-progress-pencil')"
                :size="30"
              />
            </v-col>
            <v-col class="py-2" :class="{ shrink: !result.graphType && !(result.data && result.data.questionText)}">
              <v-row v-if="result.graphType" align="center" no-gutters>
                <platform-icon
                  :name="result.criteriaType === 'BoardMembers' ? GraphTypeIcons[GraphTypes.Appraisal] : GraphTypeIcons[result.graphType]"
                  :size="40"
                  color="highlight"
                  class="mr-2"
                />
                <span class="highlight--text text-no-wrap">
                {{ getFriendlyGraphTypeName(result) }}
              </span>
              </v-row>
              <div
                v-else-if="result.data && result.data.questionText"
                class="highlight--text"
              >
                {{ result.data.questionText }}
              </div>
              <platform-icon
                v-else
                :size="40"
                name="committee"
                color="highlight"
                class="mr-2"
              />
            </v-col>
            <v-col
              v-if="result.subTitle"
              class="d-flex align-center px-2"
              :class="{
                'flex-grow-0 justify-end': result.graphType || (result.data && result.data.questionText)
              }"
            >
            <span
              class="text-no-wrap white pl-2 py-2"
              :class="{
                'highlight--text': result.committee,
                'text-right primary--text': !result.committee
              }"
              :style="{
                'min-width': $vuetify.breakpoint.smAndUp ? '280px' : undefined
              }"
            >
              {{ result.subTitle }}
            </span>
              <div v-if="result.criteriaId && result.criteriaType === RepeatForOptions.BoardMembers" class="pl-2 py-1">
                <platform-avatar
                  class="rounded"
                  internal-loader
                  small-loader
                  entity-type="individual"
                  :entity="{
                    id: result.criteriaId,
                    companyId: platformSelectedCompanyId,
                    preferredName: result.criteria,
                  }"
                  :size="48"
                />
              </div>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <display-results-group
            :result-group="result"
            :report-section="reportSection"
            :section="section"
            :loading="loading"
            @updateReportSection="(payload) => $emit('updateReportSection', payload)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import ReportContextButton from '@/components/analysis/ReportContextButton.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { getFullName } from '@/helpers/individual'
import { RepeatForFriendlyNames, RepeatForOptions } from '@/helpers/questionnaire/questions'
import { getDefaultGraphConfig, getTip, hasLegend, hasTip } from '@/helpers/questionnaire/results'
import { ResultGroup } from '@/types/ResultSet'
import { GraphTypeIcons } from '@betterboards/graphs'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import Vue, { PropType } from 'vue'
import { Colors } from '@/helpers/theme'
import { PlatformSection } from '@betterboards/shared/types/Platform'
import DisplayResultsGroup from '@/components/analysis/tabs/DisplayResultGroup.vue'
import { GraphResult, ProgressSection, ResultSet, ResultType, SectionReviewItem, SectionReviewItemGroup } from '@betterboards/shared/types/ResultSet'
import { ReportContextItem } from '@/helpers/recommendations'

const GraphSortOrder = {
  [GraphTypes.Scale]: 1,
  [GraphTypes.Scatter]: 2,
  [GraphTypes.Line]: 3,
  [GraphTypes.Variance]: 4,
  [GraphTypes.Appraisal]: 5
}

export default Vue.extend({
  name: 'DisplayResultsPanel',
  props: {
    results: {
      type: Array as PropType<ResultType[]>
    },
    reportSection: {
      type: Object as PropType<any>,
      required: false
    },
    report: {
      type: Object as PropType<any>
    },
    section: {
      type: Object as PropType<PlatformSection>
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DisplayResultsGroup,
    ReportContextButton,
    PlatformAvatar
  },
  data: () => ({
    GraphTypes,
    GraphTypeIcons,
    RepeatForFriendlyNames,
    RepeatForOptions
  }),
  mounted (): void {
    if (this.$route.params.resultId) {
      this.toggleResultPanelOpen(this.$route.params.resultId, this.$route.params.criteriaId, true)
    }
  },
  computed: {
    resultSetSections (): ProgressSection[] {
      return this.$store.getters['Report/resultSetSections']
    },
    resultSetSection (): ProgressSection | undefined {
      return this.resultSetSections?.find((s) => s.id === this.section.id)
    },
    resultsList (): Array<ResultGroup> {
      return [...this.results]
        .sort((a, b) => {
          if (a.criteria === b.criteria && (a.graphType || b.graphType)) {
            if (!a.graphType) {
              // Sort graphs result (b) after responses result (a)
              return -1
            }
            if (!b.graphType) {
              // Sort graphs result (a) after responses result (b)
              return 1
            }
            // Sort graphs results by pre-defined order
            return GraphSortOrder[a.graphType!] - GraphSortOrder[b.graphType!]
          }
          if (a.criteria && b.criteria) {
            // Sort by criteria value (Individual name, Committee name, Role/Know-how Area, etc.) alphabetically
            return a.criteria.localeCompare(b.criteria)
          }
          if (a.graphType === b.graphType) {
            // Sort "vs External Data" graphs after their main counterpart
            if (a.criteriaId === 'external') {
              // Sort "vs External Data" result (a) after main result (b)
              return 1
            } else if (b.criteriaId === 'external') {
              // Sort "vs External Data" result (b) after main result (a)
              return -1
            }
          }
          // Leave in place
          return 0
        })
        .reduce((results: Array<ResultGroup>, r: ResultType) => {
          const lastResult: ResultGroup | undefined = results[results.length - 1] as ResultGroup
          const isCommitteeResult: boolean = !!r.committee
          const isScaleResult: boolean = r.graphType === GraphTypes.Scale
          let reviewItem: SectionReviewItem | undefined
          if (r.data.questionId) {
            const resultGroup = this.resultSetSection?.reviewItems?.find((g: SectionReviewItemGroup) => {
              return g.items.some((i) => i.link.resultId === r.data.questionId)
            })
            reviewItem = resultGroup?.items.find((i) => {
              if (i.link.resultId !== r.data.questionId) {
                return false
              }
              return !r.criteriaId || i.link.criteriaId === r.criteriaId
            })
          }
          if (lastResult?.__typename === 'ResultGroup') {
            // Check if last result is of the same type (Scale graph results/committee results), if so group
            const bothCommittee: boolean = isCommitteeResult && !!lastResult?.committee && lastResult.criteriaId === r.criteriaId
            const bothScale: boolean = isScaleResult && lastResult?.graphType === r.graphType && (lastResult?.groupId ? lastResult.groupId === r.groupId : true)
            if (bothCommittee || bothScale) {
              lastResult.results.push(r)
              return results
            }
          }
          if (isCommitteeResult) {
            results.push({
              __typename: 'ResultGroup',
              completed: reviewItem?.completed,
              reviewedAt: reviewItem?.reviewedAt,
              requiresReview: !!reviewItem?.requiresReview,
              results: [r],
              criteria: r.criteria ?? this.$t('shared.graphs.committees.generalStructureTitle'),
              criteriaId: r.criteriaId,
              committee: r.committee
            } as ResultGroup)
            return results
          }
          if (isScaleResult) {
            results.push({
              __typename: 'ResultGroup',
              graphType: r.graphType,
              results: [r],
              groupId: r.groupId,
              groupName: r.groupName
            } as ResultGroup)
            return results
          }
          results.push({
            __typename: 'ResultGroup',
            graphType: r.graphType,
            completed: reviewItem?.completed,
            reviewedAt: reviewItem?.reviewedAt,
            requiresReview: !!reviewItem?.requiresReview,
            results: [r],
            criteriaType: r.criteriaType,
            criteria: r.criteria,
            criteriaId: r.criteriaId,
            data: r.data,
            groupId: r.groupId
          } as ResultGroup)
          return results
        }, [])
        .map((result: ResultGroup) => {
          if (result.criteria) {
            if (RepeatForFriendlyNames[result.criteria] && result.criteriaId === 'external') {
              // Roles/Knowhow Areas vs External Boards
              result.subTitle = `${RepeatForFriendlyNames[result.criteria] as string} vs External Boards`
            } else {
              result.subTitle = RepeatForFriendlyNames[result.criteria] ?? result.criteria
            }
          } else {
            result.subTitle = result.groupName
          }
          if (result.criteriaId && result.criteriaType === RepeatForOptions.BoardMembers) {
            // Replace BM preferred name with full name for use by assessor
            const individual = this.report?.individuals?.find((i) => i.id === result.criteriaId)
            if (individual && individual.firstName) {
              result.subTitle = getFullName(individual)
            }
          }
          if (result.__typename === 'ResultGroup') {
            const scaleGraphs = result.results.filter((r) => r.graphType === GraphTypes.Scale)
            if (scaleGraphs.length > 0) {
              const axis: GraphResult = {
                type: 'graphs',
                graphType: GraphTypes.Scale,
                data: {},
                tickLabels: true
              } as any
              let firstScaleGraphIndex = result.results.findIndex((r) => r.graphType === GraphTypes.Scale)
              if (firstScaleGraphIndex === -1) {
                firstScaleGraphIndex = 0
              }
              if (scaleGraphs.length === 1) {
                // Insert axis after only scale graph
                result.results.splice(firstScaleGraphIndex, 0, axis)
              } else {
                // Insert axis at start
                result.results.splice(firstScaleGraphIndex, 0, axis)
                // Insert axis at end
                result.results.push(axis)
              }
            }
            result.results.forEach((r: ResultType) => {
              if (hasTip(r)) {
                result.tip = getTip(r.criteria as RepeatForOptions)
              }
              if (hasLegend(r) && !result.legendResult) {
                result.legendResult = r
              }
            })
          }
          return result
        })
    },
    isResponseAnalysis (): boolean {
      return this.resultsList.every((r: ResultGroup) => !r.graphType)
    },
    hasCommitteeResults (): boolean {
      return this.resultsList.some((r: ResultGroup) => r.committee)
    },
    platformSelectedCompany (): any | undefined {
      return this.$store.state.Company.selectedCompany
    },
    platformSelectedCompanyId (): string | undefined {
      return this.platformSelectedCompany?.id
    },
    companyConfiguration (): any | undefined {
      return this.platformSelectedCompany?.configuration
    },
    reportConfig (): any | undefined {
      const companyConfig = this.companyConfiguration
      if (!companyConfig) {
        return undefined
      }
      return typeof companyConfig.report === 'string' ? JSON.parse(companyConfig.report) : companyConfig.report
    },
    graphsConfig (): any {
      return this.reportConfig?.graphs
    },
    defaultGraphConfig (): any | undefined {
      return {
        scale: 1,
        comparison: !!this.comparisonResultSet,
        ...getDefaultGraphConfig(this.companyConfiguration)
      }
    },
    reportContextButtonKey (): ReportContextItem | undefined {
      if (this.hasCommitteeResults) {
        return ReportContextItem.CommitteesPage
      }
      if (this.isResponseAnalysis) {
        if (this.section?.slug === 'strength') {
          return ReportContextItem.IndividualStrengths
        }
        return ReportContextItem.KeyFindings
      }
      return undefined
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    }
  },
  methods: {
    toggleResultPanelOpen (resultId: string, criteriaId?: string, scrollTo: boolean = false): void {
      const index = this.resultsList.findIndex((resultGroup) => {
        if (resultGroup.data?.questionId) {
          return resultGroup.data.questionId === resultId && resultGroup.criteriaId === criteriaId
        }
        return resultGroup.results.some((r) => r.type === 'graphs' && r.data.questionId === resultId && r.criteriaId === criteriaId)
      })
      if (index === -1) {
        return
      }
      const panel: any = this.$refs.resultPanel?.[index]
      panel.toggle()
      if (scrollTo && panel.$el?.scrollIntoView) {
        setTimeout(() => {
          panel.$el.scrollIntoView()
        }, 500)
      }
    },
    getColor (color: string): string {
      switch (color) {
        case 'primary':
          if (this.platformSelectedCompany?.configuration?.primaryColor) {
            return this.platformSelectedCompany.configuration.primaryColor
          }
          return Colors.primary
        case 'secondary':
          if (this.platformSelectedCompany?.configuration?.secondaryColor) {
            return this.platformSelectedCompany.configuration.secondaryColor
          }
          return Colors.secondary
        default:
          return color
      }
    },
    getFriendlyGraphTypeName (result: ResultGroup): string | undefined {
      if (!result.graphType) {
        return undefined
      }
      const type = result.criteriaType === RepeatForOptions.BoardMembers ? GraphTypes.Appraisal : result.graphType
      const resultsList: GraphResult[] = result.results.filter<GraphResult>((r): r is GraphResult => r.type === 'graphs')
      const resultsCount = resultsList.filter(
        (r: GraphResult) => !r.tickLabels // Filter out Scale graph axis Results
      ).length
      return this.$tc(`analysis.graphs.${type}`, resultsCount) as string
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.results-panels {
  padding-bottom: 60px;
}
</style>
