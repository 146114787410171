import d3 from 'd3'
import { GroupSelection, SVGSelection } from '../types/d3'
import { CategoryGraphDataPoint, FontSizes, LineConfig } from '../types/Graph'
import { GraphConfig } from '../variants/Abstract'
import { drawDots } from './dots'

export function drawGraphLegend (
  config: GraphConfig,
  items: LineConfig[],
  parentGroup: GroupSelection | SVGSelection,
  xAxis?: d3.ScaleLinear<any, any>,
  yAxis?: d3.ScaleBand<string>,
  alternateLegendStyle: boolean = false
): void {
  const legendWidth = config.width * 0.80
  const padding = config.width * 0.05
  const midPoint = config.width / 2
  const colWidth = legendWidth / 2
  const lineHeight = config.scale * FontSizes.DataLabel * 1.1
  const legendHeight = Math.ceil(items.length / 2) * lineHeight
  // if (legendHeight > config.height) {
  //   legendHeight = config.margin.bottom
  // }
  const topYPos = config.scale * (config.margin?.top ?? 0)
  const y = config.height - legendHeight
  const group = parentGroup
    .append('g')
    .attr('id', 'legend')
    .attr('class', 'legend')
    .attr('transform', `translate(0, ${-topYPos})`)
  // .attr('transform', `translate(${x}, ${y})`)

  items.forEach((item, index) => {
    const label = item.label ?? (item.property ? `${item.property.charAt(0).toUpperCase()}${item.property.slice(1)}` : undefined)
    if (!label) {
      console.warn('Couldn\'t find a label for item in legend:', item)
      return
    }

    // Flow across 2 columns
    const newLine = index % 2 === 0
    const lineNumber = Math.floor(index / 2)
    const xVal = midPoint + (newLine ? -colWidth : padding)
    const yVal = y + lineNumber * lineHeight
    const itemGroup: GroupSelection = group.append('g')
      .attr('transform', `translate(${xVal}, ${yVal})`)
      .attr('class', 'legend--item')
    const data: CategoryGraphDataPoint[] = [{
      xVal: 0,
      yVal: 0,
      label
    }]

    const itemConfig: LineConfig = {
      ...item,
      alignLabel: 'right',
      strokeLabel: false,
      dataLabels: true
    }
    drawDots(config, data, itemGroup, itemConfig, xAxis, yAxis, alternateLegendStyle)
  })
  // wrapAxisText(group, colWidth, FontSizes.DataLabel, 0, true, '.legend--item')
}
