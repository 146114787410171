<template>
  <report-preview-page
    :title="sectionName"
    :page-margin="false"
  >
    <v-row no-gutters class="grow flex-column">
      <div class="page-margin section-introduction">
        <display-markdown
          v-if="committeeSummaryContent"
          :value="committeeSummaryContent"
        />
        <display-markdown
          v-if="previewContent"
          :value="previewContent"
        />
      </div>
      <v-row no-gutters class="page-margin">
        <display-graphs
          class="page-graphs"
          :flex="graphsFlexLayout"
          :result-group="resultsGroup"
          :config="graphConfigOverride"
          :legend-config="legendGraphConfigOverride"
        />
      </v-row>
    </v-row>
  </report-preview-page>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ReportResult } from '@/types/Report'
import { PlatformCompany } from '@betterboards/shared/types/Company'
import { GraphResult, ResponsesResult, ResultSet, ResultType, SectionResults } from '@betterboards/shared/types/ResultSet'
import ReportPreviewPage from '@/components/analysis/report-previews/partials/Page.vue'
import { PlatformReportSection } from '@betterboards/shared/types/Report'
import { ScoreCollectionGroup, ScoreCollectionInfo } from '@betterboards/shared/types/ReportBenchmark'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import { ResultGroup } from '@/types/ResultSet'
import { ScaleGraphConfig } from '@betterboards/graphs/variants/ScaleGraph'
import DisplayGraphs from '@/components/graphs/DisplayGraphs.vue'
import DisplayMarkdown from '@/components/DisplayMarkdown.vue'

export default Vue.extend({
  name: 'SectionIntroductionReportPreview',
  components: {
    DisplayMarkdown,
    DisplayGraphs,
    ReportPreviewPage
  },
  props: {
    result: { type: Object as PropType<ResponsesResult>, required: false },
    section: { type: Object as PropType<SectionResults>, required: true },
    reportSection: { type: Object as PropType<PlatformReportSection>, required: false },
    reportResult: { type: Object as PropType<ReportResult>, required: false },
    previewContent: { type: String as PropType<string | undefined>, required: false }
  },
  data: () => ({
    //
  }),
  computed: {
    selectedCompany (): PlatformCompany | undefined {
      return this.$store.state.Company.selectedCompany
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    },
    sectionName (): string {
      return this.$t('shared.graphs.committees.generalStructureTitle') as string
    },
    committeeSummaryContent (): string | undefined {
      if (!this.reportResult?.summary) {
        if (this.previewContent) {
          return undefined
        }
        // ReportSection has no introduction, display example content
        return this.$t('analysis.reportContent.recommendationsModal.exampleContent.sectionIntroduction') as string
      }
      return this.reportResult.summary
    },
    sectionScores (): ScoreCollectionInfo[] | undefined {
      const scoreGroups: ScoreCollectionGroup[] = this.$store.getters['Analysis/sectionScores']
      if (!scoreGroups) {
        return undefined
      }
      if (!this.section) {
        return undefined
      }
      return scoreGroups.find(
        (s: ScoreCollectionGroup) => s.sectionId === this.section.id
      )?.scores
    },
    graphConfigOverride (): Partial<ScaleGraphConfig> {
      return {
        xScale: 0.35,
        yScale: 0.35,
        scale: 0.5
      }
    },
    legendGraphConfigOverride (): Partial<ScaleGraphConfig> {
      return {
        xScale: 0.5,
        yScale: 0.5,
        scale: 0.4,
        legend: true
      }
    },
    graphResults (): GraphResult[] {
      const results = this.section.results.filter(
        (r: ResultType): r is GraphResult => {
          if (!r.committee || r.criteriaId) {
            // Not a Committee Structure Result
            return false
          }
          return r.type === 'graphs' && r.graphType === GraphTypes.Scale
        }
      )
      if (results.length === 0) {
        return []
      }
      const tickLabelResult: GraphResult = {
        ...results[0],
        data: {
          ...results[0].data,
          questionText: undefined,
          avg: undefined
        },
        tickLabels: true
      } as any

      if (results.length > 1) {
        results.unshift(tickLabelResult)
      }
      results.push(tickLabelResult)
      return results
    },
    graphsFlexLayout (): boolean {
      return this.graphResults.length > 8
    },
    resultsGroup (): ResultGroup {
      return {
        __typename: 'ResultGroup',
        results: this.graphResults,
        legendResult: this.legendResult
      }
    },
    legendResult (): GraphResult | undefined {
      const legendResult = this.graphResults.find((r) => !!r.data)
      if (!legendResult) {
        return undefined
      }
      return legendResult
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.page-margin {
  margin: 0 12px;
}

.section-introduction {
  color: var(--v-primary-base);
  font-size: 0.95em;
  line-height: 1.6;
  padding-bottom: 4px;
}

.page-graphs {
  color: var(--v-primary-base);
  flex-grow: 1;
  font-size: 0.9em;
  line-height: 1.15;
}
</style>
