<template>
  <v-container v-if="selectedCompany" class="px-6">
    <h1 class="primary-header sans-serif text-center mt-5">
      <platform-icon
        name="check-outline"
        color="primary"
        :size="80"
      />
      {{ $t('form.wizard.reviewCompany.companyCreated') }}
    </h1>
    <v-row class="flex-nowrap pt-1 pb-6" no-gutters justify="center" align="center">
      <v-col class="shrink pr-3">
        <platform-avatar
          entity-type="company"
          :size="96"
          :entity="selectedCompany"
        />
      </v-col>
      <v-col class="shrink">
        <h2 class="sans-serif text-left" style="white-space: nowrap">{{ selectedCompany.name }}</h2>
      </v-col>
    </v-row>
    <div class="mb-10">
      <h3 class="primary-header sans-serif text-center mb-2">
        {{ $t('form.wizard.reviewCompany.individualsTitle') }}
      </h3>
      <v-row justify="center" no-gutters>
        <v-col v-if="!companyIndividuals.length" class="text-center">
          <span class="text-body-2 grey--text text--darken-1">
            {{ $t('form.wizard.reviewCompany.noIndividuals') }}
          </span>
        </v-col>
        <v-col
          v-for="(individual, index) in companyIndividuals"
          :key="`${individual.id}-${index}`"
          class="shrink d-flex flex-column"
          style="flex-basis: 130px"
        >
          <platform-entity-card
            align="center"
            entity-type="individual"
            compact
            flat
            :inline-header="false"
            :entity="individual"
          />
        </v-col>
      </v-row>
    </div>
    <div v-if="!hideCommittees" class="px-8">
      <h3 class="primary-header sans-serif text-center mb-2">
        {{ $t('form.wizard.reviewCompany.committeesTitle') }}
      </h3>
      <v-row
        no-gutters
        class="grow"
        :justify="$vuetify.breakpoint.lgAndUp ? 'start' : 'center'"
      >
        <v-col v-if="!companyCommittees.length" class="text-center">
          <span class="text-body-2 grey--text text--darken-1">
            {{ $t('form.wizard.reviewCompany.noCommittees') }}
          </span>
        </v-col>
        <v-col
          v-for="(committee, index) in companyCommittees"
          :key="`${committee.id}-${index}`"
          :cols="12"
          :class="{
            'px-12': $vuetify.breakpoint.lgAndUp
          }"
        >
          <platform-entity-card
            align="start"
            compact
            row
            flat
            entity-type="committee"
            list-members
            hide-sub-title
            :entity="committee"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script lang="ts">
import { Company, CompanyTeamTypes, PackageItemTypes } from '@/API'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { PlatformPackage, PlatformPackageItem } from '@/types/Platform'
import { SurveyConfiguration } from '@/types/Survey'
import Vue, { PropType } from 'vue'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { PlatformCommittee } from '@betterboards/shared/types/Committee'

export default Vue.extend({
  name: 'ReviewCompany',
  components: {
    PlatformAvatar,
    PlatformEntityCard
  },
  props: {
    selectedPackage: { type: Object as PropType<PlatformPackage>, required: true },
    hideCommittees: { type: Boolean, default: false }
  },
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    companyCommittees (): PlatformCommittee[] {
      return this.$store.getters['Committee/list']
    },
    surveyConfiguration (): SurveyConfiguration | undefined {
      if (!this.selectedPackage?.packageItems.items.length) {
        return undefined
      }
      return this.selectedPackage?.packageItems.items.find(
        (pi): pi is PlatformPackageItem<SurveyConfiguration> => pi.type === PackageItemTypes.SurveyConfiguration
      )?.content
    },
    packageTargetTeams (): CompanyTeamTypes[] | undefined {
      return this.surveyConfiguration?.targetTeams
    },
    individualsList (): PlatformIndividual[] {
      return this.$store.getters['Individual/individualsList']
    },
    companyIndividuals (): PlatformIndividual[] {
      const packageTargetTeams = this.packageTargetTeams
      if (!packageTargetTeams?.length) {
        return this.individualsList
      }
      return this.individualsList.filter((i) => {
        return i.teams?.items?.some((ti) => packageTargetTeams.includes(ti.teamId))
      })
    }
  }
})
</script>
