<template>
  <platform-modal
    v-model="showModal"
    icon="user"
    :title="$t('form.individual.createNewIndividual')"
    :width="800"
    :saving="saving"
    :save-disabled="!isValid"
    @save="confirmCreateIndividual"
    @validate="$refs.form.validate()"
    @close="$emit('close')"
  >
    <individual-form
      v-if="formData"
      ref="form"
      :individual="formData"
      :saving="saving"
      @input="handleInput"
      @valid="(val) => isValid = val"
      @pendingFile="pendingFileUpload = true"
      @uploadedFile="pendingFileUpload = false"
    />
  </platform-modal>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { PartialIndividual } from '@betterboards/shared/types/Individual'
import PlatformModal from '@/components/PlatformModal.vue'
import IndividualForm from '@/components/individuals/IndividualForm.vue'
import { trimStringFields } from '@/helpers/validation'

export default Vue.extend({
  name: 'CreateIndividualModal',
  components: {
    PlatformModal,
    IndividualForm
  },
  data () {
    return {
      showModal: true,
      formData: <PartialIndividual>{
        id: '',
        email: '',
        preferredName: '',
        appointments: {
          Chair: 0,
          SID: 0,
          NonExecutive: 0,
          CommitteeChair: 0,
          Other: 0
        }
      },
      pendingFileUpload: false,
      saving: false,
      isValid: false
    }
  },
  watch: {
    pendingFileUpload (val, oldVal): void {
      if (oldVal && !val) {
        this.individualCreated()
      }
    }
  },
  computed: {
    ...mapState('Company', ['selectedCompany'])
  },
  methods: {
    ...mapActions('Individual', ['createIndividual']),
    handleInput (ev): void {
      this.formData = ev.data
    },
    confirmCreateIndividual (): void {
      if (this.saving) {
        return
      }
      const individualData = trimStringFields({
        ...this.formData,
        id: undefined,
        companyId: this.selectedCompany.id
      })
      this.saving = true
      this.createIndividual(individualData)
        .then((individual) => {
          if (individual && individual.id) {
            this.formData.id = individual.id
          }
          if (!this.pendingFileUpload) {
            this.individualCreated()
          }
        })
        .catch((err) => {
          console.error('Error creating individual', err)
          this.$toasted.error(this.$t('form.individual.createIndividualErrorMessage') as string)
        })
        .finally(() => {
          if (!this.pendingFileUpload) {
            this.saving = false
          }
        })
    },
    individualCreated (): void {
      this.$emit('created', this.formData)
      this.$toasted.success(this.$t('form.individual.individualCreatedSuccessMessage') as string)
    }
  }
})
</script>
