import { GetQuestionnaireQuery, Questionnaire } from '@/API'
import { ignoreParentCompanyError } from '@/helpers/company'
import { Module } from 'vuex'
import Vue from 'vue'
import API from '@/services/API'
import { questionnairesByCompanyId } from '@/graphql/queries'
import {
  deleteQuestionnaire,
  updateQuestionnaire
} from '@/graphql/mutations'
import {
  onCreateQuestionnaire,
  onDeleteQuestionnaire,
  onUpdateQuestionnaire
} from '@/graphql/subscriptions'
import { GraphQLResult } from '@aws-amplify/api-graphql/src/types/index'
import { getQuestionnaireObject } from '@/graphql/custom/getQuestionnaireObject'
import { TrackedEvent } from '@/plugins/analytics'
import { NewQuestionnaire } from '@betterboards/shared/types/Questionnaire'
import { PlatformQuestionnaire } from '@betterboards/shared/types/Platform'

interface QuestionnaireStore {
  list: PlatformQuestionnaire[]
  listNextToken?: string
  listCompanyId?: string // Used for identifying the company the list is currently for
  startedFetchingList: boolean
  selected: PlatformQuestionnaire | undefined
  amplifySubscriptions: any[]
}

const defaultState = (): QuestionnaireStore => ({
  list: [],
  listNextToken: undefined,
  listCompanyId: undefined,
  startedFetchingList: false,
  selected: undefined,
  amplifySubscriptions: []
})

const QuestionnaireState: Module<QuestionnaireStore, any> = {
  namespaced: true,
  state: defaultState(),
  getters: {
    fetchedWholeList (state): boolean {
      return state.startedFetchingList && !state.listNextToken
    }
  },
  mutations: {
    setList: (state, items: PlatformQuestionnaire[]) => (state.list = items),
    setListNextToken: (state, nextToken: string) => {
      state.listNextToken = nextToken
    },
    setStartedFetchingList (state, val) {
      state.startedFetchingList = val
    },
    resetList (state) {
      state.list.splice(0, state.list.length)
      state.listNextToken = undefined
      state.startedFetchingList = false
    },
    setListCompany: (state, companyId: string) => {
      state.listCompanyId = companyId
    },
    addToList: (state, items: PlatformQuestionnaire[]) => (items.forEach((item) => state.list.push(item))),
    removeFromList: (state, item: Questionnaire) => {
      const index = state.list.findIndex((lookup) => lookup.id === item.id)
      if (index === -1) {
        return
      }
      state.list.splice(index, 1)
    },
    updateListItem: (state, item: PlatformQuestionnaire) => {
      const index = state.list.findIndex((lookup) => lookup.id === item.id)
      if (item.deletedAt) {
        if (index === -1) {
          return
        }
        state.list.splice(index, 1)
        return
      }
      if (index === -1) {
        state.list.push(item)
        return
      }
      state.list.splice(index, 1, item)
    },
    setSelectedQuestionnaire: function (state, item: PlatformQuestionnaire) {
      state.selected = item
      if (!state.selected.sections?.items) {
        return
      }
      if (state.selected.variants && typeof state.selected.variants === 'string') {
        state.selected.variants = JSON.parse(state.selected.variants)
      }
      if (state.selected.customContent && typeof state.selected.customContent === 'string') {
        state.selected.customContent = JSON.parse(state.selected.customContent)
      }
      state.selected.sections.items = state.selected.sections.items.map((section) => {
        if (!section?.blocks?.items) {
          return section
        }

        section.blocks.items = section.blocks.items.map(block => {
          if (!block?.data) {
            return block
          }

          if (typeof block.data === 'string') {
            block.data = JSON.parse(block.data)
          }
          return block
        })

        return section
      })
    },
    teardownSubscriptions (state): void {
      state.amplifySubscriptions.forEach((sub) => sub.unsubscribe())
      state.amplifySubscriptions.splice(0, state.amplifySubscriptions.length)
    }
  },
  actions: {
    async setupSubscriptions ({ state, rootState, commit }) {
      state.amplifySubscriptions = []
      let sub: any
      sub = API
        .graphql({
          query: onCreateQuestionnaire,
          variables: {
            companyId: rootState.Company.selectedCompany.id
          }
        })
        .subscribe({
          next: ({ data }) => commit('addToList', [data.onCreateQuestionnaire])
        })
      state.amplifySubscriptions.push(sub)

      sub = API.graphql({
        query: onUpdateQuestionnaire,
        variables: {
          companyId: rootState.Company.selectedCompany.id
        }
      }).subscribe({
        next: ({ data }) => commit('updateListItem', data.onUpdateQuestionnaire)
      })
      state.amplifySubscriptions.push(sub)

      sub = API.graphql({
        query: onDeleteQuestionnaire,
        variables: {
          companyId: rootState.Company.selectedCompany.id
        }
      }).subscribe({
        next: ({ data }) => commit('removeFromList', data.onDeleteQuestionnaire)
      })
      state.amplifySubscriptions.push(sub)
    },
    async loadList ({ commit, state, rootState }, fresh?: boolean): Promise<any> {
      const companyId = rootState.Company.selectedCompany?.id
      const response = await API.graphql({
        query: questionnairesByCompanyId,
        variables: {
          nextToken: fresh ? undefined : state.listNextToken,
          companyId
        }
      })
      commit('setListCompany', companyId)
      commit('setStartedFetchingList', true)
      const data: Questionnaire[] = response.data.questionnairesByCompanyId?.items as unknown[] as Questionnaire[]
      if (fresh) {
        commit('setList', [])
      }
      const questionnaires = data?.sort((a, b) => b.updatedAt.localeCompare(a.updatedAt))
      if (questionnaires) {
        questionnaires.forEach((questionnaire: Questionnaire) => commit('updateListItem', questionnaire))
      }
      commit('setListNextToken', response.data.questionnairesByCompanyId.nextToken)
      return response
    },
    deleteQuestionnaire ({ commit }, questionnaire) {
      API.graphql({
        query: deleteQuestionnaire,
        variables: {
          input: {
            id: questionnaire.id
          }
        }
      })
        .then(() => this._vm.$analytics({ name: TrackedEvent.QuestionnaireDelete, attrs: { questionnaireId: questionnaire.id } }))
        .catch((err) => {
          if (!ignoreParentCompanyError(err)) {
            console.error('error deleting questionnaire', err)
            Vue.toasted.error('There was an error deleting the questionnaire')
          }
        })
    },
    async fetchQuestionnaireById ({ commit }, questionnaireId: string) {
      const response = await API.graphql({
        query: getQuestionnaireObject,
        variables: {
          questionnaireId: questionnaireId
        }
      }) as GraphQLResult<GetQuestionnaireQuery>
      commit('updateListItem', response.data?.getQuestionnaire)
      return response.data?.getQuestionnaire
    },
    async selectQuestionnaire ({ commit, state, dispatch }, questionnaire: Questionnaire) {
      await dispatch('fetchQuestionnaireById', questionnaire.id)
      const freshQuestionnaire = state.list.find((item) => item.id === questionnaire.id)
      commit('setSelectedQuestionnaire', freshQuestionnaire)
    },
    async updateQuestionnaire ({ commit, rootState }, questionnaire: Questionnaire | NewQuestionnaire) {
      if (!questionnaire.id) {
        throw new Error('Unable to update Questionnaire without an ID.')
      }

      const customContent: string | null = questionnaire.customContent ? JSON.stringify(questionnaire.customContent) : null
      try {
        await API.graphql({
          query: updateQuestionnaire,
          variables: {
            input: {
              id: questionnaire.id,
              name: questionnaire.name,
              variants: questionnaire.variants,
              customContent
            }
          }
        })
      } catch (err) {
        if (!ignoreParentCompanyError(err)) {
          console.error(
            'Failed to update Questionnaire',
            JSON.stringify(err, null, 2)
          )
          throw err
        }
      }
      // this._vm.$analytics({ name: TrackedEvent.QuestionnaireCreate, attrs: { questionnaireId: questionnaire.id } })
    }
  }
}

export default QuestionnaireState
