import {
  Block,
  CognitoIdentity,
  Company,
  CompanyPackage,
  CompanyUser,
  Package,
  PackageItem,
  QuestionnaireVariant,
  Section,
  Survey,
  Totals
} from '@/API'
import { QuestionBlockData } from '@/types/Question'
import {
  PlatformQuestionnaire,
  QuestionnaireVariantCode
} from '@betterboards/shared/types/Platform'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'

export { QuestionnaireVariantCode }

export interface PlatformImageConfig {
  id: string
  objectType: string
  companyId: string
}

export enum PlatformEntityTypes {
  User = 'user',
  Individual = 'individual',
  Company = 'company',
  CompanyUser = 'companyUser',
  Committee = 'committee',
  Questionnaire = 'questionnaire',
  SurveyGroup = 'surveyGroup',
  Section = 'section',
  Block = 'block',
  Result = 'result', // Not technically an entity but a datatype (objects in the ResultSetGenerator response)
  Package = 'package',
  Report = 'report',
  ReportDocument = 'reportDocument'
}

export enum PlatformEntityStatuses {
  Versions = 'versions', // Represents the "editions" of a Questionnaire, linking this Company's Questionnaires on Package ID
  Team = 'team', // Represents a Team an Individual belongs to
  Tag = 'tag', // A generic string tag on an entity record
}

export interface EntityStatus {
  status: PlatformEntityStatuses
  text?: string
  value: number | string
  hideValue?: boolean
  tooltip?: string
  icon?: string
  clickEvent?: string // Name of the event to emit from the component if this status is clicked.
  clickTooltip?: string // Only shown if a listener is defined for this status's clickEvent
  primary?: boolean // Use Primary style
  secondary?: boolean // Use Secondary style
  chip?: boolean // Display as chip
}

export const DefaultAddress = {
  line1: null,
  line2: null,
  city: null,
  region: null,
  postalCode: null,
  country: null
}

export const DefaultUser = (): any => ({
  cognitoId: null,
  id: null,
  displayName: null,
  email: null,
  companies: {
    items: []
  }
})

export interface PlatformSurvey extends Omit<Partial<Survey>, 'individual' | 'totals' | 'response' | 'questionnaire' | 'variantCode'> {
  individual: PlatformIndividual
  groupId: string
  totals: Omit<Totals, '__typename'>
  response: any[]
  questionnaire: PlatformQuestionnaire
  variantCode: QuestionnaireVariantCode
}

/**
 * Used only to typehint a Survey object returned from the API before it has been validated to contain a Questionnaire
 */
export interface PlatformSurveyNoQuestionnaire extends Omit<PlatformSurvey, 'questionnaire'> {
  questionnaire: PlatformQuestionnaire | null
}

export interface PlatformBlock<DataType = QuestionBlockData> extends Omit<Block, 'data' | 'variantCode'> {
  data: DataType
  variantCode: QuestionnaireVariantCode
}

/** Partial version of PlatformBlock for usage in forms referring to the data which is still being constructed. */
export interface PartialBlock<T = undefined>
  extends Omit<PlatformBlock<T>, '__typename' | 'displayOrder' | 'createdAt' | 'updatedAt' | 'variantCode'> {
  variantCode: QuestionnaireVariantCode
}

/** Partial version of PlatformBlock for usage in forms referring to the data which is still being constructed. */
export interface PartialSection
  extends Omit<PlatformSection, '__typename' | 'createdAt' | 'updatedAt' | 'variantCode' | 'displayOrder' | 'name'> {
  name: string | null
  displayOrder: number | null
  variantCode?: QuestionnaireVariantCode
}

export interface PlatformSection extends Omit<Section, 'blocks' | 'variantCode'> {
  blocks: {
    items: PlatformBlock[]
  }
  variantCode: QuestionnaireVariantCode
}

export interface PlatformQuestionnaireVariant extends Omit<QuestionnaireVariant, '__typename' | 'variantCode'> {
  variantCode: QuestionnaireVariantCode
}

export interface PlatformCompanyPackage extends Omit<CompanyPackage, 'package'> {
  package: PlatformPackage
}

export interface PlatformUser extends Omit<CognitoIdentity, 'companies'> {
  companies: {
    items: PlatformCompanyUser[]
  }
}

export interface PlatformCompanyUser extends Omit<CompanyUser, 'company' | 'user'> {
  company: Company
  user: PlatformUser
}

export interface PlatformPackage extends Omit<Package, 'variants' | 'packageItems'> {
  variants: QuestionnaireVariantCode[]
  packageItems: {
    items: PlatformPackageItem[]
  }
}

export interface PlatformPackageItem<C = string | any> extends Omit<PackageItem, 'content'> {
  content: C
}
