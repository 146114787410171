<template>
  <v-dialog
    v-model="showModal"
    :persistent="persistent"
    :width="width"
    :content-class="dialogContentClass"
    :scrollable="false"
  >
    <v-card data-test-target="platformModal" class="d-flex flex-column overflow-y-hidden">
      <v-card-title
        v-if="title"
        class="headline white lighten-2"
        :class="{
          'mb-4': headerPadding
        }"
      >
        <v-row align="center" no-gutters class="min-width-0">
          <v-col class="shrink pr-2">
            <platform-icon
              v-if="icon"
              :reverse="iconReversed"
              :color="warning ? 'warning' : 'secondary'"
              :name="icon"
              :size="36"
            />
          </v-col>
          <v-col class="grow-full min-width-0">
            <h4 class="modal--title primary--text sans-serif text-no-wrap overflow-ellipses" v-tooltip="title">
              {{ title }}
            </h4>
          </v-col>
          <v-col v-if="closeButton" class="shrink">
            <platform-icon
              name="close"
              :size="28"
              @click="showModal = false"
            />
          </v-col>
          <slot name="header-append" />
        </v-row>
      </v-card-title>

      <v-card-text :class="contentCardClass">
        <slot name="default" />
      </v-card-text>

      <v-divider />

      <v-card-actions v-if="hasActions">
        <platform-button
          v-if="$listeners.delete"
          @click="(evt) => $emit('delete', evt)"
          warning
          icon="delete"
        >
          {{ $t('modal.shared.delete') }}
        </platform-button>

        <slot name="dangerActions" />

        <v-spacer />

        <slot name="secondaryActions" />

        <platform-button
          v-if="cancel"
          secondary
          :disabled="loading || saving"
          :loading="loading"
          @click="showModal = false"
        >
          {{ $t('modal.shared.cancel') }}
        </platform-button>

        <slot name="actions" />

        <platform-button
          v-if="$listeners.confirm"
          primary
          :loading="saving"
          :disabled="confirmDisabled"
          @click="(evt) => $emit('confirm', evt)"
          @disabledClick="(evt) => $emit('validate', evt)"
        >
          {{ $t('modal.shared.confirm') }}
        </platform-button>
        <platform-button
          v-if="$listeners.save"
          primary
          :loading="saving"
          :disabled="saveDisabled"
          @click="(evt) => $emit('save', evt)"
          @disabledClick="(evt) => $emit('validate', evt)"
        >
          {{ $t('modal.shared.save') }}
        </platform-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'PlatformModal',
  props: {
    value: { type: Boolean, required: true },
    title: { type: String, required: false },
    icon: { type: String, required: false },
    iconReversed: { type: Boolean, required: false },
    width: { type: Number, required: false },
    contentClass: { type: String, required: false },
    contentCardPadded: { type: Boolean, default: true },
    warning: { type: Boolean, required: false },
    cancel: { type: Boolean, default: true },
    closeButton: { type: Boolean, default: false },
    persistent: { type: Boolean, default: true },
    saving: { type: Boolean, required: false },
    loading: { type: Boolean, required: false },
    confirmDisabled: { type: Boolean, required: false },
    saveDisabled: { type: Boolean, required: false },
    scrollable: { type: Boolean, default: true },
    headerPadding: { type: Boolean, default: true }
  },
  data () {
    return {
      timerId: null,
      formData: null
    }
  },
  computed: {
    showModal: {
      set (val: boolean) {
        this.$emit('input', val)
        this.$emit(val ? 'open' : 'close')
      },
      get (): boolean {
        return this.value
      }
    },
    hasActions (): boolean {
      return !!(this.cancel || this.$listeners.delete || this.$listeners.confirm || this.$listeners.save || this.$slots.actions || this.$slots.dangerActions)
    },
    dialogContentClass (): string {
      const classes: string[] = [
        'flex-column',
        'overflow-y-hidden',
        this.contentClass
      ]
      if (this.showModal) {
        classes.push('d-flex')
      }
      return classes.filter((c) => !!c).join(' ')
    },
    contentCardClass (): any {
      return {
        'overflow-scroll-stable-both': this.scrollable,
        'overflow-y-hidden': !this.scrollable,
        'overflow-y-auto': this.scrollable,
        'pa-0': !this.contentCardPadded
      }
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.modal--title {
  word-break: normal;
}
</style>
