import { CompanyStatsCollection } from './index'
import { $t } from '../../translations/index'

export default function getStatisticCategoryName (
  collection: CompanyStatsCollection,
  category: string
): string {
  switch (collection) {
    case CompanyStatsCollection.Country:
      return $t(`global.countries.${category}`) as string
    case CompanyStatsCollection.Sector:
      return $t(`universe.sectorOptions.${category}`) as string
  }
  return category
}
