
/**
 * lightenColor - Lightens a color by a decimal percentage, where 1 is full lightness, -1 is full darkness.
 */
export function lightenColor (hexCode: string, lightenPct: number): string {
  const lightenAmount = 1 + lightenPct

  let {
    r,
    g,
    b
  } = hexToRgb(hexCode)

  r = Math.round(r * lightenAmount)
  g = Math.round(g * lightenAmount)
  b = Math.round(b * lightenAmount)

  r = r > 255 ? 255 : r
  g = g > 255 ? 255 : g
  b = b > 255 ? 255 : b

  const rr = r.toString(16).length === 1 ? `0${r.toString(16)}` : r.toString(16)
  const gg = g.toString(16).length === 1 ? `0${g.toString(16)}` : g.toString(16)
  const bb = b.toString(16).length === 1 ? `0${b.toString(16)}` : b.toString(16)

  return `${hexCode.startsWith('#') ? '#' : ''}${rr}${gg}${bb}`
}

/**
 * getColorTransparent - Takes a hex code and a transparency amount and returns an rgba() string
 */
export function getColorTransparent (hexCode: string, alpha: number): string {
  const {
    r,
    g,
    b
  } = hexToRgb(hexCode)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

/**
 * hexToRgb - Converts a hex code to an object with RGB values
 */
function hexToRgb (hexCode: string): { r: number, g: number, b: number } {
  if (hexCode.startsWith('#')) {
    hexCode = hexCode.slice(1)
  }

  let r = parseInt(hexCode.substring(0, 2), 16)
  let g = parseInt(hexCode.substring(2, 4), 16)
  let b = parseInt(hexCode.substring(4, 6), 16)

  r = r < 255 ? r : 255
  g = g < 255 ? g : 255
  b = b < 255 ? b : 255

  return {
    r,
    g,
    b
  }
}
