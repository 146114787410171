<template>
  <v-row class="flex-column flex-nowrap overflow-y-hidden" no-gutters>
    <confirmation-modal
      v-if="confirmingIndividualResetPassword"
      force-icon="dispatch"
      entity-type="individual"
      :force-title="$t('modal.resendQuestionnaireInvite.title')"
      :message="$t('modal.resendQuestionnaireInvite.confirmationMessage', [confirmingIndividualResetPassword.preferredName])"
      :entity="confirmingIndividualResetPassword"
      :loading="pendingIndividualResetPassword || pendingRefresh"
      @cancel="confirmingIndividualResetPassword = null"
      @confirm="confirmIndividualResetPassword"
    >
      <span>
        {{ $t('modal.resendQuestionnaireInvite.newPasswordTip') }}
      </span>
      <span
        v-if="confirmingIndividualResetPassword.completionStatus === CompletionStatus.AccountDisabledPasswordReset"
        class="warning--text text-caption pt-3"
      >
        {{ $t('modal.resendQuestionnaireInvite.passwordAlreadyResetTip') }}
      </span>
    </confirmation-modal>
    <v-col class="min-height-0 d-flex overflow-y-auto">
      <v-container>
        <v-card class="mb-8" v-if="company || questionnaireName">
          <v-card-text>
            <v-row no-gutters align="start">
              <v-col class="grow">
                <h1 class="highlight--text mb-2" v-if="company">{{ company.name }}</h1>
                <h2 v-if="questionnaireName">{{ $t('page.viewSurvey.responsesStatus.project') }}: {{ questionnaireName }}</h2>
              </v-col>
              <v-col class="shrink">
                <v-row no-gutters justify="end" align="end" :class="{ 'flex-nowrap': $vuetify.breakpoint.lgAndUp }">
                  <v-col
                    :order="1"
                    :order-lg="2"
                    class="d-flex justify-end pl-5 pb-1"
                  >
                    <platform-spinner
                      v-if="pendingRefresh"
                      :size="32"
                      :width="2"
                    />
                    <platform-icon
                      v-else
                      name="refresh"
                      :size="32"
                      @click="refresh"
                    />
                  </v-col>
                  <v-col
                    :order="2"
                    :order-lg="1"
                    class="pt-1"
                  >
                    <v-row no-gutters>
                      <report-document-actions
                        icon="document"
                        :report-documents="reportDocuments"
                        :available-variants="availableVariants"
                        :selected-variant="selectedVariant"
                        @queue="queueResponseStatusReport"
                        @download="downloadResponseStatusReport"
                      />
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <platform-spinner v-else class="mt-3 mx-auto" />
        <v-col>
          <v-row
            v-for="(individual, index) in individuals"
            :key="index"
          >
            <platform-entity-card
              row
              hide-title
              align="start"
              entity-type="individual"
              :entity="individual"
            >
              <v-row no-gutters align="center">
                <v-col>
                  <v-row no-gutters align="center">
                    <v-col class="grow">
                      <v-row no-gutters justify="center" class="flex-column">
                        <v-row no-gutters align="center">
                          <strong class="grey--text text--darken-4 text--compact">
                            {{ individual.fullName }}
                          </strong>
                          <span class="text-caption grey--text text--darken-2 pl-1">
                            - {{ individual.roleName }}
                          </span>
                        </v-row>
                        <span class="text-body-2 grey--text text--darken-1 pb-1">
                          {{ $t('page.viewSurvey.responsesStatus.progress', [individual.preferredName]) }}
                        </span>
                      </v-row>
                    </v-col>
                    <v-col
                      v-if="individual.completionStatus === CompletionStatus.AccountNotEnabled || individual.completionStatus === CompletionStatus.AccountDisabledPasswordReset"
                      class="shrink pb-1"
                    >
                      <platform-button
                        v-tooltip:top="$t('modal.resendQuestionnaireInvite.resendInviteTooltip')"
                        secondary
                        compact
                        default-case
                        icon="dispatch"
                        :icon-size="20"
                        @click="triggerConfirmResetPasswordModal(individual)"
                      >
                        {{ $t('modal.resendQuestionnaireInvite.resendInviteAction') }}
                      </platform-button>
                    </v-col>
                  </v-row>
                  <v-progress-linear
                    rounded
                    class="mb-1 user-progress"
                    :value="individual.displayCompletion ? individual.totals.progress : 0"
                    :height="20"
                  >
                    <span
                      v-if="individual.displayCompletion || fetchFailed"
                      style="font-size: 10px; color: white;"
                    >
                      {{ individual.displayCompletion || '0%' }}
                    </span>
                    <platform-spinner
                      v-else
                      color="grey"
                      :size="12"
                      :width="2"
                    />
                  </v-progress-linear>
                </v-col>
                <v-col class="shrink pl-3 pr-2">
                  <div
                    v-tooltip:bottom="individual.displayCompletionStatus"
                    style="width: 30px; height: 30px; position: relative"
                  >
                    <platform-icon
                      name="tablet"
                      :color="individual.completionStatus === CompletionStatus.InProgress || individual.completionStatus === CompletionStatus.Completed ? 'primary' : 'grey darken-1'"
                      style="position: absolute; top: 0; left: 0; transition: color 150ms;"
                      :size="36"
                    />
                    <platform-icon
                      v-if="individual.completionStatus === CompletionStatus.Completed || individual.completionStatus === CompletionStatus.InProgress"
                      style="position: absolute; top: 8px; left: 10px; transition: color 150ms;"
                      :name="individual.completionStatus === CompletionStatus.Completed ? 'check-circle' : 'text'"
                      :color="individual.completionStatus === CompletionStatus.Completed ? 'secondary' : 'grey darken-3'"
                      :size="16"
                    />
                    <div v-else style="position: absolute; top: 3px; left: 10px; transition: color 150ms;">
                      <platform-icon
                        v-if="individual.completionStatus === CompletionStatus.AccountNotEnabled || individual.completionStatus === CompletionStatus.AccountDisabledPasswordReset"
                        name="key"
                        :color="individual.completionStatus === CompletionStatus.AccountDisabledPasswordReset ? 'primary' : 'grey darken-1'"
                        :size="16"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
            </platform-entity-card>
          </v-row>
        </v-col>
      </v-container>
    </v-col>
    <v-col class="shrink">
      <v-toolbar :height="60">
        <v-container>
          <v-row justify="end">
            <v-col class="shrink">
              <platform-button
                primary
                :to="{ name: 'ResponseStatusList' }"
              >
                {{ $t('page.viewSurvey.responsesStatus.back') }}
              </platform-button>
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import API from '@/services/API'
import Vue, { PropType } from 'vue'
import dayjs from 'dayjs'
import { QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import { PartialReportDocument, PlatformReportDocument } from '@betterboards/shared/types/Report'
import sortResultSetIndividuals from '@betterboards/shared/helpers/entities/Individual/sortResultSetIndividuals'
import { OnUpdateReportDocumentSubscription, ReportTemplate } from '@/API'
import { PlatformSurveyGroup } from '@/types/SurveyGroup'
import { onUpdateReportDocument } from '@/graphql/subscriptions'
import { getFullName } from '@/helpers/individual'
import { calculateCompletion, resendQuestionnaireInvite } from '@/helpers/survey'
import { getSurveyGroupById } from '@/helpers/surveyGroup'
import { downloadReportDocument, getVariantLatestReportDocument, listReportDocuments, queueReportDocument } from '@/helpers/reportDocument'
import { NullVariantCodeValue } from '@/helpers/questionnaire'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { ShortDateDisplayFormat } from '@/components/PlatformDateDisplay.vue'
import ReportDocumentActions from '@/components/report/reportDocument/ReportDocumentActions.vue'
import { UserAccountStatus } from '@betterboards/shared/types/API'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import { PlatformIndividual } from '@betterboards/shared/types/Individual'
import { ResultsIndividual } from '@betterboards/shared/types/ResultSet'
import { GraphQLOptionsV6 } from '@aws-amplify/api-graphql'

enum CompletionStatus {
  Completed = 'Completed',
  InProgress = 'InProgress',
  NotStarted = 'NotStarted',
  AccountNotEnabled = 'AccountNotEnabled', // User hasn't enabled their account yet
  AccountEnabledPasswordReset = 'AccountEnabledPasswordReset', // User's account is in the EnabledPasswordReset state
  AccountDisabledPasswordReset = 'AccountDisabledPasswordReset', // User's account is in the DisabledPasswordReset state
}

export default Vue.extend({
  components: {
    ConfirmationModal,
    ReportDocumentActions,
    PlatformSpinner,
    PlatformEntityCard
  },
  props: {
    surveys: Array as PropType<any[]>
  },
  data: () => ({
    pendingArtificialDelay: false,
    pendingRefresh: false,
    pendingIndividualResetPassword: false,
    fetchFailed: false,
    surveyGroup: <PlatformSurveyGroup | undefined>undefined,
    reportDocuments: <PlatformReportDocument[]>[],
    confirmingIndividualResetPassword: <PlatformIndividual | null>null,
    subscriptions: <any[]>[],
    CompletionStatus
  }),
  mounted (): void {
    this.init()
    this.setupSubscriptions()
    this.pendingArtificialDelay = true
    setTimeout(() => {
      this.pendingArtificialDelay = false
    }, 600)
  },
  beforeDestroy (): void {
    this.resetSubscriptions()
  },
  watch: {
    company (newCompany, oldCompany): void {
      if (newCompany?.id && newCompany.id === oldCompany?.id) {
        return
      }
      this.resetSubscriptions()
      this.setupSubscriptions()
    }
  },
  computed: {
    surveyGroupId (): string {
      return this.$route.params.surveyId
    },
    userCognitoId (): string {
      return this.$store.state.User.entity.username
    },
    availableVariants (): QuestionnaireVariantCode[] {
      if (this.surveyGroup?.variants) {
        return this.surveyGroup.variants.map((v) => v.variantCode)
      }
      if (!this.surveys?.length) {
        return []
      }
      return this.surveys.reduce(
        (variantCodes: QuestionnaireVariantCode[], s) => {
          if (s.variantCode && !variantCodes.includes(s.variantCode)) {
            variantCodes.push(s.variantCode)
          }
          return variantCodes
        },
        []
      )
    },
    selectedVariant (): QuestionnaireVariantCode | string {
      return this.availableVariants[0] ?? NullVariantCodeValue
    },
    individuals (): ResultsIndividual[] {
      if (!this.surveysList) {
        return []
      }
      return this.surveysList
        .map((s) => {
          const id = s.individualId || s.id
          const progress = calculateCompletion(s)
          let displayCompletion: string | undefined
          let completionStatus: CompletionStatus = CompletionStatus.NotStarted
          let displayCompletionStatus: string = this.$t('page.viewSurvey.responsesStatus.notStarted') as string
          if (!this.pendingArtificialDelay) {
            displayCompletion = typeof progress === 'number' ? `${Math.round(progress)}%` : undefined
            if (s.finishedAt) {
              completionStatus = CompletionStatus.Completed
              displayCompletionStatus = this.$t('page.viewSurvey.responsesStatus.completed') as string
            } else if (progress && progress > 0) {
              completionStatus = CompletionStatus.InProgress
              displayCompletionStatus = this.$t('page.viewSurvey.responsesStatus.inProgress') as string
            } else {
              if (s.individual.accountStatus === UserAccountStatus.Created || s.individual.accountStatus === UserAccountStatus.Invited) {
                completionStatus = CompletionStatus.AccountNotEnabled
                displayCompletionStatus = this.$t('page.viewSurvey.responsesStatus.accountNotEnabled') as string
              } else if (s.individual.accountStatus === UserAccountStatus.DisabledPasswordReset) {
                completionStatus = CompletionStatus.AccountDisabledPasswordReset
                displayCompletionStatus = this.$t('page.viewSurvey.responsesStatus.accountPasswordReset') as string
              }
            }
          }

          const roleName = s.individual.jobTitle ?? this.$t(`shared.individual.roles.${s.individual.role}`)
          return {
            id,
            preferredName: s.preferredName ?? (s.individual ? s.individual.preferredName ?? getFullName(s.individual) : getFullName(s)),
            fullName: getFullName(s.individual),
            firstName: s.individual?.firstName,
            familyName: s.individual?.familyName,
            role: s.individual.role,
            roleName,
            accountStatus: s.individual.accountStatus,
            companyId: this.company.id,
            finishedAt: s.finishedAt,
            completed: !!s.finishedAt,
            completionStatus,
            displayCompletionStatus,
            displayCompletion,
            totals: {
              ...(s.individual?.totals ?? {}),
              progress
            }
          } as ResultsIndividual
        })
        .sort(sortResultSetIndividuals)
    },
    surveysList (): any[] {
      if (this.surveyGroup?.surveys?.items?.length) {
        return this.surveyGroup.surveys.items
      }
      return this.surveys
    },
    survey (): any | undefined {
      if (!this.surveysList) {
        return
      }
      // Pick any from the group that has the data we need
      return this.surveysList.find((s) => s.companyId)
    },
    company (): any | undefined {
      const companyId = this.survey?.companyId
      return this.$store.state.Company.list.find((c) => c.id === companyId)
    },
    questionnaireName (): string | undefined {
      return this.survey?.questionnaire?.name
    }
  },
  methods: {
    async init (): Promise<void> {
      await Promise.all([
        this.fetchSurveyResultSet(),
        this.fetchResponseStatusReportDocuments()
      ])
    },
    async fetchSurveyResultSet (): Promise<void> {
      this.fetchFailed = false
      try {
        this.surveyGroup = await getSurveyGroupById(this.surveyGroupId)
      } catch (err) {
        console.error('Failed to fetch surveys, error:', err)
        this.fetchFailed = true
      }
    },
    async fetchResponseStatusReportDocuments (): Promise<void> {
      this.fetchFailed = false
      let response: PlatformReportDocument[] | undefined
      try {
        response = await listReportDocuments(this.surveyGroupId, ReportTemplate.ResponseStatus)
      } catch (err) {
        console.error('Failed to fetch surveys, error:', err)
        this.fetchFailed = true
      }
      if (!response?.length) {
        return
      }
      this.reportDocuments.push(...response)
    },
    refresh (): void {
      this.pendingRefresh = true
      this.pendingArtificialDelay = true
      this.init()
        .finally(() => {
          this.pendingRefresh = false
          this.pendingArtificialDelay = false
        })
    },
    triggerConfirmResetPasswordModal (individual: PlatformIndividual): void {
      this.refresh()
      this.confirmingIndividualResetPassword = individual
    },
    async confirmIndividualResetPassword (): Promise<void> {
      if (!this.confirmingIndividualResetPassword) {
        return
      }
      const targetIndividualId = this.confirmingIndividualResetPassword.id
      this.pendingIndividualResetPassword = true
      try {
        await resendQuestionnaireInvite(
          targetIndividualId,
          this.surveyGroupId
        )
      } catch (err) {
        console.error('Failed to reset Individual password, error:', err, targetIndividualId)
        this.$toasted.error(this.$t('page.viewSurvey.responsesStatus.resendInviteErrorMessage') as string)
        throw err
      } finally {
        this.pendingIndividualResetPassword = false
        this.confirmingIndividualResetPassword = null
      }
      this.updateUserAccountStatus(targetIndividualId)
      this.$toasted.success(this.$t('page.viewSurvey.responsesStatus.resendInviteSuccessMessage') as string)
    },
    updateUserAccountStatus (targetIndividualId: string): void {
      if (!this.surveyGroup?.surveys?.items?.length) {
        return
      }
      const surveyIndex = this.surveyGroup.surveys.items.findIndex((s) => s.individualId === targetIndividualId)
      if (surveyIndex === -1) {
        return
      }
      const currentAccountStatus = this.surveyGroup.surveys.items[surveyIndex].individual.accountStatus
      if (currentAccountStatus === UserAccountStatus.Enabled || currentAccountStatus === UserAccountStatus.EnabledPasswordReset) {
        this.surveyGroup.surveys.items[surveyIndex].individual.accountStatus = UserAccountStatus.EnabledPasswordReset
      } else {
        this.surveyGroup.surveys.items[surveyIndex].individual.accountStatus = UserAccountStatus.DisabledPasswordReset
      }
    },
    async queueResponseStatusReport (request: { variantCode: QuestionnaireVariantCode | typeof NullVariantCodeValue }): Promise<void> {
      const payload: PartialReportDocument = {
        requesterId: this.userCognitoId,
        surveyGroupId: this.surveyGroupId,
        companyId: this.company.id,
        variantCode: request.variantCode && request.variantCode !== NullVariantCodeValue ? request.variantCode : null,
        template: ReportTemplate.ResponseStatus
      }
      let createdReportDocument: PlatformReportDocument
      try {
        createdReportDocument = await queueReportDocument(payload)
      } catch (err) {
        console.error('Failed to queue ResponseStatus Report Document:', err)
        throw err
      }
      if (createdReportDocument) {
        console.debug('Queued new ResponseStatus ReportDocument:', createdReportDocument)
        this.reportDocuments.push(createdReportDocument)
      }
    },
    async downloadResponseStatusReport (request: { variantCode: QuestionnaireVariantCode }): Promise<void> {
      console.debug('Downloading reportDoc:', request)
      const reportDocument = getVariantLatestReportDocument(this.reportDocuments, request.variantCode)
      if (!reportDocument) {
        console.error('Failed to find ReportDocument requested for download.', request)
        return
      }
      const currentDate = dayjs().format(ShortDateDisplayFormat)
      const fileName: string = `${this.questionnaireName} ${currentDate}`
      try {
        await downloadReportDocument(
          reportDocument,
          fileName
        )
      } catch (err) {
        console.error('Failed to download ResponseStatus ReportDocument, err:', err, reportDocument)
        throw err
      }
      console.debug('Downloaded report!')
    },
    resetSubscriptions (): void {
      if (!this.subscriptions.length) {
        return
      }
      this.subscriptions.forEach(
        (item) => item.unsubscribe()
      )
      this.subscriptions.splice(0, this.subscriptions.length)
    },
    setupSubscriptions (): void {
      if (!this.company?.id) {
        return
      }
      const params: GraphQLOptionsV6<OnUpdateReportDocumentSubscription, typeof onUpdateReportDocument> = {
        query: onUpdateReportDocument,
        variables: {
          companyId: this.company.id,
          requesterId: this.userCognitoId
        }
      }
      console.debug('Setting up ResponseStatus ReportDocument subscription', params)
      this.subscriptions.push(API
        .graphql(params)
        .subscribe({
          next: ({ data }) => {
            const reportDocument: PlatformReportDocument | undefined = data.onUpdateReportDocument as PlatformReportDocument | undefined
            if (!reportDocument) {
              return
            }
            if (reportDocument.surveyGroupId !== this.surveyGroupId || reportDocument.template !== ReportTemplate.ResponseStatus) {
              return
            }
            const index = this.reportDocuments.findIndex(
              (r: PlatformReportDocument) => r.id === reportDocument.id
            )
            if (reportDocument.deletedAt) {
              // Item has been deleted
              if (index !== -1) {
                // Remove deleted item from list
                this.reportDocuments.splice(index, 1)
              }
              return
            }
            if (index === -1) {
              // Add new item to the start of the array (to keep them ordered newest to oldest)
              this.reportDocuments.unshift({ ...reportDocument })
              return
            }
            // Replace existing item in list
            this.reportDocuments.splice(index, 1, { ...reportDocument })
          },
          error: error => console.warn(error)
        }))
    }
  }
})
</script>

<style lang="scss">
.user-progress {
  transition-duration: 0.8s;
}
</style>
