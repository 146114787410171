<template>
  <v-text-field
    class="platform-text-field"
    v-bind="attrs"
    v-on="on"
    v-model="inputValue"
    ref="input"
    :type="inputType"
    :readonly="readonly"
    :label="required ? `${label || ''} *` : label"
    :rules="validationRules"
    :outlined="outlined"
    :solo="solo"
    :flat="flat"
    :loading="loading"
    :disabled="disabled"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    :persistent-placeholder="persistentPlaceholder"
    :autofocus="autofocus"
    @focus="$emit('focus')"
  >
    <input-label slot="label" :label="label" :required="required" />
    <template
      v-if="$slots.append"
      #append
    >
      <slot
        name="append"
      />
    </template>
    <template
      v-if="$slots.default"
      #default
    >
      <slot
        name="default"
      />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import Vue from 'vue'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'
import { InputFieldMixin } from '@/components/mixins/InputField'

export default Vue.extend({
  name: 'PlatformTextField',
  components: {
    InputLabel
  },
  mixins: [InputFieldMixin],
  props: {
    value: { type: String, required: false },
    inputType: { type: String, default: 'text' },
    autofocus: { type: Boolean, default: false }
  }
})
</script>
