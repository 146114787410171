import * as d3 from 'd3'
import { AnySelection, GroupSelection } from '../types/d3'
import { handleBrowserDelay } from '../helpers/handleBrowserDelay'

// This feels quite silly, but it works for now til we have a smarter way of dynamic text scaling.
export function getAxisTextScale (characterCount: number): number {
  if (characterCount > 900) {
    return (900 / characterCount + 1) / 2
  }
  if (characterCount > 750) {
    return (750 / characterCount + 1) / 2
  }
  if (characterCount > 500) {
    return (500 / characterCount + 1) / 2
  }
  return 1
}

interface Config {
  width: number
  fontSize: number
  padding?: number
  alignLeft?: boolean
  verticalAlign?: 'center'
  lineHeight?: number
}

export function wrapText (rows: AnySelection, config: Config): void {
  let { width, fontSize, verticalAlign, padding = 10, alignLeft = false, lineHeight = fontSize + 2 } = config
  if (!alignLeft) {
    padding *= -1
  }

  rows.each(function (field, index, nodes) {
    const text = d3.select(nodes[index])
    let words: any[] = []
    const tspans = text.selectAll('tspan')
    if (tspans.nodes().length) {
      tspans.each((field, index, nodes) => {
        const el = d3.select(nodes[index])
        const node = el.node()
        words.push(node)
      })
    } else {
      // Create a list of nodes from the text content inside the element
      words = text
        .text()
        .split(/\s+/)
        .filter((i) => !!i)
    }
    const y = text.attr('y')
    let currentLineWords: string[] = []
    let currentLine = text
      .text(null)
      .append('tspan')
      .attr('class', 'line')
      .attr('x', padding)
      .attr('y', y)
      .attr('alignment-baseline', 'central')

    let lines = 0
    words.forEach((node, index) => {
      let lineNode
      lineNode = currentLine.node()
      if (!lineNode) {
        throw new Error('Failed to wrap text: (1) failed to fetch tspan node for text line.')
      }
      if (typeof node === 'string') {
        currentLineWords.push(node)
        currentLine.text(currentLineWords.join(' '))
      } else {
        lineNode.appendChild(node)
      }
      currentLine.append('tspan').text(' ')

      if (lineNode.getComputedTextLength() > width) {
        lines++
        // Didn't fit on last line, remove it
        if (typeof node === 'string') {
          currentLineWords.pop()
          currentLine.text(currentLineWords.join(' '))
        } else {
          lineNode.removeChild(node)
        }
        // Move to next line
        currentLineWords = []
        currentLine = text.append('tspan')
          .attr('class', 'line')
          .attr('x', padding)
          .attr('dy', lineHeight)
          .attr('alignment-baseline', 'central')

        // Append word to new line
        lineNode = currentLine.node()
        if (!lineNode) {
          throw new Error('Failed to wrap text: (2) failed to fetch tspan node for text line.')
        }
        if (typeof node === 'string') {
          currentLineWords.push(node)
          currentLine.text(currentLineWords.join(' '))
        } else {
          lineNode.appendChild(node)
        }
        currentLine.append('tspan').text(' ')
      }
    })

    if (verticalAlign === 'center') {
      text.attr('dy', -lines / 2 * lineHeight)
    }
  })
}

export function wrapNormalText (element: AnySelection, config: Config): void {
  handleBrowserDelay(() => {
    wrapText(element, config)
  })
}

export function wrapAxisText (yAxis: GroupSelection, config: Config, selector: string = '.tick text'): void {
  handleBrowserDelay(() => {
    yAxis
      .selectAll(selector)
      .call(wrapText, config)
  })
}
