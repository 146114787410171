export const CountryDialCodes: Array<{ countryCode: string, dialCode: string }> = [
  {
    dialCode: '44',
    countryCode: 'GB'
  },
  {
    dialCode: '49',
    countryCode: 'DE'
  },
  {
    dialCode: '1',
    countryCode: 'US'
  },
  {
    dialCode: '41',
    countryCode: 'CH'
  },
  {
    dialCode: '93',
    countryCode: 'AF'
  },
  {
    dialCode: '358',
    countryCode: 'AX'
  },
  {
    dialCode: '355',
    countryCode: 'AL'
  },
  {
    dialCode: '213',
    countryCode: 'DZ'
  },
  {
    dialCode: '1684',
    countryCode: 'AS'
  },
  {
    dialCode: '376',
    countryCode: 'AD'
  },
  {
    dialCode: '244',
    countryCode: 'AO'
  },
  {
    dialCode: '1264',
    countryCode: 'AI'
  },
  {
    dialCode: '672',
    countryCode: 'AQ'
  },
  {
    dialCode: '1268',
    countryCode: 'AG'
  },
  {
    dialCode: '54',
    countryCode: 'AR'
  },
  {
    dialCode: '374',
    countryCode: 'AM'
  },
  {
    dialCode: '297',
    countryCode: 'AW'
  },
  {
    dialCode: '61',
    countryCode: 'AU'
  },
  {
    dialCode: '43',
    countryCode: 'AT'
  },
  {
    dialCode: '994',
    countryCode: 'AZ'
  },
  {
    dialCode: '1242',
    countryCode: 'BS'
  },
  {
    dialCode: '973',
    countryCode: 'BH'
  },
  {
    dialCode: '880',
    countryCode: 'BD'
  },
  {
    dialCode: '1246',
    countryCode: 'BB'
  },
  {
    dialCode: '375',
    countryCode: 'BY'
  },
  {
    dialCode: '32',
    countryCode: 'BE'
  },
  {
    dialCode: '501',
    countryCode: 'BZ'
  },
  {
    dialCode: '229',
    countryCode: 'BJ'
  },
  {
    dialCode: '1441',
    countryCode: 'BM'
  },
  {
    dialCode: '975',
    countryCode: 'BT'
  },
  {
    dialCode: '591',
    countryCode: 'BO'
  },
  {
    dialCode: '387',
    countryCode: 'BA'
  },
  {
    dialCode: '267',
    countryCode: 'BW'
  },
  {
    dialCode: '55',
    countryCode: 'BR'
  },
  {
    dialCode: '246',
    countryCode: 'IO'
  },
  {
    dialCode: '673',
    countryCode: 'BN'
  },
  {
    dialCode: '359',
    countryCode: 'BG'
  },
  {
    dialCode: '226',
    countryCode: 'BF'
  },
  {
    dialCode: '257',
    countryCode: 'BI'
  },
  {
    dialCode: '855',
    countryCode: 'KH'
  },
  {
    dialCode: '237',
    countryCode: 'CM'
  },
  {
    dialCode: '1',
    countryCode: 'CA'
  },
  {
    dialCode: '238',
    countryCode: 'CV'
  },
  {
    dialCode: ' 345',
    countryCode: 'KY'
  },
  {
    dialCode: '236',
    countryCode: 'CF'
  },
  {
    dialCode: '235',
    countryCode: 'TD'
  },
  {
    dialCode: '56',
    countryCode: 'CL'
  },
  {
    dialCode: '86',
    countryCode: 'CN'
  },
  {
    dialCode: '61',
    countryCode: 'CX'
  },
  {
    dialCode: '61',
    countryCode: 'CC'
  },
  {
    dialCode: '57',
    countryCode: 'CO'
  },
  {
    dialCode: '269',
    countryCode: 'KM'
  },
  {
    dialCode: '242',
    countryCode: 'CG'
  },
  {
    dialCode: '243',
    countryCode: 'CD'
  },
  {
    dialCode: '682',
    countryCode: 'CK'
  },
  {
    dialCode: '506',
    countryCode: 'CR'
  },
  {
    dialCode: '225',
    countryCode: 'CI'
  },
  {
    dialCode: '385',
    countryCode: 'HR'
  },
  {
    dialCode: '53',
    countryCode: 'CU'
  },
  {
    dialCode: '357',
    countryCode: 'CY'
  },
  {
    dialCode: '420',
    countryCode: 'CZ'
  },
  {
    dialCode: '45',
    countryCode: 'DK'
  },
  {
    dialCode: '253',
    countryCode: 'DJ'
  },
  {
    dialCode: '1767',
    countryCode: 'DM'
  },
  {
    dialCode: '1849',
    countryCode: 'DO'
  },
  {
    dialCode: '593',
    countryCode: 'EC'
  },
  {
    dialCode: '20',
    countryCode: 'EG'
  },
  {
    dialCode: '503',
    countryCode: 'SV'
  },
  {
    dialCode: '240',
    countryCode: 'GQ'
  },
  {
    dialCode: '291',
    countryCode: 'ER'
  },
  {
    dialCode: '372',
    countryCode: 'EE'
  },
  {
    dialCode: '251',
    countryCode: 'ET'
  },
  {
    dialCode: '500',
    countryCode: 'FK'
  },
  {
    dialCode: '298',
    countryCode: 'FO'
  },
  {
    dialCode: '679',
    countryCode: 'FJ'
  },
  {
    dialCode: '358',
    countryCode: 'FI'
  },
  {
    dialCode: '33',
    countryCode: 'FR'
  },
  {
    dialCode: '594',
    countryCode: 'GF'
  },
  {
    dialCode: '689',
    countryCode: 'PF'
  },
  {
    dialCode: '241',
    countryCode: 'GA'
  },
  {
    dialCode: '220',
    countryCode: 'GM'
  },
  {
    dialCode: '995',
    countryCode: 'GE'
  },
  {
    dialCode: '233',
    countryCode: 'GH'
  },
  {
    dialCode: '350',
    countryCode: 'GI'
  },
  {
    dialCode: '30',
    countryCode: 'GR'
  },
  {
    dialCode: '299',
    countryCode: 'GL'
  },
  {
    dialCode: '1473',
    countryCode: 'GD'
  },
  {
    dialCode: '590',
    countryCode: 'GP'
  },
  {
    dialCode: '1671',
    countryCode: 'GU'
  },
  {
    dialCode: '502',
    countryCode: 'GT'
  },
  {
    dialCode: '44',
    countryCode: 'GG'
  },
  {
    dialCode: '224',
    countryCode: 'GN'
  },
  {
    dialCode: '245',
    countryCode: 'GW'
  },
  {
    dialCode: '595',
    countryCode: 'GY'
  },
  {
    dialCode: '509',
    countryCode: 'HT'
  },
  {
    dialCode: '379',
    countryCode: 'VA'
  },
  {
    dialCode: '504',
    countryCode: 'HN'
  },
  {
    dialCode: '852',
    countryCode: 'HK'
  },
  {
    dialCode: '36',
    countryCode: 'HU'
  },
  {
    dialCode: '354',
    countryCode: 'IS'
  },
  {
    dialCode: '91',
    countryCode: 'IN'
  },
  {
    dialCode: '62',
    countryCode: 'ID'
  },
  {
    dialCode: '98',
    countryCode: 'IR'
  },
  {
    dialCode: '964',
    countryCode: 'IQ'
  },
  {
    dialCode: '353',
    countryCode: 'IE'
  },
  {
    dialCode: '44',
    countryCode: 'IM'
  },
  {
    dialCode: '972',
    countryCode: 'IL'
  },
  {
    dialCode: '39',
    countryCode: 'IT'
  },
  {
    dialCode: '1876',
    countryCode: 'JM'
  },
  {
    dialCode: '81',
    countryCode: 'JP'
  },
  {
    dialCode: '44',
    countryCode: 'JE'
  },
  {
    dialCode: '962',
    countryCode: 'JO'
  },
  {
    dialCode: '77',
    countryCode: 'KZ'
  },
  {
    dialCode: '254',
    countryCode: 'KE'
  },
  {
    dialCode: '686',
    countryCode: 'KI'
  },
  {
    dialCode: '850',
    countryCode: 'KP'
  },
  {
    dialCode: '82',
    countryCode: 'KR'
  },
  {
    dialCode: '965',
    countryCode: 'KW'
  },
  {
    dialCode: '996',
    countryCode: 'KG'
  },
  {
    dialCode: '856',
    countryCode: 'LA'
  },
  {
    dialCode: '371',
    countryCode: 'LV'
  },
  {
    dialCode: '961',
    countryCode: 'LB'
  },
  {
    dialCode: '266',
    countryCode: 'LS'
  },
  {
    dialCode: '231',
    countryCode: 'LR'
  },
  {
    dialCode: '218',
    countryCode: 'LY'
  },
  {
    dialCode: '423',
    countryCode: 'LI'
  },
  {
    dialCode: '370',
    countryCode: 'LT'
  },
  {
    dialCode: '352',
    countryCode: 'LU'
  },
  {
    dialCode: '853',
    countryCode: 'MO'
  },
  {
    dialCode: '389',
    countryCode: 'MK'
  },
  {
    dialCode: '261',
    countryCode: 'MG'
  },
  {
    dialCode: '265',
    countryCode: 'MW'
  },
  {
    dialCode: '60',
    countryCode: 'MY'
  },
  {
    dialCode: '960',
    countryCode: 'MV'
  },
  {
    dialCode: '223',
    countryCode: 'ML'
  },
  {
    dialCode: '356',
    countryCode: 'MT'
  },
  {
    dialCode: '692',
    countryCode: 'MH'
  },
  {
    dialCode: '596',
    countryCode: 'MQ'
  },
  {
    dialCode: '222',
    countryCode: 'MR'
  },
  {
    dialCode: '230',
    countryCode: 'MU'
  },
  {
    dialCode: '262',
    countryCode: 'YT'
  },
  {
    dialCode: '52',
    countryCode: 'MX'
  },
  {
    dialCode: '691',
    countryCode: 'FM'
  },
  {
    dialCode: '373',
    countryCode: 'MD'
  },
  {
    dialCode: '377',
    countryCode: 'MC'
  },
  {
    dialCode: '976',
    countryCode: 'MN'
  },
  {
    dialCode: '382',
    countryCode: 'ME'
  },
  {
    dialCode: '1664',
    countryCode: 'MS'
  },
  {
    dialCode: '212',
    countryCode: 'MA'
  },
  {
    dialCode: '258',
    countryCode: 'MZ'
  },
  {
    dialCode: '95',
    countryCode: 'MM'
  },
  {
    dialCode: '264',
    countryCode: 'NA'
  },
  {
    dialCode: '674',
    countryCode: 'NR'
  },
  {
    dialCode: '977',
    countryCode: 'NP'
  },
  {
    dialCode: '31',
    countryCode: 'NL'
  },
  {
    dialCode: '599',
    countryCode: 'AN'
  },
  {
    dialCode: '687',
    countryCode: 'NC'
  },
  {
    dialCode: '64',
    countryCode: 'NZ'
  },
  {
    dialCode: '505',
    countryCode: 'NI'
  },
  {
    dialCode: '227',
    countryCode: 'NE'
  },
  {
    dialCode: '234',
    countryCode: 'NG'
  },
  {
    dialCode: '683',
    countryCode: 'NU'
  },
  {
    dialCode: '672',
    countryCode: 'NF'
  },
  {
    dialCode: '1670',
    countryCode: 'MP'
  },
  {
    dialCode: '47',
    countryCode: 'NO'
  },
  {
    dialCode: '968',
    countryCode: 'OM'
  },
  {
    dialCode: '92',
    countryCode: 'PK'
  },
  {
    dialCode: '680',
    countryCode: 'PW'
  },
  {
    dialCode: '970',
    countryCode: 'PS'
  },
  {
    dialCode: '507',
    countryCode: 'PA'
  },
  {
    dialCode: '675',
    countryCode: 'PG'
  },
  {
    dialCode: '595',
    countryCode: 'PY'
  },
  {
    dialCode: '51',
    countryCode: 'PE'
  },
  {
    dialCode: '63',
    countryCode: 'PH'
  },
  {
    dialCode: '872',
    countryCode: 'PN'
  },
  {
    dialCode: '48',
    countryCode: 'PL'
  },
  {
    dialCode: '351',
    countryCode: 'PT'
  },
  {
    dialCode: '1939',
    countryCode: 'PR'
  },
  {
    dialCode: '974',
    countryCode: 'QA'
  },
  {
    dialCode: '40',
    countryCode: 'RO'
  },
  {
    dialCode: '7',
    countryCode: 'RU'
  },
  {
    dialCode: '250',
    countryCode: 'RW'
  },
  {
    dialCode: '262',
    countryCode: 'RE'
  },
  {
    dialCode: '590',
    countryCode: 'BL'
  },
  {
    dialCode: '290',
    countryCode: 'SH'
  },
  {
    dialCode: '1869',
    countryCode: 'KN'
  },
  {
    dialCode: '1758',
    countryCode: 'LC'
  },
  {
    dialCode: '590',
    countryCode: 'MF'
  },
  {
    dialCode: '508',
    countryCode: 'PM'
  },
  {
    dialCode: '1784',
    countryCode: 'VC'
  },
  {
    dialCode: '685',
    countryCode: 'WS'
  },
  {
    dialCode: '378',
    countryCode: 'SM'
  },
  {
    dialCode: '239',
    countryCode: 'ST'
  },
  {
    dialCode: '966',
    countryCode: 'SA'
  },
  {
    dialCode: '221',
    countryCode: 'SN'
  },
  {
    dialCode: '381',
    countryCode: 'RS'
  },
  {
    dialCode: '248',
    countryCode: 'SC'
  },
  {
    dialCode: '232',
    countryCode: 'SL'
  },
  {
    dialCode: '65',
    countryCode: 'SG'
  },
  {
    dialCode: '421',
    countryCode: 'SK'
  },
  {
    dialCode: '386',
    countryCode: 'SI'
  },
  {
    dialCode: '677',
    countryCode: 'SB'
  },
  {
    dialCode: '252',
    countryCode: 'SO'
  },
  {
    dialCode: '27',
    countryCode: 'ZA'
  },
  {
    dialCode: '211',
    countryCode: 'SS'
  },
  {
    dialCode: '500',
    countryCode: 'GS'
  },
  {
    dialCode: '34',
    countryCode: 'ES'
  },
  {
    dialCode: '94',
    countryCode: 'LK'
  },
  {
    dialCode: '249',
    countryCode: 'SD'
  },
  {
    dialCode: '597',
    countryCode: 'SR'
  },
  {
    dialCode: '47',
    countryCode: 'SJ'
  },
  {
    dialCode: '268',
    countryCode: 'SZ'
  },
  {
    dialCode: '46',
    countryCode: 'SE'
  },
  {
    dialCode: '963',
    countryCode: 'SY'
  },
  {
    dialCode: '886',
    countryCode: 'TW'
  },
  {
    dialCode: '992',
    countryCode: 'TJ'
  },
  {
    dialCode: '255',
    countryCode: 'TZ'
  },
  {
    dialCode: '66',
    countryCode: 'TH'
  },
  {
    dialCode: '670',
    countryCode: 'TL'
  },
  {
    dialCode: '228',
    countryCode: 'TG'
  },
  {
    dialCode: '690',
    countryCode: 'TK'
  },
  {
    dialCode: '676',
    countryCode: 'TO'
  },
  {
    dialCode: '1868',
    countryCode: 'TT'
  },
  {
    dialCode: '216',
    countryCode: 'TN'
  },
  {
    dialCode: '90',
    countryCode: 'TR'
  },
  {
    dialCode: '993',
    countryCode: 'TM'
  },
  {
    dialCode: '1649',
    countryCode: 'TC'
  },
  {
    dialCode: '688',
    countryCode: 'TV'
  },
  {
    dialCode: '256',
    countryCode: 'UG'
  },
  {
    dialCode: '380',
    countryCode: 'UA'
  },
  {
    dialCode: '971',
    countryCode: 'AE'
  },
  {
    dialCode: '598',
    countryCode: 'UY'
  },
  {
    dialCode: '998',
    countryCode: 'UZ'
  },
  {
    dialCode: '678',
    countryCode: 'VU'
  },
  {
    dialCode: '58',
    countryCode: 'VE'
  },
  {
    dialCode: '84',
    countryCode: 'VN'
  },
  {
    dialCode: '1284',
    countryCode: 'VG'
  },
  {
    dialCode: '1340',
    countryCode: 'VI'
  },
  {
    dialCode: '681',
    countryCode: 'WF'
  },
  {
    dialCode: '967',
    countryCode: 'YE'
  },
  {
    dialCode: '260',
    countryCode: 'ZM'
  },
  {
    dialCode: '263',
    countryCode: 'ZW'
  }
]
