<template>
  <report-preview-page :title="sectionName">
    <strong class="key-findings__question-text">
      {{ questionText }}
    </strong>
    <div class="key-findings__summary">
      <span v-if="keyFindingsSummary" class="user-content">
        {{ keyFindingsSummary }}
      </span>
      <span v-if="previewContent" class="preview-content" :class="{ 'pt-1': !!keyFindingsSummary }">
        {{ previewContent }}
      </span>
    </div>
    <div
      v-for="({ value, color }, index) in keyFindingsItems"
      :key="index"
      class="key-findings__item"
      :style="{ 'background-color': color }"
    >
      <strong class="key-findings__item-text">
        {{ value }}
      </strong>
    </div>
  </report-preview-page>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ReportResult } from '@/types/Report'
import { PlatformSection } from '@betterboards/shared/types/Platform'
import { CompanyConfiguration, PlatformCompany } from '@betterboards/shared/types/Company'
import { ResponsesResult } from '@betterboards/shared/types/ResultSet'
import ReportPreviewPage from '@/components/analysis/report-previews/partials/Page.vue'

interface DisplayKeyFindingsItem {
  value: string
  color: string
}

const DefaultKeyFindingsColours = [
  '#24235f',
  '#2d398b',
  '#e44500',
  '#ae3033',
  '#727072'
]

export default Vue.extend({
  name: 'KeyFindingsReportPreview',
  components: {
    ReportPreviewPage
  },
  props: {
    result: { type: Object as PropType<ResponsesResult>, required: false },
    section: { type: Object as PropType<PlatformSection>, required: true },
    reportResult: { type: Object as PropType<ReportResult>, required: false },
    previewContent: { type: String as PropType<string | undefined>, required: false }
  },
  data: () => ({
    //
  }),
  computed: {
    selectedCompany (): PlatformCompany | undefined {
      return this.$store.state.Company.selectedCompany
    },
    companyConfiguration (): CompanyConfiguration | undefined {
      return this.selectedCompany?.configuration
    },
    sectionName (): string {
      if (this.section.slug === 'clarity' && this.result?.committee && !this.result?.criteriaId) {
        return this.$t('shared.graphs.committees.generalStructureTitle') as string
      }
      return this.section.name
    },
    questionText (): string {
      if (!this.result?.data?.questionText) {
        return this.$t('analysis.reportContent.recommendationsModal.exampleContent.questionText') as string
      }
      return this.result.data.questionText
    },
    keyFindingsSummary (): string | undefined {
      if (!this.reportResult?.summary) {
        if (this.previewContent) {
          return undefined
        }
        // ReportResult has no summary, display example content
        return this.$t('analysis.reportContent.recommendationsModal.exampleContent.keyFindingsSummary') as string
      }
      return this.reportResult.summary
    },
    keyFindingsItems (): DisplayKeyFindingsItem[] {
      const boxColors: string[] = this.companyConfiguration?.report?.keyFindings?.boxColors ?? DefaultKeyFindingsColours
      if (!this.reportResult?.values || !this.hasKeyFindingsItems) {
        // ReportResult has no items, display example content
        return boxColors.map((color, index): DisplayKeyFindingsItem => ({
          value: this.$t('analysis.reportContent.recommendationsModal.exampleContent.keyFindingsItems', [index]) as string,
          color
        }))
      }
      return this.reportResult.values.map((value, index): DisplayKeyFindingsItem => ({
        value,
        color: boxColors[index % boxColors.length] // Loop through colours per index, wrapping back round if index > boxColors.length
      }))
    },
    hasKeyFindingsItems (): boolean {
      if (!this.reportResult?.values?.length) {
        return false
      }
      // Check at least one item has content (intentionally excluding empty strings)
      return this.reportResult.values.some(
        (i: string | undefined) => !!i
      )
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.key-findings__question-text {
  color: var(--v-primary-base);
  font-size: 0.95em;
  line-height: 1.6;
}
.key-findings__summary {
  color: var(--v-primary-base);
  font-size: 0.8em;
  font-weight: 300;
  padding: 6px 6px 10px;

  line-height: 1.7;
  text-align: justify;

  .user-content {
    display: block;
  }
  .preview-content {
    display: block;
    color: var(--v-primary-lighten2);
  }
}
.key-findings__item {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  font-size: 0.95em;
  padding: 4px;
  margin: 6px 0;
  min-height: 4.25em;
  color: white;
  text-align: center;
}
</style>
