<template>
  <div class="overflow-y-hidden d-flex flex-column">
    <v-row no-gutters class="pb-2">
      <v-col class="grow">
        <h2 v-if="!ready || !subSections.length">{{ $t('analysis.sectionTabs.sectionIntroduction') }}</h2>
      </v-col>
      <v-col v-if="!subSections.length" class="shrink">
        <report-context-button
          :value="reportContextButtonKey"
          :master-section-id="section.masterSectionId"
          :section="section"
          :report-section="reportSection"
          @insertRecommendation="(content) => handleInsertRecommendedContent(content)"
        />
      </v-col>
    </v-row>
    <platform-spinner
      v-if="loading || !ready"
      class="mt-4 mx-auto"
    />
    <template v-else-if="reportSection && !subSections.length">
      <PlatformWYSIWYG
        :placeholder="tooltip"
        :max-length="textFieldLimit"
        :initial-data="currentContent"
        @newInput="saveUpdate"
      />
      <div class="text-right pt-2">
        <sign-off-button
          :value="sectionIntroReportResult && !!sectionIntroReportResult.reviewedAt"
          @input="toggleSectionIntroReviewed"
        />
      </div>
    </template>
    <div v-else-if="!!subSections.length">
      <div v-for="subSection in subSections" :key="subSection.slug" class="mb-10">
        <v-row no-gutters class="pb-2">
          <v-col class="grow">
            <h2>{{ subSection.name }} introduction</h2>
          </v-col>
          <v-col class="shrink pb-">
            <report-context-button
              :value="reportContextButtonKey"
              :master-section-id="section.masterSectionId"
              :criteria-id="subSection.slug"
              :section="section"
              :sub-section="subSection"
              :report-section="reportSection"
              @insertRecommendation="(content) => handleInsertRecommendedContent(content, subSection.slug)"
            />
          </v-col>
        </v-row>
        <PlatformWYSIWYG
          :placeholder="tooltip"
          :max-length="getSubSectionTextFieldLimit(subSection.slug)"
          :initial-data="getCurrentContentBySlug(subSection.slug)"
          @newInput="(value) => saveSubSectionUpdate(subSection.slug, { value })"
        />
        <div class="text-right pt-2">
          <sign-off-button
            :value="!!subSection.reviewedAt"
            @input="toggleSubSectionIntroReviewed(subSection.slug, subSection.reviewedAt)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ReportContextButton from '@/components/analysis/ReportContextButton.vue'
import SignOffButton from '@/components/analysis/SignOffButton.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { PlatformReport, PlatformReportSection, ReportSectionField, ReportSectionResult } from '@/types/Report'
import Vue, { PropType } from 'vue'
import { SubSectionIntroduction } from '@/API'
import { MaxFieldLengths } from '@/helpers/validation'
import PlatformWYSIWYG from '@/components/PlatformWYSIWYG.vue'
import { ReportContextItem } from '@/helpers/recommendations'
import { SectionResults } from '@betterboards/shared/types/ResultSet'
import { parseMarkdownContent } from '@/helpers/text'
import { InputFields } from '@betterboards/shared/types/Platform'

export default Vue.extend({
  components: {
    SignOffButton,
    ReportContextButton,
    PlatformWYSIWYG,
    PlatformSpinner
  },
  props: {
    section: Object as PropType<SectionResults>,
    survey: Object,
    responses: Array,
    reportSection: Object as PropType<PlatformReportSection>,
    loading: { type: Boolean, default: false }
  },
  data () {
    return {
      subSectionIntroductions: <SubSectionIntroduction[]>[],
      MaxFieldLengths,
      InputFields
    }
  },
  async mounted (): Promise<void> {
    this.initSubSections()
  },
  watch: {
    subSections: 'initSubSections'
  },
  computed: {
    ready (): boolean {
      if (this.subSections.length) {
        // Has SubSections data
        return true
      }
      // Has ReportSection
      return !!this.reportSection
    },
    selectedReport (): PlatformReport | null {
      return this.$store.getters['Report/selectedReport']
    },
    subSections (): { name: string, slug: string, reviewedAt?: string }[] {
      if (!this.section?.averages?.subSections?.length) {
        return []
      }
      return this.section.averages.subSections.map((subSection) => {
        const ssIntro = this.reportSection?.subSectionIntroductions?.find((ss) => ss && ss.slug === subSection.slug)
        return {
          name: subSection.name,
          slug: subSection.slug,
          reviewedAt: ssIntro?.reviewedAt ?? undefined
        }
      })
    },
    currentContent: {
      get (): string | undefined {
        if (this.reportSection?.introduction && this.reportSection?.introduction.length > 0) {
          return this.reportSection.introduction
        }

        return undefined
      },
      set (val: string | undefined): void {
        if (this.reportSection) {
          const reportSection = {
            ...this.reportSection,
            introduction: val
          }
          this.$emit('updateReportSection', reportSection)
        }
      }
    },
    sectionIntroReportResult (): ReportSectionField | undefined {
      return this.reportSection.results.find((r: ReportSectionResult) => {
        if (r.questionId) {
          return false
        }
        const reportSectionFieldResult = r as ReportSectionField
        return reportSectionFieldResult.field === 'section-intro'
      }) as ReportSectionField | undefined
    },
    tooltip (): string {
      return this.$t('analysis.introduction.tooltip') as string
    },
    reportContextButtonKey (): ReportContextItem.SectionIntroduction {
      return ReportContextItem.SectionIntroduction
    },
    textFieldLimit (): number {
      const sectionIntroFieldOverride: number | undefined = this.selectedReport?.configuration?.maxFieldLengths?.sections?.[this.section.id]?.[InputFields.SectionIntroduction]
      if (sectionIntroFieldOverride) {
        return sectionIntroFieldOverride
      }
      return MaxFieldLengths[InputFields.SectionIntroduction]
    }
  },
  methods: {
    initSubSections (): void {
      this.subSectionIntroductions = this.reportSection?.subSectionIntroductions as SubSectionIntroduction[] ?? []
    },
    getCurrentContentBySlug (slug: string): string | null | undefined {
      if (!this.subSectionIntroductions) {
        return
      }
      const item = this.subSectionIntroductions.find((item: any) => item.slug === slug)
      if (!item) {
        return ''
      }
      return item.value
    },
    saveUpdate (val): void {
      if (!this.reportSection) {
        console.error('Unable to save section introduction as no report section is loaded')
        return
      }
      this.currentContent = val
    },
    saveSubSectionUpdate (slug: string, val: Pick<SubSectionIntroduction, 'value' | 'reviewedAt'>): void {
      if (!this.reportSection) {
        console.error('Unable to save sub-section introduction as no report section is loaded')
        return
      }
      const subSectionIntroductions = this.subSectionIntroductions
      const index = subSectionIntroductions.findIndex((item: any) => item.slug === slug)
      if (index === -1) {
        subSectionIntroductions.push({ slug, ...val } as any)
      } else {
        subSectionIntroductions.splice(index, 1, {
          ...subSectionIntroductions[index],
          ...val
        })
      }
      const reportSection = {
        ...this.reportSection,
        subSectionIntroductions
      }
      this.$emit('updateReportSection', reportSection)
    },
    toggleSectionIntroReviewed (): void {
      const results = this.reportSection.results
      const index = results.findIndex((r: ReportSectionResult) => {
        if (r.questionId) {
          return false
        }
        const reportSectionFieldResult = r as ReportSectionField
        return reportSectionFieldResult.field === 'section-intro'
      })
      if (index === -1) {
        results.push({
          field: 'section-intro',
          reviewedAt: (new Date()).toISOString()
        } as ReportSectionField)
      } else {
        results.splice(index, 1, {
          ...results[index],
          reviewedAt: results[index].reviewedAt ? null : (new Date()).toISOString()
        } as ReportSectionField)
      }
      const reportSection = {
        ...this.reportSection,
        results
      }
      this.$emit('updateReportSection', reportSection)
    },
    toggleSubSectionIntroReviewed (slug: string, currentReviewedStatus?: boolean): void {
      this.saveSubSectionUpdate(slug, { reviewedAt: currentReviewedStatus ? null : (new Date()).toISOString() })
    },
    handleInsertRecommendedContent (content: string, subSectionSlug?: string | undefined): void {
      if (subSectionSlug) {
        const subSectionIntroductions = this.subSectionIntroductions
        const currentSubSectionIntro: SubSectionIntroduction | undefined = subSectionIntroductions.find(
          (item: SubSectionIntroduction) => item.slug === subSectionSlug
        ) ?? undefined
        const newValue = currentSubSectionIntro?.value
          ? `${currentSubSectionIntro.value}\n\n${content}`
          : content
        const newSubSectionIntro: Partial<SubSectionIntroduction> = {
          ...currentSubSectionIntro,
          value: parseMarkdownContent(newValue)
        }
        this.saveSubSectionUpdate(subSectionSlug, newSubSectionIntro)
        return
      }
      const newContent = this.currentContent
        ? `${this.currentContent}\n\n${content}`
        : content
      this.saveUpdate(
        parseMarkdownContent(newContent)
      )
    },
    getSubSectionTextFieldLimit (subSectionSlug: string): number {
      const subSectionIntroFieldOverride: number | undefined = this.selectedReport?.configuration?.maxFieldLengths?.sections?.[subSectionSlug]?.[InputFields.SectionIntroductionCompact]
      if (subSectionIntroFieldOverride) {
        return subSectionIntroFieldOverride
      }
      const sectionIntroFieldOverride: number | undefined = this.selectedReport?.configuration?.maxFieldLengths?.sections?.[this.section.id]?.[InputFields.SectionIntroductionCompact]
      if (sectionIntroFieldOverride) {
        return sectionIntroFieldOverride
      }
      return MaxFieldLengths[InputFields.SectionIntroductionCompact]
    }
  }
})
</script>
