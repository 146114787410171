<template>
  <v-text-field
    class="platform-number-field"
    v-bind="attrs"
    v-on="on"
    v-model="inputValue"
    ref="input"
    type="number"
    :hide-spin-buttons="hideSpinButtons"
    :readonly="readonly"
    :label="required ? `${label || ''} *` : label"
    :rules="validationRules"
    :outlined="outlined"
    :solo="solo"
    :flat="flat"
    :hide-details="hideDetails"
    :loading="loading"
    :disabled="disabled"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :placeholder="placeholder"
    :min="min"
    :max="max"
    @focus="$emit('focus')"
  >
    <input-label slot="label" :label="label" :required="required" />
    <template
      v-if="$slots.append"
      #append
    >
      <slot
        name="append"
      />
    </template>
    <template
      v-if="$slots.default"
      #default
    >
      <slot
        name="default"
      />
    </template>
  </v-text-field>
</template>

<script lang="ts">
import Vue from 'vue'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'
import { InputFieldMixin } from '@/components/mixins/InputField'

export default Vue.extend({
  name: 'PlatformNumberField',
  components: {
    InputLabel
  },
  mixins: [InputFieldMixin],
  props: {
    value: { type: Number, required: false },
    min: { type: Number, required: false },
    max: { type: Number, required: false },
    hideSpinButtons: { type: Boolean, required: false }
  }
})
</script>
