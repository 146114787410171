<template>
  <div class="grow">
    <h3 class="sans-serif font-weight-bold primary--text mb-4">
      {{ this.title || $t('analysis.keyFindings.header') }}
    </h3>

    <v-row
      v-if="reportContextButtonKey"
      no-gutters
      justify="end"
      class="pb-4"
    >
      <report-context-button
        :value="reportContextButtonKey"
        :result="result"
        :report-result="reportResult"
        :section="section"
        :master-section-id="reportResult.masterSectionId"
        :master-block-id="reportResult.masterBlockId"
        :criteria-id="reportResult.criteriaId"
        @insertRecommendation="handleInsertRecommendedSummaryContent"
      />
    </v-row>

    <platform-text-area
      outlined
      auto-grow
      :rows="4"
      :label="$t('analysis.summary.title')"
      :rules="form.validation.summary"
      :value="answer.summary"
      ref="summary"
      @input="(val) => handleSummaryInput(val)"
    >
      <template #append>
        <platform-status-indicator
          v-if="answer.summary || answer.values"
          icon-only
          :warning-tooltip="$t('platformStatusIndicator.warningTooltip')"
          :loading="form.savingSummary"
          :saved="form.savedSummary"
          :warning="$refs.summary ? !$refs.summary.valid : false"
        />
      </template>
    </platform-text-area>

    <v-row
      no-gutters
      v-for="(item, i) in fields"
      :key="i"
      class="pt-2"
    >
      <platform-text-area
        auto-grow
        outlined
        ref="findings"
        :value="answer.values[i]"
        :rows="1"
        :label="`${$t('analysis.keyFindings.text', [i + 1])}`"
        :rules="form.validation.value"
        @input="(val) => handleInput(i, val)"
      >
        <template #append>
          <platform-status-indicator
            v-if="answer.values || answer.summary"
            icon-only
            :warning-tooltip="$t('platformStatusIndicator.warningTooltip')"
            :loading="form.savingValues.includes(i)"
            :saved="form.savedValues.includes(i)"
            :warning="$refs.findings && $refs.findings[i] ? !$refs.findings[i].valid : false"
          />
        </template>
      </platform-text-area>
    </v-row>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { debounce } from 'lodash'
import { mapActions } from 'vuex'
import { CommitteeSummaryField, ResponsesResultKeyFindings } from '@/types/Report'
import { MaxFieldLengths, Validators } from '@/helpers/validation'
import PlatformTextArea from '@/components/shared/inputs/PlatformTextArea.vue'
import PlatformStatusIndicator from '@/components/shared/PlatformStatusIndicator.vue'
import { ReportField } from '@betterboards/shared/helpers/entities/Report/index'
import { PlatformSection } from '@betterboards/shared/types/Platform'
import ReportContextButton from '@/components/analysis/ReportContextButton.vue'
import { ReportContextItem } from '@/helpers/recommendations'
import { ResponsesResult } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'KeyFindingAnswer',
  components: {
    ReportContextButton,
    PlatformStatusIndicator,
    PlatformTextArea
  },
  props: {
    result: Object as PropType<ResponsesResult>,
    reportResult: Object as PropType<ResponsesResultKeyFindings | CommitteeSummaryField>,
    section: { type: Object as PropType<PlatformSection>, required: true },
    fields: { type: Number, default: 5 },
    title: { type: String, required: false }
  },
  data () {
    return {
      answer: <ResponsesResultKeyFindings | CommitteeSummaryField>{
        field: undefined,
        questionId: undefined,
        criteriaId: undefined,
        groupId: undefined,
        summary: <string | undefined>undefined,
        values: <Array<string | undefined>>[]
      },
      form: {
        validation: {
          summary: [Validators.maxLength(MaxFieldLengths.KeyFindingsSummary)],
          value: [Validators.maxLength(MaxFieldLengths.KeyFindingsValue)]
        },
        saving: false,
        saved: false,
        savingSummary: false,
        savedSummary: false,
        savingValues: <number[]>[],
        savedValues: <number[]>[]
      },
      updateResult: <undefined | (() => void)>undefined,
      MaxFieldLengths
    }
  },
  mounted () {
    this.init()
    this.updateResult = debounce(this._updateResult, 1500, {
      leading: false,
      trailing: true
    })
  },
  watch: {
    reportResult (): void {
      this.form.saved = true
      this.form.saving = false
      this.init()
      this.setSavedStatus()
    }
  },
  computed: {
    reportContextButtonKey (): ReportContextItem | undefined {
      if (this.isCommitteeStructureResult) {
        return ReportContextItem.CommitteesStructurePage
      }
      return ReportContextItem.KeyFindings
    },
    isCommitteeStructureResult (): boolean {
      return this.reportResult.field === ReportField.CommitteeSummary
    }
  },
  methods: {
    ...mapActions('Report', ['saveReportSection']),
    init (): void {
      if (!this.reportResult) {
        return
      }
      if (!this.reportResult.questionId && !this.reportResult.field) {
        console.error('Failed to init KeyFindingAnswer, ReportResult missing questionId or field:', this.reportResult)
        throw new Error('Key Finding Answer missing key fields.')
      }
      this.answer.field = this.reportResult.field
      this.answer.questionId = this.reportResult.questionId
      this.answer.criteriaId = this.reportResult.criteriaId
      this.answer.groupId = this.reportResult.groupId
      if (this.reportResult.summary && !this.answer.summary) {
        this.answer.summary = this.reportResult.summary
      }
      if (!this.reportResult.values) {
        return
      }
      if (!this.answer.values) {
        this.answer.values = []
      }
      if (this.answer.values.length) {
        return
      }
      this.answer.values.splice(0, this.answer.values.length, ...this.reportResult.values)
    },
    handleSummaryInput (val: string) {
      if (!this.updateResult) {
        return
      }
      const summary: any = this.$refs.summary
      if (!summary || !summary.validate()) {
        return
      }
      this.answer.summary = val
      this.form.saved = false
      this.form.saving = true
      this.form.savedSummary = false
      this.form.savingSummary = true
      this.updateResult()
    },
    handleInput (index: number, val: string) {
      if (!this.updateResult) {
        return
      }
      const input: any = this.$refs.findings?.[index]
      const valid = input?.validate()
      if (!input || !valid) {
        return
      }
      if (!this.answer.values) {
        this.answer.values = []
      }
      if (this.answer.values.length < index) {
        for (let i = this.answer.values.length; i < index; i++) {
          this.answer.values.push('')
        }
      }
      this.answer.values.splice(index, 1, val)
      this.form.saved = false
      this.form.saving = true

      this.setValueSavingStatus(index)
      this.updateResult()
    },
    _updateResult (): void {
      this.$emit('update', this.answer)
    },
    handleInsertRecommendedSummaryContent (content: string): void {
      const newContent = this.answer.summary
        ? `${this.answer.summary}\n\n${content}`
        : content
      this.handleSummaryInput(newContent)
    },
    setSavedStatus (): void {
      if (!this.form.savedSummary) {
        this.form.savedSummary = this.form.savingSummary
      }

      this.form.savingValues.forEach(this.setValueSavedStatus)

      this.form.savingSummary = false
      this.form.savingValues.splice(0, this.form.savedValues.length)
    },
    setValueSavedStatus (index: number): void {
      const savedExistsIndex: number = this.form.savedValues.indexOf(index)
      if (savedExistsIndex === -1) {
        this.form.savedValues.push(index)
      }
    },
    setValueSavingStatus (index: number): void {
      const savedExistsIndex: number = this.form.savedValues.indexOf(index)
      if (savedExistsIndex !== -1) {
        this.form.savedValues.splice(savedExistsIndex, 1)
      }

      const savingExistsIndex: number = this.form.savingValues.indexOf(index)
      if (savingExistsIndex === -1) {
        this.form.savingValues.push(index)
      }
    }
  }
})
</script>
