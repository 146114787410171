<template>
  <v-list class="analysis-sidebar pb-0">
    <h2 class="text-center mb-4" v-if="resultSet">{{ resultSet.name }}</h2>
    <v-list-item
      v-for="link in startLinks"
      :key="link.route"
      :ref="`systemPage-${link.slug}`"
      :to="{
        name: link.route,
        params: {
          surveyId: $route.params.surveyId
        }
      }"
    >
      <v-list-item-icon>
        <platform-icon
          :color="link.icon && (!link.requiresCompletion || link.completed) ? 'white' : 'grey darken-3'"
          :name="link.icon || 'check-outline'"
        />
      </v-list-item-icon>

      <v-list-item-content>
        <span class="section-name">
          {{ link.name }}
        </span>
      </v-list-item-content>
    </v-list-item>
    <platform-spinner
      v-if="pendingFetch"
      class="mx-auto my-3"
      :size="20"
      :width="2"
    />
    <template v-else>
      <v-list-item
        v-for="(section, index) in sections"
        :key="`${section ? section.id : ''}-${index}`"
        ref="sectionLink"
        :class="{
          'v-list-item--active': $route.params.sectionId === section.id
        }"
        :to="{
          name: 'AnalysisSection',
          params: {
            surveyId: $route.params.surveyId,
            sectionId: section.id,
            tab: $route.params.tab
          }
        }"
      >
        <v-list-item-icon>
          <platform-icon
            name="check-outline"
            class="section-completion"
            :class="{
              completed: !!section.reviewed
            }"
            :color="!!section.reviewed ? 'secondary' : 'grey darken-3'"
          />
        </v-list-item-icon>

        <v-list-item-content>
          <span class="section-name">
            {{ section.name }}
          </span>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-list-item
      v-for="link in endLinks"
      :key="link.route"
      :ref="`systemPage-${link.slug}`"
      :to="{
        name: link.route,
        params: {
          surveyId: $route.params.surveyId
        }
      }"
    >
      <v-list-item-icon>
        <platform-icon
          :color="link.icon && (!link.requiresCompletion || link.completed) ? 'white' : 'grey darken-3'"
          :name="link.icon || 'check-outline'"
        />
      </v-list-item-icon>

      <v-list-item-content>
        <span class="section-name">
          {{ link.name }}
        </span>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import { SystemPage, SystemPageConfig, SystemPages } from '@/store/Analysis'
import { QuestionnaireVariantCode } from '@/types/Platform'
import { PlatformReport, PlatformReportSection } from '@/types/Report'
import Vue from 'vue'
import {
  mapMutations,
  mapState
} from 'vuex'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { ProgressSection, SectionResults } from '@betterboards/shared/types/ResultSet'
import sortByDisplayOrder from '@betterboards/shared/helpers/util/sortByDisplayOrder'

interface TranslatedSystemPageConfig extends SystemPageConfig {
  name?: string
  completed?: boolean
  requiresCompletion?: boolean
}

export default Vue.extend({
  name: 'AnalysisSidebar',
  components: {
    PlatformSpinner
  },
  beforeMount () {
    this.setAnalysisLinks()
  },
  beforeDestroy () {
    this.setDefaultLinks()
  },
  watch: {
    '$route.params.sectionId' (val: string): void {
      const index = this.sections.findIndex((s) => s.id === val)
      if (index === -1) {
        return
      }
      const sectionLink: any = this.$refs.sectionLink?.[index]
      sectionLink.$el.scrollIntoView({
        block: 'nearest'
      })
    },
    activeSystemPage (val: typeof SystemPages[keyof typeof SystemPages] | undefined): void {
      if (val?.slug) {
        const link: any = this.$refs[`systemPage-${val.slug}`]?.[0] // Refs are in a v-for but uniquely keyed
        if (link?.$el.scrollIntoView) {
          link.$el.scrollIntoView({
            block: 'nearest'
          })
        }
      }
    }
  },
  computed: {
    ...mapState('Analysis', ['resultSet', 'pendingFetch']),
    activeSystemPage (): typeof SystemPages[keyof typeof SystemPages] | undefined {
      return Object.values(SystemPages).find((p) => p.route === this.$route.name)
    },
    resultSetSections (): ProgressSection[] {
      return this.$store.getters['Report/resultSetSections']
    },
    sections (): SectionResults[] {
      if (!this.resultSetSections) {
        return []
      }
      const sections = this.resultSetSections.filter((section: ProgressSection) => !!section) as ProgressSection[]
      return sections.sort(sortByDisplayOrder)
    },
    startLinks (): SystemPageConfig[] {
      return this.systemPages.filter((page) => page.position === 'start')
    },
    endLinks (): SystemPageConfig[] {
      return this.systemPages.filter((page) => page.position === 'end')
    },
    systemPages (): TranslatedSystemPageConfig[] {
      return (Object.values(SystemPages) as TranslatedSystemPageConfig[])
        .map((page: TranslatedSystemPageConfig) => {
          page.name = this.$t(`nav.analysis.${page.slug}`) as string
          if (this.pageRequiresCompletion(page)) {
            page.requiresCompletion = true
            if (this.pendingFetch) {
              page.completed = false
            } else {
              page.completed = this.isPageCompleted(page)
            }
          }
          return page
        })
    },
    selectedVariant (): QuestionnaireVariantCode | undefined {
      return this.$store.state.Analysis.variantCode
    },
    report (): PlatformReport | null {
      return this.$store.state.Report.report
    },
    reportSections (): PlatformReportSection[] {
      if (!this.report?.sections?.items) {
        return []
      }
      return this.report.sections.items as PlatformReportSection[]
    }
  },
  methods: {
    ...mapMutations('Navigation', ['setAnalysisLinks', 'setDefaultLinks']),
    pageRequiresCompletion (page: TranslatedSystemPageConfig): boolean {
      switch (page.slug) {
        case SystemPage.EvaluationInformation:
        case SystemPage.FocusAreas:
          return true
        default:
          return false
      }
    },
    isPageCompleted (page: TranslatedSystemPageConfig): boolean {
      switch (page.slug) {
        case SystemPage.EvaluationInformation:
          return !!this.report?.summary
        case SystemPage.FocusAreas:
          return !!this.report?.focusAreas
        default:
          return false
      }
    }
  }
})
</script>

<style lang="scss">
.analysis-sidebar {
  .section-completion {
    i {
      background: linear-gradient(to bottom, var(--v-grey-darken1), var(--v-grey-darken2));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &.completed {
      i {
        background: linear-gradient(to bottom, var(--v-secondary-lighten2), var(--v-secondary-base));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  .v-list-item--active {
    .section-completion:not(.completed) {
      i {
        background: linear-gradient(to bottom, var(--v-grey-darken3), var(--v-grey-darken4));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .section-name {
      font-weight: bold;
      letter-spacing: -0.1px;
    }
  }
}
</style>
