import { Section } from '@/API'
import { persistSection } from '@/helpers/questionnaire'
import { Module } from 'vuex'
import Vue from 'vue'
import API from '@/services/API'
import { deleteSection } from '@/graphql/mutations'
import { PlatformSection } from '@betterboards/shared/types/Platform'

interface SectionStore {
  placeholder?: undefined
}

const defaultState = (): SectionStore => ({
  //
})

const SectionState: Module<SectionStore, any> = {
  namespaced: true,
  state: defaultState(),
  actions: {
    deleteSection ({ commit }: any, section: Section | undefined) {
      if (!section?.id || !section.variantCode) {
        console.warn('Failed to delete section due to missing ID or variantCode.', { section })
        return
      }
      API.graphql({
        query: deleteSection,
        variables: {
          input: {
            id: section.id,
            variantCode: section.variantCode
          }
        }
      })
        .catch((e: Error) => {
          console.error('error deleting section', e)
          Vue.toasted.error('There was an error deleting the section')
        })
    },
    async updateSection ({ commit }: any, { section, questionnaireId }: { section: PlatformSection, questionnaireId?: string }): Promise<PlatformSection | undefined> {
      return await persistSection(section, undefined, questionnaireId)
    }
  }
}

export default SectionState
