import { CriteriaGroup, CriteriaItem, QuestionnaireCustomContent } from '../../../types/Content'

export default function getUpdatedCustomContent (
  questionnaireCustomContent: QuestionnaireCustomContent | undefined,
  newCriteriaGroupItems: CriteriaGroup[],
  changedCriteriaGroupItems: CriteriaGroup[],
  deletedCriteriaGroupItems: CriteriaGroup[]
): QuestionnaireCustomContent | undefined {
  if (!questionnaireCustomContent) {
    return undefined
  }
  const criteriaGroupSlugs: string[] = Object.keys(questionnaireCustomContent)
  const customContent: QuestionnaireCustomContent = criteriaGroupSlugs.reduce<QuestionnaireCustomContent>(
    (customContent: QuestionnaireCustomContent, criteriaGroupSlug: string) => {
      customContent[criteriaGroupSlug] = [...questionnaireCustomContent?.[criteriaGroupSlug]]
      return customContent
    },
    {}
  )

    newCriteriaGroupItems.forEach((criteriaGroup: CriteriaGroup): void => {
    criteriaGroup.items.forEach((criteriaItem: CriteriaItem): void => {
      const index = customContent[criteriaGroup.slug].findIndex(
        (c) => c.slug === criteriaItem.slug && c.variantCode === criteriaItem.variantCode
      )
      if (index === -1) {
        customContent[criteriaGroup.slug].push(criteriaItem)
      }
    })
  })
  newCriteriaGroupItems.splice(0, newCriteriaGroupItems.length)

  changedCriteriaGroupItems.forEach((criteriaGroup: CriteriaGroup): void => {
    criteriaGroup.items.forEach((criteriaItem: CriteriaItem): void => {
      const index = customContent[criteriaGroup.slug].findIndex(
        (c) => c.slug === criteriaItem.slug && c.variantCode === criteriaItem.variantCode
      )
      if (index !== -1) {
        customContent[criteriaGroup.slug].splice(index, 1, criteriaItem)
      }
    })
  })
  changedCriteriaGroupItems.splice(0, changedCriteriaGroupItems.length)

  deletedCriteriaGroupItems.forEach((criteriaGroup: CriteriaGroup): void => {
    criteriaGroup.items.forEach((criteriaItem: CriteriaItem): void => {
      const index = customContent[criteriaGroup.slug].findIndex(
        (c) => c.slug === criteriaItem.slug && c.variantCode === criteriaItem.variantCode
      )
      if (index !== -1) {
        customContent[criteriaGroup.slug].splice(index, 1)
      }
    })
  })
  deletedCriteriaGroupItems.splice(0, deletedCriteriaGroupItems.length)

  return customContent
}
