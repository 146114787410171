import { Individual } from '../../../types/API'
import { PlatformIndividual } from '../../../types/Individual'

export function getPreferredName (individual: Individual | PlatformIndividual): string {
  if (individual.preferredName) {
    return individual.preferredName
  }

  return [individual.title, individual.firstName, individual.familyName].filter((item) => item).join(' ')
}
