var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"flex-column",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"shrink pt-2"},[_c('span',{staticClass:"text-body-2 valid-hint",class:{
        'grey--text text--darken-2': _vm.isValid,
        'warning--text': !_vm.isValid,
      }},[_vm._v(" "+_vm._s(_vm.$t('page.viewSurvey.questions.MultipleChoice.tip'))+" ")])]),_c('v-col',{staticClass:"grow"},[_c('v-row',{ref:"options",staticClass:"multichoicequestion options flex-grow-1 flex-wrap pt-2",class:{
        'single-row': _vm.singleRow
      },attrs:{"justify":"center","align":"stretch"}},[(_vm.question.optionsType === _vm.RepeatForOptions.BoardMembers)?_vm._l((_vm.question.optionValues),function({ individualId, individual: { preferredName, firstName, familyName } }){return _c('v-col',{key:individualId,staticClass:"option flex-grow-0 d-flex justify-center",style:(_vm.optionStyle)},[_c('div',{staticClass:"checkbox d-flex flex-column",class:{
              'selected': _vm.isSelected(individualId),
              'disabled': _vm.isPreview,
            },on:{"click":function($event){return _vm.selectAnswer(individualId)}}},[_c('div',{staticClass:"flex-grow-1 d-flex"},[(individualId && _vm.questionnaire.companyId)?_c('platform-avatar',{staticClass:"avatar mb-4 mx-auto elevation-5",attrs:{"entity-type":"individual","placeholder-size":"xl","internal-loader":"","size":null,"entity":{ id: individualId, companyId: _vm.questionnaire.companyId, preferredName, firstName, familyName }}}):_vm._e()],1),_c('span',{staticClass:"flex-shrink-1 grey--text text--darken-3"},[_vm._v(" "+_vm._s(preferredName || firstName)+" ")])])])}):_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }