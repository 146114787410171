<template>
  <v-row>
    <v-col :cols="10">
      <v-form ref="form" v-model="formValid" @submit.prevent="">
        <v-form-base
          class="no-col-gutters"
          :model="section"
          :schema="sectionSchema"
          @input="onInput"
        />
      </v-form>
    </v-col>
    <v-col :cols="2" class="text-center">
      <v-row class="pt-3" justify="end">
        <v-col v-show="hasMultipleVariants" shrink :cols="12" md="auto">
          <v-row justify="end" align="start" class="flex-wrap pb-2 pr-2">
            <variant-picker
              v-model="activeQuestionnaireVariant"
              entity-type="section"
              :selected-questionnaire-variant="selectedQuestionnaireVariant"
              :variants="variants"
            />
          </v-row>
        </v-col>
        <v-col shrink :cols="12" :md="hasMultipleVariants ? 'auto' : 12">
          <v-row justify="end" align="start" class="flex-wrap">
            <platform-button
              secondary
              icon="duplicate"
              class="ml-2 mb-2"
              v-tooltip="$t('form.questionnaire.sections.duplicateSectionAction')"
              :disabled="disabled"
              @click="$emit('duplicate')"
            />
            <platform-button
              warning
              icon="delete"
              class="ml-2 mb-2"
              v-tooltip="$t('form.questionnaire.sections.deleteSectionAction')"
              :disabled="disabled"
              @click="$emit('delete')"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col :cols="12">
      <blocks-list
        v-if="activeQuestionnaireVariant"
        :value="activeVariantBlocks"
        :selected-questionnaire-variant="activeQuestionnaireVariant"
        :variants="variants"
        :disabled="disabled"
        :section-id="section.id"
        @valid="(val) => blocksValid = val"
        @updateBlock="(val) => $emit('updateBlock', val)"
        @deleteBlock="(val) => $emit('deleteBlock', val)"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { LanguageCode } from '@/API'
import VariantPicker from '@/components/shared/VariantPicker.vue'
import Vue, { PropType } from 'vue'
import VFormBase from 'vuetify-form-base'
import { PlatformBlock, PlatformQuestionnaireVariant, PlatformSection, QuestionnaireVariantCode } from '@/types/Platform'
import BlocksList from '@/components/forms/blocks/BlocksList.vue'

export default Vue.extend({
  name: 'SectionForm',
  props: {
    sectionVariants: { type: Object as PropType<{ [variantCode: string]: PlatformSection }>, required: true }, // Array of variants of this Section
    selectedQuestionnaireVariant: { type: String as PropType<QuestionnaireVariantCode>, required: false },
    variants: { type: Array as PropType<PlatformQuestionnaireVariant[]>, required: true },
    questionnaireId: { type: String, required: false },
    disabled: { type: Boolean, default: false }
  },
  components: {
    VariantPicker,
    BlocksList,
    VFormBase
  },
  data: () => ({
    activeQuestionnaireVariant: <QuestionnaireVariantCode | undefined>undefined,
    formValid: false,
    blocksValid: false
  }),
  watch: {
    isValid (val: boolean): void {
      this.$emit('valid', val)
    }
  },
  mounted (): void {
    this.activeQuestionnaireVariant = this.selectedQuestionnaireVariant
  },
  computed: {
    defaultVariant (): QuestionnaireVariantCode | undefined {
      return this.variants?.[0]?.variantCode as QuestionnaireVariantCode | undefined
    },
    section: {
      get (): PlatformSection | undefined {
        if (!this.activeQuestionnaireVariant || !this.defaultVariant) {
          return undefined
        }
        const section = this.sectionVariants?.[this.activeQuestionnaireVariant]
        if (section) {
          return section
        }
        const defaultSection = this.sectionVariants?.[this.defaultVariant]
        return {
          ...defaultSection,
          variantCode: this.activeQuestionnaireVariant as LanguageCode,
          questionnaireId: this.questionnaireId,
          companyId: this.$store.state.Company.selectedCompany.id,
          __typename: undefined,
          createdAt: undefined,
          updatedAt: undefined
        } as Partial<PlatformSection> as PlatformSection
      },
      set (val: PlatformSection): void {
        this.$emit('updateSection', {
          ...val,
          variantCode: this.activeQuestionnaireVariant ?? val.variantCode
        })
      }
    },
    sectionSchema (): any {
      return {
        name: { type: 'BBTextField', label: 'Section Title', class: 'pr-4', col: 12, disabled: this.disabled, required: true }
      }
    },
    activeVariantBlocks (): PlatformBlock[] {
      return Object.values(this.sectionVariants).reduce<PlatformBlock[]>((blocks, section) => {
        section?.blocks?.items?.forEach((block) => {
          if (blocks.find((b) => b.id === block.id && b.variantCode === block.variantCode)) {
            return
          }
          blocks.push(block)
        })
        return blocks
      }, [])
    },
    hasMultipleVariants (): boolean {
      return this.variants.length > 1
    },
    isValid (): boolean {
      return this.formValid && this.blocksValid
    }
  },
  methods: {
    onInput ({ data }: any): void {
      this.section = data
    }
  }
})
</script>
