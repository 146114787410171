import VueI18n from 'vue-i18n'
import Vue from 'vue'
import { setTranslatorMethod, TranslationMessages } from '@betterboards/shared/translations'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  messages: TranslationMessages,
  silentFallbackWarn: true
})

setTranslatorMethod(
  (key: string, ...args: any[]): string => {
    if (typeof args[0] === 'number') {
      return i18n.tc(key, ...args)
    }
    return i18n.t(key, ...args) as string
  }
)
