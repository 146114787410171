import { MultiTextQuestionGroup } from '../../../types/Question'
import { $t } from '../../../translations/index'
import { PlatformBlock, QuestionBlock } from '../../../types/Platform'
import { BlockType } from '../../../types/API'

export enum RepeatForOptions {
  None = 'None',
  BoardMembers = 'BoardMembers',
  Committees = 'Committees',
  KnowhowAreas = 'KnowhowAreas',
  Roles = 'Roles'
}

export enum QuestionTypes {
  Text = 'text',
  MultiText = 'multi-text',
  Scale = 'scale',
  MultipleChoice = 'MultipleChoice',
}

export enum ScaleCriteriaOptions {
  Agreement = 'Agreement',
  Importance = 'Importance',
  Development = 'Development'
}

export enum MultiTextGroup {
  Strengths = 'strengths',
  Situations = 'situations',
  Top = 'top',
  More = 'more',
  Less = 'less',
  Other = 'other'
}

export function getDefaultMultiTextHeader (group: MultiTextQuestionGroup): string | undefined {
  switch (group.slug) {
    case MultiTextGroup.Strengths:
      return $t('report.results.strengthsHeader') as string
    case MultiTextGroup.Situations:
      return $t('report.results.situationsHeader') as string
  }
}

export function normalizeSlug (slug: string): string {
  return slug
    .toLowerCase()
    .replace(/\s/g, '-')
    .replace(/[^-a-zA-Z0-9]+/g, '')
}

export function isQuestionBlock (block: PlatformBlock | undefined): block is QuestionBlock {
  if (!block) {
    return false
  }

  return block.variant === BlockType.Question
}
