<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24">
    <g fill="currentColor">
      <path d="m 6 3.75 l 2.1225 -1.23 l 0.75 1.305 l -2.1225 1.2225 l 2.1225 1.2225 l -0.75 1.305 l -2.1225 -1.23 v 2.4525 h -1.5 v -2.4525 l -2.1225 1.23 l -0.75 -1.305 l 2.1225 -1.2225 l -2.1225 -1.2225 l 0.75 -1.305 l 2.1225 1.23 v -2.4525 h 1.5 v 2.4525 z"/>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'StateModifiedAsterisk'
})
</script>
