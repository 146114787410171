<template>
  <svg
    class="scale-graph"
    ref="graph"
    :style="{
      maxWidth: width ? `${width}px` : undefined,
      maxHeight: height ? `${height}px` : undefined
    }"
  />
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { createGraph, ScaleGraph } from '@betterboards/graphs/index'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import { ScaleGraphConfig, ScaleGraphData } from '@betterboards/graphs/variants/ScaleGraph'
import { mapState } from 'vuex'

function DefaultData () {
  return {
    graph: undefined as undefined | ScaleGraph
  }
}

export default Vue.extend({
  name: 'ScaleGraph',
  props: {
    data: {
      type: Object as PropType<ScaleGraphData>,
      required: false
    },
    comparisonData: {
      type: Object as PropType<ScaleGraphData | undefined>,
      required: false
    },
    config: {
      type: Object as PropType<Partial<ScaleGraphConfig>>,
      required: false
    }
  },
  data: DefaultData,
  mounted (): void {
    this.renderGraph()
  },
  watch: {
    data: 'renderGraph',
    'user.defaultLanguage': 'renderGraph'
  },
  computed: {
    ...mapState('User', ['user']),
    xScale (): number {
      if (this.config.xScale) {
        return this.config.xScale
      }
      return 1
    },
    yScale (): number {
      if (this.config.yScale) {
        return this.config.yScale
      }
      return 1
    },
    width (): number {
      if (this.config.legend) {
        return 550 * this.xScale
      }
      return 300 * this.xScale
    },
    height (): number {
      if (this.config.tickLabels) {
        if (this.yScale < 1) {
          return 12 - 12 * this.yScale
        }
        return 12
      }
      if (this.config.legend) {
        if (this.config.comparison) {
          return 40 * this.yScale
        }
        return 22 * this.yScale
      }
      if (this.comparisonData) {
        return 40 * this.yScale
      }
      return 35 * this.yScale
    }
  },
  methods: {
    renderGraph (): any {
      const comparison = !!this.comparisonData || this.config.comparison
      const config: Partial<ScaleGraphConfig> = {
        width: this.width,
        height: this.height,
        comparison,
        ...this.config,
        margin: {
          left: 8,
          right: 8,
          top: this.config.tickLabels ? -4 : (this.config.legend ? -10 : 3),
          bottom: this.config.tickLabels ? 18 : (comparison ? 3 : 0),
          ...this.config.margin
        }
      }

      const data: any | undefined = this.config.legend ? undefined : { ...this.data }
      if (data && this.comparisonData) {
        data.comparison = {
          min: this.comparisonData.min,
          max: this.comparisonData.max,
          avg: this.comparisonData.avg
        }
      }

      const graph = this.graph = createGraph<GraphTypes.Scale>(GraphTypes.Scale, this.$refs.graph as SVGElement, config)
      graph.render(data)
    }
  }
})
</script>

<style lang="scss">
.scale-graph {
  display: block;
  margin: 0 auto;
  width: auto;
  height: 80px;
}
</style>
