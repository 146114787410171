<template>
  <div>
    <v-expansion-panels v-model="menuExpanded" accordion flat hover>
      <v-expansion-panel class="platform--expansion-panel">
        <v-expansion-panel-header class="header header--sm px-3 py-1">
          <h4 class="primary-header sans-serif text-left">
            {{ $t('analysis.systemPages.responseStatus.individual.detailsHeader') }}
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <h4 class="font-weight-bold sans-serif text-left grey--text text--darken-3 mt-1 mb-2">
            {{ $t('analysis.systemPages.responseStatus.individual.responsesHeader') }}
          </h4>
          <v-row align="top">
            <v-col
              v-tooltip:bottom="$t('analysis.systemPages.responseStatus.individual.answeredQuestionsTooltip', [answeredOnlyQuestions])"
              :cols="12"
              :sm="4"
            >
              <v-row no-gutters class="flex-column" align="center" justify="center">
                <span class="text-caption grey--text text--darken-2">
                  {{ $t('analysis.systemPages.responseStatus.individual.answeredHeader') }}
                </span>
                <span class="text-body-1 font-weight-bold">
                  {{ answeredOnlyQuestions }}
                </span>
              </v-row>
            </v-col>
            <v-col
              v-tooltip:bottom="$t('analysis.systemPages.responseStatus.individual.skippedTooltip', [totals.skippedAnswers])"
              :cols="12"
              :sm="4"
            >
              <v-row no-gutters class="flex-column" align="center" justify="center">
                <span class="text-caption grey--text text--darken-2">
                  {{ $t('analysis.systemPages.responseStatus.individual.skippedHeader') }}
                </span>
                <span class="text-body-1 font-weight-bold">
                  {{ totals.skippedAnswers }}
                </span>
              </v-row>
            </v-col>
            <v-col
              v-tooltip:bottom="$t('analysis.systemPages.responseStatus.individual.uncertainTooltip', [totals.uncertainAnswers])"
              :cols="12"
              :sm="4"
            >
              <v-row no-gutters class="flex-column" align="center" justify="center">
                <span class="text-caption grey--text text--darken-2">
                  {{ $t('analysis.systemPages.responseStatus.individual.uncertainHeader') }}
                </span>
                <span class="text-body-1 font-weight-bold">
                  {{ totals.uncertainAnswers }}
                </span>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ResultsIndividualTotals } from '@betterboards/shared/types/Survey'

export default Vue.extend({
  name: 'IndividualResponseTotals',
  props: {
    totals: { type: Object as PropType<ResultsIndividualTotals>, required: true }
  },
  data: () => ({
    menuExpanded: false
  }),
  computed: {
    answeredOnlyQuestions (): number {
      return this.totals.completedQuestions - this.totals.skippedAnswers - this.totals.uncertainAnswers
    }
  }
})
</script>
