export const Colors = {
  primary: '#24235f',
  secondary: '#e44500',
  black: '#000000',
  white: '#ffffff',
  grey: {
    lightest: '#f2f3f2',
    lighter: '#ebeceb',
    light: '#c7c6c7',
    medium: '#727072',
    dark: '#323032'
  },
  tenureLength: {
    danger: '#c2413d',
    warning: '#efb54b',
    okay: '#86bb45',
    none: '#cbcbcb'
  }
}
