<template>
  <div class="mx-2">
    <v-form ref="form" v-model="formValid" @submit.prevent="">
      <v-form-base
        :model="reportGroup"
        :schema="formSchema"
        @input="handleInput"
      />
    </v-form>
  </div>
</template>

<script lang="ts">
import { Company, LanguageCode } from '@/API'
import { QuestionnaireVariantCode } from '@/types/Platform'
import { PlatformReportGroup } from '@/types/Report'
import Vue from 'vue'
import VFormBase from 'vuetify-form-base'

export default Vue.extend({
  name: 'ReportGroupForm',
  components: {
    VFormBase
  },
  props: {
    value: { type: Object, required: true },
    saving: { type: Boolean, required: false }
  },
  data: () => ({
    formValid: false
  }),
  watch: {
    isValid (val: boolean) {
      this.$emit('valid', val)
    }
  },
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    reportGroup: {
      get (): PlatformReportGroup {
        return this.value
      },
      set (reportGroup: PlatformReportGroup) {
        this.$emit('input', reportGroup)
      }
    },
    availableVariants (): QuestionnaireVariantCode[] {
      return Object.values(LanguageCode) as QuestionnaireVariantCode[]
    },
    formSchema (): any {
      const schema: any = {
        name: {
          type: 'BBTextField',
          label: this.$t('form.reportGroup.name'),
          placeholder: this.reportGroup.name,
          col: 12,
          required: true,
          disabled: this.saving
        }
      }

      if (this.reportGroup.variants?.length && this.reportGroup.variants?.length > 1) {
        schema.variants = {
          type: 'array',
          label: this.$t('form.reportGroup.variants'),
          col: 12,
          schema: {
            variantCode: {
              type: 'BBSelectField',
              items: this.availableVariants.map((languageCode: string) => ({
                text: this.$t(`global.languages.${languageCode}`),
                value: languageCode
              })),
              label: this.$t('form.reportGroup.variant.languageCode'),
              col: 3,
              readOnly: true,
              hideArrow: true
            },
            spacer: {
              type: 'spacer',
              col: 1
            },
            name: {
              type: 'BBTextField',
              label: this.$t('form.reportGroup.variant.name'),
              col: 8,
              required: true
            }
          }
        }
      }
      return schema
    },
    isValid (): boolean {
      return this.formValid
    }
  },
  methods: {
    validate () {
      const form: any = this.$refs.form
      form.validate()
    },
    handleInput (input): void {
      this.reportGroup = input.data
    }
  }
})
</script>
