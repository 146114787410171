import { ResultsIndividual } from '../../../types/ResultSet'
import { UserAccountStatus } from '../../../types/API'

const AccountStateOrders: UserAccountStatus[] = [
  UserAccountStatus.Enabled,
  UserAccountStatus.EnabledPasswordReset,
  UserAccountStatus.DisabledPasswordReset,
  UserAccountStatus.Created,
  UserAccountStatus.Invited
]

export default function sortResultSetIndividuals (a: ResultsIndividual, b: ResultsIndividual): number {
  if (a.finishedAt && b.finishedAt) {
    return a.finishedAt.localeCompare(b.finishedAt)
  }
  if (a.finishedAt) {
    return -1
  }
  if (b.finishedAt) {
    return 1
  }
  if (!!a.totals?.progress || !!b.totals?.progress) {
    if (!a.totals?.progress) {
      return 1
    }
    if (!b.totals?.progress) {
      return -1
    }
    if (a.totals.progress !== b.totals.progress) {
      return b.totals.progress - a.totals.progress
    }
  }

  if (a.accountStatus === b.accountStatus) {
    if (a.surveyUpdatedAt && b.surveyUpdatedAt) {
      return a.surveyUpdatedAt.localeCompare(b.surveyUpdatedAt)
    }
    return 0
  }
  if (!a.accountStatus) {
    return -1
  }
  if (!b.accountStatus) {
    return 1
  }

  const aIndex = AccountStateOrders.indexOf(a.accountStatus)
  const bIndex = AccountStateOrders.indexOf(b.accountStatus)
  return aIndex - bIndex
}
