<template>
  <v-container fluid class="fill-height">
    <report-config-modal
      v-if="showReportConfigModal && selectedReportGroup && selectedReport"
      :target-tab="reportConfigModalTargetTab"
      @close="handleCloseReportConfigModal"
    />
    <v-row v-if="initialised" class="fill-height">
      <v-col class="grow d-flex flex-column">
        <v-card class="pt-4 pb-3 mb-3">
          <v-row no-gutters>
            <v-col
              :cols="12"
              md="auto"
              class="grow"
            >
              <v-row class="flex-column">
                <v-col>
                  <v-card-title
                    is="h1"
                    class="primary--text px-5 pb-6"
                  >
                    {{ title }}
                  </v-card-title>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="grow d-flex align-end justify-end"
            >
              <v-row no-gutters align="end" class="flex-column">
                <v-row v-if="sectionScores" no-gutters justify="end" class="mb-3">
                  <v-col class="shrink">
                    <v-card
                      v-for="(collectionGroup, index) in sectionScores"
                      :key="index"
                      class="mx-5"
                      :class="{
                        'mb-3': sectionScores.length > 1
                      }"
                    >
                      <v-card-text class="pa-0">
                        <div
                          class="d-flex flex-row"
                        >
                          <template v-for="(collectionInfo, collectionGroupIndex) in collectionGroup.scores">
                            <div
                              :key="`${index}-${collectionGroupIndex}`"
                              v-if="collectionInfo.value"
                              class="section-score-column px-4 py-3"
                              v-tooltip="collectionInfo.tooltip"
                              :style="sectionScoreStyles(collectionInfo.collection)"
                            >
                              <v-row
                                class="section-score flex-nowrap mx-0"
                                align="center"
                                justify="start"
                              >
                                <div class="shrink pr-2 py-3" v-if="collectionInfo.value">
                                  <span
                                    class="score rounded-circle"
                                    :class="[collectionInfo.collection === 'average' || collectionInfo.collection === 'organisationAverage' ? 'primary' : 'secondary']"
                                  >
                                    {{ typeof collectionInfo.value === 'number' ? collectionInfo.value.toFixed(2) : collectionInfo.value }}
                                  </span>
                                </div>
                                <div class="grow flex-basis-0">
                                  <span class="label primary--text">
                                    {{ collectionInfo.label }}
                                  </span>
                                </div>
                              </v-row>
                            </div>
                          </template>
                        </div>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
                <platform-button
                  primary
                  default-case
                  icon="document-edit"
                  class="mr-3 mb-3"
                  @click="handleOpenReportConfigModal()"
                >
                  {{ $t('analysis.configureReportButton') }}
                </platform-button>
                <platform-button
                  v-if="showEditScoresConfig"
                  primary
                  default-case
                  icon="scores"
                  class="mr-3 mb-3"
                  @click="handleOpenReportConfigModal(ReportConfigTab.Scores)"
                >
                  {{ $t('analysis.configureScoresAction') }}
                </platform-button>
              </v-row>
            </v-col>
            <v-col
              v-if="showVariantPicker || showSectionProgressButton"
              :cols="12"
              class="grow d-flex px-5"
            >
              <v-row no-gutters align="end">
                <v-col :order="2" :order-lg="1" class="pt-3">
                  <platform-button
                    v-if="showSectionProgressButton"
                    secondary
                    default-case
                    icon="progress"
                    :to="{
                      name: 'AnalysisProgress',
                      params: {
                        surveyId: $route.params.surveyId,
                        targetSectionId: activeSectionId
                      }
                    }"
                  >
                    {{ $t('analysis.viewSectionProgressAction') }}
                  </platform-button>
                </v-col>
                <v-col
                  :order="1"
                  :order-lg="2"
                  :cols="12"
                  lg="auto"
                >
                  <v-row
                    v-if="showVariantPicker"
                    no-gutters
                    align="end"
                    justify="end"
                    class="text-right"
                    :class="{
                      'flex-nowrap': $vuetify.breakpoint.mdAndUp
                    }"
                  >
                    <span
                      v-if="showVariantPickerTip"
                      class="text-body-2 grey--text text--darken-2"
                      :class="{
                        'text-no-wrap': $vuetify.breakpoint.mdAndUp
                      }"
                    >
                      {{ $t('analysis.variantPickerTip') }}
                    </span>
                    <div class="ml-3">
                      <variant-picker
                        v-model="selectedVariant"
                        entity-type="result"
                        color="primary"
                        large
                        :variants="availableVariants"
                        :loading="pendingFetch || pendingFetchReport"
                      />
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>

        <router-view
          v-show="initialised && isSystemPage"
          v-bind="{
            section: systemPage,
            results: resultSet,
            report: selectedReport,
            selectedVariant,
            loadingComparisonData: selectedReport && selectedReport.comparisonSurveyGroupId ? pendingFetchComparisonResultSet : undefined,
            loadingReport: pendingFetchReport
          }"
        />

        <div class="rounded overflow-hidden">
          <v-tabs
            v-show="!isSystemPage"
            background-color="primary"
            dark
            v-model="tab"
          >
            <v-tab
              v-for="t in availableTabs"
              :key="t.key"
              :to="t.key"
            >{{ t.title }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" v-if="initialised && !isSystemPage">
            <v-tab-item
              v-for="t in availableTabs"
              :key="t.key"
              :id="t.key"
              :value="t.key"
            >
              <v-card v-if="section" flat class="pa-5">
                <component
                  :is="t.component"
                  :key="section.id || 'no-section-id'"
                  v-bind="{
                    section,
                    results: t.results,
                    reportSection: section && reportSectionsMap[section.id] ? reportSectionsMap[section.id][selectedVariant] : undefined,
                    report: resultSet,
                    loading: pendingFetchReport || pendingFetchReportSection
                  }"
                  @updateReportSection="updateReportSection"
                />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
    <v-row justify="center" class="pt-8" v-else>
      <platform-spinner />
    </v-row>
  </v-container>
</template>

<script lang="ts">
import CreateReportGroupModal from '@/components/analysis/report-groups/CreateReportGroupModal.vue'
import ReportGroupSelector from '@/components/analysis/report-groups/ReportGroupSelector.vue'
import { SurveyGroupProgress } from '@/types/SurveyGroup'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { CognitoIdentity, ReportSection } from '@/API'
import PlatformSelectField from '@/components/shared/inputs/PlatformSelectField.vue'
import { GraphTypes } from '@betterboards/graphs/types/Graph'
import { PlatformReport, PlatformReportGroup } from '@/types/Report'
import { PlatformQuestionnaireVariant, QuestionnaireVariantCode } from '@/types/Platform'
import { ScoreCollectionGroup, V1ComparisonReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import { ScoreCollection } from '@betterboards/shared/helpers/entities/Result/scores'
import ReportContextButton from '@/components/analysis/ReportContextButton.vue'
import AnalysisDashboard from '@/components/analysis/system-pages/AnalysisDashboard.vue'
import AnalysisProgress from '@/components/analysis/system-pages/AnalysisProgress.vue'
import DisplayResultsPanel from '@/components/analysis/tabs/DisplayResultsPanel.vue'
import DividerPage from '@/components/analysis/tabs/DividerPage.vue'
import SectionIntroduction from '@/components/analysis/tabs/SectionIntroduction.vue'
import ReportConfigForm from '@/components/forms/reports/ReportConfigForm.vue'
import ReportConfigModal, { ReportConfigTab } from '@/components/modals/ReportConfigModal.vue'
import PlatformModal from '@/components/PlatformModal.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { SystemPage, SystemPages } from '@/store/Analysis'
import VariantPicker from '@/components/shared/VariantPicker.vue'
import SurveyGroupSelectorModal from '@/components/questionnaire/releases/SurveyGroupSelectorModal.vue'
import { Result, ResultSet, ResultType, SectionResults } from '@betterboards/shared/types/ResultSet'
import { isV1ComparisonReportBenchmark } from '@betterboards/shared/helpers/statistics/ReportBenchmark'

enum AnalysisTab {
  ResponseAnalysis = 'response-analysis',
  Committees = 'committees',
  AutogeneratedGraphs = 'autogenerated-graphs',
  SectionIntro = 'section-intro',
  DividerPage = 'divider-page',
}

type Filter = Partial<Result>

const AnalysisOverrideTranslationLocale = 'analysisOverrides'

export default Vue.extend({
  name: 'Analysis',
  components: {
    ReportGroupSelector,
    CreateReportGroupModal,
    PlatformSelectField,
    VariantPicker,
    ReportConfigModal,
    ReportConfigForm,
    ReportContextButton,
    PlatformModal,
    PlatformSpinner,
    AnalysisDashboard,
    AnalysisProgress,
    DividerPage,
    SectionIntroduction,
    DisplayResultsPanel,
    SurveyGroupSelectorModal
  },
  props: {
    page: { type: String, required: false }
  },
  data: () => ({
    initialised: false,
    showReportConfigModal: false,
    reportConfigModalTargetTab: <ReportConfigTab | undefined>undefined,
    showSurveyGroupSelectorModal: false,
    tab: AnalysisTab.ResponseAnalysis,
    pendingListComparisonResultSets: false,
    pendingSelectComparisonResultSet: <string | undefined>undefined,
    pendingFetchComparisonResultSet: false,
    pendingFetchReport: false,
    pendingFetchReportSection: false,
    ReportConfigTab
  }),
  mounted () {
    if (this.$route.name === 'Analysis') {
      this.$router.replace({
        name: 'AnalysisDashboard'
      })
    }
    this.setActiveTab()
    this.init()
  },
  beforeDestroy () {
    this.$store.commit('Analysis/reset')
    this.$store.commit('Report/reset')
    this.resetTranslationOverrides()
  },
  watch: {
    'selectedCompany.id' () {
      this.init()
    },
    'section.id' (val, oldVal) {
      if (val === oldVal) {
        return
      }
      this.$store.commit('Analysis/setActiveSection', val)
      if (!this.selectedReport) {
        return
      }
      this.fetchSelectedReportSection()
    },
    selectedVariant (variantCode: QuestionnaireVariantCode) {
      this.fetchSelectedReport(variantCode)
      this.fetchSelectedResultSet(variantCode)
    },
    selectedReportGroup (newVal: PlatformReportGroup, oldVal: PlatformReportGroup | undefined) {
      if (!this.selectedVariant) {
        return
      }
      if (newVal.scores) {
        const comparisonScore: V1ComparisonReportBenchmark | undefined = newVal.scores.find(isV1ComparisonReportBenchmark)
        const comparisonSurveyGroupId: string | null = comparisonScore?.enabled && comparisonScore.comparisonSurveyGroupId
          ? comparisonScore.comparisonSurveyGroupId
          : null
        this.handleComparisonResultSetChange(comparisonSurveyGroupId)
      }
      if (oldVal && newVal.id === oldVal.id) {
        return
      }
      // ReportGroup has just been fetched and we already have a selectedVariant
      this.fetchSelectedReport(this.selectedVariant)
    },
    selectedReport (): void {
      this.setTranslationOverrides()
    },
    '$i18n.locale' (): void {
      this.setTranslationOverrides()
    },
    '$route.params.sectionId': 'setActiveTab',
    '$route.params.tab': 'setActiveTab'
  },
  computed: {
    ...mapGetters('Analysis', ['completion']),
    ...mapGetters('SurveyGroup', { surveyGroupsList: 'list' }),
    ...mapState('Questionnaire', { questionnairesList: 'list' }),
    ...mapState('Analysis', ['pendingFetch']),
    ...mapState('Company', ['selectedCompany']),
    currentUser (): CognitoIdentity {
      return this.$store.state.User.user
    },
    activeSectionId (): string {
      return this.$route.params.sectionId
    },
    resultSet (): ResultSet | null {
      return this.$store.state.Analysis.resultSet
    },
    surveyGroupId (): string {
      return this.$route.params.surveyId
    },
    surveyGroup (): SurveyGroupProgress | undefined {
      return this.$store.getters['Analysis/selectedSurveyGroup']
    },
    selectedVariant: {
      set (variantCode: QuestionnaireVariantCode | undefined): void {
        this.$store.commit('Analysis/setActiveVariant', variantCode)
      },
      get (): QuestionnaireVariantCode | undefined {
        return this.$store.state.Analysis.variantCode
      }
    },
    comparisonResultSet (): ResultSet | null {
      return this.$store.state.Analysis.comparisonResultSet
    },
    availableComparisonSurveyGroups (): SurveyGroupProgress[] | undefined {
      return this.$store.getters['Analysis/availableComparisonSurveyGroups']
    },
    selectedReportGroup (): PlatformReportGroup | null {
      return this.$store.getters['Report/selectedReportGroup']
    },
    selectedReport (): PlatformReport | null {
      return this.$store.getters['Report/selectedReport']
    },
    sectionScores (): ScoreCollectionGroup[] | null {
      return this.$store.getters['Analysis/sectionScores']
    },
    comparisonSurveyGroupId (): string | null {
      if (!this.selectedReport) {
        return null
      }
      return this.selectedReport.comparisonSurveyGroupId ?? null
    },
    availableVariants (): PlatformQuestionnaireVariant[] | undefined {
      return this.resultSet?.variants
    },
    showVariantPicker (): boolean {
      if (this.$route.name === 'GenerateReport') {
        return false
      }
      if (!this.availableVariants?.length) {
        return false
      }
      return this.availableVariants.length > 1
    },
    showVariantPickerTip (): boolean {
      if (this.activeSectionId) {
        return true
      }
      const slug = this.systemPage?.slug
      if (!slug) {
        return false
      }
      return [
        SystemPage.Dashboard,
        SystemPage.Progress,
        SystemPage.EvaluationInformation,
        SystemPage.FocusAreas
      ].includes(slug)
    },
    title (): string | null {
      if (this.isSystemPage) {
        return this.systemPage?.slug ? this.$t(`nav.analysis.${this.systemPage.slug}`) as string : null
      }
      return this.section?.name ?? null
    },
    section (): SectionResults | undefined {
      if (!this.activeSectionId) {
        return undefined
      }
      if (this.systemPage) {
        return undefined
      }
      return this.resultSet?.sections?.find((section: SectionResults) => section.id === this.activeSectionId)
    },
    systemPage (): typeof SystemPages[keyof typeof SystemPages] | undefined {
      return Object.values(SystemPages).find((p) => p.route === this.$route.name)
    },
    isSystemPage (): boolean {
      return !!this.systemPage
    },
    showEditScoresConfig (): boolean {
      return !!this.systemPage?.configureScores
    },
    availableTabs (): Array<{ key: AnalysisTab, title: string, component: string, results?: Result[] }> {
      const tabs = [
        {
          key: AnalysisTab.ResponseAnalysis,
          title: `${this.$t('analysis.sectionTabs.responseAnalysis')}`,
          component: 'DisplayResultsPanel',
          results: this.filterResults({ type: 'responses' })
        },
        {
          key: AnalysisTab.Committees,
          title: 'Committees',
          component: 'DisplayResultsPanel',
          results: this.filterResults({ committee: true })
        },
        {
          key: AnalysisTab.AutogeneratedGraphs,
          title: `${this.$t('analysis.sectionTabs.autogeneratedGraphs')}`,
          component: 'DisplayResultsPanel',
          results: this.filterResults({ type: 'graphs' })
        },
        {
          key: AnalysisTab.SectionIntro,
          title: `${this.$t('analysis.sectionTabs.sectionIntroduction')}`,
          component: 'SectionIntroduction',
          results: this.filterResults({ graphType: GraphTypes.Scale })
        },
        {
          key: AnalysisTab.DividerPage,
          title: `${this.$t('analysis.sectionTabs.dividerPage')}`,
          component: 'DividerPage'
        }
      ]
      return tabs.filter((tab) => {
        if (this.sectionResults.length && tab.results && !tab.results.length) {
          return false
        }
        return true
      })
    },
    sectionResults (): ResultType[] {
      if (this.isSystemPage || !this.section) {
        return []
      }
      return this.section.results
    },
    reportSectionsMap (): { [sectionId: string]: { [variantCode: string]: ReportSection } } {
      const reportSections = this.$store.state.Report.selectedReportSections
      if (!reportSections?.length || this.pendingFetchComparisonResultSet || this.pendingFetchReport) {
        return {}
      }
      return reportSections.reduce((sections, section) => {
        if (!sections[section.sectionId]) {
          sections[section.sectionId] = []
        }
        sections[section.sectionId][section.variantCode] = section
        return sections
      }, {})
    },
    correctCompanySelected (): boolean {
      if (!this.resultSet?.company?.id || !this.selectedCompany?.id) {
        return false
      }
      return this.resultSet.company.id === this.selectedCompany.id
    },
    organisationId (): string | undefined {
      return this.selectedCompany?.parentId
    },
    showSectionProgressButton (): boolean {
      if (this.activeSectionId) {
        return true
      }
      if (this.isSystemPage && this.systemPage) {
        return [SystemPage.EvaluationInformation, SystemPage.FocusAreas].includes(this.systemPage.slug as SystemPage)
      }
      return false
    },
    showComparisonConfigModalButton (): boolean {
      return !!this.systemPage?.comparisonPicker && !!this.availableComparisonSurveyGroups
    }
  },
  methods: {
    ...mapActions('Report', ['loadReportSection', 'saveReportSection']),
    ...mapActions('Analysis', ['getResultSet', 'getComparisonResultSet']),
    ...mapActions('Company', ['selectCompany']),
    setTranslationOverrides (): void {
      const translationOverrides = this.selectedReport?.configuration?.translationOverrides
      if (!translationOverrides) {
        this.resetTranslationOverrides()
        return
      }
      if (this.$i18n.locale === AnalysisOverrideTranslationLocale) {
        return
      }
      this.$i18n.fallbackLocale = this.$i18n.locale ?? 'en'
      this.$i18n.setLocaleMessage(AnalysisOverrideTranslationLocale, translationOverrides)
      this.$i18n.locale = AnalysisOverrideTranslationLocale
    },
    resetTranslationOverrides (): void {
      this.$i18n.setLocaleMessage('analysisOverrides', {})
      this.$i18n.locale = this.currentUser.defaultLanguage
      this.$i18n.fallbackLocale = 'en'
    },
    setActiveTab (): void {
      if (this.$route.params.tab) {
        const tabIndex = this.availableTabs.findIndex((tab) => tab.key === this.$route.params.tab)
        if (tabIndex !== -1 && this.tab !== this.availableTabs[tabIndex].key) {
          this.tab = this.availableTabs[tabIndex].key
          return
        }
      }
      if (this.$route.name === 'AnalysisSection') {
        if (!this.$route.params.tab || !this.availableTabs.some((t) => t.key === this.$route.params.tab)) {
          this.$router.replace({
            params: {
              tab: this.availableTabs[0].key
            }
          })
        }
      }
    },
    /**
     * Ensure the correct Company is selected and re-fetches its data.
     */
    async selectCorrectCompany (): Promise<void> {
      if (!this.resultSet?.company) {
        return
      }
      try {
        await this.selectCompany(this.resultSet.company)
      } catch (err) {
        console.error('Failed to select correct company, err:', err)
        throw err
      }
    },
    async init (): Promise<void> {
      const surveyGroupId = this.surveyGroupId
      this.$store.commit('Analysis/setPendingFetch', true)
      try {
        await this.fetchResultSet(surveyGroupId, this.selectedVariant)
        if (this.correctCompanySelected) {
          if (!this.availableComparisonSurveyGroups?.length) {
            void this.$store.dispatch('SurveyGroup/listSurveyGroups', {
              companyId: this.resultSet?.company.id
            })
          }
          await this.$store.dispatch('Report/loadReportGroups', {
            surveyGroupId
          })
        }
        this.initialised = true
      } catch (err) {
        console.error('Failed to initialize analysis:', err)
        this.$toasted.error('Failed to initialize analysis, please refresh and try again!')
      } finally {
        this.$store.commit('Analysis/setPendingFetch', false)
      }
    },
    async fetchResultSet (surveyGroupId: string, variantCode?: QuestionnaireVariantCode) {
      await this.getResultSet({
        surveyGroupId,
        variantCode
      })
      await this.selectCorrectCompany()
      const variants = this.resultSet?.variants
      if (!this.selectedVariant) {
        if (!variants) {
          return
        }
        this.selectedVariant = variants[0].variantCode
      }
    },
    async fetchReportSection (reportId: string, sectionId?: string): Promise<any> {
      if (!sectionId || !this.selectedReport?.id || !this.correctCompanySelected) {
        return
      }
      this.pendingFetchReportSection = true
      try {
        await this.loadReportSection({
          reportId,
          variantCode: this.selectedVariant,
          sectionId
        })
      } catch (err) {
        console.error('Failed to fetch report section!', err)
        this.$toasted.error('Failed to load data.')
      } finally {
        this.pendingFetchReportSection = false
      }
    },
    async fetchSelectedReportSection (): Promise<void> {
      if (!this.section?.id || !this.selectedReport) {
        return
      }
      await this.fetchReportSection(this.selectedReport.id, this.section.id)
    },
    filterResults (filter: Filter): Result[] | undefined {
      if (!filter) {
        return
      }
      return this.sectionResults.filter((i: Result): boolean => {
        if (i.committee) {
          return !!filter.committee
        }
        if (filter.type) {
          return i.type === filter.type
        }
        if (filter.graphType) {
          return i.graphType === filter.graphType
        }
        return false
      })
    },
    updateReportSection (reportSection) {
      this.saveReportSection({
        ...reportSection,
        variantCode: this.selectedVariant
      })
        .catch((err) => {
          this.$toasted.error(
            this.$t('analysis.updateReportSectionErrorMessage') as string
          )
          console.error('Failed to update ReportSection:', reportSection, err)
          throw err
        })
    },
    getReportByVariant (variantCode?: string): PlatformReport | undefined {
      if (!this.selectedReportGroup) {
        return
      }
      const reports: PlatformReport[] | undefined = this.selectedReportGroup?.reports?.items
      if (!reports?.length) {
        return
      }
      if (!variantCode) {
        return reports[0]
      }
      return reports.find(
        (report: PlatformReport) => report.variantCode === variantCode
      )
    },
    handleComparisonResultSetChange (comparisonSurveyGroupId: string | null | undefined, oldComparisonSurveyGroupId?: string | undefined): void {
      console.debug('Comparison ResultSet changed:', comparisonSurveyGroupId)

      if (!comparisonSurveyGroupId) {
        this.showSurveyGroupSelectorModal = false
        this.pendingFetchComparisonResultSet = true
        this.$nextTick(() => {
          this.pendingFetchComparisonResultSet = false
        })
        this.$store.commit('Analysis/resetComparisonResultSet')
        return
      }
      if (comparisonSurveyGroupId && comparisonSurveyGroupId !== oldComparisonSurveyGroupId) {
        this.fetchComparisonResultSet(comparisonSurveyGroupId)
          .then(() => {
            this.showSurveyGroupSelectorModal = false
          })
      }
    },
    async handleSelectComparisonResultSet (comparisonSurveyGroupId: string | null | undefined): Promise<void> {
      if (!this.selectedReport) {
        return
      }
      if (this.selectedReport.comparisonSurveyGroupId === comparisonSurveyGroupId) {
        // Already selected
        this.showSurveyGroupSelectorModal = false
        return
      }

      this.pendingSelectComparisonResultSet = comparisonSurveyGroupId ?? undefined
      try {
        await this.$store.dispatch('Report/saveComparisonResultSet', { comparisonSurveyGroupId })
      } finally {
        this.pendingSelectComparisonResultSet = undefined
      }

      this.handleComparisonResultSetChange(comparisonSurveyGroupId)
    },
    async fetchComparisonResultSet (surveyGroupId: string): Promise<void> {
      this.pendingFetchComparisonResultSet = true
      try {
        await this.$store.dispatch('Analysis/getComparisonResultSet', { surveyGroupId })
      } finally {
        this.pendingFetchComparisonResultSet = false
      }
    },
    async fetchSelectedResultSet (variantCode: QuestionnaireVariantCode): Promise<void> {
      const surveyGroupId = this.surveyGroupId
      this.$store.commit('Analysis/setPendingFetch', true)
      try {
        await this.fetchResultSet(surveyGroupId, variantCode)
      } finally {
        this.$store.commit('Analysis/setPendingFetch', false)
      }
    },
    async fetchSelectedReport (variantCode: QuestionnaireVariantCode): Promise<void> {
      if (!this.selectedReportGroup) {
        console.debug('fetchSelectedReport early return, no selected ReportGroup')
        return
      }
      const report = this.getReportByVariant(variantCode)
      if (!report) {
        console.warn('Failed to find Report for new selected Variant:', variantCode)
        throw new Error('Failed to select Report Variant.')
      }
      this.pendingFetchReport = true
      this.pendingFetchReportSection = true
      try {
        await this.$store.dispatch('Report/selectReport', { reportId: report.id })
      } catch (err) {
        console.error('Failed to load Report', {
          variantCode,
          reportId: report.id,
          err
        })
        throw err
      } finally {
        this.pendingFetchReport = false
      }

      // Anything else

      // @TODO: Uncomment this
      try {
        await this.fetchSelectedReportSection()
      } catch (err) {
        console.error('Failed to fetch initial ReportSection', {
          variantCode,
          reportId: report.id,
          err
        })
        throw err
      }
    },
    sectionScoreStyles (collection: ScoreCollection): any {
      return {
        borderRightWidth: [ScoreCollection.Average, ScoreCollection.ComparisonAverage, ScoreCollection.OrganisationAverage, ScoreCollection.UniverseAverage].includes(collection) ? '1px' : undefined
      }
    },
    handleOpenReportConfigModal (targetTab?: ReportConfigTab | undefined): void {
      this.showReportConfigModal = true
      this.reportConfigModalTargetTab = targetTab
    },
    handleCloseReportConfigModal (): void {
      this.showReportConfigModal = false
      this.reportConfigModalTargetTab = undefined
    }
  }
})
</script>

<style lang="scss">
.v-tabs-slider-wrapper .v-tabs-slider {
  color: var(--v-secondary-base) !important;
}
.section-score-column {
  width: 190px;
  border-color: var(--v-grey-lighten2);
  border-style: solid;
  border-width: 0;

  .section-score {
    .label {
      font-size: 1.1em;
    }
    .score {
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: #ffffff;
      text-align: center;
    }
  }
}
</style>
