<template>
  <v-row no-gutters class="flex-column">
    <div>
      <span class="grey--text text--darken-2">
        {{ $t('modal.report.config.editScores.instruction') }}
      </span>
    </div>
    <v-expansion-panels
      v-model="openReportBenchmarkPanels"
      multiple
      class="mt-2 px-1"
    >
      <v-expansion-panel
        v-for="(reportScore, index) in availableReportBenchmarks"
        :key="index"
        class="scores-panel mb-2 pointer-events--auto"
        ref="benchmarkPanel"
        readonly
      >
        <v-expansion-panel-header class="scores-panel--header pa-0">
          <template #actions>
            <div class="d-none"></div>
          </template>
          <template #default>
            <div class="d-flex flex-column overflow-hidden">
              <v-row no-gutters align="center" class="flex-nowrap">
                <div
                  class="flex-grow-1 flex-shrink-1 overflow-hidden px-3 py-1"
                  @click="toggleReportBenchmarkPanelOpened(reportScore)"
                >
                  <display-report-benchmark
                    v-if="reportScore"
                    :value="reportScore"
                  />
                </div>
                <div class="shrink">
                  <div
                    v-if="reportScore.scores && reportScore.scores.length"
                    v-tooltip:top="$t('modal.report.config.editScores.editScoreTooltip')"
                    style="position: relative; width: 24px; height: 24px;"
                    @click="handleEditScores(reportScore)"
                  >
                    <platform-button
                      primary
                      compact
                      small
                      text
                      icon="edit"
                      :icon-size="20"
                      :disabled="!!syncingReportBenchmarkId || pendingFetchScores"
                      :loading="!!syncingReportBenchmarkId && syncingReportBenchmarkId === reportScore.id"
                      style="position: absolute; left: 0; top: 0; z-index: 2;"
                    />
                    <platform-icon
                      v-if="hasBenchmarkBeenUpdated(reportScore.id) && (!syncingReportBenchmarkId || syncingReportBenchmarkId !== reportScore.id)"
                      color="secondary"
                      name="state-modified"
                      :size="20"
                      style="position: absolute; left: 3px; top: 4px; z-index: 1;"
                    />
                  </div>
                </div>
                <div class="shrink pl-4" style="height: 24px;">
                  <platform-switch
                    v-if="reportScore.scores && reportScore.scores.length"
                    hide-details
                    primary
                    read-only
                    v-tooltip:top="$t(reportScore.enabled ? 'modal.report.config.editScores.disableScoreTooltip' : 'modal.report.config.editScores.enableScoreTooltip')"
                    :value="!!reportScore.enabled"
                    @click="toggleReportBenchmarkEnabled(reportScore)"
                  />
                </div>
                <div class="shrink d-flex justify-center pl-2 pr-3">
                  <div class="v-expansion-panel-header__icon" style="width: auto !important;">
                    <platform-icon
                      :name="reportScore.scores && reportScore.scores.length ? 'caret-down' : 'create'"
                      :color="reportScore.scores && reportScore.scores.length ? 'secondary' : 'grey'"
                      :size="reportScore.scores && reportScore.scores.length ? 24 : 20"
                      @click="toggleReportBenchmarkPanelOpened(reportScore)"
                    />
                  </div>
                </div>
              </v-row>
              <v-progress-linear
                v-if="!!pendingFetchOrgScoresCompanyId && pendingFetchOrgScoresCompanyId === reportScore.companyId"
                indeterminate
                color="secondary"
                style="width: 100%;"
                :height="2"
              />
            </div>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters class="flex-nowrap py-4">
            <display-report-benchmark-scores
              v-if="reportScore.scores"
              sm
              :value="reportScore.scores"
            />
          </v-row>
          <v-row
            v-if="hasBenchmarkBeenUpdated(reportScore.id)"
            no-gutters
            align="center"
            justify="center"
          >
            <div class="d-inline-block primary--text text-caption text--compact-2 text-center font-weight-bold px-4">
              <span class="secondary--text">
                *
              </span>
              {{ $t('modal.report.config.editScores.modifiedScoreNote') }}
            </div>
          </v-row>
          <v-row no-gutters class="flex-nowrap pt-3" justify="space-between">
            <platform-button
              v-if="canDeleteBenchmark(reportScore)"
              warning
              default-case
              small
              compact
              icon="delete"
              class="ma-1"
              :icon-size="15"
              v-tooltip:top="$t('modal.report.config.editScores.removeScoreTooltip')"
              @click="handleRemoveScores(reportScore)"
            >
              {{ $t('modal.report.config.editScores.removeScoreAction') }}
            </platform-button>
            <platform-button
              secondary
              default-case
              small
              compact
              icon="sync"
              class="ma-1"
              :disabled="!!syncingReportBenchmarkId || pendingFetchScores"
              :loading="!!syncingReportBenchmarkId && syncingReportBenchmarkId === reportScore.id"
              :icon-size="15"
              v-tooltip:top="$t('modal.report.config.editScores.syncScoreTooltip', [reportScore.displayUpdatedAt])"
              @click="showConfirmSyncBenchmarkModal(reportScore)"
            >
              {{ $t('modal.report.config.editScores.syncScoreAction') }}
            </platform-button>
            <platform-button
              secondary
              default-case
              small
              compact
              icon="edit"
              class="ma-1"
              :disabled="!!syncingReportBenchmarkId || pendingFetchScores"
              :icon-size="15"
              v-tooltip:top="$t('modal.report.config.editScores.editScoreTooltip')"
              @click="handleEditScores(reportScore)"
            >
              {{ $t('modal.report.config.editScores.editScoreAction') }}
            </platform-button>
            <platform-button
              :primary="!reportScore.enabled"
              :secondary="reportScore.enabled"
              style="transition: background-color 250ms ease-in-out; width: 70px;"
              default-case
              small
              compact
              class="ma-1"
              v-tooltip:top="$t(reportScore.enabled ? 'modal.report.config.editScores.disableScoreTooltip' : 'modal.report.config.editScores.enableScoreTooltip')"
              :icon="reportScore.enabled ? 'eye-closed' : 'eye'"
              :icon-size="15"
              @click="toggleReportBenchmarkEnabled(reportScore)"
            >
              <v-row no-gutters class="d-flex justify-center">
                {{ $t(reportScore.enabled ? 'modal.report.config.editScores.disableScoreAction' : 'modal.report.config.editScores.enableScoreAction') }}
              </v-row>
            </platform-button>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <survey-group-selector-modal
      v-if="editingComparisonBenchmark && editingReportBenchmark"
      save-confirmation
      :value="editingReportBenchmark ? editingReportBenchmark.comparisonSurveyGroupId : undefined"
      :survey-groups="availableComparisonSurveyGroups"
      :loading-group="pendingFetchComparisonSurveyGroup || undefined"
      :title="$t('modal.questionnaire.releases.comparisonTitle')"
      :instruction="$t('modal.questionnaire.releases.comparisonInstruction')"
      @input="handleCreateComparisonBenchmark"
      @close="handleCloseComparisonBenchmarkModal"
    />
    <universe-selector-modal
      v-if="editingUniverseBenchmark"
      v-model="editingUniverseBenchmark"
      :queries="selectedBenchmarkV1Queries"
      :loading="pendingSaveScores"
      :v2="!!editingReportBenchmark && editingReportBenchmark.version === '2'"
      @input="handleUniverseModalUpdate"
      @update="handleSelectUniverseQueries"
    />
    <edit-report-benchmark-modal
      v-if="!!editingReportBenchmark && !editingUniverseBenchmark && !editingComparisonBenchmark"
      :value="editingReportBenchmark"
      @close="editingReportBenchmark = null"
      @input="handleUpdateReportBenchmark"
    />
    <confirm-sync-benchmark-modal
      v-if="confirmingSyncBenchmark"
      :value="confirmingSyncBenchmark"
      @confirm="handleSyncScores(confirmingSyncBenchmark)"
      @close="confirmingSyncBenchmark = null"
    />
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { v4 as uuid } from 'uuid'
import API from '@/services/API'
import { ReportBenchmark, ScoreCollectionGroup, V1ReportBenchmark, V2ReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import { ScoreCollection, sortReportBenchmarks } from '@betterboards/shared/helpers/entities/Result/scores'
import { PlatformCompany } from '@betterboards/shared/types/Company'
import UniverseSelectorModal from '@/components/analysis/universe/UniverseSelectorModal.vue'
import { UniverseQuery } from '@betterboards/shared/types/Report'
import { PlatformReportGroup } from '@/types/Report'
import { BaseStatsResult, StatsResult, V2StatisticsQuery } from '@betterboards/shared/types/Statistic'
import DisplayReportBenchmark from '@/components/forms/reports/benchmarks/DisplayReportBenchmark.vue'
import { normalizeFloat } from '@betterboards/shared/helpers/util/numbers'
import { findReportBenchmark, findReportBenchmarkIndex, isV1ComparisonReportBenchmark } from '@betterboards/shared/helpers/statistics/ReportBenchmark'
import { buildReportBenchmarkV2StatsQueries, isOrganisationV2StatsQuery, normalizeUniverseQueries } from '@betterboards/shared/helpers/statistics/queries'
import { getReportBenchmarkDisplayInfo } from '@betterboards/shared/helpers/statistics/getReportBenchmarkDisplayInfo'
import EditReportBenchmarkModal from '@/components/forms/reports/benchmarks/EditReportBenchmarkModal.vue'
import buildAvailableReportBenchmarksList from '@/helpers/statistics/buildAvailableReportBenchmarksList'
import SurveyGroupSelectorModal from '@/components/questionnaire/releases/SurveyGroupSelectorModal.vue'
import { SurveyGroupProgress } from '@/types/SurveyGroup'
import { ResultSet, SectionAveragesData, SectionResults } from '@betterboards/shared/types/ResultSet'
import ConfirmSyncBenchmarkModal from '@/components/forms/reports/benchmarks/ConfirmSyncBenchmarkModal.vue'
import DisplayReportBenchmarkScores from '@/components/forms/reports/benchmarks/DisplayReportBenchmarkScores.vue'

export default Vue.extend({
  name: 'ManageReportBenchmarksForm',
  components: {
    DisplayReportBenchmarkScores,
    ConfirmSyncBenchmarkModal,
    SurveyGroupSelectorModal,
    EditReportBenchmarkModal,
    DisplayReportBenchmark,
    UniverseSelectorModal
  },
  props: {
    value: { type: Array as PropType<ReportBenchmark[]>, required: true }
  },
  data: () => ({
    pendingSaveScores: false,
    pendingFetchScores: false,
    pendingFetchOrgScoresCompanyId: <string | null>null,
    pendingFetchComparisonSurveyGroup: <string | null>null,
    editingReportBenchmark: <ReportBenchmark | null>null,
    confirmingSyncBenchmark: <ReportBenchmark | null>null,
    syncingReportBenchmarkId: <string | null>null,
    editingUniverseBenchmark: false,
    editingComparisonBenchmark: false,
    openReportBenchmarkPanels: <number[]>[], // Will contain indexes of each open panel,
    updatedBenchmarks: <string[]>[] // Contains the IDs of any Benchmarks which the user has created/updated in this session
  }),
  computed: {
    selectedCompany (): PlatformCompany {
      return this.$store.state.Company.selectedCompany
    },
    selectedReportGroup (): PlatformReportGroup | null {
      return this.$store.getters['Report/selectedReportGroup']
    },
    savedScores: {
      get (): ReportBenchmark[] {
        return this.value
      },
      set (scores: ReportBenchmark[]) {
        this.$emit('input', scores)
      }
    },
    allowMultipleUniverseBenchmarks (): boolean {
      return !!this.selectedReportGroup?.configuration?.scores?.allowMultipleUniverse
    },
    showV2UniverseOption (): boolean {
      return !!this.selectedReportGroup?.configuration?.scores?.showV2Universe
    },
    surveyGroupsList (): SurveyGroupProgress[] {
      return this.$store.getters['SurveyGroup/list']
    },
    surveyGroup (): SurveyGroupProgress | undefined {
      return this.$store.getters['Analysis/selectedSurveyGroup']
    },
    availableComparisonSurveyGroups (): SurveyGroupProgress[] {
      return this.$store.getters['Analysis/availableComparisonSurveyGroups']
    },
    legacyScoreGroups (): ScoreCollectionGroup[] {
      const legacyScoreGroups: ScoreCollectionGroup[] | undefined = this.$store.getters['Analysis/sectionScores']
      if (legacyScoreGroups?.length) {
        return legacyScoreGroups
      }
      return []
    },
    companiesList (): PlatformCompany[] {
      return this.$store.state.Company.list
    },
    availableReportBenchmarks (): ReportBenchmark[] {
      if (!this.companiesList) {
        return []
      }
      return buildAvailableReportBenchmarksList(
        this.savedScores,
        this.selectedCompany,
        this.companiesList,
        this.allowMultipleUniverseBenchmarks,
        this.showV2UniverseOption
      )
    },
    selectedBenchmarkV1Queries (): UniverseQuery[] {
      if (!this.editingReportBenchmark) {
        return []
      }
      const group = findReportBenchmark(this.savedScores, this.editingReportBenchmark)
      if (!group?.queries?.length) {
        return []
      }
      // Convert v2 Stats queries to v1 for usage in the Universe Selector modal
      return group.queries.map((query: V2StatisticsQuery | UniverseQuery): UniverseQuery => {
        if ('collection' in query) {
          // Already a v1 Query
          return query
        }
        // Transform v2 Query
        return {
          collection: query.field,
          category: query.value
        }
      })
    }
  },
  methods: {
    async toggleReportBenchmarkEnabled (score: ReportBenchmark): Promise<void> {
      if (this.pendingFetchScores) {
        return
      }

      console.debug('toggleReportBenchmarkEnabled:', score.enabled, score.collection, score)
      if (score.enabled) {
        // Currently enabled, toggle to disabled
        this.updateReportBenchmark({
          ...score,
          enabled: false
        })
        return
      }

      if (!score.scores || !score.scores.length) {
        if (score.collection === ScoreCollection.ComparisonAverage && !score.comparisonSurveyGroupId) {
          this.editingReportBenchmark = score
          this.editingComparisonBenchmark = true
          return
        }
        if (score.collection === ScoreCollection.UniverseAverage) {
          // Universe score needs queries selecting before it can be enabled
          this.editingReportBenchmark = score
          this.editingUniverseBenchmark = true
          return
        }
      }

      // Currently disabled, toggle to enabled
      this.updateReportBenchmark({
        ...score,
        enabled: true
      })

      if (score.scores?.length) {
        // Already have scores, no need to fetch
        return
      }

      if (score.collection === ScoreCollection.OrganisationAverage && score.companyId) {
        await this.fetchReportBenchmarkData(score)
        return
      }
      console.debug('toggleReportBenchmarkEnabled not sure what to do.', score.collection, score)
    },
    toggleReportBenchmarkPanelOpened (benchmark: ReportBenchmark): void {
      const index: number = findReportBenchmarkIndex(this.availableReportBenchmarks, benchmark)
      const existsIndex: number = this.openReportBenchmarkPanels.indexOf(index)
      console.debug('toggleReportBenchmarkPanelOpened', index, existsIndex, benchmark, this.openReportBenchmarkPanels)
      if (existsIndex === -1) {
        // Panel is currently closed
        if (!benchmark.scores || !benchmark.scores.length) {
          // No scores loaded yet
          if (benchmark.enabled) {
            this.editingReportBenchmark = benchmark
          }
          if (benchmark.collection === ScoreCollection.ComparisonAverage) {
            this.editingComparisonBenchmark = true
            this.editingReportBenchmark = benchmark
            return
          }
          if (benchmark.collection === ScoreCollection.OrganisationAverage && benchmark.companyId) {
            this.fetchReportBenchmarkData(benchmark)
            return
          }
          if (benchmark.collection === ScoreCollection.UniverseAverage) {
            this.editingUniverseBenchmark = true
            this.editingReportBenchmark = benchmark
            return
          }
          this.toggleReportBenchmarkEnabled(benchmark)
          return
        }
        this.openReportBenchmarkPanel(benchmark)
        return
      }
      // Panel is currently open
      this.closeReportBenchmarkPanel(benchmark)
      console.debug('toggleReportBenchmarkPanelOpened done', this.openReportBenchmarkPanels)
    },
    openReportBenchmarkPanel (score: ReportBenchmark): void {
      const index: number = findReportBenchmarkIndex(this.availableReportBenchmarks, score)
      const existsIndex: number = this.openReportBenchmarkPanels.indexOf(index)
      if (existsIndex !== -1) {
        return
      }
      if (index !== -1) {
        this.openReportBenchmarkPanels.push(index)
        const panelComponent: any | undefined = this.$refs.benchmarkPanel?.[index]
        if (panelComponent) {
          const panelElement = panelComponent?.$el
          setTimeout(() => {
            panelElement.scrollIntoView({
              block: 'center',
              inline: 'nearest',
              behavior: 'smooth'
            })
          }, 400)
        }
      }
    },
    closeReportBenchmarkPanel (score: ReportBenchmark): void {
      const scoreIndex: number = findReportBenchmarkIndex(this.availableReportBenchmarks, score)
      if (scoreIndex !== -1) {
        const panelIndex: number = this.openReportBenchmarkPanels.indexOf(scoreIndex)
        if (panelIndex !== -1) {
          this.openReportBenchmarkPanels.splice(panelIndex, 1)
        }
      }
    },
    handleSelectUniverseQueries ({ scores, queries }: { scores: StatsResult[], queries?: UniverseQuery[] }): void {
      if (!this.editingReportBenchmark) {
        console.error('Unable to handle selecting Universe queries as no ReportBenchmark is being edited.')
        return
      }
      const reportScore: ReportBenchmark | undefined = getReportBenchmarkDisplayInfo({
        ...this.editingReportBenchmark,
        collection: this.editingReportBenchmark.collection as any,
        scores: scores.map((s: StatsResult): StatsResult => ({
          key: s.key,
          masterSectionId: s.masterSectionId,
          displayOrder: s.displayOrder,
          value: s.value,
          name: s.name,
          subSections: s.subSections
        })),
        queries: queries && this.editingReportBenchmark.version === '2' ? normalizeUniverseQueries(queries) : queries,
        enabled: true,
        updatedAt: (new Date()).toISOString()
      })
      if (!reportScore) {
        console.error('Failed to get ReportBenchmark display info to add new universe score.')
        return
      }
      this.updateReportBenchmark(reportScore)
      this.$nextTick(() => {
        this.openReportBenchmarkPanel(reportScore)
      })
      this.editingReportBenchmark = null
      this.editingComparisonBenchmark = false
      this.editingUniverseBenchmark = false
    },
    handleEditScores (reportScore: ReportBenchmark): void {
      if (!reportScore.scores) {
        return
      }
      this.editingReportBenchmark = reportScore
    },
    showConfirmSyncBenchmarkModal (benchmark: ReportBenchmark): void {
      this.confirmingSyncBenchmark = benchmark
    },
    async handleSyncScores (benchmark: ReportBenchmark): Promise<void> {
      if (!benchmark.id) {
        return
      }
      this.confirmingSyncBenchmark = null
      this.syncingReportBenchmarkId = benchmark.id
      try {
        await this.fetchReportBenchmarkData(benchmark)
      } finally {
        this.pendingFetchScores = false
        this.syncingReportBenchmarkId = null
      }
    },
    async handleRemoveScores (reportScore: ReportBenchmark): Promise<void> {
      const scores = [...this.savedScores]
      const index: number = findReportBenchmarkIndex(scores, reportScore)
      if (index !== -1) {
        this.closeReportBenchmarkPanel(reportScore)
        scores.splice(index, 1)
        this.savedScores = scores
      }
    },
    async fetchV1ReportBenchmarkData (benchmark: V1ReportBenchmark): Promise<void> {
      if (benchmark.collection === ScoreCollection.ComparisonAverage) {
        await this.updateSelectedComparisonSurveyGroup(benchmark, benchmark.comparisonSurveyGroupId)
        return
      }

      let queries: UniverseQuery[] | { companyId: string } | undefined = benchmark.queries
      if (benchmark.companyId) {
        queries = {
          companyId: benchmark.companyId
        }
      }
      if (!queries) {
        console.debug('Unable to get queries to fetch scores for v1 Benchmark:', benchmark)
        return
      }
      const payload = {
        queries
      }

      if (!Array.isArray(queries) && queries.companyId) {
        this.pendingFetchOrgScoresCompanyId = queries.companyId
      }
      this.pendingFetchScores = true

      let benchmarkScores: StatsResult[] | undefined
      try {
        benchmarkScores = await this.$store.dispatch(
          'Analysis/fetchUniverse',
          payload
        )
      } catch (err) {
        this.$toasted.error(
          this.$t('modal.report.config.editScores.editScore.fetchScoresError') as string
        )
        return
      } finally {
        this.pendingFetchScores = false
        this.pendingFetchOrgScoresCompanyId = null
      }

      if (!benchmarkScores?.length) {
        return
      }
      this.updateReportBenchmark({
        ...benchmark,
        scores: benchmarkScores,
        updatedAt: (new Date()).toISOString()
      })
    },
    async fetchV2ReportBenchmarkData (score: V2ReportBenchmark): Promise<void> {
      if (!score.queries) {
        score.queries = buildReportBenchmarkV2StatsQueries(score)
      }

      if (!score.queries?.length) {
        console.warn('Invalid request to add Score Group, no queries provided.', score.queries)
        return
      }

      const orgQuery: V2StatisticsQuery | undefined = score.queries.find(isOrganisationV2StatsQuery)
      if (orgQuery) {
        this.pendingFetchOrgScoresCompanyId = orgQuery.value
      }

      this.pendingFetchScores = true

      let scoresQueryResponse: { results: StatsResult[] }
      try {
        scoresQueryResponse = await API.post(
          'backendfunctions',
          '/stats/v2',
          {
            body: {
              queries: score.queries
            }
          }
        )
      } catch (err) {
        this.$toasted.error(
          this.$t('modal.report.config.editScores.editScore.fetchScoresError') as string
        )
        return
      } finally {
        this.pendingFetchScores = false
        this.pendingFetchOrgScoresCompanyId = null
      }
      console.debug('scoresQueryResponse:', scoresQueryResponse)
      const results: StatsResult[] = scoresQueryResponse.results
      const scores: StatsResult[] = results.reduce(
        (scores: StatsResult[], sectionScore: StatsResult): StatsResult[] => {
          if (sectionScore.subSections) {
            sectionScore.subSections.forEach((subSectionScore): void => {
              console.debug('subSectionScore:', subSectionScore)
              // @TODO: Remove this hacky temp fix
              if (subSectionScore.key === '0e3aabec-bd78-4910-9fd8-9f51ecc75321') {
                subSectionScore.name = 'Organisation of Board Meetings'
              }
              if (subSectionScore.key === '10b4bb95-ed8a-45d9-9a97-df6fcebd1f1f') {
                subSectionScore.name = 'Running of Board Meetings'
              }

              scores.push(subSectionScore)
            })
          }
          if (sectionScore.value) {
            scores.push(sectionScore)
          }
          return scores
        },
        []
      )
      const reportScore: ReportBenchmark = {
        ...score,
        queries: score.queries ?? [],
        scores,
        updatedAt: (new Date()).toISOString()
      }
      this.updateReportBenchmark(reportScore)
    },
    async fetchReportBenchmarkData (score: ReportBenchmark): Promise<void> {
      if (this.pendingFetchScores) {
        return
      }
      this.closeReportBenchmarkPanel(score)

      switch (score.version) {
        case '1':
          await this.fetchV1ReportBenchmarkData(score)
          break
        case '2':
          await this.fetchV2ReportBenchmarkData(score)
          break
      }

      this.openReportBenchmarkPanel(score)
    },
    async handleUpdateReportBenchmark (updatedReportBenchmark: ReportBenchmark): Promise<void> {
      const index: number = findReportBenchmarkIndex(this.savedScores, updatedReportBenchmark)

      const currentBenchmark: ReportBenchmark | undefined = index === -1
        ? undefined
        : this.savedScores[index]

      this.openReportBenchmarkPanel(updatedReportBenchmark)
      if (currentBenchmark?.collection === ScoreCollection.ComparisonAverage && updatedReportBenchmark.collection === currentBenchmark.collection) {
        if (updatedReportBenchmark.comparisonSurveyGroupId !== currentBenchmark.comparisonSurveyGroupId) {
          await this.handleSyncScores(updatedReportBenchmark)
          return
        }
      }

      this.updateReportBenchmark(updatedReportBenchmark)
    },
    updateReportBenchmark (updatedReportBenchmark: ReportBenchmark): void {
      const currentScores: ReportBenchmark[] = [...this.savedScores]
      const index: number = findReportBenchmarkIndex(currentScores, updatedReportBenchmark)

      if (!updatedReportBenchmark.id) {
        updatedReportBenchmark.id = uuid()
      }

      if (!this.hasBenchmarkBeenUpdated(updatedReportBenchmark.id)) {
        this.updatedBenchmarks.push(updatedReportBenchmark.id)
      }

      if (index === -1) {
        currentScores.push(updatedReportBenchmark)
      } else {
        currentScores.splice(index, 1, updatedReportBenchmark)
      }
      this.savedScores = sortReportBenchmarks(currentScores)
    },
    handleUniverseModalUpdate (val: boolean): void {
      if (val) {
        return
      }
      // User closed the modal without confirming
      this.editingReportBenchmark = null
      this.editingComparisonBenchmark = false
      this.editingUniverseBenchmark = false
    },
    async updateSelectedComparisonSurveyGroup (benchmark: ReportBenchmark, comparisonSurveyGroupId: string): Promise<void> {
      this.pendingFetchComparisonSurveyGroup = comparisonSurveyGroupId
      this.pendingFetchScores = true
      const updatedBenchmark: ReportBenchmark = {
        ...benchmark,
        comparisonSurveyGroupId,
        updatedAt: (new Date()).toISOString()
      }

      try {
        await this.$store.dispatch(
          'Analysis/getComparisonResultSet',
          {
            surveyGroupId: comparisonSurveyGroupId
          }
        )
        const comparisonResultSet: ResultSet = this.$store.state.Analysis.comparisonResultSet
        const comparisonScores: StatsResult[] = comparisonResultSet.sections.reduce(
          (scores: StatsResult[], section: SectionResults): StatsResult[] => {
            const subSections: BaseStatsResult[] = []
            if (section.averages?.subSections) {
              section.averages?.subSections.forEach((ss: SectionAveragesData): void => {
                const value: number | null = typeof ss.average === 'string'
                  ? parseInt(ss.average)
                  : ss.average
                if (!value || isNaN(value)) {
                  return undefined
                }
                subSections.push({
                  masterSectionId: section.masterSectionId,
                  sectionSlug: section.slug,
                  key: ss.slug,
                  name: ss.name,
                  value
                })
              })
            }
            if (section.averages?.average || subSections.length) {
              const value: number | null = typeof section.averages?.average === 'string'
                ? parseInt(section.averages.average)
                : (section.averages?.average ?? null)
              scores.push({
                masterSectionId: section.masterSectionId,
                sectionSlug: section.slug,
                key: section.masterSectionId,
                name: section.name,
                value,
                subSections: subSections.length ? subSections : undefined
              })
            }
            return scores
          },
          []
        )
        if (!comparisonScores.length) {
          this.$toasted.info(
            this.$t('modal.report.config.editScores.editScore.comparison.noResultsError') as string
          )
          this.editingComparisonBenchmark = true
          this.editingReportBenchmark = benchmark
          return
        }
        updatedBenchmark.scores = comparisonScores
        this.updateReportBenchmark(updatedBenchmark)
        // await this.$store.dispatch('Report/saveComparisonResultSet', { comparisonSurveyGroupId })
      } catch (err) {
        console.error('Failed to fetch data to save Comparison Benchmark.', err)
        this.$toasted.error(
          this.$t('modal.report.config.editScores.editScore.fetchScoresError') as string
        )
        return
      } finally {
        this.pendingFetchComparisonSurveyGroup = null
        this.pendingFetchScores = false
      }
      this.handleCloseComparisonBenchmarkModal()
    },
    handleCreateComparisonBenchmark (comparisonSurveyGroupId: string): void {
      if (!this.editingReportBenchmark || !isV1ComparisonReportBenchmark(this.editingReportBenchmark)) {
        return
      }
      const updatedReportBenchmark = {
        ...this.editingReportBenchmark,
        comparisonSurveyGroupId,
        updatedAt: (new Date()).toISOString(),
        enabled: true
      }
      this.fetchV1ReportBenchmarkData(updatedReportBenchmark)
        .then(() => {
          if (!this.editingReportBenchmark) {
            this.openReportBenchmarkPanel(updatedReportBenchmark)
          }
        })
    },
    handleCloseComparisonBenchmarkModal (): void {
      this.editingReportBenchmark = null
      this.editingComparisonBenchmark = false
    },
    hasBenchmarkBeenUpdated (benchmarkId: string | undefined): boolean {
      if (!benchmarkId) {
        return false
      }
      return this.updatedBenchmarks.includes(benchmarkId)
    },
    canDeleteBenchmark (benchmark: ReportBenchmark): boolean {
      return benchmark.collection !== ScoreCollection.SystemAverage
    },
    normalizeFloat
  }
})
</script>

<style lang="scss" scoped="scoped">
.scores-panel {
  &--header {
    min-height: 48px !important;
  }
}
</style>
