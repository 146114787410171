<template>
  <v-row class="flex-column" no-gutters>
    <v-col class="shrink pt-2">
      <span
        class="text-body-2 valid-hint"
        :class="{
          'grey--text text--darken-2': isValid,
          'warning--text': !isValid,
        }"
      >
        {{ $t('page.viewSurvey.questions.MultipleChoice.tip') }}
      </span>
    </v-col>
    <v-col class="grow">
      <v-row
        ref="options"
        class="multichoicequestion options flex-grow-1 flex-wrap pt-2"
        justify="center"
        align="stretch"
        :class="{
          'single-row': singleRow
        }"
      >
        <template v-if="question.optionsType === RepeatForOptions.BoardMembers">
          <v-col
            v-for="{ individualId, individual: { preferredName, firstName, familyName } } in question.optionValues"
            :key="individualId"
            class="option flex-grow-0 d-flex justify-center"
            :style="optionStyle"
          >
            <div
              class="checkbox d-flex flex-column"
              :class="{
                'selected': isSelected(individualId),
                'disabled': isPreview,
              }"
              @click="selectAnswer(individualId)"
            >
              <div class="flex-grow-1 d-flex">
                <platform-avatar
                  v-if="individualId && questionnaire.companyId"
                  entity-type="individual"
                  class="avatar mb-4 mx-auto elevation-5"
                  placeholder-size="xl"
                  internal-loader
                  :size="null"
                  :entity="{ id: individualId, companyId: questionnaire.companyId, preferredName, firstName, familyName }"
                />
              </div>
              <span class="flex-shrink-1 grey--text text--darken-3">
                {{ preferredName || firstName }}
              </span>
            </div>
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import { debounce } from 'lodash'
import { Questionnaire } from '@/API'
import { MultipleChoiceQuestionData } from '@/types/Question'
import { RepeatForOptions } from '@/helpers/questionnaire/questions'
import PlatformAvatar from '@/components/shared/PlatformAvatar.vue'
import { BlockPresetResponse } from '@betterboards/shared/helpers/entities/Questionnaire/index'

export default Vue.extend({
  name: 'SurveyMultipleChoiceQuestion',
  components: {
    PlatformAvatar
  },
  props: {
    question: {
      type: Object as PropType<MultipleChoiceQuestionData>,
      required: true
    },
    value: {
      type: [Array, String] as PropType<string[] | typeof BlockPresetResponse>,
      required: false
    }, // answers
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    RepeatForOptions,
    optionStyle: {
      width: <string | undefined>undefined,
      flexBasis: <string | undefined>undefined
    },
    resizeHandler: <undefined | (() => void)>undefined,
    answers: <any[]>[]
  }),
  mounted () {
    this.calculateStyle()
    this.resizeHandler = debounce(
      () => {
        this.calculateStyle()
        setTimeout(() => {
          this.calculateStyle()
        }, 500)
      },
      200,
      { leading: false, trailing: true }
    )
    if (Array.isArray(this.value)) {
      this.answers = this.value
    }
    window.visualViewport.addEventListener('resize', this.resizeHandler)
  },
  beforeDestroy () {
    if (this.resizeHandler) {
      window.visualViewport.removeEventListener('resize', this.resizeHandler)
    }
  },
  watch: {
    isValid (val) {
      this.$emit('valid', val)
    },
    answers (val) {
      if (!this.isValid) {
        return
      }
      this.$emit('input', val)
    },
    value (val) {
      if (Array.isArray(val)) {
        this.answers = val
      }
    }
  },
  computed: {
    ...mapState('Survey', [
      'survey'
    ]),
    questionnaire (): Questionnaire | null {
      if (!this.survey) {
        return null
      }

      return this.survey.questionnaire
    },
    isValid (): boolean {
      if (!this.answers || !Array.isArray(this.answers)) {
        return true
      }
      return this.answers.length <= 3
    },
    singleRow (): boolean {
      return !!this.question.optionValues && this.question.optionValues.length <= 4
    }
  },
  methods: {
    /**
     * The goal of this function is to attempt to use calculated flexBasis-es to force all elements
     *  onto a set number of rows, while retaining a flex layout.
     * On mobile ($vuetify.breakpoint.xsOnly), this changes to trying to fit all options into as close to a grid as possible.
     */
    calculateStyle (): void {
      const NumberOfLines = 2

      const containerEl: any | undefined = this.$refs.options
      if (!containerEl || !this.question.optionValues?.length) {
        return
      }

      const containerWidth = containerEl.getBoundingClientRect().width
      const isMobile = this.$vuetify.breakpoint.xsOnly

      const totalItems: number = this.question.optionValues.length
      let itemsPerRow: number | undefined
      if (isMobile) {
        itemsPerRow = Math.floor(Math.sqrt(totalItems))
      } else {
        itemsPerRow = this.singleRow ? totalItems : Math.ceil(totalItems / NumberOfLines)
      }
      const width = itemsPerRow ? `${Math.floor(containerWidth / itemsPerRow)}px` : undefined

      this.optionStyle = {
        width,
        flexBasis: width
      }
    },
    selectAnswer (answerId: string): void {
      if (this.isPreview) {
        return
      }
      const selected = this.answers ? [...this.answers] : []
      const index = selected.findIndex((answer) => answer === answerId)
      if (index === -1) {
        selected.push(answerId)
      } else {
        selected.splice(index, 1)
      }
      this.answers = selected
    },
    isSelected (answerId: string): boolean {
      return Array.isArray(this.answers) && this.answers.some((answer) => answer === answerId)
    }
  }
})
</script>

<style lang="scss">
.valid-hint {
  transition: color ease-in-out 75ms;
}
.multichoicequestion.options {
  $minAvatarSize: 80px;
  $maxAvatarSize: 15vh; // Width responsiveness is handled in calculateStyle in the component
  &.single-row {
    .option {
      max-width: $maxAvatarSize * 1.5;
    }
  }
  .option {
    min-width: $minAvatarSize * 1.3;
    .checkbox {
      cursor: pointer;
      transition: padding ease-in-out 200ms;
      position: relative;

      &.disabled {
        cursor: not-allowed;

        &:after {
          $padding: 4px;
          content: ' ';
          position: absolute;
          left: -$padding;
          right: -$padding;
          top: -$padding;
          bottom: -$padding;
          background-color: var(--v-grey-darken1);
          opacity: 0.3;
          border-radius: 4px;
        }
      }

      &.selected {
        .avatar {
          border-width: 4px;
          box-shadow: 0 0 20px -3px var(--color-selected-shadow);
        }
      }

      .avatar {
        aspect-ratio: 1;
        border-radius: 4px !important;
        min-width: $minAvatarSize;
        min-height: $minAvatarSize;
        max-width: $maxAvatarSize;
        max-height: $maxAvatarSize;
        transition: border-width ease-in-out 200ms, box-shadow ease-in-out 200ms;
        border: 0 solid var(--color-selected-highlight);
        box-shadow: 0 0 0 0 var(--color-selected-shadow);

        img {
          display: block;

          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
