import { render, staticRenderFns } from "./BoardMember.vue?vue&type=template&id=59d42fc1&scoped=true"
import script from "./BoardMember.vue?vue&type=script&lang=ts"
export * from "./BoardMember.vue?vue&type=script&lang=ts"
import style0 from "./BoardMember.vue?vue&type=style&index=0&id=59d42fc1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59d42fc1",
  null
  
)

export default component.exports