/* tslint:disable */
/* eslint-disable */
import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getFullCompanyPackageItems = /* GraphQL */ `
  query GetFullCompanyPackage($companyId: ID!, $packageId: ID!, $type: ModelPackageItemTypesInput, $key: ModelStringInput) {
    getCompanyPackage(companyId: $companyId, packageId: $packageId) {
      companyId
      packageId
      inherited
      public
      package {
        id
        categories
        name
        description
        variants
        packageItems(filter: { type: $type, key: $key }) {
          items {
            packageId
            type
            key
            content
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
` as GeneratedQuery<
  APITypes.GetFullCompanyPackageQueryVariables,
  APITypes.GetFullCompanyPackageQuery
>
