<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fill-rule="evenodd">
      <g stroke="currentColor">
        <g>
          <g>
            <g>
              <path stroke-linecap="square" d="M5 35L35 35M5 35L5 5" transform="translate(-364 -1481) translate(348 1372) translate(0 94) translate(16 15)"/>
              <path stroke-linecap="round" d="M2 7L5 4 8 7" transform="translate(-364 -1481) translate(348 1372) translate(0 94) translate(16 15)"/>
              <path stroke-linecap="round" d="M31.5 36.5L34.5 33.5 37.5 36.5" transform="translate(-364 -1481) translate(348 1372) translate(0 94) translate(16 15) rotate(90 34.5 35)"/>
              <path stroke-linecap="round" d="M9 25.009C11.192 17.003 13.073 13 14.644 13c2.356 0 5.959 17.29 8.365 17.29 2.407 0 6.299-24.025 9.38-24.025 2.054 0 3.514 3.578 4.38 10.735" transform="translate(-364 -1481) translate(348 1372) translate(0 94) translate(16 15)"/>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'AppraisalGraphIcon'
})
</script>
