import PlatformTooltip from '@/components/shared/PlatformTooltip.vue'
import Vue from 'vue'
import { v4 as uuid } from 'uuid'
import { DirectiveBinding } from 'vue/types/options'
import { CombinedVueInstance } from 'vue/types/vue'

const instanceCache: { [componentId: string]: CombinedVueInstance<any, any, any, any, any> } = {}

const Tooltip = Vue.extend(PlatformTooltip)

function getBindingContent (binding: DirectiveBinding): string | undefined {
  return typeof binding.value === 'object' ? binding.value.content : binding.value
}

function createTooltip (el: HTMLElement, binding: DirectiveBinding): CombinedVueInstance<any, any, any, any, any> {
  const content = getBindingContent(binding)
  const config = typeof binding.value === 'object' ? binding.value : {}
  const attachSide = binding.arg ?? config.side ?? 'top'

  if (!content) {
    // console.debug('Ignoring call to createTooltip with no content in binding.')
    return
  }

  const instance = new Tooltip({
    propsData: {
      top: attachSide === 'top',
      right: attachSide === 'right',
      bottom: attachSide === 'bottom',
      left: attachSide === 'left',
      ...config,
      content,
      activator: el
    }
  })
  instance.$mount()

  const componentId = uuid()
  el.dataset.tooltipId = componentId
  instanceCache[componentId] = instance
  return instance
}

/**
 * v-tooltip directive
 * Usage:
 *   `v-tooltip="'A cool tooltip!'"`
 *   `v-tooltip:right="'A cool tooltip on the right side!'"`
 *   `v-tooltip:[side]="'A cool tooltip on the right side!'"` // where `side` is any dynamic property
 *   `v-tooltip="{ content: 'A cool tooltip on the right side!', side: 'bottom', nudgeLeft: 80 }"`
 */
Vue.directive('tooltip', {
  inserted (el, binding: DirectiveBinding) {
    createTooltip(el, binding)
  },
  componentUpdated (el, binding) {
    const componentId = el.dataset.tooltipId as string

    if (!instanceCache[componentId]) {
      // If el is missing from cache it was probably ignored in the inserted call due to null content, so we'll create it now.
      createTooltip(el, binding)
      return
    }
    const content = getBindingContent(binding)
    if (instanceCache[componentId].content === content) {
      return
    }
    instanceCache[componentId].content = content
  },
  unbind (el) {
    const componentId = el.dataset.tooltipId as string

    if (!instanceCache[componentId]) {
      // Probably not an error as the tooltip may have just never been inserted due to null content.
      // console.warn('Failed to unbind tooltip due to element missing from cache.')
      return
    }
    instanceCache[componentId].$destroy(true)
    instanceCache[componentId] = undefined
  }
})
