<template>
  <v-row no-gutters align="center" class="flex-column pt-2">
    <div class="mb-2 px-1">
      <span class="grey--text text--darken-2">
        {{ $t('modal.addMFADevice.SMS.phoneNumberInstruction') }}
      </span>
    </div>
    <v-row no-gutters align="center" justify="center">
      <v-form v-model="phoneNumberValid" @submit.prevent="" class="px-3 pb-2">
        <v-row no-gutters align="start" justify="center" class="flex-nowrap">
          <v-col
            class="pr-1"
            style="max-width: 115px;"
          >
            <platform-autocomplete
              v-model="formData.dialCodeCountry"
              hide-details
              required
              class="dial-code__input"
              :label="$t('modal.addMFADevice.SMS.dialCodeFieldLabel')"
              :filter="filterDialCodes"
              :items="countryDialCodeOptions"
              :disabled="pendingAddPhoneNumber || pendingAddMethod || sentVerificationCode"
            >
              <template #item="{ on, attrs, item }">
                <v-list-item
                  v-on="on"
                  v-bind="attrs"
                  style="min-height: 40px;"
                >
                  <v-row
                    no-gutters
                    class="flex-nowrap"
                    align="center"
                    justify="start"
                    style="max-width: 250px;"
                  >
                    <div style="width: 30px;" class="mr-1 d-flex justify-center">
                      <country-flag
                        :countryCode="item.value"
                        class="country-flag"
                      />
                    </div>
                    <span class="text-body-2 grey--text text--darken-2">
                      {{ item.label }}
                      <span class="font-italic">
                        (+{{ item.text }})
                      </span>
                    </span>
                  </v-row>
                </v-list-item>
              </template>
              <template #selection="{ item }">
                <v-row
                  no-gutters
                  class="flex-nowrap mr-1"
                  align="center"
                  justify="start"
                >
                  <div style="width: 35px;" class="pr-1">
                    <country-flag
                      :countryCode="item.value"
                    />
                  </div>
                  <span class="grey--text text--darken-2">
                    +{{ item.text }}
                  </span>
                </v-row>
              </template>
            </platform-autocomplete>
          </v-col>
          <v-col>
            <platform-text-field
              required
              v-model="formData.phoneNumber"
              :label="$t('modal.addMFADevice.SMS.phoneNumberFieldLabel')"
              :rules="phoneNumberFieldValidators"
              :loading="pendingAddPhoneNumber || pendingAddMethod"
              :disabled="pendingAddPhoneNumber || pendingAddMethod || sentVerificationCode"
            />
          </v-col>
        </v-row>
      </v-form>
      <platform-button
        icon="send"
        secondary
        class="ml-2"
        :loading="pendingAddPhoneNumber"
        :disabled="!formValid || pendingAddPhoneNumber || sentVerificationCode"
        @click="handleUpdateUserPhoneNumber"
      >
        Send
      </platform-button>
    </v-row>
    <div class="mt-4 mb-5 px-1">
      <span class="grey--text text--darken-2">
        {{ $t('modal.addMFADevice.SMS.verificationCodeInstruction') }}
      </span>
    </div>
    <v-form v-model="verificationCodeValid" @submit.prevent="">
      <platform-text-field
        required
        v-model="formData.verificationCode"
        outlined
        :label="$t('modal.addMFADevice.SMS.verificationCodeFieldLabel')"
        :rules="verificationCodeFieldValidators"
        :loading="pendingAddMethod"
        :disabled="!sentVerificationCode"
      />
    </v-form>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformCognitoUser } from '@/types/User'
import { Validators } from '@/helpers/validation'
import { SelectItem } from '@/helpers/forms'
import { CountryDialCodes } from '@/helpers/phone'
import CountryFlag from '@/components/shared/CountryFlag.vue'
import PlatformAutocomplete from '@/components/shared/inputs/PlatformAutocomplete.vue'
import PlatformTextField from '@/components/shared/inputs/PlatformTextField.vue'
import { updateUserAttributes, sendUserAttributeVerificationCode } from 'aws-amplify/auth'

interface CountryCodeSelectItem extends SelectItem {
  label: string
}

export default Vue.extend({
  name: 'AddSMSMethodForm',
  components: {
    CountryFlag,
    PlatformTextField,
    PlatformAutocomplete
  },
  props: {
    currentCognitoUser: { type: Object as PropType<PlatformCognitoUser>, required: true }
  },
  data: () => ({
    formData: {
      dialCodeCountry: <string | null>null,
      phoneNumber: <string | null>null,
      verificationCode: <null | string>null
    },
    phoneNumberValid: false,
    verificationCodeValid: false,
    pendingAddPhoneNumber: false,
    pendingAddMethod: false,
    sentVerificationCode: false
  }),
  watch: {
    verificationCodeValid (val: boolean): void {
      this.$emit('valid', val)
    },
    'formData.verificationCode' (val): void {
      this.$emit('input', val)
    }
  },
  computed: {
    formValid (): boolean {
      return !!this.formData.dialCodeCountry && !!this.formData.phoneNumber && this.phoneNumberValid
    },
    phoneNumberFieldValidators (): any[] {
      return [
        Validators.maxLength(15),
        Validators.phoneNumber
      ]
    },
    verificationCodeFieldValidators (): any[] {
      return [
        Validators.maxLength(6)
      ]
    },
    countryDialCodeOptions (): CountryCodeSelectItem[] {
      return CountryDialCodes.map(({ dialCode, countryCode }: { dialCode: string, countryCode: string }): CountryCodeSelectItem => {
        return {
          label: this.$t(`global.countries.${countryCode}`) as string,
          text: dialCode,
          value: countryCode
        }
      })
    }
  },
  methods: {
    async handleUpdateUserPhoneNumber (): Promise<void> {
      if (this.pendingAddPhoneNumber || !this.currentCognitoUser || !this.formValid) {
        return
      }
      this.pendingAddPhoneNumber = true

      const dialCode = this.countryDialCodeOptions.find((o) => o.value === this.formData.dialCodeCountry)?.text
      const phoneNumber = `+${dialCode}${this.formData.phoneNumber}`
      const userAttributes = {
        phone_number: phoneNumber
      }

      try {
        await updateUserAttributes({
          userAttributes
        })
      } catch (err) {
        this.pendingAddPhoneNumber = false
        console.error('Failed to update phone number of current Cognito User, error:', err)
        this.$toasted.error(this.$t('modal.addMFADevice.SMS.errorMessage') as string)
        throw err
      }

      try {
        await sendUserAttributeVerificationCode({
          userAttributeKey: 'phone_number'
        })
      } catch (err) {
        console.error('Failed to trigger sending SMS verification code:', err)
        this.$toasted.error(this.$t('modal.addMFADevice.SMS.errorMessage') as string)
        throw err
      } finally {
        this.pendingAddPhoneNumber = false
      }
      this.sentVerificationCode = true
    },
    filterDialCodes (item: CountryCodeSelectItem, inputQuery: string, itemText: string): boolean {
      const queryText = inputQuery.toLowerCase()
      if (queryText.charAt(0) === '+') {
        return `+${itemText}`.includes(queryText)
      }
      return itemText.includes(queryText) || item.label.toLowerCase().includes(queryText)
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.country-flag {
  border: 1px solid var(--v-grey-darken2);
}

.dial-code__input::v-deep input {
  min-width: 25px !important;
}
</style>
