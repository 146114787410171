import API from '@/services/API'
import { RecommendationConditionRequest, RecommendationResponse } from '@betterboards/shared/types/Recommendations'
import { RecommendationContext } from '@betterboards/shared/helpers/entities/Report'
import { QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import { ApiError } from 'aws-amplify/api'

export enum ReportContextItem {
  EvaluationInformation = 'EvaluationInformation',
  KeyFindings = 'KeyFindings',
  IndividualStrengths = 'IndividualStrengths',
  SectionIntroduction = 'SectionIntroduction',
  CommitteesPage = 'CommitteesPage',
  CommitteesStructurePage = 'CommitteesStructurePage',
  DividerPageSummary = 'DividerPageSummary',
  FocusAreas = 'FocusAreas',
}

export async function requestRecommendation (
  surveyGroupId: string,
  packageId: string | undefined,
  variantCode: QuestionnaireVariantCode,
  context: RecommendationContext,
  masterSectionId?: string | undefined,
  masterBlockId?: string | undefined,
  criteriaId?: string | undefined
): Promise<RecommendationResponse> {
  const body: RecommendationConditionRequest = {
    surveyGroupId,
    packageId,
    variantCode,
    context,
    masterSectionId,
    masterBlockId,
    criteriaId
  }
  let response: RecommendationResponse
  try {
    response = await API.post('backendfunctions', '/recommendations', {
      body
    })
  } catch (err) {
    if (err instanceof ApiError && err?.response?.statusCode !== 404) {
      console.error('Failed to fetch Recommendation.', err, { err, body })
    }
    throw err
  }
  return response
}
