import {
  ReportBenchmark,
  V1ComparisonReportBenchmark,
  V1ReportBenchmarkCondition
} from '@betterboards/shared/types/ReportBenchmark'
import { ScoreCollection } from '@betterboards/shared/helpers/entities/Result/scores'
import { getReportBenchmarkDisplayInfo } from '@betterboards/shared/helpers/statistics/getReportBenchmarkDisplayInfo'
import { isV1ComparisonReportBenchmark } from '@betterboards/shared/helpers/statistics/ReportBenchmark'
import { PlatformCompany } from '@betterboards/shared/types/Company'

export default function buildComparisonBenchmarkOptions (
  existingBenchmarks: ReportBenchmark[],
  selectedCompany: PlatformCompany
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  availableBenchmarks.push(
    ...buildV1ComparisonBenchmarkOptions(
      existingBenchmarks,
      selectedCompany
    )
  )

  return availableBenchmarks
}

export function buildV1ComparisonBenchmarkOptions (
  existingBenchmarks: ReportBenchmark[],
  selectedCompany: PlatformCompany
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  const existingV1ComparisonBenchmark: V1ComparisonReportBenchmark | undefined = existingBenchmarks.find(isV1ComparisonReportBenchmark)

  let comparisonBenchmarkData: V1ReportBenchmarkCondition<ScoreCollection.ComparisonAverage> | undefined = existingV1ComparisonBenchmark
  if (!comparisonBenchmarkData) {
    comparisonBenchmarkData = {
      collection: ScoreCollection.ComparisonAverage,
      version: '1'
    }
  }

  const benchmark: ReportBenchmark | undefined = getReportBenchmarkDisplayInfo(comparisonBenchmarkData)

  if (benchmark) {
    availableBenchmarks.push(benchmark)
  }

  return availableBenchmarks
}
