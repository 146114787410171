<template>
  <platform-infinite-scroll
    :fetching="pendingFetchQuestionnaires"
    :fetched-all="fetchedWholeList"
    :disabled="fetchFailed"
    :activate-distance="300"
    @fetch="fetchPage"
  >
    <v-container id="questionnaires--list">
      <create-questionnaire-modal
        v-if="creatingQuestionnaire"
        v-model="creatingQuestionnaire"
      />
      <survey-group-selector-modal
        v-if="selectingSurveyGroupOptions"
        :survey-groups="selectingSurveyGroupOptions"
        :target-route="AnalysisRouteName"
        @close="selectingSurveyGroupOptions = undefined"
      />
      <v-row :class="{ 'flex-column-reverse': $vuetify.breakpoint.mdAndDown }">
        <v-col :cols="12" :lg="8">
          <h3 v-if="!pendingFetchQuestionnaires && !list.length">
            {{ $t('page.questionnaires.list.noResults') }}
          </h3>
          <template v-else>
            <v-row
              v-for="(questionnaire) in list"
              :key="questionnaire.id"
              no-gutters
            >
              <v-col>
                <platform-entity-card
                  align="start"
                  :entity-type="getQuestionnaireCardEntityType(surveyGroupQuestionnairesMap[questionnaire.id])"
                  :entity="getQuestionnaireCardEntity(questionnaire, surveyGroupQuestionnairesMap[questionnaire.id])"
                  list-members
                  row
                  :loading-members="pendingFetchSurveyGroups && (questionnaire.status === 'RELEASED' || questionnaire.status === 'DISPATCHED')"
                  :inline-header="inlineHeader"
                  @edit="$router.push({ name: 'QuestionnaireEdit', params: { id: questionnaire.id, target: questionnaire }})"
                  @delete="removeQuestionnaire(questionnaire)"
                  @displayVersions="displaySurveyGroupSelection(surveyGroupQuestionnairesMap[questionnaire.id])"
                >
                  <v-progress-linear
                    v-if="questionnaire.status === 'RELEASED' || questionnaire.status === 'DISPATCHED'"
                    :value="surveyGroupQuestionnairesMap[questionnaire.id] ? surveyGroupQuestionnairesMap[questionnaire.id].completion : 0"
                    :height="12"
                    rounded
                    class="mb-4"
                  >
                    <span
                      v-if="surveyGroupQuestionnairesMap[questionnaire.id]"
                      class="grey--text text--lighten-3"
                      style="font-size: 8px;"
                    >
                      {{ surveyGroupQuestionnairesMap[questionnaire.id].displayCompletion }}
                    </span>
                  </v-progress-linear>
                  <template slot="actions">
                    <platform-button
                      secondary
                      class="mx-1 my-1 grow"
                      icon="preview"
                      :to="{ name: 'PreviewSurvey', params: { surveyId: questionnaire.id }}"
                    >
                      {{ $t('page.questionnaires.list.previewAction') }}
                    </platform-button>
                  </template>
                  <template slot="primaryActions">
                    <platform-button
                      v-if="questionnaire.status === 'DISPATCHED'"
                      primary
                      class="my-1 mx-1 grow"
                      icon="analysis"
                      :disabled="!canAnalyse(questionnaire.id)"
                      :to="getSurveyGroupRoute(questionnaire.id)"
                    >
                      {{ $t('page.questionnaires.list.analyseAction') }}
                    </platform-button>
                    <platform-button
                      v-if="questionnaire.status === 'NEW' || questionnaire.status === 'RELEASED'"
                      primary
                      icon="dispatch"
                      class="my-1 mx-1 grow"
                      :to="{
                        name: 'SurveyRelease',
                        params: {
                          id: questionnaire.id,
                          target: questionnaire
                        }
                      }"
                    >
                      {{ $t('page.questionnaires.list.releaseAction') }}
                    </platform-button>
                  </template>
                </platform-entity-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col :cols="12" :lg="4">
          <v-card>
            <v-card-title>{{ $t('page.questionnaires.list.header') }}</v-card-title>
            <v-card-subtitle>{{ $t('page.questionnaires.list.description') }}</v-card-subtitle>
            <v-card-actions>
              <v-btn
                color="primary"
                @click="creatingQuestionnaire = true"
              >
                {{ $t('page.questionnaires.list.createQuestionnaireAction') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </platform-infinite-scroll>
</template>

<script lang="ts">
import SurveyGroupSelectorModal from '@/components/questionnaire/releases/SurveyGroupSelectorModal.vue'
import { PlatformEntityTypes } from '@/types/Platform'
import { QuestionnaireListItem, SurveyGroupProgress } from '@/types/SurveyGroup'
import Vue from 'vue'
import { RawLocation } from 'vue-router'
import { mapState, mapGetters, mapActions } from 'vuex'
import CreateQuestionnaireModal from '@/components/questionnaire/CreateQuestionnaireModal.vue'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import PlatformInfiniteScroll from '@/components/shared/PlatformInfiniteScroll.vue'
import { Survey, Questionnaire } from '@/API'
import API from '@/services/API'
import { calculateCompletion } from '@/helpers/survey'
import { updateQuestionnaire } from '@/graphql/mutations'
import { ignoreParentCompanyError } from '@/helpers/company'
import { SortQuestionnairesSurveyGroups } from '@/helpers/surveyGroup'
import { PlatformQuestionnaire } from '@betterboards/shared/types/Platform'

const AnalysisRouteName = 'Analysis'

export default Vue.extend({
  name: 'QuestionnaireList',
  components: {
    SurveyGroupSelectorModal,
    PlatformInfiniteScroll,
    CreateQuestionnaireModal,
    PlatformEntityCard
  },
  props: {
    isFinished: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    creatingQuestionnaire: false,
    selectingSurveyGroupOptions: <SurveyGroupProgress[] | undefined>undefined,
    pendingRefresh: false,
    allSurveys: <any[]>[],
    pendingFetchQuestionnaires: false,
    pendingFetchSurveyGroups: false,
    fetchedAll: false,
    fetchFailed: false,
    AnalysisRouteName
  }),
  mounted () {
    if (!this.selectedCompany) {
      return
    }

    this.setupSubscriptions()
    this.fetchData()
  },
  beforeDestroy () {
    this.$store.commit('Questionnaire/teardownSubscriptions')
  },
  watch: {
    selectedCompany (val) {
      if (!val) {
        return
      }
      this.$store.commit('Questionnaire/teardownSubscriptions')
      this.setupSubscriptions()
      this.fetchData()
    }
  },
  computed: {
    ...mapState('Questionnaire', ['listCompanyId', 'listNextToken']),
    ...mapState('Questionnaire', { questionnairesList: 'list' }),
    ...mapGetters('Questionnaire', ['fetchedWholeList']),
    ...mapGetters('SurveyGroup', { surveyGroupsList: 'list' }),
    ...mapState('Company', ['selectedCompany']),
    inlineHeader (): boolean {
      return this.$vuetify.breakpoint.smAndUp
    },
    list (): Questionnaire[] {
      return [...this.questionnairesList].sort(SortQuestionnairesSurveyGroups)
    },
    surveyGroupQuestionnairesMap (): { [questionnaireId: number]: SurveyGroupProgress } {
      const map = {}
      if (this.pendingFetchSurveyGroups) {
        this.questionnairesList.forEach((questionnaire) => {
          map[questionnaire.id] = null
        })
        return map
      }
      this.surveyGroupsList.forEach((surveyGroup) => {
        map[surveyGroup.questionnaireId] = surveyGroup
      })
      return map
    }
  },
  methods: {
    ...mapActions('Questionnaire', ['loadList', 'deleteQuestionnaire', 'setupSubscriptions']),
    ...mapActions('Survey', ['fetchReleases']),
    fetchData (): void {
      if (this.listCompanyId !== this.selectedCompany.id) {
        // We don't need to show the loader if the current data is already for the correct company, we can just fetch
        //  in the background and update.
        this.$store.commit('Questionnaire/resetList')
      }
      void this.fetchSurveyGroups()
      this.fetchPage(true)
    },
    fetchPage (resetList: boolean = false): void {
      this.pendingFetchQuestionnaires = true
      this.loadList(resetList)
        .catch((err) => {
          this.fetchFailed = true
          console.error('Questionnaire List fetchPage failed', err)
          throw err
        })
        .finally(() => {
          this.pendingFetchQuestionnaires = false
          if (!this.listNextToken) {
            this.fetchedAll = true
          }
        })
    },
    async fetchSurveyGroups (): Promise<void> {
      const companyId = this.selectedCompany?.id
      if (!companyId) {
        throw new Error('Failed to fetch SurveyGroups for list as no selected Company was found.')
      }
      this.pendingFetchSurveyGroups = true

      try {
        await this.$store.dispatch('SurveyGroup/listSurveyGroups', {
          companyId
        })
      } finally {
        this.pendingFetchSurveyGroups = false
      }
    },
    getSurveyGroupRoute (questionnaireId: string): RawLocation | undefined {
      const surveyGroup = this.surveyGroupQuestionnairesMap[questionnaireId]
      if (!surveyGroup) {
        return undefined
      }

      return {
        name: AnalysisRouteName,
        params: {
          surveyId: surveyGroup.id
        }
      }
    },
    calculateCompletion (group: Survey[]): number | undefined {
      const totalCompletion = group.reduce((total, survey) => total + (calculateCompletion(survey) ?? 0), 0)
      return totalCompletion / group.length
    },
    getCompletionDisplayVal (group: Survey[]): string | undefined {
      const val = this.calculateCompletion(group)
      if (!val || isNaN(val as any)) {
        return '0%'
      }
      return `${Math.round(val)}%`
    },
    displaySurveyGroupSelection (surveyGroup: SurveyGroupProgress): void {
      this.selectingSurveyGroupOptions = this.surveyGroupsList.filter((s) => s.questionnaireId === surveyGroup.questionnaireId)
    },
    async removeQuestionnaire (questionnaire: QuestionnaireListItem): Promise<void> {
      try {
        await API.graphql({
          query: updateQuestionnaire,
          variables: {
            input: {
              id: questionnaire.id,
              deletedAt: new Date().toISOString()
            }
          }
        })
        this.$store.commit('Questionnaire/removeFromList', questionnaire)
        this.$toasted.success(this.$t('page.questionnaires.toast.deleteSuccess') as string)
      } catch (err) {
        if (!ignoreParentCompanyError(err)) {
          console.error('Caught an error while trying to delete questionnaire:', err)
          this.$toasted.error(this.$t('page.questionnaires.toast.deleteError') as string)
          throw err
        }
      }
    },
    getQuestionnaireCardEntity (questionnaire: PlatformQuestionnaire, surveyGroup?: SurveyGroupProgress): PlatformQuestionnaire | SurveyGroupProgress {
      return surveyGroup ?? questionnaire
    },
    getQuestionnaireCardEntityType (surveyGroup: SurveyGroupProgress | undefined): PlatformEntityTypes {
      return surveyGroup ? PlatformEntityTypes.SurveyGroup : PlatformEntityTypes.Questionnaire
    },
    canAnalyse (questionnaireId: string): boolean {
      return !!this.surveyGroupQuestionnairesMap[questionnaireId]
    }
  }
})
</script>
