import { ResultSection, ResultType, SectionResults } from '../../../types/ResultSet'

export function findComparisonResult <S extends SectionResults = ResultSection> (
  comparisonSection: S | undefined,
  result: ResultType
): ResultType | undefined {
  if (!comparisonSection?.results) {
    return undefined
  }
  return comparisonSection.results.find((r): boolean => {
    if (result.graphType && r.graphType !== result.graphType) {
      return false
    }
    if (result.criteriaId && r.criteriaId !== result.criteriaId) {
      return false
    }
    if (result.data.masterBlockId) {
      return r.data.masterBlockId === result.data.masterBlockId
    }
    if (result.criteriaId && result.criteriaId && r.criteriaId === result.criteriaId) {
      return true
    }
    return !!result.criteria && r.criteria === result.criteria
  })
}
