import * as d3 from 'd3'
import { FontSizes } from '../types/Graph'
import { AbstractGraph, GraphConfig, GraphInterface } from './Abstract'

export interface ScatterGraphDataPoint {
  x: number
  y: number
  id?: string
  label?: string
  value?: string
  alignLabel?: 'top' | 'right' | 'bottom' | 'left'
  opportunityArea?: boolean
  darken?: boolean
  small?: boolean
}

export type ScatterGraphData = ScatterGraphDataPoint[]

export interface ScatterGraphConfig extends GraphConfig {
  width: number
  height: number
  xTicks: number
  yTicks: number
  margin: {
    top: number
    left: number
    right: number
    bottom: number
  }
  axes: {
    x: {
      label: string
    }
    y: {
      label: string
    }
  }
  range: {
    min: {
      x: number
      y: number
    }
    max: {
      x: number
      y: number
    }
  }
  highlight?: {
    minX: number
    minY: number
    label?: string
    sublabel?: string
  }
  legend: boolean
}

export default class ScatterGraph extends AbstractGraph<ScatterGraphConfig> implements GraphInterface<ScatterGraphData> {
  public getDefaultConfig (): Partial<ScatterGraphConfig> {
    return {
      width: 800,
      height: 620,
      scale: 1.3,
      xTicks: 5,
      yTicks: 5,
      range: {
        min: {
          x: 1,
          y: 1
        },
        max: {
          x: 5,
          y: 5
        }
      },
      margin: {
        top: 30,
        left: 40 + FontSizes.AxisLabel * 2,
        right: 24,
        bottom: 40 + FontSizes.AxisLabel * 2
      },
      axes: {
        x: {
          label: 'Importance'
        },
        y: {
          label: 'Development'
        }
      },
      highlight: {
        minX: 4,
        minY: 4,
        label: 'Opportunity Areas',
        sublabel: '(more important, less developed)'
      }
    }
  }

  private svg!: d3.Selection<SVGElement, any, any, any>

  render (data: ScatterGraphData): void {
    super.render(data)

    if (data && Array.isArray(data)) {
      const points: Array<{ x: number, y: number, rows: any[] }> = []
      data.forEach((r) => {
        let point = points.find((p) => p.x === r.x && p.y === r.y)
        if (!point) {
          point = {
            x: r.x,
            y: r.y,
            rows: []
          }
          points.push(point)
        }
        point.rows.push(r)
      })
      points.forEach((p) => {
        p.rows.forEach((r, index) => {
          switch (index) {
            case 0:
              r.alignLabel = 'top'
              break
            case 1:
              r.alignLabel = 'right'
              break
            case 2:
              r.alignLabel = 'bottom'
              break
            case 3:
              r.alignLabel = 'left'
              break
          }
        })
      })
    }

    this.svg = d3
      .select(this.selector)

    // Clear current contents (in case this is a re-render)
    this.svg
      .selectAll('*')
      .remove()

    // const xVals = data.map((r) => r.x)
    // const yVals = data.map((r) => r.y)

    const heightScaleRatio = 1 // @TODO: This
    const strokeDashArray = (4 * heightScaleRatio).toString() + ', ' + (3 * heightScaleRatio).toString()

    // X axis
    const x = d3.scaleLinear()
      .domain([this.config.range.min.x, this.config.range.max.x])
      .range([this.config.margin.left, this.config.width - this.config.margin.right])

    const xAxisWidth = this.config.width - this.config.margin.left - this.config.margin.right
    const yAxisHeight = this.config.height - this.config.margin.top - this.config.margin.bottom

    this.drawGrid(xAxisWidth, yAxisHeight, strokeDashArray)

    const xAxis = this.svg.append('g')
      .attr('transform', `translate(0, ${this.config.height - this.config.margin.bottom})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(5)
      )
    xAxis.append('text')
      .text(this.config.axes.x.label)
      .attr('dx', this.config.margin.left + xAxisWidth / 2)
      .attr('dy', this.config.margin.bottom - this.config.scale * FontSizes.AxisLabel)
      .attr('font-size', this.config.scale * FontSizes.AxisLabel)
      .attr('font-weight', '700')
      .attr('text-anchor', 'middle')
      .attr('vertical-align', 'bottom')
      .style('fill', this.config.colors.primary)
    xAxis.select('.domain')
      .style('stroke', this.config.colors.grey.dark)
    xAxis.selectAll('.tick line')
      .style('stroke', this.config.colors.grey.medium)
    xAxis.selectAll('.tick text')
      .attr('font-size', this.config.scale * FontSizes.AxisLabel * 0.8)
      .attr('fill', this.config.colors.grey.dark)

    // Y axis
    const y = d3.scaleLinear()
      .domain([this.config.range.max.y, this.config.range.min.y])
      .range([this.config.margin.top, this.config.height - this.config.margin.bottom])
    const yAxis = this.svg.append('g')
      .attr('transform', `translate(${this.config.margin.left}, 0)`)
      .call(
        d3
          .axisLeft(y)
          .ticks(5)
      )
    yAxis
      .append('text')
      .text(this.config.axes.y.label)
      .attr('transform', 'rotate(-90)')
      .attr('dx', -yAxisHeight / 2 - this.config.margin.top)
      .attr('dy', -this.config.margin.left + this.config.scale * FontSizes.AxisLabel)
      .attr('font-size', this.config.scale * FontSizes.AxisLabel)
      .attr('font-weight', '700')
      .attr('text-anchor', 'middle')
      .attr('vertical-align', 'top')
      // .style('transform', 'rotate(-90deg)')
      .style('fill', this.config.colors.primary)
    yAxis.select('.domain')
      .style('stroke', this.config.colors.grey.dark)
    yAxis.selectAll('.tick line')
      .style('stroke', this.config.colors.grey.medium)
    yAxis.selectAll('.tick text')
      .attr('font-size', this.config.scale * FontSizes.AxisLabel * 0.8)
      .attr('fill', this.config.colors.grey.dark)

    // Add dots
    const dots = this.svg.append('g')
      .selectAll('dot')
      .data(data)
      .enter()

    const DotRadius = 4
    const DotHeight = DotRadius * 2
    dots.append('circle')
      .attr('cx', (d) => x(d.x) as number)
      .attr('cy', (d) => y(d.y) as number)
      .attr('r', (d) => d.small ? DotRadius * 0.8 : DotRadius)
      .style('fill', (d) => d.darken ? this.config.colors.secondaryAlternate : this.config.colors.secondary)
      .style('opacity', 0.7)
      .style('stroke', 'white')
    const padding = this.config.scale * FontSizes.DataLabel * 1.1
    dots.append('text')
      .attr('x', (d) => x(d.x) as number + (d.small ? 0.8 : 1) * (d.alignLabel === 'left' ? -padding : (d.alignLabel === 'right' ? padding : 0)))
      .attr('y', (d) => y(d.y) as number + DotHeight + (d.small ? 0.8 : 1) * (d.alignLabel === 'top' ? -padding : (d.alignLabel === 'bottom' ? padding : 0)))
      .attr('font-size', (d) => (d.small ? 0.8 : 1) * this.config.scale * FontSizes.DataLabel)
      .attr('text-anchor', 'middle')
      .style('font-weight', '900')
      .style('stroke', 'white')
      .style('stroke-width', 1)
      .style('fill', (d) => d.darken ? this.config.colors.primaryAlternate : this.config.colors.primary)
      .text((d) => d.label ?? null)

    // Highlight (eg. opportunity area)
    if (this.config.highlight) {
      const highlightX = x(this.config.highlight.minX) as number
      const highlightY = y(this.config.highlight.minY) as number
      const highlightWidth = x(this.config.range.max.x) as number - highlightX
      const highlightHeight = y(this.config.range.min.y) as number - highlightY
      const highlightLabel = this.config.highlight.label
      const highlightSublabel = this.config.highlight.sublabel

      const highlightGroup = this.svg.append('g')
        .attr('transform', `translate(${highlightX.toString()}, ${highlightY.toString()})`)
      highlightGroup.append('rect')
        .attr('width', highlightWidth)
        .attr('height', highlightHeight)
        .attr('fill-opacity', 0.2)
        .style('fill', this.config.colors.primary)
        .style('stroke', this.config.colors.primary)

      const highlightLabelHeight = highlightHeight * 0.75
      if (highlightLabel) {
        highlightGroup
          .append('text')
          .text(highlightLabel)
          .attr('dx', highlightWidth / 2)
          .attr('dy', highlightLabelHeight)
          .attr('font-size', this.config.scale * FontSizes.Label)
          .attr('font-weight', '700')
          .attr('text-anchor', 'middle')
          .attr('fill', this.config.colors.primary)
      }
      if (highlightSublabel) {
        highlightGroup
          .append('text')
          .text(highlightSublabel)
          .attr('dx', highlightWidth / 2)
          .attr('y', highlightLabelHeight + this.config.scale * FontSizes.Label + 2)
          .attr('font-size', this.config.scale * FontSizes.Sublabel)
          .attr('text-anchor', 'middle')
          .attr('fill', this.config.colors.primary)
      }
    }
  }

  private drawGrid (xAxisWidth: number, yAxisHeight: number, strokeDashArray: string): void {
    // Grid
    const horizontalGrid = d3.scaleLinear()
      .domain([this.config.range.max.y, this.config.range.min.y])
      .range([this.config.margin.top, this.config.height - this.config.margin.bottom])
    const hGrid = this.svg.append('g')
      .attr('transform', `translate(${this.config.margin.left}, 0)`)
      .call(
        d3
          .axisLeft(horizontalGrid)
          .ticks(5)
          .tickSize(-xAxisWidth)
      )
    hGrid
      .selectAll('.tick line')
      .style('stroke', this.config.colors.grey.light)
      .attr('stroke-dasharray', strokeDashArray)
    hGrid
      .selectAll('.tick text')
      .remove()
    hGrid
      .select('.domain')
      .remove()
    const verticalGrid = d3.scaleLinear()
      .domain([this.config.range.min.x, this.config.range.max.x])
      .range([this.config.margin.left, this.config.width - this.config.margin.right])
    const vGrid = this.svg.append('g')
      .attr('transform', `translate(0, ${this.config.margin.top})`)
      .call(
        d3
          .axisBottom(verticalGrid)
          .ticks(5)
          .tickSize(yAxisHeight)
      )
    vGrid
      .selectAll('.tick line')
      .style('stroke', this.config.colors.grey.light)
      .attr('stroke-dasharray', strokeDashArray)
    vGrid
      .selectAll('.tick text')
      .remove()
    vGrid
      .select('.domain')
      .remove()
  }
}
