<template>
  <v-row justify="start" class="fill-height flex-column" no-gutters>
    <v-col class="shrink">
      <platform-text-area
        v-model="answer"
        full-width
        clearable
        auto-grow
        class="text-answer"
        :label="$t('page.viewSurvey.yourAnswer')"
        :rows="minRows"
        :rules="form.validation.answer"
        :disabled="isPreview"
        :suffix="answer && answer.length >= MaxFieldLengths.TextQuestionResponse * 0.75 ? `${answer.length || '0'}/${MaxFieldLengths.TextQuestionResponse}` : undefined"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { TextQuestionData } from '@/types/Question'
import { Validators, MaxFieldLengths } from '@/helpers/validation'
import { BlockPresetResponse } from '@betterboards/shared/helpers/entities/Questionnaire/index'
import PlatformTextArea from '@/components/shared/inputs/PlatformTextArea.vue'

export default Vue.extend({
  name: 'SurveyTextQuestion',
  components: { PlatformTextArea },
  props: {
    question: { type: Object as PropType<TextQuestionData>, required: true },
    value: { type: String, required: false }, // answer
    isPreview: { type: Boolean, default: false }
  },
  data: () => ({
    minRows: 2,
    form: {
      validation: {
        answer: [
          Validators.maxLength(MaxFieldLengths.TextQuestionResponse)
        ]
      }
    },
    MaxFieldLengths
  }),
  computed: {
    answer: {
      get (): string {
        if (this.value === BlockPresetResponse.Skipped || this.value === BlockPresetResponse.Uncertain) {
          return ''
        }
        return this.value
      },
      set (val: string): void {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    //
  }
})
</script>

<style lang="scss" scoped="scoped">
.text-answer::v-deep {
  textarea {
    max-height: 7em;
    overflow-y: auto;
    padding-right: 10px;
  }
}
</style>
