import API from '@/services/API'
import {
  PlatformReportGroup,
  UpdateReportGroupRequestBody,
  UpdateReportGroupResponse
} from '@betterboards/shared/types/Report'
import { PlatformQuestionnaireVariant } from '@/types/Platform'

export function getDefaultReportGroup (reportGroups: PlatformReportGroup[]): PlatformReportGroup {
  return reportGroups.reduce((selectedReportGroup: PlatformReportGroup, reportGroup: PlatformReportGroup): PlatformReportGroup => {
    if (selectedReportGroup.createdAt.localeCompare(reportGroup.createdAt) < 0) {
      return selectedReportGroup
    }
    return reportGroup
  })
}

export async function createReportGroup (
  surveyGroupId: string,
  name: string,
  variants: PlatformQuestionnaireVariant[]
): Promise<void> {
  const payload = {
    surveyGroupId,
    name: name.trim(),
    variants: variants.map(({ name, variantCode }: PlatformQuestionnaireVariant) => ({
      name: name.trim(),
      variantCode: variantCode
    }))
  }

  console.debug('Creating ReportGroup with payload:', payload)
  let response
  try {
    response = await API.post('backendfunctions', '/report/group', {
      body: payload
    })
  } catch (err) {
    console.error('Failed to create ReportGroup', JSON.stringify({ payload, err }, null, 2))
    throw err
  }

  return response
}

export async function updateReportGroup (
  reportGroupData: UpdateReportGroupRequestBody
): Promise<UpdateReportGroupResponse> {
  if (!reportGroupData.reportGroupId) {
    throw new Error('Failed to update ReportGroup as no ReportGroup ID was provided.')
  }
  const payload = {
    reportGroupId: reportGroupData.reportGroupId,
    surveyGroupId: reportGroupData.surveyGroupId,
    scores: reportGroupData.scores,
    comparisonSurveyGroupId: reportGroupData.comparisonSurveyGroupId
  }

  console.debug('Updating ReportGroup/Reports with payload:', payload)
  let response
  try {
    response = await API.put('backendfunctions', '/report/group', {
      body: payload
    })
  } catch (err) {
    console.error('Failed to update ReportGroup/Reports', JSON.stringify({ payload, err }, null, 2))
    throw err
  }

  return response
}
