<template>
  <v-row ref="container" class="flex-column flex-nowrap overflow-y-auto" no-gutters>
    <v-col ref="content" class="shrink">
      <slot name="default" />
    </v-col>
    <v-col class="shrink pt-2 pb-6 text-center">
      <span v-if="fetchedAll" class="text-body-2 grey--text">
        <template v-if="hasScroll || fetches > 1">No more results to load.</template>
      </span>
      <template v-else>
        <template v-if="fetching">
          <platform-spinner v-if="!hideSpinner" :style="{ visibility: fetching ? undefined : 'hidden' }" class="mx-auto" />
        </template>
        <span v-else class="text-body-2 grey--text" :class="{ 'cursor-pointer': canManuallyFetch }" @click="manualFetch">
          <template v-if="hasScroll">
            Scroll down to fetch more results.
          </template>
          <template v-else>
            Click here to fetch more results.
          </template>
        </span>
      </template>
    </v-col>
    <v-col v-if="$slots.actions">
      <slot name="actions" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue from 'vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'

export default Vue.extend({
  name: 'PlatformInfiniteScroll',
  components: {
    PlatformSpinner
  },
  props: {
    fetching: { type: Boolean, default: false },
    hideSpinner: { type: Boolean, default: false },
    fetchedAll: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    activateDistance: { type: Number, default: 200 }
  },
  data () {
    return {
      scrollHandler: <any>null,
      lastActivateHeight: null,
      hasScroll: false,
      fetches: 0
    }
  },
  watch: {
    fetching (val) {
      /**
       * When fetching is completed, check if the new content is scrollable and set this.hasScroll
       */
      if (!val) {
        this.checkHasScroll()
      }
    }
  },
  mounted () {
    const container: any = this.$refs.container
    this.scrollHandler = (evt) => {
      if (this.fetching || this.fetchedAll || evt.target.scrollTop === this.lastActivateHeight) {
        return
      }
      if (evt.target.offsetHeight + evt.target.scrollTop + this.activateDistance >= evt.target.scrollHeight) {
        this.lastActivateHeight = evt.target.scrollTop
        this.fetch()
      }
    }
    if (container) {
      container.addEventListener('scroll', this.scrollHandler)
    }
    this.checkHasScroll()
  },
  beforeDestroy () {
    if (this.scrollHandler) {
      const container: any = this.$refs.container
      if (container) {
        container.removeEventListener('scroll', this.scrollHandler)
      }
    }
  },
  computed: {
    canManuallyFetch (): boolean {
      return !this.fetching
    }
  },
  methods: {
    fetch (): void {
      if (this.fetching || this.disabled) {
        return
      }
      this.fetches++
      this.$emit('fetch')
    },
    /**
     * Allows manually requesting the next page by clicking on the "scroll down.." message
     */
    manualFetch (): void {
      if (!this.canManuallyFetch) {
        return
      }
      this.fetch()
    },
    checkHasScroll (): void {
      const container: any = this.$refs.container
      const content: any = this.$refs.content
      this.$nextTick(() => {
        this.hasScroll = content.clientHeight > container.clientHeight
        if (!this.hasScroll && !this.fetching && !this.fetchedAll) {
          this.fetch()
        }
      })
    }
  }
})
</script>
