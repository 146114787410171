<template>
  <platform-menu-button
    :primary="action === 'queue'"
    :secondary="action === 'download'"
    close-on-click
    class="grow"
  >
    <template #menu v-if="hasMultipleVariants">
      <v-row no-gutters class="flex-column">
        <platform-button
          v-for="{ variantCode, tooltip, reportDocument } in reportDocumentVariants"
          :key="variantCode"
          v-show="variantCode !== selectedVariant"
          v-tooltip:bottom="tooltip"
          default-case
          class="pointer-events--auto mt-1"
          :primary="action === 'queue'"
          :secondary="action === 'download'"
          :icon="icon"
          :loading="pendingVariant === variantCode"
          :disabled="action === 'download' && (!reportDocument || !reportDocument.finishedAt)"
          @click="emitClick(variantCode)"
        >
          {{ actionButtonText }} ({{ variantCode.toUpperCase() }})
        </platform-button>
      </v-row>
    </template>
    <platform-button
      v-tooltip:bottom="defaultActionTooltip"
      default-case
      class="grow pointer-events--auto"
      :primary="action === 'queue'"
      :secondary="action === 'download'"
      :icon="icon"
      :loading="pendingVariant === selectedVariant || pendingVariant === true"
      :disabled="action === 'download' && (!latestReportDocument || !latestReportDocument.finishedAt)"
      @click="emitClick(selectedVariant)"
    >
      {{ actionButtonText }}
      <template v-if="hasMultipleVariants && selectedVariant && selectedVariant !== NullVariantCodeValue">
        ({{ selectedVariant.toUpperCase() }})
      </template>
    </platform-button>
  </platform-menu-button>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { QuestionnaireVariantCode } from '@betterboards/shared/types/Platform'
import { getReportDocumentDownloadTooltip, getVariantLatestReportDocument } from '@/helpers/reportDocument'
import PlatformMenuButton from '@/components/shared/PlatformMenuButton.vue'
import { NullVariantCodeValue } from '@/helpers/questionnaire'
import { PlatformReportDocument } from '@betterboards/shared/types/Report'

interface VariantReportDocumentInfo {
  variantCode: QuestionnaireVariantCode
  tooltip: string
  reportDocument: PlatformReportDocument | undefined
}

export default Vue.extend({
  name: 'ReportDocumentAction',
  components: {
    PlatformMenuButton
  },
  data: () => ({
    NullVariantCodeValue
  }),
  props: {
    action: { type: String as PropType<'queue' | 'download'>, required: true },
    variants: { type: Array as PropType<QuestionnaireVariantCode[] | undefined>, required: false },
    reportDocuments: { type: Array as PropType<PlatformReportDocument[]>, required: false },
    selectedVariant: { type: String as PropType<QuestionnaireVariantCode | undefined>, required: false },
    icon: { type: String, required: true },
    pendingVariant: { type: [Boolean, String] as PropType<boolean | QuestionnaireVariantCode>, default: false }
  },
  computed: {
    hasMultipleVariants (): boolean {
      if (!this.variants) {
        return false
      }
      return this.variants.length > 1
    },
    actionButtonText (): string {
      const translationKey = this.action === 'queue'
        ? 'analysis.generateReport.generateButton'
        : 'analysis.generateReport.downloadButton'
      return this.$t(translationKey) as string
    },
    latestReportDocument (): PlatformReportDocument | undefined {
      return getVariantLatestReportDocument(
        this.reportDocuments,
        this.selectedVariant
      )
    },
    defaultActionTooltip (): string {
      return getReportDocumentDownloadTooltip(this.latestReportDocument)
    },
    reportDocumentVariants (): VariantReportDocumentInfo[] {
      if (!this.variants) {
        return []
      }
      return this.variants.map((variantCode: QuestionnaireVariantCode): VariantReportDocumentInfo => {
        const reportDocument = getVariantLatestReportDocument(
          this.reportDocuments,
          variantCode
        )
        return {
          tooltip: getReportDocumentDownloadTooltip(reportDocument),
          variantCode,
          reportDocument
        }
      })
    }
  },
  methods: {
    emitClick (variantCode: QuestionnaireVariantCode | undefined): void {
      this.$emit('click', { variantCode })
    }
  }
})
</script>
