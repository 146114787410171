<template>
  <v-list>
    <h2
      v-if="questionnaire"
      class="serif text-center mb-4 overflow-ellipses"
      :class="{
        'text-body-2': !expanded
      }"
    >
      {{ questionnaireName }}
    </h2>
    <v-list-item
      v-for="(section, index) in displaySections"
      :key="`${section ? section.id : ''}-${index}`"
      ref="sectionLink"
      class="py-2"
      :disabled="!sectionHasQuestions(section)"
      :class="{
        'v-list-item--active': activeSection && section.id === activeSection.id && !showSubmitStep
      }"
      @click="setActiveSection(section)"
    >
      <platform-icon
        name="check-outline"
        class="mr-2 section-completion"
        :class="{
          completed: checkSectionCompleted(section)
        }"
        :color="checkSectionCompleted(section) ? 'secondary' : (activeSection && section.id === activeSection.id ? 'grey darken-3' : 'grey darken-2')"
        :size="30"
      />

      <span class="section-name">
        {{ section.name }}<br>
      </span>
    </v-list-item>
    <v-list-item
      class="py-2"
      :class="{
        'v-list-item--active': showSubmitStep
      }"
      @click="setShowSubmitStep(true)"
    >
      <platform-icon
        name="text-box-check"
        class="mr-2 section-completion"
        color="white"
        :size="30"
      />

      <span class="section-name">
        {{ $t('page.viewSurvey.submitStep') }}
      </span>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'
import { Block, Questionnaire, Section } from '@/API'
import { getQuestionnaireVariantName } from '@betterboards/shared/helpers/entities/Variants/getVariantName'
import sortByDisplayOrder from '@betterboards/shared/helpers/util/sortByDisplayOrder'

export default Vue.extend({
  name: 'SurveySidebar',
  props: {
    expanded: { type: Boolean, default: false }
  },
  data: () => ({
    completedSections: []
  }),
  watch: {
    sectionIndex (sectionIndex) {
      const sectionLink = this.$refs.sectionLink?.[sectionIndex]
      if (!sectionLink?.$el) {
        return
      }
      sectionLink.$el.scrollIntoView({
        block: 'nearest'
      })
    }
  },
  computed: {
    ...mapState('Survey', [
      'survey',
      'sectionIndex',
      'blockIndex'
    ]),
    questionnaireName (): string | undefined {
      return getQuestionnaireVariantName(this.survey.questionnaire, this.survey.variantCode)
    },
    questionnaire (): Questionnaire | null {
      console.log('this.survey', this.survey)
      if (!this.survey) {
        return null
      }

      return this.survey.questionnaire
    },
    sections (): Section[] {
      if (!this.questionnaire?.sections?.items) {
        return []
      }
      const sections = this.questionnaire.sections.items.filter(
        (section: Section | null): boolean => !!section
      ) as Section[]
      return sections.sort(sortByDisplayOrder)
    },
    displaySections (): Section[] {
      return this.sections.filter((section: Section | null): boolean => {
        return !!section?.blocks?.items?.length
      })
    },
    totalQuestions (): number {
      return this.sections.reduce(
        (numQuestions, section) => numQuestions + (section.blocks?.items?.length ?? 0),
        0
      )
    },
    currentQuestionNumber (): number | null {
      let questionNumber = 1

      console.log('this.sectionIndex', this.sectionIndex)
      console.log('this.blockIndex', this.blockIndex)

      for (let s = 0; s < this.sectionIndex; s++) {
        questionNumber += this.sections[s].blocks?.items?.length ?? 0
      }

      return questionNumber + this.blockIndex
    },
    showSubmitStep (): boolean {
      return this.$store.state.Survey.showSubmitStep
    },
    activeSection (): Section {
      return this.sections[this.sectionIndex]
    },
    activeSectionBlocks (): Block[] {
      const blocks = this.activeSection.blocks?.items?.filter(block => !!block) as Block[] ?? []
      return blocks.sort(sortByDisplayOrder)
    },
    activeBlock (): Block {
      return this.activeSectionBlocks[this.blockIndex]
    }
  },
  methods: {
    ...mapMutations('Survey', ['setQuestionIndex']),
    setActiveSection (section: Section): void {
      console.debug('setActiveSection', section)
      if (!section.blocks?.items?.length) {
        return
      }
      const sectionIndex: number = this.sections.findIndex(
        (s: Section): boolean => s.id === section.id
      )
      if (this.showSubmitStep) {
        this.setShowSubmitStep(false)
      }
      this.setQuestionIndex({
        sectionIndex
      })
    },
    setShowSubmitStep (showSubmitStep: boolean): void {
      this.$store.commit('Survey/setShowSubmitStep', showSubmitStep)
    },
    sectionHasQuestions (section: Section): boolean {
      return !!section.blocks?.items?.length
    },
    checkSectionCompleted (section: Section): boolean {
      // @TODO: Implement completing sections
      return this.completedSections.some((sectionId) => sectionId === section.id)
    }
  }
})
</script>

<style lang="scss" scoped>
.section-completion {
  i {
    background: linear-gradient(to bottom, var(--v-grey-darken1), var(--v-grey-darken2));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.completed {
    i {
      background: linear-gradient(to bottom, var(--v-secondary-lighten2), var(--v-secondary-base));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.v-list-item--active {
  .section-completion:not(.completed) {
    i {
      background: linear-gradient(to bottom, var(--v-grey-darken3), var(--v-grey-darken4));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .section-name {
    font-weight: bold;
    letter-spacing: -0.1px;
  }
}
</style>
