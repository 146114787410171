<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g transform="translate(-364 -1390) translate(348 1372) translate(16 18)">
            <path stroke="currentColor" stroke-linecap="square" d="M5 35L35 35M5 35L5 5"/>
            <path stroke="currentColor" stroke-linecap="round" d="M2 7L5 4 8 7"/>
            <path stroke="currentColor" stroke-linecap="round" d="M31.5 36.5L34.5 33.5 37.5 36.5" transform="rotate(90 34.5 35)"/>
            <circle cx="11" cy="13" r="2" fill="currentColor"/>
            <circle cx="19" cy="19" r="2" fill="currentColor"/>
            <circle cx="28" cy="10" r="2" fill="currentColor"/>
            <circle cx="31" cy="24" r="2" fill="currentColor"/>
            <circle cx="14" cy="29" r="2" fill="currentColor"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'ScatterGraphIcon'
})
</script>
