<template>
  <v-menu
    ref="menu"
    v-model="menuActive"
    transition="scale-transition"
    offset-y
    :close-on-content-click="false"
    :max-width="maxWidth"
    :disabled="disabled"
    :nudge-top="nudgeTop"
    :nudge-left="nudgeLeft"
    :nudge-right="nudgeRight"
    :nudge-bottom="nudgeBottom"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot
        name="activator"
        v-bind="{ on, attrs }"
      />
    </template>
    <template>
      <v-card
        class="platform-menu py-2 px-3"
        :min-width="350"
      >
        <slot name="default" />
      </v-card>
    </template>
  </v-menu>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'PlatformMenu',
  components: {
    //
  },
  props: {
    value: { type: Boolean, required: false },
    maxWidth: { type: Number, default: 500 },
    disabled: { type: Boolean, required: false },
    nudgeTop: { type: Number, required: false },
    nudgeLeft: { type: Number, required: false },
    nudgeRight: { type: Number, required: false },
    nudgeBottom: { type: Number, required: false }
  },
  data: () => ({
    menuActive: false
  }),
  watch: {
    value (value: boolean): void {
      this.menuActive = value
    },
    menuActive (menuActive: boolean): void {
      this.$emit('input', menuActive)
    }
  },
  computed: {
    //
  },
  methods: {
    //
  }
})
</script>

<style lang="scss" scoped>

</style>
