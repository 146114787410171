export interface GenericObject { [key: string]: any }

export type ObjectFields<O extends GenericObject> = Array<keyof O & string>

export type OmitObjectFields<
  O extends GenericObject,
  OmitFields extends ObjectFields<O>
> = Omit<GenericObject, keyof OmitFields>

/**
 * Returns a copy of the target object with the provided root properties ommitted.
 *  Does not de-duplicate nested fields.
 */
export default function omitObjectFields <
  T extends GenericObject = any,
  OmitFields extends ObjectFields<T> = ObjectFields<T>,
  R extends OmitObjectFields<GenericObject, OmitFields> = OmitObjectFields<GenericObject, OmitFields>
> (target: T, omitFields: OmitFields): R {
  const fields: string[] = Object.keys(target)
  return fields.reduce<R>(
    (newObject: R, field: string): R => {
      if (!omitFields.includes(field)) {
        newObject[field] = target[field]
      }
      return newObject
    },
    {} as R
  )
}
