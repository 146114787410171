/* tslint:disable */
/* eslint-disable */
import * as APITypes from '@/API'
import { GeneratedQuery } from '@aws-amplify/api-graphql'

export const getQuestionnaireObject = /* GraqhQL */ `
  query GetQuestionnaireObject($questionnaireId: ID!) {
    getQuestionnaire(id: $questionnaireId) {
      id
      createdAt
      companyId
      name
      status
      groups
      customContent
      packageId
      editDisabled
      company {
        name
        individuals {
          items {
            individualId
            individual {
              title
              preferredName
              familyName
              firstName
              role
              email
              teams {
                items {
                  companyId
                  individualId
                  teamId
                }
                nextToken
              }
            }
          }
          nextToken
        }
        committees {
          items {
            id
            name
            tags
          }
        }
      }
      sections {
        items {
          id
          variantCode
          questionnaireId
          companyId
          companyId
          createdAt
          name
          updatedAt
          slug
          displayOrder
          blocks {
            items {
              id
              variantCode
              sectionId
              companyId
              groupId
              variant
              data
              createdAt
              updatedAt
              displayOrder
            }
            nextToken
          }
        }
        nextToken
      }
      variants {
        name
        variantCode
      }
      updatedAt
    }
  }
` as GeneratedQuery<
  APITypes.GetQuestionnaireObjectQueryVariables,
  APITypes.GetQuestionnaireObjectQuery
>;
