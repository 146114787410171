<template>
  <div>
    <v-col class="mb-5">
      <v-row justify="center" align="center" align-content="center">
        <div style="position: relative">
          <v-avatar
            class="rounded-circle cursor-pointer mb-1"
            color="grey darken-1"
            size="140"
            @click="dialog = true"
          >
            <img
              v-if="savedImage"
              :placeholder="`Current ${title}`"
              :src="savedImage"
            />
            <file-viewer
              v-else-if="objectId && (companyId || companyIdOptional) && !fetchPreviewFailed"
              ref="filePreview"
              :object-id="objectId"
              :object-type="objectType"
              :company-id="companyId"
              :placeholder="$t('form.fileUpload.title', [title])"
              @failed="fetchPreviewFailed = true"
            />
            <div v-else class="text-center text-body-2 font-weight-bold text-uppercase px-10 white--text">
              <platform-icon
                name="upload"
                color="white"
                :size="70"
              />
              {{ title }}
            </div>
          </v-avatar>
          <platform-button
            style="position: absolute; bottom: 0; right: 0;"
            icon="edit"
            :loading="loading"
            rounded
            primary
            @click="dialog = true"
          />
        </div>
      </v-row>
    </v-col>

    <platform-modal
      v-model="dialog"
      :title="$t('form.fileUpload.title', [title])"
      :icon="icon || 'upload'"
      :width="600"
      @save="save"
      @close="dialog = false"
    >
      <v-row class="flex-column">
        <v-col class="grow d-flex pb-12 justify-center">
          <v-card class="shrink d-flex overflow-hidden">
            <div style="position: relative">
              <vue-avatar
                :image="imageSrc"
                :width="150"
                :height="150"
                :rotation="rotation"
                :scale="scale"
                :border-radius="borderRadius"
                :placeholder-svg="null"
                accept="image/jpeg"
                class="avatar-editor"
                ref="imageeditor"
                style="position: relative; z-index: 2;"
                @vue-avatar-editor:image-ready="onImageReady"
              />
              <div
                class="placeholder-text text-center text-body-2 font-weight-bold text-uppercase px-10 grey--text darken-1"
              >
                <platform-icon
                  name="upload"
                  color="grey darken-1"
                  :size="70"
                />
                {{ title }}
              </div>
              <platform-button
                style="position: absolute; bottom: 2px; right: 2px; z-index: 3;"
                icon="edit"
                primary
                text
                @click="openFilePicker"
              />
            </div>
          </v-card>
        </v-col>
        <v-col class="shrink">
          <v-row>
            <v-col :cols="12" :sm="2">
              {{ $t('form.fileUpload.scale') }}
            </v-col>
            <v-col :cols="12" :sm="10">
              <v-slider
                v-model="scale"
                hide-details
                :min="0.5"
                :max="3"
                :step="0.02"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col class="shrink">
          <v-row>
            <v-col :cols="12" :sm="2">
              {{ $t('form.fileUpload.rotation') }}
            </v-col>
            <v-col :cols="12" :sm="10">
              <v-slider
                v-model="rotation"
                hide-details
                :min="0"
                :max="360"
                :step="10"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </platform-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { VueAvatar } from 'vue-avatar-editor-improved'
import API from '@/services/API'
import axios from 'axios'
import { invalidateCachedImage, loadImage } from '@/helpers/forms'
import FileViewer from '@/components/FileViewer'
import PlatformModal from '@/components/PlatformModal'

export default Vue.extend({
  props: {
    value: String,
    obj: Object,
    title: String,
    icon: { type: String, required: false },
    companyId: String,
    objectType: String,
    objectId: String,
    loading: { type: Boolean, required: false }
  },
  components: {
    PlatformModal,
    FileViewer,
    VueAvatar
  },
  data () {
    return {
      dialog: false,
      rotation: 0,
      scale: 1,
      borderRadius: 100,
      uploading: false,
      imageSrc: undefined,
      savedImage: null,
      fetchPreviewFailed: false
    }
  },
  mounted () {
    this.fetchImage()
  },
  watch: {
    companyId () {
      this.checkPendingUpload()
    },
    objectId () {
      this.checkPendingUpload()
    }
  },
  computed: {
    companyIdOptional () {
      return this.objectType === 'platformUserAvatar'
    }
  },
  methods: {
    onImageReady () {
      this.scale = 1
      this.rotation = 0
    },
    async fetchImage () {
      if (this.objectId && this.objectType && (this.companyId || this.companyIdOptional)) {
        const src = await loadImage(this.objectType, this.objectId, this.companyId)
        if (src) {
          this.imageSrc = src
        }
      }
    },
    save () {
      this.savedImage = this.$refs.imageeditor.getImageScaled().toDataURL('image/jpeg')
      this.uploadImage()
    },
    checkPendingUpload () {
      this.fetchPreviewFailed = false
      if (this.savedImage) {
        this.uploadImage()
      }
    },
    async uploadImage () {
      if (this.uploading) {
        return
      }
      const needsCompanyId = this.companyIdOptional ? false : !this.companyId
      if (!this.objectId || needsCompanyId) {
        this.dialog = false
        this.$emit('pending')
        return
      }
      this.uploading = true
      const dataUri = this.savedImage

      const signedUrl = await API.put('backendfunctions', `/${this.companyId ?? 'null'}/files`, {
        body: {
          objectType: this.objectType,
          objectId: this.objectId
        }
      })

      const response = await axios.put(signedUrl.link, this.dataUriToBlob(dataUri), {
        headers: {
          'Content-Type': 'image/jpeg'
        }
      })
      this.uploading = false
      this.$emit('uploaded')

      if (response.status === 200) {
        invalidateCachedImage(this.objectType, this.objectId, this.companyId)
        this.$toasted.success(`${this.title} uploaded successfully`)
        this.dialog = false
      }
    },
    dataUriToBlob (dataUri) {
      const binary = atob(dataUri.split(',')[1])
      const parts = []
      for (let i = 0; i < binary.length; i++) {
        parts.push(binary.charCodeAt(i))
      }

      return new Blob([new Uint8Array(parts)], { type: 'image/jpeg' })
    },
    openFilePicker () {
      this.$refs.imageeditor?.clicked()
    }
  }
})
</script>

<style lang="scss">
.avatar-editor {
  canvas {
    display: block;
  }
}
.placeholder-text {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1;
}
</style>
