<template>
  <div class="platform-menu-button d-inline-block">
    <v-menu
      transition="slide-y-transition"
      bottom
      offset-y
      content-class="elevation-0"
      :close-on-content-click="closeOnClick"
    >
      <v-card color="transparent">
        <slot name="menu" />
      </v-card>
      <template v-slot:activator="{ on, attrs }">
        <v-item-group class="v-btn-toggle d-flex" :dark="primary" :class="{ 'border-primary': secondary }">
          <slot name="default" />
          <platform-button
            v-if="$slots.menu"
            :primary="primary"
            :secondary="secondary"
            compact
            icon="caret-down"
            v-bind="attrs"
            v-on="on"
          />
        </v-item-group>
      </template>
    </v-menu>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'PlatformMenuButton',
  props: {
    primary: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
    closeOnClick: { type: Boolean, default: false }
  }
})
</script>

<style lang="scss" scoped>
.platform-menu-button {
  ::v-deep .v-btn {
    opacity: 1;
  }
  ::v-deep .v-btn-toggle {
    &.border-primary {
      .v-btn {
        border-color: var(--v-primary-base) !important;
      }
    }
  }
}
</style>
