<template>
  <platform-modal
    icon="user-settings"
    :title="$t('modal.account.title')"
    :value="showModal"
    :width="800"
    :loading="pendingFileUpload"
    :header-padding="false"
    :save-disabled="!formValid"
    @close="closeModal"
    @save="submit"
  >
    <div v-if="showModal" class="account-modal">
      <v-tabs v-model="activeTab" class="mb-4">
        <v-tab
          v-for="tab in availableTabs"
          :key="tab.key"
          :href="`#${tab.key}`"
        >
          {{ tab.title }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab">
        <v-tab-item
          v-for="tab in availableTabs"
          :key="tab.key"
          :id="tab.key"
          :value="tab.key"
        >
          <component
            :is="tab.component"
            v-model="formData"
            @pendingFile="handlePendingFile"
            @uploadedFile="handleUploadedFile"
            @valid="(val) => formValid = val"
            @close="closeModal"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </platform-modal>
</template>

<script lang="ts">
import { PlatformUser } from '@betterboards/shared/types/Platform'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { trimStringFields } from '@/helpers/validation'
import { invalidateCachedImage } from '@/helpers/forms'
import PlatformModal from '@/components/PlatformModal.vue'
import EditAccountProfile from './tabs/EditAccountProfile.vue'
import EditAccountSecurity from './tabs/EditAccountSecurity.vue'

enum EditAccountTab {
  Profile = 'Profile',
  Security = 'Security',
}

export default Vue.extend({
  name: 'EditAccountModal',
  components: {
    PlatformModal,
    EditAccountProfile,
    EditAccountSecurity
  },
  props: {
    //
  },
  data () {
    return {
      showModal: true,
      activeTab: EditAccountTab.Profile,
      formData: {
        id: <string | null>null
      } as Partial<PlatformUser>,
      pendingFileUpload: false,
      formValid: false
    }
  },
  mounted () {
    this.formData = {
      ...this.user
    }
  },
  computed: {
    ...mapState('User', ['user']),
    ...mapState('Company', ['selectedCompany']),
    availableTabs (): Array<{ key: EditAccountTab, title: string, component: string }> {
      return [
        {
          key: EditAccountTab.Profile,
          title: this.$t('modal.account.profile.title') as string,
          component: 'EditAccountProfile'
        },
        {
          key: EditAccountTab.Security,
          title: this.$t('modal.account.security.title') as string,
          component: 'EditAccountSecurity'
        }
      ]
    }
  },
  methods: {
    ...mapActions('User', ['updateUser']),
    async handleInput (ev) {
      this.formData = ev.data
    },
    submit () {
      if (!this.formValid) {
        return
      }
      const profileData = trimStringFields(this.formData)
      this.updateUser(profileData)
        .then(() => {
          this.$toasted.success(this.$t('form.editProfile.successMessage') as string)
          this.closeModal()
        })
        .catch((err) => {
          console.error('Error updating Individual info', err)
          this.$toasted.error(this.$t('form.editProfile.errorMessage') as string)
        })
    },
    closeModal () {
      this.$emit('input', false)
    },
    handlePendingFile () {
      this.pendingFileUpload = false
      if (this.formData.id) {
        invalidateCachedImage('platformUserAvatar', this.formData.id)
      }
    },
    handleUploadedFile () {
      this.pendingFileUpload = false
      if (this.formData.id) {
        invalidateCachedImage('platformUserAvatar', this.formData.id)
      }
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.account-modal {
  min-height: 375px;
}
</style>
