<template>
  <v-progress-circular
    indeterminate
    class="d-block"
    :width="width"
    :size="size"
    :color="color"
  />
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'PlatformSpinner',
  props: {
    color: { type: String, default: 'grey' },
    size: { type: [String, Number], default: 30 },
    width: { type: Number, default: 3 }
  }
})
</script>
