<template>
  <v-tooltip
    :disabled="disabled || !content"
    :top="top"
    :right="right"
    :bottom="bottom"
    :left="left"
    :nudge-top="nudgeTop"
    :nudge-right="nudgeRight"
    :nudge-bottom="nudgeBottom"
    :nudge-left="nudgeLeft"
    :activator="activator"
  >
    <span>{{ content }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'PlatformTooltip',
  props: {
    activator: { required: true },
    content: { type: String, required: false },
    top: { type: Boolean, default: false },
    right: { type: Boolean, default: false },
    bottom: { type: Boolean, default: false },
    left: { type: Boolean, default: false },
    nudgeTop: { type: Number, required: false },
    nudgeRight: { type: Number, required: false },
    nudgeBottom: { type: Number, required: false },
    nudgeLeft: { type: Number, required: false },
    disabled: { type: Boolean, default: false }
  }
})
</script>
