import { CreateIndividualMutation, GetIndividualQuery, UpdateIndividualMutation } from '@/API'
import { listFullCompanyIndividuals } from '@/graphql/custom/listFullCompanyIndividuals'
import { ignoreParentCompanyError } from '@/helpers/company'
import { sortIndividuals } from '@/helpers/individual'
import { sortTeamIndividuals } from '@/helpers/team'
import { Module } from 'vuex'
import Vue from 'vue'
import API from '@/services/API'
import {
  createCompanyIndividual,
  createIndividual,
  deleteIndividual,
  updateIndividual as updateIndividualMutation
} from '@/graphql/mutations'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { getIndividual } from '@/graphql/queries'
import { PlatformCompanyIndividual, PlatformIndividual } from '@betterboards/shared/types/Individual'
import { PlatformTeamIndividual, TeamListItem } from '@betterboards/shared/types/Team'

interface IndividualState {
  list: PlatformIndividual[]
  selectedIndividual: PlatformIndividual | null
}

function sortTeamListItems (a: TeamListItem, b: TeamListItem): number {
  if (!a.teamId && !b.teamId) {
    return 0
  }
  if (!a.teamId) {
    return 1
  }
  if (!b.teamId) {
    return -1
  }
  return a.teamId.localeCompare(b.teamId)
}

const defaultState = (): IndividualState => ({
  list: [] as PlatformIndividual[],
  selectedIndividual: null as PlatformIndividual | null
})
const IndividualStore: Module<IndividualState, any> = {
  namespaced: true,
  state: defaultState(),
  getters: {
    individualsList (state: IndividualState): PlatformIndividual[] {
      const list: PlatformIndividual[] = state.list
      if (!list) {
        return []
      }
      return list
        .map((individual) => {
          if (individual.teams?.items?.length) {
            individual.teams.items.sort(sortTeamIndividuals)
          }
          return individual
        })
        .sort(sortIndividuals)
    },
    teamIndividualsList (state: IndividualState, getters): TeamListItem[] {
      const teams = getters.individualsList.reduce((teams: TeamListItem[], individual: PlatformIndividual) => {
        if (!individual.teams?.items.length) {
          let index = teams.findIndex((t) => t.teamId === undefined)
          if (index === -1) {
            const noTeam: TeamListItem = {
              teamId: undefined,
              individuals: []
            }
            index = teams.push(noTeam) - 1
          }
          teams[index].individuals.push(individual)
          return teams
        }
        individual.teams.items.forEach((teamIndividual: PlatformTeamIndividual) => {
          const teamId = teamIndividual.teamId
          let index = teams.findIndex((t) => t.teamId === teamId)
          if (index === -1) {
            const newTeam: TeamListItem = {
              teamId,
              individuals: []
            }
            index = teams.push(newTeam) - 1
          }
          teams[index].individuals.push(individual)
        })
        return teams
      }, [])
      return teams.sort(sortTeamListItems)
    }
  },
  mutations: {
    setList: (state: any, value: any[]) => {
      state.list = value
    },
    resetList: (state: any) => {
      state.list = []
    },
    addIndividual: (state: any, individual: PlatformIndividual) => {
      if (!individual.id) {
        return
      }
      const index = state.list.findIndex((i) => i.id === individual.id)
      if (index !== -1) {
        state.list.splice(index, 1, individual)
        return
      }
      state.list.push(individual)
    },
    updateIndividual: (state: any, individual: PlatformIndividual) => {
      const index = state.list.findIndex((item: PlatformIndividual) => item.id === individual.id)
      if (index !== -1) {
        return state.list.splice(index, 1, individual)
      }
    },
    removeIndividual: (state: any, individual: PlatformIndividual) => {
      const index = state.list.findIndex((item: PlatformIndividual) => item.id === individual.id)
      if (index !== -1) {
        return state.list.splice(index, 1)
      }
    },
    setSelectedIndividual: (state: any, individual: PlatformIndividual) => (state.selectedIndividual = individual)
  },
  actions: {
    async createIndividual ({ rootState }, individualData) {
      const companyId = rootState.Company.selectedCompany?.id
      let individualResponse
      try {
        individualResponse = await API.graphql({
          query: createIndividual,
          variables: {
            input: {
              ...individualData,
              companyId
            }
          }
        }) as GraphQLResult<CreateIndividualMutation>
      } catch (err) {
        if (!ignoreParentCompanyError(err)) {
          throw err
        }
        individualResponse = err
      }

      const individual: any = individualResponse.data?.createIndividual

      let ciResponse
      try {
        ciResponse = await API.graphql({
          query: createCompanyIndividual,
          variables: {
            input: {

              individualId: individual.id,
              companyId
            }
          }
        })
      } catch (err) {
        if (!ignoreParentCompanyError(err)) {
          throw err
        }
        ciResponse = err
      }
      individual.companyIndividual = ciResponse.data?.createCompanyIndividual
      return individual
    },
    async loadList ({
      commit,
      rootState
    }, fresh?: boolean) {
      if (!rootState.Company.selectedCompany) {
        commit('resetList')
        return
      }
      const response = await API.graphql({
        query: listFullCompanyIndividuals,
        variables: {
          companyId: rootState.Company.selectedCompany.id
        }
      })

      if (fresh) {
        commit('resetList')
      }
      const individuals: PlatformCompanyIndividual[] = response.data.individualsByCompanyId.items as PlatformCompanyIndividual[]
      individuals
        .filter((item: PlatformCompanyIndividual): item is PlatformCompanyIndividual => !!item.individual)
        .forEach(({ individual }: { individual: PlatformIndividual }) => {
          commit('addIndividual', individual)
        })
    },
    async deleteIndividual ({ commit, rootState }, individual: PlatformIndividual) {
      try {
        console.log('attempting to delete', individual)
        await API.graphql({
          query: deleteIndividual,
          variables: {
            input: {
              id: individual.id
            }
          }
        })
        commit('removeIndividual', individual)
      } catch (err) {
        console.error('error deleting individual', err)
        Vue.toasted.error('There was an error removing the individual')
        throw err
      }
    },
    async updateIndividual ({ rootState }, individual) {
      let updateIndividualResp: GraphQLResult<UpdateIndividualMutation>
      try {
        updateIndividualResp = await API.graphql({
          query: updateIndividualMutation,
          variables: {
            input: {
              ...individual,
              updatedAt: undefined,
              ownedCompanies: undefined,
              companies: undefined,
              committees: undefined,
              cognitoIdentity: undefined,
              cognitoId: undefined,
              teams: undefined
            }
          }
        }) as GraphQLResult<UpdateIndividualMutation>
      } catch (e) {
        console.error('Error updating individual:', e)
        Vue.toasted.error('There was an error (1) trying to update the individual, please try again later')
        return
      }
      const companyIndividualId = updateIndividualResp.data?.updateIndividual?.id
      if (!companyIndividualId) {
        console.error('No CompanyIndividual ID found in updateIndividual response. Response:', updateIndividualResp)
        Vue.toasted.error('There was an error (2) trying to update the individual, please try again later')
      }
    },
    async fetchIndividualById ({ commit }, individualId: string) {
      const response = await API.graphql({
        query: getIndividual,
        variables: {
          id: individualId
        }
      }) as GraphQLResult<{ getIndividual: GetIndividualQuery }>
      const individual = response.data?.getIndividual
      if (!individual) {
        console.warn('Failed to fetch individual, response:', response.data)
        return
      }
      commit('addIndividual', individual)
      return individual
    },
    async fetchIndividuals ({ dispatch }, profiles: any[]): Promise<any[]> {
      const promises = profiles.map(async (profile) => await dispatch('fetchIndividualById', profile.individualId))
      return await Promise.all(promises)
    }
    // async selectIndividual ({ commit, state, dispatch }, profiles: any[]) {
    //   profiles.forEach((profile) => await dispatch('fetchIndividualById', profile.id))
    //
    //   await dispatch('fetchIndividualIndividuals', freshIndividual)
    //   commit('setSelectedIndividual', freshIndividual)
    // }
  }
}

export default IndividualStore
