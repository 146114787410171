import * as d3 from 'd3'
import { drawDots } from '../d3/dots'
import { drawGraphLegend } from '../d3/legend'
import { getAxisTextScale, wrapAxisText, wrapNormalText } from '../d3/text'
import { getGraphColor } from '../helpers'
import { AnySelection, GroupSelection } from '../types/d3'
import { CategoryGraphDataPoint, DotTypes, FontSizes, LineConfig } from '../types/Graph'
import { AbstractGraph, GraphConfig, GraphInterface } from './Abstract'

/**
 * Returns whether the line for the provided property has datapoint labels enabled
 */
function isPropertyLineLabelled (property: string, lines: LineConfig[]): boolean {
  const line: LineConfig | undefined = lines.find(
    (l: LineConfig): boolean => l.property === property
  )
  return !!line?.dataLabels
}

/**
 * Returns the offset to be applied to the label for the datapoint `val` so as to not intersect with that of `otherVal`.
 */
function getDatapointsOffset (val: number, otherVal: number): number {
  const diff = otherVal - val
  if (!diff) {
    return 0
  }
  if (diff > 0 && diff <= 1) {
    return 25
  }
  return 0
}

const DefaultOffset = 30
/**
 * Calculates the x-offset that should be applied to a given datapoint based on
 *  other datapoints on the same row as well as the angle of the line connected
 *  to the datapoint for this property on the previous row, in order to try
 *  prevent overlap between the label and the other labels/the line.
 */
function getXOffset (value: number, angle: number, defaultPosition: 1 | 0 | -1, nextVal?: number, lastVal?: number): number {
  let xOffset = 0

  // Avoid overlap with datapoints on same row
  if (nextVal) {
    xOffset -= getDatapointsOffset(value, nextVal)
  }
  if (lastVal) {
    xOffset += getDatapointsOffset(lastVal, value)
  }

  // No offset
  if (xOffset === 0) {
    xOffset = defaultPosition * DefaultOffset
  }
  const angleMayConflict: boolean = (angle > 0 && xOffset > 0) || (angle < 0 && xOffset < 0)
  if (angle !== 0 && angleMayConflict) {
    const angleSize = Math.abs(angle)
    // Line going same way as label, move the label horizontally so it is under line
    if (angleSize > 3 * Math.PI / 7) {
      // Line too steep, just put the label on top else it would stray too far
    } else if (angleSize > 2 * Math.PI / 7) {
      xOffset *= 2.1
    } else if (angleSize > Math.PI / 3) {
      xOffset *= 1.9
    } else if (angleSize > Math.PI / 4) {
      xOffset *= 1.7
    } else if (angleSize > Math.PI / 5) {
      xOffset *= 1.2
    } else if (angleSize > Math.PI / 6) {
      xOffset *= 1.1
    } else if (angleSize > Math.PI / 7) {
      xOffset *= 1.0
    }
  }

  // Avoid edges
  if (value < 1.3) {
    if (value === 1) {
      return xOffset < 0
        ? xOffset * 0.1
        : xOffset * 1.1
    }

    if (xOffset < 0) {
      return xOffset * Math.abs(1 - value) * 4
    }
  }
  if (value > 4.7) {
    if (value === 5) {
      return xOffset > 0
        ? xOffset * 0.2
        : xOffset * 1.1
    }

    if (xOffset > 0) {
      return xOffset * Math.abs(value - 5) * 4
    }
  }

  return xOffset
}

export interface GraphVote {
  option: string
  value: number
}

interface LineGraphBaseDataPoint {
  type: string
  category: string // Y value, category or question
  label?: string // For future usage in tooltips etc.
  votes?: GraphVote[]
}
export interface LineGraphAppraisalDataPoint extends LineGraphBaseDataPoint {
  type: 'appraisal'
  self: number
  avg: number
  importance?: undefined
  development?: undefined
}
export interface LineGraphImportanceDataPoint extends LineGraphBaseDataPoint {
  type: 'importance'
  importance: number
  development: number
  avg?: undefined
  self?: undefined
}

export type LineGraphDataPoint = LineGraphAppraisalDataPoint | LineGraphImportanceDataPoint

export type LineGraphData = LineGraphDataPoint[]

// Keeping these internal to this file until we refactor them out to unify them with existing types
export interface LineGraphPropertyDatapoint {
  property: string
  value: number
}
export interface LineGraphRow {
  row: LineGraphDataPoint
  values: LineGraphPropertyDatapoint[]
}

export interface LineGraphConfig extends GraphConfig {
  width: number
  height: number // base height only
  xTicks: number
  yTicks: number
  votes: boolean
  votesConfig: {
    header: string
  }
  legend: boolean
  margin: {
    top: number
    left: number
    right: number
    bottom: number
  }
  axes: {
    x: {
      label: string
    }
    y: {
      label: string
    }
  }
  range: {
    min: {
      x: number
      y: number
    }
    max: {
      x: number
      y: number
    }
  }
  lines: LineConfig[]
  hideEmptyResults?: boolean // hide results with no avg or self values
}

export default class LineGraph extends AbstractGraph<LineGraphConfig> implements GraphInterface<LineGraphDataPoint[]> {
  public getDefaultConfig (): Partial<LineGraphConfig> {
    return {
      width: 900,
      height: 465,
      scale: 1,
      xTicks: 5,
      yTicks: 5,
      votes: false,
      votesConfig: {
        header: 'Top 3 Colleagues'
      },
      range: {
        min: {
          x: 1,
          y: 1
        },
        max: {
          x: 5,
          y: 5
        }
      },
      margin: {
        top: 32,
        left: 100,
        right: 50,
        bottom: 24
      },
      lines: [
        {
          property: 'development',
          color: 'primary',
          dot: DotTypes.Circle
        },
        {
          property: 'importance',
          color: 'secondary',
          dot: DotTypes.Rect
        }
      ],
      legend: false,
      hideEmptyResults: true
    }
  }

  private svg!: d3.Selection<SVGElement, any, any, any>

  render (data: LineGraphDataPoint[]): void {
    super.render(data)

    let graphData: LineGraphDataPoint[] = []
    const isAppraisalData: boolean = data?.some(
      (d: LineGraphDataPoint): boolean => d.type === 'appraisal'
    )
    if (isAppraisalData) {
      graphData = this.config.hideEmptyResults
        ? data.filter((d: LineGraphDataPoint): boolean => (!!d.avg || !!d.self))
        : data
    }

    const isImportanceData: boolean = data?.some(
      (d: LineGraphDataPoint): boolean => d.type === 'importance'
    )
    if (isImportanceData) {
      graphData = this.config.hideEmptyResults
        ? data.filter((d: LineGraphDataPoint): boolean => (!!d?.importance || !!d.development))
        : data
    }

    const xAxisWidth = this.config.width - this.config.margin.left - this.config.margin.right
    const yAxisHeight = this.config.height - this.config.margin.top - this.config.margin.bottom
    const YAxisPaddingOuter = graphData?.length
      ? (yAxisHeight / graphData.length) / 2
      : 0

    this.svg = d3
      .select(this.selector)

    // Clear current contents (in case this is a re-render)
    this.svg
      .selectAll('*')
      .remove()

    const dataGroup = this.svg.append('g')
      .attr('transform', `translate(0, ${YAxisPaddingOuter})`)

    // const xVals = data.map((r) => r.x)
    // const yVals = data.map((r) => r.y)

    const lines = this.config.lines.map((line: LineConfig) => {
      line.color = getGraphColor(line.color, this.config)
      return line
    })

    const categories: string[] = graphData
      ? graphData
        .filter((d) => d.category)
        .map((d) => d.category)
      : []
    const x = d3.scaleLinear()
      .domain([this.config.range.min.x, this.config.range.max.x])
      .range([this.config.margin.left, this.config.width - this.config.margin.right])
    const xAxisEl = this.svg.append('g')
      .attr('transform', `translate(0, ${this.config.margin.top})`)
      .call(
        d3
          .axisTop(x)
      )
    const YAXisPadddingRatio = 0.1
    const y = d3.scaleBand()
      .paddingOuter(YAXisPadddingRatio)
      .paddingInner(0)
      .domain(categories)
      .range([this.config.margin.top, this.config.height - this.config.margin.bottom])

    if (graphData?.length) {
      const gridColumns = 5
      const heightScaleRatio = 1 // @TODO: This
      const strokeDashArray = (4 * heightScaleRatio).toString() + ', ' + (3 * heightScaleRatio).toString()

      // X axis
      xAxisEl
        .select('.domain')
        .attr('stroke', this.config.colors.grey.dark)
      xAxisEl
        .selectAll('.tick line')
        .attr('stroke', this.config.colors.grey.dark)
      xAxisEl
        .selectAll('.tick')
        .each((field: any, index, nodes) => {
          const el = d3.select(nodes[index])
          // Remove half ticks
          if (field % 1 === 0.5) {
            el.remove()
            return
          }
          el
            .select('text')
            .attr('font-size', this.config.scale * FontSizes.AxisLabel * 0.8)
            .attr('fill', this.config.colors.grey.dark)
        })

      // Y axis
      const yAxisEl = this.svg.append('g')
        .lower()
        .attr('id', 'y-axis')
        .attr('transform', `translate(${this.config.margin.left}, 0)`)
        .call(
          d3.axisLeft(y)
            .tickSize(5)
        )
      yAxisEl
        .select('.domain')
        .attr('stroke', this.config.colors.grey.dark)
      yAxisEl
        .selectAll('.tick line')
        .attr('stroke', this.config.colors.grey.dark)

      // Draw vertical lines for grid below axes
      this.drawVerticalGrid(yAxisHeight, gridColumns * 2 - 1, this.config.colors.grey.lightest, strokeDashArray)
      this.drawVerticalGrid(yAxisHeight, gridColumns, this.config.colors.grey.lighter, strokeDashArray)

      // Format Y-axis labels
      const shrinkLabels = graphData.some((q) => q.category?.split(' ').some((word) => word?.length > 12))
      const axisCharacterCount = graphData.reduce((total, question) => total + (question?.category?.length ?? 0), 0)
      const axisTextScale: number = getAxisTextScale(axisCharacterCount)
      const axisLabelFontSize = this.config.scale * (FontSizes.AxisLabel as number) * axisTextScale * (shrinkLabels ? 0.8 : 1)
      const yAxisLabelPadding = 26
      const yAxisWidth = this.config.margin.left - yAxisLabelPadding - 20
      yAxisEl
        .selectAll('.tick text')
        .attr('fill', '#000000')
        .attr('font-size', `${axisLabelFontSize}px`)
        .attr('class', 'axis-text')

      // Build a horizontal grid based off of Y-axis ticks
      yAxisEl
        .selectAll('.tick')
        .append('line')
        .lower()
        .attr('stroke', this.config.colors.grey.lighter)
        .attr('stroke-dasharray', strokeDashArray)
        .attr('x2', xAxisWidth)
      wrapAxisText(yAxisEl, {
        width: yAxisWidth,
        fontSize: axisLabelFontSize,
        padding: yAxisLabelPadding,
        verticalAlign: 'center'
      }, '.tick .axis-text')

      const rightAxisLabelFontSize = Math.ceil(
        this.config.scale * (FontSizes.Votes as number) * (graphData.length > 10 ? 11 / graphData.length : 1)
      )
      // Add the right side axis if the data is set on the result
      if (this.config.votes) {
        const itemContainers = yAxisEl
          .selectAll('.tick')
          .append('text')
          .attr('class', 'votes')
          .attr('text-anchor', 'start')
          .attr('transform', `translate(${xAxisWidth + 8}, 0)`)
          .attr('fill', this.config.colors.primary)
          .attr('font-size', `${rightAxisLabelFontSize}px`)
          .selectAll('tspan')
          .data((d, i: number) => graphData[i].votes as GraphVote[])
          .join('tspan')
          .attr('class', 'item')
          .attr('text-anchor', 'start')
        itemContainers
          .append('tspan')
          .text((d) => d.option)
          .attr('font-weight', 700)
        itemContainers
          .append('tspan')
          .text((d) => ` (${d.value})`)

        const rightAxisWidth = this.config.margin.right - yAxisLabelPadding
        wrapAxisText(yAxisEl, {
          width: rightAxisWidth - yAxisLabelPadding,
          fontSize: rightAxisLabelFontSize,
          padding: yAxisLabelPadding,
          alignLeft: true,
          verticalAlign: 'center'
        }, '.tick .votes')

        // Header
        const headerFontSize = this.config.scale * FontSizes.AxisLabel
        const headerEl = yAxisEl
          .append('text') as AnySelection
        headerEl
          .attr('class', 'votes-header')
          .attr('transform', `translate(${xAxisWidth}, ${16})`)
          .attr('alignment-baseline', 'top')
          .attr('text-anchor', 'start')
          .attr('x', 0)
          .attr('y', 0)
          .text(`${this.config.votesConfig.header}*`)
          .attr('fill', this.config.colors.primary)
          .attr('font-weight', 700)
          .attr('font-size', headerFontSize)

        wrapNormalText(headerEl, {
          width: rightAxisWidth,
          fontSize: headerFontSize,
          padding: yAxisLabelPadding,
          alignLeft: true,
          lineHeight: headerFontSize + 1
        })
      }

      const linesDataMap: { [property: string]: { line: LineConfig, data: CategoryGraphDataPoint[] }} = {}
      const rows: LineGraphRow[] = graphData
        .map((row: LineGraphDataPoint) => {
          const lineValues = {} as any
          lines.forEach((line: LineConfig) => {
            const value = row[line.property as keyof LineGraphDataPoint]
            if (!value) {
              return
            }
            lineValues[line.property] = value
          })
          return {
            row,
            values: Object.keys(lineValues)
              .sort((a, b) => lineValues[a] - lineValues[b])
              .map((property) => ({ property, value: lineValues[property] }))
          }
        })
      const propertyPositionTotals = rows
        .reduce((totals: { [key: string]: number }, row) => {
          row.values.forEach(({ property, value }: { property: string, value: number }) => {
            if (!totals[property]) {
              totals[property] = 0
            }
            totals[property] += value
          })
          return totals
        }, {})
      const propertyAveragePositions = Object.keys(propertyPositionTotals).reduce((averages: any, property) => {
        averages[property] = propertyPositionTotals[property] / rows.length
        return averages
      }, {})
      const lineDefaultLabelPositions: any = {}
      const dataLabelProperties: string[] = Object.keys(propertyAveragePositions).filter((p: string) => isPropertyLineLabelled(p, lines))
      dataLabelProperties.forEach((property: string, index: number): void => {
        if (dataLabelProperties.every((p) => propertyAveragePositions[p] === propertyAveragePositions[property])) {
          lineDefaultLabelPositions[property] = index < dataLabelProperties.length / 2 ? 'left' : 'right'
          return
        }
        if (dataLabelProperties.some((p) => propertyAveragePositions[p] > propertyAveragePositions[property])) {
          lineDefaultLabelPositions[property] = 'left'
          return
        }
        lineDefaultLabelPositions[property] = 'right'
      })

      rows.forEach((rowData, rowIndex) => {
        const labelledRowDatapoints: LineGraphPropertyDatapoint[] = rowData.values.filter(
          (r: LineGraphPropertyDatapoint): boolean => isPropertyLineLabelled(r.property, lines)
        )
        const unlabelledRowDatapoints: LineGraphPropertyDatapoint[] = rowData.values.filter(
          (r: LineGraphPropertyDatapoint): boolean => !isPropertyLineLabelled(r.property, lines)
        )
        labelledRowDatapoints
          // each dataset/line
          .forEach(({ property, value }: LineGraphPropertyDatapoint, propertyIndex) => {
            if (!linesDataMap[property]) {
              const line = lines.find((l) => l.property === property)
              if (!line) {
                throw new Error(`Missing config for line with property "${property}"`)
              }
              linesDataMap[property] = {
                line,
                data: []
              }
            }
            const row = rowData.row
            // Check for collisions on this row
            const nextProperty = labelledRowDatapoints[propertyIndex + 1]
            const lastProperty = labelledRowDatapoints[propertyIndex - 1]
            const nextVal: number | undefined = nextProperty && isPropertyLineLabelled(nextProperty.property, lines)
              ? nextProperty?.value
              : undefined
            const lastVal: number | undefined = lastProperty && isPropertyLineLabelled(lastProperty.property, lines)
              ? lastProperty?.value
              : undefined
            const defaultLabelPosition = lineDefaultLabelPositions[property as any]
            const defaultAlignment = defaultLabelPosition ? (defaultLabelPosition === 'right' ? 1 : -1) : 0
            let xOffset = 0
            // Check for collisions on previous row
            const prevRow = rows[rowIndex - 1]
            if (prevRow) {
              prevRow.values.forEach((prevRowValues) => {
                if (prevRowValues.property !== property) {
                  // @TODO: Handle me
                  return
                }
                if (value && prevRowValues.value) {
                  const xVal = x(value)
                  const yVal = y(row.category)
                  const lastXVal = x(prevRowValues.value)
                  const lastYVal = y(prevRow.row.category)
                  if (!yVal || !xVal || !lastXVal || !lastYVal) {
                    console.warn('Row missing some data?', { xVal, yVal, lastXVal, lastYVal })
                    return
                  }
                  const xDiff = lastXVal - xVal
                  const yDiff = lastYVal - yVal
                  if (!yDiff) {
                    return
                  }
                  const angle = Math.atan2(yDiff, xDiff) + Math.PI / 2 // +/- angle between points from Y axis
                  // debugGroup.append('path')
                  //   .attr('d', `m ${xVal} ${yVal} l ${xDiff * Math.cos(angle === 0 ? angle : Math.PI / 4 - Math.abs(angle))} ${yDiff}`)
                  //   .attr('class', 'trig-tests')
                  //   .attr('stroke', 'pink')
                  //   .attr('stroke-width', 1.5)
                  //   .raise()
                  const diff = getXOffset(
                    value,
                    angle,
                    defaultAlignment,
                    nextVal,
                    lastVal
                  )
                  xOffset += diff
                }
              })
            } else {
              const diff = getXOffset(
                value,
                0,
                defaultAlignment,
                nextVal,
                lastVal
              )
              xOffset += diff
            }
            linesDataMap[property].data.push({
              ...row,
              x: value,
              xOffset
            })
          })
        unlabelledRowDatapoints.forEach(({ property, value }: LineGraphPropertyDatapoint) => {
          if (!linesDataMap[property]) {
            const line = lines.find((l) => l.property === property)
            if (!line) {
              throw new Error(`Missing config for line with property "${property}"`)
            }
            linesDataMap[property] = {
              line,
              data: []
            }
          }
          const row = rowData.row
          linesDataMap[property].data.push({
            ...row,
            x: value,
            xOffset: 0
          })
        })
      })

      this.config.lines.map((l) => l.property).forEach((property: string) => {
        if (!linesDataMap[property]) {
          return
        }
        const { line, data } = linesDataMap[property]

        this.drawLine(data, dataGroup, line, x, y)
      })
    }

    const alternateStyleCheck = graphData?.length > 1
    if (this.config.legend) {
      drawGraphLegend(this.config, lines, this.svg, x, y, alternateStyleCheck)
    }
  }

  private drawVerticalGrid (yAxisHeight: number, verticalTickCount: number, color: string, strokeDashArray: string): void {
    // Grid
    const verticalGrid = d3.scaleLinear()
      .domain([this.config.range.min.x, this.config.range.max.x])
      .range([this.config.margin.left, this.config.width - this.config.margin.right])
    const vGrid = this.svg.append('g')
      .lower()
      .attr('transform', `translate(0, ${this.config.margin.top})`)
      .call(
        d3
          .axisBottom(verticalGrid)
          .ticks(verticalTickCount)
          .tickSize(yAxisHeight)
      )
    vGrid
      .selectAll('.tick line')
      .style('stroke', color)
      .attr('stroke-dasharray', strokeDashArray)
    vGrid
      .selectAll('.tick text')
      .remove()
    vGrid
      .select('.domain')
      .remove()
  }

  private drawLine (data: CategoryGraphDataPoint[], group: GroupSelection, lineConfig: LineConfig, xAxis: d3.ScaleLinear<any, any>, yAxis: d3.ScaleBand<string>): void {
    const strokeWidth = lineConfig.dot === DotTypes.Dash
      ? 2.5
      : 3.5
    const lines = group.append('path')
      .lower()
      .datum(data)
      .style('opacity', 0.9)
      .attr('fill', 'none')
      .attr('stroke', lineConfig.color)
      .attr('stroke-width', strokeWidth)
      .attr('shape-rendering', 'geometricPrecision')
      .attr('d',
        d3.line<CategoryGraphDataPoint>()
          .x((d) => d.xVal ?? (d.x ? xAxis(d.x) : 0))
          .y((d) => d.yVal ?? yAxis(d.category as string) as number)
      )

    if (data.length > 1 && (lineConfig.dot === DotTypes.Dash || lineConfig.dot === DotTypes.Dotted)) {
      const dashArray = lineConfig.dot === DotTypes.Dotted ? '1, 6' : '6, 6'
      const lineCap = lineConfig.dot === DotTypes.Dotted ? 'round' : 'square'
      lines
        .attr('stroke-dasharray', dashArray)
        .attr('stroke-linecap', lineCap)
        .attr('stroke-linejoin', 'round')
    } else {
      drawDots(this.config, data, group, lineConfig, xAxis, yAxis)
    }
  }
}
