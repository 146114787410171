<template>
  <platform-infinite-scroll
    hide-spinner
    :fetching="loading"
    :fetched-all="reachedEndOfList"
    :activate-distance="200"
    @fetch="fetchNextPage"
  >
    <v-container>
      <create-user-modal
        v-if="creatingUser"
        :manageable-companies="manageableCompanies"
        @close="creatingUser = false"
        @created="userCreated"
      />
      <edit-user-modal
        v-if="editingCompanyUser"
        :content="editingCompanyUser"
        :manageable-companies="manageableCompanies"
        @close="editingCompanyUser = false"
        @updated="userUpdated"
      />
      <v-row :class="{ 'flex-column-reverse': $vuetify.breakpoint.mdAndDown }">
        <v-col :cols="12" :lg="8">
          <v-row v-for="(company, index) in companies" :key="`${company.id}-${index}`" no-gutters>
            <v-col>
              <platform-entity-card :entity="company" entity-type="company" align="start" hide-sub-title>
                <v-row class="pt-2" no-gutters justify="start">
                  <v-col
                    v-for="companyUser in company.users"
                    :key="companyUser.id"
                    class="shrink d-flex flex-column justify-start px-3"
                    :style="{ flexBasis: '180px' }"
                  >
                    <platform-entity-card
                      hover
                      flat
                      bubble-edit
                      placeholder-size="xl"
                      align="center"
                      class="text-body-2"
                      entity-type="user"
                      :inner-margin="3"
                      :entity="companyUser.user"
                      :avatar-size="48"
                      :inline-header="false"
                      @edit="editingCompanyUser = companyUser"
                      @click="editingCompanyUser = companyUser"
                    >
                      <span class="grey--text text--darken-1" style="font-size: 0.85em">{{ $t(`global.accountTypes.${companyUser.accountType}`) }}</span>
                    </platform-entity-card>
                  </v-col>
                </v-row>
              </platform-entity-card>
            </v-col>
          </v-row>
          <v-row justify="center" class="py-6" :style="{ visibility: loading ? undefined : 'hidden' }">
            <platform-spinner />
          </v-row>
        </v-col>
        <v-col :cols="12" :lg="4">
          <v-card>
            <v-card-title>{{ $t('page.admins.list.header') }}</v-card-title>
            <v-card-subtitle>{{ $t('page.admins.list.description') }}</v-card-subtitle>
            <v-card-actions>
              <platform-button
                primary
                @click="creatingUser = true"
              >
                {{ $t('page.admins.list.createAdminAction') }}
              </platform-button>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </platform-infinite-scroll>
</template>

<script lang="ts">
import PlatformInfiniteScroll from '@/components/shared/PlatformInfiniteScroll.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { sortByCreatedAt } from '@/helpers/company'
import Vue from 'vue'
import { mapState } from 'vuex'
import API from '@/services/API'
import { companyUserByCompanyId } from '@/graphql/queries'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import CreateUserModal from '@/components/user/CreateUserModal.vue'
import EditUserModal from '@/components/user/EditUserModal.vue'
import { AccountType, CognitoIdentity } from '@/API'

const PageSize = 5
const AccountTypeIndexes: AccountType[] = Object.values(AccountType)

export default Vue.extend({
  components: {
    PlatformInfiniteScroll,
    PlatformSpinner,
    CreateUserModal,
    PlatformEntityCard,
    EditUserModal
  },
  data () {
    return {
      list: [] as CognitoIdentity[],
      companies: <any[]>[],
      editingCompanyUser: false as CognitoIdentity | false,
      creatingUser: false as CognitoIdentity | false,
      loading: false,
      nextCompanyIndex: 0,
      scrollHandler: <any>null
    }
  },
  mounted () {
    void this.fetchNextPage()
  },
  computed: {
    ...mapState('Company', ['selectedCompany', 'companyAccessList']),
    manageableCompanies (): Array<{ id: string, name?: string, accountType: 'Admin' }> {
      return this.companyAccessList
        .filter((item) => item.accountType === 'Admin' && item.id && item.name)
    },
    allCompanies (): any[] {
      return [...this.$store.state.Company.list]
        .filter((c) => !c.deletedAt)
        .sort(sortByCreatedAt)
    },
    reachedEndOfList (): boolean {
      return this.nextCompanyIndex >= this.allCompanies.length
    }
  },
  methods: {
    editUser (user): void {
      this.editingCompanyUser = user
    },
    userCreated (user): void {
      console.info('created user:', user)
      this.$toasted.success('New user created successfully!')
      this.reset()
    },
    userUpdated (user: CognitoIdentity): void {
      console.log('updated user', user)
      this.reset()
    },
    async fetchNextPage (): Promise<void> {
      if (this.reachedEndOfList || this.loading) {
        return
      }
      const companies: any[] = []
      this.loading = true
      try {
        const nextCompanies = this.allCompanies.slice(this.nextCompanyIndex, this.nextCompanyIndex + PageSize)
        const promises = nextCompanies.map(async (company) => {
          const result = await API.graphql({
            query: companyUserByCompanyId,
            variables: {
              companyId: company.id
            }
          })
          const companyUsers = result.data?.companyUserByCompanyId?.items
          companies.push({
            ...company,
            users: companyUsers
              .sort((a, b) => {
                if (!a.accountType) {
                  return -1
                }
                if (!b.accountType) {
                  return 1
                }
                const indexA = AccountTypeIndexes.indexOf(a.accountType)
                const indexB = AccountTypeIndexes.indexOf(b.accountType)
                if (indexA === -1) {
                  return -1
                }
                if (indexB === -1) {
                  return 1
                }
                return indexA - indexB
              })
          })
        })
        try {
          await Promise.all(promises)
        } finally {
          this.nextCompanyIndex += PageSize
          companies
            .sort(sortByCreatedAt)
            .forEach((c) => {
              if (c) {
                this.companies.push(c)
              }
            })
        }
      } finally {
        this.loading = false
      }
    },
    reset (): void {
      this.nextCompanyIndex = 0
      this.companies.splice(0, this.companies.length)
      void this.fetchNextPage()
    }
  }
})
</script>
