<template>
  <v-row no-gutters align="center" class="flex-md-nowrap py-2">
    <v-col class="pr-3">
      <v-select
        :items="availableUniverses"
        :disabled="pendingFetch"
        :loading="pendingFetch"
        hide-details
        v-model="collection"
        class="mt-0"
        :label="$t('universe.universeRow.criteriaLabel')"
      />
    </v-col>
    <v-col class="pr-3">
      <v-select
        :items="availableCriteria"
        :disabled="pendingFetch || (collection && collection.length <= 0)"
        :loading="pendingFetch"
        hide-details
        v-model="category"
        class="mt-0"
        :label="$t('universe.universeRow.categoryLabel')"
      />
    </v-col>
    <v-col class="shrink">
      <div class="pt-4">
        <platform-icon
          name="delete"
          color="warning"
          v-tooltip="$t('universe.universeRow.removeCriteriaTooltip')"
          @click="$emit('delete')"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import API from '@/services/API'
import { UniverseQuery } from '@betterboards/shared/types/Report'

export default Vue.extend({
  name: 'UniverseQueryRow',
  props: {
    value: { type: Object as PropType<UniverseQuery>, required: true },
    v2: { type: Boolean, required: false }
  },
  data () {
    return {
      availableOptions: [] as Array<{ collection: string, collectionName: string, category: string, name: string, country?: string }>,
      pendingFetch: false
    }
  },
  mounted () {
    this.init()
  },
  computed: {
    collection: {
      set (collection: string): void {
        const collectionName = this.availableUniverses.find((c) => c.value === collection)?.text
        this.$emit('input', {
          ...this.value,
          collection,
          collectionName,
          category: null,
          categoryName: null
        })
      },
      get (): string {
        return this.value.collection
      }
    },
    category: {
      set (category: string): void {
        const categoryName = this.availableCriteria.find((c) => c.value === category)?.text
        this.$emit('input', {
          ...this.value,
          category,
          categoryName
        })
      },
      get (): string {
        return this.value.category
      }
    },
    availableUniverses (): { value: string, text: string }[] {
      if (this.availableOptions.length <= 0) {
        return []
      }
      // item.collectionName
      return this.availableOptions
        .filter((item) => item.collection && item.collectionName)
        .map((item) => ({ value: item.collection, text: this.$t(`universe.collections.${item.collection}`) as string }))
    },
    availableCriteria (): { value: string, text: string }[] {
      if (!this.collection) {
        return []
      }

      return this.availableOptions
        .filter((item) => item.collection === this.collection && item.category && item.name)
        .map((item) => ({
          value: item.category,
          text: item.collection === 'sector'
            ? this.$t(`universe.sectorOptions.${item.category}`) as string
            : item.name
        }))
    }
  },
  methods: {
    init (): void {
      this.pendingFetch = true
      const path: string = this.v2
        ? '/stats/options/v2'
        : '/stats/options'
      const method: 'get' | 'post' = this.v2
        ? 'post'
        : 'get'
      const params: any | undefined = this.v2
        ? {
            body: {
              queries: [] // @TODO: Implement passing queries here (and using them on the backend)
            }
          }
        : undefined
      API[method]<any[] | any>('backendfunctions', path, params)
        .then((val: any[] | any) => {
          this.availableOptions = this.v2
            ? val.results
            : val
        })
        .finally(() => {
          this.pendingFetch = false
        })
    }
  }
})
</script>
