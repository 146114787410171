import { ReportBenchmark, V1UniverseReportBenchmark, V2UniverseReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import { ScoreCollection } from '@betterboards/shared/helpers/entities/Result/scores'
import { getReportBenchmarkDisplayInfo } from '@betterboards/shared/helpers/statistics/getReportBenchmarkDisplayInfo'
import { isV1UniverseReportBenchmark, isV2UniverseReportBenchmark } from '@betterboards/shared/helpers/statistics/ReportBenchmark'

export default function buildUniverseBenchmarkOptions (
  existingBenchmarks: ReportBenchmark[],
  allowMultipleUniverseBenchmarks: boolean,
  showV2UniverseOption: boolean
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  availableBenchmarks.push(...buildV1UniverseBenchmarkOptions(existingBenchmarks, allowMultipleUniverseBenchmarks))

  if (showV2UniverseOption) {
    availableBenchmarks.push(...buildV2UniverseBenchmarkOptions(existingBenchmarks, allowMultipleUniverseBenchmarks))
  }

  return availableBenchmarks
}

export function buildV1UniverseBenchmarkOptions (
  existingBenchmarks: ReportBenchmark[],
  allowMultipleUniverseBenchmarks: boolean
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  const existingV1UniverseScores: V1UniverseReportBenchmark[] = existingBenchmarks.filter(isV1UniverseReportBenchmark)
  existingV1UniverseScores.forEach((s: V1UniverseReportBenchmark): void => {
    // Include card for each existing v21Universe Score
    const benchmarkDisplayInfo: ReportBenchmark | undefined = getReportBenchmarkDisplayInfo(s)
    if (benchmarkDisplayInfo) {
      availableBenchmarks.push(benchmarkDisplayInfo)
    }
  })

  if (!existingV1UniverseScores.length || allowMultipleUniverseBenchmarks) {
    const addNewV1UniverseScore: ReportBenchmark | undefined = getReportBenchmarkDisplayInfo({
      collection: ScoreCollection.UniverseAverage,
      version: '1',
      enabled: false,
      scores: []
    })
    if (addNewV1UniverseScore) {
      availableBenchmarks.push(addNewV1UniverseScore)
    }
  }

  return availableBenchmarks
}

export function buildV2UniverseBenchmarkOptions (
  existingBenchmarks: ReportBenchmark[],
  allowMultipleUniverseBenchmarks: boolean
): ReportBenchmark[] {
  const availableBenchmarks: ReportBenchmark[] = []

  const existingV2UniverseScores: V2UniverseReportBenchmark[] = existingBenchmarks.filter(isV2UniverseReportBenchmark)
  if (existingV2UniverseScores.length) {
    existingV2UniverseScores.forEach((s: V2UniverseReportBenchmark): void => {
      // Include card for each existing v2 Universe Score
      const benchmarkDisplayInfo: ReportBenchmark | undefined = getReportBenchmarkDisplayInfo(
        {
          enabled: true,
          ...s,
          scores: s.scores ?? [],
          queries: s.queries ?? []
        }
      )
      if (benchmarkDisplayInfo) {
        availableBenchmarks.push(benchmarkDisplayInfo)
      }
    })
  }

  if (!existingV2UniverseScores.length || allowMultipleUniverseBenchmarks) {
    // Include card to add a new v2 Universe Score
    const addNewV2UniverseBenchmark: ReportBenchmark | undefined = getReportBenchmarkDisplayInfo({
      collection: ScoreCollection.UniverseAverage,
      version: '2',
      enabled: false,
      scores: []
    })
    if (addNewV2UniverseBenchmark) {
      availableBenchmarks.push(addNewV2UniverseBenchmark)
    }
  }

  return availableBenchmarks
}
