import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const listFullReportGroupsBySurveyGroup = /* GraphQL */ `
  query ListFullReportGroupsBySurveyGroup(
    $surveyGroupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportGroupsBySurveyGroup(
      surveyGroupId: $surveyGroupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        surveyGroupId
        companyId
        name
        variants {
          name
          variantCode
        }
        configuration {
          hideComparisonResponses
          combineComparisonResponses
          reportDocumentEstimates {
            generateCollectiveDuration
            generateGenericDuration
            generateIndividualDuration
            downloadIndividualsArchiveDuration
          }
          scores {
              showV2Universe
              allowMultipleUniverse
          }
        }
        createdAt
        updatedAt
        deletedAt
        scores
        reports {
          items {
            id
            companyId
            surveyGroupId
            variantCode
            comparisonSurveyGroupId
          }
          nextToken
        }
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.ListFullReportGroupsBySurveyGroupQueryVariables,
  APITypes.ListFullReportGroupsBySurveyGroupQuery
>
