import { render, staticRenderFns } from "./ReportGroupSelector.vue?vue&type=template&id=f1510850&scoped=true"
import script from "./ReportGroupSelector.vue?vue&type=script&lang=ts"
export * from "./ReportGroupSelector.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1510850",
  null
  
)

export default component.exports