import { BlockType } from '@/API'
import { generateSelectItems } from '@/helpers/forms'

export { BlockType as BlockTypes }

export enum BlockFriendlyNames {
  Question = 'Question',
  Markdown = 'Information'
}

export const BlockTypeItems = generateSelectItems(BlockType, BlockFriendlyNames)

/**
 * Fields within the Block `data` object that are not synced across variants of the Block.
 */
export const EditableBlockVariantDataFields = [
  'questionText',
  'questionIntro',
  'questionTextSelfEvaluation',
  'questionIntroSelfEvaluation',
  'groups'
]
