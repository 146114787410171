import { LanguageCode } from '../../../types/API'
import { QuestionnaireVariantCode } from '../../../types/Platform'

export const DefaultTemplateValue = 'default'
export const DefaultQuestionnaireVariantName = 'Default'
export const DefaultQuestionnaireVariant: QuestionnaireVariantCode = LanguageCode.en

export const NullVariantCodeValue = 'null'

export enum BlockPresetResponse {
  Skipped = '__SKIPPED__',
  Uncertain = '__UNCERTAIN__'
}
