import { ResultSection, SectionResults } from '../../../types/ResultSet'

export function findComparisonResultSection <S extends SectionResults = ResultSection> (
  comparisonResultSections?: S[],
  section?: S
): S | undefined {
  return comparisonResultSections?.find((s: S) => {
    if (s.masterSectionId && section?.masterSectionId) {
      return s.masterSectionId === section.masterSectionId
    }
    return section && s.id === section.id
  })
}
