<template>
  <platform-modal
    :title="modalTitleText"
    icon="universe"
    :value="showModal"
    :width="800"
    @close="showModal = false"
  >
    <div class="mb-3">
      <span>
        {{ $t('analysis.universeSelectorModal.instruction') }}
      </span>
    </div>
    <universe-selector
      v-if="showModal"
      v-model="formData.queries"
      ref="universeSelector"
      :v2="v2"
      @valid="(val) => isValid = val"
      @queried="(scores) => results = scores"
    />
    <template slot="actions">
      <platform-button
        primary
        :icon="primaryAction === ButtonActions.Save ? 'text-box-check' : 'preview-globe'"
        :disabled="!isValid"
        :loading="loading"
        @click="handlePrimaryAction"
      >
      {{ primaryActionText }}
      </platform-button>
    </template>
  </platform-modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import PlatformModal from '@/components/PlatformModal.vue'
import UniverseSelector from '@/components/analysis/universe/UniverseSelector.vue'
import { UniverseQuery } from '@betterboards/shared/types/Report'

// TODO: Convert to translations
enum ButtonActions {
  Preview = 'Preview',
  Save = 'Save Results',
}

export default Vue.extend({
  name: 'UniverseSelectorModal',
  props: {
    value: { type: Boolean, required: true },
    queries: { type: Array as PropType<UniverseQuery[]>, required: false },
    loading: { type: Boolean, required: false },
    v2: { type: Boolean, default: false }
  },
  components: {
    UniverseSelector,
    PlatformModal
  },
  data: () => ({
    isValid: true,
    results: <any[] | undefined>undefined,
    formData: {
      queries: <UniverseQuery[]>[]
    },
    ButtonActions
  }),
  watch: {
    showModal (val: boolean): void {
      if (val) {
        this.isValid = true
      }
    },
    queries: 'init'
  },
  mounted (): void {
    this.init()
  },
  computed: {
    showModal: {
      set (val: any) {
        this.$emit('input', val)
      },
      get (): any {
        return this.value
      }
    },
    primaryAction (): ButtonActions {
      if (this.results) {
        return ButtonActions.Save
      }
      return ButtonActions.Preview
    },
    emptyResults (): boolean {
      return !this.results?.length
    },
    modalTitleText (): string {
      return this.formData.queries.length
        ? this.$t('analysis.universeSelectorModal.updateUniverseTitle') as string
        : this.$t('analysis.universeSelectorModal.selectUniverseTitle') as string
    },
    primaryActionText (): string {
      if (this.primaryAction === ButtonActions.Save) {
        if (this.v2) {
          return this.$t('analysis.universeSelectorModal.confirmAction') as string
        }
        return this.$t('analysis.universeSelectorModal.saveButton') as string
      }
      return this.$t('analysis.universeSelectorModal.previewButton') as string
    }
  },
  methods: {
    init (): void {
      this.formData.queries.splice(
        0,
        this.formData.queries.length,
        ...this.queries
      )
    },
    handlePrimaryAction (): void {
      const universeComponent: any = this.$refs.universeSelector
      if (this.primaryAction === ButtonActions.Save) {
        this.$emit(
          'update',
          {
            scores: this.results,
            queries: this.formData.queries
          }
        )
        return
      }
      universeComponent.runQuery()
    }
  }
})
</script>
