import { get, post, put, del } from 'aws-amplify/api'
import { DeleteOperation, RestApiOptionsBase, RestApiResponse } from '@aws-amplify/api-rest/src/types'
import { GraphQLVariablesV6, GraphQLOptionsV6, GraphQLResponseV6 } from '@aws-amplify/api-graphql'
import { ModelIDKeyConditionInput } from '@betterboards/shared/types/API'
import { generateClient } from '@aws-amplify/api'

interface RestApiOptions extends RestApiOptionsBase {
  body: RestAPIRequestBody
}

type RestAPIRequestBody = Record<string, any>
type RestAPIResponseBody = Record<string, any>

interface GraphQLGenericVariables {
  companyId?: string | ModelIDKeyConditionInput
  // input: {
  //   createdAt?: undefined
  //   updatedAt?: undefined
  //   __typename?: undefined
  //   blocks?: undefined
  // }
}

interface GraphQLOptions<
  FALLBACK_TYPES = unknown,
  TYPED_GQL_STRING extends string = string
> extends GraphQLOptionsV6<FALLBACK_TYPES, TYPED_GQL_STRING> {
  variables?: GraphQLVariablesV6<FALLBACK_TYPES, TYPED_GQL_STRING> & GraphQLGenericVariables
}

/**
 * API Service that handles CRUD requests to our Lambda REST API as well as requests to the GraphQL API
 */
export default {
  graphql <
    FALLBACK_TYPES = unknown,
    TYPED_GQL_STRING extends string = string
  > (
    request: GraphQLOptions<FALLBACK_TYPES, TYPED_GQL_STRING>
  ): GraphQLResponseV6<FALLBACK_TYPES, TYPED_GQL_STRING> {
    const graphQLClient = generateClient()

    const response = graphQLClient.graphql<FALLBACK_TYPES, TYPED_GQL_STRING>(
      request
    )
    return response as any
  },

  async get <R extends any = RestAPIResponseBody> (apiName: string, path: string, options?: RestApiOptions, jsonResponse: boolean = true): Promise<R> {
    const restOperation = get({
      apiName,
      path,
      options
    })

    const response: RestApiResponse = await restOperation.response
    if (jsonResponse) {
      return await response.body.json() as R
    }
    return await response.body.text() as R
  },

  async post <R extends any = RestAPIResponseBody> (apiName: string, path: string, options?: RestApiOptions, jsonResponse: boolean = true): Promise<R> {
    const restOperation = post({
      apiName,
      path,
      options
    })

    const response: RestApiResponse = await restOperation.response
    if (jsonResponse) {
      return await response.body.json() as R
    }
    return await response.body.text() as R
  },

  async put <R extends any = RestAPIResponseBody> (apiName: string, path: string, options?: RestApiOptions, jsonResponse: boolean = true): Promise<R> {
    const restOperation = put({
      apiName,
      path,
      options
    })

    const response: RestApiResponse = await restOperation.response
    if (jsonResponse) {
      return await response.body.json() as R
    }
    return await response.body.text() as R
  },

  async del (apiName: string, path: string): Promise<void> {
    const restOperation: DeleteOperation = del({
      apiName,
      path
    })

    await restOperation.response
  }
}
