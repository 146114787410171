import { CriteriaGroup, CriteriaItem } from '../../../types/Content'

export default function deleteCriteriaItem (
  criteriaItems: CriteriaItem[],
  criteriaGroupSlug: string,
  changedCriteriaGroupItems: CriteriaGroup[],
  deletedCriteriaGroupItems: CriteriaGroup[]
): {
  changedCriteriaGroupItems: CriteriaGroup[]
  deletedCriteriaGroupItems: CriteriaGroup[]
} {
  const changedGroupIndex: number = changedCriteriaGroupItems.findIndex(
    (cg: CriteriaGroup): boolean => cg.slug === criteriaGroupSlug
  )
  if (changedGroupIndex !== -1) {
    changedCriteriaGroupItems.splice(changedGroupIndex, 1)
  }

  let deleteGroupIndex: number = deletedCriteriaGroupItems.findIndex(
    (cg: CriteriaGroup): boolean => cg.slug === criteriaGroupSlug
  )
  if (deleteGroupIndex === -1) {
    deleteGroupIndex = deletedCriteriaGroupItems.push({
      slug: criteriaGroupSlug,
      items: []
    }) - 1
  }
  criteriaItems.forEach((criteriaGroupItem: CriteriaItem): void => {
    deletedCriteriaGroupItems[deleteGroupIndex].items.push(criteriaGroupItem)
  })

  return {
    changedCriteriaGroupItems,
    deletedCriteriaGroupItems
  }
}
