import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getFullCompanyUser = /* GraphQL */ `
  query GetFullCompanyUser($cognitoIdentityId: ID!, $companyId: ID!) {
    getCompanyUser(
      cognitoIdentityId: $cognitoIdentityId
      companyId: $companyId
    ) {
      cognitoIdentityId
      companyId
      accountType
      user {
        id
        cognitoId
        userCreatedBy
        createdAt
        updatedAt
        displayName
        email
        defaultLanguage
        avatar {
          bucket
          region
          key
        }
        companies {
          items {
            companyId
            accountType
            company {
              name
              deletedAt
              createdAt
              sector
            }
          }
          nextToken
        }
      }
      company {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
` as GeneratedQuery<
  APITypes.GetFullCompanyUserQueryVariables,
  APITypes.GetFullCompanyUserQuery
>
