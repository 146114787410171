<template>
  <div class="px-1 pt-1 pb-5 px-lg-2">
    <v-stepper v-model="currentStep" vertical flat class="transparent">
      <template v-for="step in steps">
        <v-stepper-step
          :key="`pss-${step.step}`"
          :step="step.step"
          :complete="step.completed"
        >
          <v-row align="center" no-gutters>
            <v-col class="shrink">
              <span class="text-no-wrap">
                {{ step.label }}
              </span>
            </v-col>
          </v-row>
        </v-stepper-step>

        <div
          v-if="step.step === AvailableSteps.CompanySelection"
          :key="`pss-package-${step.step}`"
        >
          <select-company-form
            row
            :value="selectedCompanyId"
          />
        </div>
        <div
          v-if="step.step === AvailableSteps.PackageSelection"
          :key="`pss-package-${step.step}`"
          class="px-8 pt-3"
        >
          <v-row v-if="isLoading">
            <platform-spinner class="mt-12 mx-auto" />
          </v-row>
          <template v-else>
            <v-row>
              <span v-if="!packageCategories.length" class="text-body-2 pt-2 pl-2">
                {{ $t('form.packageSelector.noResultsMessage') }}
              </span>
              <template v-else>
                <v-col
                  v-for="(category, index) in packageCategories"
                  class="pt-0"
                  :key="index"
                  :cols="12"
                  :md="12"
                  :lg="6"
                >
                  <h1 class="primary-header mb-3">
                    {{ category.name }}
                  </h1>
                  <v-row class="flex-column">
                    <v-col
                      v-for="questionnairePackage in category.packages"
                      :key="questionnairePackage.id"
                    >
                      <package-preview
                        :questionnaire-package="questionnairePackage"
                        @click="selectPackage(questionnairePackage)"
                      />
                    </v-col>
                  </v-row>
                </v-col>
              </template>
            </v-row>
          </template>
        </div>
      </template>
    </v-stepper>
  </div>
</template>

<script lang="ts">
import SelectCompanyForm from '@/components/forms/companies/SelectCompanyForm.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import { listCompanyPackages } from '@/helpers/packages'
import Vue, { PropType } from 'vue'
import { Company, PackageCategories, Questionnaire } from '@/API'
import { PlatformCompanyPackage, PlatformPackage } from '@/types/Platform'
import { flagPicker } from '@/helpers/flags'
import { sortByCreatedAt } from '@/helpers/company'
import PackagePreview from '@/components/questionnaire/packages/PackagePreview.vue'

interface PackageCategory {
  name: string
  packages: PlatformPackage[]
  slug: PackageCategories
}

enum Steps {
  CompanySelection = 1,
  PackageSelection = 2,
}

export default Vue.extend({
  name: 'PackageSelector',
  components: {
    SelectCompanyForm,
    PlatformSpinner,
    PackagePreview
  },
  props: {
    value: { type: String, required: false },
    availablePackages: { type: Array as PropType<PlatformPackage[]>, required: false },
    loading: { type: Boolean, required: false },
    companyQuestionnaires: { type: Array as PropType<Questionnaire[]>, required: true }
  },
  data: () => ({
    currentStep: <Steps>Steps.CompanySelection,
    pendingFetchTemplates: false,
    packageCategories: [] as PackageCategory[],
    changedCompany: false,
    AvailableSteps: Steps
  }),
  mounted (): void {
    void this.init()
  },
  watch: {
    availablePackages (): void {
      void this.init()
    },
    selectedCompanyId (): void {
      this.changedCompany = true
      this.currentStep = Steps.PackageSelection
    }
  },
  computed: {
    steps (): any[] {
      return [
        {
          label: this.$t('form.packageSelector.companyStepTitle'),
          step: Steps.CompanySelection,
          field: 'company',
          completed: this.changedCompany
        },
        {
          label: this.$t('form.packageSelector.packageStepTitle'),
          step: Steps.PackageSelection,
          field: 'company',
          completed: false
        }
      ]
    },
    currentStepIndex (): number {
      return this.currentStep - 1
    },
    isLoading (): boolean {
      return this.loading || this.pendingFetchTemplates
    },
    selectedCompany (): Company {
      return this.$store.state.Company.selectedCompany
    },
    selectedCompanyId (): string {
      return this.selectedCompany.id
    },
    iconSize (): number {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 80
      }
      if (this.$vuetify.breakpoint.mdOnly) {
        return 60
      }
      if (this.$vuetify.breakpoint.smOnly) {
        return 65
      }
      return 55
    }
  },
  methods: {
    flagPicker,
    async init (): Promise<void> {
      this.pendingFetchTemplates = true
      this.getQuestionnairePackages()
        .finally(() => {
          this.pendingFetchTemplates = false
        })
    },
    async getQuestionnairePackages (): Promise<void> {
      let packages: PlatformPackage[]
      if (this.availablePackages) {
        packages = this.availablePackages
      } else {
        const companyPackages: PlatformCompanyPackage[] = await listCompanyPackages(this.selectedCompanyId)
        packages = companyPackages.map((p: PlatformCompanyPackage) => p.package)
      }

      const packageCategories = packages.reduce(
        (categories: PackageCategory[], questionnairePackage: PlatformPackage) => {
          questionnairePackage.categories.forEach((categorySlug: PackageCategories | null) => {
            if (!categorySlug) {
              return
            }
            let index = categories.findIndex((c) => c.slug === categorySlug)
            if (index === -1) {
              const category: PackageCategory = {
                slug: categorySlug,
                name: this.$t(`form.wizard.packages.${categorySlug}Packages`) as string,
                packages: []
              }
              index = categories.push(category) - 1
            }
            categories[index].packages.push(questionnairePackage)
          })

          for (const category of categories) {
            category.packages?.sort(sortByCreatedAt).reverse()
          }
          return categories
        },
        []
      )
      this.packageCategories.splice(0, this.packageCategories.length, ...packageCategories)
    },
    selectPackage (questionnairePackage) {
      this.$emit('input', questionnairePackage.id)
    }
  }
})
</script>
