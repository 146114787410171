import { i18n } from '@/plugins/i18n'
import { InputFields } from '@betterboards/shared/types/Platform'
import { InputFieldLimits } from '@betterboards/shared/types/Report'

export const MaxFieldLengths: InputFieldLimits = {
  [InputFields.SectionIntroduction]: 800,
  [InputFields.SectionIntroductionCompact]: 500,
  [InputFields.SectionSummary]: 2500,
  [InputFields.EvaluationInformation]: 600,
  [InputFields.FocusAreas]: 10000,
  [InputFields.KeyFindingsSummary]: 350,
  [InputFields.KeyFindingsValue]: 120,
  [InputFields.TextQuestionText]: 250,
  [InputFields.TextQuestionSelfEvaluation]: 200,
  [InputFields.ScaleQuestionText]: 170,
  [InputFields.ScaleQuestionIntro]: 120,
  [InputFields.ScaleQuestionTextSelfEvaluation]: 200,
  [InputFields.ScaleQuestionIntroSelfEvaluation]: 120,
  [InputFields.MultiTextQuestionHeader]: 50,
  [InputFields.MultiTextQuestionResponse]: 500,
  [InputFields.TextQuestionResponse]: 500
}

export const ValidationPatterns = {
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  validCharacters: /^[a-zA-Z0-9_!#$%&'*+\/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/, //eslint-disable-line
  phone: /^\+?[0-9]{0,15}$/
}

export const Validators = {
  maxLength: (maxLength: number) => (val: string) => val?.length > maxLength ? i18n.t('form.validation.maxLengthErrorMessage', [maxLength]) as string : true,
  required: (val?: string) => !val || val.length === 0 ? i18n.t('form.validation.requiredErrorMessage') as string : true,
  email: (val?: string) => val && ValidationPatterns.email.test(val) ? true : i18n.t('form.validation.emailErrorMessage') as string,
  allowedEmailCharacters: (val?: string) => val && ValidationPatterns.validCharacters.test(val) ? true : i18n.t('form.validation.validCharactersCheckErrorMessage') as string,
  phoneNumber: (val?: string) => val && ValidationPatterns.phone.test(val) ? true : i18n.t('form.validation.phoneErrorMessage') as string
}

export function trimStringFields (formData: any): any {
  for (const data in formData) {
    if (typeof (formData[data]) === 'string') {
      formData[data] = formData[data].trim()
    }
  }
  if (formData.address) {
    for (const data in formData.address) {
      if (typeof (formData.address[data]) === 'string') {
        formData.address[data] = formData.address[data].trim()
      }
    }
  }
  return formData
}
