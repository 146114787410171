<template>
  <div class="pt-6 pb-2">
    <h3 class="mb-3">
      {{ $t('form.questionnaire.customContent.criteriaGroupTitle') }}
      <template v-if="criteriaGroups && criteriaGroups.length">
        ({{ criteriaGroups.length }})
      </template>
    </h3>
    <v-expansion-panels
      multiple
      readonly
      class="mb-4"
    >
      <v-expansion-panel
        v-for="(criteriaGroup) in criteriaGroups"
        :key="criteriaGroup.slug"
        :ref="`criteria-${criteriaGroup.slug}`"
        class="platform--expansion-panel mt-4 rounded"
      >
        <v-expansion-panel-header @click="toggleCriteriaGroupExpansionPanel(criteriaGroup.slug)">
          <div class="min-width-0">
            <v-row no-gutters align="center" class="flex-nowrap">
              <v-col class="flex-grow-1 min-width-0">
                <div class="criteria-group--header pr-6">
                  <strong :class="{
                    'warning--text': invalidCriteriaGroups.includes(criteriaGroup.slug)
                  }">
                    <template>
                      {{  $t(`form.questionnaire.customContent.groupTitles.${criteriaGroup.slug}`) }}
                    </template>
                  </strong>
                  <span class="text--secondary">
                    <span>(</span>
                    <strong>{{ getSelectedVariantCriteriaGroupsLength(criteriaGroup.items, criteriaGroup.slug) }} </strong>
                    <span>{{ $t('form.questionnaire.customContent.criteriaCount') }}</span>
                    <span>)</span>
                  </span>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <criteria-items-list
            :value="criteriaGroup"
            :selected-questionnaire-variant="selectedQuestionnaireVariant"
            :variants="variants"
            :questionnaire-id="questionnaireId"
            :disabled="disabled"
            :unsaved-criteria-groups="unsavedCriteriaGroups"
            :deleted-saved-criteria-groups="deletedSavedCriteriaGroups"
            @addCriteriaItem="(criteriaItems) => addCriteriaItem(criteriaGroup.slug, criteriaItems)"
            @updateCriteriaItem="(criteriaItem) => updateCriteriaItem(criteriaGroup.slug, criteriaItem)"
            @deleteCriteriaItem="(criteriaItems) => deleteCriteriaItem(criteriaGroup.slug, criteriaItems)"
            @valid="(valid) => updateCriteriaGroupValidity(criteriaGroup.slug, valid)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script lang="ts">
import CriteriaItemsList from '@/components/forms/criteria-groups/CriteriaItemsList.vue'
import Vue, { PropType } from 'vue'
import {
  PlatformQuestionnaireVariant,
  QuestionnaireVariantCode
} from '@/types/Platform'
import {
  CriteriaGroup,
  CriteriaItem,
  QuestionnaireCustomContent
} from '@betterboards/shared/types/Content'

export default Vue.extend({
  name: 'CriteriaGroupsList',
  components: {
    CriteriaItemsList
  },
  props: {
    value: { type: Object as PropType<QuestionnaireCustomContent>, required: false },
    selectedQuestionnaireVariant: { type: String as PropType<QuestionnaireVariantCode>, required: false },
    variants: { type: Array as PropType<PlatformQuestionnaireVariant[]>, required: true },
    questionnaireId: { type: String, required: false },
    disabled: { type: Boolean, default: false },
    saving: { type: Boolean, default: false }
  },
  mounted (): void {
    this.checkValidity()
  },
  data: () => ({
    invalidCriteriaGroups: <string[]>[],
    unsavedCriteriaGroups: <CriteriaGroup[]>[],
    deletedSavedCriteriaGroups: <CriteriaGroup[]>[]
  }),
  watch: {
    invalidCriteriaGroups: 'checkValidity',
    saving () {
      // Reset once save has finished
      if (!this.saving) {
        this.reset()
      }
    }
  },
  computed: {
    defaultVariant (): QuestionnaireVariantCode | undefined {
      return this.variants?.[0]?.variantCode as QuestionnaireVariantCode | undefined
    },
    criteriaGroups (): CriteriaGroup[] {
      return Object.keys(this.value)
        .reduce(
          (criteriaGroups: CriteriaGroup[], criteriaSlug: string): CriteriaGroup[] => {
            if (!criteriaGroups.find((c) => c.slug === criteriaSlug)) {
              criteriaGroups.push({
                slug: criteriaSlug,
                items: this.value[criteriaSlug]
              })
            }
            return criteriaGroups
          },
          []
        )
        .sort((criteriaGroupA: CriteriaGroup, criteriaGroupB: CriteriaGroup) => {
          return criteriaGroupA.slug.localeCompare(criteriaGroupB.slug)
        })
    }
  },
  methods: {
    reset (): void {
      this.unsavedCriteriaGroups.splice(0, this.unsavedCriteriaGroups.length)
      this.deletedSavedCriteriaGroups.splice(0, this.deletedSavedCriteriaGroups.length)
    },
    toggleCriteriaGroupExpansionPanel (slug?: string): void {
      if (!slug) {
        return
      }
      const sectionEl = this.$refs[`criteria-${slug}`]?.[0]
      if (sectionEl) {
        sectionEl.toggle()
      }
    },
    updateCriteriaGroupValidity (criteriaGroupSlug: string | undefined, valid: boolean): void {
      if (typeof criteriaGroupSlug !== 'string') {
        console.warn('Failed to updateCriteriaGroupValidity, CriteriaGroup has no slug!', criteriaGroupSlug)
        return
      }
      const index = this.invalidCriteriaGroups.indexOf(criteriaGroupSlug)
      if (index !== -1) {
        if (!valid) {
          // CriteriaGroup already marked as invalid, ignore.
          return
        }
        // CriteriaGroup no longer invalid
        this.invalidCriteriaGroups.splice(index, 1)
      }
      if (valid) {
        return
      }
      this.invalidCriteriaGroups.push(criteriaGroupSlug)
    },
    checkValidity (): void {
      if (this.invalidCriteriaGroups.length > 0) {
        this.$emit('valid', false)
        return
      }
      this.$emit('valid', true)
    },
    getSelectedVariantCriteriaGroupsLength (criteriaItems: CriteriaItem[], criteriaSlug: string): number {
      const savedItems = criteriaItems.filter((group: CriteriaItem) => group.variantCode === this.selectedQuestionnaireVariant).length

      let unsavedItems = 0
      const slugIndex = this.unsavedCriteriaGroups.findIndex((cg: CriteriaGroup) => cg.slug === criteriaSlug)
      if (slugIndex !== -1) {
        unsavedItems = this.unsavedCriteriaGroups[slugIndex].items.filter(
          (group: CriteriaItem) => group.variantCode === this.selectedQuestionnaireVariant
        ).length
      }

      let deletedSavedItems = 0
      const deletedSlugIndex = this.deletedSavedCriteriaGroups.findIndex((cg: CriteriaGroup) => cg.slug === criteriaSlug)
      if (deletedSlugIndex !== -1) {
        deletedSavedItems = this.deletedSavedCriteriaGroups[deletedSlugIndex].items.filter(
          (group: CriteriaItem) => group.variantCode === this.selectedQuestionnaireVariant
        ).length
      }

      return savedItems + unsavedItems - deletedSavedItems
    },
    addCriteriaItem (criteriaGroupSlug: string, criteriaItems: CriteriaItem[]): void {
      const existingSlugIndex = this.unsavedCriteriaGroups.findIndex((cg: CriteriaGroup) => cg.slug === criteriaGroupSlug)
      if (existingSlugIndex === -1) {
        this.unsavedCriteriaGroups.push({
          slug: criteriaGroupSlug,
          items: criteriaItems
        })
      } else {
        this.unsavedCriteriaGroups[existingSlugIndex].items.push(...criteriaItems)
      }
      this.$emit('addCriteriaItem', { criteriaGroupSlug, criteriaItems })
    },
    updateCriteriaItem (criteriaGroupSlug: string, criteriaItem: CriteriaItem): void {
      this.$emit('updateCriteriaItem', { criteriaGroupSlug, criteriaItem })
    },
    deleteCriteriaItem (criteriaGroupSlug: string, criteriaItems: CriteriaItem[]): void {
      const unsavedCriteriaGroupIndex = this.unsavedCriteriaGroups.findIndex((c: CriteriaGroup) => c.slug === criteriaGroupSlug)
      if (unsavedCriteriaGroupIndex !== -1) {
        criteriaItems.forEach((criteriaItem: CriteriaItem) => {
          const deleteIndex: number = this.unsavedCriteriaGroups[unsavedCriteriaGroupIndex].items.findIndex(
            (ci: CriteriaItem) => ci.slug === criteriaItem.slug && ci.variantCode === criteriaItem.variantCode
          )
          this.unsavedCriteriaGroups[unsavedCriteriaGroupIndex].items.splice(deleteIndex, 1)
        })
      }

      const existingSlugIndex = this.deletedSavedCriteriaGroups.findIndex((cg: CriteriaGroup) => cg.slug === criteriaGroupSlug)
      if (existingSlugIndex === -1) {
        this.deletedSavedCriteriaGroups.push({
          slug: criteriaGroupSlug,
          items: criteriaItems
        })
      } else {
        this.deletedSavedCriteriaGroups[existingSlugIndex].items.push(...criteriaItems)
      }

      this.$emit('deleteCriteriaItem', { criteriaGroupSlug, criteriaItems })
    }
  }
})
</script>

<style lang="scss" scoped>

.criteria-group--header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
