<template>
  <div class="pt-6 pb-2">
    <confirmation-modal
      v-if="confirmDeleteSection"
      entity-type="section"
      action="delete"
      :image="false"
      :entity="confirmDeleteSection"
      @confirm="deleteSection(confirmDeleteSection)"
      @cancel="confirmDeleteSection = undefined"
    />
    <h3 class="mb-3">
      {{ $t('form.questionnaire.sections.title') }}
      <template v-if="sections && sections.length">
        ({{ numSections }})
      </template>
    </h3>
    <platform-button
      @click="addSection(true)"
      primary
      icon="plus"
      v-tooltip:bottom="$t('form.questionnaire.sections.addSectionTopTooltip')"
      :disabled="disabled"
    >
      {{ $t('form.questionnaire.sections.addSectionTopAction') }}
    </platform-button>
    <v-expansion-panels multiple readonly class="mb-4">
      <v-expansion-panel
        v-for="(sections, id) in sectionVariants"
        :key="id"
        :ref="`section-${id}`"
        class="platform--expansion-panel mt-4 rounded"
      >
        <v-expansion-panel-header @click="toggleSectionExpansionPanel(id)">
          <div class="min-width-0">
            <v-row no-gutters align="center" class="flex-nowrap">
              <platform-icon
                v-if="invalidSections.includes(id)"
                name="alert-circle-outline"
                color="warning"
                :size="16"
                class="pr-1"
              />
              <v-col class="flex-grow-1 min-width-0">
                <div class="section-header pr-6">
                  <strong :class="{
                    'warning--text': invalidSections.includes(id)
                  }">
                    <template v-if="
                      sections[selectedQuestionnaireVariant] &&
                      sections[selectedQuestionnaireVariant].name
                    ">
                      {{ sections[selectedQuestionnaireVariant].name }}
                    </template>
                    <template v-else>
                      {{ sections[defaultVariant].name || $t('form.questionnaire.sections.unnamedSection') }}
                    </template>
                  </strong>
                  <span v-if="id && sectionBlockCounts[id] !== undefined" class="text--secondary">
                    <span>(</span>
                    <strong>{{ sectionBlockCounts[id] }} </strong>
                    <span>{{ $tc('form.questionnaire.sections.questionCount', sectionBlockCounts[id]) }}</span>
                    <span>)</span>
                  </span>
                </div>
              </v-col>
              <v-col cols="auto" class="pr-6">
                <order-controls
                  entity="Section"
                  :position="sections[defaultVariant].displayOrder"
                  :max="numSections"
                  :disabled="disabled"
                  @moveUp="() => moveSectionUp(sections[defaultVariant])"
                  @moveDown="() => moveSectionDown(sections[defaultVariant])"
                />
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <section-form
            v-if="sections"
            type="SectionForm"
            :section-variants="sections"
            :selected-questionnaire-variant="selectedQuestionnaireVariant"
            :variants="variants"
            :questionnaire-id="questionnaireId"
            :disabled="disabled"
            @updateSection="updateSection"
            @valid="(val) => updateSectionValidity(id, val)"
            @duplicate="duplicateSection(sections[defaultVariant])"
            @delete="confirmDeleteSection = sections[defaultVariant]"
            @updateBlock="updateBlock"
            @deleteBlock="(val) => $emit('deleteBlock', val)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div v-if="sections.length > 0">
      <platform-button
        primary
        icon="plus"
        v-tooltip="$t('form.questionnaire.sections.addSectionBottomTooltip')"
        :disabled="disabled"
        @click="addSection(false)"
      >
        {{ $t('form.questionnaire.sections.addSectionBottomAction') }}
      </platform-button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { v4 as uuid } from 'uuid'
import { cloneDeep } from 'lodash'
import { PartialSection, PlatformBlock, PlatformQuestionnaireVariant, QuestionnaireVariantCode } from '@/types/Platform'
import { getSectionVariantData } from '@/helpers/questionnaire'
import OrderControls from '@/components/shared/OrderControls.vue'
import SectionForm from '@/components/forms/sections/SectionForm.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import sortByDisplayOrder from '@betterboards/shared/helpers/util/sortByDisplayOrder'
import { PlatformSection } from '@betterboards/shared/types/Platform'

type SectionsMap = { [sectionId: string]: { [variantCode: string]: PlatformSection } | undefined }

const DefaultSection = {
  name: null,
  displayOrder: null,
  blocks: {
    items: []
  }
}

export default Vue.extend({
  name: 'SectionsList',
  components: {
    ConfirmationModal,
    OrderControls,
    SectionForm
  },
  props: {
    value: { type: Array as PropType<PlatformSection[]>, required: false },
    selectedQuestionnaireVariant: { type: String as PropType<QuestionnaireVariantCode>, required: false },
    variants: { type: Array as PropType<PlatformQuestionnaireVariant[]>, required: true },
    questionnaireId: { type: String, required: false },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({
    sections: <PlatformSection[]>[], // Sections with a variantCode matching this.defaultVariant
    sectionVariantsMap: <SectionsMap>{},
    invalidSections: <string[]>[],
    confirmDeleteSection: <PlatformSection | undefined>undefined
  }),
  mounted (): void {
    this.initSections()
    this.checkValidity()
  },
  watch: {
    value: 'initSections',
    invalidSections: 'checkValidity'
  },
  computed: {
    numSections (): number {
      return Object.keys(this.sectionVariants).length
    },
    sectionBlockCounts (): { [sectionId: string]: number } {
      return Object.values(this.sectionVariantsMap).reduce((map: any, sectionsMap: { [variantCode: string]: PlatformSection } | undefined) => {
        if (!sectionsMap) {
          return map
        }
        const section = sectionsMap[this.selectedQuestionnaireVariant]
        map[section.id] = section.blocks?.items?.filter((b) => b.variantCode === this.selectedQuestionnaireVariant).length ?? 0
        return map
      }, {})
    },
    defaultVariant (): QuestionnaireVariantCode | undefined {
      return this.variants?.[0]?.variantCode as QuestionnaireVariantCode | undefined
    },
    /**
     * Display value for this.sectionVariantsMap, sorted on displayOrder and filters out keys
     *  with undefined values so it can be used in a v-for.
     */
    sectionVariants (): SectionsMap {
      const variant = this.defaultVariant
      if (!variant) {
        return {}
      }
      return Object.keys(this.sectionVariantsMap)
        .sort((a, z) => {
          const aDisplayOrder = this.sectionVariantsMap[a]?.[variant]?.displayOrder
          const zDisplayOrder = this.sectionVariantsMap[z]?.[variant]?.displayOrder
          if (aDisplayOrder !== undefined && zDisplayOrder !== undefined) {
            return aDisplayOrder - zDisplayOrder
          }
          if (aDisplayOrder === undefined) {
            return 1
          }
          if (zDisplayOrder === undefined) {
            return -1
          }
          return 0
        })
        .reduce((map, sectionId) => {
          const sections = this.sectionVariantsMap[sectionId]
          if (!sections) {
            return map
          }
          map[sectionId] = sections
          return map
        }, {})
    }
  },
  methods: {
    initSections (): void {
      if (!this.value?.length) {
        return
      }
      const sections: PlatformSection[] = []
      this.value.forEach((section: PlatformSection) => {
        section.variantCode = section.variantCode ?? this.defaultVariant
        this.setSectionVariant(section)
        if (section.variantCode === this.defaultVariant) {
          sections.push(section)
        }
      })
      const newSections = this.orderSections(sections)
      this.sections.splice(
        0,
        this.sections.length,
        ...newSections
      )
    },
    setSectionVariant (sectionVariant: PlatformSection): void {
      if (!this.sectionVariantsMap[sectionVariant.id]) {
        Vue.set(this.sectionVariantsMap, sectionVariant.id, this.variants.reduce((map, v) => {
          map[v.variantCode] = getSectionVariantData(sectionVariant, v.variantCode)
          return map
        }, {}))
      }
      Vue.set(this.sectionVariantsMap[sectionVariant.id]!, sectionVariant.variantCode, sectionVariant)
    },
    toggleSectionExpansionPanel (id?: string): void {
      if (!id) {
        return
      }
      const sectionEl = this.$refs[`section-${id}`]?.[0]
      if (sectionEl) {
        sectionEl.toggle()
      }
    },
    addSection (atStart: boolean): void {
      if (this.disabled) {
        return
      }
      const section: PartialSection = {
        ...cloneDeep(DefaultSection),
        id: uuid(),
        questionnaireId: this.questionnaireId,
        companyId: this.$store.state.Company.selectedCompany.id,
        variantCode: this.selectedQuestionnaireVariant
      } as PartialSection

      const sections = [...this.sections]
      if (atStart) {
        sections.unshift(section as PlatformSection)
      } else {
        sections.push(section as PlatformSection)
      }
      this.setSections(sections)
      const orderedSection = this.sections.find((s) => s.id === section.id && s.variantCode === section.variantCode)
      if (orderedSection) {
        this.setSectionVariant(orderedSection)
      }

      this.$nextTick(() => {
        this.toggleSectionExpansionPanel(section.id)
      })
    },
    updateSection (section: PlatformSection): void {
      if (this.disabled || this.invalidSections.includes(section.id)) {
        return
      }
      this.setSectionVariant(section)
      this.$emit('updateSection', section)
    },
    updateSectionValidity (sectionId: string | undefined, valid: boolean): void {
      if (typeof sectionId !== 'string') {
        console.warn('Failed to updateSectionValidity, section has no id!', sectionId)
        return
      }
      const index = this.invalidSections.indexOf(sectionId)
      if (index !== -1) {
        if (!valid) {
          // Section already marked as invalid, ignore.
          return
        }
        // Section no longer invalid
        this.invalidSections.splice(index, 1)
      }
      if (valid) {
        return
      }
      this.invalidSections.push(sectionId)
    },
    checkValidity (): void {
      if (this.invalidSections.length > 0) {
        this.$emit('valid', false)
        return
      }
      this.$emit('valid', true)
    },
    duplicateSection (section): void {
      if (this.disabled) {
        return
      }
      const tempSections = [...this.sections]
      const index = tempSections.findIndex((s) => s.id === section.id)
      const newIndex = index + 1
      const newSection = {
        ...cloneDeep(DefaultSection),
        name: `${section.name} - Copy`,
        id: uuid(),
        companyId: this.$store.state.Company.selectedCompany.id,
        questionnaireId: section.questionnaireId,
        variantCode: this.selectedQuestionnaireVariant
      } as PartialSection
      tempSections.splice(newIndex, 0, newSection as PlatformSection)
      this.setSectionVariant(section)
      this.setSections(tempSections)

      this.$nextTick(() => {
        this.toggleSectionExpansionPanel(section.id)
      })
    },
    deleteSection (section): void {
      this.confirmDeleteSection = undefined
      const variantsMap = this.sectionVariantsMap[section.id]
      if (this.disabled || !variantsMap) {
        return
      }
      const tempSections = [...this.sections]
      Object.keys(variantsMap).forEach((variantCode) => {
        if (variantsMap[variantCode]) {
          this.$emit('deleteSection', {
            ...section,
            variantCode
          })
          const index = tempSections.findIndex((b) => b.id === section.id && b.variantCode === section.variantCode)
          if (index !== -1) {
            tempSections.splice(index, 1)
          }
        }
      })
      this.setSections(tempSections)
      this.sectionVariantsMap[section.id] = undefined
    },
    orderSections (sections: PlatformSection[]): PlatformSection[] {
      return sections.sort(sortByDisplayOrder)
    },
    setSections (sections: PlatformSection[]): void {
      this.sections = sections
        .map((section, index) => {
          const newSection = {
            ...section,
            displayOrder: index + 1
          }
          if (newSection.displayOrder !== section.displayOrder) {
            this.updateSection(newSection)
          }
          return newSection
        })
    },
    moveSectionUp (section): void {
      if (this.disabled) {
        return
      }
      const tempSections = [...this.sections]
      const currentIndex = tempSections.findIndex((s) => s.id === section.id)
      if (currentIndex === 0) {
        return
      }
      const newIndex = currentIndex - 1
      tempSections.splice(currentIndex, 1)
      tempSections.splice(newIndex, 0, section)
      this.setSections(tempSections)
    },
    moveSectionDown (section): void {
      if (this.disabled) {
        return
      }
      const tempSections = [...this.sections]
      const currentIndex = tempSections.findIndex((s) => s.id === section.id)
      if (currentIndex === tempSections.length) {
        return
      }
      const newIndex = currentIndex + 1
      tempSections.splice(currentIndex, 1)
      tempSections.splice(newIndex, 0, section)
      this.setSections(tempSections)
    },
    updateBlock (block: PlatformBlock): void {
      this.$emit('updateBlock', block)
    }
  }
})
</script>

<style lang="scss" scoped>

.section-header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
