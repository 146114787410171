import { i18n } from '@/plugins/i18n'

export default function getPageTitle (routeName: string): string | undefined {
  if (routeName.includes('Analysis')) {
    routeName = 'Analysis'
  }
  switch (routeName) {
    case 'ResponseStatusList':
      return i18n.t('route.QuestionnairesReportList.questionnaireResponses') as string
    case 'SurveyResponseStatus':
      return i18n.t('route.QuestionnairesReportList.questionnaireResponses') as string
    case 'QuestionnaireResponseAnalysis':
      return i18n.t('route.QuestionnairesReportList.questionnaireResponses') as string
    case 'QuestionnairesReportList':
      return i18n.t('route.QuestionnairesReportList.questionnaireReports') as string
    case 'SurveyRelease':
      return i18n.t('route.QuestionnairesReportList.releaseQuestionnaire') as string
    case 'Analysis':
      return i18n.t('route.QuestionnairesReportList.analysis') as string
    default:
      return undefined
  }
}
