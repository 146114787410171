<template>
  <div v-if="questionnaire" class="flex-grow-1 d-flex flex-column">
    <create-questionnaire-variant-modal
      v-if="creatingVariant"
      v-model="creatingVariant"
      :questionnaire="questionnaire"
      :available-languages="availableVariants"
      @create="createVariant"
    />
    <div class="flex-basis-0 flex-grow-1 overflow-y-auto">
      <v-container id="questionnaires--create">
        <v-row>
          <v-col>
            <v-card elevation="2" class="pb-6">
              <v-card-title>{{ $t('page.questionnaire.editor.title') }}</v-card-title>
              <platform-spinner v-if="pendingFetch" class="my-2 mx-auto" />
              <template v-else>
                <v-card-text>
                  <v-form ref="form" v-model="questionnaireFormValid" @submit.prevent="">
                    <v-form-base
                      class="no-col-gutters"
                      ref="questionnaireForm"
                      :model="questionnaire"
                      :schema="questionnaireSchema"
                      @input="questionnaireChanged = true"
                    />
                  </v-form>
                </v-card-text>

                <div>
                  <v-tabs
                    v-if="questionnaire.variants"
                    background-color="primary"
                    dark
                    v-model="activeVariant"
                  >
                    <v-tab
                      v-for="variant in questionnaire.variants"
                      :key="variant.variantCode"
                      :href="`#${variant.variantCode}`"
                      v-tooltip:top="variant.name === DefaultQuestionnaireVariantName
                        ? $t('page.questionnaire.editor.defaultVariantTooltip')
                        : $t('page.questionnaire.editor.variantTabTooltip', [variant.name])"
                    >
                      {{ $t(`global.languages.${variant.variantCode}`) }}
                    </v-tab>
                    <platform-button
                      text
                      icon="plus"
                      force-color="grey lighten-2"
                      v-tooltip="$t('page.questionnaire.editor.addVariantTooltip')"
                      :disabled="editDisabled || !canUpdate"
                      @click="creatingVariant = true"
                    >
                      {{ $t('page.questionnaire.editor.addVariantAction') }}
                    </platform-button>
                  </v-tabs>

                  <v-tabs-items
                    v-if="questionnaire.variants"
                    v-model="activeVariant"
                  >
                    <v-tab-item
                      v-for="variant in questionnaire.variants"
                      :key="variant.variantCode"
                      :id="variant.variantCode"
                    >
                      <v-card-text>
                        <v-form ref="form" v-model="variantFormValid" @submit.prevent="">
                          <v-form-base
                            class="no-col-gutters"
                            ref="variantForm"
                            :model="questionnaire.variants[activeVariantIndex]"
                            :schema="questionnaireVariantSchema"
                            @input="questionnaireChanged = true"
                          />
                        </v-form>
                      </v-card-text>
                    </v-tab-item>
                  </v-tabs-items>
                </div>

                <v-card-text>
                  <sections-list
                    v-if="questionnaire && activeVariant"
                    :value="questionnaire.sections.items"
                    :selected-questionnaire-variant="activeVariant"
                    :variants="questionnaire.variants"
                    :disabled="editDisabled || !canUpdate"
                    :questionnaire-id="questionnaire.id"
                    @valid="(val) => formValid = val"
                    @updateSection="updateSection"
                    @deleteSection="deleteSection"
                    @updateBlock="updateBlock"
                    @deleteBlock="deleteBlock"
                  />
                  <criteria-groups-list
                    v-if="showCriteriaList"
                    :value="questionnaireCustomContent"
                    :selected-questionnaire-variant="activeVariant"
                    :variants="questionnaire.variants"
                    :disabled="!canUpdate"
                    :questionnaire-id="questionnaire.id"
                    :saving="loading.save"
                    @valid="(val) => formValid = val"
                    @addCriteriaItem="handleAddCriteriaItem"
                    @updateCriteriaItem="handleUpdateCriteriaItem"
                    @deleteCriteriaItem="handleDeleteCriteriaItem"
                  />
                </v-card-text>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="flex-basis-0 flex-shrink-1">
      <v-toolbar
        class="grey lighten-5"
        bottom
        :style="{
          borderTop: '1px solid var(--v-grey-lighten1)'
        }"
        :height="60"
      >
        <div class="d-flex flex-row justify-space-between" :style="{ width: '100%' }">
          <platform-button
            :to="{ name: 'QuestionnaireList' }"
            icon="return"
            secondary
          >
            {{ $t('page.questionnaire.editor.backAction') }}
          </platform-button>

          <platform-button
            v-if="!isCreating"
            secondary
            icon="preview"
            @click="preview"
          >
            {{ $t('page.questionnaire.editor.previewAction') }}
          </platform-button>

          <v-row no-gutters class="shrink flex-nowrap">
            <template v-if="!isCreating && !pendingFetch">
              <platform-button
                v-if="isDispatched"
                primary
                icon="analysis"
                class="mr-2"
                @click="openAnalysis"
              >
                {{ $t('page.questionnaire.editor.analyseAction') }}
              </platform-button>
              <platform-button
                v-if="!isReleased"
                primary
                icon="dispatch"
                @click="releaseQuestionnaire"
              >
                {{ $t('page.questionnaire.editor.releaseAction') }}
              </platform-button>
            </template>

            <platform-button
              primary
              :loading="loading.save"
              :disabled="loading.save || !canSave"
              icon="save"
              v-tooltip="saveButtonTooltip"
              class="ml-2 pointer-events--auto"
              @click="save"
            >
              {{ isCreating ? $t('page.questionnaire.editor.createAction') : $t('page.questionnaire.editor.updateAction') }}
            </platform-button>
          </v-row>
        </div>
      </v-toolbar>
    </div>
  </div>
</template>

<script lang="ts">
import {
  LanguageCode,
  QuestionnaireStatus
} from '@/API'
import SectionsList from '@/components/forms/sections/SectionsList.vue'
import CriteriaGroupsList from '@/components/forms/criteria-groups/CriteriaGroupsList.vue'
import CreateQuestionnaireVariantModal from '@/components/questionnaire/variants/CreateVariantModal.vue'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import {
  getLatestRelease,
  getSectionVariantData,
  updateSectionsAndBlocks
} from '@/helpers/questionnaire'
import { EditableBlockVariantDataFields } from '@/helpers/questionnaire/blocks'
import {
  PlatformQuestionnaireVariant,
  QuestionnaireVariantCode
} from '@/types/Platform'
import { DefaultQuestionnaireVariantName } from '@betterboards/shared/helpers/entities/Questionnaire/index'
import Vue from 'vue'
import VFormBase from 'vuetify-form-base'
import {
  mapActions,
  mapState
} from 'vuex'
import { PlatformQuestionnaire, PlatformSection, PlatformBlock } from '@betterboards/shared/types/Platform'
import { UpdateQuestionnaireRequest } from '@/types/Questionnaire'
import { CriteriaGroup, CriteriaItem, QuestionnaireCustomContent } from '@betterboards/shared/types/Content'
import getUpdatedCustomContent from '@betterboards/shared/helpers/entities/Questionnaire/getUpdatedCustomContent'
import addCriteriaItem from '@betterboards/shared/helpers/entities/Questionnaire/addCriteriaItem'
import updateCriteriaItem from '@betterboards/shared/helpers/entities/Questionnaire/updateCriteriaItem'
import deleteCriteriaItem from '@betterboards/shared/helpers/entities/Questionnaire/deleteCriteriaItem'

const required = msg => v => !!v || msg

export default Vue.extend({
  name: 'QuestionnaireEditor',
  components: {
    CriteriaGroupsList,
    CreateQuestionnaireVariantModal,
    PlatformSpinner,
    SectionsList,
    VFormBase
  },
  props: {
    isCreating: { type: Boolean, required: false, default: false }
  },
  data () {
    return {
      amplifySubscriptions: [],
      editingTime: <Date | null>null,
      questionnaire: <Partial<PlatformQuestionnaire>>{
        id: undefined,
        companyId: undefined,
        name: undefined,
        status: undefined,
        sections: {
          items: []
        },
        groups: undefined,
        customContent: undefined,
        createdAt: undefined,
        variants: [],
        editDisabled: false
      },
      questionnaireChanged: false,
      newCriteriaGroupItems: <CriteriaGroup[]>[],
      changedSections: <PlatformSection[]>[],
      changedBlocks: <PlatformBlock[]>[],
      changedCriteriaGroupItems: <CriteriaGroup[]>[],
      deletedSections: <PlatformSection[]>[],
      deletedBlocks: <PlatformBlock[]>[],
      deletedCriteriaGroupItems: <CriteriaGroup[]>[],
      formValid: false,
      questionnaireFormValid: false,
      variantFormValid: false,
      pendingFetch: false,
      loading: {
        save: false
      },
      creatingVariant: false,
      activeVariant: <string | undefined>undefined,
      DefaultQuestionnaireVariantName
    }
  },
  computed: {
    ...mapState('Questionnaire', ['selected']),
    ...mapState('Company', ['selectedCompany']),
    questionnairePayload (): Partial<PlatformQuestionnaire> {
      return {
        id: this.questionnaire?.id,
        name: this.questionnaire?.name,
        variants: this.questionnaire?.variants,
        customContent: getUpdatedCustomContent(
          this.questionnaireCustomContent,
          this.newCriteriaGroupItems,
          this.changedCriteriaGroupItems,
          this.deletedCriteriaGroupItems
        )
      }
    },
    /** Disables updating an already released questionnaire **/
    canUpdate (): boolean {
      return !this.isReleased
    },
    questionnaireSchema (): any {
      return {
        name: {
          type: 'text',
          label: this.$t('form.questionnaire.name'),
          col: { cols: 12, md: 10, lg: 8 },
          rules: [required(this.$t('form.questionnaire.nameRequiredMessage'))],
          disabled: !this.canUpdate
        }
      }
    },
    questionnaireVariantSchema (): any {
      return {
        name: {
          type: 'BBTextField',
          label: this.$t('form.questionnaire.variant.name'),
          col: { cols: 12, md: 8, lg: 6 },
          tooltip: this.$t('form.questionnaire.variant.nameTooltip'),
          required: true,
          disabled: !this.canUpdate
        }
      }
    },
    isReleased (): boolean {
      if (!this.questionnaire?.status) {
        return false
      }
      return this.questionnaire.status === QuestionnaireStatus.RELEASED || this.questionnaire.status === QuestionnaireStatus.DISPATCHED
    },
    isDispatched (): boolean {
      if (!this.questionnaire?.status) {
        return false
      }
      return this.questionnaire.status === QuestionnaireStatus.DISPATCHED
    },
    isChanged (): boolean {
      return this.questionnaireChanged ||
        this.newCriteriaGroupItems.length > 0 ||
        this.deletedSections.length > 0 ||
        this.deletedBlocks.length > 0 ||
        this.deletedCriteriaGroupItems.length > 0 ||
        this.changedSections.length > 0 ||
        this.changedBlocks.length > 0 ||
        this.changedCriteriaGroupItems.length > 0
    },
    canSave (): boolean {
      if (!this.formValid) {
        return false
      }
      if (this.isCreating) {
        return true
      }
      if (!this.isChanged) {
        return false
      }
      return this.canUpdate
    },
    availableVariants (): QuestionnaireVariantCode[] {
      const languageCodes: QuestionnaireVariantCode[] = Object.values(LanguageCode)
      if (!this.questionnaire?.variants?.length) {
        return languageCodes
      }
      return languageCodes.filter((languageCode) => {
        return !this.questionnaire.variants?.some((v) => v?.variantCode === languageCode)
      })
    },
    activeVariantIndex (): number | undefined {
      if (!this.questionnaire?.variants?.length) {
        return undefined
      }
      const index = this.questionnaire.variants.findIndex((v) => v.variantCode === this.activeVariant)
      return index === -1 ? undefined : index
    },
    questionnaireCustomContent (): QuestionnaireCustomContent | undefined {
      return this.questionnaire.customContent
    },
    showCriteriaList (): boolean {
      return !!this.questionnaire && !!this.activeVariant && !!this.questionnaireCustomContent
    },
    editDisabled (): boolean {
      return !!this.questionnaire?.editDisabled
    },
    saveButtonTooltip (): string | undefined {
      if (!this.formValid) {
        return this.$t('page.questionnaire.editor.errorInQuestionnaire') as string
      }
      return this.isReleased
        ? this.$t('page.questionnaire.editor.alreadyReleasedMessage') as string
        : this.isCreating
          ? undefined
          : this.$t('page.questionnaire.editor.updateTooltip') as string
    }
  },
  async mounted (): Promise<void> {
    if (this.$route.params.id) {
      this.pendingFetch = true
      try {
        await this.initFromId(this.$route.params.id)
        if (this.selectedCompany.id !== this.selected.companyId) {
          await this.selectCompany({ id: this.selected.companyId })
        }
      } finally {
        this.pendingFetch = false
      }
    }
    if (!this.activeVariant) {
      this.activeVariant = this.questionnaire?.variants?.[0]?.variantCode
    }
    this.setVariantCodes(this.questionnaire as PlatformQuestionnaire)
  },
  methods: {
    ...mapActions('Questionnaire', ['selectQuestionnaire']),
    ...mapActions('Survey', ['fetchReleases']),
    ...mapActions('Company', ['selectCompany']),
    syncChangedBlockVariants (): void {
      const variants = this.questionnaire.variants
      if (!variants) {
        return
      }
      const variantChanges: any[] = []
      this.changedBlocks.forEach((block) => {
        variants.forEach((variant) => {
          if (variant.variantCode === block.variantCode) {
            return
          }
          const changedBlockVariant = this.changedBlocks.find((b) => b.id === block.id && b.variantCode === variant.variantCode)
          const sectionMatch = this.questionnaire.sections?.items?.find((s) => s.id === block.sectionId && s.variantCode === variant.variantCode)
          const originalBlockVariant = sectionMatch?.blocks?.items?.find((b) => b.id === block.id && b.variantCode === variant.variantCode)
          if (!changedBlockVariant?.data && !originalBlockVariant?.data) {
            // Block doesn't have this variant yet, no need to sync.
            return
          }
          const blockData = {
            ...block.data
          }
          EditableBlockVariantDataFields.forEach((field) => {
            blockData[field] = changedBlockVariant?.data?.[field] ??
              originalBlockVariant?.data?.[field] ??
              block?.data?.[field]
          })
          variantChanges.push({
            ...block,
            variantCode: variant.variantCode,
            data: blockData,
            createdAt: originalBlockVariant?.createdAt,
            updatedAt: originalBlockVariant?.updatedAt
          })
        })
      })

      variantChanges.forEach((block) => {
        const index = this.changedBlocks.findIndex((b) => b.id === block.id && b.variantCode === block.variantCode)
        if (index === -1) {
          this.changedBlocks.push(block)
          return
        }
        this.changedBlocks.splice(index, 1, block)
      })
    },
    async save (): Promise<void> {
      this.loading.save = true

      this.syncChangedBlockVariants()
      try {
        await this.persistData()
      } catch (err) {
        console.error('Failed to persist Questionnaire data', {
          payload: this.questionnairePayload,
          err
        })
        this.$toasted.error(
          this.isCreating
            ? this.$t('page.questionnaire.editor.createErrorMessage') as string
            : this.$t('page.questionnaire.editor.updateErrorMessage') as string
        )
      } finally {
        this.loading.save = false
      }
    },
    async persistData (): Promise<void> {
      if (!this.questionnaire.companyId) {
        throw new Error('Failed to update questionnaire as no company ID is available.')
      }
      if (!this.questionnaire.id) {
        throw new Error('Failed to update questionnaire as no id is available.')
      }

      await this.$store.dispatch('Questionnaire/updateQuestionnaire', this.questionnairePayload)

      const updateQuestionnaireRequest: UpdateQuestionnaireRequest = {
        sections: {
          create: this.changedSections.filter((section: PlatformSection) => !section.createdAt),
          update: this.changedSections.filter((section: PlatformSection) => section.createdAt),
          delete: this.deletedSections.map((section: PlatformSection) => ({
            id: section.id,
            variantCode: section.variantCode
          }))
        },
        blocks: {
          create: this.changedBlocks.filter((block: PlatformBlock) => !block.createdAt),
          update: this.changedBlocks.filter((block: PlatformBlock) => block.createdAt),
          delete: this.deletedBlocks.map((block: PlatformBlock) => ({
            id: block.id,
            variantCode: block.variantCode
          }))
        },
        companyId: this.questionnaire.companyId,
        id: this.questionnaire.id
      }
      try {
        await updateSectionsAndBlocks(updateQuestionnaireRequest)
      } catch (err) {
        console.error('Caught an error while updating Questionnaire Sections and Blocks', JSON.stringify(err, null, 2))
        throw err
      }

      this.resetForm()
      this.$toasted.success(
        this.isCreating
          ? this.$t('page.questionnaire.editor.createSuccessMessage') as string
          : this.$t('page.questionnaire.editor.updateSuccessMessage') as string
      )
      console.debug('Successfully created/updated questionnaire', JSON.stringify(this.questionnaire, null, 2))
      await this.initFromId(this.questionnaire.id)
    },
    async initFromId (id): Promise<void> {
      await this.selectQuestionnaire({ id })
      this.editingTime = new Date()
      this.questionnaire = this.selected
    },
    preview (): void {
      if (!this.questionnaire?.id) {
        return
      }
      this.$router.push({
        name: 'PreviewSurvey',
        params: {
          surveyId: this.questionnaire.id
        }
      })
    },
    async openAnalysis (): Promise<void> {
      if (!this.questionnaire?.id) {
        return
      }
      const questionnaireReleases = await this.fetchReleases(this.questionnaire.id)
      const releases = questionnaireReleases.items
      const release = getLatestRelease(releases)
      await this.$router.push({
        name: 'Analysis',
        params: {
          surveyId: release.groupId
        }
      })
    },
    createVariant (variant: PlatformQuestionnaireVariant): void {
      if (!this.questionnaire.variants) {
        return
      }
      this.questionnaire.variants.push(variant)
      this.creatingVariant = false
      this.questionnaireChanged = true
    },
    deleteSection (section: PlatformSection): void {
      if (section.createdAt) {
        this.deletedSections.push(section)
      }
      const index = this.changedSections.findIndex((s) => s.id === section.id && s.variantCode === section.variantCode)
      if (index !== -1) {
        this.changedSections.splice(index, 1)
      }
    },
    updateSection (section: PlatformSection): void {
      const index = this.changedSections.findIndex((s) => s.id === section.id && s.variantCode === section.variantCode)
      if (index === -1) {
        this.changedSections.push({
          ...section,
          questionnaireId: this.questionnaire.id ?? section.questionnaireId,
          companyId: this.selectedCompany.id
        })
        return
      }
      this.changedSections.splice(index, 1, section)
    },
    updateBlock (block: PlatformBlock): void {
      const sectionMatch = this.questionnaire.sections?.items?.find((s) => s.id === block.sectionId && s.variantCode === block.variantCode)
      const changedSectionMatch = this.changedSections.find((s) => s.id === block.sectionId && s.variantCode === block.variantCode)
      if (!sectionMatch && !changedSectionMatch) {
        const defaultVariant = this.questionnaire.variants?.[0]?.variantCode
        const defaultSection = this.questionnaire.sections?.items?.find((s) => s.id === block.sectionId && s.variantCode === defaultVariant)
        if (defaultSection) {
          this.updateSection(getSectionVariantData(defaultSection, block.variantCode))
        }
      }

      const index = this.changedBlocks.findIndex((b) => b.id === block.id && b.variantCode === block.variantCode)
      if (index === -1) {
        this.changedBlocks.push({
          ...block,
          companyId: this.selectedCompany.id
        })
        return
      }
      this.changedBlocks.splice(index, 1, block)
    },
    deleteBlock (block: PlatformBlock): void {
      if (block.createdAt) {
        this.deletedBlocks.push(block)
      }
      const index = this.changedBlocks.findIndex((b) => b.id === block.id && b.variantCode === block.variantCode)
      if (index !== -1) {
        this.changedBlocks.splice(index, 1)
      }
    },
    handleAddCriteriaItem ({
      criteriaItems,
      criteriaGroupSlug
    }: {
      criteriaItems: CriteriaItem[],
      criteriaGroupSlug: string
    }): void {
      this.newCriteriaGroupItems = addCriteriaItem(
        criteriaItems,
        criteriaGroupSlug,
        this.newCriteriaGroupItems
      )
    },
    handleUpdateCriteriaItem ({
      criteriaItem,
      criteriaGroupSlug
    }: {
      criteriaItem: CriteriaItem,
      criteriaGroupSlug: string
    }): void {
      this.changedCriteriaGroupItems = updateCriteriaItem(
        criteriaItem,
        criteriaGroupSlug,
        this.changedCriteriaGroupItems
      )
    },
    handleDeleteCriteriaItem ({
      criteriaItems,
      criteriaGroupSlug
    }: {
      criteriaItems: CriteriaItem[],
      criteriaGroupSlug: string
    }): void {
      const criteriaGroupItems: {
        changedCriteriaGroupItems: CriteriaGroup[],
        deletedCriteriaGroupItems: CriteriaGroup[]
      } = deleteCriteriaItem(
        criteriaItems,
        criteriaGroupSlug,
        this.changedCriteriaGroupItems,
        this.deletedCriteriaGroupItems
      )

      this.changedCriteriaGroupItems = criteriaGroupItems.changedCriteriaGroupItems
      this.deletedCriteriaGroupItems = criteriaGroupItems.deletedCriteriaGroupItems
    },
    resetForm (): void {
      this.newCriteriaGroupItems.splice(0, this.newCriteriaGroupItems.length)
      this.deletedSections.splice(0, this.deletedSections.length)
      this.deletedBlocks.splice(0, this.deletedBlocks.length)
      this.deletedCriteriaGroupItems.splice(0, this.deletedCriteriaGroupItems.length)
      this.changedSections.splice(0, this.changedSections.length)
      this.changedBlocks.splice(0, this.changedBlocks.length)
      this.changedCriteriaGroupItems.splice(0, this.changedCriteriaGroupItems.length)
      this.questionnaireChanged = false
    },
    /** Sets the variantCode field to the value of this.activeVariant on any sections/blocks missing it. */
    setVariantCodes (questionnaire: PlatformQuestionnaire): void {
      const activeVariant = this.activeVariant as QuestionnaireVariantCode | undefined
      if (!activeVariant) {
        console.warn('Failed to setVariantCodes due to undefined activeVariant.')
        return
      }
      questionnaire.sections?.items?.forEach((section) => {
        if (!section.variantCode) {
          section.variantCode = activeVariant
        }
        section.blocks?.items?.forEach((block) => {
          if (!block.variantCode) {
            block.variantCode = activeVariant
          }
          if (!block.createdAt) {
            this.updateBlock(block)
          }
        })
        if (!section.createdAt) {
          this.updateSection(section)
        }
      })
    },
    releaseQuestionnaire (): void {
      void this.$router.push({
        name: 'SurveyRelease',
        params: {
          id: this.questionnaire.id as string
        }
      })
    }
  }
})
</script>
