<template>
  <div class="d-flex flex-column">
    <edit-committee-modal
      v-if="editingCommittee"
      :committee="editingCommittee"
      @close="editingCommittee = null"
    />
    <create-committee-modal
      v-if="creatingCommittee"
      @close="creatingCommittee = false"
      @created="committeeCreated"
    />
    <v-row no-gutters>
      <platform-button
        primary
        class="mb-4 ml-1"
        @click="creatingCommittee = true"
      >
        {{ $t('form.wizard.committees.createCommitteeAction') }}
      </platform-button>
    </v-row>
    <v-row no-gutters class="px-2">
      <v-col v-if="companyCommittees.length">
        <v-row
          v-for="(committee, index) in companyCommittees"
          :key="`${committee.id}-${index}`"
          no-gutters
          class="grow"
        >
          <v-col class="grow">
            <platform-entity-card
              align="start"
              compact
              entity-type="committee"
              list-members
              hide-sub-title
              row
              inline-actions
              :entity="committee"
              :deleting="removingCommittee === committee.id"
              @edit="editCommittee(committee)"
              @delete="removeCommittee(committee)"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else>
        <span class="text-body-2 grey--text">
          {{ $t('form.wizard.committees.noResults') }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script lang="ts">
import { Company } from '@/API'
import Vue from 'vue'
import EditCommitteeModal from '@/components/committees/EditCommitteeModal.vue'
import PlatformEntityCard from '@/components/PlatformEntityCard.vue'
import CreateCommitteeModal from '@/components/committees/CreateCommitteeModal.vue'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'CommitteesForm',
  data: () => ({
    editingCommittee: null,
    creatingCommittee: false,
    removingCommittee: null
  }),
  components: {
    EditCommitteeModal,
    PlatformEntityCard,
    CreateCommitteeModal
  },
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    companyCommittees (): any[] | undefined {
      return this.$store.getters['Committee/list']
    }
  },
  methods: {
    ...mapActions('Committee', ['deleteCommittee']),
    editCommittee (committee) {
      this.editingCommittee = committee
    },
    removeCommittee (committee) {
      this.removingCommittee = committee.id
      this.$store.dispatch('Committee/deleteCommittee', committee)
        .then((response) => {
          this.removingCommittee = null
          this.$toasted.global.success(this.$t('page.committees.list.deleteSuccessMessage') as string)
          console.debug('Deleted committee!', response)
        })
    },
    committeeCreated (): void {
      this.creatingCommittee = false
    }
  }
})
</script>
