/**
 * Unfortunately methods like node.getComputedTextLength don't work until the
 *  graph has already been rendered in the DOM, so we use this method to set
 *  a 1ms delay before calling them.
 */
export function handleBrowserDelay (
  callback: () => void
): void {
  if (isBrowser()) {
    setTimeout(() => {
      callback()
    }, 1)
    return
  }
  callback()
}

export function isBrowser (): boolean {
  return typeof window === 'object'
}
