/* tslint:disable */
/* eslint-disable */
import { GeneratedQuery } from '@aws-amplify/api-graphql'
import * as APITypes from '@/API'

export const getFullSurveyByOwner = /* GraqhQL */ `
  query FullSurveysByOwner(
    $owner: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveysByOwner(
      owner: $owner
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        companyId
        questionnaireId
        individualId
        owner
        key
        variantCode
        dispatchedAt
        finishedAt
        deletedAt
        totals {
          questions
          questionsCompleted
        }
        response
        status
        invitation
        createdAt
        updatedAt
        individual {
          id
          createdAt
          email
          companyId
          dateOfBirth
          firstName
          gender
          contactNumber
          preferredName
          familyName
          title
          role
          jobTitle
          religion
          ethnicDesignation
          joinDate
          updatedAt
        }
        questionnaire {
          id
          createdAt
          deletedAt
          companyId
          name
          status
          groups
          customContent
          packageId
          variants {
            name
            variantCode
          }
          updatedAt
        }
      }
      nextToken
    }
  }
` as GeneratedQuery<
  APITypes.FullSurveysByOwnerQueryVariables,
  APITypes.FullSurveysByOwnerQuery
>
