<template>
  <div>
    <confirmation-modal
      v-if="showPasswordResetModal"
      force-icon="lock-open"
      action="resetPassword"
      entity-type="individual"
      :message="$t('modal.resetPassword.confirmationMessage')"
      :entity="formData"
      :loading="pendingResetPassword"
      @confirm="sendPasswordReset"
      @cancel="showPasswordResetModal = false"
    />
    <confirmation-modal
        v-if="deleteConfirmationVisible"
        action="delete"
        entity-type="individual"
        :entity="formData"
        @confirm="confirmDeleteIndividual"
        @cancel="deleteConfirmationVisible = false"
    />
    <platform-modal
        v-model="showModal"
        icon="user"
        :width="800"
        :title="`${$t('form.individual.edit')} - ${individualFullName}`"
        :saving="saving"
        :save-disabled="!isValid"
        @save="confirmUpdateIndividual"
        @validate="$refs.form.validate()"
        @delete="promptDeleteIndividual"
    >
      <individual-form
        v-if="formData"
        ref="form"
        :individual="formData"
        :saving="saving"
        @input="handleInput"
        @valid="(val) => isValid = val"
      />
      <template slot="dangerActions">
        <platform-button
          secondary
          icon="lock-open"
          @click="promptPasswordReset"
        >
          {{ $t('modal.resetPassword.action') }}
        </platform-button>
      </template>
    </platform-modal>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { mapActions, mapMutations } from 'vuex'
import API from '@/services/API'
import { PartialIndividual, PlatformIndividual } from '@betterboards/shared/types/Individual'
import { getFullName } from '@/helpers/individual'
import PlatformModal from '@/components/PlatformModal.vue'
import PlatformButton from '@/components/shared/PlatformButton.vue'
import IndividualForm from '@/components/individuals/IndividualForm.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import { trimStringFields } from '@/helpers/validation'

export default Vue.extend({
  name: 'EditIndividualModal',
  props: {
    value: { type: Object as PropType<PlatformIndividual>, required: true }
  },
  components: {
    ConfirmationModal,
    PlatformModal,
    IndividualForm,
    PlatformButton
  },
  data () {
    return {
      showModal: true,
      deleteConfirmationVisible: false,
      showPasswordResetModal: false,
      pendingResetPassword: false,
      formData: <PartialIndividual>{
        id: '',
        email: '',
        preferredName: '',
        appointments: {
          Chair: 0,
          SID: 0,
          NonExecutive: 0,
          CommitteeChair: 0,
          Other: 0
        }
      },
      timerId: null as any,
      saving: false,
      isValid: false
    }
  },
  mounted () {
    this.initFormData()
  },
  watch: {
    showModal (val) {
      if (val) {
        this.initFormData()
      } else {
        this.$emit('close')
      }
    }
  },
  computed: {
    individualFullName (): string | undefined {
      if (!this.formData) {
        return undefined
      }
      return (getFullName(this.formData) || this.formData.preferredName) as string
    }
  },
  methods: {
    ...mapMutations('Individual', ['setSelectedIndividual']),
    ...mapActions('Individual', ['updateIndividual', 'deleteIndividual']),
    initFormData (): void {
      if (!this.value) {
        return
      }
      const individual = JSON.parse(JSON.stringify(this.value))
      this.formData = {
        ...individual,
        appointments: individual.appointments ?? this.formData.appointments
      }
    },
    confirmUpdateIndividual (): void {
      if (this.saving) {
        return
      }
      this.saving = true
      const individualData = trimStringFields(this.formData)
      this.updateIndividual(individualData)
        .then(() => {
          this.showModal = false
          this.$emit('updated')
        })
        .catch((err) => {
          console.error('Caught an error updating an individual:', err)
          this.$toasted.error('A problem occurred while trying to update that individual. Please try again or contact support.')
        })
        .finally(() => {
          this.saving = false
        })
    },
    handleInput (ev): void {
      this.formData = ev.data
    },
    promptDeleteIndividual () {
      this.deleteConfirmationVisible = true
    },
    promptPasswordReset () {
      this.showPasswordResetModal = true
    },
    confirmDeleteIndividual (): void {
      this.deleteIndividual(this.formData)
        .then(() => {
          this.$toasted.global.success('Board member successfully deleted!')
          this.showModal = false
        })
    },
    async sendPasswordReset (): Promise<void> {
      if (!this.formData?.id) {
        return
      }
      this.pendingResetPassword = true
      try {
        await API.post('backendfunctions', '/users/reset-password', {
          body: {
            individualId: this.formData.id
          }
        })
      } catch (err) {
        console.error('error resetting password', err)
        this.$toasted.error(this.$t('modal.resetPassword.errorMessage') as string)
        throw err
      } finally {
        this.pendingResetPassword = false
      }
      this.$toasted.success(
        this.$t('modal.resetPassword.successMessage', [this.individualFullName, this.formData.email]) as string
      )
      this.showPasswordResetModal = false
    }
  }
})
</script>
