import * as d3 from 'd3'
import { drawGraphLegend } from '../d3/legend'
import { normalizeFloat } from '../helpers'
import { SVGSelection } from '../types/d3'
import { DotTypes, FontSizes, LineConfig } from '../types/Graph'
import { AbstractGraph, GraphConfig, GraphInterface } from './Abstract'
import buildTriangleIconPath from '../helpers/paths/buildTriangleIconPath'

const ComparisonBallSize = 10
const DotSize = 12

export interface QuestionData {
  questionText?: string
  questionIntro?: string
  questionTextSelfEvaluation?: string
  questionIntroSelfEvaluation?: string
}

export interface ScaleGraphData extends QuestionData {
  field?: string
  min: number
  max: number
  avg: number
  comparison?: {
    min: number
    max: number
    avg: number
  }
}

export interface ScaleGraphConfig extends GraphConfig {
  width: number
  height: number
  tickLabels: boolean // Display values below ticks?
  range: {
    min: number
    max: number
  }
  margin: {
    top: number
    left: number
    right: number
    bottom: number
  }
  legend: boolean
  properties: {
    answersVariance: string
    answersVarianceComparison: string
    average: string
    averageComparison: string
  }
}

interface LineSizes {
  dataTick: number
  smallTick: number
  largeTick: number
}

enum StrokeWidths {
  Light = 1,
  Regular = 2,
  Bold = 3,
}

const lineCaps = 'round'

const DefaultConfig = {
  width: 210,
  height: 30,
  scale: 1,
  range: {
    min: 1,
    max: 5
  },
  margin: {
    top: 3,
    left: 10,
    right: 10,
    bottom: 0
  },
  tickLabels: false,
  legend: false,
  comparison: false,
  properties: {
    answersVariance: 'Variance of Answers',
    answersVarianceComparison: 'Variance (Comparison)',
    average: 'Average',
    averageComparison: 'Average (Comparison)'
  }
}

export default class ScaleGraph extends AbstractGraph<ScaleGraphConfig> implements GraphInterface<ScaleGraphData> {
  public getDefaultConfig (): Partial<ScaleGraphConfig> {
    return DefaultConfig
  }

  render (data: ScaleGraphData): void {
    super.render(data)

    const svg: SVGSelection = d3
      .select(this.selector)

    // Clear current contents (in case this is a re-render)
    svg
      .selectAll('*')
      .remove()

    const graphHeight: number = this.config.height - this.config.margin.top - this.config.margin.bottom
    const lineSizes: LineSizes = {
      dataTick: 0.65 * graphHeight,
      smallTick: 0.75 * graphHeight,
      largeTick: 0.9 * graphHeight
    }

    const config = this.config

    const startY: number = this.config.margin.top
    const mainLineY: number = startY + graphHeight / 2

    const dotSize = DotSize * this.config.scale
    const comparisonDotSize = ComparisonBallSize * this.config.scale

    // x-axis
    const xAxis = d3.scaleLinear()
      .domain([this.config.range.min, this.config.range.max])
      .range([this.config.margin.left, this.config.width - this.config.margin.right])
    const axis = svg.append('g')
      .call(d3.axisBottom(xAxis).ticks(5))
    axis.select('.domain').remove()
    const ticks = axis
      .selectAll('.tick text')
    if (this.config.tickLabels) {
      ticks
        .attr('transform', `translate(0, ${this.config.height - this.config.margin.bottom})`)
        .attr('font-size', this.config.scale * FontSizes.AxisLabelSmall)
        .attr('font-weight', 500)
        .attr('fill', this.config.colors.grey.dark)
    } else {
      ticks.remove()
    }

    if (data) {
      // Re-styling x-axis tick lines
      axis
        .selectAll('.tick line')
        .each(function (val, index, nodes) {
          const line = d3.select(nodes[index])
          line
            .attr('stroke', config.colors.grey.light)
          if (val === config.range.min || val === config.range.max) {
            line
              .attr('stroke-width', config.scale * StrokeWidths.Bold)
              .attr('stroke-linecap', lineCaps)
              .attr('stroke-dasharray', null)
              .attr('y1', mainLineY - lineSizes.largeTick / 2)
              .attr('y2', mainLineY + lineSizes.largeTick / 2)
            return
          }
          line
            .attr('stroke-width', config.scale * StrokeWidths.Regular)
            .attr('stroke-linecap', lineCaps)
            .attr('stroke-dasharray', (3 * config.scale).toString() + ', ' + (3.75 * config.scale).toString())
            .attr('y1', mainLineY - lineSizes.smallTick / 2)
            .attr('y2', mainLineY + lineSizes.smallTick / 2)
        })

      // Background grey horizontal line
      svg
        .append('line')
        .attr('x1', xAxis(config.range.min) as number + 1)
        .attr('x2', xAxis(config.range.max) as number)
        .attr('y1', mainLineY)
        .attr('y2', mainLineY)
        .attr('stroke-width', config.scale * StrokeWidths.Bold)
        .attr('stroke-linecap', lineCaps)
        .attr('stroke', config.colors.grey.light)

      if (config.comparison && data.comparison) {
        const comparisonLineSizes = {
          dataTick: lineSizes.dataTick * 0.25,
          smallTick: lineSizes.smallTick * 0.25,
          largeTick: lineSizes.largeTick * 0.25
        }
        const comparisonLineY = this.config.height - 2
        drawRangePlot(svg, this.config, xAxis, comparisonLineY, comparisonLineSizes, data.comparison, false, true)
      }
      drawRangePlot(svg, this.config, xAxis, mainLineY, lineSizes, data)
    } else {
      axis
        .selectAll('.tick line')
        .remove()
    }

    if (this.config.legend) {
      const items: LineConfig[] = [
        {
          property: this.config.properties.answersVariance,
          color: this.config.colors.primary,
          dot: DotTypes.Range,
          dotSize: dotSize
        },
        {
          property: this.config.properties.average,
          color: this.config.colors.secondary,
          dot: DotTypes.Circle,
          dotSize: dotSize
        }
      ]
      if (this.config.comparison) {
        items.push(
          {
            property: this.config.properties.answersVarianceComparison,
            color: this.config.colors.primaryAlternate,
            dot: DotTypes.RangeAlt,
            dotSize: dotSize
          },
          {
            property: this.config.properties.averageComparison,
            color: this.config.colors.secondaryAlternate,
            dot: DotTypes.Triangle,
            dotSize: comparisonDotSize
          }
        )
      }
      drawGraphLegend(this.config, items, svg)
    }
  }
}

/**
 * Draws the portion of the Scale graph representing the data - the range plot and average ball.
 */
function drawRangePlot (
  svg: SVGSelection,
  config: ScaleGraphConfig,
  xAxis: d3.ScaleLinear<any, any>,
  mainLineY: number,
  lineSizes: LineSizes,
  data: ScaleGraphData,
  averageLabel: boolean = true,
  comparison: boolean = false
): void {
  const dataset = svg.data([data])
  const primary = comparison ? config.colors.primaryAlternate : config.colors.primary
  const secondary = comparison ? config.colors.secondaryAlternate : config.colors.secondary
  const strokeWidth = config.scale * (comparison ? StrokeWidths.Regular : StrokeWidths.Bold)

  const hasRangeValues: boolean = !Number.isNaN(data.min) && !Number.isNaN(data.max)
  // Don't render range lines if they are the same value as the average (ie there is no range)
  const hasRange: boolean = hasRangeValues && data.min !== data.avg && data.max !== data.avg

  if (hasRange) {
    // Primary horizontal line
    const primaryLine = dataset
      .append('line')
      .attr('x1', (d) => xAxis(d.min) as number + StrokeWidths.Light / 2)
      .attr('x2', (d) => xAxis(d.max) as number + StrokeWidths.Light / 2)
      .attr('y1', mainLineY)
      .attr('y2', mainLineY)
      .attr('stroke-width', strokeWidth)
      .attr('stroke-linecap', lineCaps)
      .attr('stroke', primary)

    if (comparison) {
      primaryLine.attr('stroke-dasharray', '5, 5')
    }

    // Vertical lines at ends of primary horizontal line
    const endDataLines = [data.min, data.max]
    endDataLines.forEach(
      (val) => {
        if (!val || val === data.avg) {
          return
        }
        dataset
          .append('line')
          .attr('x1', xAxis(val) as number + StrokeWidths.Light / 2)
          .attr('x2', xAxis(val) as number + StrokeWidths.Light / 2)
          .attr('y1', mainLineY - lineSizes.dataTick / (comparison ? 1 : 2))
          .attr('y2', mainLineY + (comparison ? 0 : lineSizes.dataTick / 2))
          .attr('stroke-width', strokeWidth)
          .attr('stroke-linecap', lineCaps)
          .attr('stroke', primary)
      }
    )
  }

  if (data.avg) {
    const averageDatapointX: number = (xAxis(data.avg) as number) + 0.5
    if (comparison) {
      dataset.append('path')
        .attr('d', buildTriangleIconPath(averageDatapointX, mainLineY - 5, config.scale))
        .attr('fill', secondary)
        .attr('stroke', secondary)
        .attr('stroke-width', config.scale)
    } else {
      const dotSize = config.scale * DotSize * 0.5
      dataset
        .append('circle')
        .attr('cx', averageDatapointX)
        .attr('cy', mainLineY)
        .attr('r', dotSize)
        .attr('fill', secondary)
    }

    if (averageLabel) {
      dataset
        .append('text')
        .text((d) => normalizeFloat(d.avg))
        .attr('text-anchor', 'middle')
        .attr('font-size', `${config.scale * 14}px`)
        .attr('font-weight', 900)
        .attr('fill', primary)
        .attr('stroke-width', config.scale)
        .attr('stroke', 'white')
        .attr('x', (d) => {
          let labelRelativeX = d.avg
          if (labelRelativeX < 0.1) {
            labelRelativeX = 0.1
          }
          if (labelRelativeX > 4.9) {
            labelRelativeX = 4.9
          }
          return xAxis(labelRelativeX) as number + 0.5
        })
        .attr('y', `${config.scale * 10}px`)
    }
  }
}
