import { MultiTextResponsesResult, ResponsesResult } from '../../../types/ResultSet'
import { MultiTextQuestionGroup } from '../../../types/Question'

/**
 * Returns a boolean whether the provided Result is based on a MultiText Question
 */
export function isMultiTextResult (
  result: ResponsesResult<any>
): result is MultiTextResponsesResult {
  return !!result.data?.groups?.length
}

/**
 * Returns a boolean whether the provided MultiText Result has the provided target groups
 */
export function isTargetMultiTextResult (
  result: ResponsesResult<any>,
  targetGroups: string[]
): result is MultiTextResponsesResult {
  if (!isMultiTextResult(result)) {
    return false
  }
  return result.data.groups?.every((group: MultiTextQuestionGroup) => {
    return targetGroups.some((g: string) => {
      if (!group.slug && group.id) {
        // Older questionnaires don't have slugs in these groups so use the ID
        return group.id.includes(g)
      }
      return group.slug === g
    })
  })
}
