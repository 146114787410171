<template>
  <platform-modal
    v-model="showModal"
    :icon="reportScore.icon || 'scores'"
    :width="800"
    :title="$t('modal.report.config.editScores.editScore.title')"
    @confirm="submit"
    @close="showModal = false"
  >
    <edit-report-benchmark-form
      v-model="formData"
    />
  </platform-modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { ReportBenchmark } from '@betterboards/shared/types/ReportBenchmark'
import { StatsResult, V2StatisticsQuery } from '@betterboards/shared/types/Statistic'
import PlatformModal from '@/components/PlatformModal.vue'
import EditReportBenchmarkForm from '@/components/forms/reports/benchmarks/EditReportBenchmarkForm.vue'
import { ScoreCollection } from '@betterboards/shared/helpers/entities/Result/scores'
import { UniverseQuery } from '@betterboards/shared/types/Report'

export default Vue.extend({
  name: 'EditReportBenchmarkModal',
  components: {
    EditReportBenchmarkForm,
    PlatformModal
  },
  props: {
    value: { type: Object as PropType<ReportBenchmark>, required: true }
  },
  data: () => ({
    showModal: true,
    formData: <Partial<ReportBenchmark>>{
      id: <string | undefined>undefined,
      collection: <ScoreCollection | undefined>undefined,
      version: <string | undefined>undefined,
      title: <string | undefined>undefined,
      subTitle: <string | undefined>undefined,
      queries: <V2StatisticsQuery[] | UniverseQuery[]>[],
      scores: <StatsResult[]>[]
    }
  }),
  watch: {
    showModal (showModal: boolean): void {
      if (!showModal) {
        this.$emit('close')
      }
    },
    value: 'init'
  },
  mounted (): void {
    this.init()
  },
  computed: {
    reportScore: {
      get (): ReportBenchmark {
        return this.value
      },
      set (val: ReportBenchmark): void {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    init (): void {
      this.formData.id = this.value.id
      this.formData.collection = this.value.collection
      this.formData.version = this.value.version
      this.formData.title = this.value.title
      this.formData.subTitle = this.value.subTitle
      this.formData.comparisonSurveyGroupId = this.value.comparisonSurveyGroupId
      if (!this.formData.queries) {
        this.formData.queries = []
      }
      this.formData.queries.splice(
        0,
        this.formData.queries.length,
        ...(this.value.queries ?? []) as any
      )
      if (this.value.scores?.length) {
        if (!this.formData.scores) {
          this.formData.scores = []
        }
        this.formData.scores.splice(
          0,
          this.formData.scores.length,
          ...this.value.scores
        )
      }
    },
    submit (): void {
      this.reportScore = {
        ...this.reportScore,
        ...(this.formData as any)
      }
      this.showModal = false
    }
  }
})
</script>
