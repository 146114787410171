
import { ResultType, SectionResults, SectionReviewItem, SectionReviewItemGroup } from '@betterboards/shared/types/ResultSet'

export function isResultSectionReviewed (sectionResults: SectionResults, reviewItems: SectionReviewItemGroup[]): boolean {
  if (reviewItems.length) {
    // Section has items requiring Review, only return true if all have been marked as Reviewed.
    return reviewItems.every((reviewItemGroup: SectionReviewItemGroup) => {
      return reviewItemGroup.items.every((reviewItem: SectionReviewItem) => !!reviewItem.reviewedAt)
    })
  }
  // Section has no ReviewItems, only mark as Reviewed if all Results have some data
  return sectionResults.results.every((i: ResultType) => {
    if (i.type === 'graphs') {
      // Graphs Result
      if (Array.isArray(i.data)) {
        return !!i.data.length
      }
      return !!i.data
    }
    // Responses Result
    return !!i.data.responses.length
  })
}
