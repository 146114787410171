<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g transform="translate(-366 -1672) translate(348 1372) translate(0 282) translate(18 18) translate(1 5) translate(.5 .5)">
                    <path stroke="currentColor" stroke-linecap="square" d="M1.5 2.5L36.5 2.5"/>
                    <path stroke="currentColor" stroke-linecap="round" d="M.5.5L.5 4.5M9.5.5L9.5 4.5M18.5.5L18.5 4.5M27.5.5L27.5 4.5M36.5.5L36.5 4.5"/>
                    <circle cx="32.5" cy="2.5" r="2.5" fill="currentColor"/>
                  </g>
                  <g transform="translate(-366 -1672) translate(348 1372) translate(0 282) translate(18 18) translate(1 5) translate(.5 .5) translate(0 12)">
                    <path stroke="currentColor" stroke-linecap="square" d="M1.5 2.5L36.5 2.5"/>
                    <path stroke="currentColor" stroke-linecap="round" d="M.5.5L.5 4.5M9.5.5L9.5 4.5M18.5.5L18.5 4.5M27.5.5L27.5 4.5M36.5.5L36.5 4.5"/>
                    <circle cx="13.5" cy="2.5" r="2.5" fill="currentColor"/>
                  </g>
                  <g transform="translate(-366 -1672) translate(348 1372) translate(0 282) translate(18 18) translate(1 5) translate(.5 .5) translate(0 24)">
                    <path stroke="currentColor" stroke-linecap="square" d="M1.5 2.5L36.5 2.5"/>
                    <path stroke="currentColor" stroke-linecap="round" d="M.5.5L.5 4.5M9.5.5L9.5 4.5M18.5.5L18.5 4.5M27.5.5L27.5 4.5M36.5.5L36.5 4.5"/>
                    <circle cx="22.5" cy="2.5" r="2.5" fill="currentColor"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'VarianceGraphIcon'
})
</script>
