<template>
  <platform-modal
    v-model="showModal"
    icon="document"
    :width="600"
    :save-disabled="!isValid"
    :title="$t('analysis.reportGroup.createModal.title')"
    :saving="saving"
    @save="save"
    @validate="$refs.form.validate()"
  >
    <div class="pb-3">
      <span>
        {{ $t('analysis.reportGroup.createModal.instruction') }}
      </span>
    </div>
    <report-group-form
      ref="form"
      v-model="reportGroup"
      :saving="saving"
      @valid="(val) => isValid = val"
    />
  </platform-modal>
</template>

<script lang="ts">
import { Company } from '@/API'
import ReportGroupForm from '@/components/analysis/report-groups/ReportGroupForm.vue'
import PlatformModal from '@/components/PlatformModal.vue'
import { createReportGroup } from '@/helpers/reportGroup'
import { DefaultQuestionnaireVariantName } from '@betterboards/shared/helpers/entities/Questionnaire/index'
import { PlatformQuestionnaireVariant } from '@betterboards/shared/types/Platform'
import { PlatformReportGroup } from '@betterboards/shared/types/Report'
import Vue, { PropType } from 'vue'
import { ResultSet } from '@betterboards/shared/types/ResultSet'

export default Vue.extend({
  name: 'CreateReportGroupModal',
  components: {
    PlatformModal,
    ReportGroupForm
  },
  props: {
    selectedReportGroup: { type: Object as PropType<PlatformReportGroup>, required: true }
  },
  data () {
    return {
      showModal: true,
      reportGroup: {
        name: null,
        variants: <PlatformQuestionnaireVariant[]>[]
      },
      saving: false,
      isValid: false
    }
  },
  mounted (): void {
    this.init()
  },
  watch: {
    'showModal' (val) {
      if (!val) {
        this.$emit('close')
      }
    }
  },
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    },
    resultSet (): ResultSet | null {
      return this.$store.state.Analysis.resultSet
    },
    hasMultipleVariants (): boolean {
      if (!this.resultSet) {
        return false
      }
      return this.resultSet.variants.length > 1
    }
  },
  methods: {
    init (): void {
      this.initialiseReportGroupVariants()
    },
    initialiseReportGroupVariants (): void {
      if (!this.resultSet) {
        console.error('Failed to initialize variants, missing ResultSet')
        return
      }

      this.reportGroup.variants = this.resultSet.variants.map(
        (v): PlatformQuestionnaireVariant => {
          const name = this.hasMultipleVariants
            ? ''
            : DefaultQuestionnaireVariantName
          return {
            variantCode: v.variantCode,
            name
          }
        }
      )
    },
    async save (): Promise<void> {
      const name = this.reportGroup.name
      const variants = this.reportGroup.variants
      const surveyGroupId: string = this.selectedReportGroup.surveyGroupId

      if (!name || !variants) {
        console.error('Missing name or variants', name, variants)
        return
      }

      this.saving = true
      let createReportGroupResponse: any
      try {
        createReportGroupResponse = await createReportGroup(
          surveyGroupId,
          name,
          variants
        )
      } catch (err) {
        console.error(
          'Caught an error while trying to create a ReportGroup:',
          {
            err,
            surveyGroupId,
            name,
            variants
          }
        )
        this.$toasted.error(this.$t('analysis.reportGroup.createModal.errorMessage') as string)
        throw err
      } finally {
        this.saving = false
      }

      this.showModal = false
      const reportGroup = {
        ...this.reportGroup,
        companyId: this.selectedCompany?.id,
        id: createReportGroupResponse.reportGroupId
      }
      this.$emit('created', reportGroup)
    }
  }
})
</script>
