// import { Store } from 'vuex'

export enum TrackedEvent {
  QuestionnaireCreate = 'questionnaireCreate',
  QuestionnaireDelete = 'questionnaireDelete'
}

// let defaultAttributes

// @TODO: Decide if we want to re-implement this Analytics logic for Amplify v6:
export default {
  install (Vue, options: { disabled?: boolean }) {
    // const buildDefaultAttributes = (store: Store<any>): { companyId: string, organisationId: string } => {
    //   const companyId = store.state.Company.selectedCompany?.id ?? 'undefined'
    //   const parentCompanyId = store.state.Company.selectedCompany?.parentId ?? 'undefined'
    //
    //   return {
    //     companyId,
    //     organisationId: parentCompanyId
    //   }
    // }
    //
    // Vue.prototype.$initAnalytics = async function (this: Vue) {
    //   defaultAttributes = buildDefaultAttributes(this.$store)
    //   if (options.disabled) {
    //     console.debug('Analytics disabled so ignoring $initAnalytics call.', defaultAttributes)
    //     return
    //   }
    //   Analytics.autoTrack('pageView', {
    //     enable: true,
    //     type: 'SPA'
    //   })
    //   Analytics.autoTrack('event', {
    //     enable: true,
    //     events: ['click'],
    //     selectorPrefix: 'data-bi-',
    //     attributes: defaultAttributes
    //   })
    // }
    // Vue.prototype.$analytics = async function (this: Vue, event: AnalyticsEvent) {
    //   if (options.disabled) {
    //     console.debug('Analytics disabled so ignoring an $analytics call:', event)
    //     return
    //   }
    //   event.attributes = { ...event.attributes, ...defaultAttributes }
    //   await Analytics.record(event)
    // }
  }
}
