import { CognitoUser } from 'amazon-cognito-identity-js'
import {
  CognitoIdentity,
  Company,
  CompanyUser,
  CreateCognitoIdentityInput,
  CreateCognitoIdentityMutation,
  GetUserByCognitoIdQuery, ModelSortDirection,
  UpdateCognitoIdentityInput,
  UpdateCognitoIdentityMutation
} from '@/API'
import { Module } from 'vuex'
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth'
import { GraphQLResult } from '@aws-amplify/api-graphql'
import { updateCognitoIdentity } from '@/graphql/mutations'
import { getFullUserByCognitoId } from '@/graphql/custom/getFullUserByCognitoId'
import { onCreateCompanyUser, onDeleteCompanyUser } from '@/graphql/subscriptions'
import { i18n } from '@/plugins/i18n'
import API from '@/services/API'

type Optional<T> = T | undefined

interface UserState {
  user: Optional<CognitoIdentity>
  entity: Optional<CognitoUser>
  companyMembershipSubscriptions: any[]
}

const defaultState = (): UserState => ({
  entity: undefined,
  user: undefined,
  companyMembershipSubscriptions: []
})

const UserStore: Module<UserState, any> = {
  namespaced: true,
  state: defaultState(),
  getters: {
    displayName: (state: UserState) => {
      if (!state.user) {
        return ''
      }

      return state.user.displayName
    }
  },
  mutations: {
    reset (state: UserState) {
      const newState = defaultState()
      for (const key of Object.keys(newState)) {
        state[key] = newState[key]
      }
    },
    setUser: (state: UserState, user: CognitoIdentity) => {
      state.user = user
      i18n.locale = user.defaultLanguage || 'en'
    },
    setCognitoUser: (state: UserState, user: CognitoUser) => (state.entity = user),
    setNewCompanyMembershipSubscription: (state: UserState, value: any) => (state.companyMembershipSubscriptions = value),
    pushCompanyMembershipSubscription: (state: UserState, subscription: any) => (state.companyMembershipSubscriptions.push(subscription))
  },
  actions: {
    async setup ({
      commit,
      dispatch
    }) {
      const user = await getCurrentUser()
      commit('setCognitoUser', user)

      const cognitoIdentity: CognitoIdentity = await dispatch('fetchCognitoIdentity', {
        cognitoId: user.username
      })

      commit('setUser', cognitoIdentity)
      await dispatch('loadCompanyList', {
        userCompanies: cognitoIdentity.companies?.items ?? []
      })

      void dispatch('User/selectCompany', undefined, { root: true })
    },
    async fetchCognitoIdentity ({ dispatch }, { cognitoId }: { cognitoId: string }): Promise<CognitoIdentity> {
      const cognitoIdentityResponse: GraphQLResult<GetUserByCognitoIdQuery> = await API.graphql({
        query: getFullUserByCognitoId,
        variables: {
          cognitoId,
          sortDirection: ModelSortDirection.DESC
        }
      })

      if (!cognitoIdentityResponse) {
        console.error('Failed to fetch information for the current individual. (1)')
        throw new Error('Failed to load User Company List.')
      }

      const cognitoIdentity: CognitoIdentity = cognitoIdentityResponse.data?.getUserByCognitoId?.items?.[0] as CognitoIdentity
      if (!cognitoIdentity) {
        console.error('Failed to fetch information for the current individual. (1)')
        throw new Error('Failed to load User Company List.')
      }

      return cognitoIdentity
    },
    async loadCompanyList ({ dispatch }, { userCompanies }: { userCompanies?: CompanyUser[] }): Promise<void> {
      if (!userCompanies?.length) {
        throw new Error('CognitoIdentity has no Companies.')
      }

      const promises = userCompanies.map(async (cognitoIdentity) => {
        if (cognitoIdentity && !cognitoIdentity.company?.deletedAt) {
          const company = {
            id: cognitoIdentity.companyId,
            name: cognitoIdentity.company?.name,
            createdAt: cognitoIdentity.company?.createdAt,
            sector: cognitoIdentity.company?.sector
          }
          await dispatch('Company/addCompanyToList', company, { root: true })
          await dispatch('Company/addCompanyAccessPermission', {
            id: cognitoIdentity.companyId,
            accountType: cognitoIdentity.accountType,
            name: cognitoIdentity.company?.name
          }, { root: true })
        }
      })

      await Promise.allSettled(promises)
    },
    async selectCompany ({ dispatch }) {
      const selectedCompany = localStorage.getItem('selectedCompany')
      console.debug('selecting company', 1, selectedCompany)
      if (selectedCompany) {
        try {
          await dispatch('Company/selectCompany', { id: selectedCompany }, { root: true })
          console.debug('selecting company', 2)
          return
        } catch (err) {
          console.warn('Failed to select company, attempting to auto-select.')
        }
      }
      console.debug('selecting company', 3)
      await dispatch('Company/pickLowestAccessCompany', undefined, { root: true })
    },
    async updateUser ({
      state,
      commit
    }: { state: UserState, commit: any }, user: CognitoIdentity) {
      if (!state.user || !state.entity) {
        throw new Error('Tried to update individual whilst non loaded')
      }

      const { data } = await API.graphql({
        query: updateCognitoIdentity,
        variables: {
          input: {
            id: user.id,
            displayName: user.displayName,
            defaultLanguage: user.defaultLanguage,
            avatar: user.avatar
          }
        }
      }) as GraphQLResult<UpdateCognitoIdentityMutation>
      commit('setUser', data?.updateCognitoIdentity)
    },
    async createUser ({
      state,
      commit
    }, user: CreateCognitoIdentityInput & { companies: Array<{ action: 'create' | 'delete', companyId: string}> }): Promise<CreateCognitoIdentityMutation> {
      return await API.post('backendfunctions', '/users', { body: user })
    },
    async updatePlatformUser ({
      state,
      commit
    }, user: UpdateCognitoIdentityInput & { companies: Array<{ action: 'create' | 'delete', companyId: string}> }): Promise<UpdateCognitoIdentityMutation> {
      return await API.put('backendfunctions', '/users', { body: user })
    },
    roleByCompanyId: ({ state }: { state: UserState }, companyId: string) => {
      if (state.user?.companies?.items) {
        const companyUserEntry = state.user?.companies.items
          .filter((item) => !!item)
          .find((item) => item?.companyId === companyId)

        return companyUserEntry?.accountType
      }
    },
    async setupNewCompanyMembershipSubscription ({ commit, dispatch, state }: { commit: Function, dispatch: Function, state: UserState }) {
      if (!state.user) {
        throw new Error('Unable to setup subscription as no user is set.')
      }
      const createCompanyUserSubscription = API.graphql({
        query: onCreateCompanyUser,
        variables: {
          cognitoIdentityId: state.user.id
        }
      })
        .subscribe({
          next: ({ data }) => {
            fetchAuthSession({ forceRefresh: true })
              .then(() => {
                const companyUser = data?.onCreateCompanyUser
                if (companyUser.company?.id) {
                  const company: Partial<Company> = {
                    id: companyUser.company.id,
                    name: companyUser.company.name,
                    createdAt: companyUser.company.createdAt
                  }
                  dispatch('Company/addCompanyToList', company, { root: true })
                }
                dispatch('Company/addCompanyAccessPermission', {
                  id: companyUser.companyId,
                  accountType: companyUser.accountType,
                  name: companyUser.company?.name
                }, { root: true })
              })
              .catch((err) => {
                console.error('Failed to refresh token to handle onCreateCompanyUser, error:', err)
              })
          }
        })
      const deleteCompanyUserSubscription = API.graphql({
        query: onDeleteCompanyUser,
        variables: {
          cognitoIdentityId: state.user.id
        }
      })
        .subscribe({
          next: ({ data }) => {
            const companyUser = data?.onDeleteCompanyUser
            void dispatch('Company/removeCompanyAccessPermission', companyUser.companyId, { root: true })
            // fetchAuthSession({ forceRefresh: true })
            //   .catch((err) => {
            //     console.error('Failed to refresh token to handle onDeleteCompanyUser, error:', err)
            //   })
            //   .finally(() => {
            //     void dispatch('User/selectCompany', undefined, { root: true })
            //   })
          }
        })
      commit('pushCompanyMembershipSubscription', createCompanyUserSubscription)
      commit('pushCompanyMembershipSubscription', deleteCompanyUserSubscription)
      console.debug('Setup company subscriptions', state.companyMembershipSubscriptions.length)
    },
    async teardownNewCompanyMembershipSubscription ({ commit, state }) {
      if (state.companyMembershipSubscriptions.length > 0) {
        state.companyMembershipSubscriptions.forEach((item) => item.unsubscribe())
        commit('setNewCompanyMembershipSubscription', [])
      }
    }
  }
}

export default UserStore
