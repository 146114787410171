<template>
  <div class="pt-6 pb-2">
    <confirmation-modal
      v-if="confirmDeleteCriteriaItem"
      entity-type="section"
      action="delete"
      :image="false"
      :entity="confirmDeleteCriteriaItem"
      @confirm="deleteCriteriaItem(confirmDeleteCriteriaItem)"
      @cancel="confirmDeleteCriteriaItem = undefined"
    />
    <h3 class="mb-3">
      {{ $t('form.questionnaire.customContent.itemsTitle') }}
    </h3>
    <v-expansion-panels
      multiple
      readonly
      class="mb-4"
    >
      <v-expansion-panel
        v-for="(criteriaItemVariants, slug) in criteriaItemsVariants"
        :key="slug"
        :ref="`criteria-${slug}`"
        class="platform--expansion-panel mt-4 rounded"
      >
        <v-expansion-panel-header @click="toggleCriteriaItemExpansionPanel(slug)">
          <div class="min-width-0">
            <v-row no-gutters align="center" class="flex-nowrap">
              <v-col class="flex-grow-1 min-width-0">
                <div class="criteria-item--header pr-6">
                  <strong :class="{
                    'warning--text': invalidCriteriaItems.includes(slug)
                  }">
                    <template v-if="criteriaItemVariants[selectedQuestionnaireVariant]">
                      {{ criteriaItemVariants[selectedQuestionnaireVariant].name || $t('form.questionnaire.customContent.unnamedItem') }}
                    </template>
                  </strong>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <criteria-item-form
            v-model="criteriaItemsVariants[slug]"
            :selected-questionnaire-variant="selectedQuestionnaireVariant"
            :variants="variants"
            :questionnaire-id="questionnaireId"
            :disabled="disabled"
            @updateCriteriaItem="updateCriteriaItem"
            @delete="(criteriaItem) => confirmDeleteCriteriaItem = criteriaItem"
            @valid="(valid) => updateCriteriaItemValidity(slug, valid)"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div
      class="d-inline-block"
      v-tooltip:bottom="disabledAddButtonTooltip"
    >
      <platform-button
        @click="addCriteriaItem"
        primary
        icon="plus"
        :disabled="disabled || disabledAddButton"
      >
        Add Criteria Item
      </platform-button>
    </div>
  </div>
</template>

<script lang="ts">
import CriteriaItemForm from '@/components/forms/criteria-groups/CriteriaItemForm.vue'
import { getCriteriaItemVariantData } from '@/helpers/questionnaire'
import Vue, { PropType } from 'vue'
import {
  PlatformQuestionnaireVariant,
  QuestionnaireVariantCode
} from '@/types/Platform'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import { cloneDeep } from 'lodash'
import { DefaultQuestionnaireVariant } from '@betterboards/shared/helpers/entities/Questionnaire/index'
import { v4 as uuid } from 'uuid'
import { CriteriaGroup, CriteriaItem } from '@betterboards/shared/types/Content'

interface CriteriaItemsMap {
  [criteriaItemSlug: string]: {
    [variantCode: string]: CriteriaItem | null
  } | undefined
}

const DefaultCriteriaItem = {
  name: null,
  slug: null,
  description: null,
  variantCode: DefaultQuestionnaireVariant
}

const MaxCustomContentItems = 14

export default Vue.extend({
  name: 'CriteriaItemsList',
  components: {
    CriteriaItemForm,
    ConfirmationModal
  },
  props: {
    value: { type: Object as PropType<CriteriaGroup>, required: true },
    selectedQuestionnaireVariant: { type: String as PropType<QuestionnaireVariantCode>, required: false },
    variants: { type: Array as PropType<PlatformQuestionnaireVariant[]>, required: true },
    questionnaireId: { type: String, required: false },
    unsavedCriteriaGroups: { type: Array as PropType<CriteriaGroup[]>, required: false },
    deletedSavedCriteriaGroups: { type: Array as PropType<CriteriaGroup[]>, required: false },
    disabled: { type: Boolean, default: false }
  },
  data: () => ({
    criteriaItemsVariantsMap: <CriteriaItemsMap>{},
    invalidCriteriaItems: <string[]>[],
    confirmDeleteCriteriaItem: <CriteriaItem | undefined>undefined
  }),
  mounted (): void {
    this.checkValidity()
    this.init()
  },
  watch: {
    invalidCriteriaItems: 'checkValidity'
  },
  computed: {
    defaultVariant (): QuestionnaireVariantCode | undefined {
      return this.variants?.[0]?.variantCode as QuestionnaireVariantCode | undefined
    },
    criteriaItemsVariants (): CriteriaItemsMap {
      return Object.keys(this.criteriaItemsVariantsMap).reduce(
        (map, slug) => {
          if (this.criteriaItemsVariantsMap[slug]) {
            map[slug] = this.criteriaItemsVariantsMap[slug]
          }
          return map
        },
        {}
      )
    },
    totalUniqueCriteriaItems (): number {
      const unsavedCriteriaGroupIndex: number = this.unsavedCriteriaGroups?.findIndex(
        (c: CriteriaGroup) => c.slug === this.value.slug
      )
      let unsavedCriteriaItems: number = 0
      if (unsavedCriteriaGroupIndex !== -1) {
        unsavedCriteriaItems = this.unsavedCriteriaGroups[unsavedCriteriaGroupIndex].items.filter(
          (ci: CriteriaItem) => ci.variantCode === this.selectedQuestionnaireVariant
        ).length
      }

      const deleteSavedCriteriaGroupIndex: number = this.deletedSavedCriteriaGroups?.findIndex(
        (c: CriteriaGroup) => c.slug === this.value.slug
      )
      let deletedSavedCriteriaItems: number = 0
      if (deleteSavedCriteriaGroupIndex !== -1) {
        deletedSavedCriteriaItems = this.deletedSavedCriteriaGroups[deleteSavedCriteriaGroupIndex].items.filter(
          (ci: CriteriaItem) => ci.variantCode === this.selectedQuestionnaireVariant
        ).length
      }

      return this.value.items.filter((i) => i.variantCode === this.selectedQuestionnaireVariant).length + unsavedCriteriaItems - deletedSavedCriteriaItems
    },
    disabledAddButton (): boolean {
      return this.totalUniqueCriteriaItems === MaxCustomContentItems
    },
    disabledAddButtonTooltip (): string {
      return this.disabledAddButton
        ? this.$t('form.questionnaire.customContent.addButtonDisabledTooltip') as string
        : this.$t('form.questionnaire.customContent.addButtonTooltip') as string
    }
  },
  methods: {
    init (): void {
      if (!this.value?.items.length) {
        return
      }
      this.value.items.forEach((criteriaItem: CriteriaItem) => {
        criteriaItem.variantCode = criteriaItem.variantCode ?? this.defaultVariant
        this.setCriteriaVariant(criteriaItem)
      })
    },
    setCriteriaVariant (criteriaItemVariant: CriteriaItem): void {
      if (!this.criteriaItemsVariantsMap[criteriaItemVariant.slug]) {
        const criteriaItemVariants = this.variants.reduce((map, v) => {
          map[v.variantCode] = getCriteriaItemVariantData(criteriaItemVariant, v.variantCode)
          return map
        }, {})
        Vue.set(this.criteriaItemsVariantsMap, criteriaItemVariant.slug, criteriaItemVariants)
      }
      Vue.set(this.criteriaItemsVariantsMap[criteriaItemVariant.slug]!, criteriaItemVariant.variantCode, criteriaItemVariant)
    },
    updateCriteriaItem (criteriaItem: CriteriaItem): void {
      if (this.disabled || this.invalidCriteriaItems.includes(criteriaItem.slug)) {
        return
      }
      this.setCriteriaVariant(criteriaItem)
      this.$emit('updateCriteriaItem', criteriaItem)
    },
    deleteCriteriaItem (criteriaItem: CriteriaItem): void {
      if (this.disabled) {
        return
      }

      this.confirmDeleteCriteriaItem = undefined
      const variantsMap = {
        ...this.criteriaItemsVariantsMap[criteriaItem.slug]
      }

      const deleteCriteriaItem: CriteriaItem[] = []
      Object.keys(variantsMap).forEach((variantCode: string) => {
        const criteriaItemVariant: CriteriaItem | null = variantsMap[variantCode]
        if (criteriaItemVariant) {
          deleteCriteriaItem.push(criteriaItemVariant)
        }
      })
      this.$emit('deleteCriteriaItem', deleteCriteriaItem)
      this.criteriaItemsVariantsMap[criteriaItem.slug] = undefined
    },
    toggleCriteriaItemExpansionPanel (slug?: string): void {
      if (!slug) {
        return
      }
      const sectionEl = this.$refs[`criteria-${slug}`]?.[0]
      if (sectionEl) {
        sectionEl.toggle()
      }
    },
    updateCriteriaItemValidity (slug: string | undefined, valid: boolean): void {
      if (typeof slug !== 'string') {
        console.warn('Failed to updateCriteriaItemValidity, criteriaItem has no slug!', slug)
        return
      }
      const index = this.invalidCriteriaItems.indexOf(slug)
      if (index !== -1) {
        if (!valid) {
          // CriteriaItem already marked as invalid, ignore.
          return
        }
        // CriteriaItem no longer invalid
        this.invalidCriteriaItems.splice(index, 1)
      }
      if (valid) {
        return
      }
      this.invalidCriteriaItems.push(slug)
    },
    checkValidity (): void {
      if (this.invalidCriteriaItems.length > 0) {
        this.$emit('valid', false)
        return
      }
      this.$emit('valid', true)
    },
    addCriteriaItem (): void {
      if (this.disabled) {
        return
      }
      const criteriaItem: CriteriaItem = {
        ...cloneDeep(DefaultCriteriaItem),
        name: 'New Criteria Item',
        slug: uuid(),
        description: '',
        variantCode: this.selectedQuestionnaireVariant
      }

      const criteriaItemVariants: CriteriaItem[] = this.variants.reduce(
        (criteriaItems: CriteriaItem[], variant: PlatformQuestionnaireVariant) => {
          criteriaItems.push({
            ...criteriaItem,
            variantCode: variant.variantCode
          })
          return criteriaItems
        },
        []
      )

      this.setCriteriaVariant(criteriaItem)
      this.$emit('addCriteriaItem', criteriaItemVariants)

      this.$nextTick(() => {
        this.toggleCriteriaItemExpansionPanel(criteriaItem.slug)
      })
    }
  }
})
</script>

<style lang="scss" scoped>

.criteria-item--header {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>
