<template>
  <div
    class="section-scores__container"
    :style="sectionScoresContainerStyle"
  >
    <div
      v-for="({ collection, value, label }, index) in displaySectionScores"
      :key="index"
      class="section-score"
      :style="sectionScoreStyle"
    >
      <div class="score__label">
        <div class="content">
          {{ label }}
        </div>
        <span
          v-if="collection === ScoreCollection.UniverseAverage && displayUniverseQueries"
          class="sub-content"
        >
          {{ displayUniverseQueries }}
        </span>
      </div>
      <div class="ball">
        <span class="ball-content">
          {{ value }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { CompanyConfiguration, PlatformCompany } from '@betterboards/shared/types/Company'
import { ScoreCollectionInfo } from '@betterboards/shared/types/ReportBenchmark'
import { ScoreCollection } from '@betterboards/shared/helpers/entities/Result/scores'

export default Vue.extend({
  name: 'SectionScoreBalls',
  props: {
    sectionScores: { type: Array as PropType<ScoreCollectionInfo[]>, required: true },
    subSection: { type: Boolean, default: false }
  },
  data: () => ({
    ScoreCollection
  }),
  computed: {
    selectedCompany (): PlatformCompany {
      return this.$store.state.Company.selectedCompany
    },
    companyConfiguration (): CompanyConfiguration | undefined {
      return this.selectedCompany?.configuration
    },
    reportConfiguration (): any | undefined {
      return this.companyConfiguration?.report
    },
    displaySectionScores (): ScoreCollectionInfo[] {
      return this.sectionScores
        .filter(
          (s: ScoreCollectionInfo) => !!s.value
        )
        .map((s: ScoreCollectionInfo): ScoreCollectionInfo => {
          const value = typeof s?.value === 'number'
            ? s.value.toFixed(2)
            : s.value
          const label = s.collection === ScoreCollection.Average && this.subSection
            ? this.selectedCompany.name
            : s.label
          return {
            ...s,
            value,
            label
          }
        })
    },
    sectionScoreBackgroundColor (): string | undefined {
      return this.reportConfiguration?.sectionScores?.background
    },
    sectionScorePrimaryColor (): string | undefined {
      return this.reportConfiguration?.sectionScores?.primaryColor
    },
    sectionScoresContainerStyle (): any {
      return {
        'background-color': this.sectionScoreBackgroundColor
      }
    },
    sectionScoreStyle (): any {
      return {
        'background-color': this.sectionScorePrimaryColor
      }
    }
  }
})
</script>

<style lang="scss" scoped="scoped">
.section-scores__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--v-grey-lighten2);
  box-shadow: 0 1px 4px rgba(96, 96, 99, 0.6);
  padding: 0 8px 4px;
  margin: 8px 0 10px;

  .section-score {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    font-size: 0.95em;
    padding: 6px 3px 4px;

    .ball {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background-color: var(--v-primary-base);
      color: white;
      font-size: 1.1em;
    }
    .score__label {
      flex-grow: 1;
      padding-bottom: 4px;
      color: var(--v-primary-base);

      .content {
        font-size: 1.1em;
        line-height: 1.1;
        font-weight: 600;
      }
      .sub-content {
        font-size: 0.65em;
        line-height: 1.05;
      }
    }
  }
}
</style>
