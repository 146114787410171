<template>
  <v-row no-gutters justify="center">
    <v-col :cols="12" class="pb-3">
      <span class="primary--text font-weight-bold">
        {{ $t('form.individual.appointments.title', [totalAppointments]) }}
      </span>
    </v-col>
    <v-col
      v-for="(option, index) in appointmentOptions"
      :key="index"
      :cols="12"
      :sm="6"
      class="px-2 d-flex flex-column align-center"
    >
      <input-label
        :label="option.label"
        class="text-body-2 grey--text text--darken-1 text-no-wrap"
      />
      <platform-number-field
        v-model.number="appointments[option.role]"
        class="number-field pt-0 mt-0"
        :class="[option.role]"
        :min="0"
        :max="1000"
      />
    </v-col>
    <v-col :cols="12" class="text-right">
      <platform-icon
        name="check-outline"
        color="secondary"
        class="cursor-pointer"
        :size="24"
        @click="$emit('close')"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformIndividualAppointments } from '@betterboards/shared/types/Individual'
import PlatformNumberField from '@/components/shared/inputs/PlatformNumberField.vue'
import InputLabel from '@/components/shared/inputs/InputLabel.vue'

export default Vue.extend({
  name: 'IndividualAppointmentsForm',
  components: {
    InputLabel,
    PlatformNumberField
  },
  props: {
    value: { type: Object as PropType<PlatformIndividualAppointments>, required: true },
    totalAppointments: { type: Number, required: true }
  },
  data: () => ({
    menuActive: false
  }),
  computed: {
    appointments: {
      get (): PlatformIndividualAppointments {
        return this.value
      },
      set (appointments: PlatformIndividualAppointments): void {
        this.$emit('input', appointments)
      }
    },
    appointmentOptions (): Array<{ label: string, role: string, value: number }> {
      return Object.keys(this.value).map((role: string): any => {
        return {
          role,
          label: this.$t(`shared.individual.boardAppointments.${role}`),
          value: this.value[role]
        }
      })
    }
  },
  methods: {
    //
  }
})
</script>

<style lang="scss" scoped="scoped">
::v-deep .number-field {
  max-width: 40px;
  &.Total {
    max-width: 60px;
  }
}
</style>
