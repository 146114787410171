import { ReportConfiguration, ReportPageSize } from '../../../types/Report'

export { RecommendationContext } from './recommendations'

export enum ReportField {
  SectionIntroduction = 'section-intro',
  DividerPage = 'divider-page',
  CommitteeSummary = 'committee-summary',
}

// Reports
export const GenericReportIndividualId = 'none'

export const DefaultReportConfig: ReportConfiguration = {
  showDiversity: false,
  showTenure: false,
  pageSize: ReportPageSize.A4,
  systemAverageTitle: 'Better Boards',
  pages: {}
}
