<template>
  <platform-modal
    :icon="icon"
    :title="$t('modal.addMFADevice.title')"
    :value="showModal"
    :width="400"
    :header-padding="false"
    :saving="pendingAddMethod"
    :confirm-disabled="!formValid"
    @close="closeModal"
    @confirm="submit"
  >
    <template v-if="currentCognitoUser">
      <add-totp-method-form
        v-if="method === UserMFAMethod.TOTP"
        v-model="formData.verificationCode"
        :current-cognito-user="currentCognitoUser"
        @valid="(val) => formValid = val"
      />
      <add-sms-method-form
        v-else-if="method === UserMFAMethod.SMS"
        v-model="formData.verificationCode"
        :current-cognito-user="currentCognitoUser"
        @valid="(val) => formValid = val"
      />
    </template>
    <platform-spinner
      v-else
      :size="30"
      class="mx-auto"
    />
  </platform-modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue'
import { PlatformCognitoUser } from '@/types/User'
import { Company } from '@/API'
import PlatformModal from '@/components/PlatformModal.vue'
import AddTotpMethodForm from '@/components/user/account/mfa/AddTOTPMethodForm.vue'
import AddSmsMethodForm from '@/components/user/account/mfa/AddSMSMethodForm.vue'
import { UserMFAMethod } from '@/types/Auth'
import PlatformSpinner from '@/components/shared/PlatformSpinner.vue'
import {
  verifyTOTPSetup,
  confirmUserAttribute
} from 'aws-amplify/auth'

export default Vue.extend({
  name: 'AddMFAMethodModal',
  components: {
    PlatformSpinner,
    PlatformModal,
    AddTotpMethodForm,
    AddSmsMethodForm
  },
  props: {
    method: { type: String as PropType<UserMFAMethod>, required: true },
    currentCognitoUser: { type: Object as PropType<PlatformCognitoUser>, required: true },
    icon: { type: String, default: 'shield-key' }
  },
  data: () => ({
    formData: {
      verificationCode: <null | string>null
    },
    formValid: false,
    showModal: true,
    pendingAddMethod: false,
    pendingInitTOTP: false,
    totpSecretCode: <null | string>null,
    UserMFAMethod
  }),
  computed: {
    selectedCompany (): Company | undefined {
      return this.$store.state.Company.selectedCompany
    }
  },
  methods: {
    closeModal (): void {
      this.$emit('close')
    },
    async submit (): Promise<void> {
      if (!this.currentCognitoUser || !this.formData.verificationCode || this.pendingAddMethod) {
        return
      }
      this.pendingAddMethod = true
      try {
        await this.verifyMFACode()
      } catch (err) {
        console.error('Failed to set preferred MFA method:', this.formData.verificationCode, err)
        this.$toasted.error(this.$t('modal.addMFADevice.preferenceErrorMessage') as string)
        throw err
      } finally {
        this.pendingAddMethod = false
      }

      this.$toasted.success(this.$t('modal.addMFADevice.successMessage') as string)
      this.$emit('added', this.method)
      this.$emit('close')
    },
    async verifyMFACode (): Promise<void> {
      switch (this.method) {
        case UserMFAMethod.TOTP:
          return await this.verifyTOTPCode()
        case UserMFAMethod.SMS:
          return await this.verifySMSCode()
      }
      throw new Error('Unrecognized MFA method provided.')
    },
    async verifySMSCode (): Promise<void> {
      if (!this.currentCognitoUser || !this.formData.verificationCode) {
        console.warn('verifySMSCode early return', this.formData.verificationCode)
        return
      }

      try {
        await confirmUserAttribute({
          userAttributeKey: 'phone_number',
          confirmationCode: this.formData.verificationCode
        })
      } catch (err) {
        this.pendingAddMethod = false
        console.error('Failed to verify SMS MFA code:', this.formData.verificationCode, err)
        this.$toasted.error(this.$t('modal.addMFADevice.errorMessage') as string)
        throw err
      }
      console.info('Verified SMS MFA code!')
    },
    async verifyTOTPCode (): Promise<void> {
      console.debug('verifyTOTPCode', 1)
      if (!this.currentCognitoUser || !this.formData.verificationCode) {
        console.debug('verifyTOTPCode return', 2)
        return
      }
      console.debug('verifyTOTPCode', 3)

      try {
        await verifyTOTPSetup({
          code: this.formData.verificationCode
        })
      } catch (err) {
        console.error('Failed to verify TOTP token:', this.formData.verificationCode, err)
        this.$toasted.error(this.$t('modal.addMFADevice.errorMessage') as string)
        throw err
      } finally {
        this.pendingAddMethod = false
      }
      console.info('Verified TOTP token!')
    }
  }
})
</script>
